// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Tooltip from "common/Tooltip";
import { IRow } from "common/datagrid/DataGrid";

interface ILicensesColumnProps {
  value: any;
  row: IRow;
}

const LicensesColumn = ({ value, row }: ILicensesColumnProps) => {
  return (
    <div className="grid-cell-content">
      {value && row.data["fileName"] && (
        <Tooltip title={value}>
          <a
            href={`#/Licenses/${row.data["licenseType"]}/${encodeURIComponent(
              row.data["fileName"]
            )}`}
            rel="noopener noreferrer"
            target="_blank"
            data-qa="link"
            className="link-label"
          >
            {value}
          </a>
        </Tooltip>
      )}
      {value && !row.data["fileName"] && row.data["licensesUrl"] && (
        <Tooltip title={value}>
          <a
            href={row.data["licensesUrl"]}
            rel="noopener noreferrer"
            target="_blank"
            data-qa="link"
            className="link-label"
          >
            {value}
          </a>
        </Tooltip>
      )}
      {!value && null}
    </div>
  );
};

export default LicensesColumn;
