// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import ILine from "@apm/widgets/build/models/ILine";
import IRegion from "@apm/widgets/build/models/IRegion";
import ISeries from "@apm/widgets/build/models/ISeries";
import ParametersTrend from "@apm/widgets/build/widgets/ParametersTrend";
import Processing from "components/common/Processing";
import { WidgetErrorMessage } from "components/common/widget/Widget";
import { RouterContext } from "core/app/components/RouterProvider";
import { Statuses } from "core/data/models/Data";
import { useContext } from "react";
import useParametersChart from "../hooks/useParametersChart";
import ISelectedParameter from "../models/ISelectedParameter";
import "./ParametersChart.less";

const chartHeight = 360;

export interface ITranslations {
  noData: string;
  title: string;
}

export interface IParametersChartProps {
  assetId: string;
  selectedParameters: ISelectedParameter[];
}

const ParametersChart = ({
  assetId,
  selectedParameters
}: IParametersChartProps) => {
  const {
    formatXTick,
    formatTooltipTitle,
    formatValue,
    lines,
    regions,
    series,
    status,
    translations
  } = useParametersChart(assetId, selectedParameters);

  return (
    <div className="parameters-chart-container">
      <ParametersChartContent
        formatTooltipTitle={formatTooltipTitle}
        formatTooltipValue={formatValue}
        formatXTick={formatXTick}
        formatYTick={formatValue}
        lines={lines}
        regions={regions}
        series={series}
        status={status}
        translations={translations}
      />
    </div>
  );
};

interface IParametersChartContentProps {
  formatXTick: (date: Date) => string;
  formatYTick: (value: number, unit: string) => string;
  formatTooltipTitle: (date: Date) => string;
  formatTooltipValue: (
    value: number,
    unit: string | null | undefined
  ) => string;
  lines: ILine[];
  regions: IRegion[];
  series: ISeries[];
  status: Statuses;
  translations?: ITranslations;
}

const ParametersChartContent = ({
  formatXTick,
  formatYTick,
  formatTooltipTitle,
  formatTooltipValue,
  lines,
  regions,
  series,
  status,
  translations
}: IParametersChartContentProps) => (
  <div className="parameters-chart">
    {status === null && (
      <ParametersChartNoData
        formatYTick={formatYTick}
        translations={translations}
      />
    )}
    {status === Statuses.Loading && <ParametersChartLoading />}
    {status === Statuses.Succeeded && (
      <ParametersChartSucceeded
        formatXTick={formatXTick}
        formatYTick={formatYTick}
        formatTooltipTitle={formatTooltipTitle}
        formatTooltipValue={formatTooltipValue}
        lines={lines}
        regions={regions}
        series={series}
        translations={translations}
      />
    )}
    {status === Statuses.Failed && <ParametersChartFailed />}
  </div>
);

const ParametersChartLoading = () => (
  <div className="loading">
    <Processing />
  </div>
);

interface IParametersChartNoDataProps {
  formatYTick: (value: number, unit: string) => string;
  translations?: ITranslations;
}

const ParametersChartNoData = ({
  formatYTick,
  translations
}: IParametersChartNoDataProps) => {
  return (
    <ParametersTrend
      dataQa="parameters-chart"
      formatYTick={formatYTick}
      height={chartHeight}
      series={null}
      showLegend={false}
      showWidgetCard={false}
      translations={translations}
    />
  );
};

interface IParametersChartSucceededProps {
  formatXTick: (date: Date) => string;
  formatYTick: (value: number, unit: string) => string;
  formatTooltipTitle: (date: Date) => string;
  formatTooltipValue: (
    value: number,
    unit: string | null | undefined
  ) => string;
  lines: ILine[];
  regions: IRegion[];
  series: ISeries[];
  translations?: ITranslations;
}

const ParametersChartSucceeded = ({
  formatXTick,
  formatYTick,
  formatTooltipTitle,
  formatTooltipValue,
  lines,
  regions,
  series,
  translations
}: IParametersChartSucceededProps) => {
  const { experimental } = useContext(RouterContext);

  return (
    <ParametersTrend
      dataQa="parameters-chart"
      experimental={experimental}
      formatXTick={formatXTick}
      formatYTick={formatYTick}
      formatTooltipTitle={formatTooltipTitle}
      formatTooltipValue={formatTooltipValue}
      height={lines?.length || regions?.length ? chartHeight - 50 : chartHeight}
      series={series}
      showLegend={{
        thresholds: true
      }}
      showWidgetCard={false}
      thresholds={{
        lines,
        regions
      }}
      translations={translations}
    />
  );
};

const ParametersChartFailed = () => <WidgetErrorMessage />;

export default ParametersChart;
