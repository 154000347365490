// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import * as d3 from "d3";
import { colorBlue50 } from "styles/ColorVariables";

class BoxPlot {
  root: d3.Selection<SVGElement, null, null, null>;
  private width = 300;
  private height = 100;
  private margin = { top: 10, right: 20, bottom: 30, left: 10 };
  private boxHeight = 25;
  private boxCenter = (this.getChartHeight() - 10) / 2;

  private getChartWidth() {
    return this.width - this.margin.left - this.margin.right;
  }

  private getChartHeight() {
    return this.height - this.margin.top - this.margin.bottom;
  }

  private getDataExtent(data: BoxPlotData) {
    const dataExtent = d3.extent([
      data.getLowerExtreme(),
      data.getUpperExtreme(),
      data.getValue()
    ]);
    const extraInterval = (dataExtent[1] - dataExtent[0]) * 0.1;
    return d3.extent([
      dataExtent[0] - extraInterval,
      dataExtent[1] + extraInterval
    ]);
  }

  constructor(root: SVGElement) {
    this.root = d3.select(root);
  }

  public draw(data: BoxPlotData) {
    this.clear();
    if (data.getValue() !== undefined || data.getValue() != null) {
      this.drawChart(data);
    }
  }

  private clear() {
    this.root.selectAll("*").remove();
  }

  private drawChart(data: BoxPlotData) {
    const chartStroke = "#797979";

    this.root.attr("width", this.width).attr("height", this.height);

    const rootGroup = this.root.append("svg:g");

    rootGroup
      .attr(
        "transform",
        "translate(" + this.margin.left + "," + this.margin.top + ")"
      )
      .attr("shape-rendering", "crispEdges");

    // show the x scale
    const x = d3
      .scaleLinear()
      .domain(this.getDataExtent(data))
      .range([0, this.getChartWidth()])
      .nice();
    rootGroup
      .append("g")
      .attr("transform", "translate(0," + this.getChartHeight() + ")")
      .call(d3.axisBottom(x).ticks(6, "r"))
      .append("text")
      .text(data.getUnit())
      .attr(
        "transform",
        "translate(" + this.getChartWidth() / 2 + "," + 30 + ")"
      )
      .attr("fill", "currentColor");

    // show the main vertical line
    rootGroup
      .append("line")
      .attr("y1", this.boxCenter)
      .attr("y2", this.boxCenter)
      .attr("x1", x(data.getLowerExtreme()))
      .attr("x2", x(data.getUpperExtreme()))
      .style("stroke", chartStroke);

    // show the box
    rootGroup
      .append("rect")
      .attr("y", this.boxCenter - this.boxHeight / 2)
      .attr("x", x(data.getQ1()))
      .attr("width", x(data.getQ3()) - x(data.getQ1()))
      .attr("height", this.boxHeight)
      .style("stroke", chartStroke)
      .style("fill", "#ffffff");

    // show median, lowerExtreme and upperExtreme horizontal lines
    rootGroup
      .selectAll("toto")
      .data([data.getLowerExtreme(), data.getMedian(), data.getUpperExtreme()])
      .enter()
      .append("line")
      .attr("y1", this.boxCenter - this.boxHeight / 2)
      .attr("y2", this.boxCenter + this.boxHeight / 2)
      .attr("x1", function (d: number) {
        return x(d);
      })
      .attr("x2", function (d: number) {
        return x(d);
      })
      .style("stroke", chartStroke);

    // show value horizontal lines
    let dataValue = data.getValue();
    if (dataValue !== undefined && dataValue != null) {
      rootGroup
        .append("line")
        .attr("y1", this.boxCenter - this.boxHeight / 2 - 8)
        .attr("y2", this.boxCenter + this.boxHeight / 2 + 8)
        .attr("x1", x(dataValue))
        .attr("x2", x(dataValue))
        .style("stroke", colorBlue50)
        .style("stroke-width", "3px");
    }
  }
}

class BoxPlotData {
  value: number;
  q1: number;
  median: number;
  q3: number;
  upperExtreme: number;
  lowerExtreme: number;
  unit?: string;

  constructor(
    value: number,
    q1: number,
    median: number,
    q3: number,
    upperExtreme: number,
    lowerExtreme: number,
    unit?: string
  ) {
    this.value = value;
    this.q1 = q1;
    this.median = median;
    this.q3 = q3;
    this.upperExtreme = upperExtreme;
    this.lowerExtreme = lowerExtreme;
    this.unit = unit;
  }

  getValue() {
    return this.value;
  }

  getQ1() {
    return this.q1;
  }

  getMedian() {
    return this.median;
  }

  getQ3() {
    return this.q3;
  }

  getUpperExtreme() {
    return this.upperExtreme;
  }

  getLowerExtreme() {
    return this.lowerExtreme;
  }

  getUnit() {
    return this.unit;
  }
}

export default BoxPlot;

export { BoxPlotData };
