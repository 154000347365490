// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import PowerBiReports, {
  IReport,
  ITemplate
} from "@apm/widgets/build/widgets/PowerBiReports";
import { notifications } from "@pg/common/build/components/Notifications";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import Processing from "components/common/Processing";
import Data, { Statuses } from "core/data/models/Data";
import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import ReportsStore from "features/MainDashboard/analytics/ReportsStore";
import { saveAs } from "file-saver";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import { AppDispatch } from "store";
import { config } from "utils/AppConfig";
import loadReports from "../actions/loadReports";
import loadReportsTemplates from "../actions/loadReportsTemplates";
import removeReports from "../actions/removeReports";
import removeReportsTemplates from "../actions/removeReportsTemplates";
import { IReportInfo } from "../models/IReportInfo";
import { IReportName } from "../models/IReportName";
import getReportsSelector from "../selectors/getReportsSelector";
import getReportsTemplatesSelector from "../selectors/getReportsTemplatesSelector";

interface IReportsConfigurationActions {
  loadReportsTemplates: () => void;
  removeReportsTemplates: () => void;
  loadReports: () => void;
  removeReports: () => void;
}

interface IReportsConfigurationDataProps {
  templates: Data<string[]>;
  reports: Data<IReportInfo[]>;
}

interface IReportsConfigurationProps
  extends IReportsConfigurationActions,
    IReportsConfigurationDataProps {}

const ReportsConfiguration = (props: IReportsConfigurationProps) => {
  const intl = useIntl();

  useEffect(() => {
    props.loadReportsTemplates();
    props.loadReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const templates: ITemplate[] = useMemo(
    () =>
      props.templates?.data?.map((t) => ({
        name: t
      })),
    [props.templates]
  );

  const [displayedReports, setDisplayedReports] = useState<IReportInfo[]>();

  useEffect(() => {
    if (props.reports?.data) setDisplayedReports(props.reports?.data);
  }, [props.reports]);

  const handleDeleteReport = (report: IReportInfo) => {
    const url = UrlService.getApiUrl(config.api.reports.deleteUrl, [
      {
        name: "datasetId",
        value: report.DatasetId
      }
    ]);

    EndpointService.delete(
      url,
      () => {
        setDisplayedReports(
          displayedReports?.filter((r) => r.DatasetId !== report.DatasetId)
        );
      },
      (request) => {
        if (request.status === 400) {
          const modelState = request.responseJSON.ModelState;
          let errorMessage = "";
          if (modelState.hasOwnProperty("Delete")) {
            errorMessage = modelState.Delete[0];
            notifications.error({
              message: intl.formatMessage(
                {
                  defaultMessage: "Delete {name} failed.",
                  id: `settings_main.data_configuration.pbix_upload_error.${errorMessage}`
                },
                { name: report.Name }
              )
            });
          }
        } else
          notifications.error({
            message: intl.formatMessage(
              {
                defaultMessage: "Delete {name} failed.",
                id: `settings_main.data_configuration.pbix_upload_error`
              },
              { name: report.Name }
            )
          });
      }
    );
  };

  const reports: IReport[] = useMemo(() => {
    return displayedReports?.map((r) => ({
      name: r.Name,
      type: r.Type,
      id: r.DatasetId
    }));
  }, [displayedReports]);

  const templatesStatus = useMemo(() => {
    return props.templates?.status === Statuses.Loading
      ? "loading"
      : props.templates?.status === Statuses.Failed
      ? "failed"
      : props.templates?.status === Statuses.Succeeded
      ? "succeeded"
      : null;
  }, [props.templates]);

  const reportsStatus = useMemo(() => {
    return props.reports?.status === Statuses.Loading
      ? "loading"
      : props.reports?.status === Statuses.Failed
      ? "failed"
      : props.reports?.status === Statuses.Succeeded
      ? "succeeded"
      : null;
  }, [props.reports]);

  const getReportName = useCallback((filename: string) => {
    const fullName = filename;
    let nameParts = filename.split(".");
    const prefix = nameParts[0];
    nameParts = nameParts.filter(
      (p, i) => i !== 0 && i !== nameParts.length - 1
    );
    let name = nameParts.join(".");
    const result: IReportName = {
      fullName: fullName,
      prefix: prefix,
      name: name
    };

    return result;
  }, []);

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handleChange = useCallback(
    (info: UploadChangeParam<UploadFile<any>>) => {
      const endFileList = info.fileList.filter(
        (f) =>
          !displayedReports
            .map((r) => r.Name)
            .includes(getReportName(f.name).name)
      );

      setFileList(endFileList);
    },
    [displayedReports, getReportName]
  );

  return (
    <PowerBiReports
      templatesStatus={templatesStatus}
      templates={templates}
      reportsStatus={reportsStatus}
      reports={reports}
      translations={{
        title: intl.formatMessage({
          defaultMessage: "Power BI Reports",
          id: "app_menu.settings.reports_upload"
        }),
        directQuery: intl.formatMessage({
          defaultMessage:
            "To upload a Power BI report the file must be Direct Query and match following naming convention.",
          id: "settings_main.reports_configuration.direct_query_only"
        }),
        dashboard: intl.formatMessage({
          defaultMessage: "Dashboard",
          id: "app_menu.homepage"
        }),
        nameVariable: intl.formatMessage({
          defaultMessage: "Name",
          id: "settings_main.reports_configuration.template_name"
        }),
        asset: intl.formatMessage({
          defaultMessage: "Asset",
          id: "settings_main.reports_configuration.asset_label"
        }),
        assetType: intl.formatMessage({
          defaultMessage: "Asset Type",
          id: "asset.nameplate.asset_type"
        }),
        legendAssetType: intl.formatMessage({
          defaultMessage: "as in asset nameplate",
          id: "settings_main.reports_configuration.legend_assettype"
        }),
        legendName: intl.formatMessage({
          defaultMessage: "name must be different then in standard reports",
          id: "settings_main.reports_configuration.legend_name"
        }),
        templates: intl.formatMessage({
          defaultMessage: "Templates",
          id: "settings_main.data_configuration.templates"
        }),
        reportsFailed: props.reports?.message,
        templatesFailed: props.templates?.message,
        dragAndDrop: intl.formatMessage({
          defaultMessage: "Drag and drop a report",
          id: "settings_main.reports_configuration.drag_and_drop"
        }),
        or: intl.formatMessage({
          defaultMessage: "OR",
          id: "settings_main.reports_configuration.or"
        }),
        clickHere: intl.formatMessage({
          defaultMessage: "Click here to upload it",
          id: "settings_main.reports_configuration.click_here"
        })
      }}
      fileList={fileList}
      onChange={handleChange}
      downloadTemplate={(name: string) => {
        saveAs(
          UrlService.getApiUrl(config.api.powerbiembed.template, {
            name
          })
        );
      }}
      deleteReport={(name: string, type: string, id: string) => {
        notifications.confirm({
          title: intl.formatMessage({
            defaultMessage: "Are you sure delete this report?",
            id: "settings_main.data_configuration.pbix_delete_question"
          }),
          onOk: () => {
            handleDeleteReport({
              Name: name,
              DatasetId: id,
              Type: type
            });
          }
        });
      }}
      upload={(
        file: File,
        fileName: string,
        onSuccess: (body: Object) => void,
        onError: (event: Error, body?: Object) => void,
        onProgress: (event: { percent: number }) => void
      ) => {
        const url = UrlService.getApiUrl(config.api.reports.importUrl);

        const reader = new FileReader();

        reader.onerror = (e: Event) => {
          notifications.error({
            message: intl.formatMessage(
              {
                defaultMessage: "Uploading {name} failed.",
                id: "settings_main.data_configuration.file_upload_error"
              },
              { fileName }
            )
          });
        };

        reader.onload = function (e: Event) {
          const base64FileData = (e.target as any).result
            ? (e.target as any).result.split("base64,")[1]
            : "";

          const reportToSend = {
            FileByteData: base64FileData,
            ReportName: file.name
          };

          EndpointService.put(
            url,
            (request, data: IReportInfo) => {
              const reportName = getReportName(file.name);

              if (reportName.prefix === "Dashboard")
                ReportsStore.loadPbiReports();

              setDisplayedReports((prevState) => [
                ...prevState.filter((r) => r.Name !== reportName.name),
                {
                  Name: reportName.name,
                  Type: data.Type,
                  DatasetId: data.DatasetId
                }
              ]);
              onSuccess({ jsonToSend: reportToSend });
            },
            (request) => {
              onError({
                name: request.responseText,
                message: ""
              });

              if (request.status === 400) {
                const modelState = request.responseJSON;
                let errorMessage = "";

                if (modelState.hasOwnProperty("FileByteData")) {
                  errorMessage = modelState.FileByteData[0];
                } else if (modelState.hasOwnProperty("ReportName")) {
                  errorMessage = modelState.ReportName[0];
                }
                if (errorMessage) {
                  notifications.error({
                    message: intl.formatMessage(
                      {
                        defaultMessage: "Uploading {name} failed.",
                        id: `settings_main.data_configuration.pbix_upload_error.${errorMessage}`
                      },
                      {
                        name: file.name
                      }
                    )
                  });
                }
              } else {
                notifications.error({
                  message: intl.formatMessage(
                    {
                      defaultMessage: "Uploading {name} failed.",
                      id: "settings_main.data_configuration.file_upload_error"
                    },
                    { name: file.name }
                  )
                });
              }
            },
            (percent) => onProgress({ percent }),
            reportToSend,
            "application/json; charset=UTF-8"
          );
        };

        reader.readAsDataURL(file);
      }}
      customProcessing={<Processing className="spinner small light" />}
    />
  );
};

const mapStateToProps = (state: IState): IReportsConfigurationDataProps => ({
  templates: getReportsTemplatesSelector(state),
  reports: getReportsSelector(state)
});

const mapDispatchToProps = (
  dispatch: AppDispatch
): IReportsConfigurationActions => {
  return {
    loadReportsTemplates: () => dispatch(loadReportsTemplates()),
    removeReportsTemplates: () => dispatch(removeReportsTemplates()),
    loadReports: () => dispatch(loadReports()),
    removeReports: () => dispatch(removeReports())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportsConfiguration);
