// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Col, Row } from "antd";
import Container from "common/Container";
import { useAppNavigate } from "core/app/components/RouterProvider";
import { UserRoles } from "core/app/components/auth/Authorization";
import { getUserSelector } from "core/app/reducers/AppReducer";
import { IUser } from "core/app/reducers/settings/UserReducer";
import AuthorizationService from "core/app/services/AuthorizationService";
import React, { useMemo } from "react";
import { useAppSelector } from "store";
import AssetRisk from "./AssetRisk";
import AssetsDistribution from "./AssetsDistribution";
import RiskMap from "./RiskMap";
import WatchList from "./WatchList";
import Analytics from "./analytics/Analytics";
import KeyRiskChanges from "./keyriskchanges/components/keyriskchanges";
import OpenIssues from "./openissues";

const MainDashboard: React.FunctionComponent = () => {
  const user = useAppSelector(getUserSelector);
  const navigate = useAppNavigate();

  const userHasAccess = useMemo(
    () =>
      AuthorizationService.isAuthorized(user.data as IUser, [
        UserRoles.Administrator,
        UserRoles.Engineer
      ]),
    [user.data]
  );

  return (
    <Container size="lg">
      <Row className="homepage">
        <Col span={10}>
          <AssetRisk />
          <RiskMap />
        </Col>
        <Col span={6}>
          <OpenIssues />
          <KeyRiskChanges />
        </Col>
        <Col span={8}>
          {userHasAccess && <Analytics navigate={navigate} />}
          <WatchList />
          <AssetsDistribution />
        </Col>
      </Row>
    </Container>
  );
};

export default MainDashboard;
