// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import { Tooltip } from "antd";
import ButtonWithIcon from "common/button/ButtonWithIcon";
import AssetModal from "features/ConfigurationTool";
import useShowTextForNameplateIcon from "features/detailpage/components/nameplateIcons/hooks/useShowTextForNameplateIcon";
import { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";

interface IEditAssetButtonProps {
  assetId?: string;
}

const EditAssetButton = ({ assetId }: IEditAssetButtonProps) => {
  const [assetModalVisible, setAssetModalVisible] = useState<boolean>(false);
  const handleModalCancel = useCallback(() => {
    setAssetModalVisible(false);
  }, []);
  const showTextForIcon = useShowTextForNameplateIcon();

  return (
    <>
      <ButtonWithIcon
        type="link"
        onClick={() => setAssetModalVisible(true)}
        icon={
          showTextForIcon ? (
            <Icon name="edit" size="sm" />
          ) : (
            <Tooltip
              placement="top"
              title={
                <FormattedMessage
                  defaultMessage="Edit"
                  id="configuration_tool.action.edit"
                />
              }
            >
              <span>
                <Icon name="edit" size="sm" />
              </span>
            </Tooltip>
          )
        }
        dataQa="config-tool-edit-button"
      >
        {showTextForIcon && (
          <FormattedMessage
            defaultMessage="Edit"
            id="configuration_tool.action.edit"
          />
        )}
      </ButtonWithIcon>
      <AssetModal
        assetId={assetId}
        visible={assetModalVisible}
        onCancel={handleModalCancel}
      />
    </>
  );
};

export default EditAssetButton;
