// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import removeData from "core/data/actions/removeData";
import AssetRiskTypes from "models/AssetRiskTypes";
import { dataKey } from "./loadAssetRiskCounts";

const removeAssetRiskCounts = (type: AssetRiskTypes, id?: string[]) =>
  removeData(dataKey(type, id));

export default removeAssetRiskCounts;
