// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import FieldTypes from "../../../models/FieldTypes";
import IConfiguration from "../models/IConfiguration";
import dataTypeOptions from "./dataTypeOptions";
import partitionModbusOptions from "./partitionModbusOptions";

export const connectedDevicesConfiguration: IConfiguration = {
  forms: [
    {
      formKey: "connectedDevices",
      enterDataWithCurrentDate: true,
      sections: [
        {
          sectionName: {
            defaultMessage: "General information",
            id: "configuration_tool.tab.connected_devices_general_information"
          },
          sectionType: "horizontalList",
          span: { xs: 24, sm: 12, lg: 8 },
          fields: [
            {
              fieldName: {
                defaultMessage: "Name",
                id: "configuration_tool.tab.connected_devices_general_information_name"
              },
              fieldKey: "DeviceName",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              rules: [{ required: true }],
              inputType: "String",
              validationRules: {
                validations: ["string", "required"]
              }
            },
            {
              fieldName: {
                defaultMessage: "Device Host",
                id: "configuration_tool.tab.connected_devices_general_information_device_host"
              },
              fieldKey: "DeviceHost",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              rules: [{ required: true }],
              inputType: "String",
              validationRules: {
                validations: ["string", "required"]
              }
            },
            {
              fieldName: {
                defaultMessage: "Device Port",
                id: "configuration_tool.tab.connected_devices_general_information_device_port"
              },
              fieldKey: "DevicePort",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                { required: true },
                {
                  type: "number",
                  min: 0,
                  max: 10000
                }
              ],
              inputType: "Decimal",
              validationRules: {
                validations: ["number", "required", "min", "max"],
                params: {
                  required: "",
                  min: 0,
                  max: 10000
                }
              }
            },
            {
              fieldName: {
                defaultMessage: "Unit ID",
                id: "configuration_tool.tab.connected_devices_general_information_register_unit_id"
              },
              fieldKey: "UnitId",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                { required: true },
                {
                  type: "number",
                  min: 0,
                  max: 248
                }
              ],
              inputType: "Decimal",
              validationRules: {
                validations: ["number", "required", "min", "max"],
                params: {
                  min: 0,
                  max: 248
                }
              }
            },

            {
              fieldName: {
                defaultMessage: "Device Type",
                id: "configuration_tool.tab.connected_devices_general_information_device_type"
              },
              fieldKey: "DeviceType",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              rules: [{ required: true }],
              inputType: "String",
              validationRules: {
                validations: ["string", "required"]
              }
            },

            {
              fieldName: {
                defaultMessage: "Register Address Offset",
                id: "configuration_tool.tab.connected_devices_general_information_register_address_offset"
              },
              fieldKey: "RegisterAddressOffset",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                { required: true },
                {
                  type: "number",
                  min: 0,
                  max: 10000
                }
              ],
              inputType: "Decimal",
              validationRules: {
                validations: ["number", "required", "min", "max"],
                params: {
                  min: 0,
                  max: 10000
                }
              }
            }
          ]
        },
        {
          sectionName: {
            defaultMessage: "Parameter mapping",
            id: "configuration_tool.tab.connected_devices_parameter_mapping"
          },
          sectionType: "grid",
          span: { xs: 24, sm: 12, lg: 8 },
          fields: [
            {
              fieldName: {
                defaultMessage: "Display Name",
                id: "configuration_tool.tab.connected_devices_parameter_mapping_display_name"
              },
              fieldKey: "DisplayName",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              rules: [
                {
                  type: "string",
                  min: 0,
                  max: 128
                }
              ],
              validationRules: {
                validations: ["string", "max", "nullable"],
                params: {
                  max: 128
                }
              },
              inputType: "String",
              order: 1
            },
            {
              fieldName: {
                defaultMessage: "Data Type",
                id: "configuration_tool.tab.connected_devices_parameter_mapping_data_type"
              },
              fieldKey: "ParamType",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              rules: [{ required: true }],
              validationRules: {
                validations: ["mixed", "oneOf", "required", "nullable"],
                params: {
                  oneOf: dataTypeOptions.map(({ value }) => value)
                }
              },
              inputType: "list",
              listValues: dataTypeOptions,
              order: 2
            },
            {
              fieldName: {
                defaultMessage: "APM Parameter Name",
                id: "configuration_tool.tab.connected_devices_parameter_mapping_apm_parameter_name"
              },
              fieldKey: "ParamName",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              rules: [{ required: true }],
              validationRules: {
                validations: ["string", "required", "nullable"]
              },
              inputType: "autoComplete",
              order: 3
            },
            {
              fieldName: {
                defaultMessage: "Partition Modbus",
                id: "configuration_tool.tab.connected_devices_parameter_mapping_partition_modbus"
              },
              fieldKey: "RegisterFunctionCode",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: partitionModbusOptions,
              rules: [{ required: true }],
              validationRules: {
                validations: ["mixed", "oneOf", "required", "nullable"],
                params: {
                  oneOf: partitionModbusOptions.map(({ value }) => value)
                }
              },
              order: 4
            },
            {
              fieldName: {
                defaultMessage: "Register Address",
                id: "configuration_tool.tab.connected_devices_parameter_mapping_register_address"
              },
              fieldKey: "RegisterAddress",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                { required: true },
                { type: "number", min: 0, max: 65536 }
              ],
              validationRules: {
                validations: ["number", "required", "min", "max"],
                params: {
                  min: 0,
                  max: 65536
                }
              },
              inputType: "Decimal",
              order: 5
            },
            {
              fieldName: {
                defaultMessage: "Slope Factor",
                id: "configuration_tool.tab.connected_devices_parameter_mapping_slope_factor"
              },
              fieldKey: "ParamSlopeFactor",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              validationRules: {
                validations: ["number", "nullable"]
              },
              order: 6
            },
            {
              fieldName: {
                defaultMessage: "Offset Factor",
                id: "configuration_tool.tab.connected_devices_parameter_mapping_offset_factor"
              },
              fieldKey: "ParamOffsetFactor",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              validationRules: {
                validations: ["number", "nullable"]
              },
              order: 7
            }
          ]
        }
      ]
    }
  ]
};
