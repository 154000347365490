// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { SelectedFilters } from "common/FilterBar";
import { IDataEndpoint, IRow } from "common/datagrid/DataGrid";
import useIssueStatusTranslation from "common/issueStatus/hooks/useIssueStatusTranslation";
import { IssueStatuses } from "common/issueStatus/models/IssueStatuses";
import {
  isSuccess,
  isWarning
} from "common/issueStatusUpdate/utils/getResponseStatus";
import { UserRoles } from "core/app/components/auth/Authorization";
import { IUser } from "core/app/reducers/settings/UserReducer";
import AuthorizationService from "core/app/services/AuthorizationService";
import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import bulkUpdateIssuesStatusAction from "features/issues/actions/bulkUpdateIssuesStatusAction";
import closeIssueAction from "features/issues/actions/closeIssueAction";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { config } from "utils/AppConfig";
import { isUserAgreedSkipLimitConfirmationModal } from "../components/IssuesLimitConfirmationModal";
import useIssuesGridActionConfig, {
  IGridActionCallbacks
} from "./useIssueGridActionsConfig";
import useIssuesGridColumnsConfig, {
  IOnMaintenancePriorityClickOptions,
  IOnNumberOfActionsClickOptions
} from "./useIssuesGridColumnsConfig";

interface IUseGridOptions {
  user: IUser;
  filters: SelectedFilters;
  checkedRowsLimit: number;
  onMaintenancePriorityClick?: (
    options: IOnMaintenancePriorityClickOptions
  ) => void;
  onMaintenancePriorityRequest?: () => void;
  onNumberOfActionsClick?: (options: IOnNumberOfActionsClickOptions) => void;
  setIsLimitModalVisible: (isVisible: boolean) => void;
}

interface IIssueStatusChangeModalData {
  visible: boolean;
  assetIssueId?: string;
  currentStatus?: IssueStatuses;
  title?: string;
  gridActionCallbacks?: IGridActionCallbacks;
}

const useIssueGrid = ({
  user,
  filters,
  checkedRowsLimit,
  onMaintenancePriorityClick,
  onMaintenancePriorityRequest,
  onNumberOfActionsClick,
  setIsLimitModalVisible
}: IUseGridOptions) => {
  const issueStatusTranslation = useIssueStatusTranslation();
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const [rowsTotal, setRowsTotal] = useState<number>(0);
  const [checkedAll, setCheckedAll] = useState<boolean | null>(false);
  const [checkedRows, setCheckedRows] = useState<IRow[]>([]);
  const [rowsUnderLimit, setRowsUnderLimit] = useState<IRow[]>([]);
  const [shouldUpdateGrid, setShouldUpdateGrid] = useState<boolean>(false);
  const [modalData, setModalData] = useState<IIssueStatusChangeModalData>({
    visible: false
  });

  const intl = useIntl();

  const showCloseIssueModal = useCallback(
    (
      assetIssueId: string,
      currentStatus: IssueStatuses,
      title: string,
      gridActionCallbacks: IGridActionCallbacks
    ) => {
      setModalData({
        visible: true,
        assetIssueId,
        currentStatus,
        title,
        gridActionCallbacks
      });
    },
    []
  );

  const hideCloseIssueModal = useCallback(() => {
    setModalData({ visible: false });
  }, []);

  const handleChangeIssueConfirm = useCallback(
    async (status: IssueStatuses, reason: string) => {
      const issuesToUpdate = checkedRows.reduce((acc, { data }) => {
        acc[data.AssetIssueId] = data.Status;
        return acc;
      }, {} as { [key: string]: IssueStatuses });

      await bulkUpdateIssuesStatusAction(
        {
          status,
          issuesToUpdate,
          reason
        },
        issueStatusTranslation,
        intl
      ).then((responseData) => {
        if (isSuccess(responseData)) {
          setCheckedRows([]);
          setCheckedAll(false);
        }

        if (isWarning(responseData)) {
          setCheckedAll(false);
          const failedIssuesIds = Object.values(responseData.Errors).flat();

          const isFailedIssue = (checkedIssue: IRow) =>
            !failedIssuesIds.every(
              (failedIssueId: string) =>
                failedIssueId !== checkedIssue.data.AssetIssueId
            );

          const failedIssues = checkedRows.filter((checkedIssue) =>
            isFailedIssue(checkedIssue)
          );
          setCheckedRows(failedIssues);
        }

        setShouldUpdateGrid(true);
      });
    },
    [checkedRows, intl, issueStatusTranslation]
  );

  const handleCloseIssueConfirm = useCallback(
    async (status: IssueStatuses, reason: string) => {
      setCheckedRows([]);
      setCheckedAll(false);
      await closeIssueAction(
        modalData?.assetIssueId,
        modalData?.currentStatus,
        reason,
        issueStatusTranslation,
        intl
      ).then(() => {
        hideCloseIssueModal();
        modalData?.gridActionCallbacks?.onSucceeded();
      });
    },
    [
      hideCloseIssueModal,
      intl,
      issueStatusTranslation,
      modalData?.assetIssueId,
      modalData?.currentStatus,
      modalData?.gridActionCallbacks
    ]
  );

  const columns = useIssuesGridColumnsConfig({
    onMaintenancePriorityClick,
    onMaintenancePriorityRequest,
    onNumberOfActionsClick
  });

  const isReadOnlyMode = useMemo(() => {
    return !AuthorizationService.isAuthorized(user, [
      UserRoles.Administrator,
      UserRoles.Engineer,
      UserRoles.LimitedEngineer
    ]);
  }, [user]);

  const defaultActions = useIssuesGridActionConfig(showCloseIssueModal);

  const actions = useMemo(
    () => (isReadOnlyMode ? [] : defaultActions),
    [defaultActions, isReadOnlyMode]
  );

  const getIssuesCount = useCallback(() => {
    EndpointService.postJson(
      UrlService.getApiUrl(config.api.watchlist.issuesCountByRangeUrl),
      (_request, data: { Value: number }) => setRowsTotal(data?.Value || 0),
      (err) => {
        console.error("Cannot get the issues total number", err);
      },
      {
        search: filters.search,
        filters: filters.selects
      }
    );
  }, [filters.search, filters.selects]);

  const dataEndpoint: IDataEndpoint = useMemo(
    () => ({
      url: UrlService.getApiUrl(config.api.watchlist.issuesRangeUrl),
      type: "POST",
      content: {
        search: filters.search,
        filters: filters.selects
      },
      onDataLoaded: (_total, _newRows, loadedRows, isSortOrFilter) => {
        if (isSortOrFilter) {
          setCheckedRows([]);
          setCheckedAll(false);
          return;
        }

        // check if checked rows are still in the grid after data load
        const actualCheckedRows = loadedRows.filter((row) =>
          checkedRows.some(
            (checkedRow) =>
              checkedRow.data.AssetIssueId === row.data.AssetIssueId
          )
        );

        setCheckedRows(actualCheckedRows);

        if (checkedAll) {
          setCheckedAll(null);
        }

        if (isFirstLoad) {
          // prevent the second load of IssuesCount after the first load
          setIsFirstLoad(false);
          return;
        }

        getIssuesCount();
      }
    }),
    [
      checkedAll,
      checkedRows,
      filters.search,
      filters.selects,
      getIssuesCount,
      isFirstLoad
    ]
  );

  const isCheckboxHidden = useCallback(
    ({ data }: IRow) => data.Status === IssueStatuses.Closed,
    []
  );

  const getCheckableRows = useCallback(
    (rows: IRow[]) => rows?.filter((row) => !isCheckboxHidden(row)) || [],
    [isCheckboxHidden]
  );

  const getIsCheckAllPossible = useCallback(
    (loadedRows: IRow[]) => {
      const checkableRows = getCheckableRows(loadedRows);

      return checkableRows.length > 0;
    },
    [getCheckableRows]
  );

  const onCheckboxChange = useCallback(
    (e: CheckboxChangeEvent, row: IRow, loadedRows: IRow[]) => {
      switch (true) {
        // check if we uncheck check all checkbox and we have more than one row checked
        case checkedAll === true && checkedRows.length > 1:
          setCheckedAll(null);
          break;
        // check if we faced the limit of checked rows and check the next one
        case checkedAll === false &&
          e.target.checked &&
          rowsTotal > checkedRowsLimit &&
          checkedRows.length === checkedRowsLimit - 1:
          setCheckedAll(null);
          break;
        // check if only one row is checked and we uncheck it so we can uncheck check all checkbox
        case (checkedAll === null || checkedAll === true) &&
          checkedRows.length === 1 &&
          !e.target.checked:
          setCheckedAll(false);
          break;
        // check if all rows are checked except one so we can check check all checkbox
        case (checkedAll === false || checkedAll === null) &&
          checkedRows.length === getCheckableRows(loadedRows).length - 1 &&
          e.target.checked:
          setCheckedAll(true);
          break;
      }

      setCheckedRows(
        e.target.checked
          ? [...checkedRows, row]
          : checkedRows.filter(
              ({ data }) => data.AssetIssueId !== row.data.AssetIssueId
            )
      );
    },
    [checkedAll, checkedRows, getCheckableRows, rowsTotal, checkedRowsLimit]
  );

  const shouldUpdateGridData = useCallback(() => {
    if (!shouldUpdateGrid) {
      return false;
    }

    setShouldUpdateGrid(false);
    return true;
  }, [shouldUpdateGrid]);

  const setCheckedRowsUnderLimit = useCallback(
    (rows?: IRow[]) => {
      setCheckedAll(null);
      setCheckedRows(rows || rowsUnderLimit);
      setIsLimitModalVisible(false);
    },
    [rowsUnderLimit, setIsLimitModalVisible]
  );

  const getRowsUnderLimit = useCallback(
    (loadedRows: IRow[]) => {
      let rowsToCheck = checkedRowsLimit;

      // filter the rows which could be checked under the limit
      return loadedRows.filter((row) => {
        if (isCheckboxHidden(row)) {
          return false;
        }

        return rowsToCheck-- > 0;
      });
    },
    [isCheckboxHidden, checkedRowsLimit]
  );

  const handleCheckAllChange = useCallback(
    (e: CheckboxChangeEvent, loadedRows: IRow[], isIndeterminate: boolean) => {
      if (isIndeterminate) {
        setCheckedRows([]);
        setCheckedAll(false);

        return;
      }

      const rowsUnderLimit = getRowsUnderLimit(loadedRows);

      // will be used if user agrees to check all possible rows in Limit Modal
      setRowsUnderLimit(rowsUnderLimit);

      // user faced the limit of checked rows and tries to check all
      if (
        e.target.checked &&
        rowsUnderLimit.length < getCheckableRows(loadedRows).length
      ) {
        if (isUserAgreedSkipLimitConfirmationModal()) {
          setCheckedRowsUnderLimit(rowsUnderLimit);
        } else {
          setIsLimitModalVisible(true);
        }

        return;
      }

      setCheckedRows(e.target.checked ? rowsUnderLimit : []);
      setCheckedAll(e.target.checked);
    },
    [
      getCheckableRows,
      getRowsUnderLimit,
      setIsLimitModalVisible,
      setCheckedRowsUnderLimit
    ]
  );

  const isCheckboxDisabled = useCallback(
    (row: IRow, checkedRows: IRow[]) => {
      if (
        checkedRows.find(
          ({ data }) => data.AssetIssueId === row.data.AssetIssueId
        )
      ) {
        return false;
      }

      return checkedRows.length >= checkedRowsLimit;
    },
    [checkedRowsLimit]
  );

  const disabledTooltip = useMemo(
    () =>
      intl.formatMessage(
        {
          id: "issues_page.issue.checkbox.disabled_tooltip",
          defaultMessage: "Maximum number of issues to select is {limit}"
        },
        {
          limit: checkedRowsLimit
        }
      ),
    [intl, checkedRowsLimit]
  );

  const getId = useCallback((row: IRow) => row.data.AssetIssueId, []);

  const getAreActionsAvailable = useCallback(
    (row: IRow) => row.data.Status !== IssueStatuses.Closed,
    []
  );

  useEffect(getIssuesCount, [getIssuesCount, filters.search, filters.selects]);

  return {
    dataEndpoint,
    rowsTotal,
    actions,
    columns,
    checkedRows,
    checkbox: {
      checkedRows,
      checkedAll,
      disabledTooltip,
      isCheckboxHidden,
      isCheckboxDisabled,
      getIsCheckAllPossible,
      getId,
      setCheckedRows,
      onCheckboxChange,
      handleCheckAllChange
    },
    hideCloseIssueModal,
    modalVisible: modalData.visible,
    modalTitle: modalData?.title,
    shouldUpdateGridData,
    handleCloseIssueConfirm,
    handleChangeIssueConfirm,
    setCheckedRowsUnderLimit,
    getAreActionsAvailable
  };
};

export default useIssueGrid;
