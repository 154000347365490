// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { IAjaxAction } from "reducers/Index";
import { AppDispatch } from "store";
import { config } from "utils/AppConfig";
import IConductanceData from "../models/IConductanceData";

export enum Actions {
  DetailPage_BatterConductance_SetConductanceData = "DetailPage_BushingVisualization_SetOnlineBushingData"
}

export const setConductanceDataAction = (
  request: JQueryXHR,
  data?: IConductanceData
): IAjaxAction<IConductanceData> => ({
  type: Actions.DetailPage_BatterConductance_SetConductanceData,
  request,
  data
});

export const loadConductanceData = (
  dispatch: AppDispatch,
  assetId?: string
): Promise<void> => {
  return new Promise((resolve, reject) => {
    const url = UrlService.getApiUrl(
      config.api.detailPage.batteryConductanceUrl,
      { assetId }
    );

    const request = EndpointService.getJson<IConductanceData>(
      url,
      (request, data) => {
        dispatch(setConductanceDataAction(request, data));
        resolve();
      },
      (request) => {
        dispatch(setConductanceDataAction(request));
        reject();
      }
    );

    dispatch(setConductanceDataAction(request));
  });
};
