// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import FormContainer from "common/form/containers/FormContainer";
import Processing from "components/common/Processing";
import {
  WidgetErrorMessage,
  WidgetNoDataMessage
} from "components/common/widget/Widget";
import Data, { Statuses } from "core/data/models/Data";
import ActiveDirectoryEmailStatusContainer from "features/notifications/containers/ActiveDirectoryEmailStatusContainer";
import AssetTypesContainer from "features/notifications/containers/AssetTypesContainer";
import DegradedToggleViewContainer from "features/notifications/containers/DegradedToggleViewContainer";
import NotificationsNavBarContainer from "features/notifications/containers/NotificationsNavbarContainer";
import OrganizationsContainer from "features/notifications/containers/OrganizationsContainer";
import INotificationsModelInput from "features/notifications/models/INotificationsModelInput";
import { isEmpty } from "lodash";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import "./Notifications.less";

const notificationsFormName = "Notifications_Form";

const NotificationsComponent = () => (
  <FormContainer name={notificationsFormName} showActionButtons={false}>
    <div className="notifications">
      <NotificationsNavBarContainer />
      <div>
        <div className="container">
          <div className="bootstrap-row">
            <div className="col col-md-8">
              <DegradedToggleViewContainer />
            </div>
            <div className="col col-md-8">
              <ActiveDirectoryEmailStatusContainer />
            </div>
          </div>
          <div className="bootstrap-row tables-container">
            <div className="container">
              <div className="bootstrap-row">
                <div className="col col-md-14 tables-container__header">
                  <FormattedMessage
                    id="notifications.select_prefer_organizations_and_asset_types"
                    defaultMessage="For selected organizations and asset types"
                  />
                </div>
              </div>
              <div className="tables bootstrap-row">
                <div className="col">
                  <OrganizationsContainer />
                </div>
                <div className="col">
                  <AssetTypesContainer />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </FormContainer>
);

interface INotifications {
  modelInput: Data<INotificationsModelInput>;
}

export default ({ modelInput }: INotifications) => {
  switch (modelInput.status) {
    case Statuses.Loading:
      return <Processing />;
    case Statuses.Succeeded:
      return isEmpty(modelInput.data) ? (
        <WidgetNoDataMessage />
      ) : (
        <NotificationsComponent />
      );
    case Statuses.NotFound:
      return <WidgetNoDataMessage />;
    case Statuses.Failed:
      return <WidgetErrorMessage />;
    default:
      return (
        <div className="tile">
          <div className="tile-content empty" />
        </div>
      );
  }
};
