// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import {
  GetFilter,
  ISelectedFilters,
  OnFilter
} from "@pg/common/build/components/FilterPanel";
import { isBoolean } from "lodash";
import { useCallback } from "react";
import { MessageDescriptor, useIntl } from "react-intl";
import ApiSelectedFilterRange from "../models/ISelectedFilterRange";
import ApiSelectedFilters from "../models/ISelectedFilters";
import ApiSelectedFilterSelect from "../models/ISelectedFilterSelect";
import UseFilter from "../models/UseFilter";
import loadFilter from "../utils/loadFilter";
import mapToOption from "../utils/mapToOption";

const mapToApiSelectedFilters = ({
  search,
  selects,
  ranges
}: ISelectedFilters): ApiSelectedFilters => {
  const selectFilters = selects.map((f) => {
    const apiFilter: ApiSelectedFilterSelect = {
      Id: f.id,
      Options: f.options?.map((o) => mapToOption(o))
    };

    return apiFilter;
  });

  const rangeFilters = ranges.map((f) => {
    const apiFilter: ApiSelectedFilterRange = {
      Id: f.id,
      RangeMax: f.rangeMax,
      RangeMin: f.rangeMin
    };

    return apiFilter;
  });

  return {
    search: search?.value,
    selects: selectFilters,
    ranges: rangeFilters
  };
};

const useFilterBar = (onFilter: (filters: ApiSelectedFilters) => void) => {
  const intl = useIntl();

  const formatPlaceholder = useCallback(
    (message: MessageDescriptor, selectedItems: string[][]) => {
      if (selectedItems.length === 0) return intl.formatMessage(message);

      if (selectedItems.length === 1)
        return selectedItems[0]
          .map(
            (i) =>
              i ||
              intl.formatMessage({
                defaultMessage: "Unknown",
                id: "filter_panel.filters.select.placeholder.unknown"
              })
          )
          .join(" / ");

      return intl.formatMessage(
        {
          defaultMessage: "{numberOfItems} selected",
          id: "filter_panel.filters.select.placeholder.selected"
        },
        { numberOfItems: selectedItems.length }
      );
    },
    [intl]
  );

  const getFilter = useCallback<GetFilter>(
    (filterName) => {
      return loadFilter(filterName, intl);
    },
    [intl]
  );

  const handleFilter = useCallback<OnFilter>(
    (filters) => {
      if (onFilter) onFilter(mapToApiSelectedFilters(filters));
    },

    [onFilter]
  );

  const getHidden = useCallback(
    (use: UseFilter) => (!isBoolean(use) ? use?.hidden : undefined),
    []
  );

  const getHideOnStatusPanel = useCallback(
    (use: UseFilter) => (!isBoolean(use) ? use?.hideOnStatusPanel : undefined),
    []
  );

  const getDisabled = useCallback(
    (use: UseFilter) => (!isBoolean(use) ? use?.disabled : undefined),
    []
  );

  const getDisabledTooltip = useCallback(
    (use: UseFilter) => (!isBoolean(use) ? use?.disabledTooltip : undefined),
    []
  );

  return {
    formatPlaceholder,
    getFilter,
    getHidden,
    getHideOnStatusPanel,
    getDisabled,
    getDisabledTooltip,
    handleFilter
  };
};

export default useFilterBar;
