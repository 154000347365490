// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { notifications } from "@pg/common";
import { IssueStatuses } from "common/issueStatus/models/IssueStatuses";
import { IntlShape } from "react-intl";
import IUpdateIssueStatusResponse from "../models/IUpdateIssueStatusResponse";
import {
  getErrorMessage,
  getSuccessMessage,
  getWarningMessage
} from "./getMessages";
import { isSuccess, isWarning } from "./getResponseStatus";

export const showSuccessNotifications = (
  response: IUpdateIssueStatusResponse,
  issueCount: number,
  status: IssueStatuses,
  issueStatusTranslation: { [key in IssueStatuses]: string },
  intl: IntlShape
) => {
  if (isSuccess(response))
    notifications.success({
      message: getSuccessMessage(
        issueCount,
        issueStatusTranslation[status],
        intl
      )
    });
  else if (isWarning(response))
    notifications.warning({
      message: getWarningMessage(
        response,
        issueStatusTranslation[status],
        intl
      ),
      duration: null
    });
  else
    notifications.error({
      message: getErrorMessage(response, issueCount, intl)
    });
};

export const showErrorNotifications = (
  response: IUpdateIssueStatusResponse,
  issueCount: number,
  intl: IntlShape
) => {
  notifications.error({
    message: getErrorMessage(response, issueCount, intl)
  });
};
