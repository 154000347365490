// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import React from "react";
import { FormattedMessage } from "react-intl";

interface IMessageRowProps {
  onReloadClick: () => void;
}

const MessageRow = (props: IMessageRowProps) => {
  const { onReloadClick } = props;

  return (
    <div className="grid-row-container message-row">
      <div className="grid-row">
        <div>
          <Icon name="info" variant="outlined" />
        </div>
        <div>
          <a
            href="#/"
            onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
              e.preventDefault();
            }}
          >
            <span />
          </a>
          <FormattedMessage
            defaultMessage={
              "Data loading failed. Click {reload} to get new data."
            }
            id={"data_grid.skeleton.loading.failed"}
            values={{
              reload: (
                <a
                  href="#/"
                  onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                    e.preventDefault();
                    if (onReloadClick) onReloadClick();
                  }}
                >
                  <FormattedMessage
                    defaultMessage="reload"
                    id="data_grid.skeleton.loading.failed.reload"
                  />
                </a>
              )
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default MessageRow;
