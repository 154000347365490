// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Col, Row } from "antd";
import LifecycleStatus from "models/LifecycleStatus";
import DielectricContext from "../../../contexts/DielectricContext";
import { IModelDetails } from "../../DielectricTab/DielectricTab";
import SF6Parameters from "../../SF6Parameters";
import { SF6PressureTimedChart } from "../../SF6PressureChart";
import "./DielectricSF6Tab.less";

interface IDielectricSF6TabProps {
  assetId: string;
  lifecycleStatus: LifecycleStatus;
  model: IModelDetails;
}

const DielectricSF6Tab = ({
  assetId,
  lifecycleStatus,
  model
}: IDielectricSF6TabProps) => (
  <DielectricContext.Provider
    value={{
      assetId
    }}
  >
    <Row className="dielectric-parameters-chart-tab">
      <Col span={8}>
        <SF6Parameters assetId={assetId} lifecycleStatus={lifecycleStatus} />
      </Col>
      <Col span={16}>
        <SF6PressureTimedChart model={model} showAdjustToThresholdsSwitch />
      </Col>
    </Row>
  </DielectricContext.Provider>
);

export default DielectricSF6Tab;
