// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import {
  IChart,
  IChartGroup
} from "@apm/widgets/build/widgets/ParameterCharts";
import { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { colorBlue60, colorStatusOrange } from "styles/ColorVariables";
import { BushingReference } from "../../../models/BushingReference";
import IBushingOfflineDataResult from "../../../models/IBushingOfflineDataResult";
import getAxisXLine from "../../../utils/getAxisXLine";
import getHeaderLabel from "../../../utils/getHeaderLabel";
import getMinMaxDates from "../../../utils/getMinMaxDates";

const useBushingOfflineCharts = (
  assetId: string,
  chartsData: IBushingOfflineDataResult[],
  loadTransformerParameters: (assetId: string) => void
) => {
  const intl = useIntl();
  const bushings = useMemo<IChartGroup[]>(() => {
    if (!chartsData) return;

    const charts: IChart[] = chartsData.map((chartData) => {
      const powerFactorBushingParametersData =
        chartData.BushingParametersData.find((data) =>
          data.ParameterId.includes(BushingReference.PowerFactor)
        );
      const capacitanceBushingParametersData =
        chartData.BushingParametersData.find((data) =>
          data.ParameterId.includes(BushingReference.Capacitance)
        );
      const powerFactorBushingReferenceData =
        chartData.BushingReferenceData.find((data) =>
          data.ReferenceId.includes(BushingReference.PowerFactor)
        );
      const capacitanceBushingReferenceData =
        chartData.BushingReferenceData.find((data) =>
          data.ReferenceId.includes(BushingReference.Capacitance)
        );
      const { firstDate, lastDate } = getMinMaxDates(
        powerFactorBushingParametersData,
        capacitanceBushingParametersData
      );
      const manufacturer = chartData.BushingInfoData.Manufacturer;
      const model = chartData.BushingInfoData.Model;
      const installDate = chartData.BushingInfoData.InstallDate;
      const headerLabel = getHeaderLabel(
        manufacturer,
        model,
        installDate,
        intl
      );
      const optionalXGridLine = getAxisXLine(firstDate, lastDate, installDate);

      return {
        name: chartData.WindingDesignationId,
        displayName: chartData.WindingDesignationId,
        adjustY1SizeToPositiveValues: true,
        values: {
          default: {
            headerLabel,
            optionalXGridLine,
            regions: [
              {
                name: "PowerFactor",
                displayName: "Power Factor (%)",
                start: powerFactorBushingReferenceData?.Value,
                end: powerFactorBushingReferenceData?.Value,
                color: colorBlue60,
                seriesName: intl.formatMessage({
                  id: `detail_page.widgets.analytics.transformers.PowerFactor`,
                  defaultMessage: "PowerFactor"
                })
              },
              {
                name: "Capacitance",
                displayName: "Capacitance (pF)",
                start: capacitanceBushingReferenceData?.Value,
                end: capacitanceBushingReferenceData?.Value,
                color: colorStatusOrange,
                seriesName: intl.formatMessage({
                  id: `detail_page.widgets.analytics.transformers.Capacitance`,
                  defaultMessage: "Capacitance"
                })
              }
            ],
            series: [
              {
                name: intl.formatMessage({
                  id: `detail_page.widgets.analytics.transformers.PowerFactor`,
                  defaultMessage: "PowerFactor"
                }),
                color: colorBlue60,
                unit: powerFactorBushingParametersData?.Unit,
                values: powerFactorBushingParametersData?.Readings || {}
              },
              {
                name: intl.formatMessage({
                  id: `detail_page.widgets.analytics.transformers.Capacitance`,
                  defaultMessage: "Capacitance"
                }),
                color: colorStatusOrange,
                unit: capacitanceBushingParametersData?.Unit,
                values: capacitanceBushingParametersData?.Readings || {}
              }
            ]
          }
        }
      };
    });

    const chartH = charts.filter((chart) => chart.name.includes("H"));
    const chartX = charts.filter((chart) => chart.name.includes("X"));
    const chartY = charts.filter((chart) => chart.name.includes("Y"));

    return [
      {
        name: "H",
        charts: chartH,
        chartsInRow: 2
      },
      {
        name: "X",
        charts: chartX,
        chartsInRow: 2
      },
      {
        name: "Y",
        charts: chartY,
        chartsInRow: 2
      }
    ];
  }, [chartsData, intl]);

  useEffect(() => {
    loadTransformerParameters(assetId);
  }, [assetId, loadTransformerParameters]);

  return { bushings };
};

export default useBushingOfflineCharts;
