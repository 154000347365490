// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import dayjs from "dayjs";
import React from "react";
import {
  FormattedMessage,
  useIntl,
  FormatDateOptions,
  MessageDescriptor
} from "react-intl";

import IIssue from "features/detailpage/features/issues/models/IIssue";
import DayjsInput from "models/Dayjs";

interface IIssueChangesTooltipProps {
  issue: IIssue;
}

const IssueChangesTooltip = ({ issue }: IIssueChangesTooltipProps) => {
  const createdByLabel = {
    defaultMessage: "Created by:",
    id: "detail_page.issues.issue.created_by"
  };

  const createdDateLabel = {
    defaultMessage: "Created date:",
    id: "detail_page.issues.issue.created_date"
  };

  const closedByLabel = {
    defaultMessage: "Closed by:",
    id: "detail_page.issues.issue.closed_by"
  };

  const closedDateLabel = {
    defaultMessage: "Closed date:",
    id: "detail_page.issues.issue.closed_date"
  };

  const editedByLabel = {
    defaultMessage: "Edited by:",
    id: "detail_page.issues.issue.edited_by"
  };

  const editedDateLabel = {
    defaultMessage: "Edited date:",
    id: "detail_page.issues.issue.edited_date"
  };

  const isClosed = issue.Status === "Closed";
  const createdBy =
    issue.CreatedBy &&
    (issue.CreatedBy.NameAndSurname || issue.CreatedBy.EditorUserId);
  const editedBy =
    issue.LastUpdateBy &&
    (issue.LastUpdateBy.NameAndSurname || issue.LastUpdateBy.EditorUserId);

  return (
    <div className="tooltip-created-date">
      <IssueChange
        className="creation-date"
        date={issue.CreateDate}
        labelMessage={createdBy ? createdByLabel : createdDateLabel}
        userName={createdBy}
      />
      <hr />
      <IssueChange
        className="last-update-date"
        date={issue.UpdateDate}
        labelMessage={
          isClosed && editedBy
            ? closedByLabel
            : isClosed && !editedBy
            ? closedDateLabel
            : !isClosed && editedBy
            ? editedByLabel
            : editedDateLabel
        }
        userName={editedBy}
      />
    </div>
  );
};

interface IIssueChangeProps {
  className?: string;
  date: string;
  labelMessage: MessageDescriptor;
  userName: string;
}

const IssueChange = ({
  className,
  date,
  labelMessage,
  userName
}: IIssueChangeProps) => {
  const intl = useIntl();

  const formatDate = (date: DayjsInput) =>
    intl.formatDate(dayjs(date).local().toDate());

  const timeFormat: FormatDateOptions = {
    hour: "numeric",
    minute: "numeric"
  };

  const formatTime = (date: DayjsInput) =>
    intl.formatDate(dayjs(date).local().toDate(), timeFormat);

  return (
    <div className={`issue-change ${className}`}>
      <div className="change-label">
        <FormattedMessage {...labelMessage} />
      </div>
      <div className="change">
        {userName ? (
          <span>{userName}</span>
        ) : (
          <FormattedMessage
            defaultMessage="SYSTEM"
            id="detail_page.issues_list.created_date.system"
          />
        )}
        <span>-</span>
        <span className="bold">{formatDate(date)}</span>
        <span>{formatTime(date)}</span>
      </div>
    </div>
  );
};

export default IssueChangesTooltip;
