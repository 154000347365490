// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { config } from "utils/AppConfig";

const addToWatchList = (assetId: string) => {
  const url = UrlService.getApiUrl(
    config.api.assetWatchList.addToWatchListUrl,
    {
      assetId
    }
  );

  return new Promise<void>(function (resolve, reject) {
    EndpointService.post(
      url,
      () => {
        resolve();
      },
      (request) => {
        reject(request);
      }
    );
  });
};

export default addToWatchList;
