// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { IAgingContactWear } from "features/detailpage/features/aging/models/IAgingContactWear";
import getAgingContactWearSelector from "features/detailpage/features/aging/selectors/getAgingContactWearSelector";
import { isEmpty, isEqual, isNil } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useAppSelector } from "store";

const expandButtonHeight = 56;

const useAgingContactWear = () => {
  const agingContactWear = useAppSelector(getAgingContactWearSelector);
  const intl = useIntl();

  const title = useMemo(
    () =>
      intl.formatMessage({
        id: "detail_page.widgets.circuit_breaker.aging.contact_wear_chart.title",
        defaultMessage: "Contact Wear (I²t)"
      }),
    [intl]
  );

  const isDataNull = useCallback((data: IAgingContactWear) => {
    let isNoDataAvailable = false;

    const isEveryOperationHasNoData = (arr: (null | number | boolean)[]) =>
      arr.every((item: boolean) => isEqual(item, true) || isNil(item));

    if (!data.Chart || isEmpty(data.Chart.DataSeries)) {
      isNoDataAvailable = true;
    } else {
      // array to check if every operation is not empty/null/undefined
      const arrOfOperations: (null | number | boolean)[] = [];
      // array to check if every value of particular operation is not null/undefined
      const operationValues: (null | number | boolean)[] = [];

      Object.values(data.Chart.DataSeries).forEach((operation) => {
        arrOfOperations.push(isNil(operation) || isEmpty(operation));
        if (operation) {
          Object.values(operation).forEach((operationValue: number) => {
            operationValues.push(operationValue);
          });
        }
      });

      isNoDataAvailable =
        isEveryOperationHasNoData(arrOfOperations) ||
        isEveryOperationHasNoData(operationValues);
    }

    return isNoDataAvailable;
  }, []);

  const calculateHeight = () => window.innerHeight - 620 - expandButtonHeight;
  const [height, setHeight] = useState<number>(calculateHeight());

  const onResize = () => {
    setHeight(calculateHeight());
  };

  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    agingContactWear,
    isDataNull,
    height,
    title
  };
};

export default useAgingContactWear;
