// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { useAppSelector } from "store";
import { IScatterPlotWidgetOwnProps } from "../components/ScatterPlotWidget";
import getScatterPlotXDataSelector from "../selectors/getScatterPlotXDataSelector";
import getScatterPlotYDataSelector from "../selectors/getScatterPlotYDataSelector";
import useScatterPlotWidget from "./useScatterPlotWidget";

const useFamilyContentsWidget = ({
  parameters
}: IScatterPlotWidgetOwnProps) => {
  const scatterPlotYData = useAppSelector(getScatterPlotYDataSelector);
  const scatterPlotXData = useAppSelector(getScatterPlotXDataSelector);

  const { getDataStatus } = useScatterPlotWidget(
    parameters,
    scatterPlotYData,
    scatterPlotXData
  );

  const status = getDataStatus();
  return { scatterPlotYData, scatterPlotXData, status };
};
export default useFamilyContentsWidget;
