// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Statuses } from "core/data/models/Data";
import getVisualizationsConfigurationSelector from "core/selectors/getCustomerVisualizationsSelector";
import { useCallback } from "react";
import { useAppSelector } from "store";
import IVisualizationInfoDictionary from "../models/IVisualizationInfoDictionary";
import VisualizationKeys from "../models/VisualizationKeys";

const useFeature = () => {
  const visualizationConfiguration = useAppSelector(
    getVisualizationsConfigurationSelector
  );

  const isAvailable = useCallback(
    (id: VisualizationKeys) => {
      if (
        visualizationConfiguration &&
        visualizationConfiguration.status === Statuses.Succeeded &&
        visualizationConfiguration.data
      )
        return visualizationConfiguration.data[id];
    },
    [visualizationConfiguration]
  );

  const getAvailable = useCallback(
    <T,>(dictionary: IVisualizationInfoDictionary<T>): T[] => {
      const result: T[] = [];

      Object.keys(dictionary).forEach((key) => {
        if (isAvailable(key as VisualizationKeys)) {
          result.push(dictionary[key]);
        }
      });

      return result;
    },
    [isAvailable]
  );

  return { isAvailable, getAvailable };
};

export default useFeature;
