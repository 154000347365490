// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { IRow, IRowData } from "common/datagrid/DataGrid";
import useNavToComponents from "hooks/useNavToComponents";
import { isNil } from "lodash";
import { AssetRiskEnum } from "models/AssetRisks";
import { Link } from "react-router-dom";
import styled from "styled-components";

interface IComponentsCountColumnProps {
  row: IRow<IRowData>;
  count: number;
  risk?: AssetRiskEnum;
  onRiskClick: (row: IRow<IRowData>, risk: AssetRiskEnum) => void;
  className?: string;
}

const ComponentsCountColumn = ({
  row,
  count,
  risk,
  onRiskClick,
  className
}: IComponentsCountColumnProps) => {
  const { navigateTo } = useNavToComponents();

  return (
    <>
      {!isNil(count) && (
        <StyledLink
          key="navigate-to-components"
          to={navigateTo(row.data["AssetId"], risk)}
          data-qa="componentLink"
          className="link-label"
        >
          <StyledValue
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              onRiskClick(row, risk);
            }}
          >
            {count}
          </StyledValue>
        </StyledLink>
      )}
    </>
  );
};

const gridPadding = "10px";

const StyledLink = styled(Link)`
  width: 100%;
  height: 100%;
  display: block;
`;

const StyledValue = styled.span`
  padding: ${gridPadding};
  width: 100%;
  height: 100%;
  display: block;
`;

export default ComponentsCountColumn;
