// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { notifications } from "@pg/common/build/components/Notifications";
import { resetForm } from "common/form/actions/FormActions";
import { useCallback, useRef } from "react";
import { IntlShape } from "react-intl";
import { useAppDispatch, useAppSelector } from "store";
import { setNotificationsEnabledAction } from "../actions/NotificationsActions";
import getCanBeSavedSelector from "../selectors/getCanBeSavedSelector";
import getNotificationsEnabledSelector from "../selectors/getNotificationsEnabledSelector";
import { formName } from "../selectors/getNotificationsFormSelector";
import getNotificationsModelOutputSelector from "../selectors/getNotificationsModelOutputSelector";
import getUserHasEmailSelector from "../selectors/getUserHasEmailSelector";
import hasValidDataSelector from "../selectors/hasValidDataSelector";
import useSaveNotifications from "./useSaveNotifications";
import useSetAllAssetTypesChecked from "./useSetAllAssetTypesChecked";
import useSetAllDegradedChecked from "./useSetAllDegradedChecked";
import useSetAllOrganizationsChecked from "./useSetAllOrganizationsChecked";
import useSetCanBeSaved from "./useSetCanBeSaved";

const useNotificationsNavbarContainer = () => {
  const { saveNotifications } = useSaveNotifications();
  const { setAllOrganizationChecked } = useSetAllOrganizationsChecked();
  const { setAllAssetTypesChecked } = useSetAllAssetTypesChecked();
  const { setAllDegradedChecked } = useSetAllDegradedChecked();

  const userHasEmail = useAppSelector(getUserHasEmailSelector);
  const notificationsEnabled = useAppSelector(getNotificationsEnabledSelector);
  const hasValidData = useAppSelector(hasValidDataSelector);
  const notificationsModelOutput = useAppSelector(
    getNotificationsModelOutputSelector
  );
  const canBeSaved = useAppSelector(getCanBeSavedSelector);

  const dispatch = useAppDispatch();

  const { setCanBeSaved } = useSetCanBeSaved();
  const setNotificationsEnabled = useCallback(() => {
    if (!notificationsEnabled) {
      dispatch(setNotificationsEnabledAction(true));
      setCanBeSaved(true);
      setAllOrganizationChecked(true);
      setAllAssetTypesChecked(true);
      setAllDegradedChecked(true);
      setCanBeSaved(true);
      resetForm(dispatch, formName);
    }
  }, [
    dispatch,
    notificationsEnabled,
    setAllAssetTypesChecked,
    setAllDegradedChecked,
    setAllOrganizationChecked,
    setCanBeSaved
  ]);

  const offButtonRef = useRef();

  const showUnsavedSettingsQuestion = useCallback(
    (intl: IntlShape, onConfirm: () => void, onCancel?: () => void) => {
      notifications.confirm({
        title: intl.formatMessage({
          id: "notifications.navbar_container.notification_settings_hasnt_been_saved",
          defaultMessage:
            "Notifications will be disabled. Are you sure you want to turn off notifications?"
        }),
        onOk: () => {
          onConfirm();
        },
        onCancel: () => {
          if (onCancel) onCancel();
        }
      });
    },
    []
  );

  const setNotificationsDisabled = useCallback(
    (intl: IntlShape) => {
      if (notificationsEnabled) {
        showUnsavedSettingsQuestion(intl, async () => {
          try {
            await saveNotifications(notificationsModelOutput(true));
            dispatch(setNotificationsEnabledAction(false));
            setAllOrganizationChecked(false);
            setAllAssetTypesChecked(false);
            resetForm(dispatch, formName);
          } catch (e) {
            console.error(e);
          }
        });
      }
    },
    [
      dispatch,
      notificationsEnabled,
      notificationsModelOutput,
      saveNotifications,
      setAllAssetTypesChecked,
      setAllOrganizationChecked,
      showUnsavedSettingsQuestion
    ]
  );

  const onSaveButtonClicked = useCallback(async () => {
    try {
      await saveNotifications(notificationsModelOutput());
    } catch (e) {
      console.error(e);
    }
  }, [notificationsModelOutput, saveNotifications]);

  return {
    canBeSaved,
    userHasEmail,
    notificationsEnabled,
    hasValidData,
    setNotificationsEnabled,
    setNotificationsDisabled,
    onSaveButtonClicked,
    offButtonRef
  };
};

export default useNotificationsNavbarContainer;
