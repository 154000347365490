// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import * as React from "react";
import { FormattedNumber, FormattedMessage } from "react-intl";

import DataGrid, { IColumnConfig, IRowData } from "common/datagrid/DataGrid";
import IReplacementPriorityScore from "features/detailpage/features/replacementscore/models/IReplacementPriorityScore";

import "./ReplacementPriorityGrid.less";

interface IReplacementPriorityGridProps {
  scoreCalculation: IReplacementPriorityScore[];
  replacementScoreModelImplementationId: string;
}

const FactorColumn = ({ nameId }: { nameId: string }) => (
  <div className="grid-cell factor">
    <FormattedMessage id={nameId} defaultMessage={nameId} />
  </div>
);

const PercentageMaxProgressBar = ({ value }: { value: number }) => (
  <div className="progress">
    <div
      className="progress-bar"
      role="progressbar"
      aria-valuenow={value}
      aria-valuemin={0}
      aria-valuemax={100}
      style={{
        width: `${value}%`
      }}
    />
  </div>
);

const PercentageMaxColumn = ({
  percentageOfMaxValue
}: {
  percentageOfMaxValue: number;
}) => (
  <div className="grid-cell percentage-of-max-value">
    <div className="percentage-wrapper">
      <FormattedNumber
        value={percentageOfMaxValue}
        maximumFractionDigits={1}
        minimumFractionDigits={1}
      />
      %
    </div>
    <PercentageMaxProgressBar value={percentageOfMaxValue} />
  </div>
);

const ValueColumn = ({
  value,
  factor,
  replacementScoreModelImplementationId
}: {
  value: number;
  factor: string;
  replacementScoreModelImplementationId: string;
}) => {
  const shouldDisplayMinusSign = React.useCallback(() => {
    const replacementScoreModelImplementationIdWhitelist = [
      "ReplacementTransformerModel",
      "ReplacementCircuitBreakerModel"
    ];
    return (
      factor.endsWith("financial") &&
      value > 0 &&
      replacementScoreModelImplementationIdWhitelist.some(
        (x) => x === replacementScoreModelImplementationId
      )
    );
  }, [value, factor, replacementScoreModelImplementationId]);

  const message = shouldDisplayMinusSign() ? (
    <FormattedMessage
      id="detail_page.replacement_priority.grid.negative_financial_value"
      defaultMessage="-{value}"
      values={{
        value: (
          <FormattedNumber
            value={value}
            maximumFractionDigits={1}
            minimumFractionDigits={1}
          />
        )
      }}
    ></FormattedMessage>
  ) : (
    <FormattedNumber
      value={value}
      maximumFractionDigits={1}
      minimumFractionDigits={1}
    />
  );

  return <div className="grid-cell value">{message}</div>;
};

class ReplacementPriorityGrid extends React.PureComponent<
  IReplacementPriorityGridProps
> {
  render() {
    const { scoreCalculation } = this.props;
    return (
      <div className="grid-replacement-priority">
        <DataGrid
          columns={this.getColumnsConfig()}
          data={scoreCalculation}
          footerComponent={() => null}
        />
      </div>
    );
  }

  private getColumnsConfig = (): IColumnConfig[] => {
    const { replacementScoreModelImplementationId } = this.props;
    return [
      {
        id: "factor",
        component: (value, row) => <FactorColumn nameId={row.data["NameId"]} />,
        compareFunction: (r1: IRowData, r2: IRowData) => {
          const r1Text = (r1["NameId"] as string).toLowerCase();
          const r2Text = (r2["NameId"] as string).toLowerCase();
          if (r1Text < r2Text) {
            return -1;
          }
          if (r1Text > r2Text) {
            return 1;
          }

          return 0;
        },
        frozen: true,
        message: {
          id: "detail_page.replacement_priority.grid.factor",
          defaultMessage: "Factor"
        },
        weight: 4
      },
      {
        id: "percentage_max_value",
        component: (value, row) => (
          <PercentageMaxColumn
            percentageOfMaxValue={row.data["PercentageOfMaxValue"]}
          />
        ),
        compareFunction: (r1: IRowData, r2: IRowData) =>
          r1["PercentageOfMaxValue"] - r2["PercentageOfMaxValue"],
        frozen: true,
        message: {
          id: "detail_page.replacement_priority.grid.percentage_max",
          defaultMessage: "% of Max Value"
        },
        weight: 2
      },
      {
        id: "value",
        component: (value, row) => (
          <ValueColumn
            value={row.data["Value"]}
            factor={row.data["NameId"]}
            replacementScoreModelImplementationId={
              replacementScoreModelImplementationId
            }
          />
        ),
        compareFunction: (r1: IRowData, r2: IRowData) =>
          r1["Value"] - r2["Value"],
        frozen: true,
        message: {
          id: "detail_page.replacement_priority.grid.value",
          defaultMessage: "Value"
        }
      }
    ];
  };
}

export default ReplacementPriorityGrid;
