// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import Popover, { popoverClick } from "common/popover/Popover";
import Data from "core/data/models/Data";
import ParameterSources from "models/ParameterSources";
import { useCallback, useState } from "react";
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import { AppDispatch } from "store";
import ParameterHistogram, {
  loadParameterHistogram
} from "../../ParameterHistogram";
import usePopoverHideOnScroll from "../../ParameterHistogram/hooks/usePopoverHideOnScroll";
import IParameterHistogram from "../../ParameterHistogram/models/IParameterHistogram";
import getParameterHistogramSelector from "../../ParameterHistogram/selectors/getParameterHistogramSelector";

interface IActionHistogramActionsData {
  histogramData: Data<IParameterHistogram[]>;
}

interface IActionHistogramActionsAction {
  loadParameterHistogram: (
    assetId: string,
    parameterName: string,
    parameterSource: ParameterSources
  ) => void;
}
export interface IActionHistogramOwnProps {
  assetId: string;
  parameterName: string;
  parameterSource: ParameterSources;
  translatedParameterName: string;
  unit: string | null | undefined;
}

type IActionHistogramProps = IActionHistogramActionsData &
  IActionHistogramActionsAction &
  IActionHistogramOwnProps;

const ActionHistogram = ({
  assetId,
  parameterName,
  parameterSource,
  translatedParameterName,
  unit,
  histogramData,
  loadParameterHistogram
}: IActionHistogramProps) => {
  const [popoverVisible, setPopoverVisible] = useState(false);

  const scrollHandle = () => {
    setPopoverVisible(false);
  };

  usePopoverHideOnScroll(popoverVisible, scrollHandle);

  const handlePopoverHide = useCallback(() => {
    setPopoverVisible(false);
  }, []);

  const handlePopoverShow = useCallback(() => {
    setPopoverVisible(true);
    loadParameterHistogram(assetId, parameterName, parameterSource);
  }, [assetId, loadParameterHistogram, parameterName, parameterSource]);

  return (
    <Popover
      content={
        <ParameterHistogram
          histogramData={histogramData}
          title={translatedParameterName}
          unit={unit}
        />
      }
      onShow={handlePopoverShow}
      onHide={handlePopoverHide}
      overlayClassName="histogram-parameters-popover"
      placement="topRight"
      visible={popoverVisible}
      destroyTooltipOnHide
    >
      <span className="action-histogram-popover-icon">
        <Icon name="bar_chart" className={`${popoverClick}`} />
      </span>
    </Popover>
  );
};

const mapStateToProps = (state: IState): IActionHistogramActionsData => ({
  histogramData: getParameterHistogramSelector(state)
});

const mapDispatchToProps = (
  dispatch: AppDispatch
): IActionHistogramActionsAction => ({
  loadParameterHistogram: (
    assetId: string,
    parameterName: string,
    parameterSource: ParameterSources
  ) => dispatch(loadParameterHistogram(assetId, parameterName, parameterSource))
});

export default connect(mapStateToProps, mapDispatchToProps)(ActionHistogram);
