// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { IData, ISeries } from "@apm/widgets/build/widgets/OperationsCharts";
import {
  IParametersState,
  IState
} from "@apm/widgets/build/widgets/OperationsParameters";
import IOperation from "../models/IOperation";
import IPole from "../models/IPole";

export default class OperationsChartCreator {
  private multiSeriesCounter: number = 0;

  create(operations: IOperation, parametersState: IState): Promise<IData[]> {
    return new Promise((resolve) => {
      setTimeout(() => {
        const chartData = this.createChartData(operations, parametersState);
        resolve(chartData);
      });
    });
  }

  private createChartData(
    operations: IOperation,
    parametersState: IState
  ): IData[] {
    const data: IData[] = [];
    this.multiSeriesCounter = 0;
    this.fillParamsPole(data, operations["Pole1"], parametersState["pole1"]);
    this.fillParamsPole(data, operations["Pole2"], parametersState["pole2"]);
    this.fillParamsPole(data, operations["Pole3"], parametersState["pole3"]);

    return data
      .sort((a, b) => {
        if (a.type === b.type) {
          return a.label < b.label ? 1 : -1;
        } else {
          return a.type < b.type ? -1 : 1;
        }
      })
      .reverse();
  }

  private fillParamsPole(
    data: IData[],
    poleOperationsValues: IPole,
    parametersState: IParametersState
  ) {
    const paramsKeys = parametersState ? Object.keys(parametersState) : null;

    paramsKeys?.forEach((paramKey) => {
      const paramState = parametersState[paramKey];
      const operationParameter = poleOperationsValues?.Parameters.find(
        (x) => x.Id === paramKey
      );
      if (operationParameter && paramState?.checked) {
        const dataIndex = this.getDatSeriesIndex(data, operationParameter.Id);
        if (dataIndex > -1) {
          data[dataIndex].series.push({
            id: operationParameter.Id,
            color: paramState.color,
            values: this.createSeries(operationParameter.Values)
          });
        } else {
          const seriesId = this.isPHMultiseries(operationParameter.Id)
            ? "multiseries_ph"
            : `multiseries_${operationParameter.Id}`;
          data.push({
            id: seriesId,
            label: operationParameter.Id.toUpperCase(),
            type: operationParameter.Type,
            series: [
              {
                id: operationParameter.Id,
                color: paramState.color,
                values: this.createSeries(operationParameter.Values)
              }
            ]
          });
        }
      }
    });
  }

  private getDatSeriesIndex(data: IData[], id: string) {
    if (this.isPHMultiseries(id)) {
      return data.findIndex((x) => x.id === "multiseries_ph");
    }

    return data.findIndex((x) => x.id === `multiseries_${id}`);
  }

  private createSeries(values: number[]): ISeries {
    return values?.map((value, index): ISeries => {
      return { time: index, value };
    });
  }

  private isPHMultiseries(id: string) {
    return ["ph1", "ph2", "ph3"].some((x) => x === id);
  }
}
