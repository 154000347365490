// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { FormInstance } from "antd";
import update from "immutability-helper";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { IMenuItem } from "../components/AssetModalMenu";
import useConfiguration, {
  ITranslatedMenuItem
} from "../hooks/useConfiguration";
import DataTypes from "../models/DataTypes";
import FormTypes from "../models/FormTypes";
import IForm from "../models/IForm";
import IFormValues from "../models/IFormValues";
import { ITabInfo } from "../models/ITabInfo";

export interface IFormItem {
  formKey: string;
  formType: FormTypes;
  dataType: DataTypes;
  form?: FormInstance<IFormValues>;
  invalid: boolean;
  touched: boolean;
  tabs?: ITabInfo[];
  formConfiguration?: IForm;
}

const useForms = () => {
  const {
    translatedMenuConfiguration,
    configuredParameterNames,
    getFormConfiguration,
    getConfiguredParameterNames
  } = useConfiguration();

  const prepareForms = useCallback(
    (menuItem: ITranslatedMenuItem, forms: IFormItem[]) => {
      if (menuItem.formType && menuItem.dataType) {
        forms.push({
          formKey: menuItem.key,
          formType: menuItem.formType,
          dataType: menuItem.dataType,
          invalid: false,
          touched: false,
          formConfiguration: getFormConfiguration(menuItem.key)
        });
      }
      menuItem.children?.map((submenuItem) => {
        return prepareForms(submenuItem, forms);
      });
    },
    [getFormConfiguration]
  );

  const getInitialForms = () => {
    const forms: IFormItem[] = [];
    translatedMenuConfiguration.forEach((menuItem) => {
      prepareForms(menuItem, forms);
    });
    return forms;
  };

  const [forms, setForms] = useState<IFormItem[]>(getInitialForms);
  const [selectedMenu, setSelectedMenu] = useState<string>("nameplate");
  const [blockActions, setBlockActions] = useState<boolean>(true);
  const intl = useIntl();

  useEffect(() => {
    const forms: IFormItem[] = [];
    translatedMenuConfiguration.map((menuItem) => {
      return prepareForms(menuItem, forms);
    });
    setForms(forms);
    setSelectedMenu(forms[0].formKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [translatedMenuConfiguration]);

  useEffect(() => {
    let touched = false;
    forms.forEach((formInfo) => {
      if (formInfo.touched) touched = true;
    });
    setBlockActions(!touched);
  }, [forms]);

  const getFormInfo = useCallback(
    (formKey: string) => {
      const filteredForms = forms.filter((form) => form.formKey === formKey);
      if (filteredForms?.length > 0) {
        return filteredForms[0];
      }
    },
    [forms]
  );

  const getMenuItem = useCallback(
    (menuItem: ITranslatedMenuItem) => {
      const formInfo = getFormInfo(menuItem.key);
      return {
        key: menuItem.key,
        iconName: menuItem.iconName,
        title: menuItem.title,
        form: formInfo ? formInfo.form : null,
        invalid: formInfo ? formInfo.invalid : false,
        touched: formInfo ? formInfo.touched : false
      };
    },
    [getFormInfo]
  );

  const isDataTypeTouched = useCallback(
    (formDataType: DataTypes) => {
      const touchedForms = forms.filter(
        (formItem) => formItem.dataType === formDataType && formItem.touched
      );

      return touchedForms.length > 0;
    },
    [forms]
  );

  const menuItems: IMenuItem[] = useMemo(() => {
    return translatedMenuConfiguration.map((menuItem) => ({
      key: menuItem.key,
      title: menuItem.title,
      children: menuItem.children.map((subMenuItem) => {
        return getMenuItem(subMenuItem);
      })
    }));
  }, [translatedMenuConfiguration, getMenuItem]);

  const validateMessages = useMemo(() => {
    return {
      required: intl.formatMessage({
        id: "configuration_tool.validation.value_is_required",
        defaultMessage: "Value is required"
      }),
      whitespace: intl.formatMessage({
        id: "configuration_tool.validation.value_should_not_be_empty",
        defaultMessage: "Value should not be empty"
      }),
      types: {
        string: intl.formatMessage({
          id: "configuration_tool.validation.value_incorrect_type",
          defaultMessage: "Value has incorrect type"
        }),
        number: intl.formatMessage({
          id: "configuration_tool.validation.value_incorrect_type",
          defaultMessage: "Value has incorrect type"
        }),
        integer: intl.formatMessage({
          id: "configuration_tool.validation.value_whole_number",
          // eslint-disable-next-line no-template-curly-in-string
          defaultMessage: "Value must be a whole number"
        })
      },
      number: {
        range: intl.formatMessage({
          id: "configuration_tool.validation.expected_range_min_max",
          // eslint-disable-next-line no-template-curly-in-string
          defaultMessage: "Expected range: ${min}-${max}"
        }),
        min: intl.formatMessage({
          id: "configuration_tool.validation.value_greater_or_equal_to",
          // eslint-disable-next-line no-template-curly-in-string
          defaultMessage: "Value must be greater or equal to ${min}"
        }),
        max: intl.formatMessage({
          id: "configuration_tool.validation.value_lower_or_equal_to",
          // eslint-disable-next-line no-template-curly-in-string
          defaultMessage: "Value must be lower or equal to ${max}"
        })
      },
      string: {
        max: intl.formatMessage({
          id: "configuration_tool.validation.max_length",
          // eslint-disable-next-line no-template-curly-in-string
          defaultMessage: "Max length: ${max}"
        })
      }
    };
  }, [intl]);

  const registerForm = useCallback(
    (formKey: string, form: FormInstance<IFormValues>, tabs?: ITabInfo[]) => {
      const formIndex = forms.findIndex((form) => {
        return form.formKey === formKey;
      });

      if (formIndex >= 0) {
        const newForms = update(forms, {
          [formIndex]: {
            $merge: {
              form,
              tabs,
              invalid:
                tabs?.some(({ invalid }) => invalid) ||
                forms[formIndex].invalid,
              touched:
                tabs?.some(({ touched }) => touched) || forms[formIndex].touched
            }
          }
        });
        setForms(newForms);
      }
    },
    [forms]
  );

  const updateFormItemTouchedInfo = useCallback(
    (formKey: string, value: boolean = true) => {
      const formIndex = forms.findIndex((form) => form.formKey === formKey);
      if (formIndex >= 0) {
        const newForms = update(forms, {
          [formIndex]: { $merge: { touched: value } }
        });
        setForms(newForms);
      }
    },
    [forms]
  );

  const updateFormItemInvalidInfo = useCallback(
    (formKey: string, value: boolean = true) => {
      const formIndex = forms.findIndex((form) => form.formKey === formKey);
      if (formIndex >= 0) {
        const newForms = update(forms, {
          [formIndex]: { $merge: { invalid: value } }
        });
        setForms(newForms);
      }
    },
    [forms, setForms]
  );

  const updateFormItemValidationInfo = useCallback(
    ({
      formKey,
      invalid,
      touched
    }: {
      formKey: string;
      invalid: boolean;
      touched: boolean;
    }) => {
      const formIndex = forms.findIndex((form) => form.formKey === formKey);
      if (formIndex >= 0) {
        const newForms = update(forms, {
          [formIndex]: { $merge: { invalid, touched } }
        });
        setForms(newForms);
      }
    },
    [forms, setForms]
  );

  const changeTabsInfo = useCallback(
    (formKey: string, invalidTabsKeys: ITabInfo[]) => {
      const formIndex = forms.findIndex((form) => form.formKey === formKey);
      if (formIndex >= 0) {
        const newTabs: ITabInfo[] = forms[formIndex].tabs.map((t) => {
          const tabToUpdate = invalidTabsKeys.find(
            (el) => el.tabId === t.tabId
          );
          return tabToUpdate
            ? {
                tabId: t.tabId,
                invalid: tabToUpdate?.invalid,
                touched: tabToUpdate?.touched
              }
            : t;
        });

        const anyTabInvalid = newTabs.some(({ invalid }) => invalid);

        const anyTabTouched = newTabs.some(({ touched }) => touched);

        let newForms = update(forms, {
          [formIndex]: {
            $merge: {
              invalid: anyTabInvalid,
              touched: anyTabTouched,
              tabs: newTabs
            }
          }
        });

        setForms(newForms);
      }
    },
    [forms]
  );

  const changeSelectedMenu = useCallback(
    async (key: string) => {
      const currentFormInfo = getFormInfo(selectedMenu);
      if (
        currentFormInfo &&
        currentFormInfo.formKey !== "connectedDevices" &&
        currentFormInfo.touched
      ) {
        await currentFormInfo.form
          .validateFields()
          .then(() => {
            updateFormItemInvalidInfo(selectedMenu, false);
          })
          .catch(() => {
            updateFormItemInvalidInfo(selectedMenu, true);
          });
      }
      setSelectedMenu(key);
    },
    [getFormInfo, selectedMenu, updateFormItemInvalidInfo]
  );

  const resetFormsState = useCallback(
    (formDataType: DataTypes = null) => {
      const newForms: IFormItem[] = [];
      forms.forEach((itemValue, itemKey) => {
        if (formDataType === null || formDataType === itemValue.dataType) {
          const newTabs: ITabInfo[] = [];
          itemValue.tabs?.forEach((t) => {
            newTabs.push(
              update(t, {
                $merge: { invalid: false, touched: false }
              })
            );
          });

          const newFormInfo = update(forms[itemKey], {
            $merge: {
              invalid: false,
              touched: false,
              tabs: newTabs.length > 0 ? newTabs : null
            }
          });

          newForms.push(newFormInfo);
        } else {
          newForms.push(itemValue);
        }
      });
      setForms(newForms);
    },
    [forms, setForms]
  );

  const validForms = useCallback(async () => {
    const currentForm = getFormInfo(selectedMenu);

    let formsAreValid = true;

    if (currentForm.formKey !== "connectedDevices" && currentForm?.touched) {
      await currentForm.form
        .validateFields()
        .then(() => {
          updateFormItemInvalidInfo(selectedMenu, false);
        })
        .catch(() => {
          updateFormItemInvalidInfo(selectedMenu, true);
          formsAreValid = false;
        });
    }

    const invalidForms = forms.filter(
      (formItem) =>
        (formItem !== currentForm && formItem.invalid) ||
        (formItem.formKey === "connectedDevices" && formItem.invalid)
    );

    if (invalidForms.length > 0) formsAreValid = false;

    return formsAreValid;
  }, [forms, selectedMenu, getFormInfo, updateFormItemInvalidInfo]);

  return {
    forms,
    selectedMenu,
    menuItems,
    blockActions,
    validateMessages,
    configuredParameterNames,
    getFormInfo,
    registerForm,
    changeSelectedMenu,
    updateFormItemTouchedInfo,
    updateFormItemValidationInfo,
    resetFormsState,
    validForms,
    isDataTypeTouched,
    changeTabsInfo,
    getConfiguredParameterNames
  };
};

export default useForms;
