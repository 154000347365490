// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import StorageService from "core/app/services/StorageService";
import IRiskMatrixConfiguration from "features/detailpage/features/riskmatrix/models/IRiskMatrixConfiguration";
import { isEmpty, isNil } from "lodash";
import { useCallback } from "react";
import { useAppSelector } from "store";
import ILevelConfiguration from "../models/ILevelConfiguration";
import RiskMatrixScope from "../models/RiskMatrixScope";
import getIsComponentSelector from "../selectors/getIsComponentSelector";

const riskMatrixConfigurationKey = "RiskMatrixConfiguration";

const useRiskMatrixConfiguration = () => {
  const isComponent = useAppSelector(getIsComponentSelector);

  const isRiskMatrixConfigurationValid = (config: IRiskMatrixConfiguration) => {
    return (
      config.AssetConfiguration !== undefined ||
      config.ComponentConfiguration !== undefined
    );
  };

  const parseRiskMatrixConfiguration = useCallback(
    (value: string, key: string): IRiskMatrixConfiguration | null => {
      try {
        const parsedValue = JSON.parse(value) as IRiskMatrixConfiguration;

        if (isRiskMatrixConfigurationValid(parsedValue)) {
          return parsedValue;
        } else {
          throw new Error(
            "Wrong value in for risk matrix configuration in local storage"
          );
        }
      } catch (e) {
        StorageService.removeUserSessionStorageItem(key);
        return null;
      }
    },
    []
  );

  const getRiskMatrixConfigurationFromLocalStorage = useCallback(
    (tenant: string) => {
      const key = `${tenant}-${riskMatrixConfigurationKey}`;
      const value = StorageService.getUserSessionStorageItem(key);

      if (!isEmpty(value)) {
        return parseRiskMatrixConfiguration(value, key);
      }

      return null;
    },
    [parseRiskMatrixConfiguration]
  );

  const mergeRiskMatrixConfig = (
    riskMatrixConfiguration: IRiskMatrixConfiguration,
    localStorageConfiguration: IRiskMatrixConfiguration
  ) => {
    if (isNil(localStorageConfiguration)) {
      return riskMatrixConfiguration;
    }
    const config = {
      ...riskMatrixConfiguration,
      ComponentConfiguration: {
        ...riskMatrixConfiguration.ComponentConfiguration,
        DefaultScope: riskMatrixConfiguration.ComponentConfiguration.IsShown
          ? localStorageConfiguration.ComponentConfiguration.DefaultScope
          : riskMatrixConfiguration.ComponentConfiguration.DefaultScope
      },
      AssetConfiguration: {
        ...riskMatrixConfiguration.AssetConfiguration,
        DefaultScope: riskMatrixConfiguration.AssetConfiguration.IsShown
          ? localStorageConfiguration.AssetConfiguration.DefaultScope
          : riskMatrixConfiguration.AssetConfiguration.DefaultScope
      }
    };

    return config;
  };

  const convertSubstationToLocationIfNeeded = useCallback(
    (configuration: ILevelConfiguration) => {
      if ((configuration.DefaultScope as string) === "Substation") {
        configuration.DefaultScope = "Location";
      }
    },
    []
  );

  const getRiskMatrixConfiguration = useCallback(
    (riskMatrixConfiguration: IRiskMatrixConfiguration, tenant: string) => {
      const localStorageRiskMatrixConfiguration =
        getRiskMatrixConfigurationFromLocalStorage(tenant);
      const config = mergeRiskMatrixConfig(
        riskMatrixConfiguration,
        localStorageRiskMatrixConfiguration
      );

      const levelConfig = isComponent
        ? config.ComponentConfiguration
        : config.AssetConfiguration;

      convertSubstationToLocationIfNeeded(levelConfig);

      return levelConfig;
    },
    [
      convertSubstationToLocationIfNeeded,
      getRiskMatrixConfigurationFromLocalStorage,
      isComponent
    ]
  );

  const getDefaultScope = useCallback(
    (riskMatrixConfiguration: IRiskMatrixConfiguration, tenant: string) => {
      const configuration = getRiskMatrixConfiguration(
        riskMatrixConfiguration,
        tenant
      );

      return configuration.DefaultScope
        ? configuration.DefaultScope.toLocaleLowerCase()
        : "fleet";
    },
    [getRiskMatrixConfiguration]
  );

  const setRiskMatrixConfigurationToLocalStorage = useCallback(
    (
      riskMatrixConfiguration: IRiskMatrixConfiguration,
      scope: RiskMatrixScope,
      tenant: string
    ) => {
      const config =
        getRiskMatrixConfigurationFromLocalStorage(tenant) ||
        riskMatrixConfiguration;

      if (isComponent) {
        config.ComponentConfiguration.DefaultScope = scope;
      } else {
        config.AssetConfiguration.DefaultScope = scope;
      }

      StorageService.setUserSessionStorageItem(
        `${tenant}-${riskMatrixConfigurationKey}`,
        JSON.stringify(config)
      );
    },
    [getRiskMatrixConfigurationFromLocalStorage, isComponent]
  );

  return {
    getDefaultScope,
    getRiskMatrixConfiguration,
    getRiskMatrixConfigurationFromLocalStorage,
    setRiskMatrixConfigurationToLocalStorage
  };
};

export default useRiskMatrixConfiguration;
