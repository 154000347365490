// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import DataSource from "common/DuvalAnalysis/models/DataSource";
import { createDuvalPoints } from "common/DuvalAnalysis/utils/duvalAnalysisHelper";
import * as d3 from "d3";
import { IntlShape } from "react-intl";
import { getStaticMarkup } from "../../DuvalAnalysis/components/DuvalAnalysisTooltip";
import IDuvalPoint from "../models/IDuvalPoint";
import createDuvalPentagonConvertedCoordinates from "./createDuvalPentagonConvertedCoordinates";

interface IDuvalPentagonPoints {
  root: D3Selection<SVGElement>;
  tooltip: D3Selection<HTMLElement>;
  points: IDuvalPoint[];
  intl: IntlShape;
  dataSource: DataSource;
}

type D3Selection<T extends d3.BaseType> = d3.Selection<
  T,
  unknown,
  null,
  undefined
>;

const createDuvalPentagonPoints = ({
  root,
  tooltip,
  points,
  intl,
  dataSource
}: IDuvalPentagonPoints) => {
  createDuvalPoints({
    root,
    dataSource,
    sortedPoints: points.sort((a, b) =>
      a?.Date < b?.Date ? -1 : a?.Date > b?.Date ? 1 : 0
    ),
    showTooltip: (pointEvent: IDuvalPoint & MouseEvent, sortedPoints) => showTooltip(tooltip, pointEvent, intl, dataSource, sortedPoints),
    hideTooltip: () => hideTooltip(tooltip),
    getCoords: (duvalPoint: IDuvalPoint) =>
      createDuvalPentagonConvertedCoordinates({
        point: getPointCoordinates(duvalPoint)
      })
  });
};

function getPointCoordinates(point: IDuvalPoint) {
  const getAngleInRadians = (deg: number) => {
    const radians = Number((deg * Math.PI) / 180.0);
    return radians;
  };

  const radiansHydrogen = getAngleInRadians(90);
  const radiansEthane = getAngleInRadians(162);
  const radiansMethane = getAngleInRadians(234);
  const radiansEthylene = getAngleInRadians(306);
  const radiansAcetylene = getAngleInRadians(18);

  let hydrogen = {
    x: getHydrogenPercent(point) * Math.cos(radiansHydrogen),
    y: getHydrogenPercent(point) * Math.sin(radiansHydrogen)
  };

  let ethane = {
    x: getEthanePercent(point) * Math.cos(radiansEthane),
    y: getEthanePercent(point) * Math.sin(radiansEthane)
  };

  let methane = {
    x: getMethanePercent(point) * Math.cos(radiansMethane),
    y: getMethanePercent(point) * Math.sin(radiansMethane)
  };

  let ethylene = {
    x: getEthylenePercent(point) * Math.cos(radiansEthylene),
    y: getEthylenePercent(point) * Math.sin(radiansEthylene)
  };

  let acetylene = {
    x: getAcetylenePercent(point) * Math.cos(radiansAcetylene),
    y: getAcetylenePercent(point) * Math.sin(radiansAcetylene)
  };

  let x1 = hydrogen.x;
  let y1 = hydrogen.y;
  let x2 = ethane.x;
  let y2 = ethane.y;
  let x3 = methane.x;
  let y3 = methane.y;
  let x4 = ethylene.x;
  let y4 = ethylene.y;
  let x5 = acetylene.x;
  let y5 = acetylene.y;

  let area1 = x1 * y2 - x2 * y1;
  let area2 = x2 * y3 - x3 * y2;
  let area3 = x3 * y4 - x4 * y3;
  let area4 = x4 * y5 - x5 * y4;
  let area5 = x5 * y1 - x1 * y5;

  if (area1 - area5 === area1) {
    area5 = Number.MIN_VALUE;
  }
  if (area2 - area1 === area2) {
    area1 = Number.MIN_VALUE;
  }
  if (area3 - area2 === area3) {
    area2 = Number.MIN_VALUE;
  }
  if (area4 - area3 === area4) {
    area3 = Number.MIN_VALUE;
  }
  if (area5 - area4 === area5) {
    area4 = Number.MIN_VALUE;
  }

  let totalArea = (area1 + area2 + area3 + area4 + area5) / 2;

  // Centroipoint X
  let centroidX1 = (x1 + x2) * area1;
  let centroidX2 = (x2 + x3) * area2;
  let centroidX3 = (x3 + x4) * area3;
  let centroidX4 = (x4 + x5) * area4;
  let centroidX5 = (x5 + x1) * area5;
  let centroidX =
    (centroidX1 + centroidX2 + centroidX3 + centroidX4 + centroidX5) /
    (6 * totalArea);

  // Centroipoint Y
  let centroidY1 = (y1 + y2) * area1;
  let centroidY2 = (y2 + y3) * area2;
  let centroidY3 = (y3 + y4) * area3;
  let centroidY4 = (y4 + y5) * area4;
  let centroidY5 = (y5 + y1) * area5;
  let centroidY =
    (centroidY1 + centroidY2 + centroidY3 + centroidY4 + centroidY5) /
    (6 * totalArea);

  if (getHydrogenPercent(point) === 100) {
    centroidX = 0;
    centroidY = 40;
  }
  if (getEthanePercent(point) === 100) {
    centroidX = -38;
    centroidY = 12.4;
  }
  if (getMethanePercent(point) === 100) {
    centroidX = -23.5;
    centroidY = -32.4;
  }
  if (getEthylenePercent(point) === 100) {
    centroidX = 23.5;
    centroidY = -32.4;
  }
  if (getAcetylenePercent(point) === 100) {
    centroidX = 38;
    centroidY = 12.4;
  }

  return {
    x: centroidX,
    y: centroidY
  };
}

function getHydrogenPercent(point: IDuvalPoint) {
  return getRelativePercent(point.Hydrogen, point);
}

function getAcetylenePercent(point: IDuvalPoint) {
  return getRelativePercent(point.Acetylene, point);
}

function getEthylenePercent(point: IDuvalPoint) {
  return getRelativePercent(point.Ethylene, point);
}

function getMethanePercent(point: IDuvalPoint) {
  return getRelativePercent(point.Methane, point);
}

function getEthanePercent(point: IDuvalPoint) {
  return getRelativePercent(point.Ethane, point);
}

function getRelativePercent(value: number, point: IDuvalPoint) {
  return (value / getTotal(point)) * 100;
}

function getTotal(point: IDuvalPoint) {
  return (
    point.Hydrogen +
    point.Acetylene +
    point.Ethylene +
    point.Methane +
    point.Ethane
  );
}

function showTooltip(
  tooltip: D3Selection<HTMLElement>,
  pointEvent: MouseEvent,
  intl: IntlShape,
  dataSource: DataSource,
  sortedPoints: IDuvalPoint
) {
  const labels = {
    hydrogen: "H2",
    acetylene: "C2H2",
    ethylene: "C2H4",
    methane: "CH4",
    ethane: "C2H6"
  };

  const html = getStaticMarkup({
    intl: intl,
    date: new Date(sortedPoints.Date),
    dataSource: dataSource,
    gases: [
      {
        label: labels.hydrogen,
        ppm: sortedPoints.Hydrogen,
        percent: Math.round(getHydrogenPercent(sortedPoints))
      },
      {
        label: labels.acetylene,
        ppm: sortedPoints.Acetylene,
        percent: Math.round(getAcetylenePercent(sortedPoints))
      },
      {
        label: labels.ethylene,
        ppm: sortedPoints.Ethylene,
        percent: Math.round(getEthylenePercent(sortedPoints))
      },
      {
        label: labels.methane,
        ppm: sortedPoints.Methane,
        percent: Math.round(getMethanePercent(sortedPoints))
      },
      {
        label: labels.ethane,
        ppm: sortedPoints.Ethane,
        percent: Math.round(getEthanePercent(sortedPoints))
      }
    ]
  });

  tooltip
    .transition()
    .duration(200)
    .style("opacity", 0.95)
    .style("display", "block");
  tooltip
    .html(html)
    .style("left", pointEvent.pageX + 10 + "px")
    .style("top", pointEvent.pageY + 10 + "px");
}

function hideTooltip(tooltip: D3Selection<HTMLElement>) {
  tooltip
    .transition()
    .duration(500)
    .style("opacity", 0)
    .style("display", "none");
}

export default createDuvalPentagonPoints;
