// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { EventEmitter } from "events";

const changeEvent = "change";

/**
 * @deprecated Use Redux store instead if this
 */
abstract class BaseStore extends EventEmitter {
  addChangeListener(callback: () => void) {
    this.on(changeEvent, callback);
  }

  removeChangeListener(callback: () => void) {
    this.removeListener(changeEvent, callback);
  }

  emitChange() {
    this.emit(changeEvent);
  }
}

export { BaseStore, changeEvent };
