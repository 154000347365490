// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import ParameterCharts, {
  IDataSourceConfig,
  ITranslations,
  TimeFrames
} from "@apm/widgets/build/widgets/ParameterCharts";
import { formatDateTime } from "common/DateTime/utils/dateFormatters";
import Processing from "components/common/Processing";
import {
  WidgetErrorMessage,
  WidgetNoDataMessage
} from "components/common/widget/Widget";
import { Statuses } from "core/data/models/Data";
import { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import { AppDispatch } from "store";
import loadVoltageStressData from "../actions/loadVoltageStressData";
import removeVoltageStressData from "../actions/removeVoltageStressData";
import dataSourceKey from "../constants/dataSourceKey";
import ChartGroup from "../models/ChartGroup";
import ChartType from "../models/ChartType";
import getVoltageStressDataSelector from "../selectors/getVoltageStressDataSelector";
import getChart from "../utils/getChart";
import getChartGroups from "../utils/getChartGroups";
import getLegend from "../utils/getLegend";

interface IVoltageStressActions {
  loadVoltageStressData: (assetId: string, tab: ChartGroup) => void;
  removeVoltageStressData: (assetId: string) => void;
}
interface IVoltageStressOwnProps {
  assetId: string;
  chartGroup: ChartGroup;
}

type VoltageStressProps = IVoltageStressOwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const VoltageStress = ({
  assetId,
  chartGroup,
  voltageStressData,
  loadVoltageStressData,
  removeVoltageStressData
}: VoltageStressProps) => {
  const intl = useIntl();

  useEffect(() => {
    loadVoltageStressData(assetId, chartGroup);
    return () => {
      removeVoltageStressData(assetId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId, chartGroup]);

  const chartGroups = useMemo(
    () =>
      voltageStressData?.data
        ? getChartGroups(intl, voltageStressData?.data, chartGroup)
        : null,
    [intl, voltageStressData?.data, chartGroup]
  );

  const dataSourceConfig: IDataSourceConfig = useMemo(
    () => ({
      dataSources: ["history", "year"],
      dataSourceSessionKey: dataSourceKey,
      defaultDataSource: "year",
      showLabel: false
    }),
    []
  );

  return (
    <>
      {voltageStressData?.status === Statuses.Loading && <Processing />}
      {voltageStressData?.status === Statuses.Succeeded && (
        <ParameterCharts
          className="parameters-chart"
          adjustYSizeToPositiveValues={false}
          showAdjustToThresholdsSwitch={true}
          chartGroups={chartGroups}
          getChart={(chartType: string, timeFrame: TimeFrames) =>
            getChart(
              intl,
              chartType as ChartType,
              timeFrame,
              voltageStressData?.data
            )
          }
          getLegend={getLegend(
            intl,
            voltageStressData?.data?.TransformerType,
            chartGroup
          )}
          formatTooltipTitle={(date: string | number | Date) =>
            formatDateTime(intl, date)
          }
          formatXTick={(date: string | number | Date) => intl.formatDate(date)}
          dataSourceConfig={dataSourceConfig}
          showTimeFrameSwitch={false}
          translations={
            {
              adjustToThresholds: intl.formatMessage({
                defaultMessage: "Adjust to thresholds",
                id: "detail_page.adjust_to_thresholds"
              }),
              empty: intl.formatMessage({
                defaultMessage: "NO DATA AVAILABLE",
                id: "detail_page.widgets.analytics.linechart.nodata"
              }),
              expandButtonTooltip: intl.formatMessage({
                defaultMessage: "Expand",
                id: "global.chart.expand"
              }),
              series: (seriesName: string) =>
                intl.formatMessage({
                  defaultMessage: seriesName,
                  id: `detail_page.widgets.analytics.transformers.voltage_stress.legend.${seriesName}`
                }),
              getDataSourceOptionTranslation: (dataSource: string) =>
                intl.formatMessage({
                  defaultMessage: dataSource,
                  id: `detail_page.widgets.analytics.transformers.voltage_stress.data_source.${dataSource}`
                })
            } as ITranslations
          }
        />
      )}
      {voltageStressData?.status === Statuses.NotFound && (
        <WidgetNoDataMessage />
      )}
      {voltageStressData?.status === Statuses.Failed && <WidgetErrorMessage />}
    </>
  );
};

const mapStateToProps = (state: IState, ownProps: IVoltageStressOwnProps) => ({
  voltageStressData: getVoltageStressDataSelector(state)(ownProps.assetId)
});

const mapDispatchToProps = (dispatch: AppDispatch): IVoltageStressActions => ({
  loadVoltageStressData: (assetId: string, chartGroup: ChartGroup) => {
    return dispatch(loadVoltageStressData(assetId, chartGroup));
  },
  removeVoltageStressData: (assetId: string) =>
    dispatch(removeVoltageStressData(assetId))
});

export default connect(mapStateToProps, mapDispatchToProps)(VoltageStress);
