// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { FormattedMessage } from "react-intl";
import styled from "styled-components";

interface INoReportsProps {
  className?: string;
}

const NoReports = ({ className }: INoReportsProps) => {
  return (
    <div className={className}>
      <FormattedMessage
        id="detail_page.widgets.embedded_reports.no_reports"
        defaultMessage="No reports"
      />
    </div>
  );
};

export default styled(NoReports)`
  height: 100px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
