// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Typography } from "antd";
import {
  DateDifferenceService,
  Formats
} from "common/formatters/DateDifference";
import Popover from "common/popover/Popover";
import AssetRiskLastUpdatedTooltipExtended from "common/tooltips/AssetRiskExecutionDateTooltipExtended";
import IAssetStatusUpdateInfo from "models/IAssetStatusUpdateInfo";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import Label from "./Label";

const { Text } = Typography;

interface ILastUpdateProps {
  degradationScoreDate: string;
  degradationScoreExecutionDate: string;
  latestParameterDate: string;
  statusUpdateInfo: IAssetStatusUpdateInfo;
}

const LastUpdate = ({
  degradationScoreDate,
  degradationScoreExecutionDate,
  latestParameterDate,
  statusUpdateInfo
}: ILastUpdateProps) => {
  const intl = useIntl();

  const dateToCalculate = useMemo(() => {
    return (
      degradationScoreExecutionDate ??
      degradationScoreDate ??
      latestParameterDate ??
      statusUpdateInfo?.Date
    );
  }, [
    degradationScoreExecutionDate,
    degradationScoreDate,
    latestParameterDate,
    statusUpdateInfo
  ]);

  const lastUpdatedBefore = useMemo(() => {
    return DateDifferenceService.format(dateToCalculate, intl, Formats.Long);
  }, [dateToCalculate, intl]);

  return (
    <CenteredColumnWrapper className="asset-last-update asset-ribbon-section">
      <div
        className="asset-last-update__label"
        data-qa="header-last-update-label"
      >
        <Label>
          <FormattedMessage
            id="asset_detail_page.header.last_update"
            defaultMessage="Last update"
          />
        </Label>
      </div>
      <div
        className="asset-last-update__value"
        data-qa="header-last-update-value"
      >
        <Popover
          trigger="hover"
          placement="bottom"
          content={
            <AssetRiskLastUpdatedTooltipExtended
              lastUpdatedBefore={lastUpdatedBefore}
              conditionCalculationDate={degradationScoreExecutionDate}
              conditionDate={degradationScoreDate}
              latestParameterDate={latestParameterDate}
              statusUpdateInfo={statusUpdateInfo}
            />
          }
        >
          <Text>
            <FormattedMessage
              defaultMessage="{value} ago"
              id="asset_detail_page.header.last_update.value"
              values={{
                value: lastUpdatedBefore
              }}
            />
          </Text>
        </Popover>
      </div>
    </CenteredColumnWrapper>
  );
};

const CenteredColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default LastUpdate;
