// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { DataItem } from "billboard.js";
import IAssetPoint from "common/AssetRiskMatrix/models/IAssetPoint";
import ICluster from "common/AssetRiskMatrix/models/ICluster";
import IPrognosticPoint from "common/AssetRiskMatrix/models/IPrognosticPoint";
import { isEmpty } from "lodash";
import React, { useCallback } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { useIntl } from "react-intl";
import AssetRiskMatrixTooltip from "../../AssetRiskMatrixTooltip";
import ClusterRiskMatrixTooltip from "../../ClusterRiskMatrixTooltip";
import PrognosticRiskMatrixTooltip from "../../PrognosticRiskMatrixTooltip";

interface IUseAssetRiskMatrixTooltipsProps {
  grayscale: boolean;
  getAssetData: (seriesName: string, idx: number) => IAssetPoint;
  getClusterData: (seriesName: string, idx: number) => ICluster;
  getPrognosticPointData: (seriesName: string, idx: number) => IPrognosticPoint;
}

const useAssetRiskMatrixTooltips = ({
  grayscale,
  getAssetData,
  getClusterData,
  getPrognosticPointData
}: IUseAssetRiskMatrixTooltipsProps) => {
  const intl = useIntl();
  const isAssetData = useCallback(
    (seriesName: string) =>
      seriesName === "current_asset" || seriesName === "other_assets",
    []
  );

  const isPrognosticData = useCallback(
    (seriesName: string) => seriesName === "prognostic",
    []
  );

  const getAssetTooltipContent = useCallback(
    (seriesName: string, idx: number) => {
      const asset = getAssetData(seriesName, idx);

      if (asset) {
        return renderToStaticMarkup(
          <AssetRiskMatrixTooltip assetPoint={asset} intl={intl} />
        );
      }

      return "";
    },
    [getAssetData, intl]
  );

  const getClusterTooltipContent = useCallback(
    (seriesName: string, idx: number) => {
      const cluster = getClusterData(seriesName, idx);

      if (cluster) {
        return renderToStaticMarkup(
          <ClusterRiskMatrixTooltip cluster={cluster} intl={intl} />
        );
      }

      return "";
    },
    [getClusterData, intl]
  );

  const getPrognosticTooltipContent = useCallback(
    (seriesName: string, idx: number) => {
      const prognostic = getPrognosticPointData(seriesName, idx);

      if (prognostic) {
        return renderToStaticMarkup(
          <PrognosticRiskMatrixTooltip intl={intl} prognostic={prognostic} />
        );
      }

      return "";
    },
    [getPrognosticPointData, intl]
  );

  const getTooltipContent = useCallback(
    (data: DataItem[]) => {
      if (!isEmpty(data) && !grayscale) {
        const id: string = data[0].id;
        const idx: number = data[0].index;

        if (isAssetData(id)) {
          return getAssetTooltipContent(id, idx);
        } else if (id.startsWith("cluster")) {
          return getClusterTooltipContent(id, idx);
        } else if (isPrognosticData(id)) {
          return getPrognosticTooltipContent(id, idx);
        }
      }

      return "";
    },
    [
      grayscale,
      isAssetData,
      isPrognosticData,
      getAssetTooltipContent,
      getClusterTooltipContent,
      getPrognosticTooltipContent
    ]
  );

  return { getTooltipContent };
};

export default useAssetRiskMatrixTooltips;
