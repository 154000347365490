// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Statuses } from "core/data/models/Data";
import { loadContactWearAction } from "features/detailpage/features/aging/actions/AgingContactWearActions";
import AgingContactWear from "features/detailpage/features/aging/components/AgingContactWear";
import useAgingContactWear from "features/detailpage/features/aging/hooks/useAgingContactWear";
import { isEmpty } from "lodash";
import React from "react";
import { useAppDispatch } from "store";

interface IAgingContactWearContainerProps {
  assetId: string;
}

export default ({ assetId }: IAgingContactWearContainerProps) => {
  const dispatch = useAppDispatch();
  const { agingContactWear } = useAgingContactWear();

  React.useEffect(() => {
    if (
      agingContactWear.status !== Statuses.Succeeded ||
      isEmpty(agingContactWear.data)
    ) {
      dispatch(loadContactWearAction(assetId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <AgingContactWear agingContactWear={agingContactWear} />;
};
