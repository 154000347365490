// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { ITranslations } from "core/app/reducers/TranslationsReducer";
import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { IAjaxAction } from "reducers/Index";
import { AppDispatch } from "store";
import { config } from "utils/AppConfig";

export enum Actions {
  Translations_SetTranslation = "Translations_SetTranslation"
}

const setTranslationsAction = (
  request: JQueryXHR,
  data?: ITranslations
): IAjaxAction<ITranslations> => {
  const action: IAjaxAction<ITranslations> = {
    type: Actions.Translations_SetTranslation,
    request,
    data
  };
  return action;
};

export const loadTranslations = (
  dispatch: AppDispatch,
  locale: string | null
) => {
  const url = UrlService.getApiUrl(config.app.localesApiUrl, [
    { name: "locale", value: locale || "" }
  ]);

  const request = EndpointService.getJson<ITranslations>(
    url,
    (request, data) => {
      dispatch(setTranslationsAction(request, data));
    },
    (request) => {
      dispatch(setTranslationsAction(request));
    }
  );

  dispatch(setTranslationsAction(request));
};
