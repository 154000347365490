// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Processing from "components/common/Processing";
import { FormattedMessage } from "react-intl";
import { styled } from "styled-components";
import {
  fontSizeMedium,
  iconFontSizeLarge,
  spacingMedium,
  spacingXLarge
} from "styles/StyleVariables";
import Item, { IWatchListItem } from "./Item";

interface IListProps {
  isLoading: boolean;
  data: IWatchListItem[];
}

const List = ({ isLoading, data }: IListProps) => {
  if (isLoading) {
    return <Processing />;
  }

  if (data.length === 0) {
    return (
      <NoAssets>
        <NoAssetsTitle>
          <FormattedMessage
            id="homepage.watch_list.no_assets.title"
            defaultMessage="There are no assets here."
          />
        </NoAssetsTitle>
        <NoAssetsText>
          <FormattedMessage
            id="homepage.watch_list.no_assets.text"
            defaultMessage="Start watching assets in your fleet."
          />
        </NoAssetsText>
      </NoAssets>
    );
  }

  return (
    <>
      {data.map((item) => (
        <Item key={item.AssetId} {...item} />
      ))}
    </>
  );
};

const NoAssets = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${spacingXLarge};
  text-align: center;
`;

const NoAssetsTitle = styled.div`
  font-size: ${iconFontSizeLarge};
  margin-bottom: ${spacingMedium};
`;

const NoAssetsText = styled.div`
  font-size: ${fontSizeMedium};
`;

export default List;
