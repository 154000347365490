// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import {
  colorGray100,
  colorPrimary
} from "@pg/common/build/styles/ColorVariables";

export const getSeriesColor = (seriesName: string) => {
  switch (true) {
    case seriesName.startsWith("online") || seriesName.startsWith("advanced"):
      return colorPrimary;
    case seriesName.startsWith("offline"):
      return colorGray100;
    default:
      return null;
  }
};
