// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React, { ReactNode } from "react";
import { Typography } from "antd";

import "./NumberValue.less";

interface INumberValueProps {
  children?: ReactNode;
  className?: string;
}

const { Title } = Typography;

const NumberValue = ({ children, className }: INumberValueProps) => {
  return (
    <Title
      className={`asset-ribbon-number-value ${className ? className : ""}`}
      level={2}
    >
      {children}
    </Title>
  );
};

export default NumberValue;
