// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { groupBy } from "lodash";
import {
  colorPrimary,
  colorStatusOrange,
  colorStatusYellow
} from "styles/ColorVariables";

import ITick from "../models/ITick";
import Scale from "../models/Scale";
import Svg from "../models/Svg";
import TickTypes from "../models/TickTypes";

const colorGridlineNormal = "#a0a0a0";
const colorGridlineAlert = colorStatusOrange;
const colorGridlineWarning = colorStatusYellow;
const colorLabelPrimary = colorPrimary;
const fontSizeSmall = "8.5pt";
const shift = 4;

class GridlineService {
  static drawGridlines = (
    svg: Svg,
    width: number,
    height: number,
    ticks: ITick[],
    scale: Scale
  ) => {
    ticks.forEach((t) => {
      const circle = svg.append("circle");

      circle
        .attr("cx", width / 2)
        .attr("cy", height / 2)
        .attr("fill", "none")
        .attr("r", scale(t.value));

      const groupsByType = groupBy(
        ticks.filter((x) => x.value === t.value),
        (t) => t.type
      );
      const showNormal = !(Object.keys(groupsByType).length > 1);

      if (t.type === TickTypes.Normal && showNormal) {
        circle.attr("stroke", colorGridlineNormal);
      }
      if (t.type === TickTypes.Alert) {
        circle.attr("stroke", colorGridlineAlert);
        circle.attr("stroke-dasharray", 10);
        circle.attr("stroke-width", 2);
      }
      if (t.type === TickTypes.Warning) {
        circle.attr("stroke", colorGridlineWarning);
        circle.attr("stroke-dasharray", 10);
        circle.attr("stroke-width", 2);
      }
    });

    GridlineService.drawGridlinesLabels(svg, width, height, ticks, scale);
  };

  private static drawGridlinesLabels = (
    svg: Svg,
    width: number,
    height: number,
    ticks: ITick[],
    scale: Scale
  ) => {
    const labels = [
      0,
      ...ticks.filter((t) => t.type === TickTypes.Normal).map((t) => t.value)
    ];

    labels.forEach((l) => {
      const x = height / 2 + scale(l) + shift;
      const y = width / 2 + 3 * shift;

      svg
        .append("text")
        .attr("x", x)
        .attr("y", y)
        .attr("font-size", fontSizeSmall)
        .attr("fill", colorLabelPrimary)
        .text(`${l.toString()}%`);
    });
  };
}

export default GridlineService;
