// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { loadRiskMatrixConfiguration } from "features/detailpage/features/riskmatrix/actions/RiskMatrixActions";
import { useCallback } from "react";
import { useAppDispatch } from "store";

const useLoadRiskMatrixConfiguration = () => {
  const dispatch = useAppDispatch();
  return useCallback(() => loadRiskMatrixConfiguration(dispatch), [dispatch]);
};

export default useLoadRiskMatrixConfiguration;
