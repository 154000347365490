// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Drawer, Typography } from "antd";
import { IRow, IRowData } from "common/datagrid/DataGrid";
import Switch from "common/form/components/Switch";
import { AssetRiskEnum } from "models/AssetRisks";
import { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { colorWidgetBackground } from "styles/ColorVariables";
import {
  defaultDrawerWidth,
  spacingMedium,
  spacingXXLarge
} from "styles/StyleVariables";
import { riskSwitchOptions } from "../constants/riskSwitchOptions";
import ComponentsRisks from "./ComponentsRisks";
import SidePanelGrid from "./SidePanelGrid";

const { Text } = Typography;

export interface ISidePanelStateProps {
  row: IRow<IRowData>;
  risk: AssetRiskEnum;
}

interface ISidePanelContentProps extends ISidePanelStateProps {
  isSidePanelVisible: boolean;
  handleSidePanelClose: () => void;
  className?: string;
}

const SidePanelContent = ({
  row,
  risk,
  handleSidePanelClose,
  isSidePanelVisible,
  className
}: ISidePanelContentProps) => {
  const [riskState, setRiskState] = useState(risk);

  useEffect(() => {
    setRiskState(risk);
  }, [risk]);

  const handleRiskChange = useCallback((risk: AssetRiskEnum) => {
    setRiskState(risk);
  }, []);

  return (
    <StyledDrawer
      placement="right"
      width={defaultDrawerWidth}
      onClose={handleSidePanelClose}
      open={isSidePanelVisible}
      getContainer={false}
      destroyOnClose={true}
      mask={false}
      className={className}
      zIndex={900}
    >
      {row && (
        <>
          <AssetSummary className="asset-summary">
            <AssetDetails className="asset-details">
              <AssetInfo>
                <AssetId strong>{row.data["AssetId"]}</AssetId>
                <FormattedMessage
                  defaultMessage="{location} / {type}"
                  id="component_risk_summary_page.side_panel.location_type"
                  values={{
                    location: row.data["AssetLocation"],
                    type: row.data["AssetType"]
                  }}
                />
              </AssetInfo>
              <Switch
                defaultSelected={risk}
                items={riskSwitchOptions}
                onChange={handleRiskChange}
              />
            </AssetDetails>
            <ComponentsRisks row={row} />
          </AssetSummary>
          <SidePanelGrid row={row} risk={riskState}></SidePanelGrid>
        </>
      )}
    </StyledDrawer>
  );
};

const StyledDrawer = styled(Drawer)`
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
  position: absolute;

  .ant-drawer-wrapper-body {
    background: ${colorWidgetBackground};
  }

  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
`;

const AssetSummary = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${spacingXXLarge};
`;

const AssetDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: ${spacingXXLarge};
  flex-wrap: wrap;
`;

const AssetInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${spacingMedium};
`;

const AssetId = styled(Text)`
  white-space: pre-wrap;
  word-break: break-word;
`;

export default SidePanelContent;
