// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { isNil } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import useParametersTranslations from "../../../hooks/useParametersTranslations";
import IMechanicalPoles from "../models/IMechanicalPoles";
import mapToPole from "../utils/mapToPole";

const useMechanical = (
  mechanical: IMechanicalPoles,
  onAdjustExpectedRangeClick?: (
    parameterName: string,
    success?: () => void,
    error?: () => void
  ) => void,
  onAcknowledgeClick?: (
    parameterName: string,
    success?: () => void,
    error?: () => void
  ) => void
) => {
  const intl = useIntl();
  const [processing, setProcessing] = useState(false);
  const { translations } = useParametersTranslations();

  const pole1 = useMemo(
    () => mapToPole(mechanical.Pole1, intl, 1),
    [mechanical.Pole1, intl]
  );

  const pole2 = useMemo(
    () => mapToPole(mechanical.Pole2, intl, 2),
    [mechanical.Pole2, intl]
  );

  const pole3 = useMemo(
    () => mapToPole(mechanical.Pole3, intl, 3),
    [mechanical.Pole3, intl]
  );

  const formatValue = useCallback(
    (value: number | string | null) =>
      typeof value === "number"
        ? intl.formatNumber(value, { maximumFractionDigits: 1 })
        : isNil(value)
        ? "-"
        : value,

    [intl]
  );

  const handleAdjustExpectedRangeClick = useCallback(
    (parameterName: string) => {
      if (onAdjustExpectedRangeClick) {
        setProcessing(true);
        onAdjustExpectedRangeClick(
          parameterName,
          () => {
            setProcessing(false);
          },
          () => {
            setProcessing(false);
          }
        );
      }
    },
    [onAdjustExpectedRangeClick]
  );

  const handleAcknowledgeClick = useCallback(
    (parameterName: string) => {
      if (onAcknowledgeClick) {
        setProcessing(true);
        onAcknowledgeClick(
          parameterName,
          () => {
            setProcessing(false);
          },
          () => {
            setProcessing(false);
          }
        );
      }
    },
    [onAcknowledgeClick]
  );

  return {
    formatValue,
    handleAdjustExpectedRangeClick,
    handleAcknowledgeClick,
    pole1,
    pole2,
    pole3,
    processing,
    translations
  };
};

export default useMechanical;
