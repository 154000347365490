// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Icon, notifications } from "@pg/common";
import addToWatchList from "common/watchIcon/actions/addToWatchList";
import removeFromWatchList from "common/watchIcon/actions/removeFromWatchList";
import IWatchIconTranslations from "common/watchIcon/models/IWatchIconTranslations";
import { useCallback, useState } from "react";
import styled from "styled-components";
import { colorGray0, colorIconAccent } from "styles/ColorVariables";

interface IAssetWatchListIcon {
  assetId: string;
  assetWatched: boolean;
  translations: IWatchIconTranslations;
  getAddErrorMessage: (request: JQuery.jqXHR) => string;
  getRemoveErrorMessage: (request: JQuery.jqXHR) => string;
}

function AssetWatchListIcon({
  assetId,
  assetWatched,
  translations,
  getAddErrorMessage,
  getRemoveErrorMessage
}: Readonly<IAssetWatchListIcon>) {
  const [watched, setWatched] = useState(assetWatched);
  const [processing, setProcessing] = useState(false);

  const handleIconClick = useCallback(() => {
    if (watched) {
      setProcessing(true);
      removeFromWatchList(assetId)
        .then(() => {
          setWatched(false);
          notifications.success({
            message: translations.assetRemoved
          });
        })
        .catch((request: JQuery.jqXHR) => {
          const message = getRemoveErrorMessage(request);
          notifications.error({
            message
          });
        })
        .finally(() => {
          setProcessing(false);
        });
    } else {
      setProcessing(true);
      addToWatchList(assetId)
        .then(() => {
          setWatched(true);
          notifications.success({
            message: translations.assetAdded
          });
        })
        .catch((request) => {
          const message = getAddErrorMessage(request);
          notifications.error({
            message
          });
        })
        .finally(() => {
          setProcessing(false);
        });
    }
  }, [
    watched,
    assetId,
    translations,
    getRemoveErrorMessage,
    getAddErrorMessage
  ]);

  return (
    <IconWrapper
      className={`watch-list-component`}
      data-qa={`watch-list-component`}
      onClick={(e: React.MouseEvent<Element, MouseEvent>) => {
        e.stopPropagation();
      }}
    >
      {processing ? (
        <Icon
          name="refresh"
          className="processing"
          spin
          data-qa="in-progress-icon"
          onClick={(e: React.MouseEvent<Element, MouseEvent>) => {
            e.stopPropagation();
          }}
        />
      ) : (
        <Icon
          name="grade"
          variant={watched ? "round" : "outlined"}
          className={"watched-icon"}
          onClick={(e: React.MouseEvent<Element, MouseEvent>) => {
            e.stopPropagation();
            handleIconClick();
          }}
        />
      )}
    </IconWrapper>
  );
}

const IconWrapper = styled.div`
  cursor: pointer;
  border: none;
  outline: none;
  background-color: ${colorGray0};
  padding-left: 0px;
  width: 40px;

  &:hover {
    color: ${colorIconAccent};
  }

  .spin {
    display: flex;
    height: 100%;
    align-items: center;

    .icon.processing {
      border: none !important;
    }
  }
`;

export default AssetWatchListIcon;
