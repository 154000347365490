// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import "./Risk.less";
import TooltipWrapper from "common/tooltipwrapper/TooltipWrapper";

interface IRiskProps {
  risk: string;
  Tooltip?: React.ComponentType;
  className?: string;
}

const Risk = (props: IRiskProps) => {
  const { risk, Tooltip, className } = props;
  const transformedRisk = risk.toLowerCase();

  return Tooltip
    ? getColumnWithTooltip(transformedRisk, Tooltip, className)
    : getColumn(transformedRisk, className);
};

export default Risk;

// #region Functions

function getColumn(transformedRisk: string, className?: string): JSX.Element {
  return (
    <div className={`column risk ${className}`}>
      <div
        className={`asset-risk-bg column-tooltip ${transformedRisk}`}
        data-qa="risk-color"
      />
    </div>
  );
}

function getColumnWithTooltip(
  transformedRisk: string,
  Tooltip: React.ComponentType,
  className?: string
): JSX.Element {
  return (
    <div className="column risk">
      <TooltipWrapper Tooltip={() => <Tooltip />}>
        <div
          className={`asset-risk-bg column-tooltip ${transformedRisk}`}
          data-qa="risk-color"
        />
      </TooltipWrapper>
    </div>
  );
}

// #endregion
