// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Tooltip } from "antd";
import MaintenanceScore from "common/formatters/MaintenanceScore";
import IIssue from "features/detailpage/features/issues/models/IIssue";
import { FormattedMessage } from "react-intl";

interface IIssueDetailsMaintenancePriorityProps {
  issue: IIssue;
}

const IssueDetailsMaintenancePriority = ({
  issue
}: IIssueDetailsMaintenancePriorityProps) => (
  <div>
    <div className="default-grey-label">
      <FormattedMessage
        defaultMessage="Maintenance Priority"
        id="detail_page.issues.issue_details.maintenance-priority"
      />
    </div>
    <div className="value">
      <Tooltip
        placement="top"
        title={<MaintenancePriorityTooltip issue={issue} />}
      >
        {issue.TotalNumberOfIssues > 0 ? (
          <span>
            <FormattedMessage
              defaultMessage="{position} {of} {total}"
              id="detail_page.issues.issue.maintenance_priority.ordinal_number_of"
              values={{
                position: (
                  <span className="bold">
                    <FormattedMessage
                      defaultMessage="#{issuePosition, selectordinal, one {#st} two {#nd} few {#rd} other {#th}}"
                      id="detail_page.issues.issue.maintenance_priority.ordinal_number_of.position"
                      values={{
                        issuePosition: issue.MaintenancePriorityScoreOrder
                      }}
                    />
                  </span>
                ),
                of: (
                  <FormattedMessage
                    defaultMessage="out of"
                    id="detail_page.issues.issue.maintenance_priority.ordinal_number_of.out_.of"
                  />
                ),
                total: (
                  <span className="bold">
                    <FormattedMessage
                      defaultMessage="{issueCount, number}"
                      id="detail_page.issues.issue.maintenance_priority.ordinal_number_of.total"
                      values={{
                        issueCount: issue.TotalNumberOfIssues
                      }}
                    />
                  </span>
                )
              }}
            />
          </span>
        ) : (
          <span>-</span>
        )}
      </Tooltip>
    </div>
  </div>
);

export default IssueDetailsMaintenancePriority;

interface IMaintenancePriorityTooltipProps {
  issue: IIssue;
}

const MaintenancePriorityTooltip = ({
  issue
}: IMaintenancePriorityTooltipProps) => {
  return (
    <div className="tooltip-maintenance-priority">
      <FormattedMessage
        defaultMessage="Maintenance Priority: {value}"
        id="detail_page.issues.issue.tooltip-maintenance-priority.label"
        values={{
          value: <MaintenanceScore value={issue.MaintenancePriority} />
        }}
      />
    </div>
  );
};
