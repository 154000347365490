// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import IAssetStatus from "models/IAssetStatus";
import isExactLifecycleStatus from "utils/isExactLifecycleStatus";
import LifecycleStatus from "../../../models/LifecycleStatus";
import ILifecycleStatusStyle from "../models/ILifecycleStatusStyle";

const getLifecycleStatusStyle = (
  lifecycleStatus: IAssetStatus["LifecycleStatus"]
): ILifecycleStatusStyle | undefined => {
  if (isExactLifecycleStatus(lifecycleStatus, LifecycleStatus.InReserve))
    return { iconName: "inventory_2", className: "in-reserve" };
  if (isExactLifecycleStatus(lifecycleStatus, LifecycleStatus.Removed))
    return { iconName: "remove_circle_outline", className: "removed" };
  if (isExactLifecycleStatus(lifecycleStatus, LifecycleStatus.InService))
    return { iconName: undefined, className: "in-service" };
};

export default getLifecycleStatusStyle;
