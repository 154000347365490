// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import MotorStartsBarChart from "@apm/widgets/build/widgets/MotorStartsBarChart";
import Processing from "components/common/Processing";
import {
  WidgetErrorMessage,
  WidgetNoDataMessage
} from "components/common/widget/Widget";
import Data, { Statuses } from "core/data/models/Data";
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import { AppDispatch } from "store";
import loadMotorStarts from "../actions/loadMotorStarts";
import useMotorStarts from "../hooks/useMotorStarts";
import IMotorStarts from "../models/IMotorStarts";
import getMotorStartsSelector from "../selectors/getMotorStartsSelector";

interface IMotorStartsData {
  motorStarts: Data<IMotorStarts>;
}

interface IMotorStartsActions {
  loadMotorStarts: (assetId: string) => void;
}

interface IMotorStartsOwnProps {
  assetId: string;
}

type MotorStartsProps = IMotorStartsOwnProps &
  IMotorStartsData &
  IMotorStartsActions;

const MotorStarts = ({
  assetId,
  motorStarts,
  loadMotorStarts
}: MotorStartsProps) => {
  const { title, series, emptyTranslation, formatXTick, formatYTick } =
    useMotorStarts({
      assetId,
      motorStarts,
      loadMotorStarts
    });

  switch (motorStarts?.status) {
    case Statuses.Loading:
      return <Processing />;
    case Statuses.Succeeded:
      return motorStarts?.data ? (
        <MotorStartsBarChart
          title={title}
          series={series}
          formatXTick={formatXTick}
          formatYTick={formatYTick}
          height={240}
          emptyTranslation={emptyTranslation}
        />
      ) : (
        <WidgetNoDataMessage />
      );
    case Statuses.NotFound:
      return <WidgetNoDataMessage />;
    case Statuses.Failed:
      return <WidgetErrorMessage />;
    default:
      return null;
  }
};

const mapStateToProps = (
  state: IState,
  { assetId }: IMotorStartsOwnProps
): IMotorStartsData => ({
  motorStarts: getMotorStartsSelector(state)(assetId)
});

const mapDispatchToProps = (dispatch: AppDispatch): IMotorStartsActions => ({
  loadMotorStarts: (assetId: string) => dispatch(loadMotorStarts(assetId))
});

export default connect(mapStateToProps, mapDispatchToProps)(MotorStarts);
