// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import * as React from "react";

import useNotificationsNavbarContainer from "../hooks/useNotificationsNavbarContainer";

import NotificationsNavbar from "../components/NotificationsNavBar";

const NotificationsNavbarContainer = () => {
  const {
    canBeSaved,
    userHasEmail,
    notificationsEnabled,
    hasValidData,
    setNotificationsEnabled,
    setNotificationsDisabled,
    onSaveButtonClicked,
    offButtonRef
  } = useNotificationsNavbarContainer();

  return (
    <NotificationsNavbar
      canBeSaved={canBeSaved}
      userHasEmail={userHasEmail}
      notificationsEnabled={notificationsEnabled}
      hasValidData={hasValidData}
      onOnButtonClicked={setNotificationsEnabled}
      onOffButtonClicked={setNotificationsDisabled}
      onSaveButtonClicked={onSaveButtonClicked}
      offButtonRef={offButtonRef}
    />
  );
};

export default NotificationsNavbarContainer;
