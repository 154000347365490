// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import SearchParams from "@pg/common/build/models/SearchParams";
import { Typography } from "antd";
import Processing from "components/common/Processing";
import { routes } from "core/app/components/AppRoutes";
import { useAppNavigate } from "core/app/components/RouterProvider";
import Data, { Statuses } from "core/data/models/Data";
import IAssetTree from "features/detailpage/features/assettree/models/IAssetTree";
import getAssetTreeSelector from "features/detailpage/features/assettree/selectors/getAssetTreeSelector";
import useNavToAsset from "hooks/useNavToAsset";
import IAssetStatus from "models/IAssetStatus";
import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { IState } from "reducers/Index";
import isRowLinkClicked from "utils/isRowLinkClicked";
import getLifecycleStatusStyle from "../utils/getLifecycleStatusStyle";
import "./SidePanel.less";
import Tooltip from "common/Tooltip";

const { Text } = Typography;

export interface ISidePanelActions {}

export interface ISidePanelRouteParams {
  assetId: string;
}

export interface ISidePanelData {
  assetTree?: Data<IAssetTree>;
}

export interface ISidePanelProps extends ISidePanelActions, ISidePanelData {}

const SidePanel = (props: ISidePanelProps) => {
  const location = useLocation();
  const navigate = useAppNavigate();
  const isSelected = (componentId: string): boolean =>
    componentId === new SearchParams(location.search).get("assetId");
  const { navigateTo } = useNavToAsset();

  const getDetailsNode = (
    assetId: string,
    risk: string,
    lifecycleStatus: IAssetStatus["LifecycleStatus"],
    isComponent: boolean
  ): React.ReactNode => {
    const statusStyle = getLifecycleStatusStyle(lifecycleStatus);

    const currentAssetId = new SearchParams(location.search).get("assetId");

    return (
      <div
        className="details"
        onClick={(e) => {
          if (isRowLinkClicked(e.target)) return;

          const currentAssetId = new SearchParams(location.search).get(
            "assetId"
          );
          if (currentAssetId !== assetId) {
            const searchParams = new SearchParams({ assetId });
            navigate({
              pathname: routes.detailPage.pathname,
              search: searchParams.toString()
            });
          }
        }}
        style={{
          paddingLeft: isComponent ? 40 : 15,
          paddingRight: 20
        }}
      >
        <Tooltip title={assetId}>
          <Link
            data-qa="link"
            to={
              currentAssetId !== assetId
                ? navigateTo(assetId)
                : navigateTo(currentAssetId)
            }
            className="link-label"
          >
            <div className={`risk ${risk}`} hidden={!risk} />
            <div className="name-icon">
              {!isComponent ? <Icon name="folder_open" size="md" /> : null}
              <Text className={`name ${isComponent ? "no-icon" : ""}`} strong>
                {assetId}
              </Text>
            </div>
            <div className="status">
              {statusStyle?.iconName && (
                <Icon variant="outlined" name={statusStyle.iconName} />
              )}
            </div>
          </Link>
        </Tooltip>
      </div>
    );
  };

  const getComponentNode = (
    componentId: string,
    risk: string,
    lifecycleStatus: IAssetStatus["LifecycleStatus"]
  ) => {
    return (
      <div
        key={componentId}
        className={`component ${isSelected(componentId) ? "active" : ""}`}
      >
        {getDetailsNode(componentId, risk, lifecycleStatus, true)}
      </div>
    );
  };

  const getProcessingNode = (): React.ReactNode => (
    <Processing className="spinner margin small light" />
  );

  const getAssetNode = (): React.ReactNode => {
    return (
      <div className="asset active">
        {getDetailsNode(
          props.assetTree.data.Id,
          null,
          props.assetTree.data.LifecycleStatus,
          false
        )}
        {props.assetTree.data.Components.map((c) =>
          getComponentNode(c.Id, c.Risk, c.LifecycleStatus)
        )}
      </div>
    );
  };

  const getErrorMessageNode = (): React.ReactNode => (
    <div className="error">
      <Icon name="error" size="md" />
      <FormattedMessage
        id="detail_page.side_panel.asset_tree.loading_failed"
        defaultMessage="Error"
      />
    </div>
  );

  let component: React.ReactNode = null;
  if (props.assetTree.status === Statuses.Loading)
    component = getProcessingNode();
  if (props.assetTree.status === Statuses.Succeeded) component = getAssetNode();
  if (props.assetTree.status === Statuses.Failed)
    component = getErrorMessageNode();

  return (
    <div className="detail-page-side-panel" data-qa="asset-tree">
      {component}
    </div>
  );
};

const mapStateToProps = (state: IState) => {
  const props: ISidePanelData = {
    assetTree: getAssetTreeSelector(state)
  };
  return props;
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SidePanel);
