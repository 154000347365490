// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import IField from "../../../models/IField";
import ISelectOption from "../../../models/ISelectOption";

const isSelectOption = (
  listValues: IField["listValues"]
): listValues is ISelectOption[] => {
  return (
    Array.isArray(listValues) &&
    listValues.some(
      (listValue) => typeof listValue === "object" && "label" in listValue
    )
  );
};

export default isSelectOption;
