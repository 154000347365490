// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import IAssetPoint from "common/AssetRiskMatrix/models/IAssetPoint";
import IAssetRiskConfigurationValue from "common/AssetRiskMatrix/models/IAssetRiskConfigurationValue";
import IChartSize from "common/AssetRiskMatrix/models/IChartSize";
import ICluster from "common/AssetRiskMatrix/models/ICluster";
import IPrognosticPoint from "common/AssetRiskMatrix/models/IPrognosticPoint";
import BBChart from "common/chart/components/BBChart";
import React from "react";
import useAssetRiskMatrix from "../hooks/useAssetRiskMatrix";
import "./AssetRiskMatrix.less";

interface IAssetRiskMatrixProps {
  className?: string;
  currentAsset?: IAssetPoint;
  otherAssets?: IAssetPoint[];
  clusters?: ICluster[];
  prognosticPoints?: IPrognosticPoint[];
  thresholds: IAssetRiskConfigurationValue;
  showLines?: boolean;
  grayscale?: boolean;
  chartSize?: IChartSize;
  onAssetClick?: (assetId: string) => void;
  onClusterClick?: (cluster: ICluster) => void;
}

const AssetRiskMatrix = ({
  className,
  currentAsset,
  otherAssets,
  thresholds,
  clusters,
  prognosticPoints,
  showLines,
  grayscale,
  chartSize,
  onAssetClick,
  onClusterClick
}: IAssetRiskMatrixProps) => {
  const { configuration, handleChartRendered } = useAssetRiskMatrix({
    currentAsset,
    otherAssets,
    clusters,
    thresholds,
    prognosticPoints,
    showLines,
    grayscale,
    chartSize,
    onAssetClick,
    onClusterClick
  });
  const classNames = ["asset-risk-chart"];
  if (grayscale) {
    classNames.push("asset-risk-chart-grayscale");
  }
  if (className) {
    classNames.push(className);
  }

  return (
    <BBChart
      className={classNames.join(" ")}
      configuration={configuration}
      onRendered={handleChartRendered}
    />
  );
};

export default AssetRiskMatrix;
