// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Button, Typography } from "antd";
import * as React from "react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import "./NotificationsNavBar.less";
import SaveButton from "./SaveButton";

const { Title } = Typography;

interface INotificationsNavBarProps {
  canBeSaved?: boolean;
  hasValidData?: boolean;
  notificationsEnabled?: boolean;
  userHasEmail?: boolean;
  onSaveButtonClicked?: () => void;
  onOnButtonClicked?: () => void;
  onOffButtonClicked?: (intl: IntlShape) => void;
  offButtonRef: React.MutableRefObject<HTMLDivElement>;
}

const NotificationsNavBar = ({
  canBeSaved,
  hasValidData,
  notificationsEnabled,
  userHasEmail,
  onSaveButtonClicked,
  onOffButtonClicked,
  onOnButtonClicked,
  offButtonRef
}: INotificationsNavBarProps) => {
  const intl = useIntl();

  return (
    <div className="c-navbar">
      <div className="header" data-qa="notifications-header-name">
        <Title level={4}>
          <FormattedMessage
            id="notifications.notifications_page_header"
            defaultMessage="E-mail Notification Preferences"
          />
        </Title>
      </div>
      <div className="header-buttons" data-qa="header-buttons">
        <div>
          <div className="button" data-qa="save-button">
            {userHasEmail && canBeSaved && notificationsEnabled ? (
              <SaveButton
                hasValidData={hasValidData}
                textMessageDescriptor={{
                  id: "notifications.save",
                  defaultMessage: "Save"
                }}
                onClick={onSaveButtonClicked}
              />
            ) : null}
          </div>
          <div className="button" data-qa="on-button">
            <Button
              type="default"
              disabled={notificationsEnabled || !userHasEmail}
              onClick={onOnButtonClicked}
            >
              <FormattedMessage id="notifications.on" defaultMessage="On" />
            </Button>
          </div>

          <div className="button" data-qa="off-button" ref={offButtonRef}>
            <Button
              type="default"
              disabled={!notificationsEnabled}
              onClick={() => onOffButtonClicked(intl)}
            >
              <FormattedMessage id="notifications.off" defaultMessage="Off" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationsNavBar;
