// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import IDegradationScoreTrend from "features/detailpage/features/risktrend/models/IDegradationScoreTrend";
import IPrognosticGridRowsInfo from "../models/IPrognosticGridRowsInfo";
import PrognosticGridRowCreator from "../services/PrognosticGridRowCreator";

interface IUsePrognosticGridRowsOptions {
  degradationScoreTrend: IDegradationScoreTrend;
  switchId: string;
  modelId: string;
  implementationId: string;
}

const usePrognosticGridRows = ({
  degradationScoreTrend,
  implementationId,
  modelId,
  switchId
}: IUsePrognosticGridRowsOptions) => {
  const intl = useIntl();
  const [prognosticRowInfo, setPrognosticRowInfo] =
    useState<IPrognosticGridRowsInfo>({
      rows: [],
      rowsReady: false
    });

  useEffect(() => {
    const calculatePrognosticRows = async () => {
      if (switchId === "grid" && !prognosticRowInfo?.rowsReady) {
        const rows = await new PrognosticGridRowCreator(
          modelId,
          implementationId,
          intl
        ).createRows(degradationScoreTrend);
        setPrognosticRowInfo({
          rows,
          rowsReady: true
        });
      }
    };

    calculatePrognosticRows();
  }, [
    degradationScoreTrend,
    implementationId,
    intl,
    modelId,
    prognosticRowInfo,
    switchId
  ]);

  return prognosticRowInfo;
};
export default usePrognosticGridRows;
