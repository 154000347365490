// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Action } from "redux";
import { AppDispatch } from "store";

import IValidator from "../validators/IValidator";

export enum Actions {
  Form_CreateForm = "Form_CreateForm",
  Form_RemoveForm = "Form_RemoveForm",
  Form_CreateInput = "Form_CreateInput",
  Form_SetInputValue = "Form_SetInputValue",
  Form_ResetForm = "Form_ResetForm"
}

export interface ICreateFormAction extends Action {
  formName: string;
}

export interface IResetFormAction extends Action {
  formName: string;
}

export interface IRemoveFormAction extends Action {
  formName: string;
}

export interface ICreateInputAction<T> extends Action {
  formName: string;
  inputName: string;
  defaultValue?: T;
  value?: T;
}

export interface ISetInputValue<T> extends Action {
  formName: string;
  inputName: string;
  value?: T;
  validators?: IValidator<T>[];
}

const createFormAction = (formName: string) => {
  const action: ICreateFormAction = {
    type: Actions.Form_CreateForm,
    formName
  };
  return action;
};

const resetFormAction = (formName: string) => {
  const action: IResetFormAction = {
    type: Actions.Form_ResetForm,
    formName
  };
  return action;
};

const removeFormAction = (formName: string) => {
  const action: IRemoveFormAction = {
    type: Actions.Form_RemoveForm,
    formName
  };
  return action;
};

const createInputAction = <T>(
  formName: string,
  inputName: string,
  defaultValue: T,
  value: T
) => {
  const action: ICreateInputAction<T> = {
    type: Actions.Form_CreateInput,
    formName,
    inputName,
    defaultValue,
    value
  };
  return action;
};

const setInputValueAction = <T>(
  formName: string,
  inputName: string,
  value: T,
  validators: IValidator<T>[]
) => {
  const action: ISetInputValue<T> = {
    type: Actions.Form_SetInputValue,
    formName,
    inputName,
    value,
    validators
  };
  return action;
};

export const createForm = (dispatch: AppDispatch, formName: string) => {
  const action = createFormAction(formName);
  dispatch(action);
};

export const resetForm = (dispatch: AppDispatch, formName: string) => {
  const action = resetFormAction(formName);
  dispatch(action);
};

export const removeForm = (dispatch: AppDispatch, formName: string) => {
  const action = removeFormAction(formName);
  dispatch(action);
};

export const createInput = <T>(
  dispatch: AppDispatch,
  formName: string,
  inputName: string,
  defaultValue: T,
  value: T
) => {
  const action = createInputAction(formName, inputName, defaultValue, value);
  dispatch(action);
};

export const setInputValue = <T>(
  dispatch: AppDispatch,
  formName: string,
  inputName: string,
  value: T,
  validators: IValidator<T>[]
) => {
  const action = setInputValueAction(formName, inputName, value, validators);
  dispatch(action);
};
