// Copyright 2016-2024 Hitachi Energy. All rights reserved.

const gasSensors = [
  "Calisto1",
  "Calisto2",
  "Calisto5",
  "Calisto9",
  "Coresense",
  "CoreSenseM10",
  "Hydran201I",
  "HydranM2",
  "Minitrans",
  "None",
  "ServeronTm1",
  "ServeronTm3",
  "ServeronTm8",
  "Transfix"
];

export default gasSensors;
