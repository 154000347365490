// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";
import { IState as IRootState } from "reducers/Index";

const getAgingContactWearSelector = createSelector(
  (state: IRootState) => state.detailPage.agingContactWear,
  agingContactWear => agingContactWear
);

export default getAgingContactWearSelector;
