// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import isPopoverClickEvent from "@pg/common/build/utils/isPopoverClickEvent";
import { Checkbox, Tooltip } from "antd";
import BodyCell from "common/datagrid/BodyCell";
import BodyRowActions from "common/datagrid/BodyRowActions";
import { IAction, ICheckbox, IColumn, IRow } from "common/datagrid/DataGrid";
import React, { useCallback, useMemo, useRef } from "react";
import ConditionalWrapper from "utils/ConditionalWrapper";

export const dragOverlapMargin = 2;

export interface IBodyRowProps {
  row: IRow;
  rows: IRow[];
  actions: IAction[];
  columns: IColumn[];
  gridId: string;
  showCheckboxColumn?: boolean;
  checkedRows?: IRow[];
  disabledTooltip: string;
  onCheckboxChange?: ICheckbox["onCheckboxChange"];
  isCheckboxDisabled?: ICheckbox["isCheckboxDisabled"];
  isCheckboxHidden?: (row: IRow) => boolean;
  checked?: (row: IRow) => boolean;
  onRowClick?: (e: React.MouseEvent<HTMLDivElement>, row: IRow) => void;
  getAreActionsAvailable?: (row: IRow) => boolean;
}

interface IPosition {
  x: number;
  y: number;
}

const BodyRow = (props: IBodyRowProps) => {
  const {
    actions,
    columns,
    gridId,
    onRowClick,
    row,
    rows,
    showCheckboxColumn,
    onCheckboxChange,
    isCheckboxDisabled,
    isCheckboxHidden,
    checkedRows,
    checked,
    disabledTooltip,
    getAreActionsAvailable
  } = props;

  const mouseDownPositionRef = useRef<IPosition>();
  const mouseUpPositionRef = useRef<IPosition>();

  const handleMouseDown = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    mouseDownPositionRef.current = {
      x: e.clientX,
      y: e.clientY
    };
  }, []);

  const handleMouseUp = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    mouseUpPositionRef.current = {
      x: e.clientX,
      y: e.clientY
    };
  }, []);

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (isPopoverClickEvent(e)) return;

      if (
        onRowClick &&
        Math.abs(
          mouseDownPositionRef.current.x - mouseUpPositionRef.current.x
        ) < dragOverlapMargin &&
        Math.abs(
          mouseDownPositionRef.current.y - mouseUpPositionRef.current.y
        ) < dragOverlapMargin
      ) {
        onRowClick(e, row);
      }
    },
    [onRowClick, row]
  );

  const cells = useMemo(
    () =>
      columns.map((c, i) => (
        <BodyCell
          column={c}
          gridId={gridId}
          key={c.config.id}
          row={row}
          rows={rows}
        />
      )),
    [columns, gridId, row, rows]
  );

  const checkboxDisabled = useMemo(
    () => !!isCheckboxDisabled?.(row, checkedRows),
    [isCheckboxDisabled, row, checkedRows]
  );

  const checkboxColumnBody = useMemo(
    () =>
      showCheckboxColumn && (
        <div
          onClick={(e) => e.stopPropagation()}
          className={`grid-cell checkbox-column-body`}
        >
          <div className="grid-cell-content">
            {!isCheckboxHidden?.(row) && (
              <ConditionalWrapper
                condition={checkboxDisabled && !!disabledTooltip}
                wrapper={(children) => (
                  <Tooltip title={disabledTooltip} placement="right">
                    {children}
                  </Tooltip>
                )}
              >
                <Checkbox
                  checked={checked?.(row)}
                  disabled={checkboxDisabled}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => onCheckboxChange?.(e, row, rows)}
                />
              </ConditionalWrapper>
            )}
          </div>
        </div>
      ),
    [
      showCheckboxColumn,
      isCheckboxHidden,
      row,
      checkboxDisabled,
      checked,
      disabledTooltip,
      onCheckboxChange,
      rows
    ]
  );

  return (
    <div className="grid-row-container">
      <div
        className={`
          grid-row
          ${onRowClick && "clickable"}
          ${row.state.selected && "selected"}
          ${checked(row) && "checked"}
          ${row.state.className}
        `}
        onClick={handleClick}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
      >
        {checkboxColumnBody}
        {cells}
        {(!getAreActionsAvailable || getAreActionsAvailable(row)) && (
          <BodyRowActions actions={actions} row={row} />
        )}
      </div>
    </div>
  );
};

export default BodyRow;
