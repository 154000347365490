// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Data from "core/data/models/Data";
import TrendComponent from "features/assetriskdashboard/components/AssetRiskDashboardTrend/TrendComponent";
import TrendChart from "features/assetriskdashboard/models/TrendChart";
import "./AssetRiskDashboardTrend.less";

export interface IAssetRiskDashboardTrendProps {
  trendChart: Data<TrendChart>;
}

const AssetRiskDashboardTrend = (props: IAssetRiskDashboardTrendProps) => (
  <div className="bootstrap-row asset-risk-dashboard__row--center">
    <div className="col-24 col-lg-20">
      <div className="tile" data-qa="asset-risk-tile">
        <div className="tile-content">
          <TrendComponent {...props} />
        </div>
      </div>
    </div>
  </div>
);

export default AssetRiskDashboardTrend;
