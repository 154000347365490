// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import * as React from "react";
import { FormattedMessage } from "react-intl";

import UrgencyConverter from "common/converters/UrgencyConverter";
import { ISelectOption } from "common/form/components/Select";
import SelectInputContainer from "common/form/containers/SelectInputContainer";
import IIssue from "features/detailpage/features/issues/models/IIssue";
import IIssueMeta from "features/detailpage/features/issues/models/IIssueMeta";
import IssueModes from "features/detailpage/features/issues/models/IssueModes";
import { issueDetailsFormName } from "./IssueDetails";

interface IIssueDetailsUrgencyProps {
  issue: IIssue;
  meta: IIssueMeta;
  urgencies: string[];
}

const IssueDetailsUrgency = ({
  issue,
  meta,
  urgencies
}: IIssueDetailsUrgencyProps) => {
  const uiUrgency = UrgencyConverter.convertApiUrgencyToUiUrgency(
    issue.Urgency
  );

  const uiUrgencies = UrgencyConverter.convertApiUrgenciesToUiUrgencies(
    urgencies
  );

  const options = uiUrgencies.map<ISelectOption>(u => ({
    label: {
      defaultMessage: u,
      id: `urgency.${u}`
    },
    value: u
  }));

  return meta.mode === IssueModes.View ? (
    <div>
      <div className="default-grey-label">
        <FormattedMessage
          defaultMessage="Urgency"
          id="detail_page.issues.issue_details.urgency"
        />
      </div>
      <div className="value">
        <FormattedMessage
          defaultMessage={uiUrgency}
          id={`urgency.${uiUrgency}`}
        />
      </div>
    </div>
  ) : meta.mode === IssueModes.Create || meta.mode === IssueModes.Edit ? (
    <div>
      <SelectInputContainer
        defaultValue={uiUrgency}
        formName={issueDetailsFormName}
        inputName="urgency"
        label={{
          defaultMessage: "Urgency",
          id: "detail_page.issues.issue_details.form.urgency"
        }}
        options={options}
      />
    </div>
  ) : null;
};

export default IssueDetailsUrgency;
