// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import * as React from "react";
import { FormattedMessage } from "react-intl";

import "./BatteryVoltageChartInfo.less";

const BatteryVoltageChartInfo = () => (
  <div className="battery-voltage-chart-info">
    <div className="values-info">
      <div>
        <span className="pilot-cell-indicator"></span>
        <FormattedMessage
          defaultMessage="Pilot Cell"
          id="detail_page.parameters.battery_voltage.chart_legend.pilot_cell"
        ></FormattedMessage>
      </div>
      <div>
        <span className="over-under-limit-indicator"></span>
        <FormattedMessage
          defaultMessage="Over / Under limit"
          id="detail_page.parameters.battery_voltage.chart_legend.over_under_limit"
        ></FormattedMessage>
      </div>
      <div>
        <span className="variability-indicator">
          <div className="arrows large" />
          <div className="arrows medium" />
          <div className="arrows small" />
        </span>
        <FormattedMessage
          defaultMessage="Variability"
          id="detail_page.parameters.battery_voltage.chart_legend.variability"
        />
      </div>
    </div>
    <div className="thresholds-info">
      <div className="bold">
        <FormattedMessage
          defaultMessage="Thresholds:"
          id="detail_page.parameters.battery_voltage.chart_legend.thresholds"
        ></FormattedMessage>
      </div>
      <div>
        <span className="reference-level-indicator"></span>
        <FormattedMessage
          defaultMessage="Reference"
          id="detail_page.parameters.battery_voltage.chart_legend.thresholds.reference"
        ></FormattedMessage>
      </div>
      <div>
        <span className="upper-lower-limit-indicator"></span>
        <FormattedMessage
          defaultMessage="Upper / Lower Limit"
          id="detail_page.parameters.battery_voltage.chart_legend.thresholds.upper_lower_limit"
        ></FormattedMessage>
      </div>
    </div>
  </div>
);

export default BatteryVoltageChartInfo;
