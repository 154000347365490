// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { useCallback, useState } from "react";

const useSidePanel = () => {
  const [isSidePanelVisible, setIsSidePanelVisible] = useState<boolean>(false);

  const handleSidePanelOpen = useCallback(() => {
    setIsSidePanelVisible(true);
  }, []);

  const handleSidePanelClose = useCallback(() => {
    setIsSidePanelVisible(false);
  }, []);

  return {
    isSidePanelVisible,
    handleSidePanelOpen,
    handleSidePanelClose
  };
};

export default useSidePanel;
