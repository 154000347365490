// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Statuses } from "core/data/models/Data";
import { useCallback, useMemo } from "react";
import { useAppSelector } from "store";
import getAssetRiskConfigurationSelector from "../../../selectors/getAssetRiskConfigurationSelector";
import getRiskMatrixClustersSelector from "../../../selectors/getRiskMatrixClustersSelector";

const useRiskMatrixChartWrapper = () => {
  const clusters = useAppSelector(getRiskMatrixClustersSelector);
  const assetRiskConfiguration = useAppSelector(
    getAssetRiskConfigurationSelector
  );

  const hasStatus = useCallback(
    (status: Statuses) =>
      assetRiskConfiguration.status === status || clusters.status === status,
    [clusters, assetRiskConfiguration]
  );

  const errorMessage = useMemo(() => {
    if (assetRiskConfiguration.status === Statuses.Failed)
      return assetRiskConfiguration.message;
    else if (clusters.status === Statuses.Failed) return clusters.message;

    return "";
  }, [assetRiskConfiguration, clusters]);

  return {
    assetRiskConfiguration,
    errorMessage,
    hasStatus
  };
};

export default useRiskMatrixChartWrapper;
