// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { isEqual } from "lodash";
import { useCallback, useRef, useState } from "react";

const useRequests = () => {
  const requestsRef = useRef<string[][]>([]);
  const [requests, setRequests] = useState<string[][]>([]);

  const addRequest = useCallback((requestId: string[]) => {
    const i = requestsRef.current.findIndex((r) => isEqual(r, requestId));
    if (i >= 0) return;

    const requests = [...requestsRef.current, requestId];
    requestsRef.current = requests;
    setRequests(requests);
  }, []);

  const resetRequests = useCallback(() => {
    requestsRef.current = [];
    setRequests([]);
  }, []);

  return {
    addRequest,
    resetRequests,
    requests,
    requestsRef
  };
};

export default useRequests;
