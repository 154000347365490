// Copyright 2016-2024 Hitachi Energy. All rights reserved.

export class CostLevels {
  static readonly Unknown = "Unknown";
  static readonly VeryHigh = "VeryHigh";
  static readonly High = "High";
  static readonly Medium = "Medium";
  static readonly Low = "Low";
  static readonly VeryLow = "VeryLow";
}

export class Cost {
  static readonly Unknown = "Unknown";
  static readonly VeryHigh = "VeryHigh";
  static readonly High = "High";
  static readonly Moderate = "Moderate";
  static readonly Low = "Low";
  static readonly VeryLow = "VeryLow";
}

export default class CostConverter {
  static convertCostLevelToCost(costLevel: string): string {
    switch (costLevel) {
      case CostLevels.VeryHigh:
        return Cost.VeryHigh;
      case CostLevels.High:
        return Cost.High;
      case CostLevels.Medium:
        return Cost.Moderate;
      case CostLevels.Low:
        return Cost.Low;
      case CostLevels.VeryLow:
        return Cost.VeryLow;
      case CostLevels.Unknown:
      default:
        return Cost.Unknown;
    }
  }
}
