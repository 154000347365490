// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { loadConductanceData } from "../actions/BatteryConductanceActions";
import BatteryConductance from "../components/BatteryConductance";
import batteryConductanceSelector from "../selectors/batteryConductanceSelector";

interface IBatteryConductanceContainerProps {
  assetId: string;
}

const BatteryConductanceContainer = ({
  assetId
}: IBatteryConductanceContainerProps) => {
  const dispatch = useAppDispatch();
  const conductanceData = useAppSelector(batteryConductanceSelector);
  const memoizedLoadConductanceData = useCallback(
    () => loadConductanceData(dispatch, assetId),
    [assetId, dispatch]
  );

  useEffect(() => {
    memoizedLoadConductanceData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <BatteryConductance conductanceData={conductanceData} />;
};

export default BatteryConductanceContainer;
