// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { ILegendItem } from "@apm/widgets/build/components/BBChart";
import ILine from "@apm/widgets/build/models/ILine";
import ISeries from "@apm/widgets/build/models/ISeries";
import {
  colorStatusOrange,
  colorStatusYellow
} from "@pg/common/build/styles/ColorVariables";
import { formatDate } from "common/DateTime/utils/dateFormatters";
import Data from "core/data/models/Data";
import { isNil } from "lodash";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import IThermal from "../models/IThermal";

const useThermalVisualizationTab = (thermal: Data<IThermal>) => {
  const intl = useIntl();

  const sortByName = useCallback(
    (a: ILegendItem, b: ILegendItem) =>
      a.name === "Load"
        ? -1
        : b.name === "Load"
        ? 1
        : typeof a.displayName === "string" && typeof b.displayName === "string"
        ? a.displayName.localeCompare(b.displayName)
        : a.name.localeCompare(b.name),
    []
  );

  const sortByHiddenThenByName = useCallback(
    (a: ILegendItem, b: ILegendItem) =>
      a.defaultHidden === b.defaultHidden
        ? sortByName(a, b)
        : a.defaultHidden
        ? 1
        : -1,
    [sortByName]
  );

  const lines = useMemo(() => {
    if (!thermal) return;
    if (!thermal.data) return;

    const lines: ILine[] = [
      !isNil(thermal.data.MaxAllowedTemperature)
        ? {
            name: "MaxAllowedTemperature",
            displayName: intl.formatMessage({
              defaultMessage: "Max. allowed Temp (°C)",
              id: `detail_page.thermal_visualization_tab.lines.max-allowed-temp-c`
            }),
            value: thermal.data.MaxAllowedTemperature,
            color: colorStatusOrange
          }
        : null,
      !isNil(thermal.data.MaxContinualHSTemperature)
        ? {
            name: "MaxContinualHSTemperature",
            displayName: intl.formatMessage({
              defaultMessage: "Max. continual HS Temp (°C)",
              id: `detail_page.thermal_visualization_tab.lines.max-continual-hs-temp-c`
            }),
            value: thermal.data.MaxContinualHSTemperature,
            color: colorStatusYellow
          }
        : null
    ].filter(Boolean);

    return lines;
  }, [intl, thermal]);

  const series = useMemo(() => {
    if (!thermal) return;
    if (!thermal.data) return;
    if (!thermal.data.ThermalData) return;

    const keys = Object.keys(thermal.data.ThermalData);
    const series = keys.map((k) => {
      const series: ISeries = {
        name: k,
        displayName: intl.formatMessage({
          defaultMessage: k,
          id: `detail_page.thermal_visualization_tab.series.${k}`
        }),
        defaultHidden: [
          "Hot_Spot_Temperature",
          "Top_Oil_Temperature",
          "Oil_Sample_Temperature_Online",
          "Bottom_Oil_Temperature"
        ].includes(k),
        values: thermal.data.ThermalData[k],
        unit: k === "Load" ? "%" : "°C"
      };

      return series;
    });

    return series;
  }, [intl, thermal]);

  const formatXTick = useCallback(
    (date: Date) => formatDate(intl, date),
    [intl]
  );

  const formatYTick = useCallback(
    (value: number, unit: string) => {
      return intl.formatMessage(
        {
          defaultMessage: `{value} {unit}`,
          id: "detail_page.thermal_visualization_tab.y_tick"
        },
        {
          value: intl.formatNumber(value, {
            maximumFractionDigits: 2
          }),
          unit
        }
      );
    },
    [intl]
  );

  const formatTooltipTitle = useCallback(
    (date: Date) => intl.formatDate(date),
    [intl]
  );

  return {
    lines,
    series,
    formatXTick,
    formatYTick,
    formatTooltipTitle,
    sortByHiddenThenByName
  };
};

export default useThermalVisualizationTab;
