// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import FormContainer from "common/form/containers/FormContainer";
import useIssueStatusTranslation from "common/issueStatus/hooks/useIssueStatusTranslation";
import { IssueStatuses } from "common/issueStatus/models/IssueStatuses";
import ProcessingModal from "components/common/ProcessingModal";
import { Statuses } from "core/data/models/Data";
import IssueStatusSelectContainer from "features/detailpage/features/issues/containers/IssueStatusSelectContainer";
import IIssue from "features/detailpage/features/issues/models/IIssue";
import IIssueMeta from "features/detailpage/features/issues/models/IIssueMeta";
import { useCallback } from "react";
import { IntlShape, useIntl } from "react-intl";
import IssueStatusChangeConfirmationModalContainer from "../containers/IssueStatusChangeConfirmationModalContainer";
import useStatusChangeModal from "../hooks/useStatusChangeModal";
import IssueCreatedDate, { Formats } from "./IssueCreatedDate";
import IssueIcon from "./IssueIcon";

export const issueCompactFormName = "DetailPage_IssueCompactForm";

export interface IIssueCompactActions {
  editIssueStatus: (
    issueId: string,
    currentStatus: IssueStatuses,
    statue: IssueStatuses,
    comment: string,
    issueStatusTranslation: { [key in IssueStatuses]: string },
    intl: IntlShape
  ) => Promise<void>;
  updateActiveIssue: (issueId: string) => Promise<void>;
}

export interface IIssueCompactOwnProps {
  issue: IIssue;
  meta: IIssueMeta;
  readonly?: boolean;
}

export interface IIssueCompactProps
  extends IIssueCompactActions,
    IIssueCompactOwnProps {}

const IssueCompact = ({
  editIssueStatus,
  updateActiveIssue,
  issue,
  meta,
  readonly
}: IIssueCompactProps) => {
  const {
    statusChangeModalData,
    showStatusChangeModal,
    hideStatusChangeModal
  } = useStatusChangeModal(issue.Status);

  const intl = useIntl();
  const issueStatusTranslations = useIssueStatusTranslation();
  const formName = `${issueCompactFormName}-${issue.Id}`;

  const title = issue.HeaderText;
  const condition = issue.ConditionText;

  const handleStatusChange = useCallback(
    async (status: IssueStatuses, comment: string) => {
      await editIssueStatus(
        issue.Id,
        issue.Status,
        status,
        comment,
        issueStatusTranslations,
        intl
      );
      if (status !== "Closed") updateActiveIssue(issue.Id);
    },
    [
      editIssueStatus,
      intl,
      issue.Id,
      issue.Status,
      issueStatusTranslations,
      updateActiveIssue
    ]
  );

  const handleStatusChangeConfirm = useCallback(
    (status: IssueStatuses, reason: string) => {
      return handleStatusChange(status, reason).then(() => {
        hideStatusChangeModal();
      });
    },
    [hideStatusChangeModal, handleStatusChange]
  );

  return (
    <FormContainer
      className="issue-compact"
      name={formName}
      showActionButtons={false}
    >
      <div className="issue-header">
        <div className="issue-header-status">
          <IssueIcon issue={issue} />
          <IssueStatusSelectContainer
            formName={formName}
            disabled={readonly}
            issueStatus={issue.Status}
            onChange={showStatusChangeModal}
            preventValueChange={true}
          />
          {!readonly && (
            <IssueStatusChangeConfirmationModalContainer
              visible={statusChangeModalData?.visible}
              status={statusChangeModalData?.status ?? issue.Status}
              onConfirmAction={handleStatusChangeConfirm}
              hideModal={hideStatusChangeModal}
            />
          )}
        </div>
        <IssueCreatedDate
          className="issue-header-date"
          format={Formats.Date}
          issue={issue}
        />
      </div>
      <div className="issue-title" data-qa="issue-title">
        {title}
      </div>
      <div className="issue-condition">{condition}</div>
      {!statusChangeModalData?.visible &&
        meta.editStatusRequest?.status === Statuses.Loading && (
          <ProcessingModal className="issue-compact-processing" />
        )}
    </FormContainer>
  );
};

export default IssueCompact;
