// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React, { ReactNode } from "react";

export interface ISettingsProviderActions {
  loadCustomer: () => void;
  loadEnvironment: () => void;
  loadMapProvider: () => void;
  loadUser: () => void;
  loadTenantList: () => void;
  loadCustomerVisualizations: () => void;
  loadCustomerVisualizationsAssetGrid: () => void;
  loadCustomerVisualizationsIssueGrid: () => void;
  loadCustomerVisualizationsComponentGrid: () => void;
  loadCustomerVisualizationsCommandIntegration: () => void;
  loadCustomerIssueSettings: () => void;
  loadWatchListLimit: () => void;
}

export interface ISettingsProviderProps extends ISettingsProviderActions {
  children?: ReactNode;
}

class SettingsProvider extends React.Component<ISettingsProviderProps> {
  componentDidMount() {
    const {
      loadCustomer,
      loadEnvironment,
      loadMapProvider,
      loadTenantList,
      loadUser,
      loadCustomerVisualizations,
      loadCustomerVisualizationsAssetGrid,
      loadCustomerVisualizationsIssueGrid,
      loadCustomerVisualizationsComponentGrid,
      loadCustomerVisualizationsCommandIntegration,
      loadCustomerIssueSettings,
      loadWatchListLimit
    } = this.props;

    loadCustomer();
    loadCustomerVisualizations();
    loadCustomerVisualizationsAssetGrid();
    loadCustomerVisualizationsIssueGrid();
    loadCustomerVisualizationsComponentGrid();
    loadCustomerVisualizationsCommandIntegration();
    loadCustomerIssueSettings();
    loadEnvironment();
    loadMapProvider();
    loadTenantList();
    loadUser();
    loadWatchListLimit();
  }

  render() {
    const { children } = this.props;
    const childArray = React.Children.toArray(children);
    return <div>{childArray}</div>;
  }
}

export default SettingsProvider;
