// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import IValidator from "./IValidator";

export default class RequiredValidator<T> implements IValidator<T> {
  validate = (value: T) =>
    typeof value === "string" ? !!value.trim() : !!value;
  errorMessage = {
    defaultMessage: "Value is required",
    id: "global.validators.validators.is_required"
  };
}
