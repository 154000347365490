// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import FilterBar, { SelectedFilters } from "common/FilterBar";
import EmptyState from "common/empty/EmptyState";
import { useMemo, useState } from "react";
import styled from "styled-components";
import ComponentsGrid from "./ComponentsGrid";

interface IComponents {
  className?: string;
}

const Components = ({ className }: IComponents) => {
  const [filters, setFilters] = useState<SelectedFilters>();

  const requiredFiltersAreSelected = useMemo(() => {
    return filters?.selects.filter((f) => f.Id !== "AssetStatus").length > 0;
  }, [filters]);

  return (
    <Wrapper className={`${className} component-page`}>
      <FilterBar
        onFilter={setFilters}
        namespace="components"
        useSearch={{ disabled: !requiredFiltersAreSelected }}
        useAssetTypeWithSubType
        useAssetStatus
        useAssetId={{ hidden: true }}
        useComponentTypeWithSubType
        useComponentRisk
        useComponentManufacturerWithModel
        columnSelectSpans={{
          xs: 3,
          sm: 3,
          md: 3,
          lg: 3,
          xl: 3,
          xxl: 3
        }}
        disabledSearchTooltip={{
          defaultMessage: "Use other filters to enable search option",
          id: "components_page.filters.search_disabled_tooltip"
        }}
      >
        {filters && (
          <Content>
            {!requiredFiltersAreSelected && (
              <EmptyState
                id="components_page.empty_state"
                iconName="account_tree"
                iconClassName="components_empty_state"
              />
            )}
            {requiredFiltersAreSelected && <ComponentsGrid filters={filters} />}
          </Content>
        )}
      </FilterBar>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;

  > div:first-of-type {
    flex-shrink: 0;
  }
`;

const Content = styled.div`
  flex-grow: 1;
  overflow: auto;
  .empty {
    .components_empty_state {
      font-size: 150px;
    }
  }
`;

export default Components;
