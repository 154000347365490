// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import IPoint from "../models/IPoint";
import Scale from "../models/Scale";

class CoordinateService {
  static angleToCoordinate = (
    width: number,
    height: number,
    scale: Scale,
    radians: number,
    r: number
  ) => {
    const x = Math.cos(radians) * scale(r);
    const y = Math.sin(radians) * scale(r);
    return { x: width / 2 + x, y: height / 2 - y };
  };

  static pointToCoordinate = (
    point: IPoint,
    width: number,
    height: number,
    scale: Scale
  ) => {
    const radians = point.degrees * (Math.PI / 180);
    const r = point.value;

    return CoordinateService.angleToCoordinate(
      width,
      height,
      scale,
      radians,
      r
    );
  };
}

export default CoordinateService;
