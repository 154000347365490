// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { IState } from "../../../../../reducers/Index";

const selectOnlineBushingData = createSelector(
  (state: IState) => state.detailPage.onlineBushingData,
  onlineBushingData => onlineBushingData
);

export default selectOnlineBushingData;
