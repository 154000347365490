// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React from "react";

import LTCOperationsBarChartService from "features/detailpage/features/ltc/services/LTCOperationsChartService";

interface IUseLTCOperationsChartConfigurationPayload {
  percentageUsed: number;
  warningThreshold: number;
  alarmThreshold: number;
}

const useLTCOperationsChartConfiguration = ({
  percentageUsed,
  warningThreshold,
  alarmThreshold
}: IUseLTCOperationsChartConfigurationPayload) => {
  const configuration = React.useMemo(
    () =>
      LTCOperationsBarChartService.getChartConfiguration(
        percentageUsed,
        warningThreshold,
        alarmThreshold
      ),
    [percentageUsed, warningThreshold, alarmThreshold]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const legendData = React.useMemo(
    LTCOperationsBarChartService.getChartLegendData,
    []
  );

  return {
    configuration,
    legendData
  };
};

export default useLTCOperationsChartConfiguration;
