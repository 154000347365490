// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { debounce } from "lodash";
import React from "react";
import ResizeObserver from "resize-observer-polyfill";

import "./ResizeWrapper.less";

type ResizeProps = {
  children: React.ReactNode;
  className?: string;
} & Partial<DefaultProps>;

type ResizeState = {
  parentWidth: number;
  parentHeight: number;
  initTime: number;
};

type DefaultProps = Readonly<typeof resizeWrapperDefaultProps>;

const resizeWrapperDefaultProps = {
  className: ""
};

const SCROLL_SIZE = 20;
const DEBOUNCE_TIME = 500;
const HEIGHT_LISTENER_INIT_DELAY = 3000;

class ResizeWrapper extends React.Component<ResizeProps, ResizeState> {
  static defaultProps = resizeWrapperDefaultProps;

  constructor(props: ResizeProps) {
    super(props);

    this.state = {
      parentWidth: undefined,
      parentHeight: undefined,
      initTime: Date.now()
    };
  }

  componentDidMount() {
    this.observer.observe(this.resizeWrapper.current.parentElement);
  }

  componentWillUnmount() {
    this.observer.unobserve(this.resizeWrapper.current.parentElement);
  }

  // get the number of pixels the scrollbar could affect the size of the parent
  private getScrollDelta = (isHeight: boolean) => {
    const coefficient =
      this.resizeWrapper.current.parentElement[
        isHeight ? "clientHeight" : "clientWidth"
      ] / document.body[isHeight ? "clientHeight" : "clientWidth"];

    // round up to the nearest hundredth
    const roundedCoefficient = Math.ceil(coefficient * 100) / 100;

    return SCROLL_SIZE * roundedCoefficient;
  };

  private isHeightChanged = () =>
    this.state.initTime + HEIGHT_LISTENER_INIT_DELAY < Date.now() && // wait for the first render(s) to finish
    Math.abs(
      this.resizeWrapper.current.parentElement.clientHeight -
        (this.state.parentHeight || 0)
    ) > this.getScrollDelta(true);

  private isWidthChanged = () =>
    Math.abs(
      this.resizeWrapper.current.parentElement.clientWidth -
        (this.state.parentWidth || 0)
    ) > this.getScrollDelta(false);

  private updateDimensions = () => {
    if (
      this.resizeWrapper &&
      this.resizeWrapper.current &&
      this.resizeWrapper.current.parentElement &&
      (this.isHeightChanged() || this.isWidthChanged())
    ) {
      this.setState({
        parentWidth: this.resizeWrapper.current.parentElement.clientWidth,
        parentHeight: this.resizeWrapper.current.parentElement.clientHeight
      });
    }
  };

  render() {
    const { parentHeight, parentWidth } = this.state;
    const { children, className } = this.props;

    const key =
      parentHeight !== undefined && parentWidth !== undefined
        ? `${parentHeight}_${parentWidth}`
        : undefined;

    return (
      <div
        key={key || "-"}
        className={`resize-wrapper ${className}`}
        ref={this.resizeWrapper}
      >
        {key ? children : null}
      </div>
    );
  }

  private observer = new ResizeObserver(() => {
    this.debouncedUpdateDimensions();
  });
  private resizeWrapper = React.createRef<HTMLDivElement>();
  private debouncedUpdateDimensions = debounce(
    this.updateDimensions,
    DEBOUNCE_TIME
  );
}

export default ResizeWrapper;
