// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import getWatchListLimitSelector from "core/selectors/getWatchListLimitSelector";
import { isNil, isString } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useAppSelector } from "store";
import getWatchListCount from "../actions/getWatchListCount";
import IWatchIconTranslations from "../models/IWatchIconTranslations";

const useWatchIcon = () => {
  const [watchListCount, setWatchListCount] = useState<number>();
  const [countErrorStatus, setCountErrorStatus] = useState<boolean>(false);

  const intl = useIntl();
  const watchListLimit = useAppSelector(getWatchListLimitSelector);

  const translations: IWatchIconTranslations = useMemo(
    () => ({
      startWatching: intl.formatMessage({
        id: "asset_watch_list.message.watch",
        defaultMessage: "Start watching"
      }),
      stopWatching: intl.formatMessage({
        id: "asset_watch_list.message.unwatch",
        defaultMessage: "Stop watching"
      }),
      watchListIsFull: watchListLimit
        ? intl.formatMessage(
            {
              id: "asset_watch_list.message.full_list",
              defaultMessage:
                "Watch List is full. You cannot add more than {count} assets to the list."
            },
            { count: watchListLimit.data }
          )
        : null,
      assetAdded: intl.formatMessage({
        id: "asset_watch_list.message.asset_added",
        defaultMessage: "You added the asset to your Watch List."
      }),
      assetRemoved: intl.formatMessage({
        id: "asset_watch_list.message.asset_removed",
        defaultMessage: "You removed the asset from your Watch List."
      })
    }),
    [intl, watchListLimit]
  );

  useEffect(() => {
    if (isNil(watchListCount)) {
      getWatchListCount()
        .then((count) => {
          setWatchListCount(count);
          setCountErrorStatus(false);
        })
        .catch(() => {
          setCountErrorStatus(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const limitReached = useMemo(() => {
    if (!isNil(watchListCount)) return watchListCount >= watchListLimit.data;
  }, [watchListCount, watchListLimit.data]);

  const increaseWatchListCount = useCallback(
    () => setWatchListCount(watchListCount + 1),
    [watchListCount]
  );
  const decreaseWatchListCount = useCallback(
    () => setWatchListCount(watchListCount - 1),
    [watchListCount]
  );

  const getGeneralErrorMessage = useCallback(
    () =>
      intl.formatMessage({
        id: `asset_watch_list.message.error`,
        defaultMessage: "The request cannot be processed. Refresh the page."
      }),
    [intl]
  );

  const getAddErrorMessage = useCallback(
    (request: JQuery.jqXHR) =>
      request.status === 400 && isString(request.responseJSON)
        ? intl.formatMessage({
            id: `asset_watch_list.message.add_error.${request.responseJSON}`,
            defaultMessage: getGeneralErrorMessage()
          })
        : getGeneralErrorMessage(),
    [getGeneralErrorMessage, intl]
  );

  const getRemoveErrorMessage = useCallback(
    (request: JQuery.jqXHR) =>
      request.status === 400 && isString(request.responseJSON)
        ? intl.formatMessage({
            id: `asset_watch_list.message.remove_error.${request.responseJSON}`,
            defaultMessage: getGeneralErrorMessage()
          })
        : getGeneralErrorMessage(),
    [getGeneralErrorMessage, intl]
  );

  return {
    translations,
    limitReached,
    increaseWatchListCount,
    decreaseWatchListCount,
    getAddErrorMessage,
    getRemoveErrorMessage,
    countErrorStatus
  };
};

export default useWatchIcon;
