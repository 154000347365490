// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { useCallback } from "react";
import { IState as IOperationsParametersWidgetState } from "@apm/widgets/build/widgets/OperationsParameters";
import { isEmpty } from "lodash";

import convertParametersToArray from "../utils/convertParametersToArray";
import convertParametersFromArray from "../utils/convertParametersFromArray";
import useUrlSearchParams from "hooks/useUrlSearchParams";

const operationsSearchKey = "o_params";

const useParametersUrl = () => {
  const { getUrlParam, setUrlParam } = useUrlSearchParams();
  const setParamsInUrl = useCallback(
    (value: IOperationsParametersWidgetState) => {
      const converted = convertParametersToArray(value);
      if (isEmpty(converted)) {
        setUrlParam(operationsSearchKey, null);
      } else {
        setUrlParam(operationsSearchKey, JSON.stringify(converted));
      }
    },
    [setUrlParam]
  );

  const getParamsFromUrl = useCallback((): IOperationsParametersWidgetState => {
    try {
      const parametersFromUrl: string[][] = JSON.parse(
        getUrlParam(operationsSearchKey)
      );
      const result: IOperationsParametersWidgetState = convertParametersFromArray(
        parametersFromUrl
      );

      return result;
    } catch (e) {
      console.error(e);
    }

    return null;
  }, [getUrlParam]);

  return {
    setParamsInUrl,
    getParamsFromUrl
  };
};

export default useParametersUrl;
