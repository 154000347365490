// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import SearchParams from "@pg/common/build/models/SearchParams";
import { IRow } from "common/datagrid/DataGrid";
import { useAppNavigate } from "core/app/components/RouterProvider";
import Guid from "core/guid/Guid";
import { isEmpty } from "lodash";
import { useCallback, useState } from "react";
import { useLocation } from "react-router";
import IParameterInfo from "../../../models/IParameterInfo";
import isClickable from "../utils/isClickable";

const searchValueQueryKey = "p_search";

const useParametersGridSucceeded = (onSelectionCleared?: () => void) => {
  const navigate = useAppNavigate();
  const location = useLocation();
  const [search, setSearch] = useState<string>(
    ((search: string) => {
      return new SearchParams(search).get(searchValueQueryKey) || "";
    })(location.search)
  );
  const [gridKey, setGridKey] = useState<string>(Guid.getUniqGuid());

  const filterRows = useCallback(
    (p: IRow<IParameterInfo>) => {
      if (!search) return true;
      if (
        p.data.TranslatedName?.toLowerCase().indexOf(search.toLowerCase()) >= 0
      )
        return true;
      if (p.data.Unit?.toLowerCase().indexOf(search.toLowerCase()) >= 0)
        return true;
      if (
        p.data.FactorsKeywords?.toLowerCase().indexOf(search.toLowerCase()) >= 0
      )
        return true;
      return false;
    },
    [search]
  );

  const handleClear = useCallback(() => {
    if (onSelectionCleared) onSelectionCleared();
    setGridKey(Guid.getUniqGuid());
  }, [onSelectionCleared]);

  const handleSelectableRow = useCallback(
    (row: IRow<IParameterInfo>) => isClickable(row),
    []
  );

  const handleSearch = useCallback(
    (value: string) => {
      const searchParams = new SearchParams(location.search);
      if (!isEmpty(value)) {
        searchParams.set(searchValueQueryKey, value);
      } else {
        searchParams.delete(searchValueQueryKey);
      }
      navigate(
        {
          search: searchParams.toString()
        },
        { replace: true }
      );
      setSearch(value);
    },
    [navigate, location.search]
  );

  return {
    search,
    filterRows,
    handleSearch,
    handleSelectableRow,
    handleClear,
    gridKey
  };
};

export default useParametersGridSucceeded;
