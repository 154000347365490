// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Icon } from "@pg/common";
import { Typography } from "antd";
import Modal from "common/Modal";
import FormContainer from "common/form/containers/FormContainer";
import TextFieldInputContainer from "common/form/containers/TextFieldInputContainer";
import FormModel from "common/form/models/Form";
import { IssueStatuses } from "common/issueStatus/models/IssueStatuses";
import ProcessingModal from "components/common/ProcessingModal";
import getCustomerIssueSettingsSelector from "core/selectors/getCustomerIssueSettingsSelector";
import { useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useAppSelector } from "store";
import styled from "styled-components";
import { colorPrimary } from "styles/ColorVariables";
import { spacingBase, spacingXLarge } from "styles/StyleVariables";

const { Text } = Typography;

export interface IIssueStatusChangeConfirmationModalActions {
  resetForm: (formName: string) => void;
}

export interface IIssueStatusChangeConfirmationModalOwnProps {
  visible: boolean;
  status: IssueStatuses;
  numberOfIssues?: number;
  title?: string;
  className?: string;
  hideModal: () => void;
  onConfirmAction: (status: IssueStatuses, comment: string) => Promise<void>;
}

interface IIssueStatusChangeConfirmationModalProps
  extends IIssueStatusChangeConfirmationModalActions,
    IIssueStatusChangeConfirmationModalOwnProps {}

const IssueStatusChangeConfirmationModal = ({
  status,
  visible,
  title,
  className,
  numberOfIssues,
  hideModal,
  resetForm,
  onConfirmAction
}: IIssueStatusChangeConfirmationModalProps) => {
  const [modalProcessing, setModalProcessing] = useState<boolean>(false);

  const issueSettings = useAppSelector(getCustomerIssueSettingsSelector);
  const intl = useIntl();

  const formName = "Issue_Status_Change_Confirmation_Form";
  const maxCommentSize = 1000;

  const handleSubmit = useCallback(
    (form: FormModel) => {
      setModalProcessing(true);
      const reason = form.inputs.get("reason").value;
      const action = onConfirmAction(status, reason);
      if (action != null)
        action
          .then(() => {
            resetForm(formName);
            setModalProcessing(false);
            hideModal();
          })
          .catch(() => {
            setModalProcessing(false);
            hideModal();
          });
    },
    [hideModal, onConfirmAction, resetForm, status]
  );

  return (
    <Modal
      className={className}
      open={visible}
      centered={true}
      width="600px"
      footer={null}
      closable={false}
      data-qa="issue-status-change-modal"
      destroyOnClose={true}
    >
      <ContentWrapper onClick={(e: any) => e.stopPropagation()}>
        <Icon name="help_outline" className="help-icon" />
        <FormWrapper>
          {title ??
            (status && (
              <FormattedMessage
                id={
                  numberOfIssues
                    ? "detail_page.issues.statuses.change.confirm.message"
                    : "detail_page.issues.status.change.confirm.message"
                }
                values={{
                  status: (
                    <Text strong>
                      {intl.formatMessage({
                        id: `detail_page.issues.statuses.${status}`,
                        defaultMessage: status
                      })}
                    </Text>
                  ),
                  num: <Text strong>{numberOfIssues}</Text>
                }}
                defaultMessage={
                  numberOfIssues
                    ? "Are you sure you want to change the status of ({num}) issues to {status}?"
                    : "Are you sure you want to change the status to {status}?"
                }
              />
            ))}
          <FormContainer
            className="form-container"
            buttonCancelLabel={{
              defaultMessage: "No",
              id: "global.messages.no"
            }}
            buttonSubmitLabel={{
              defaultMessage: "Yes",
              id: "global.messages.yes"
            }}
            disabled={false}
            name={formName}
            onCancel={hideModal}
            onSubmit={handleSubmit}
          >
            <TextFieldInputContainer
              disabled={false}
              formName={formName}
              inputName="reason"
              label={{
                defaultMessage: "Comment",
                id: "detail_page.issues.status.confirmation.form.comment"
              }}
              maxLength={maxCommentSize}
              multiline={true}
              required={!!issueSettings.data?.StatusChangeReasonRequired}
            />
          </FormContainer>
        </FormWrapper>
      </ContentWrapper>
      {visible && modalProcessing && (
        <ProcessingModal
          className="status-change-modal-processing processing-screen absolute light"
          processingClassName="spinner large dark"
        />
      )}
    </Modal>
  );
};

const FormWrapper = styled.div`
  width: 100%;
`;

const ContentWrapper = styled.div`
  padding-right: ${spacingXLarge};
  display: flex;
`;

export default styled(IssueStatusChangeConfirmationModal)`
  .ant-modal-body {
    border-top: 5px solid ${colorPrimary};

    .help-icon {
      color: ${colorPrimary};
      padding-right: ${spacingBase};
    }

    .form-container > button {
      float: right;
    }
  }
`;
