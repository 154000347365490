// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import BBChart from "common/chart/components/BBChart";
import ResizeWrapper from "common/ResizeWrapper";
import useHistorySwitchingChartConfiguration from "features/detailpage/features/ltc/hooks/useHistorySwitchingChartConfiguration";
import { IHistorySwitchingOperationsData } from "features/detailpage/features/ltc/models/IHistorySwitichingOperations";
import React from "react";
import "./HistorySwitchingChart.less";

interface IHistorySwitchingChartProps {
  historySwitching: IHistorySwitchingOperationsData;
}

const HistorySwitchingChart = ({
  historySwitching
}: IHistorySwitchingChartProps) => {
  const { configuration } = useHistorySwitchingChartConfiguration({
    historySwitching
  });
  const { columns } = configuration.data;

  return (
    <>
      <div
        className={`${
          !columns.length ? "history-switching-chart--disabled" : ""
        } history-switching-chart`}
      >
        <ResizeWrapper>
          <BBChart configuration={configuration} />
        </ResizeWrapper>
      </div>
    </>
  );
};

export default HistorySwitchingChart;
