// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { IRow } from "common/datagrid/DataGrid";
import { IColumnOrder } from "common/datagrid/models/IColumnOrder";
import { IEndpoint } from "common/datagrid/models/IEndpoint";
import { IEndpointData } from "common/datagrid/models/IEndpointData";
import DataEndpointService from "core/data/services/EndpointService";
import { isArray, isNil } from "lodash";
import { IResponse } from "../models/IResponse";
import { initialChunkSize, nextChunkSize } from "./DataService";

export default class EndpointService {
  static loadNext(
    rows: IRow[],
    endpoint: IEndpoint,
    order: IColumnOrder[],
    onSuccess?: (response: JQueryXHR, data: IEndpointData) => void,
    onError?: (response: JQueryXHR) => void
  ): JQueryXHR {
    return EndpointService.load(
      endpoint,
      order,
      onSuccess,
      onError,
      rows ? rows.length : 0,
      rows ? nextChunkSize : initialChunkSize
    );
  }

  private static load(
    endpoint: IEndpoint,
    order: IColumnOrder[],
    onSuccess: (response: JQueryXHR, data: IEndpointData) => void,
    onError: (response: JQueryXHR) => void,
    start: number,
    size: number
  ): JQueryXHR {
    const content = Object.assign({}, endpoint.content, {
      start: start,
      size: size,
      order: order
    });

    const handleSuccess = (request: JQuery.jqXHR, data: IResponse) => {
      onSuccess(request, {
        rows: isArray(data) ? data : data?.RowData || [],
        rowsTotal: data?.Total || 0,
        rowsNew: !isNil(data?.New) ? data?.New : null
      });
    };

    const handleError = (request: JQuery.jqXHR) => {
      onError(request);
    };

    if (endpoint.type === "GET") {
      return DataEndpointService.getJson<IResponse>(
        endpoint.url,
        handleSuccess,
        handleError
      );
    } else {
      return DataEndpointService.postJson<IResponse>(
        endpoint.url,
        handleSuccess,
        handleError,
        content
      );
    }
  }
}
