// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { PrimitiveArray } from "billboard.js";
import namedSeries from "common/chart/utils/namedSeries";
import { find, isNil } from "lodash";
import ICluster from "../models/ICluster";
import ITierCluster from "../models/ITierCluster";
import tiers from "../utils/tiersConfiguration";

export default class ClusterService {
  public static getClustersNamesSeries(groupedClusters: ITierCluster) {
    const clustersSeries: PrimitiveArray[] = [];

    Object.keys(groupedClusters).forEach((key) => {
      const grouped = groupedClusters[key];
      const xSeriesName = this.getXSeriesName(key);
      const seriesName = this.getSeriesName(key);

      clustersSeries.push(
        namedSeries(
          xSeriesName,
          grouped.map<number>((c) => c.Score as number)
        )
      );

      clustersSeries.push(
        namedSeries(
          seriesName,
          grouped.map((c) => c.Importance)
        )
      );
    });

    return clustersSeries;
  }

  public static getGroupedClusters(
    clusters: ICluster[],
    sortPredicate?: (a: ICluster, b: ICluster) => number
  ) {
    const grouped: ITierCluster = {};

    clusters.forEach((cluster) => {
      const className = this.getTierClassNameByAssetCount(cluster.AssetsCount);

      if (className) {
        if (grouped[className]) {
          grouped[className].push(cluster);
          if (sortPredicate) grouped[className].sort(sortPredicate);
        } else {
          grouped[className] = [cluster];
        }
      }
    });

    return grouped;
  }

  public static getClustersXs(groupedClusters: ITierCluster) {
    const xs: { [key: string]: string } = {};

    Object.keys(groupedClusters).forEach((key) => {
      const xSeriesName = this.getXSeriesName(key);
      const seriesName = this.getSeriesName(key);

      xs[seriesName] = xSeriesName;
    });

    return xs;
  }

  public static getClustersPropertyDictionary(
    groupedClusters: ITierCluster,
    value: string
  ) {
    const dict: { [key: string]: string } = {};

    Object.keys(groupedClusters).forEach((key) => {
      const seriesName = this.getSeriesName(key);

      dict[seriesName] = value;
    });

    return dict;
  }

  public static getClustersClasses(groupedClusters: ITierCluster) {
    const classes: { [key: string]: string } = {};

    Object.keys(groupedClusters).forEach((key) => {
      const seriesName = this.getSeriesName(key);
      classes[seriesName] = `cluster-${key}`;
    });

    return classes;
  }

  public static getSeriesName(tier: string) {
    return `cluster_${tier}`;
  }

  public static getTierIdBySeriesName(seriesName: string) {
    return seriesName.replace("cluster_", "");
  }

  private static getTierClassNameByAssetCount = (assetCount: number) => {
    const found = find(tiers, ({ maxSize, minSize }) => {
      if (maxSize && maxSize >= assetCount && minSize <= assetCount) {
        return true;
      } else if (isNil(maxSize) && minSize <= assetCount) {
        return true;
      }

      return false;
    });

    return found ? found.id : undefined;
  };

  private static getXSeriesName(tier: string) {
    return `x_cluster_${tier}`;
  }
}
