// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import * as React from "react";

import BushingVisualizationContainer from "../containers/BushingVisualizationContainer";

interface IBushingVisualizationTabProps {
  assetId: string;
}

const BushingVisualizationTab: React.FC<IBushingVisualizationTabProps> = ({
  assetId
}) => <BushingVisualizationContainer assetId={assetId} />;

export default BushingVisualizationTab;
