// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import TileErrorMessage from "common/tile/TileErrorMessage";
import Processing from "components/common/Processing";
import { Statuses } from "core/data/models/Data";
import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useAppDispatch } from "store";
import useKeyRiskChanges from "../../hooks/useKeyRiskChanges";
import KeyRiskChangesService from "../../services/KeyRiskChangesService";
import "./KeyRiskChanges.less";
import KeyRiskChangesItemsGroup from "./KeyRiskChangesItemsGroup";

const Loading = () => (
  <div className="key-risk-changes">
    <div className="tile">
      <div className="tile-content processing">
        <Processing className="spinner small dark" />
      </div>
    </div>
  </div>
);

const Empty = () => (
  <div className="key-risk-changes">
    <div className="tile">
      <div className="tile-content empty" />
    </div>
  </div>
);

const Error = () => (
  <div className="key-risk-changes">
    <TileErrorMessage
      messageId="homepage.server_error"
      defaultMessage="Sorry, we have problems with the server connection."
      className="new-messages"
    />
  </div>
);

const KeyRiskChanges = () => {
  const { keyRiskChangesInfo } = useKeyRiskChanges();

  const increasedItemsData = KeyRiskChangesService.filterIncreasedItemsData(
    keyRiskChangesInfo.data
  );
  const decreasedItemsData = KeyRiskChangesService.filterDecreasedItemsData(
    keyRiskChangesInfo.data
  );

  return (
    <div className="key-risk-changes">
      <div className="tile" data-qa="key-risk-changes-tile">
        <div className="tile-content">
          <div className="title" data-qa="title">
            <FormattedMessage
              id={"homepage.key-risk-changes"}
              defaultMessage="Key risk changes"
            />
          </div>
          <div className="items-group" data-qa="increased">
            <FormattedMessage
              id={"homepage.key-risk-changes.increased"}
              defaultMessage="Increased"
            />
            <KeyRiskChangesItemsGroup itemsData={increasedItemsData} />
          </div>
          <div className="items-group" data-qa="decreased">
            <FormattedMessage
              id={"homepage.key-risk-changes.decreased"}
              defaultMessage="Decreased"
            />
            <KeyRiskChangesItemsGroup itemsData={decreasedItemsData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default () => {
  const dispatch = useAppDispatch();
  const { loadKeyRiskChangesInfoAction, keyRiskChangesInfo } =
    useKeyRiskChanges();

  useEffect(() => {
    dispatch(loadKeyRiskChangesInfoAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  switch (keyRiskChangesInfo.status) {
    case Statuses.Loading:
      return <Loading />;
    case Statuses.Succeeded:
      return <KeyRiskChanges />;
    case Statuses.Failed:
      return <Error />;
    default:
      return <Empty />;
  }
};
