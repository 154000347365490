// Copyright 2016-2024 Hitachi Energy. All rights reserved.

/* eslint-disable jsx-a11y/anchor-is-valid */
import useNavToAsset from "hooks/useNavToAsset";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import "./Asset.less";
import Tooltip from "common/Tooltip";

interface IAssetProps {
  assetId: string;
  assetName: string;
  assetType: string | undefined;
  location: string | undefined;
}

const Asset = (props: IAssetProps) => {
  const { assetId, assetName, assetType, location } = props;
  const showLongMessage = assetType !== undefined && location !== undefined;
  const showShortMessage = !showLongMessage;
  const { navigateTo } = useNavToAsset();

  const Text = <>{assetName ? `${assetId} - ${assetName}` : assetId}</>;

  return (
    <div className="column asset">
      <Tooltip title={Text}>
        <Link
          data-qa="link"
          className="anchor id link-label"
          to={navigateTo(assetId)}
        >
          {Text}
        </Link>
      </Tooltip>
      <div className="details">
        {showLongMessage && (
          <FormattedMessage
            defaultMessage="{location} / {assetType}"
            id="issues_page.grid.columns.asset.location_asset_type"
            values={{
              assetType: assetType,
              location: location
            }}
          />
        )}
        {showShortMessage && (
          <FormattedMessage
            defaultMessage="{value}"
            id="issues_page.grid.columns.asset.location_asset_type_short"
            values={{
              value: assetType || location
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Asset;
