// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import { routes } from "core/app/components/AppRoutes";
import { getLocaleSubstring } from "core/app/utils/checkSearchLocales";
import { FormattedMessage } from "react-intl";
import { NavLink, useLocation } from "react-router-dom";

const OpenIssuesFooter = () => {
  const location = useLocation();
  return (
    <div className="tile-footer">
      <NavLink
        className="explore anchor"
        data-qa="link"
        to={{
          pathname: routes.issues.pathname,
          search: `f_is=["New"]&f_is=["InProgress"]&f_is=["Monitor"]&f_as=["InService"]&${getLocaleSubstring(
            location.search
          )}`
        }}
      >
        <FormattedMessage
          id="homepage.opened_issues.explore"
          defaultMessage="Explore"
        />
        <Icon name="arrow_right_alt" />
      </NavLink>
    </div>
  );
};

export default OpenIssuesFooter;
