// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { SelectedFilters } from "common/FilterBar";
import { useCallback } from "react";
import "./AssetQuery.less";
import AssetQueryGrid from "./AssetQueryGrid";
import { ViewType } from "./AssetViewSwitch";

interface IAssetQueryContentGridProps {
  filters: SelectedFilters;
  isAssetWatchList: boolean;
  onViewChange: (currentView: ViewType) => void;
}

const assetWatchListFilterName = "AssetWatchList";

const AssetQueryContent = ({
  filters,
  isAssetWatchList,
  onViewChange
}: IAssetQueryContentGridProps) => {
  const getFilters = useCallback(() => {
    if (isAssetWatchList)
      return {
        selects: [
          {
            Id: assetWatchListFilterName,
            Options: [
              {
                Id: "true"
              }
            ]
          }
        ]
      };
    else
      return {
        search: filters.search,
        ranges: filters.ranges,
        selects: filters.selects.filter(
          (f) => f.Id !== assetWatchListFilterName
        )
      };
  }, [filters.ranges, filters.search, filters.selects, isAssetWatchList]);

  return (
    filters && (
      <AssetQueryGrid
        filters={getFilters()}
        viewType={isAssetWatchList ? ViewType.WatchList : ViewType.All}
        onViewChange={onViewChange}
      />
    )
  );
};

export default AssetQueryContent;
