// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";

interface IGridFooterProps {
  filteredParamsLength: number;
  allParamsLength: number;
}

const GridFooter = ({
  filteredParamsLength,
  allParamsLength
}: IGridFooterProps) => {
  return (
    <div className="parameters-grid-footer" data-qa="number-of-parameters">
      <span>
        <FormattedMessage
          id="detail_page.widgets.parameters_list.parameter_counter"
          defaultMessage="Parameters:"
        />
      </span>
      <span className="count" data-qa="count">
        <FormattedNumber value={filteredParamsLength} />
        <span>/</span>
        <FormattedNumber value={allParamsLength} />
      </span>
    </div>
  );
};

export default GridFooter;
