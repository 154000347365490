// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { IDataEndpoint, IRow, IRowData } from "common/datagrid/DataGrid";
import { SelectedFilters } from "common/FilterBar";
import UrlService from "core/data/services/UrlService";
import { useMemo, useState } from "react";
import { config } from "utils/AppConfig";
import LocationsPanelContentSwitchOption from "../models/LocationsPanelContentSwitchOption";
import useLocationsGridColumnsConfig from "./useLocationsGridColumnsConfig";

interface IUseLocationsGridOptions {
  filters: SelectedFilters;
  handleLocationPanelOpen: (
    selectedRow: IRow<IRowData>,
    selectedContent: LocationsPanelContentSwitchOption
  ) => void;
}

const useLocationsGrid = ({
  filters,
  handleLocationPanelOpen
}: IUseLocationsGridOptions) => {
  const [rowsTotal, setRowsTotal] = useState<number>();
  const columns = useLocationsGridColumnsConfig({
    handleLocationPanelOpen
  });

  const dataEndpoint: IDataEndpoint = useMemo(
    () => ({
      url: UrlService.getApiUrl(config.api.locations.locationsRangeUrl),
      type: "POST",
      content: {
        search: filters.search,
        filters: filters.selects
      },
      onDataLoaded: (total) => {
        setRowsTotal(total);
      }
    }),
    [filters]
  );

  return {
    dataEndpoint,
    rowsTotal,
    columns
  };
};

export default useLocationsGrid;
