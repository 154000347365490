// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import TextFieldInputContainer from "common/form/containers/TextFieldInputContainer";
import useIssueStatusTranslation from "common/issueStatus/hooks/useIssueStatusTranslation";
import { IssueStatuses } from "common/issueStatus/models/IssueStatuses";
import ProcessingModal from "components/common/ProcessingModal";
import { Statuses } from "core/data/models/Data";
import IssueStatusSelectContainer from "features/detailpage/features/issues/containers/IssueStatusSelectContainer";
import IIssue from "features/detailpage/features/issues/models/IIssue";
import IIssueMeta from "features/detailpage/features/issues/models/IIssueMeta";
import IssueModes from "features/detailpage/features/issues/models/IssueModes";
import { useCallback } from "react";
import { IntlShape, useIntl } from "react-intl";
import IssueStatusChangeConfirmationModalContainer from "../containers/IssueStatusChangeConfirmationModalContainer";
import useStatusChangeModal from "../hooks/useStatusChangeModal";
import IssueCreatedDate, { Formats } from "./IssueCreatedDate";
import { issueDetailsFormName } from "./IssueDetails";
import IssueDetailsHeaderActions from "./IssueDetailsHeaderActions";
import IssueIcon from "./IssueIcon";

export interface IIssueDetailsHeaderActions {
  editIssueStatus: (
    issueId: string,
    currentStatus: IssueStatuses,
    status: IssueStatuses,
    comment: string,
    issueStatusTranslation: { [key in IssueStatuses]: string },
    intl: IntlShape
  ) => Promise<void>;
}

export interface IIssueDetailsHeaderData {}

export interface IIssueDetailsHeaderOwnProps {
  allowSave: boolean;
  issue: IIssue;
  meta: IIssueMeta;
  onEditButtonClick?: () => void;
  onSaveButtonClick?: () => void;
  onCancelButtonClick?: () => void;
  onStatusSaved?: (status: IssueStatuses) => void;
  readonly: boolean;
}

interface IIssueDetailsHeaderProps
  extends IIssueDetailsHeaderActions,
    IIssueDetailsHeaderData,
    IIssueDetailsHeaderOwnProps {}

const IssueDetailsHeader = ({
  allowSave,
  editIssueStatus,
  issue,
  meta,
  onCancelButtonClick,
  onEditButtonClick,
  onSaveButtonClick,
  onStatusSaved,
  readonly
}: IIssueDetailsHeaderProps) => {
  const {
    statusChangeModalData,
    showStatusChangeModal,
    hideStatusChangeModal
  } = useStatusChangeModal(issue.Status);

  const intl = useIntl();
  const title = issue.HeaderText;
  const issueStatusTranslations = useIssueStatusTranslation();

  const handleEditButtonClick = () => {
    if (onEditButtonClick) onEditButtonClick();
  };

  const handleSaveButtonClick = () => {
    if (onSaveButtonClick) onSaveButtonClick();
  };

  const handleCancelButtonClick = () => {
    if (onStatusSaved) onStatusSaved(issue.Status);
    if (onCancelButtonClick) onCancelButtonClick();
  };

  const handleStatusChange = useCallback(
    async (status: IssueStatuses, reason: string): Promise<void> => {
      if (meta.mode === IssueModes.View) {
        await editIssueStatus(
          issue.Id,
          issue.Status,
          status,
          reason,
          issueStatusTranslations,
          intl
        ).then(() => {
          if (onStatusSaved) onStatusSaved(status);
        });
      }
    },
    [
      editIssueStatus,
      intl,
      issue.Id,
      issue.Status,
      issueStatusTranslations,
      meta.mode,
      onStatusSaved
    ]
  );

  const handleStatusChangeConfirm = useCallback(
    (status: IssueStatuses, reason: string) => {
      return handleStatusChange(status, reason).then(() => {
        hideStatusChangeModal();
      });
    },
    [hideStatusChangeModal, handleStatusChange]
  );

  return (
    <div
      className={`
        issue-header
        ${meta.mode === IssueModes.View ? "view" : ""}
        ${meta.mode === IssueModes.Create ? "create" : ""}
        ${meta.mode === IssueModes.Edit ? "edit" : ""}
        ${readonly ? "readonly" : ""}
      `}
    >
      <div className="issue-header-main">
        <div className="issue-header-status">
          <IssueIcon issue={issue} />
          <IssueStatusSelectContainer
            formName={issueDetailsFormName}
            issueStatus={issue.Status}
            onChange={
              meta.mode === IssueModes.View
                ? showStatusChangeModal
                : handleStatusChange
            }
            disabled={readonly}
            preventValueChange={meta.mode === IssueModes.View}
          />
          <IssueStatusChangeConfirmationModalContainer
            visible={statusChangeModalData?.visible}
            status={statusChangeModalData?.status ?? issue.Status}
            onConfirmAction={handleStatusChangeConfirm}
            hideModal={hideStatusChangeModal}
          />
        </div>
        <IssueCreatedDate
          className="issue-header-date"
          format={Formats.Date}
          issue={issue}
        />
        {readonly ? null : (
          <IssueDetailsHeaderActions
            allowSave={allowSave}
            className="issue-actions"
            mode={meta.mode}
            onEditButtonClick={handleEditButtonClick}
            onSaveButtonClick={handleSaveButtonClick}
            onCancelButtonClick={handleCancelButtonClick}
          />
        )}
      </div>
      {meta.mode === IssueModes.View && (
        <div className="issue-header-title value bold" data-qa="issue-title">
          {title}
        </div>
      )}
      {(meta.mode === IssueModes.Create || meta.mode === IssueModes.Edit) && (
        <div className="issue-header-title">
          <TextFieldInputContainer
            defaultValue={title}
            formName={issueDetailsFormName}
            inputName="title"
            label={{
              defaultMessage: "Title",
              id: "detail_page.issues.issue_details.form.title"
            }}
            maxLength={1000}
            required={true}
          />
        </div>
      )}
      {!statusChangeModalData?.visible &&
        meta.editStatusRequest &&
        meta.editStatusRequest.status === Statuses.Loading && (
          <ProcessingModal className="issue-details-header-processing" />
        )}
    </div>
  );
};

export default IssueDetailsHeader;
