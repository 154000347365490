// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import { notifications } from "@pg/common/build/components/Notifications";
import { Typography } from "antd";
import Popover from "antd/lib/popover";
import ButtonWithIcon from "common/button/ButtonWithIcon";
import { ParameterService } from "common/formatters/ParameterName";
import React, { useCallback, useContext, useMemo } from "react";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import {
  colorGray0,
  colorGray10,
  colorGray20,
  colorGray30,
  colorTeal60,
  colorTeal90,
  colorWidgetBackground
} from "styles/ColorVariables";
import {
  iconFontSizeXXXLarge,
  spacingBase,
  spacingLarge,
  spacingSmall,
  spacingXLarge,
  spacingXSmall,
  spacingXXLarge
} from "styles/StyleVariables";
import SimulationModalContext from "../contexts/SimulationModalContext";
import ISimulationSummary from "../models/ISimulationSummary";

const { Title, Text } = Typography;

interface ISimulationInfoProps {
  className?: string;
  simulation: ISimulationSummary;
  active?: boolean;
  disabled?: boolean;
  onClick: () => void;
  isReadOnlyMode?: boolean;
}

const SimulationInfo = ({
  className,
  simulation,
  active,
  disabled,
  onClick,
  isReadOnlyMode
}: ISimulationInfoProps) => {
  const {
    deleteSimulation,
    pinSimulation,
    unpinSimulation,
    simulationConfig,
    modelId,
    implementationId
  } = useContext(SimulationModalContext);
  const intl = useIntl();

  const handleDeleteClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      notifications.confirm({
        title: intl.formatMessage({
          defaultMessage: "Are you sure you want to delete the simulation?",
          id: "detail_page.risk_trend.simulation_modal.message.simulation_delete_confirm"
        }),
        onOk: () => {
          deleteSimulation(simulation.Id.toString(), active).then(
            () => {},
            () => {
              notifications.error({
                message: intl.formatMessage({
                  defaultMessage: "Simulation delete failed",
                  id: "detail_page.risk_trend.simulation_modal.message.simulation_delete_failed"
                })
              });
            }
          );
        }
      });
    },
    [active, deleteSimulation, intl, simulation.Id]
  );

  const handlePinClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      if (isReadOnlyMode) return;

      if (simulation.IsPinned)
        unpinSimulation(simulation.Id).then(
          () => {},
          () => {
            notifications.error({
              message: intl.formatMessage({
                defaultMessage: "Simulation unpin failed.",
                id: "detail_page.risk_trend.simulation_modal.message.simulation_unpin_failed"
              })
            });
          }
        );
      else
        pinSimulation(simulation.Id).then(
          () => {},
          () => {
            notifications.error({
              message: intl.formatMessage({
                defaultMessage: "Simulation pin failed.",
                id: "detail_page.risk_trend.simulation_modal.message.simulation_pin_failed"
              })
            });
          }
        );
    },
    [
      simulation.IsPinned,
      simulation.Id,
      pinSimulation,
      unpinSimulation,
      intl,
      isReadOnlyMode
    ]
  );

  const popoverContent = useMemo(() => {
    return (
      <span className="date-author text-truncate">
        <FormattedMessage
          defaultMessage="{date} by {user}"
          id="detail_page.risk_trend.simulation_modal.label.date_user"
          values={{
            date: <FormattedDate value={simulation.PinnedDate} />,
            user: simulation.PinnedBy ?? (
              <FormattedMessage
                defaultMessage="Unknown User"
                id="detail_page.issues.comments.unknown_user"
              />
            )
          }}
        />
      </span>
    );
  }, [simulation]);

  return (
    <div
      key={simulation.Id.toString()}
      className={`${className} ${active ? "active" : ""} ${
        disabled ? "disabled" : ""
      }`}
      onClick={onClick}
      data-qa="simulation"
    >
      <div className="simulation-info" data-qa="simulation-info">
        <Title className="simulation-name text-truncate" level={5}>
          {simulation.Name}
        </Title>
        <Text>
          <FormattedMessage
            defaultMessage="{parameterName}: {min} - {max} {unit}"
            id="detail_page.risk_trend.simulation_modal.label.parameter_min_max_unit"
            values={{
              parameterName: ParameterService.format(
                simulationConfig.ParameterName,
                modelId,
                implementationId,
                intl
              ),
              min: simulation.LoadParameterRange.BeginValue,
              max: simulation.LoadParameterRange.EndValue,
              unit: simulationConfig.Unit ?? ""
            }}
          />
        </Text>
        <Text type="secondary" className="date-author text-truncate">
          <FormattedMessage
            defaultMessage="{date} by {user}"
            id="detail_page.risk_trend.simulation_modal.label.date_user"
            values={{
              date: <FormattedDate value={simulation.SimulationDate} />,
              user: simulation.CreatedBy ?? (
                <FormattedMessage
                  defaultMessage="Unknown User"
                  id="detail_page.issues.comments.unknown_user"
                />
              )
            }}
          />
        </Text>
      </div>
      <div className="simulation-actions">
        <div>
          <Popover
            overlayClassName={!simulation.IsPinned ? "pin-info-no-content" : ""}
            content={simulation.IsPinned && popoverContent}
            getPopupContainer={(trigger) => trigger.parentElement}
          >
            <span>
              <ButtonWithIcon
                disabled={disabled}
                className={
                  simulation.IsPinned
                    ? "pinned"
                    : isReadOnlyMode
                    ? "is-read-only"
                    : "unpinned"
                }
                type="link"
                onClick={handlePinClick}
                icon={<Icon name="push_pin" />}
                data-qa="pin-button"
              />
            </span>
          </Popover>
        </div>
        <div>
          {simulation.Status === "InProgress" && (
            <Icon
              name="refresh"
              className="processing"
              spin
              data-qa="in-progress-icon"
            />
          )}
        </div>
        <div>
          {simulation.Status !== "InProgress" && !isReadOnlyMode && (
            <ButtonWithIcon
              className="delete"
              disabled={disabled}
              type="link"
              onClick={handleDeleteClick}
              icon={<Icon name="delete" />}
              data-qa="delete-button"
            />
          )}
        </div>
      </div>
    </div>
  );
};

const StyledSimulationInfo = styled(SimulationInfo)`
  margin: ${spacingXSmall} ${spacingXLarge} ${spacingXLarge} ${spacingXXLarge};
  background-color: ${colorWidgetBackground};
  border: 1px solid transparent;
  border-radius: ${spacingXSmall};
  display: flex;

  .simulation-info {
    flex: 7;
    padding: ${spacingLarge} ${spacingBase} ${spacingLarge} ${spacingXLarge};
    overflow: hidden;

    .simulation-name {
      margin: 0px 0px ${spacingSmall} 0px;
    }

    .date-author {
      display: block;
      padding-top: ${spacingLarge};
    }
  }

  .simulation-actions {
    display: flex;
    flex-direction: column;
    width: ${iconFontSizeXXXLarge};
    padding: ${spacingBase} 0px;

    > div {
      flex: 1;
      display: inline-flex;
      align-items: center;

      .processing {
        color: ${colorTeal90};
      }

      .delete {
        color: ${colorGray30};

        &:hover {
          color: ${colorTeal60};
        }
      }

      .pinned {
        color: ${colorTeal90};
      }

      .unpinned {
        &:hover {
          color: ${colorTeal60};
        }
      }

      .is-read-only {
        display: none;
      }
    }
  }

  &.active {
    background-color: ${colorGray0};
    border-color: ${colorGray10};
    border-left: ${spacingSmall} solid ${colorTeal90};
    box-shadow: 1px 1px 5px rgb(0 0 0 / 0.25);

    .simulation-name {
      color: ${colorTeal90};
    }

    &.disabled {
      border-left-color: ${colorGray10};
      background-color: ${colorWidgetBackground};
    }
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:not(.active) {
    &:not(.disabled) {
      cursor: pointer;

      &:hover {
        border-color: ${colorGray20};
      }
    }
  }

  &:not(:hover) {
    .delete {
      display: none;
    }
    .unpinned {
      display: none;
    }
  }

  .pin-info-no-content {
    display: none;
  }
`;

export default StyledSimulationInfo;
