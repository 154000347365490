// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import IssueStatusSelect, {
  IIssueStatusSelectData
} from "features/detailpage/features/issues/components/IssueStatusSelect";
import { connect } from "react-redux";
import { IState } from "reducers/Index";

export function mapStateToProps(state: IState): IIssueStatusSelectData {
  return {
    statuses: state.detailPage.issues.statuses.data
  };
}

export default connect(mapStateToProps)(IssueStatusSelect);
