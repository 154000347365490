// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";
import { IState } from "reducers/Index";

const getScatterPlotFiltersSelector = createSelector(
  (state: IState) => state.familyAnalytics.scatterPlotFilters,
  (scatterPlotFilters) => scatterPlotFilters
);

export default getScatterPlotFiltersSelector;
