// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React from "react";
import { IntlShape, useIntl } from "react-intl";

import { NumericalValueFormatter } from "common/formatters/NumericalValue";

export interface IMaintenanceScoreProps {
  value: number;
}

const MaintenanceScore = ({ value }: IMaintenanceScoreProps) => {
  const intl = useIntl();
  const formattedValue = MaintenanceScoreService.format(value, intl);
  return <span>{formattedValue}</span>;
};

export class MaintenanceScoreService {
  static format(value: number, intl: IntlShape): string {
    const formatter = new NumericalValueFormatter("-");
    return formatter.format(value, intl);
  }
}

export default MaintenanceScore;
