// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import CenteredWrapper from "common/CenteredWrapper";
import Processing from "components/common/Processing";
import {
  WidgetErrorMessage,
  WidgetNoDataMessage
} from "components/common/widget/Widget";
import Data, { Statuses } from "core/data/models/Data";
import ILTCOperations from "features/detailpage/features/ltc/models/ILTCOperations";
import { isEmpty } from "lodash";
import LTCOperationsContent from "./LTCOperationsContent";

interface ILTCOperationsStateProps {
  ltcOperations: Data<ILTCOperations>;
  percentageUsed: number;
}

interface ILTCOperationsProps extends ILTCOperationsStateProps {}

const LTCOperations = ({
  ltcOperations,
  percentageUsed
}: ILTCOperationsProps) => {
  switch (ltcOperations.status) {
    case Statuses.Loading:
      return (
        <CenteredWrapper>
          <Processing className="spinner small dark" />
        </CenteredWrapper>
      );
    case Statuses.Succeeded:
      return isEmpty(ltcOperations.data) ? (
        <CenteredWrapper>
          <WidgetNoDataMessage />
        </CenteredWrapper>
      ) : (
        <LTCOperationsContent
          ltcOperations={ltcOperations.data}
          percentageUsed={percentageUsed}
        />
      );
    case Statuses.NotFound:
      return (
        <CenteredWrapper>
          <WidgetNoDataMessage />
        </CenteredWrapper>
      );
    case Statuses.Failed:
      return (
        <CenteredWrapper>
          <WidgetErrorMessage />
        </CenteredWrapper>
      );
    default:
      return (
        <div className="tile">
          <div className="tile-content empty" />
        </div>
      );
  }
};

export default LTCOperations;
