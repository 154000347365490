// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Tooltip from "common/Tooltip";
import useNavToAsset from "hooks/useNavToAsset";
import { Link } from "react-router-dom";
import styled from "styled-components";

interface IComponentColumnProps {
  componentId: string;
  className?: string;
}

const ComponentColumn = ({ componentId, className }: IComponentColumnProps) => {
  const { navigateTo } = useNavToAsset();

  return (
    <ColumnWrapper className={className}>
      {componentId && (
        <Tooltip title={<div>{componentId}</div>}>
          <Link
            data-qa="componentId"
            className="anchor id link-label"
            to={navigateTo(componentId)}
          >
            {componentId}
          </Link>
        </Tooltip>
      )}
    </ColumnWrapper>
  );
};

const gridPadding = "10px";

const ColumnWrapper = styled.div`
  padding: ${gridPadding};

  .id {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export default ComponentColumn;
