// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import ActionsModal, {
  IActionsModalActions,
  IActionsModalData
} from "features/detailpage/features/issues/components/ActionsModal";
import { connect } from "react-redux";
import { IState } from "reducers/Index";

export function mapStateToProps(state: IState): IActionsModalData {
  return {
    availableWorkOrders: state.detailPage.actions.workOrders.data
  };
}

export function mapDispatchToProps(): IActionsModalActions {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionsModal);
