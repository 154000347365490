// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import DataGrid, {
  AgGridReact,
  AutoCompleteCellEditor,
  CellEditingStartedEvent,
  CellEditorSelectorResult,
  CellValueChangedEvent,
  EditableCallbackParams,
  ICellEditorParams,
  IDataGridCellProps,
  IDataGridProps,
  IEditor,
  IOnCellChangedConfig,
  IValidationResult,
  RowNode,
  SelectCellEditor,
  SuppressKeyboardEventParams,
  TextCellEditor
} from "@apm/widgets/build/widgets/DataGrid";
import Icon from "@pg/common/build/components/Icon";
import { notifications } from "@pg/common/build/components/Notifications";
import { Button } from "antd";
import Container from "common/Container";
import { ParameterService } from "common/formatters/ParameterName";
import SectionName from "components/common/SectionName";
import IField from "models/IField";
import { FC, useCallback, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  default as dataIntegrationStore,
  default as DataIntegrationStore,
  IConfigurableModel,
  IConfigurableModelParameter,
  IModelParameter,
  IModelPossibleValues,
  ModelType
} from "store/dashboards/dataintegration/DataIntegrationStore";
import styled from "styled-components";
import { spacingLarge, spacingMedium } from "styles/StyleVariables";
import useModelParametersMappingValidation from "./hooks/useModelParametersMappingValidation";
import "./ModelParametersMapping.less";
import ModelParametersMappingColumnHeader from "./ModelParametersMappingColumnHeader";
import { modelParametersMappingConfiguration } from "./modelParametersMappingConfiguration";

interface IModelParametersMappingProps {
  modelPossibleValues: IModelPossibleValues;
  parameters: IConfigurableModelParameter[];
  selectedModel: IConfigurableModel;
  sources: string[];
  className?: string;
}

export type ModelParameterSelectOption = "translation" | "key" | "both";

const CustomGridWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${spacingMedium};
`;

const GridHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  ~ div .ag-header {
    min-height: 49px!important;
  }
`;

const ActionWrapper = styled.div`
  margin-left: auto;
  margin-right: ${spacingMedium};
  display: flex;
  align-items: center;
  column-gap: ${spacingLarge};
`;

const ModelParametersMapping: FC<IModelParametersMappingProps> = ({
  parameters,
  selectedModel,
  modelPossibleValues,
  sources,
  className
}) => {
  const intl = useIntl();

  const gridRef = useRef<AgGridReact>(null);

  const [highlightAfterUpdatedRows, setHighlightAfterUpdatedRows] =
    useState<boolean>(false);

  const { validateModelParameter, validateSource, validateInput } =
    useModelParametersMappingValidation();

  const getFormattedModelParameter = useCallback(
    (parameterName: string): string => {
      const modelId = selectedModel.ModelId;
      const implementationId = selectedModel.ImplementationId;
      if (!parameterName) {
        return "";
      }
      return ParameterService.format(
        parameterName,
        modelId,
        implementationId,
        intl
      );
    },
    [intl, selectedModel.ImplementationId, selectedModel.ModelId]
  );

  const mapToEditorType = useCallback(
    (inputType: IField["inputType"]): IEditor => {
      switch (inputType) {
        case "String": {
          return "Text";
        }
        case "list": {
          return "Select";
        }
        case "autoComplete": {
          return "AutoComplete";
        }
        default:
          return "Text";
      }
    },
    []
  );

  const cellEditorSelector = useCallback(
    (params: ICellEditorParams): CellEditorSelectorResult => {
      if (params.colDef.field === "Input") {
        const options = modelPossibleValues.PossibleValues[
          params.data.Source.cellValue
        ]?.map((opt) => ({
          value: opt,
          label: intl.formatMessage({
            id: `data_integration.model_config.grid.${opt}`,
            defaultMessage: opt
          })
        }));

        return {
          component: AutoCompleteCellEditor,
          params: {
            options
          }
        };
      } else if (params.colDef.field === "Source") {
        const options = sources.map((val) => {
          return {
            value: val,
            label: intl.formatMessage({
              id: `data_integration.model_config.grid.${val}`,
              defaultMessage: val
            })
          };
        });

        return {
          component: SelectCellEditor,
          params: {
            options
          }
        };
      }

      return {
        component: TextCellEditor
      };
    },
    [intl, modelPossibleValues.PossibleValues, sources]
  );

  const isCellValid = useCallback(
    (
      cellName: keyof IModelParameter,
      rowFields: {
        ModelParameter: string;
        Source: string;
        Input: string;
      },
      e?: CellValueChangedEvent
    ): IValidationResult => {
      switch (cellName) {
        case "ModelParameter": {
          return validateModelParameter(rowFields.ModelParameter);
        }
        case "Source": {
          const isInputValid = validateInput({
            Input: rowFields.Input,
            Source: rowFields.Source
          }).valid;

          if (
            e &&
            ((isInputValid && !e.data.Input.valid) ||
              (!isInputValid && e.data.Input.valid))
          ) {
            e.node?.setDataValue("Input", rowFields.Input);
          }

          return validateSource({
            Source: rowFields.Source,
            Input: rowFields.Input
          });
        }

        case "Input": {
          const isSourceValid = validateSource({
            Source: rowFields.Source,
            Input: rowFields.Input
          }).valid;

          if (
            e &&
            ((isSourceValid && !e.data.Source.valid) ||
              (!isSourceValid && e.data.Source.valid))
          ) {
            e.node?.setDataValue("Source", rowFields.Source);
          }
          return validateInput({
            Input: rowFields.Input,
            Source: rowFields.Source
          });
        }

        default: {
          return {
            valid: true
          };
        }
      }
    },
    [validateInput, validateModelParameter, validateSource]
  );

  const customValidation = useCallback(
    (e: CellValueChangedEvent): IValidationResult => {
      const rowData: Pick<
        IModelParameter,
        "ModelParameter" | "Source" | "Input"
      > = {
        ModelParameter: e.data.ModelParameter.cellValue,
        Source: e.data.Source.cellValue,
        Input: e.data.Input.cellValue
      };

      return isCellValid(e.colDef.field as keyof IModelParameter, rowData, e);
    },
    [isCellValid]
  );

  const mapTextInfo = useCallback(
    (fieldKey: keyof IModelParameter): string => {
      switch (fieldKey) {
        case "ModelParameter": {
          return intl.formatMessage({
            id: "data_integration.model_config.model_parameters_mapping.model_param.header.info",
            defaultMessage:
              "Names of parameters that are available in this performance model."
          });
        }
        case "Source": {
          return intl.formatMessage({
            id: "data_integration.model_config.model_parameters_mapping.source.header.info",
            defaultMessage:
              "Identifies how model parameter data goes into the application."
          });
        }
        case "Input": {
          return intl.formatMessage({
            id: "data_integration.model_config.model_parameters_mapping.input.header.info",
            defaultMessage:
              "It is a name that maps a customer parameter and data with a model parameter in the database of the application."
          });
        }

        default: {
          return "";
        }
      }
    },
    [intl]
  );

  const getComparedValue = useCallback(
    ({ cellName, cellValue }: { cellName: string; cellValue: string }) => {
      switch (cellName) {
        case "ModelParameter": {
          return getFormattedModelParameter(cellValue);
        }

        case "Source": {
          return cellValue
            ? intl.formatMessage({
                id: `data_integration.model_config.grid.${cellValue}`,
                defaultMessage: cellValue.toString()
              })
            : "";
        }

        case "Input": {
          return cellValue ?? "";
        }

        default: {
          return cellValue ?? "";
        }
      }
    },
    [getFormattedModelParameter, intl]
  );

  const suppressKeyboardEvent = useCallback(
    (params: SuppressKeyboardEventParams<IModelParameter>): boolean => {
      const isFieldToSuppress =
        params.colDef.field === "Source" || params.colDef.field === "Input";

      return (
        params.editing && params.event.code === "Enter" && isFieldToSuppress
      );
    },
    []
  );

  const columns = useMemo(
    () =>
      modelParametersMappingConfiguration.fields.map<
        IDataGridProps<IModelParameter>["columns"][0]
      >(({ fieldKey, fieldName, inputType }) => {
        const headerName = intl.formatMessage({
          id: fieldName.id,
          defaultMessage: fieldName.defaultMessage
        });

        const options = sources.map((val) => {
          return {
            value: val,
            label: intl.formatMessage({
              id: `data_integration.model_config.grid.${val}`,
              defaultMessage: val
            })
          };
        });

        return {
          field: fieldKey as keyof IModelParameter,
          headerName,
          headerTooltip: mapTextInfo(fieldKey as keyof IModelParameter),
          resizable: true,
          editType: mapToEditorType(inputType),
          customValidation,
          editable: (params: EditableCallbackParams) => {
            return (
              fieldKey === "Source" ||
              fieldKey === "Input" ||
              (fieldKey === "ModelParameter" && params.data.added)
            );
          },
          cellEditorSelector,
          options: fieldKey === "Source" ? options : undefined,
          suppressKeyboardEvent,
          headerComponent: ModelParametersMappingColumnHeader,
          headerComponentParams: {
            headerName,
            textInfo: mapTextInfo(fieldKey as keyof IModelParameter)
          },
          suppressMenu: true,
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: ["contains"],
            textMatcher: ({
              filterText,
              data,
              colDef
            }: {
              filterText: string;
              data: any;
              colDef: any;
            }) => {
              const filterTextLowerCase = (filterText || "").toLowerCase();
              const valueLowerCase = getComparedValue({
                cellName: data[colDef.field].cellName,
                cellValue: data[colDef.field].cellValue as string
              }).toLowerCase();

              return (
                valueLowerCase.indexOf(filterTextLowerCase) >= 0 ||
                (data.added ?? false)
              );
            },
            suppressAndOrCondition: true
          },
          sort: fieldKey === "ModelParameter" ? "asc" : null,
          comparator: (
            valueA: IDataGridCellProps<IModelParameter>,
            valueB: IDataGridCellProps<IModelParameter>,
            nodeA: RowNode<IConfigurableModelParameter>,
            nodeB: RowNode<IConfigurableModelParameter>
          ) => {
            const firstValue = getComparedValue({
              cellName: valueA.cellName,
              cellValue: valueA.cellValue as string
            }).toLowerCase();

            const secondValue = getComparedValue({
              cellName: valueB.cellName,
              cellValue: valueB.cellValue as string
            }).toLowerCase();

            const isAddedFirstNode = nodeA.data.added ?? false;

            const isAddedSecondNode = nodeB.data.added ?? false;

            if (firstValue === secondValue) return 0;
            if (
              firstValue > secondValue &&
              !isAddedFirstNode &&
              !isAddedSecondNode
            )
              return 1;
            if (
              firstValue < secondValue &&
              !isAddedFirstNode &&
              !isAddedSecondNode
            )
              return -1;

            return 0;
          }
        };
      }),
    [
      cellEditorSelector,
      customValidation,
      getComparedValue,
      intl,
      mapTextInfo,
      mapToEditorType,
      sources,
      suppressKeyboardEvent
    ]
  );

  const handleCellEditingStarted = useCallback(
    (event: CellEditingStartedEvent) => {
      DataIntegrationStore.setParameterToEditMode(event.rowIndex);
    },
    []
  );

  const rowsData = useMemo(
    () =>
      parameters.map(({ id, added, isNew, cells }) => {
        return {
          id,
          added,
          isNew,
          cells: cells.map((cell) => {
            return {
              ...cell,
              cellValue:
                cell.cellName === "ModelParameter"
                  ? getFormattedModelParameter(cell.cellValue as string)
                  : cell.cellValue ?? null,
              modified: cell.modified,
              valid: cell.valid,
              ...isCellValid(
                cell.cellName,
                dataIntegrationStore.mapToModelParameter(cells)
              )
            };
          })
        };
      }),
    [getFormattedModelParameter, isCellValid, parameters]
  );

  const handleDeleteParameter = useCallback((id: number) => {
    dataIntegrationStore.deleteParameter(id);
  }, []);

  const deleteParameterConfirm = useCallback(
    (id: number) => {
      notifications.confirm({
        title: intl.formatMessage({
          id: "configuration_tool.tab.connected_devices_parameter_mapping_delete_parameter_warn",
          defaultMessage: "Are you sure you want to delete the parameter?"
        }),
        okText: intl.formatMessage({
          id: "configuration_tool.tab.connected_devices_parameter_mapping_delete_parameter_submit",
          defaultMessage: "Delete"
        }),
        cancelText: intl.formatMessage({
          id: "configuration_tool.tab.connected_devices_parameter_mapping_delete_parameter_cancel",
          defaultMessage: "Cancel"
        }),
        onOk: () => {
          handleDeleteParameter(id);
        },
        className: "delete-parameter-confirmation-modal"
      });
    },
    [handleDeleteParameter, intl]
  );

  const handleCellChanged = useCallback(
    ({
      cellName,
      newValue,
      rowId,
      modified,
      valid,
      validationMessage
    }: IOnCellChangedConfig) => {
      DataIntegrationStore.setParameterField({
        rowId,
        cellName,
        newValue,
        modified,
        valid,
        validationMessage
      });
    },
    []
  );

  const headerMessage = useMemo(
    () => (
      <>
        <Icon name="info" variant="outlined" />
        {intl.formatMessage({
          id: "data_integration.model_config.model_parameters_mapping.model_param_edit_cell_info",
          defaultMessage: "Double-Click Cell To Edit"
        })}
      </>
    ),
    [intl]
  );
  const flashRow = useCallback((rowId: number) => {
    const newRowNode = gridRef.current.api.getRowNode(rowId.toString());
    if (newRowNode) {
      gridRef.current.api.ensureIndexVisible(newRowNode.rowIndex);
      setTimeout(() => {
        const displayRowNode = gridRef.current.api.getDisplayedRowAtIndex(
          newRowNode.rowIndex
        );
        if (displayRowNode)
          gridRef.current.api.flashCells({ rowNodes: [displayRowNode] });
      }, 100);
    }
  }, []);

  const handleAddNewParameter = useCallback(() => {
    const existingRow = DataIntegrationStore.getExistingNewRow();
    if (existingRow) {
      flashRow(existingRow.id);
    } else {
      setHighlightAfterUpdatedRows(true);
      DataIntegrationStore.addNewParameter();
      setTimeout(() => {
        setHighlightAfterUpdatedRows(false);
      }, 300);
    }
  }, [flashRow]);

  return (
    <Container className={`${className} model-parameters-mapping`}>
      <CustomGridWrapper>
        <GridHeaderWrapper>
          <SectionName
            messageId="data_integration.model_config.model_parameters_mapping"
            messageDefault="Model Parameters Mapping"
          />

          <ActionWrapper data-qa="model-parameter-mapping-action-wrapper">
            {headerMessage}
            {selectedModel && selectedModel.ModelType !== ModelType.Standard ? (
              <Button
                type="default"
                data-qa="add-parameter"
                onClick={handleAddNewParameter}
              >
                <Icon name="add" />
                <FormattedMessage
                  id="data_integration.model_config.model_parameters_mapping.add_param"
                  defaultMessage="Add parameter"
                />
              </Button>
            ) : null}
          </ActionWrapper>
        </GridHeaderWrapper>
        <DataGrid
          dataGridRef={gridRef}
          data={rowsData}
          columns={columns}
          onCellChanged={handleCellChanged}
          onRowDelete={deleteParameterConfirm}
          disabledRowsDeletion={selectedModel?.ModelType === ModelType.Standard}
          noRowsMessage={intl.formatMessage({
            id: "data_grid.no_data_found",
            defaultMessage: "No data available"
          })}
          onCellEditingStarted={handleCellEditingStarted}
          highlightAfterUpdatedRows={highlightAfterUpdatedRows}
          dataQa="model-parameter-mapping-grid"
          gridHeight="75vh"
          sizeColumnsToFit
          alwaysShowVerticalScroll
        />
      </CustomGridWrapper>
    </Container>
  );
};

export default ModelParametersMapping;
