// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import FieldTypes from "../../../models/FieldTypes";
import IConfiguration from "../models/IConfiguration";
import archingCompartmentCondition from "./archingCompartmentCondition";
import bearings from "./bearings";
import boolValues from "./boolValues";
import bushingManufacturers from "./bushingManufacturers";
import bushingSensors from "./bushingSensors";
import componentWiringCondition from "./componentWiringCondition";
import condition from "./condition";
import conditionOfValve from "./conditionOfValve";
import constructionTypes from "./constructionTypes";
import coolingClassificationConfiguration from "./coolingClassificationConfiguration";
import coolingClassificationType from "./coolingClassificationType";
import coolingCondition from "./coolingCondition";
import coolingTypes from "./coolingTypes";
import debris from "./debris";
import desiccantCondition from "./desiccantCondition";
import desiccantType from "./desiccantType";
import deviceCondition from "./deviceCondition";
import deviceType from "./deviceType";
import dgaStatus from "./dgaStatus";
import dielectricStrengthMeasurementGaps from "./dielectricStrengthMeasurementGaps";
import environment from "./environment";
import filterCondition from "./filterCondition";
import finCondition from "./finCondition";
import finish from "./finish";
import fluidTypes from "./fluidTypes";
import gasSensors from "./gasSensors";
import gasStandards from "./gasStandards";
import interruptingMedium from "./interruptingMedium";
import ltcArchingLocation from "./ltcArchingLocation";
import ltcCompartmentTypes from "./ltcCompartmentTypes";
import ltcOilPreservations from "./ltcOilPreservations";
import ltcType from "./ltcType";
import membraneLeaksPresent from "./membraneLeaksPresent";
import { models } from "./models";
import moistureSensors from "./moistureSensors";
import numberOfTanks from "./numberOfTanks";
import oilLevel from "./oilLevel";
import oilPreservationPressure from "./oilPreservationPressure";
import oilPreservationSystems from "./oilPreservationSystems";
import oilStandards from "./oilStandards";
import passFail from "./passFail";
import phasesCount from "./phasesCount";
import phasesInBank from "./phasesInBank";
import pumpsAge from "./pumpsAge";
import pumpsManufacturer from "./pumpsManufacturer";
import pumpTypes from "./pumpTypes";
import radiatorFansManufacturer from "./radiatorFansManufacturer";
import severity from "./severity";
import surgeArrestersType from "./surgeArrestersType";
import sweepFrequency from "./sweepFrequency";
import tapChangersDETCType from "./tapChangersDETCType";
import tapChangersOltcManufacturer from "./tapChangersOltcManufacturer";
import tapChangerTypes from "./tapChangerTypes";
import temperatureRise from "./temperatureRise";
import transformerConstructionTypes from "./transformerConstructionTypes";
import typical from "./typical";
import waterCoolerTypes from "./waterCoolerTypes";
import windingConfiguration from "./windingConfiguration";
import windingMetalTypes from "./windingMetalTypes";
import yesNo from "./yesNo";
import yesNoUnknown from "./yesNoUnknown";

export const inspectionConfiguration: IConfiguration = {
  forms: [
    {
      formKey: "details",
      showLastModificationDate: true,
      enterDataWithCurrentDate: true,
      preloadValues: true,
      sections: [
        {
          sectionName: {
            defaultMessage: "Standards",
            id: "configuration_tool.tab.details.section.standards"
          },
          sectionType: "list",
          span: { xs: 24, sm: 12, lg: 8 },
          fields: [
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.CurrentlyEnergized",
                defaultMessage: "Energized?"
              },
              fieldKey: "Common.CurrentlyEnergized",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.ConfigurationInfo.GasStandard",
                defaultMessage: "Gas Standard"
              },
              fieldKey: "Common.ConfigurationInfo.GasStandard",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: gasStandards
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.ConfigurationInfo.MaxTemperature",
                defaultMessage: "Maximum Allowed Temperature"
              },
              fieldKey: "Common.ConfigurationInfo.MaxTemperature",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 1000
                },
                {
                  type: "integer"
                }
              ],
              validationSchema: {
                type: "integer",
                minimum: 0,
                maximum: 1000
              },
              inputType: "Decimal",
              unit: "°C"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.MTMP.OfflineLoadFactor",
                defaultMessage: "Offline Load Factor"
              },
              fieldKey: "MTMP.OfflineLoadFactor",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 1
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 1
              },
              inputType: "Decimal",
              tooltip: {
                id: "configuration_tool.tab.details.parameter.OfflineLoadFactor.tooltip",
                defaultMessage:
                  "Expected range: 0-1; Example: 0.6; Per unit load (>1 is overload); What % load on average do you expect the transformer to be loaded to?"
              }
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.ConfigurationInfo.OilStandard",
                defaultMessage: "Oil Standard (IEEE/IEC)"
              },
              fieldKey: "Common.ConfigurationInfo.OilStandard",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: oilStandards
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.MTMP.TransformerInfo.NonSpareYears",
                defaultMessage: "Non Spare Years"
              },
              fieldKey: "MTMP.TransformerInfo.NonSpareYears",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number"
                }
              ],
              validationSchema: {
                type: "number"
              },
              inputType: "Decimal"
            }
          ]
        },
        {
          sectionName: {
            defaultMessage: "Nameplate Data",
            id: "configuration_tool.tab.details.section.nameplate_data"
          },
          sectionType: "list",
          span: { xs: 24, sm: 12, lg: 8 },
          fields: [
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.MTMP.TransformerInfo.PhaseCount",
                defaultMessage: "Phase Count"
              },
              fieldKey: "MTMP.TransformerInfo.PhaseCount",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "list",
              listValues: phasesCount
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.MTMP.TransformerInfo.LowVoltageRating",
                defaultMessage: "Low Voltage Rating"
              },
              fieldKey: "MTMP.TransformerInfo.LowVoltageRating",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number"
                }
              ],
              validationSchema: {
                type: "number"
              },
              inputType: "Decimal",
              unit: "kV"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.ThermalProfile.WindingMetal",
                defaultMessage: "Winding Metal"
              },
              fieldKey: "ThermalProfile.WindingMetal",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: windingMetalTypes
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.ThermalProfile.RatedAvgWindingRise",
                defaultMessage: "Rated Average Winding Rise"
              },
              fieldKey: "ThermalProfile.RatedAvgWindingRise",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 1,
                  max: 100
                },
                {
                  type: "integer"
                }
              ],
              validationSchema: {
                type: "integer",
                minimum: 1,
                maximum: 100
              },
              inputType: "Decimal",
              unit: "K"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.ThermalProfile.RatedBottomOilRise",
                defaultMessage: "Rated Bottom Oil Rise"
              },
              fieldKey: "ThermalProfile.RatedBottomOilRise",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 100
                },
                {
                  type: "integer"
                }
              ],
              validationSchema: {
                type: "integer",
                minimum: 0,
                maximum: 100
              },
              inputType: "Decimal",
              unit: "K"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.MTMP.TransformerInfo.BasicInsulationLevel",
                defaultMessage: "Basic Insulation Level"
              },
              fieldKey: "MTMP.TransformerInfo.BasicInsulationLevel",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number"
                }
              ],
              validationSchema: {
                type: "number"
              },
              inputType: "Decimal",
              unit: "kV"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.ConfigurationInfo.FluidType",
                defaultMessage: "Tank Fluid"
              },
              fieldKey: "Common.ConfigurationInfo.FluidType",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: fluidTypes
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.ThermalProfile.TankWeight",
                defaultMessage: "Tank Weight"
              },
              fieldKey: "ThermalProfile.TankWeight",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0
              },
              inputType: "Decimal",
              unit: "kg"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.ThermalProfile.TankFluidVolume",
                defaultMessage: "Tank Fluid Volume"
              },
              fieldKey: "ThermalProfile.TankFluidVolume",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0
              },
              inputType: "Decimal",
              unit: "L"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.OilPreservationType",
                defaultMessage: "Oil Preservation System"
              },
              fieldKey: "Common.OilPreservationType",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: oilPreservationSystems
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.MTMP.TransformerInfo.Construction",
                defaultMessage: "Construction"
              },
              fieldKey: "MTMP.TransformerInfo.Construction",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: transformerConstructionTypes
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.MTMP.TransformerInfo.PhaseInBank",
                defaultMessage: "Phase In Bank"
              },
              fieldKey: "MTMP.TransformerInfo.PhaseInBank",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: phasesInBank
            }
          ]
        },
        {
          sectionName: {
            defaultMessage: "Outline Drawing",
            id: "configuration_tool.tab.details.section.outline_drawing"
          },
          sectionType: "list",
          span: { xs: 24, sm: 12, lg: 8 },
          fields: [
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.MTMP.TransformerInfo.CoolingType",
                defaultMessage: "Cooling Type"
              },
              fieldKey: "MTMP.TransformerInfo.CoolingType",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: coolingTypes
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.MTMP.TransformerInfo.LtcCompartment",
                defaultMessage: "LTC Sample Compartment Type"
              },
              fieldKey: "MTMP.TransformerInfo.LtcCompartment",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: ltcCompartmentTypes
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.ThermalProfile.CoreAndCoilWeight",
                defaultMessage: "Core & Coil Weight"
              },
              fieldKey: "ThermalProfile.CoreAndCoilWeight",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0
              },
              inputType: "Decimal",
              unit: "kg"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Moisture.Sensor",
                defaultMessage: "Moisture Sensor"
              },
              fieldKey: "Moisture.Sensor",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: moistureSensors
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.DGA.GasSensor",
                defaultMessage: "Gas Sensor"
              },
              fieldKey: "DGA.GasSensor",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: gasSensors
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.MTMP.TransformerInfo.PumpType",
                defaultMessage: "Pump Type"
              },
              fieldKey: "MTMP.TransformerInfo.PumpType",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: pumpTypes
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.ExpertSystem.TapChangerType",
                defaultMessage: "Tap Changer Type"
              },
              fieldKey: "ExpertSystem.TapChangerType",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: tapChangerTypes
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.MTMP.TransformerInfo.LtcOilPreservation",
                defaultMessage: "LTC Oil Preservation System"
              },
              fieldKey: "MTMP.TransformerInfo.LtcOilPreservation",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: ltcOilPreservations
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.WaterCoolerType",
                defaultMessage: "Water Cooler Type"
              },
              fieldKey: "Common.WaterCoolerType",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: waterCoolerTypes
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Bushing.Online.Sensor",
                defaultMessage: "Bushing Sensor"
              },
              fieldKey: "Bushing.Online.Sensor",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: bushingSensors
            },
            {
              fieldName: {
                id: "configuration_tool.tab.visual_inspection.parameter.LTCType",
                defaultMessage: "LTC Type"
              },
              fieldKey: "VisualInspection.TapChangersDETC.LTCType",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: ltcType
            },
            {
              fieldName: {
                id: "configuration_tool.tab.visual_inspection.parameter.Manufacturer",
                defaultMessage: "Manufacturer"
              },
              fieldKey: "VisualInspection.TapChangersDETC.Manufacturer",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: tapChangersOltcManufacturer
            },
            {
              fieldName: {
                id: "configuration_tool.tab.visual_inspection.parameter.Model",
                defaultMessage: "Model"
              },
              fieldKey: "VisualInspection.TapChangersOLTC.Model",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number"
                }
              ],
              validationSchema: {
                type: "number"
              },
              inputType: "Decimal"
            },
            {
              fieldName: {
                id: "configuration_tool.tab.visual_inspection.parameter.InterruptingMedium",
                defaultMessage: "Interrupting Medium"
              },
              fieldKey: "VisualInspection.TapChangersDETC.InterruptingMedium",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: interruptingMedium
            }
          ]
        }
      ]
    },
    {
      formKey: "dga",
      enterDataWithCurrentDate: false,
      datePickerLabel: {
        id: "configuration_tool.label.date_of_the_test",
        defaultMessage: "Date of the test"
      },
      showLastModificationDate: true,
      preloadValues: true,
      sections: [
        {
          sectionName: {
            defaultMessage: "Dga Lab Result",
            id: "configuration_tool.tab.dga.section.dga_lab_results"
          },
          sectionType: "list",
          span: { xs: 24, sm: 12, lg: 8 },
          fields: [
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.DGA.OfflineData.Hydrogen",
                defaultMessage: "Hydrogen (Offline)"
              },
              fieldKey: "DGA.OfflineData.Hydrogen",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number"
                }
              ],
              validationSchema: {
                type: "number"
              },
              inputType: "Decimal",
              unit: "PPM"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.DGA.OfflineData.Methane",
                defaultMessage: "Methane (Offline)"
              },
              fieldKey: "DGA.OfflineData.Methane",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number"
                }
              ],
              validationSchema: {
                type: "number"
              },
              inputType: "Decimal",
              unit: "PPM"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.DGA.OfflineData.Ethane",
                defaultMessage: "Ethane (Offline)"
              },
              fieldKey: "DGA.OfflineData.Ethane",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number"
                }
              ],
              validationSchema: {
                type: "number"
              },
              inputType: "Decimal",
              unit: "PPM"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.DGA.OfflineData.Ethylene",
                defaultMessage: "Ethylene (Offline)"
              },
              fieldKey: "DGA.OfflineData.Ethylene",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number"
                }
              ],
              validationSchema: {
                type: "number"
              },
              inputType: "Decimal",
              unit: "PPM"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.DGA.OfflineData.Acetylene",
                defaultMessage: "Acetylene (Offline)"
              },
              fieldKey: "DGA.OfflineData.Acetylene",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number"
                }
              ],
              validationSchema: {
                type: "number"
              },
              inputType: "Decimal",
              unit: "PPM"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.DGA.OfflineData.CarbonMonoxide",
                defaultMessage: "Carbon Monoxide (Offline)"
              },
              fieldKey: "DGA.OfflineData.CarbonMonoxide",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number"
                }
              ],
              validationSchema: {
                type: "number"
              },
              inputType: "Decimal",
              unit: "PPM"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.DGA.OfflineData.CarbonDioxide",
                defaultMessage: "Carbon Dioxide (Offline)"
              },
              fieldKey: "DGA.OfflineData.CarbonDioxide",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number"
                }
              ],
              validationSchema: {
                type: "number"
              },
              inputType: "Decimal",
              unit: "PPM"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.DGA.OfflineData.Nitrogen",
                defaultMessage: "Nitrogen (Offline)"
              },
              fieldKey: "DGA.OfflineData.Nitrogen",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number"
                }
              ],
              validationSchema: {
                type: "number"
              },
              inputType: "Decimal",
              unit: "PPM"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.DGA.OfflineData.Oxygen",
                defaultMessage: "Oxygen (Offline)"
              },
              fieldKey: "DGA.OfflineData.Oxygen",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number"
                }
              ],
              validationSchema: {
                type: "number"
              },
              inputType: "Decimal",
              unit: "PPM"
            }
          ]
        },
        {
          sectionName: {
            defaultMessage: "Dga Lab Accuracies (Optional)",
            id: "configuration_tool.tab.dga.section.dga_lab_accuracies_optional"
          },
          sectionType: "list",
          span: { xs: 24, sm: 12, lg: 8 },
          fields: [
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.DGA.LabAccuracy.Hydrocarbon.LowConc.Percent",
                defaultMessage: "Hydrocarbon low conc. percentage"
              },
              fieldKey: "DGA.LabAccuracy.Hydrocarbon.LowConc.Percent",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 100
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 100
              },
              inputType: "Decimal"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.DGA.LabAccuracy.Hydrocarbon.LowConc.Min",
                defaultMessage: "Hydrocarbon low conc. min"
              },
              fieldKey: "DGA.LabAccuracy.Hydrocarbon.LowConc.Min",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number"
                }
              ],
              validationSchema: {
                type: "number"
              },
              inputType: "Decimal"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.DGA.LabAccuracy.Hydrogen.LowConc.Percent",
                defaultMessage: "Hydrogen low conc. percentage"
              },
              fieldKey: "DGA.LabAccuracy.Hydrogen.LowConc.Percent",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 100
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 100
              },
              inputType: "Decimal"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.DGA.LabAccuracy.Hydrogen.LowConc.Min",
                defaultMessage: "Hydrogen low conc. min"
              },
              fieldKey: "DGA.LabAccuracy.Hydrogen.LowConc.Min",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number"
                }
              ],
              validationSchema: {
                type: "number"
              },
              inputType: "Decimal"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.DGA.LabAccuracy.CarbonMonoxide.LowConc.Percent",
                defaultMessage: "Carbon monoxide low conc. percentage"
              },
              fieldKey: "DGA.LabAccuracy.CarbonMonoxide.LowConc.Percent",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 100
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 100
              },
              inputType: "Decimal"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.DGA.LabAccuracy.CarbonMonoxide.LowConc.Min",
                defaultMessage: "Carbon monoxide low conc. min"
              },
              fieldKey: "DGA.LabAccuracy.CarbonMonoxide.LowConc.Min",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number"
                }
              ],
              validationSchema: {
                type: "number"
              },
              inputType: "Decimal"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.DGA.LabAccuracy.Hydrocarbon.MediumConc.Percent",
                defaultMessage: "Hydrocarbon medium conc. percentage"
              },
              fieldKey: "DGA.LabAccuracy.Hydrocarbon.MediumConc.Percent",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 100
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 100
              },
              inputType: "Decimal"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.DGA.LabAccuracy.Hydrocarbon.MediumConc.Min",
                defaultMessage: "Hydrocarbon medium conc. min"
              },
              fieldKey: "DGA.LabAccuracy.Hydrocarbon.MediumConc.Min",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number"
                }
              ],
              validationSchema: {
                type: "number"
              },
              inputType: "Decimal"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.DGA.LabAccuracy.Hydrogen.MediumConc.Percent",
                defaultMessage: "Hydrogen medium conc. percentage"
              },
              fieldKey: "DGA.LabAccuracy.Hydrogen.MediumConc.Percent",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 100
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 100
              },
              inputType: "Decimal"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.DGA.LabAccuracy.Hydrogen.MediumConc.Min",
                defaultMessage: "Hydrogen medium conc. min"
              },
              fieldKey: "DGA.LabAccuracy.Hydrogen.MediumConc.Min",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [{ type: "number" }],
              validationSchema: {
                type: "number"
              },
              inputType: "Decimal"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.DGA.LabAccuracy.CarbonMonoxide.MediumConc.Percent",
                defaultMessage: "Carbon monoxide medium conc. percentage"
              },
              fieldKey: "DGA.LabAccuracy.CarbonMonoxide.MediumConc.Percent",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 100
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 100
              },
              inputType: "Decimal"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.DGA.LabAccuracy.CarbonMonoxide.MediumConc.Min",
                defaultMessage: "Carbon monoxide medium conc. min"
              },
              fieldKey: "DGA.LabAccuracy.CarbonMonoxide.MediumConc.Min",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number"
                }
              ],
              validationSchema: {
                type: "number"
              },
              inputType: "Decimal"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.DGA.LabAccuracy.Hydrocarbon.HighConc.Percent",
                defaultMessage: "Hydrocarbon high conc. percentage"
              },
              fieldKey: "DGA.LabAccuracy.Hydrocarbon.HighConc.Percent",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 100
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 100
              },
              inputType: "Decimal"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.DGA.LabAccuracy.Hydrocarbon.HighConc.Min",
                defaultMessage: "Hydrocarbon high conc. min"
              },
              fieldKey: "DGA.LabAccuracy.Hydrocarbon.HighConc.Min",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number"
                }
              ],
              validationSchema: {
                type: "number"
              },
              inputType: "Decimal"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.DGA.LabAccuracy.Hydrogen.HighConc.Percent",
                defaultMessage: "Hydrogen high conc. percentage"
              },
              fieldKey: "DGA.LabAccuracy.Hydrogen.HighConc.Percent",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 100
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 100
              },
              inputType: "Decimal"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.DGA.LabAccuracy.Hydrogen.HighConc.Min",
                defaultMessage: "Hydrogen high conc. min"
              },
              fieldKey: "DGA.LabAccuracy.Hydrogen.HighConc.Min",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number"
                }
              ],
              validationSchema: {
                type: "number"
              },
              inputType: "Decimal"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.DGA.LabAccuracy.CarbonMonoxide.HighConc.Percent",
                defaultMessage: "Carbon monoxide high conc. percentage"
              },
              fieldKey: "DGA.LabAccuracy.CarbonMonoxide.HighConc.Percent",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 100
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 100
              },
              inputType: "Decimal"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.DGA.LabAccuracy.CarbonMonoxide.HighConc.Min",
                defaultMessage: "Carbon monoxide high conc. min"
              },
              fieldKey: "DGA.LabAccuracy.CarbonMonoxide.HighConc.Min",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number"
                }
              ],
              validationSchema: {
                type: "number"
              },
              inputType: "Decimal"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.DGA.LabAccuracy.Repeatability.LowConcentration",
                defaultMessage: "Low concentration repeatability"
              },
              fieldKey: "DGA.LabAccuracy.Repeatability.LowConcentration",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number"
                }
              ],
              validationSchema: {
                type: "number"
              },
              inputType: "Decimal"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.DGA.LabAccuracy.Repeatability.MediumConcentration",
                defaultMessage: "Medium concentration repeatability"
              },
              fieldKey: "DGA.LabAccuracy.Repeatability.MediumConcentration",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number"
                }
              ],
              validationSchema: {
                type: "number"
              },
              inputType: "Decimal"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.DGA.LabAccuracy.Repeatability.HighConcentration",
                defaultMessage: "High concentration repeatability"
              },
              fieldKey: "DGA.LabAccuracy.Repeatability.HighConcentration",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number"
                }
              ],
              validationSchema: {
                type: "number"
              },
              inputType: "Decimal"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.DGA.LabAccuracy.Reproducibility",
                defaultMessage: "Reproducibility"
              },
              fieldKey: "DGA.LabAccuracy.Reproducibility",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number"
                }
              ],
              validationSchema: {
                type: "number"
              },
              inputType: "Decimal"
            }
          ]
        }
      ]
    },
    {
      formKey: "ltc",
      enterDataWithCurrentDate: false,
      sections: [
        {
          sectionName: {
            defaultMessage: "LTC Lab Results",
            id: "configuration_tool.ltc.ltc_lab_results"
          },
          sectionType: "list",
          span: { xs: 24, sm: 12, lg: 8 },
          fields: [
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.MTMP.InputData.TapChangerData.LtcAcetyleneLevel",
                defaultMessage: "LTC Acetylene"
              },
              fieldKey: "MTMP.InputData.TapChangerData.LtcAcetyleneLevel",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 9999999999999
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 9999999999999
              },
              inputType: "Decimal",
              unit: "PPM"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.MTMP.InputData.TapChangerData.LtcEthaneLevel",
                defaultMessage: "LTC Ethane"
              },
              fieldKey: "MTMP.InputData.TapChangerData.LtcEthaneLevel",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 999999999999
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 9999999999999
              },
              inputType: "Decimal",
              unit: "PPM"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.MTMP.InputData.TapChangerData.LtcEthyleneLevel",
                defaultMessage: "LTC Ethylene"
              },
              fieldKey: "MTMP.InputData.TapChangerData.LtcEthyleneLevel",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 999999999999
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 9999999999999
              },
              inputType: "Decimal",
              unit: "PPM"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.MTMP.InputData.TapChangerData.LtcHydrogenLevel",
                defaultMessage: "LTC Hydrogen"
              },
              fieldKey: "MTMP.InputData.TapChangerData.LtcHydrogenLevel",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 9999999999999
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 9999999999999
              },
              inputType: "Decimal",
              unit: "PPM"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.MTMP.InputData.TapChangerData.LtcMethaneLevel",
                defaultMessage: "LTC Methane"
              },
              fieldKey: "MTMP.InputData.TapChangerData.LtcMethaneLevel",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 9999999999999
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 9999999999999
              },
              inputType: "Decimal",
              unit: "PPM"
            }
          ]
        }
      ]
    },
    {
      formKey: "sot",
      enterDataWithCurrentDate: false,
      sections: [
        {
          sectionName: {
            defaultMessage: "SOT Results",
            id: "configuration_tool.tab.sot.section.sot_results"
          },
          sectionType: "list",
          span: { xs: 24, sm: 12, lg: 8 },
          fields: [
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.SOT.DielectricStrengthGap",
                defaultMessage: "Dielectric Strength Measurement Gap"
              },
              fieldKey: "SOT.DielectricStrengthGap",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: dielectricStrengthMeasurementGaps
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.SOT.SotData.DielectricStrength",
                defaultMessage: "Dielectric Strength / Breakdown Voltage"
              },
              fieldKey: "SOT.SotData.DielectricStrength",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0
              },
              unit: "kV"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.SOT.SotData.InhibitorContent",
                defaultMessage: "Inhibitor Content"
              },
              fieldKey: "SOT.SotData.InhibitorContent",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 100
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 100
              },
              unit: "%"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.SOT.SotData.InterfacialTension",
                defaultMessage: "Interfacial Tension"
              },
              fieldKey: "SOT.SotData.InterfacialTension",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: -100,
                  max: 1000000000000
                }
              ],
              validationSchema: {
                type: "number",
                minimum: -100,
                maximum: 1000000000000
              },
              unit: "mN/m"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.SOT.SotData.OilTemperature",
                defaultMessage: "Oil Sample Temperature"
              },
              fieldKey: "SOT.SotData.OilTemperature",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: -1000,
                  max: 1000
                }
              ],
              validationSchema: {
                type: "number",
                minimum: -1000,
                maximum: 1000
              },
              unit: "°C"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.SOT.SotData.OilPowerFactor25C",
                defaultMessage: "Oil Power/Dissipation Factor at 25°C"
              },
              fieldKey: "SOT.SotData.OilPowerFactor25C",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 100
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 100
              },
              unit: "%"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.SOT.SotData.OilPowerFactor90C",
                defaultMessage: "Oil Power/Dissipation Factor at 90°C"
              },
              fieldKey: "SOT.SotData.OilPowerFactor90C",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 100
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 100
              }
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.SOT.SotData.OilPowerFactor100C",
                defaultMessage: "Oil Power/Dissipation Factor at 100°C"
              },
              fieldKey: "SOT.SotData.OilPowerFactor100C",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 100
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 100
              },
              unit: "%"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.SOT.SotData.MoistureLevel",
                defaultMessage: "Moisture Level (offline)"
              },
              fieldKey: "SOT.SotData.MoistureLevel",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 100000000
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 100000000
              },
              unit: "ppm"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.SOT.MoistureIsSaturation",
                defaultMessage: "Moisture Is Saturation"
              },
              fieldKey: "SOT.MoistureIsSaturation",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.SOT.SotData.AcidNumber",
                defaultMessage: "Acid Number / Neutralization Index"
              },
              fieldKey: "SOT.SotData.AcidNumber",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 2
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 2
              },
              unit: "mgKOH/g"
            }
          ]
        },
        {
          sectionName: {
            defaultMessage: "SOT Results (Optional)",
            id: "configuration_tool.tab.sot.section.sot_results_optional"
          },
          sectionType: "list",
          span: { xs: 24, sm: 12, lg: 8 },
          fields: [
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Furans.TwoFurfuralDehyde",
                defaultMessage: "2FAL Portion of Furans Content Readings"
              },
              fieldKey: "Furans.TwoFurfuralDehyde",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              validationSchema: {
                type: "number"
              },
              unit: "ppb"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Furans.TotalFurans",
                defaultMessage: "Total Furans Content Readings"
              },
              fieldKey: "Furans.TotalFurans",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              validationSchema: {
                type: "number"
              },
              unit: "ppb"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Particle.SmallParticleCount",
                defaultMessage:
                  "Readings for number of small particles - in actual particles or ISO4406 count"
              },
              fieldKey: "Particle.SmallParticleCount",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              validationSchema: {
                type: "number"
              },
              unit: "ppm"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Particle.MediumParticleCount",
                defaultMessage:
                  "Readings for number of medium-size particles - in ISO4406 count"
              },
              fieldKey: "Particle.MediumParticleCount",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              validationSchema: {
                type: "number"
              },
              unit: "ppm"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Particle.LargeParticleCount",
                defaultMessage:
                  "Readings for number of large particles - in actual particles or ISO4406 count"
              },
              fieldKey: "Particle.LargeParticleCount",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              validationSchema: {
                type: "number"
              },
              unit: "ppm"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.MTMP.InsulationData.DegreeOfPolymerization",
                defaultMessage: "Degree of Polymerization"
              },
              fieldKey: "MTMP.InsulationData.DegreeOfPolymerization",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              validationSchema: {
                type: "number"
              }
            }
          ]
        }
      ]
    },
    {
      formKey: "electricalTests",
      enterDataWithCurrentDate: false,
      sections: [
        {
          sectionName: {
            defaultMessage: "Testing Flags",
            id: "configuration_tool.tab.electrical_tests.section.testing_flags"
          },
          sectionType: "list",
          span: { xs: 24, sm: 12, lg: 8 },
          fields: [
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.TestData.CoreInsulationResistanceIssue",
                defaultMessage: "Core Insulation Resistance Issue"
              },
              fieldKey: "TestData.CoreInsulationResistanceIssue",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.TestData.FrequencyResponseAnalysisIndicatesWindingMovement",
                defaultMessage:
                  "Frequency Response Analysis Indicates Winding Movement"
              },
              fieldKey:
                "TestData.FrequencyResponseAnalysisIndicatesWindingMovement",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.TestData.ImpedanceChangeIndicatesWindingMovement",
                defaultMessage: "Impedance Change Indicates Winding Movement"
              },
              fieldKey: "TestData.ImpedanceChangeIndicatesWindingMovement",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.TestData.InsulationCapacitanceChangeIndicatesWindingMovement",
                defaultMessage:
                  "Insulation Capacitance Change Indicates Winding Movement"
              },
              fieldKey:
                "TestData.InsulationCapacitanceChangeIndicatesWindingMovement",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.TestData.InsulationResistanceIssue",
                defaultMessage: "Insulation Resistance Issue"
              },
              fieldKey: "TestData.InsulationResistanceIssue",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.TestData.LtcBreakdownVoltageIssue",
                defaultMessage: "LTC Breakdown Voltage Issue"
              },
              fieldKey: "TestData.LtcBreakdownVoltageIssue",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.TestData.LtcDynamicResistanceIssue",
                defaultMessage: "LTC Dynamic Resistance Issue"
              },
              fieldKey: "TestData.LtcDynamicResistanceIssue",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.TestData.LtcMoistureIssue",
                defaultMessage: "LTC Moisture Issue"
              },
              fieldKey: "TestData.LtcMoistureIssue",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.TestData.TipUpTestApplied",
                defaultMessage: "Tip Up Test Applied"
              },
              fieldKey: "TestData.TipUpTestApplied",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.TestData.TtrTestIssue",
                defaultMessage: "TTR Test issue"
              },
              fieldKey: "TestData.TtrTestIssue",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.TestData.WindingResistanceIssue",
                defaultMessage: "Winding Resistance Issue"
              },
              fieldKey: "TestData.WindingResistanceIssue",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            }
          ]
        },
        {
          sectionName: {
            defaultMessage: "Electrical Tests (Optional)",
            id: "configuration_tool.tab.electrical_tests.section.electrical_tests_optional"
          },
          sectionType: "list",
          span: { xs: 24, sm: 12, lg: 8 },
          fields: [
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.ElectricalTests.DFRMoisture",
                defaultMessage: "DFR - Moisture"
              },
              fieldKey: "ElectricalTests.DFRMoisture",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: passFail
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.ElectricalTests.DFRContamination",
                defaultMessage: "DFR - contamination"
              },
              fieldKey: "ElectricalTests.DFRContamination",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: passFail
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.ElectricalTests.DFRConductivity",
                defaultMessage: "DFR - conductivity"
              },
              fieldKey: "ElectricalTests.DFRConductivity",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: passFail
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.ElectricalTests.SweepFrequencyResponseAnalysis",
                defaultMessage: "Sweep Frequency Response Analysis"
              },
              fieldKey: "ElectricalTests.SweepFrequencyResponseAnalysis",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: sweepFrequency
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.ElectricalTests.InducedVoltage",
                defaultMessage: "Induced Voltage (pC)"
              },
              fieldKey: "ElectricalTests.InducedVoltage",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 999999
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 999999
              }
            }
          ]
        }
      ]
    },
    {
      formKey: "bushings",
      enterDataWithCurrentDate: false,
      sections: [
        {
          sectionName: {
            defaultMessage: "Bushing Test Results",
            id: "configuration_tool.tab.bushings.section.bushing_test_results"
          },
          sectionType: "table",
          span: { xs: 24, lg: 8 },
          sectionConfiguration: {
            columns: [
              {
                labelName: {
                  id: "configuration_tool.tab.bushings.label.c1_power_factor_percent",
                  defaultMessage: "C1 Power Factor %"
                },
                span: 9
              },
              {
                labelName: {
                  id: "configuration_tool.tab.bushings.label.c1_capacitance",
                  defaultMessage: "C1 Capacitance"
                },
                span: 9
              }
            ],
            rows: [
              {
                labelName: {
                  id: "configuration_tool.label.h1",
                  defaultMessage: "H1"
                }
              },
              {
                labelName: {
                  id: "configuration_tool.label.h2",
                  defaultMessage: "H2"
                }
              },
              {
                labelName: {
                  id: "configuration_tool.label.h3",
                  defaultMessage: "H3"
                }
              },
              {
                labelName: {
                  id: "configuration_tool.label.x1",
                  defaultMessage: "X1"
                }
              },
              {
                labelName: {
                  id: "configuration_tool.label.x2",
                  defaultMessage: "X2"
                }
              },
              {
                labelName: {
                  id: "configuration_tool.label.x3",
                  defaultMessage: "X3"
                }
              },
              {
                labelName: {
                  id: "configuration_tool.label.y1",
                  defaultMessage: "Y1"
                }
              },
              {
                labelName: {
                  id: "configuration_tool.label.y2",
                  defaultMessage: "Y2"
                }
              },
              {
                labelName: {
                  id: "configuration_tool.label.y3",
                  defaultMessage: "Y3"
                }
              }
            ]
          },
          fields: [
            {
              fieldKey: "MTMP.InputData.BushingData.BushingH1PowerFactor",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 100
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 100
              }
            },
            {
              fieldKey: "MTMP.InputData.BushingData.BushingH1Capacitance",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 10000
                },
                {
                  type: "integer"
                }
              ],
              validationSchema: {
                type: "integer",
                minimum: 0,
                maximum: 10000
              }
            },
            {
              fieldKey: "MTMP.InputData.BushingData.BushingH2PowerFactor",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 100
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 100
              }
            },
            {
              fieldKey: "MTMP.InputData.BushingData.BushingH2Capacitance",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 10000
                },
                {
                  type: "integer"
                }
              ],
              validationSchema: {
                type: "integer",
                minimum: 0,
                maximum: 10000
              }
            },
            {
              fieldKey: "MTMP.InputData.BushingData.BushingH3PowerFactor",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 100
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 100
              }
            },
            {
              fieldKey: "MTMP.InputData.BushingData.BushingH3Capacitance",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 10000
                },
                {
                  type: "integer"
                }
              ],
              validationSchema: {
                type: "integer",
                minimum: 0,
                maximum: 10000
              }
            },
            {
              fieldKey: "MTMP.InputData.BushingData.BushingX1PowerFactor",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 100
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 100
              }
            },
            {
              fieldKey: "MTMP.InputData.BushingData.BushingX1Capacitance",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 10000
                },
                {
                  type: "integer"
                }
              ],
              validationSchema: {
                type: "integer",
                minimum: 0,
                maximum: 10000
              }
            },
            {
              fieldKey: "MTMP.InputData.BushingData.BushingX2PowerFactor",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 100
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 100
              }
            },
            {
              fieldKey: "MTMP.InputData.BushingData.BushingX2Capacitance",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 10000
                },
                {
                  type: "integer"
                }
              ],
              validationSchema: {
                type: "integer",
                minimum: 0,
                maximum: 10000
              }
            },
            {
              fieldKey: "MTMP.InputData.BushingData.BushingX3PowerFactor",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 100
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 100
              }
            },
            {
              fieldKey: "MTMP.InputData.BushingData.BushingX3Capacitance",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 10000
                },
                {
                  type: "integer"
                }
              ],
              validationSchema: {
                type: "integer",
                minimum: 0,
                maximum: 10000
              }
            },
            {
              fieldKey: "MTMP.InputData.BushingData.BushingY1PowerFactor",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 100
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 100
              }
            },
            {
              fieldKey: "MTMP.InputData.BushingData.BushingY1Capacitance",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 10000
                },
                {
                  type: "integer"
                }
              ],
              validationSchema: {
                type: "integer",
                minimum: 0,
                maximum: 10000
              }
            },
            {
              fieldKey: "MTMP.InputData.BushingData.BushingY2PowerFactor",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 100
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 100
              }
            },
            {
              fieldKey: "MTMP.InputData.BushingData.BushingY2Capacitance",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 10000
                },
                {
                  type: "integer"
                }
              ],
              validationSchema: {
                type: "integer",
                minimum: 0,
                maximum: 10000
              }
            },
            {
              fieldKey: "MTMP.InputData.BushingData.BushingY3PowerFactor",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 100
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 100
              }
            },
            {
              fieldKey: "MTMP.InputData.BushingData.BushingY3Capacitance",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 10000
                },
                {
                  type: "integer"
                }
              ],
              validationSchema: {
                type: "integer",
                minimum: 0,
                maximum: 10000
              }
            }
          ]
        }
      ]
    },
    {
      formKey: "factoryTestData",
      datePickerLabel: {
        id: "configuration_tool.label.date_of_the_test",
        defaultMessage: "Date of the test"
      },
      enterDataWithCurrentDate: false,
      showLastModificationDate: true,
      preloadValues: true,
      sections: [
        {
          sectionName: {
            defaultMessage: "Factory Test Report Data 1",
            id: "configuration_tool.tab.factory_test_data.section.factory_test_report_data1"
          },
          sectionType: "table",
          span: { xs: 24 },
          sectionConfiguration: {
            columns: [
              {
                labelName: {
                  id: "configuration_tool.tab.factory_test_data.label.serial_no",
                  defaultMessage: "Serial NO"
                },
                span: 3
              },
              {
                labelName: {
                  id: "configuration_tool.tab.factory_test_data.label.manufacturer",
                  defaultMessage: "Manufacturer"
                },
                span: 3
              },
              {
                labelName: {
                  id: "configuration_tool.tab.factory_test_data.label.type",
                  defaultMessage: "Type"
                },
                span: 2
              },
              {
                labelName: {
                  id: "configuration_tool.tab.factory_test_data.label.fat_pf_percent",
                  defaultMessage: "FAT PF%"
                },
                span: 2
              },
              {
                labelName: {
                  id: "configuration_tool.tab.factory_test_data.label.fat_cap",
                  defaultMessage: "FAT CAP"
                },
                span: 2
              },
              {
                labelName: {
                  id: "configuration_tool.tab.factory_test_data.label.kv",
                  defaultMessage: "KV"
                },
                span: 2
              },
              {
                labelName: {
                  id: "configuration_tool.tab.factory_test_data.label.construction_type",
                  defaultMessage: "Const."
                },
                span: 2
              },
              {
                labelName: {
                  id: "configuration_tool.tab.factory_test_data.label.install_date",
                  defaultMessage: "Install Date"
                },
                span: 3
              },
              {
                labelName: {
                  id: "configuration_tool.tab.factory_test_data.label.expected_life",
                  defaultMessage: "Expected Life"
                },
                span: 3
              }
            ],
            rows: [
              {
                labelName: {
                  id: "configuration_tool.label.h1",
                  defaultMessage: "H1"
                }
              },
              {
                labelName: {
                  id: "configuration_tool.label.h2",
                  defaultMessage: "H2"
                }
              },
              {
                labelName: {
                  id: "configuration_tool.label.h3",
                  defaultMessage: "H3"
                }
              },
              {
                labelName: {
                  id: "configuration_tool.label.x1",
                  defaultMessage: "X1"
                }
              },
              {
                labelName: {
                  id: "configuration_tool.label.x2",
                  defaultMessage: "X2"
                }
              },
              {
                labelName: {
                  id: "configuration_tool.label.x3",
                  defaultMessage: "X3"
                }
              },
              {
                labelName: {
                  id: "configuration_tool.label.y1",
                  defaultMessage: "Y1"
                }
              },
              {
                labelName: {
                  id: "configuration_tool.label.y2",
                  defaultMessage: "Y2"
                }
              },
              {
                labelName: {
                  id: "configuration_tool.label.y3",
                  defaultMessage: "Y3"
                }
              }
            ]
          },
          fields: [
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingH1Information.HistoricalSeriesNumber",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "String",
              rules: [{ whitespace: true }]
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingH1Information.Manufacturer",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: bushingManufacturers
            },
            {
              fieldKey: "MTMP.TransformerInfo.BushingH1Information.Model",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: models
            },
            {
              fieldKey: "MTMP.InputData.BushingData.BushingH1FatPowerFactor",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 10000000000
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 10000000000
              }
            },
            {
              fieldKey: "MTMP.InputData.BushingData.BushingH1FatCapacitance",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                { type: "number", min: 0, max: 10000 },
                { type: "integer" }
              ],
              validationSchema: {
                type: "integer",
                minimum: 0,
                maximum: 10000
              }
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingH1Information.VoltageClass",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [{ type: "number", min: 0, max: 10000 }],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 10000
              }
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingH1Information.ConstructionType",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: constructionTypes
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingH1Information.InstallationDate",
              fieldType: FieldTypes.Parameter,
              dataType: "DateTime",
              inputType: "DateTime",
              disableFutureDates: true,
              validationSchema: {
                type: "string",
                format: "date"
              }
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingH1Information.VendorRecommendedEndOfLife",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                { type: "number", min: 0, max: 500 },
                { type: "integer" }
              ],
              validationSchema: {
                type: "integer",
                minimum: 0,
                maximum: 500
              }
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingH2Information.HistoricalSeriesNumber",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "String",
              rules: [{ whitespace: true }]
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingH2Information.Manufacturer",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: bushingManufacturers
            },
            {
              fieldKey: "MTMP.TransformerInfo.BushingH2Information.Model",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: models
            },
            {
              fieldKey: "MTMP.InputData.BushingData.BushingH2FatPowerFactor",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 10000000000
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 10000000000
              }
            },
            {
              fieldKey: "MTMP.InputData.BushingData.BushingH2FatCapacitance",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                { type: "number", min: 0, max: 10000 },
                { type: "integer" }
              ],
              validationSchema: {
                type: "integer",
                minimum: 0,
                maximum: 10000
              }
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingH2Information.VoltageClass",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [{ type: "number", min: 0, max: 10000 }],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 10000
              }
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingH2Information.ConstructionType",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: constructionTypes
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingH2Information.InstallationDate",
              fieldType: FieldTypes.Parameter,
              dataType: "DateTime",
              inputType: "DateTime",
              disableFutureDates: true,
              validationSchema: {
                type: "string",
                format: "date"
              }
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingH2Information.VendorRecommendedEndOfLife",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                { type: "number", min: 0, max: 500 },
                { type: "integer" }
              ],
              validationSchema: {
                type: "integer",
                minimum: 0,
                maximum: 500
              }
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingH3Information.HistoricalSeriesNumber",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "String",
              rules: [{ whitespace: true }]
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingH3Information.Manufacturer",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: bushingManufacturers
            },
            {
              fieldKey: "MTMP.TransformerInfo.BushingH3Information.Model",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: models
            },
            {
              fieldKey: "MTMP.InputData.BushingData.BushingH3FatPowerFactor",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 10000000000
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 10000000000
              }
            },
            {
              fieldKey: "MTMP.InputData.BushingData.BushingH3FatCapacitance",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                { type: "number", min: 0, max: 10000 },
                { type: "integer" }
              ],
              validationSchema: {
                type: "integer",
                minimum: 0,
                maximum: 10000
              }
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingH3Information.VoltageClass",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [{ type: "number", min: 0, max: 10000 }],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 10000
              }
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingH3Information.ConstructionType",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: constructionTypes
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingH3Information.InstallationDate",
              fieldType: FieldTypes.Parameter,
              dataType: "DateTime",
              inputType: "DateTime",
              disableFutureDates: true,
              validationSchema: {
                type: "string",
                format: "date"
              }
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingH3Information.VendorRecommendedEndOfLife",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                { type: "number", min: 0, max: 500 },
                { type: "integer" }
              ],
              validationSchema: {
                type: "integer",
                minimum: 0,
                maximum: 500
              }
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingX1Information.HistoricalSeriesNumber",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "String",
              rules: [{ whitespace: true }]
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingX1Information.Manufacturer",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: bushingManufacturers
            },
            {
              fieldKey: "MTMP.TransformerInfo.BushingX1Information.Model",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: models
            },
            {
              fieldKey: "MTMP.InputData.BushingData.BushingX1FatPowerFactor",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 10000000000
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 10000000000
              }
            },
            {
              fieldKey: "MTMP.InputData.BushingData.BushingX1FatCapacitance",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                { type: "number", min: 0, max: 10000 },
                { type: "integer" }
              ],
              validationSchema: {
                type: "integer",
                minimum: 0,
                maximum: 10000
              }
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingX1Information.VoltageClass",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [{ type: "number", min: 0, max: 10000 }],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 10000
              }
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingX1Information.ConstructionType",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: constructionTypes
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingX1Information.InstallationDate",
              fieldType: FieldTypes.Parameter,
              dataType: "DateTime",
              inputType: "DateTime",
              disableFutureDates: true,
              validationSchema: {
                type: "string",
                format: "date"
              }
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingX1Information.VendorRecommendedEndOfLife",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                { type: "number", min: 0, max: 500 },
                { type: "integer" }
              ],
              validationSchema: {
                type: "integer",
                minimum: 0,
                maximum: 500
              }
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingX2Information.HistoricalSeriesNumber",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "String",
              rules: [{ whitespace: true }]
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingX2Information.Manufacturer",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: bushingManufacturers
            },
            {
              fieldKey: "MTMP.TransformerInfo.BushingX2Information.Model",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: models
            },
            {
              fieldKey: "MTMP.InputData.BushingData.BushingX2FatPowerFactor",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 10000000000
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 10000000000
              }
            },
            {
              fieldKey: "MTMP.InputData.BushingData.BushingX2FatCapacitance",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                { type: "number", min: 0, max: 10000 },
                { type: "integer" }
              ],
              validationSchema: {
                type: "integer",
                minimum: 0,
                maximum: 10000
              }
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingX2Information.VoltageClass",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [{ type: "number", min: 0, max: 10000 }],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 10000
              }
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingX2Information.ConstructionType",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: constructionTypes
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingX2Information.InstallationDate",
              fieldType: FieldTypes.Parameter,
              dataType: "DateTime",
              inputType: "DateTime",
              disableFutureDates: true,
              validationSchema: {
                type: "string",
                format: "date"
              }
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingX2Information.VendorRecommendedEndOfLife",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                { type: "number", min: 0, max: 500 },
                { type: "integer" }
              ],
              validationSchema: {
                type: "integer",
                minimum: 0,
                maximum: 500
              }
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingX3Information.HistoricalSeriesNumber",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "String",
              rules: [{ whitespace: true }]
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingX3Information.Manufacturer",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: bushingManufacturers
            },
            {
              fieldKey: "MTMP.TransformerInfo.BushingX3Information.Model",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: models
            },
            {
              fieldKey: "MTMP.InputData.BushingData.BushingX3FatPowerFactor",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 10000000000
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 10000000000
              }
            },
            {
              fieldKey: "MTMP.InputData.BushingData.BushingX3FatCapacitance",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                { type: "number", min: 0, max: 10000 },
                { type: "integer" }
              ],
              validationSchema: {
                type: "integer",
                minimum: 0,
                maximum: 10000
              }
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingX3Information.VoltageClass",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [{ type: "number", min: 0, max: 10000 }],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 10000
              }
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingX3Information.ConstructionType",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: constructionTypes
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingX3Information.InstallationDate",
              fieldType: FieldTypes.Parameter,
              dataType: "DateTime",
              inputType: "DateTime",
              disableFutureDates: true,
              validationSchema: {
                type: "string",
                format: "date"
              }
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingX3Information.VendorRecommendedEndOfLife",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                { type: "number", min: 0, max: 500 },
                { type: "integer" }
              ],
              validationSchema: {
                type: "integer",
                minimum: 0,
                maximum: 500
              }
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingY1Information.HistoricalSeriesNumber",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "String",
              rules: [{ whitespace: true }]
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingY1Information.Manufacturer",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: bushingManufacturers
            },
            {
              fieldKey: "MTMP.TransformerInfo.BushingY1Information.Model",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: models
            },
            {
              fieldKey: "MTMP.InputData.BushingData.BushingY1FatPowerFactor",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 10000000000
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 10000000000
              }
            },
            {
              fieldKey: "MTMP.InputData.BushingData.BushingY1FatCapacitance",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                { type: "number", min: 0, max: 10000 },
                { type: "integer" }
              ],
              validationSchema: {
                type: "integer",
                minimum: 0,
                maximum: 10000
              }
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingY1Information.VoltageClass",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [{ type: "number", min: 0, max: 10000 }],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 10000
              }
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingY1Information.ConstructionType",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: constructionTypes
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingY1Information.InstallationDate",
              fieldType: FieldTypes.Parameter,
              dataType: "DateTime",
              inputType: "DateTime",
              disableFutureDates: true,
              validationSchema: {
                type: "string",
                format: "date"
              }
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingY1Information.VendorRecommendedEndOfLife",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                { type: "number", min: 0, max: 500 },
                { type: "integer" }
              ],
              validationSchema: {
                type: "integer",
                minimum: 0,
                maximum: 500
              }
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingY2Information.HistoricalSeriesNumber",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "String",
              rules: [{ whitespace: true }]
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingY2Information.Manufacturer",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: bushingManufacturers
            },
            {
              fieldKey: "MTMP.TransformerInfo.BushingY2Information.Model",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: models
            },
            {
              fieldKey: "MTMP.InputData.BushingData.BushingY2FatPowerFactor",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 10000000000
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 10000000000
              }
            },
            {
              fieldKey: "MTMP.InputData.BushingData.BushingY2FatCapacitance",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                { type: "number", min: 0, max: 10000 },
                { type: "integer" }
              ],
              validationSchema: {
                type: "integer",
                minimum: 0,
                maximum: 10000
              }
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingY2Information.VoltageClass",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [{ type: "number", min: 0, max: 10000 }],
              validationSchema: {
                type: "number",
                Minimum: 0,
                Maximum: 10000
              }
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingY2Information.ConstructionType",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: constructionTypes
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingY2Information.InstallationDate",
              fieldType: FieldTypes.Parameter,
              dataType: "DateTime",
              inputType: "DateTime",
              disableFutureDates: true,
              validationSchema: {
                type: "string",
                format: "date"
              }
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingY2Information.VendorRecommendedEndOfLife",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                { type: "number", min: 0, max: 500 },
                { type: "integer" }
              ],
              validationSchema: {
                type: "integer",
                minimum: 0,
                maximum: 500
              }
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingY3Information.HistoricalSeriesNumber",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "String",
              rules: [{ whitespace: true }]
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingY3Information.Manufacturer",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: bushingManufacturers
            },
            {
              fieldKey: "MTMP.TransformerInfo.BushingY3Information.Model",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: models
            },
            {
              fieldKey: "MTMP.InputData.BushingData.BushingY3FatPowerFactor",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 10000000000
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 10000000000
              }
            },
            {
              fieldKey: "MTMP.InputData.BushingData.BushingY3FatCapacitance",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                { type: "number", min: 0, max: 10000 },
                { type: "integer" }
              ],
              validationSchema: {
                type: "integer",
                minimum: 0,
                maximum: 10000
              }
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingY3Information.VoltageClass",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [{ type: "number", min: 0, max: 10000 }],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 10000
              }
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingY3Information.ConstructionType",
              fieldType: FieldTypes.Parameter,
              dataType: "String",
              inputType: "list",
              listValues: constructionTypes
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingY3Information.InstallationDate",
              fieldType: FieldTypes.Parameter,
              dataType: "DateTime",
              inputType: "DateTime",
              disableFutureDates: true,
              validationSchema: {
                type: "string",
                format: "date"
              }
            },
            {
              fieldKey:
                "MTMP.TransformerInfo.BushingY3Information.VendorRecommendedEndOfLife",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                { type: "number", min: 0, max: 500 },
                { type: "integer" }
              ],
              validationSchema: {
                type: "integer",
                minimum: 0,
                maximum: 500
              }
            }
          ]
        },
        {
          sectionList: [
            {
              sectionName: {
                defaultMessage: "Factory Test Report Data 2",
                id: "configuration_tool.tab.factory_test_data.section.factory_test_report_data2"
              },
              sectionType: "table",
              sectionConfiguration: {
                columns: [
                  {
                    labelName: {
                      id: "configuration_tool.tab.factory_test_data.label.hot_spot_height",
                      defaultMessage: "Hot Spot Height"
                    },
                    span: 4
                  },
                  {
                    labelName: {
                      id: "configuration_tool.tab.factory_test_data.label.rated_stray_loss",
                      defaultMessage: "Rated Stray Loss"
                    },
                    span: 4
                  },
                  {
                    labelName: {
                      id: "configuration_tool.tab.factory_test_data.label.winding_eddy_loss",
                      defaultMessage: "Winding Eddy Loss"
                    },
                    span: 4
                  },
                  {
                    labelName: {
                      id: "configuration_tool.tab.factory_test_data.label.winding_eddy_loss_i2r",
                      defaultMessage: "Winding Eddy Loss I2R"
                    },
                    span: 4
                  },
                  {
                    labelName: {
                      id: "configuration_tool.tab.factory_test_data.label.winding_loss_ratio_@hotspot",
                      defaultMessage: "Winding Loss Ratio @ Hotspot"
                    },
                    span: 5
                  }
                ],
                rows: [
                  {
                    labelName: {
                      id: "configuration_tool.label.hv",
                      defaultMessage: "HV"
                    }
                  },
                  {
                    labelName: {
                      id: "configuration_tool.label.xv",
                      defaultMessage: "XV"
                    }
                  },
                  {
                    labelName: {
                      id: "configuration_tool.label.yv",
                      defaultMessage: "YV"
                    }
                  }
                ]
              },
              fields: [
                {
                  fieldKey:
                    "ThermalProfile.HeatRunTestInfo.HV.RatedHotSpotHeight",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  inputType: "Decimal",
                  rules: [{ type: "number", min: 0 }],
                  validationSchema: {
                    type: "number",
                    minimum: 0
                  }
                },
                {
                  fieldKey: "ThermalProfile.HeatRunTestInfo.HV.RatedStrayLoss",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  inputType: "Decimal",
                  rules: [{ type: "number", min: 0 }],
                  validationSchema: {
                    type: "number",
                    minimum: 0
                  }
                },
                {
                  fieldKey:
                    "ThermalProfile.HeatRunTestInfo.HV.RatedWindingLossEddy",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  inputType: "Decimal",
                  rules: [{ type: "number", min: 0 }], //TODO check if not equal to 0 is correct
                  validationSchema: {
                    type: "number",
                    minimum: 0
                  }
                },
                {
                  fieldKey:
                    "ThermalProfile.HeatRunTestInfo.HV.RatedWindingLossI2R",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  inputType: "Decimal",
                  rules: [{ type: "number", min: 0 }],
                  validationSchema: {
                    type: "number",
                    minimum: 0
                  }
                },
                {
                  fieldKey:
                    "ThermalProfile.HeatRunTestInfo.HV.RatedWindingLossRatioHotSpot",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  inputType: "Decimal",
                  rules: [{ type: "number", min: 0, max: 10000000 }],
                  validationSchema: {
                    type: "number",
                    minimum: 0,
                    maximum: 10000000
                  }
                },
                {
                  fieldKey:
                    "ThermalProfile.HeatRunTestInfo.XV.RatedHotSpotHeight",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  inputType: "Decimal",
                  rules: [{ type: "number", min: 0 }],
                  validationSchema: {
                    type: "number",
                    minimum: 0
                  }
                },
                {
                  fieldKey: "ThermalProfile.HeatRunTestInfo.XV.RatedStrayLoss",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  inputType: "Decimal",
                  rules: [{ type: "number", min: 0 }],
                  validationSchema: {
                    type: "number",
                    minimum: 0
                  }
                },
                {
                  fieldKey:
                    "ThermalProfile.HeatRunTestInfo.XV.RatedWindingLossEddy",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  inputType: "Decimal",
                  rules: [{ type: "number", min: 0 }], //TODO check if not equal to 0 is correct
                  validationSchema: {
                    type: "number",
                    minimum: 0
                  }
                },
                {
                  fieldKey:
                    "ThermalProfile.HeatRunTestInfo.XV.RatedWindingLossI2R",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  inputType: "Decimal",
                  rules: [{ type: "number", min: 0 }],
                  validationSchema: {
                    type: "number",
                    minimum: 0
                  }
                },
                {
                  fieldKey:
                    "ThermalProfile.HeatRunTestInfo.XV.RatedWindingLossRatioHotSpot",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  inputType: "Decimal",
                  rules: [{ type: "number", min: 0, max: 10000000 }],
                  validationSchema: {
                    type: "number",
                    minimum: 0,
                    maximum: 10000000
                  }
                },
                {
                  fieldKey:
                    "ThermalProfile.HeatRunTestInfo.YV.RatedHotSpotHeight",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  inputType: "Decimal",
                  rules: [{ type: "number", min: 0 }],
                  validationSchema: {
                    type: "number",
                    minimum: 0
                  }
                },
                {
                  fieldKey: "ThermalProfile.HeatRunTestInfo.YV.RatedStrayLoss",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  inputType: "Decimal",
                  rules: [{ type: "number", min: 0 }],
                  validationSchema: {
                    type: "number",
                    minimum: 0
                  }
                },
                {
                  fieldKey:
                    "ThermalProfile.HeatRunTestInfo.YV.RatedWindingLossEddy",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  inputType: "Decimal",
                  rules: [{ type: "number", min: 0 }], //TODO check if not equal to 0 is correct
                  validationSchema: {
                    type: "number",
                    minimum: 0
                  }
                },
                {
                  fieldKey:
                    "ThermalProfile.HeatRunTestInfo.YV.RatedWindingLossI2R",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  inputType: "Decimal",
                  rules: [{ type: "number", min: 0 }],
                  validationSchema: {
                    type: "number",
                    minimum: 0
                  }
                },
                {
                  fieldKey:
                    "ThermalProfile.HeatRunTestInfo.YV.RatedWindingLossRatioHotSpot",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  inputType: "Decimal",
                  rules: [{ type: "number", min: 0, max: 10000000 }],
                  validationSchema: {
                    type: "number",
                    minimum: 0,
                    maximum: 10000000
                  }
                }
              ]
            },
            {
              sectionName: {
                defaultMessage: "Winding Rise Test Results",
                id: "configuration_tool.tab.factory_test_data.section.winding_rise_test_results"
              },
              sectionType: "table",
              sectionConfiguration: {
                columns: [
                  {
                    labelName: {
                      id: "configuration_tool.tab.factory_test_data.label.od",
                      defaultMessage: "OD"
                    },
                    span: 4
                  },
                  {
                    labelName: {
                      id: "configuration_tool.tab.factory_test_data.label.of",
                      defaultMessage: "OF"
                    },
                    span: 4
                  },
                  {
                    labelName: {
                      id: "configuration_tool.tab.factory_test_data.label.on",
                      defaultMessage: "ON"
                    },
                    span: 4
                  },
                  {
                    labelName: {
                      id: "configuration_tool.tab.factory_test_data.label.onan",
                      defaultMessage: "ONAN"
                    },
                    span: 4
                  },
                  {
                    labelName: {
                      id: "configuration_tool.tab.factory_test_data.label.time_rise_constant",
                      defaultMessage: "Time Rise Constant"
                    },
                    span: 5
                  }
                ],
                rows: [
                  {
                    labelName: {
                      id: "configuration_tool.label.hv",
                      defaultMessage: "HV"
                    }
                  },
                  {
                    labelName: {
                      id: "configuration_tool.label.xv",
                      defaultMessage: "XV"
                    }
                  },
                  {
                    labelName: {
                      id: "configuration_tool.label.yv",
                      defaultMessage: "YV"
                    }
                  }
                ]
              },
              fields: [
                {
                  fieldKey:
                    "ThermalProfile.HeatRunTestInfo.HV.TestedAvgWindingRise.OD",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  inputType: "Decimal",
                  rules: [{ type: "number", min: 0, max: 10000 }],
                  validationSchema: {
                    type: "number",
                    minimum: 0,
                    maximum: 10000
                  }
                },
                {
                  fieldKey:
                    "ThermalProfile.HeatRunTestInfo.HV.TestedAvgWindingRise.OF",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  inputType: "Decimal",
                  rules: [{ type: "number", min: -100, max: 1000 }],
                  validationSchema: {
                    type: "number",
                    minimum: -100,
                    maximum: 1000
                  }
                },
                {
                  fieldKey:
                    "ThermalProfile.HeatRunTestInfo.HV.TestedAvgWindingRise.ON",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  inputType: "Decimal",
                  rules: [{ type: "number", min: -100, max: 1000 }],
                  validationSchema: {
                    type: "number",
                    minimum: -100,
                    maximum: 1000
                  }
                },
                {
                  fieldKey:
                    "ThermalProfile.HeatRunTestInfo.HV.TestedAvgWindingRise.ONAN",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  inputType: "Decimal",
                  rules: [{ type: "number", min: -100, max: 1000 }],
                  validationSchema: {
                    type: "number",
                    minimum: -100,
                    maximum: 1000
                  }
                },
                {
                  fieldKey:
                    "ThermalProfile.HeatRunTestInfo.HV.WindingTimeConstant",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  inputType: "Decimal",
                  rules: [{ type: "number", min: 0, max: 10000000 }],
                  validationSchema: {
                    type: "number",
                    minimum: 0,
                    maximum: 10000000
                  }
                },
                {
                  fieldKey:
                    "ThermalProfile.HeatRunTestInfo.XV.TestedAvgWindingRise.OD",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  inputType: "Decimal",
                  rules: [{ type: "number", min: 0, max: 10000 }],
                  validationSchema: {
                    type: "number",
                    minimum: 0,
                    maximum: 10000
                  }
                },
                {
                  fieldKey:
                    "ThermalProfile.HeatRunTestInfo.XV.TestedAvgWindingRise.OF",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  inputType: "Decimal",
                  rules: [{ type: "number", min: -100, max: 1000 }],
                  validationSchema: {
                    type: "number",
                    minimum: -100,
                    maximum: 1000
                  }
                },
                {
                  fieldKey:
                    "ThermalProfile.HeatRunTestInfo.XV.TestedAvgWindingRise.ON",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  inputType: "Decimal",
                  rules: [{ type: "number", min: -100, max: 1000 }],
                  validationSchema: {
                    type: "number",
                    minimum: -100,
                    maximum: 1000
                  }
                },
                {
                  fieldKey:
                    "ThermalProfile.HeatRunTestInfo.XV.TestedAvgWindingRise.ONAN",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  inputType: "Decimal",
                  rules: [{ type: "number", min: -100, max: 1000 }],
                  validationSchema: {
                    type: "number",
                    minimum: -100,
                    maximum: 1000
                  }
                },
                {
                  fieldKey:
                    "ThermalProfile.HeatRunTestInfo.XV.WindingTimeConstant",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  inputType: "Decimal",
                  rules: [{ type: "number", min: 0, max: 10000000 }],
                  validationSchema: {
                    type: "number",
                    minimum: 0,
                    maximum: 10000000
                  }
                },
                {
                  fieldKey:
                    "ThermalProfile.HeatRunTestInfo.YV.TestedAvgWindingRise.OD",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  inputType: "Decimal",
                  rules: [{ type: "number", min: 0, max: 10000 }],
                  validationSchema: {
                    type: "number",
                    minimum: 0,
                    maximum: 10000
                  }
                },
                {
                  fieldKey:
                    "ThermalProfile.HeatRunTestInfo.YV.TestedAvgWindingRise.OF",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  inputType: "Decimal",
                  rules: [{ type: "number", min: -100, max: 1000 }],
                  validationSchema: {
                    type: "number",
                    minimum: -100,
                    maximum: 1000
                  }
                },
                {
                  fieldKey:
                    "ThermalProfile.HeatRunTestInfo.YV.TestedAvgWindingRise.ON",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  inputType: "Decimal",
                  rules: [{ type: "number", min: -100, max: 1000 }],
                  validationSchema: {
                    type: "number",
                    minimum: -100,
                    maximum: 1000
                  }
                },
                {
                  fieldKey:
                    "ThermalProfile.HeatRunTestInfo.YV.TestedAvgWindingRise.ONAN",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  inputType: "Decimal",
                  rules: [{ type: "number", min: -100, max: 1000 }],
                  validationSchema: {
                    type: "number",
                    minimum: -100,
                    maximum: 1000
                  }
                },
                {
                  fieldKey:
                    "ThermalProfile.HeatRunTestInfo.YV.WindingTimeConstant",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  inputType: "Decimal",
                  rules: [{ type: "number", min: 0, max: 10000000 }],
                  validationSchema: {
                    type: "number",
                    minimum: 0,
                    maximum: 10000000
                  }
                }
              ]
            }
          ],
          span: { xs: 24, lg: 16 }
        },
        {
          sectionName: {
            defaultMessage: "Factory Test Report Data 3",
            id: "configuration_tool.tab.factory_test_data.section.factory_test_report_data3"
          },
          sectionType: "list",
          span: { xs: 12, lg: 8 },
          fields: [
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.ThermalProfile.CoreLoss",
                defaultMessage: "Core Loss"
              },
              fieldKey: "ThermalProfile.CoreLoss",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [{ type: "number", max: 100000000 }, { type: "integer" }],
              validationSchema: {
                type: "number",
                maximum: 100000000
              }
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.ThermalProfile.LossBasePower",
                defaultMessage: "Loss Base Power"
              },
              fieldKey: "ThermalProfile.LossBasePower",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [{ type: "number", max: 100000 }, { type: "integer" }],
              validationSchema: {
                type: "number",
                maximum: 100000
              }
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.ThermalProfile.LossBaseTemp",
                defaultMessage: "Loss Base Temperature"
              },
              fieldKey: "ThermalProfile.LossBaseTemp",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [{ type: "number", min: -100, max: 1000 }],
              validationSchema: {
                type: "number",
                minimum: -100,
                maximum: 1000
              }
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.ThermalProfile.CoreOverexcitation",
                defaultMessage: "Core Overexcitation Flag"
              },
              fieldKey: "ThermalProfile.CoreOverexcitation",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.ThermalProfile.CoreOverexcitationStart",
                defaultMessage: "Core Overexcitation Start Hour"
              },
              fieldKey: "ThermalProfile.CoreOverexcitationStart",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [{ type: "number", min: 1, max: 24 }, { type: "integer" }],
              validationSchema: {
                type: "integer",
                minimum: 0,
                maximum: 100000000
              }
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.ThermalProfile.CoreOverexcitationEnd",
                defaultMessage: "Core Overexcitation End Hour"
              },
              fieldKey: "ThermalProfile.CoreOverexcitationEnd",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [{ type: "number", min: 1, max: 24 }, { type: "integer" }],
              validationSchema: {
                type: "integer",
                minimum: 1,
                maximum: 24
              }
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.ThermalProfile.CoreOverexcitationLoss",
                defaultMessage: "Core Overexcitation Loss"
              },
              fieldKey: "ThermalProfile.CoreOverexcitationLoss",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [{ type: "number", max: 100000000 }, { type: "integer" }],
              validationSchema: {
                type: "integer",
                maximum: 100000000
              }
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.SOT.OriginalInhibitorLevel",
                defaultMessage: "Original Inhibitor Level"
              },
              fieldType: FieldTypes.Parameter,
              fieldKey: "SOT.OriginalInhibitorLevel",
              dataType: "Decimal",
              inputType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 100
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 100
              },
              unit: "%"
            }
          ]
        }
      ]
    },
    {
      formKey: "visualInspection",
      enterDataWithCurrentDate: false,
      subTabs: [
        {
          tabName: {
            defaultMessage: "Nameplate",
            id: "configuration_tool.tab.visual_inspection.sub_tab.nameplate"
          },
          sections: [
            {
              sectionName: {
                defaultMessage: "Classification",
                id: "configuration_tool.tab.visual_inspection.section.classification"
              },
              sectionType: "list",
              span: { xs: 24, sm: 12, lg: 8 },
              fields: [
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.MVARating1",
                    defaultMessage: "MVA rating 1"
                  },
                  fieldKey: "VisualInspection.Classification.MVARating1",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  rules: [
                    {
                      type: "number"
                    }
                  ],
                  validationSchema: {
                    type: "number"
                  },
                  inputType: "Decimal"
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.MVARating2",
                    defaultMessage: "MVA rating 2"
                  },
                  fieldKey: "VisualInspection.Classification.MVARating2",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  rules: [
                    {
                      type: "number"
                    }
                  ],
                  validationSchema: {
                    type: "number"
                  },
                  inputType: "Decimal"
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.MVARating3",
                    defaultMessage: "MVA rating 3"
                  },
                  fieldKey: "VisualInspection.Classification.MVARating3",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  rules: [
                    {
                      type: "number"
                    }
                  ],
                  validationSchema: {
                    type: "number"
                  },
                  inputType: "Decimal"
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.HVVoltage",
                    defaultMessage: "HV voltage"
                  },
                  fieldKey: "VisualInspection.Classification.HVVoltage",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  rules: [
                    {
                      type: "number"
                    }
                  ],
                  validationSchema: {
                    type: "number"
                  },
                  inputType: "Decimal",
                  unit: "kV"
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.XVVoltage",
                    defaultMessage: "XV voltage"
                  },
                  fieldKey: "VisualInspection.Classification.XVVoltage",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  rules: [
                    {
                      type: "number"
                    }
                  ],
                  validationSchema: {
                    type: "number"
                  },
                  inputType: "Decimal",
                  unit: "kV"
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.YVVoltage",
                    defaultMessage: "YV voltage"
                  },
                  fieldKey: "VisualInspection.Classification.YVVoltage",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  rules: [
                    {
                      type: "number"
                    }
                  ],
                  validationSchema: {
                    type: "number"
                  },
                  inputType: "Decimal",
                  unit: "kV"
                }
              ]
            },
            {
              sectionList: [
                {
                  sectionName: {
                    defaultMessage: "Winding Configuration",
                    id: "configuration_tool.tab.visual_inspection.section.winding_configuration"
                  },
                  sectionType: "list",
                  fields: [
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.HVConfiguration",
                        defaultMessage: "HV Configuration"
                      },
                      fieldKey:
                        "VisualInspection.WindingConfiguration.HVConfiguration",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: windingConfiguration
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.XVConfiguration",
                        defaultMessage: "XV Configuration"
                      },
                      fieldKey:
                        "VisualInspection.WindingConfiguration.XVConfiguration",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: windingConfiguration
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.YVConfiguration",
                        defaultMessage: "YV Configuration"
                      },
                      fieldKey:
                        "VisualInspection.WindingConfiguration.YVConfiguration",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: windingConfiguration
                    }
                  ]
                },
                {
                  sectionName: {
                    defaultMessage: "Impedance",
                    id: "configuration_tool.tab.visual_inspection.section.impedance"
                  },
                  sectionType: "list",
                  fields: [
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.ImpedanceHtoX",
                        defaultMessage: "Impedance H to X"
                      },
                      fieldKey: "VisualInspection.Impedance.ImpedanceHtoX",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Decimal",
                      rules: [
                        {
                          type: "number"
                        }
                      ],
                      validationSchema: {
                        type: "number"
                      },
                      inputType: "Decimal",
                      unit: "%"
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.ImpedanceHtoY",
                        defaultMessage: "Impedance H to Y"
                      },
                      fieldKey: "VisualInspection.Impedance.ImpedanceHtoY",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Decimal",
                      rules: [
                        {
                          type: "number"
                        }
                      ],
                      validationSchema: {
                        type: "number"
                      },
                      inputType: "Decimal",
                      unit: "%"
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.ImpedanceXtoY",
                        defaultMessage: "Impedance X to Y"
                      },
                      fieldKey: "VisualInspection.Impedance.ImpedanceXtoY",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Decimal",
                      rules: [
                        {
                          type: "number"
                        }
                      ],
                      validationSchema: {
                        type: "number"
                      },
                      inputType: "Decimal",
                      unit: "%"
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.ImpedanceHtoXMVA",
                        defaultMessage: "Impedance H to X - MVA"
                      },
                      fieldKey: "VisualInspection.Impedance.ImpedanceHtoXMVA",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Decimal",
                      rules: [
                        {
                          type: "number"
                        }
                      ],
                      validationSchema: {
                        type: "number"
                      },
                      inputType: "Decimal",
                      unit: "%"
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.ImpedanceHtoYMVA",
                        defaultMessage: "Impedance H to Y - MVA"
                      },
                      fieldKey: "VisualInspection.Impedance.ImpedanceHtoYMVA",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Decimal",
                      rules: [
                        {
                          type: "number"
                        }
                      ],
                      validationSchema: {
                        type: "number"
                      },
                      inputType: "Decimal",
                      unit: "%"
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.ImpedanceXtoYMVA",
                        defaultMessage: "Impedance X to Y - MVA"
                      },
                      fieldKey: "VisualInspection.Impedance.ImpedanceXtoYMVA",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Decimal",
                      rules: [
                        {
                          type: "number"
                        }
                      ],
                      validationSchema: {
                        type: "number"
                      },
                      inputType: "Decimal",
                      unit: "%"
                    }
                  ]
                }
              ],
              span: { xs: 24, sm: 12, lg: 8 }
            }
          ]
        },
        {
          tabName: {
            defaultMessage: "Main Tank",
            id: "configuration_tool.tab.visual_inspection.sub_tab.main_tank"
          },
          sections: [
            {
              span: { xs: 24, sm: 12, lg: 8 },
              sectionList: [
                {
                  sectionName: {
                    defaultMessage: "Grounding (Optional)",
                    id: "configuration_tool.tab.visual_inspection.section.grounding_optional"
                  },
                  sectionType: "list",
                  fields: [
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.NeutralGroundingType",
                        defaultMessage: "Neutral Grounding Type"
                      },
                      fieldKey:
                        "VisualInspection.GroundingOptional.NeutralGroundingType",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: ["resistor", "reactor", "direct"]
                    }
                  ]
                },
                {
                  sectionName: {
                    defaultMessage: "Insulation (Optional)",
                    id: "configuration_tool.tab.visual_inspection.section.insulation_optional"
                  },
                  sectionType: "list",
                  fields: [
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.HVBIL",
                        defaultMessage: "HV BIL"
                      },
                      fieldKey: "VisualInspection.InsulationOptional.HVBIL",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Decimal",
                      rules: [
                        {
                          type: "number"
                        }
                      ],
                      validationSchema: {
                        type: "number"
                      },
                      inputType: "Decimal"
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.XVBIL",
                        defaultMessage: "XV BIL"
                      },
                      fieldKey: "VisualInspection.InsulationOptional.XVBIL",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Decimal",
                      rules: [
                        {
                          type: "number"
                        }
                      ],
                      validationSchema: {
                        type: "number"
                      },
                      inputType: "Decimal"
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.YVBIL",
                        defaultMessage: "YV BIL"
                      },
                      fieldKey: "VisualInspection.InsulationOptional.YVBIL",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Decimal",
                      rules: [
                        {
                          type: "number"
                        }
                      ],
                      validationSchema: {
                        type: "number"
                      },
                      inputType: "Decimal"
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.TemperatureRise",
                        defaultMessage: "Temperature Rise"
                      },
                      fieldKey:
                        "VisualInspection.InsulationOptional.TemperatureRise",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Decimal",
                      inputType: "list",
                      listValues: temperatureRise
                    }
                  ]
                }
              ]
            },
            {
              span: { xs: 24, sm: 12, lg: 8 },
              sectionList: [
                {
                  sectionName: {
                    defaultMessage: "Tank",
                    id: "configuration_tool.tab.visual_inspection.section.tank"
                  },
                  sectionType: "list",
                  fields: [
                    {
                      fieldName: {
                        id: "AbbTransformerStandardModelV6.parameter.Inspection.Tank.CorrosionOrPaintIssue",
                        defaultMessage: "Corrosion or Paint Issues"
                      },
                      fieldKey: "Inspection.Tank.CorrosionOrPaintIssue",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Bool",
                      inputType: "list",
                      listValues: boolValues
                    },
                    {
                      fieldName: {
                        id: "AbbTransformerStandardModelV6.parameter.Inspection.Tank.HotSpotIssue",
                        defaultMessage: "Thermal Scan Hot Spot Max Temp"
                      },
                      fieldKey: "Inspection.Tank.HotSpotIssue",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Bool",
                      inputType: "list",
                      listValues: boolValues
                    },
                    {
                      fieldName: {
                        id: "AbbTransformerStandardModelV6.parameter.Inspection.Tank.OilLeak",
                        defaultMessage: "Oil Leak"
                      },
                      fieldKey: "Inspection.Tank.OilLeak",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Bool",
                      inputType: "list",
                      listValues: boolValues
                    },
                    {
                      fieldName: {
                        id: "AbbTransformerStandardModelV6.parameter.Inspection.Tank.VibrationIssue",
                        defaultMessage: "Vibration Issue"
                      },
                      fieldKey: "Inspection.Tank.VibrationIssue",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Bool",
                      inputType: "list",
                      listValues: boolValues
                    },
                    {
                      fieldName: {
                        id: "AbbTransformerStandardModelV6.parameter.MTMP.TransformerInfo.HighRiskLocation",
                        defaultMessage: "High Risk Location"
                      },
                      fieldKey: "MTMP.TransformerInfo.HighRiskLocation",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Bool",
                      inputType: "list",
                      listValues: boolValues
                    }
                  ]
                },
                {
                  sectionName: {
                    defaultMessage: "Oil (Optional)",
                    id: "configuration_tool.tab.visual_inspection.section.oil_optional"
                  },
                  sectionType: "list",
                  fields: [
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.TypeOfOil",
                        defaultMessage: "Type Of Oil"
                      },
                      fieldKey: "VisualInspection.OilOptional.TypeOfOil",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: [
                        "mineral",
                        "silicone",
                        "natural ester",
                        "synthetic ester",
                        "freon",
                        "dry type"
                      ]
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.VolumeGallons",
                        defaultMessage: "Volume (Gallons)"
                      },
                      fieldKey: "VisualInspection.OilOptional.VolumeGallons",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Decimal",
                      rules: [
                        {
                          type: "number"
                        }
                      ],
                      validationSchema: {
                        type: "number"
                      },
                      inputType: "Decimal"
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.MainTankOilLevel",
                        defaultMessage: "Main Tank Oil Level"
                      },
                      fieldKey: "VisualInspection.OilOptional.MainTankOilLevel",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: oilLevel
                    }
                  ]
                }
              ]
            },
            {
              sectionName: {
                defaultMessage: "Weights (Optional)",
                id: "configuration_tool.tab.visual_inspection.section.weights_optional"
              },
              sectionType: "list",
              span: { xs: 24, sm: 12, lg: 8 },
              fields: [
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.CoreWeight",
                    defaultMessage: "Core Weight"
                  },
                  fieldKey: "VisualInspection.WeightsOptional.CoreWeight",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  rules: [
                    {
                      type: "number"
                    }
                  ],
                  validationSchema: {
                    type: "number"
                  },
                  inputType: "Decimal"
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.OilWeight",
                    defaultMessage: "Oil Weight"
                  },
                  fieldKey: "VisualInspection.WeightsOptional.OilWeight",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  rules: [
                    {
                      type: "number"
                    }
                  ],
                  validationSchema: {
                    type: "number"
                  },
                  inputType: "Decimal"
                },
                {
                  fieldName: {
                    id: "AbbTransformerStandardModelV6.parameter.ThermalProfile.TankWeight",
                    defaultMessage: "Tank Weight"
                  },
                  fieldKey: "VisualInspection.WeightsOptional.TankWeight",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  rules: [
                    {
                      type: "number"
                    }
                  ],
                  validationSchema: {
                    type: "number"
                  },
                  inputType: "Decimal"
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.TotalWeight",
                    defaultMessage: "Total Weight"
                  },
                  fieldKey: "VisualInspection.WeightsOptional.TotalWeight",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  rules: [
                    {
                      type: "number"
                    }
                  ],
                  validationSchema: {
                    type: "number"
                  },
                  inputType: "Decimal"
                }
              ]
            }
          ]
        },
        {
          tabName: {
            defaultMessage: "MT2",
            id: "configuration_tool.tab.visual_inspection.sub_tab.MT2"
          },
          sections: [
            {
              span: { xs: 24, sm: 12, lg: 8 },
              sectionList: [
                {
                  sectionName: {
                    defaultMessage: "Top Oil Temperature Device (Optional)",
                    id: "configuration_tool.tab.visual_inspection.section.top_oil_temperature_device_optional"
                  },
                  sectionType: "list",
                  fields: [
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.Temperature",
                        defaultMessage: "Temperature"
                      },
                      fieldKey:
                        "VisualInspection.TopOilTemperatureDevice.Temperature",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Decimal",
                      rules: [
                        {
                          type: "number"
                        }
                      ],
                      validationSchema: {
                        type: "number"
                      },
                      inputType: "Decimal"
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.MaxTemperature",
                        defaultMessage: "Max Temperature"
                      },
                      fieldKey:
                        "VisualInspection.TopOilTemperatureDevice.MaxTemperature",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Decimal",
                      rules: [
                        {
                          type: "number"
                        }
                      ],
                      validationSchema: {
                        type: "number"
                      },
                      inputType: "Decimal"
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.DeviceCondition",
                        defaultMessage: "Device Condition"
                      },
                      fieldKey:
                        "VisualInspection.TopOilInsulationOptional.DeviceCondition",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: deviceCondition
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.SwitchSetpoint1",
                        defaultMessage: "Switch Setpoint 1"
                      },
                      fieldKey:
                        "VisualInspection.TopOilTemperatureDevice.SwitchSetpoint1",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Decimal",
                      rules: [
                        {
                          type: "number"
                        }
                      ],
                      validationSchema: {
                        type: "number"
                      },
                      inputType: "Decimal"
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.SwitchSetpoint2",
                        defaultMessage: "Switch Setpoint 2"
                      },
                      fieldKey:
                        "VisualInspection.TopOilTemperatureDevice.SwitchSetpoint2",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Decimal",
                      rules: [
                        {
                          type: "number"
                        }
                      ],
                      validationSchema: {
                        type: "number"
                      },
                      inputType: "Decimal"
                    }
                  ]
                },
                {
                  sectionName: {
                    defaultMessage: "HV Winding Temperature Device (Optional)",
                    id: "configuration_tool.tab.visual_inspection.section.hv_winding_temperature_device_optional"
                  },
                  sectionType: "list",
                  fields: [
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.Temperature",
                        defaultMessage: "Temperature"
                      },
                      fieldKey:
                        "VisualInspection.HVWindingTemperatureDevice.Temperature",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Decimal",
                      rules: [
                        {
                          type: "number"
                        }
                      ],
                      validationSchema: {
                        type: "number"
                      },
                      inputType: "Decimal"
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.MaxTemperature",
                        defaultMessage: "Max Temperature"
                      },
                      fieldKey:
                        "VisualInspection.HVWindingTemperatureDevice.MaxTemperature",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Decimal",
                      rules: [
                        {
                          type: "number"
                        }
                      ],
                      validationSchema: {
                        type: "number"
                      },
                      inputType: "Decimal"
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.DeviceCondition",
                        defaultMessage: "Device Condition"
                      },
                      fieldKey:
                        "VisualInspection.HVWindingInsulationOptional.DeviceCondition",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: deviceCondition
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.SwitchSetpoint1",
                        defaultMessage: "Switch Setpoint 1"
                      },
                      fieldKey:
                        "VisualInspection.HVWindingTemperatureDevice.SwitchSetpoint1",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Decimal",
                      rules: [
                        {
                          type: "number"
                        }
                      ],
                      validationSchema: {
                        type: "number"
                      },
                      inputType: "Decimal"
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.SwitchSetpoint2",
                        defaultMessage: "Switch Setpoint 2"
                      },
                      fieldKey:
                        "VisualInspection.HVWindingTemperatureDevice.SwitchSetpoint2",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Decimal",
                      rules: [
                        {
                          type: "number"
                        }
                      ],
                      validationSchema: {
                        type: "number"
                      },
                      inputType: "Decimal"
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.SwitchSetpoint3",
                        defaultMessage: "Switch Setpoint 3"
                      },
                      fieldKey:
                        "VisualInspection.HVWindingTemperatureDevice.SwitchSetpoint3",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Decimal",
                      rules: [
                        {
                          type: "number"
                        }
                      ],
                      validationSchema: {
                        type: "number"
                      },
                      inputType: "Decimal"
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.SwitchSetpoint4",
                        defaultMessage: "Switch Setpoint 4"
                      },
                      fieldKey:
                        "VisualInspection.HVWindingTemperatureDevice.SwitchSetpoint4",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Decimal",
                      rules: [
                        {
                          type: "number"
                        }
                      ],
                      validationSchema: {
                        type: "number"
                      },
                      inputType: "Decimal"
                    }
                  ]
                }
              ]
            },
            {
              sectionName: {
                defaultMessage: "XV Winding Temperature Device (Optional)",
                id: "configuration_tool.tab.visual_inspection.section.xv_winding_temperature_device_optional"
              },
              sectionType: "list",
              span: { xs: 24, sm: 12, lg: 8 },
              fields: [
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.Temperature",
                    defaultMessage: "Temperature"
                  },
                  fieldKey:
                    "VisualInspection.XVWindingTemperatureDevice.Temperature",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  rules: [
                    {
                      type: "number"
                    }
                  ],
                  validationSchema: {
                    type: "number"
                  },
                  inputType: "Decimal"
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.MaxTemperature",
                    defaultMessage: "Max Temperature"
                  },
                  fieldKey:
                    "VisualInspection.XVWindingTemperatureDevice.MaxTemperature",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  rules: [
                    {
                      type: "number"
                    }
                  ],
                  validationSchema: {
                    type: "number"
                  },
                  inputType: "Decimal"
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.DeviceCondition",
                    defaultMessage: "Device Condition"
                  },
                  fieldKey:
                    "VisualInspection.XVWindingInsulationOptional.DeviceCondition",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: deviceCondition
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.SwitchSetpoint1",
                    defaultMessage: "Switch Setpoint 1"
                  },
                  fieldKey:
                    "VisualInspection.XVWindingTemperatureDevice.SwitchSetpoint1",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  rules: [
                    {
                      type: "number"
                    }
                  ],
                  validationSchema: {
                    type: "number"
                  },
                  inputType: "Decimal"
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.SwitchSetpoint2",
                    defaultMessage: "Switch Setpoint 2"
                  },
                  fieldKey:
                    "VisualInspection.XVWindingTemperatureDevice.SwitchSetpoint2",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  rules: [
                    {
                      type: "number"
                    }
                  ],
                  validationSchema: {
                    type: "number"
                  },
                  inputType: "Decimal"
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.SwitchSetpoint3",
                    defaultMessage: "Switch Setpoint 3"
                  },
                  fieldKey:
                    "VisualInspection.XVWindingTemperatureDevice.SwitchSetpoint3",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  rules: [
                    {
                      type: "number"
                    }
                  ],
                  validationSchema: {
                    type: "number"
                  },
                  inputType: "Decimal"
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.SwitchSetpoint4",
                    defaultMessage: "Switch Setpoint 4"
                  },
                  fieldKey:
                    "VisualInspection.XVWindingTemperatureDevice.SwitchSetpoint4",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  rules: [
                    {
                      type: "number"
                    }
                  ],
                  validationSchema: {
                    type: "number"
                  },
                  inputType: "Decimal"
                }
              ]
            },
            {
              sectionName: {
                defaultMessage: "YV Winding Temperature Device (Optional)",
                id: "configuration_tool.tab.visual_inspection.section.YV_winding_temperature_device_optional"
              },
              sectionType: "list",
              span: { xs: 24, sm: 12, lg: 8 },
              fields: [
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.Temperature",
                    defaultMessage: "Temperature"
                  },
                  fieldKey:
                    "VisualInspection.YVWindingTemperatureDevice.Temperature",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  rules: [
                    {
                      type: "number"
                    }
                  ],
                  validationSchema: {
                    type: "number"
                  },
                  inputType: "Decimal"
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.MaxTemperature",
                    defaultMessage: "Max Temperature"
                  },
                  fieldKey:
                    "VisualInspection.YVWindingTemperatureDevice.MaxTemperature",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  rules: [
                    {
                      type: "number"
                    }
                  ],
                  validationSchema: {
                    type: "number"
                  },
                  inputType: "Decimal"
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.DeviceCondition",
                    defaultMessage: "Device Condition"
                  },
                  fieldKey:
                    "VisualInspection.YVWindingInsulationOptional.DeviceCondition",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: deviceCondition
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.SwitchSetpoint1",
                    defaultMessage: "Switch Setpoint 1"
                  },
                  fieldKey:
                    "VisualInspection.YVWindingTemperatureDevice.SwitchSetpoint1",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  rules: [
                    {
                      type: "number"
                    }
                  ],
                  validationSchema: {
                    type: "number"
                  },
                  inputType: "Decimal"
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.SwitchSetpoint2",
                    defaultMessage: "Switch Setpoint 2"
                  },
                  fieldKey:
                    "VisualInspection.YVWindingTemperatureDevice.SwitchSetpoint2",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  rules: [
                    {
                      type: "number"
                    }
                  ],
                  validationSchema: {
                    type: "number"
                  },
                  inputType: "Decimal"
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.SwitchSetpoint3",
                    defaultMessage: "Switch Setpoint 3"
                  },
                  fieldKey:
                    "VisualInspection.YVWindingTemperatureDevice.SwitchSetpoint3",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  rules: [
                    {
                      type: "number"
                    }
                  ],
                  validationSchema: {
                    type: "number"
                  },
                  inputType: "Decimal"
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.SwitchSetpoint4",
                    defaultMessage: "Switch Setpoint 4"
                  },
                  fieldKey:
                    "VisualInspection.YVWindingTemperatureDevice.SwitchSetpoint4",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  rules: [
                    {
                      type: "number"
                    }
                  ],
                  validationSchema: {
                    type: "number"
                  },
                  inputType: "Decimal"
                }
              ]
            }
          ]
        },
        {
          tabName: {
            defaultMessage: "MT3",
            id: "configuration_tool.tab.visual_inspection.sub_tab.MT3"
          },
          sections: [
            {
              span: { xs: 24, sm: 12, lg: 8 },
              sectionList: [
                {
                  sectionName: {
                    defaultMessage: "Infrared (optional)",
                    id: "configuration_tool.tab.visual_inspection.section.infrared_optional"
                  },
                  sectionType: "list",
                  fields: [
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.InfraredReading",
                        defaultMessage: "Infrared Reading"
                      },
                      fieldKey: "VisualInspection.Infrared.InfraredReading",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: yesNo
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.HottestSpotC",
                        defaultMessage: "Hottest Spot C"
                      },
                      fieldKey: "VisualInspection.Infrared.HottestSpotC",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Decimal",
                      rules: [
                        {
                          type: "number"
                        }
                      ],
                      validationSchema: {
                        type: "number"
                      },
                      inputType: "Decimal"
                    }
                  ]
                },
                {
                  sectionName: {
                    defaultMessage: "Oil leaks (Optional)",
                    id: "configuration_tool.tab.visual_inspection.section.oil_leaks_optional"
                  },
                  sectionType: "list",
                  fields: [
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.Severity",
                        defaultMessage: "Severity"
                      },
                      fieldKey: "VisualInspection.OilLeaks.Severity",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: severity
                    }
                  ]
                }
              ]
            },
            {
              span: { xs: 24, sm: 12, lg: 8 },
              sectionList: [
                {
                  sectionName: {
                    defaultMessage: "Movement (Optional)",
                    id: "configuration_tool.tab.visual_inspection.section.movement_optional"
                  },
                  sectionType: "list",
                  fields: [
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.NoisePresent",
                        defaultMessage: "Noise Present"
                      },
                      fieldKey: "VisualInspection.Movement.NoisePresent",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: yesNo
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.VibrationPresent",
                        defaultMessage: "Vibration Present"
                      },
                      fieldKey: "VisualInspection.Movement.VibrationPresent",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: yesNo
                    }
                  ]
                },
                {
                  sectionName: {
                    defaultMessage: "Paint (Optional)",
                    id: "configuration_tool.tab.visual_inspection.section.paint_optional"
                  },
                  sectionType: "list",
                  fields: [
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.Finish",
                        defaultMessage: "Finish"
                      },
                      fieldKey: "VisualInspection.Paint.Finish",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: finish
                    }
                  ]
                }
              ]
            },
            {
              span: { xs: 24, sm: 12, lg: 8 },
              sectionList: [
                {
                  sectionName: {
                    defaultMessage: "Wiring (Optional)",
                    id: "configuration_tool.tab.visual_inspection.section.wiring_optional"
                  },
                  sectionType: "list",
                  fields: [
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.Condition",
                        defaultMessage: "Condition"
                      },
                      fieldKey: "VisualInspection.Paint.Condition",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: condition
                    }
                  ]
                },
                {
                  sectionName: {
                    defaultMessage: "Pests (Optional)",
                    id: "configuration_tool.tab.visual_inspection.section.pests_optional"
                  },
                  sectionType: "list",
                  fields: [
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.Present",
                        defaultMessage: "Present"
                      },
                      fieldKey: "VisualInspection.Paint.Present",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: yesNo
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          tabName: {
            defaultMessage: "Oil Preservation",
            id: "configuration_tool.tab.visual_inspection.sub_tab.oil_preservation"
          },
          sections: [
            {
              span: { xs: 24, sm: 12, lg: 8 },
              sectionList: [
                {
                  sectionName: {
                    defaultMessage: "OPS Issues",
                    id: "configuration_tool.tab.visual_inspection.section.ops_issues"
                  },
                  sectionType: "list",
                  fields: [
                    {
                      fieldName: {
                        id: "AbbTransformerStandardModelV6.parameter.Inspection.OilPreservationSystem.HotSpotIssue",
                        defaultMessage: "HotSpot Issue"
                      },
                      fieldKey: "Inspection.OilPreservationSystem.HotSpotIssue",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Bool",
                      inputType: "list",
                      listValues: boolValues
                    },
                    {
                      fieldName: {
                        id: "AbbTransformerStandardModelV6.parameter.Inspection.OilPreservationSystem.OtherIssue",
                        defaultMessage: "Other Issue"
                      },
                      fieldKey: "Inspection.OilPreservationSystem.OtherIssue",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Bool",
                      inputType: "list",
                      listValues: boolValues
                    },
                    {
                      fieldName: {
                        id: "AbbTransformerStandardModelV6.parameter.Inspection.OilPreservationSystem.TwoLowPressures",
                        defaultMessage: "Two Low Pressures"
                      },
                      fieldKey:
                        "Inspection.OilPreservationSystem.TwoLowPressures",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Bool",
                      inputType: "list",
                      listValues: boolValues
                    }
                  ]
                },
                {
                  sectionName: {
                    defaultMessage: "Pressures (Optional)",
                    id: "configuration_tool.tab.visual_inspection.section.pressures_optional"
                  },
                  sectionType: "list",
                  fields: [
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.N2Pressure",
                        defaultMessage: "N2 Pressure"
                      },
                      fieldKey: "VisualInspection.Pressures.N2Pressure",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Decimal",
                      rules: [
                        {
                          type: "number"
                        }
                      ],
                      validationSchema: {
                        type: "number"
                      },
                      inputType: "Decimal"
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.BottlePressure",
                        defaultMessage: "Bottle pressure"
                      },
                      fieldKey: "VisualInspection.Pressures.BottlePressure",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Decimal",
                      rules: [
                        {
                          type: "number"
                        }
                      ],
                      validationSchema: {
                        type: "number"
                      },
                      inputType: "Decimal"
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.GasSpacePressure",
                        defaultMessage: "Gas space Pressure"
                      },
                      fieldKey: "VisualInspection.Pressures.GasSpacePressure",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Decimal",
                      rules: [
                        {
                          type: "number"
                        }
                      ],
                      validationSchema: {
                        type: "number"
                      },
                      inputType: "Decimal"
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.N2PressureGuageCondition",
                        defaultMessage: "N2 Pressure Guage Condition"
                      },
                      fieldKey:
                        "VisualInspection.Pressures.N2PressureGuageCondition",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: oilPreservationPressure
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.BottlePressureGuageCondition",
                        defaultMessage: "Bottle Pressure Guage Condition"
                      },
                      fieldKey:
                        "VisualInspection.Pressures.BottlePressureGuageCondition",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: oilPreservationPressure
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.GasSpacePressureGuageCondition",
                        defaultMessage: "Gas Space Pressure Guage Condition"
                      },
                      fieldKey:
                        "VisualInspection.Pressures.GasSpacePressureGuageCondition",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: oilPreservationPressure
                    }
                  ]
                }
              ]
            },
            {
              span: { xs: 24, sm: 12, lg: 8 },
              sectionList: [
                {
                  sectionName: {
                    defaultMessage: "Conservator (Optional)",
                    id: "configuration_tool.tab.visual_inspection.section.conservator_optional"
                  },
                  sectionType: "list",
                  fields: [
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.NumberOfTanks",
                        defaultMessage: "Number of tanks"
                      },
                      fieldKey: "VisualInspection.Conservator.NumberOfTanks",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: numberOfTanks
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.OilLevelTank1",
                        defaultMessage: "Oil Level Tank 1"
                      },
                      fieldKey: "VisualInspection.Conservator.OilLevelTank1",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: oilLevel
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.GuageCondition1",
                        defaultMessage: "Guage Condition 1"
                      },
                      fieldKey: "VisualInspection.Conservator.GuageCondition1",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: oilPreservationPressure
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.OilLevelTank2",
                        defaultMessage: "Oil Level Tank 2"
                      },
                      fieldKey: "VisualInspection.Conservator.OilLevelTank2",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: oilLevel
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.GuageCondition2",
                        defaultMessage: "Guage Condition 2"
                      },
                      fieldKey: "VisualInspection.Conservator.GuageCondition2",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: oilPreservationPressure
                    }
                  ]
                },
                {
                  sectionName: {
                    defaultMessage: "Membrane (Optional)",
                    id: "configuration_tool.tab.visual_inspection.section.membrane_optional"
                  },
                  sectionType: "list",
                  fields: [
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.LeaksPresentTank1",
                        defaultMessage: "Leaks Present (Tank 1)"
                      },
                      fieldKey: "VisualInspection.Membrane.LeaksPresentTank1",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: severity
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.MembraneAgeTank1",
                        defaultMessage: "Membrane Age (Tank 1)"
                      },
                      fieldKey: "VisualInspection.Membrane.MembraneAgeTank1",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Decimal",
                      rules: [
                        {
                          type: "number"
                        }
                      ],
                      validationSchema: {
                        type: "number"
                      },
                      inputType: "Decimal"
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.LeaksPresentTank2",
                        defaultMessage: "Leaks Present (Tank 2)"
                      },
                      fieldKey: "VisualInspection.Membrane.LeaksPresentTank2",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: membraneLeaksPresent
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.MembraneAgeTank2",
                        defaultMessage: "Membrane Age (Tank 2)"
                      },
                      fieldKey: "VisualInspection.Membrane.MembraneAgeTank2",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Decimal",
                      rules: [
                        {
                          type: "number"
                        }
                      ],
                      validationSchema: {
                        type: "number"
                      },
                      inputType: "Decimal"
                    }
                  ]
                }
              ]
            },
            {
              span: { xs: 24, sm: 12, lg: 8 },
              sectionList: [
                {
                  sectionName: {
                    defaultMessage: "Desiccant (Optional)",
                    id: "configuration_tool.tab.visual_inspection.section.desiccant_optional"
                  },
                  sectionType: "list",
                  fields: [
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.TypeTank1",
                        defaultMessage: "Type (Tank 1)"
                      },
                      fieldKey: "VisualInspection.Desiccant.TypeTank1",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: desiccantType
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.Condition1",
                        defaultMessage: "If yes, condition 1"
                      },
                      fieldKey: "VisualInspection.Desiccant.Condition1",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: desiccantCondition
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.TypeTank2",
                        defaultMessage: "Type (Tank 2)"
                      },
                      fieldKey: "VisualInspection.Desiccant.TypeTank2",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: desiccantType
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.Condition2",
                        defaultMessage: "If yes, condition 2"
                      },
                      fieldKey: "VisualInspection.Desiccant.Condition2",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: desiccantCondition
                    }
                  ]
                },
                {
                  sectionName: {
                    defaultMessage: "Infrared (Optional)",
                    id: "configuration_tool.tab.visual_inspection.section.infrared_optional"
                  },
                  sectionType: "list",
                  fields: [
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.InfraredReadingTank1",
                        defaultMessage: "Infrared Reading (Tank1)"
                      },
                      fieldKey:
                        "VisualInspection.Infrared.InfraredReadingTank1",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: yesNo
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.HottestSpotC1",
                        defaultMessage: "Hottest Spot C"
                      },
                      fieldKey: "VisualInspection.Infrared.HottestSpotC1",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Decimal",
                      rules: [
                        {
                          type: "number"
                        }
                      ],
                      validationSchema: {
                        type: "number"
                      },
                      inputType: "Decimal"
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.InfraredReadingTank2",
                        defaultMessage: "Infrared Reading (Tank2)"
                      },
                      fieldKey:
                        "VisualInspection.Infrared.InfraredReadingTank2",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: yesNo
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.HottestSpotC2",
                        defaultMessage: "Hottest Spot C"
                      },
                      fieldKey: "VisualInspection.Infrared.HottestSpotC2",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Decimal",
                      rules: [
                        {
                          type: "number"
                        }
                      ],
                      validationSchema: {
                        type: "number"
                      },
                      inputType: "Decimal"
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          tabName: {
            defaultMessage: "Cooling",
            id: "configuration_tool.tab.visual_inspection.sub_tab.cooling"
          },
          sections: [
            {
              span: { xs: 24, sm: 12, lg: 8 },
              sectionList: [
                {
                  sectionName: {
                    defaultMessage: "Cooling Condition",
                    id: "configuration_tool.tab.visual_inspection.section.cooling_condition"
                  },
                  sectionType: "list",
                  fields: [
                    {
                      fieldName: {
                        id: "AbbTransformerStandardModelV6.parameter.TestData.CoolingCondition",
                        defaultMessage: "Cooling Condition"
                      },
                      fieldKey: "TestData.CoolingCondition",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: coolingCondition
                    }
                  ]
                },
                {
                  sectionName: {
                    defaultMessage: "Classification (Optional)",
                    id: "configuration_tool.tab.visual_inspection.section.classification_optional"
                  },
                  sectionType: "list",
                  fields: [
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.Type",
                        defaultMessage: "Type"
                      },
                      fieldKey: "VisualInspection.Classification.Type",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: coolingClassificationType
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.AutoSwitching",
                        defaultMessage: "Auto-Switching"
                      },
                      fieldKey: "VisualInspection.Classification.AutoSwitching",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: yesNo
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.Configuration",
                        defaultMessage: "Configuration"
                      },
                      fieldKey: "VisualInspection.Classification.Configuration",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: coolingClassificationConfiguration
                    }
                  ]
                },
                {
                  sectionName: {
                    defaultMessage: "Controls (Optional)",
                    id: "configuration_tool.tab.visual_inspection.section.controls_optional"
                  },
                  sectionType: "list",
                  fields: [
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.ManualOperationFanGroupsOk",
                        defaultMessage: "Manual Operation Fan Groups Ok"
                      },
                      fieldKey:
                        "VisualInspection.Controls.ManualOperationFanGroupsOk",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: yesNo
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.ManualOperationFansOk",
                        defaultMessage: "Manual Operation Fans Ok"
                      },
                      fieldKey:
                        "VisualInspection.Controls.ManualOperationFansOk",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: yesNo
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.ManualOperationPumpsOk",
                        defaultMessage: "Manual Operation Pumps Ok"
                      },
                      fieldKey:
                        "VisualInspection.Controls.ManualOperationPumpsOk",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: yesNo
                    }
                  ]
                }
              ]
            },
            {
              sectionName: {
                defaultMessage: "Coolers (Optional)",
                id: "configuration_tool.tab.visual_inspection.section.coolers_optional"
              },
              sectionType: "list",
              span: { xs: 24, sm: 12, lg: 8 },
              fields: [
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.Debris",
                    defaultMessage: "Debris"
                  },
                  fieldKey: "VisualInspection.Coolers.Debris",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: debris
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.Corrosion",
                    defaultMessage: "Corrosion"
                  },
                  fieldKey: "VisualInspection.Coolers.Corrosion",
                  fieldType: FieldTypes.Parameter,

                  dataType: "String",
                  inputType: "list",
                  listValues: debris
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.FinCondition",
                    defaultMessage: "Fin Condition"
                  },
                  fieldKey: "VisualInspection.Coolers.FinCondition",
                  fieldType: FieldTypes.Parameter,

                  dataType: "String",
                  inputType: "list",
                  listValues: finCondition
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.Leaks",
                    defaultMessage: "Leaks"
                  },
                  fieldKey: "VisualInspection.Coolers.Leaks",
                  fieldType: FieldTypes.Parameter,

                  dataType: "String",
                  inputType: "list",
                  listValues: severity
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.PercentFansOperable",
                    defaultMessage: "Percent Fans Operable"
                  },
                  fieldKey: "VisualInspection.Coolers.PercentFansOperable",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  rules: [
                    {
                      type: "number",
                      min: 0,
                      max: 100
                    }
                  ],
                  validationSchema: {
                    type: "number",
                    minimum: 0,
                    maximum: 100
                  },
                  inputType: "Decimal"
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.GuageCondition",
                    defaultMessage: "Guage Condition"
                  },
                  fieldKey: "VisualInspection.Coolers.GuageCondition",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: deviceCondition
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.InfraredReading",
                    defaultMessage: "Infrared Reading"
                  },
                  fieldKey: "VisualInspection.Coolers.InfraredReading",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: yesNo
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.HottestSpot",
                    defaultMessage: "Hottest Spot"
                  },
                  fieldKey: "VisualInspection.Coolers.HottestSpot",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  rules: [
                    {
                      type: "number"
                    }
                  ],
                  validationSchema: {
                    type: "number"
                  },
                  inputType: "Decimal"
                }
              ]
            },
            {
              span: { xs: 24, sm: 12, lg: 8 },
              sectionList: [
                {
                  sectionName: {
                    defaultMessage: "Radiator (Optional)",
                    id: "configuration_tool.tab.visual_inspection.section.radiator_optional"
                  },
                  sectionType: "list",
                  fields: [
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.Debris",
                        defaultMessage: "Debris"
                      },
                      fieldKey: "VisualInspection.Radiator.Debris",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: yesNo
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.Corrosion",
                        defaultMessage: "Corrosion"
                      },
                      fieldKey: "VisualInspection.Radiator.Corrosion",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: debris
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.OilLeak",
                        defaultMessage: "Oil Leak"
                      },
                      fieldKey: "VisualInspection.Radiator.OilLeak",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: severity
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.Rust",
                        defaultMessage: "Rust"
                      },
                      fieldKey: "VisualInspection.Radiator.Rust",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: debris
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.WeldCondition",
                        defaultMessage: "Weld Condition"
                      },
                      fieldKey: "VisualInspection.Radiator.WeldCondition",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: finCondition
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.InfraredReading",
                        defaultMessage: "Infrared Reading"
                      },
                      fieldKey: "VisualInspection.Radiator.InfraredReading",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: yesNo
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.HottestSpotC",
                        defaultMessage: "Hottest Spot C"
                      },
                      fieldKey: "VisualInspection.Radiator.HottestSpotC",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Decimal",
                      rules: [
                        {
                          type: "number"
                        }
                      ],
                      validationSchema: {
                        type: "number"
                      },
                      inputType: "Decimal"
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.ConditionOfValve",
                        defaultMessage: "Condition Of Valve"
                      },
                      fieldKey: "VisualInspection.Radiator.ConditionOfValve",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: conditionOfValve
                    }
                  ]
                },
                {
                  sectionName: {
                    defaultMessage: "Radiator Fans (Optional)",
                    id: "configuration_tool.tab.visual_inspection.section.radiator_fans_optional"
                  },
                  sectionType: "list",
                  fields: [
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.Manufacturer",
                        defaultMessage: "Manufacturer"
                      },
                      fieldKey: "VisualInspection.RadiatorFans.Manufacturer",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: radiatorFansManufacturer
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.ConditionOfFans",
                        defaultMessage: "Condition Of Fans"
                      },
                      fieldKey: "VisualInspection.RadiatorFans.ConditionOfFans",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: finCondition
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.ConditionOfGuards",
                        defaultMessage: "Condition Of Guards"
                      },
                      fieldKey:
                        "VisualInspection.RadiatorFans.ConditionOfGuards",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: finCondition
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.OperableTest",
                        defaultMessage: "Operable Test"
                      },
                      fieldKey: "VisualInspection.RadiatorFans.OperableTest",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: yesNo
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.PercentOperable",
                        defaultMessage: "Percent Operable"
                      },
                      fieldKey: "VisualInspection.RadiatorFans.PercentOperable",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Decimal",
                      rules: [
                        {
                          type: "number",
                          min: 0,
                          max: 100
                        }
                      ],
                      validationSchema: {
                        type: "number",
                        minimum: 0,
                        maximum: 100
                      },
                      inputType: "Decimal"
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.NoiseVibration",
                        defaultMessage: "Noise Vibration"
                      },
                      fieldKey: "VisualInspection.RadiatorFans.NoiseVibration",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: yesNo
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          tabName: {
            defaultMessage: "C2",
            id: "configuration_tool.tab.visual_inspection.sub_tab.C2"
          },
          sections: [
            {
              sectionName: {
                defaultMessage: "Pumps (Optional)",
                id: "configuration_tool.tab.visual_inspection.section.pumps_optional"
              },
              sectionType: "list",
              span: { xs: 24, sm: 12, lg: 8 },
              fields: [
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.Manufacturer",
                    defaultMessage: "Manufacturer"
                  },
                  fieldKey: "VisualInspection.Pumps.Manufacturer",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: pumpsManufacturer
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.DateOfLastService",
                    defaultMessage: "Date Of Last Service"
                  },
                  fieldKey: "Pumps.DateOfLastService",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "DateTime",
                  disableFutureDates: true,
                  validationSchema: {
                    type: "string",
                    format: "date"
                  }
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.Age",
                    defaultMessage: "Age"
                  },
                  fieldKey: "VisualInspection.Pumps.Age",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: pumpsAge
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.Bearings",
                    defaultMessage: "Bearings"
                  },
                  fieldKey: "VisualInspection.Pumps.Bearings",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: bearings
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.NoiseVibration",
                    defaultMessage: "Noise/Vibration"
                  },
                  fieldKey: "VisualInspection.Pumps.NoiseVibration",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: yesNo
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.FlowGuageFlutter",
                    defaultMessage: "Flow Guage Flutter"
                  },
                  fieldKey: "VisualInspection.Pumps.FlowGuageFlutter",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: yesNo
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.GuageCondition",
                    defaultMessage: "Guage Condition"
                  },
                  fieldKey: "VisualInspection.Pumps.GuageCondition",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: deviceCondition
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.OilLeak",
                    defaultMessage: "Oil Leak"
                  },
                  fieldKey: "VisualInspection.Pumps.OilLeak",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: severity
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.Condition",
                    defaultMessage: "Condition"
                  },
                  fieldKey: "VisualInspection.Pumps.Condition",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: finCondition
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.OperableTest",
                    defaultMessage: "Operable Test"
                  },
                  fieldKey: "VisualInspection.Pumps.OperableTest",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: yesNo
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.PercentOperable",
                    defaultMessage: "Percent Operable"
                  },
                  fieldKey: "VisualInspection.Pumps.PercentOperable",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  rules: [
                    {
                      type: "number",
                      min: 0,
                      max: 100
                    }
                  ],
                  validationSchema: {
                    type: "number",
                    minimum: 0,
                    maximum: 100
                  },
                  inputType: "Decimal"
                }
              ]
            }
          ]
        },
        {
          tabName: {
            defaultMessage: "Accessories",
            id: "configuration_tool.tab.visual_inspection.sub_tab.Accessories"
          },
          sections: [
            {
              span: { xs: 24, sm: 12, lg: 8 },
              sectionList: [
                {
                  sectionName: {
                    defaultMessage: "Age Issue",
                    id: "configuration_tool.tab.visual_inspection.section.age_issue"
                  },
                  sectionType: "list",
                  fields: [
                    {
                      fieldName: {
                        id: "AbbTransformerStandardModelV6.parameter.HistoryData.AccessoryAgeIssue",
                        defaultMessage: "Accessory Age Issue"
                      },
                      fieldKey: "HistoryData.AccessoryAgeIssue",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Bool",
                      inputType: "list",
                      listValues: boolValues
                    }
                  ]
                },
                {
                  sectionName: {
                    defaultMessage: "Ambient Temperature Device (Optional)",
                    id: "configuration_tool.tab.visual_inspection.section.ambient_temperature_device_optional"
                  },
                  sectionType: "list",
                  fields: [
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.DeviceType",
                        defaultMessage: "Device Type"
                      },
                      fieldKey:
                        "VisualInspection.AmbientTemperatureDevice.DeviceType",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: deviceType
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.Temperature",
                        defaultMessage: "Temperature"
                      },
                      fieldKey:
                        "VisualInspection.AmbientTemperatureDevice.Temperature",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Decimal",
                      rules: [
                        {
                          type: "number"
                        }
                      ],
                      validationSchema: {
                        type: "number"
                      },
                      inputType: "Decimal"
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.MaxTemperature",
                        defaultMessage: "Max Temperature"
                      },
                      fieldKey:
                        "VisualInspection.AmbientTemperatureDevice.MaxTemperature",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Decimal",
                      rules: [
                        {
                          type: "number"
                        }
                      ],
                      validationSchema: {
                        type: "number"
                      },
                      inputType: "Decimal"
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.DeviceCondition",
                        defaultMessage: "Device Condition"
                      },
                      fieldKey:
                        "VisualInspection.AmbientTemperatureDevice.DeviceCondition",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: deviceCondition
                    }
                  ]
                },
                {
                  sectionName: {
                    defaultMessage: "Protection (Optional)",
                    id: "configuration_tool.tab.visual_inspection.section.protection_optional"
                  },
                  sectionType: "list",
                  fields: [
                    {
                      fieldName: {
                        id: "AbbTransformerStandardModelV6.parameter.Inspection.Protection.GasAccumulationRelayCombustibles",
                        defaultMessage: "Gas Accumulation Relay Combustibles"
                      },
                      fieldKey:
                        "Inspection.Protection.GasAccumulationRelayCombustibles",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Bool",
                      inputType: "list",
                      listValues: boolValues
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.GasAccumulationRelayTripped",
                        defaultMessage: "Gas Accumulation Relay Tripped"
                      },
                      fieldKey:
                        "Inspection.Protection.GasAccumulationRelayTripped",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Bool",
                      inputType: "list",
                      listValues: boolValues
                    },
                    {
                      fieldName: {
                        id: "AbbTransformerStandardModelV6.parameter.Inspection.Protection.MechanicalReliefDeviceLowOrSlow",
                        defaultMessage: "Mechanical Relief Device Low Or Slow"
                      },
                      fieldKey:
                        "Inspection.Protection.MechanicalReliefDeviceLowOrSlow",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Bool",
                      inputType: "list",
                      listValues: boolValues
                    },
                    {
                      fieldName: {
                        id: "AbbTransformerStandardModelV6.parameter.Inspection.Protection.MechanicalReliefDeviceTripped",
                        defaultMessage: "Mechanical Relief Device Tripped"
                      },
                      fieldKey:
                        "Inspection.Protection.MechanicalReliefDeviceTripped",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Bool",
                      inputType: "list",
                      listValues: boolValues
                    },
                    {
                      fieldName: {
                        id: "AbbTransformerStandardModelV6.parameter.Inspection.Protection.OilSurgePressureDeviceTripped",
                        defaultMessage: "Oil Surge Pressure Device Tripped"
                      },
                      fieldKey:
                        "Inspection.Protection.OilSurgePressureDeviceTripped",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Bool",
                      inputType: "list",
                      listValues: boolValues
                    },
                    {
                      fieldName: {
                        id: "AbbTransformerStandardModelV6.parameter.Inspection.Protection.OtherIssue",
                        defaultMessage: "Protection Other Issue"
                      },
                      fieldKey: "Inspection.Protection.OtherIssue",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Bool",
                      inputType: "list",
                      listValues: boolValues
                    },
                    {
                      fieldName: {
                        id: "AbbTransformerStandardModelV6.parameter.Inspection.Protection.TopOilRelayTripped",
                        defaultMessage: "Top Oil Relay Tripped"
                      },
                      fieldKey: "Inspection.Protection.TopOilRelayTripped",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Bool",
                      inputType: "list",
                      listValues: boolValues
                    },
                    {
                      fieldName: {
                        id: "AbbTransformerStandardModelV6.parameter.Inspection.Protection.WindingTempRelayTripped",
                        defaultMessage: "Winding Temp Relay Tripped"
                      },
                      fieldKey: "Inspection.Protection.WindingTempRelayTripped",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Bool",
                      inputType: "list",
                      listValues: boolValues
                    }
                  ]
                }
              ]
            },
            {
              sectionName: {
                defaultMessage: "Control Cabinet (Optional)",
                id: "configuration_tool.tab.visual_inspection.section.control_cabinet_optional"
              },
              sectionType: "list",
              span: { xs: 24, sm: 12, lg: 8 },
              fields: [
                {
                  fieldName: {
                    id: "AbbTransformerStandardModelV6.parameter.Inspection.Cabinet.GreenSlime",
                    defaultMessage: "Green Slime"
                  },
                  fieldKey: "Inspection.Cabinet.GreenSlime",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Bool",
                  inputType: "list",
                  listValues: boolValues
                },
                {
                  fieldName: {
                    id: "AbbTransformerStandardModelV6.parameter.Inspection.Cabinet.OilLeak",
                    defaultMessage: "Oil Leak"
                  },
                  fieldKey: "Inspection.Cabinet.OilLeak",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Bool",
                  inputType: "list",
                  listValues: boolValues
                },
                {
                  fieldName: {
                    id: "AbbTransformerStandardModelV6.parameter.Inspection.Cabinet.OtherIssue",
                    defaultMessage: "Other Issue"
                  },
                  fieldKey: "Inspection.Cabinet.OtherIssue",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Bool",
                  inputType: "list",
                  listValues: boolValues
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.ACControlVoltage",
                    defaultMessage: "AC control voltage"
                  },
                  fieldKey: "VisualInspection.ControlCabinet.ACControlVoltage",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  rules: [
                    {
                      type: "number"
                    }
                  ],
                  validationSchema: {
                    type: "number"
                  },
                  inputType: "Decimal"
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.DCControlVoltage",
                    defaultMessage: "DC control voltage"
                  },
                  fieldKey: "VisualInspection.ControlCabinet.DCControlVoltage",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  rules: [
                    {
                      type: "number"
                    }
                  ],
                  validationSchema: {
                    type: "number"
                  },
                  inputType: "Decimal"
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.OilMoistureLeak",
                    defaultMessage: "Oil/Moisture Leak"
                  },
                  fieldKey: "VisualInspection.ControlCabinet.OilMoistureLeak",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: yesNo
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.CabinetHeaters",
                    defaultMessage: "Cabinet Heaters"
                  },
                  fieldKey: "VisualInspection.ControlCabinet.CabinetHeaters",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: yesNo
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.ComponentWiringCondition",
                    defaultMessage: "Component/Wiring Condition"
                  },
                  fieldKey:
                    "VisualInspection.ControlCabinet.ComponentWiringCondition",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: componentWiringCondition
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.ExcessiveHeating",
                    defaultMessage: "Excessive Heating"
                  },
                  fieldKey: "VisualInspection.ControlCabinet.ExcessiveHeating",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: yesNo
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.InfraredReading",
                    defaultMessage: "Infrared Reading"
                  },
                  fieldKey: "VisualInspection.ControlCabinet.InfraredReading",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: yesNo
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.HottestSpotC",
                    defaultMessage: "Hottest Spot C"
                  },
                  fieldKey: "VisualInspection.ControlCabinet.HottestSpotC",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  rules: [
                    {
                      type: "number"
                    }
                  ],
                  validationSchema: {
                    type: "number"
                  },
                  inputType: "Decimal"
                }
              ]
            },
            {
              sectionName: {
                defaultMessage: "Surge Arresters (Optional)",
                id: "configuration_tool.tab.visual_inspection.section.surge_arresters_optional"
              },
              sectionType: "list",
              span: { xs: 24, sm: 12, lg: 8 },
              fields: [
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.AllCountersOkay",
                    defaultMessage: "All Counters Okay"
                  },
                  fieldKey: "Inspection.Cabinet.AllCountersOkay",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: yesNo
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.Type",
                    defaultMessage: "Type"
                  },
                  fieldKey: "Inspection.Cabinet.Type",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: surgeArrestersType
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.OperationCount",
                    defaultMessage: "Operation Count"
                  },
                  fieldKey: "VisualInspection.SurgeArresters.OperationCount",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  rules: [
                    {
                      type: "number"
                    }
                  ],
                  validationSchema: {
                    type: "number"
                  },
                  inputType: "Decimal"
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.CounterCondition",
                    defaultMessage: "Counter Condition"
                  },
                  fieldKey: "VisualInspection.SurgeArresters.CounterCondition",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  rules: [
                    {
                      type: "number"
                    }
                  ],
                  validationSchema: {
                    type: "number"
                  },
                  inputType: "Decimal"
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.InfraredReading",
                    defaultMessage: "InfraredReading"
                  },
                  fieldKey: "Inspection.SurgeArresters.InfraredReading",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: surgeArrestersType
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.HottestSpotC",
                    defaultMessage: "Hottest Spot C"
                  },
                  fieldKey: "VisualInspection.SurgeArresters.HottestSpotC",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Decimal",
                  rules: [
                    {
                      type: "number"
                    }
                  ],
                  validationSchema: {
                    type: "number"
                  },
                  inputType: "Decimal"
                }
              ]
            }
          ]
        },
        {
          tabName: {
            defaultMessage: "Tap Changer",
            id: "configuration_tool.tab.visual_inspection.sub_tab.TapChanger"
          },
          sections: [
            {
              span: { xs: 24, sm: 12, lg: 8 },
              sectionList: [
                {
                  sectionName: {
                    defaultMessage: "Tap Changer (Optional)",
                    id: "configuration_tool.tab.visual_inspection.section.tap_changer_optional"
                  },
                  sectionType: "list",
                  fields: [
                    {
                      fieldName: {
                        id: "AbbTransformerStandardModelV6.parameter.Inspection.TapChangers.DetcIssue",
                        defaultMessage: "DETC Issue"
                      },
                      fieldKey: "Inspection.TapChangers.DetcIssue",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Bool",
                      inputType: "list",
                      listValues: boolValues
                    },
                    {
                      fieldName: {
                        id: "AbbTransformerStandardModelV6.parameter.Inspection.TapChangers.LtcContactWearIssue",
                        defaultMessage: "LTC Contact Wear Issue"
                      },
                      fieldKey: "Inspection.TapChangers.LtcContactWearIssue",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Bool",
                      inputType: "list",
                      listValues: boolValues
                    },
                    {
                      fieldName: {
                        id: "AbbTransformerStandardModelV6.parameter.Inspection.TapChangers.LtcHotSpotIssue",
                        defaultMessage: "Ltc Hot Spot Issue"
                      },
                      fieldKey: "Inspection.TapChangers.LtcHotSpotIssue",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Bool",
                      inputType: "list",
                      listValues: boolValues
                    },
                    {
                      fieldName: {
                        id: "AbbTransformerStandardModelV6.parameter.Inspection.TapChangers.LtcMechanicalIssue",
                        defaultMessage: "LTC Mechanical Issue"
                      },
                      fieldKey: "Inspection.TapChangers.LtcMechanicalIssue",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Bool",
                      inputType: "list",
                      listValues: boolValues
                    }
                  ]
                },
                {
                  sectionName: {
                    defaultMessage: "Tap Changers OLTC",
                    id: "configuration_tool.tab.visual_inspection.section.tap_changers_oltc"
                  },
                  sectionType: "list",
                  fields: [
                    {
                      fieldName: {
                        id: "AbbTransformerStandardModelV6.parameter.LTC.NextMaintenanceDate",
                        defaultMessage: "Next LTC Maintenance Date"
                      },
                      fieldKey: "LTC.NextMaintenanceDate",
                      fieldType: FieldTypes.Parameter,
                      dataType: "DateTime",
                      inputType: "DateTime",
                      validationSchema: {
                        type: "string",
                        format: "date"
                      }
                    },
                    {
                      fieldName: {
                        id: "AbbTransformerStandardModelV6.parameter.LTC.TapPosition",
                        defaultMessage: "LTC Tap position"
                      },
                      fieldKey: "LTC.TapPosition",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Decimal",
                      rules: [
                        {
                          type: "number",
                          min: -35,
                          max: 35
                        },
                        {
                          type: "integer"
                        }
                      ],
                      validationSchema: {
                        type: "integer",
                        minimum: -35,
                        maximum: 35
                      },
                      inputType: "Decimal"
                    },
                    {
                      fieldName: {
                        id: "AbbTransformerStandardModelV6.parameter.LTC.MaxAllowedOperations",
                        defaultMessage: "LTC Max Allowed Operations"
                      },
                      fieldKey: "LTC.MaxAllowedOperations",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Decimal",
                      rules: [
                        {
                          type: "number"
                        }
                      ],
                      validationSchema: {
                        type: "number"
                      },
                      inputType: "Decimal"
                    },
                    {
                      fieldName: {
                        id: "AbbTransformerStandardModelV6.parameter.LTC.Operations",
                        defaultMessage: "LTC Current total Operation Count"
                      },
                      fieldKey: "LTC.Operations",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Decimal",
                      rules: [
                        {
                          type: "number"
                        }
                      ],
                      validationSchema: {
                        type: "number"
                      },
                      inputType: "Decimal"
                    },
                    {
                      fieldName: {
                        id: "AbbTransformerStandardModelV6.parameter.LTC.CommunicatingTank",
                        defaultMessage: "Communicating Tank"
                      },
                      fieldKey: "LTC.CommunicatingTank",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Bool",
                      inputType: "list",
                      listValues: boolValues
                    },
                    {
                      fieldName: {
                        id: "AbbTransformerStandardModelV6.parameter.MTMP.InputData.TapChangerData.LtcArcingLocation",
                        defaultMessage: "LTC Arching Location"
                      },
                      fieldKey:
                        "MTMP.InputData.TapChangerData.LtcArcingLocation",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: ltcArchingLocation
                    }
                  ]
                }
              ]
            },
            {
              span: { xs: 24, sm: 12, lg: 8 },
              sectionList: [
                {
                  sectionName: {
                    defaultMessage: "Tap Changers DETC",
                    id: "configuration_tool.tab.visual_inspection.section.tap_changers_detc"
                  },
                  sectionType: "list",
                  fields: [
                    {
                      fieldName: {
                        id: "AbbTransformerStandardModelV6.parameter.MTMP.TransformerInfo.DetcRegularlyOperated",
                        defaultMessage: "DETC Operated Regularly"
                      },
                      fieldKey: "MTMP.TransformerInfo.DetcRegularlyOperated",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Bool",
                      inputType: "list",
                      listValues: boolValues
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.LinksPosition",
                        defaultMessage: "Links - position"
                      },
                      fieldKey:
                        "VisualInspection.TapChangersDETC.LinksPosition",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Decimal",
                      rules: [
                        {
                          type: "number"
                        }
                      ],
                      validationSchema: {
                        type: "number"
                      },
                      inputType: "Decimal"
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.DETCLastOperationDate",
                        defaultMessage: "DETC - last operation date"
                      },
                      fieldKey:
                        "VisualInspection.TapChangersDETC.DETCLastOperationDate",
                      fieldType: FieldTypes.Parameter,
                      dataType: "DateTime",
                      inputType: "DateTime",
                      disableFutureDates: true,
                      validationSchema: {
                        type: "string",
                        format: "date"
                      }
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.DETCPosition",
                        defaultMessage: "DETC - position"
                      },
                      fieldKey: "VisualInspection.TapChangersDETC.DETCPosition",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Decimal",
                      rules: [
                        {
                          type: "number"
                        }
                      ],
                      validationSchema: {
                        type: "number"
                      },
                      inputType: "Decimal"
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.DETCDamage",
                        defaultMessage: "DETC - damage"
                      },
                      fieldKey: "VisualInspection.TapChangersDETC.DETCDamage",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: yesNo
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.Type",
                        defaultMessage: "Type"
                      },
                      fieldKey: "VisualInspection.TapChangersDETC.Type",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: tapChangersDETCType
                    }
                  ]
                },
                {
                  sectionName: {
                    defaultMessage: "Tap Changers OLTC (Optional)",
                    id: "configuration_tool.tab.visual_inspection.section.tap_changers_oltc_optional"
                  },
                  sectionType: "list",
                  fields: [
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.InstallationDate",
                        defaultMessage: "Installation Date"
                      },
                      fieldKey:
                        "VisualInspection.TapChangersOLTC.InstallationDate",
                      fieldType: FieldTypes.Parameter,
                      dataType: "DateTime",
                      inputType: "DateTime",
                      disableFutureDates: true,
                      validationSchema: {
                        type: "string",
                        format: "date"
                      }
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.RespondsProperlyToManualSwitching",
                        defaultMessage: "Responds Properly To Manual Switching"
                      },
                      fieldKey:
                        "VisualInspection.TapChangersDETC.RespondsProperlyToManualSwitching",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: yesNo
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.IfConservatorOilLevel",
                        defaultMessage: "If Conservator, Oil Level"
                      },
                      fieldKey:
                        "VisualInspection.TapChangersDETC.IfConservatorOilLevel",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Decimal",
                      rules: [
                        {
                          type: "number"
                        }
                      ],
                      validationSchema: {
                        type: "number"
                      },
                      inputType: "Decimal"
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.GuageCondition",
                        defaultMessage: "Guage Condition"
                      },
                      fieldKey:
                        "VisualInspection.TapChangersDETC.GuageCondition",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: deviceCondition
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.Dessicant",
                        defaultMessage: "Dessicant"
                      },
                      fieldKey: "VisualInspection.TapChangersDETC.Dessicant",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: yesNo
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.IfDessicantCondition",
                        defaultMessage: "If Dessicant, Condition"
                      },
                      fieldKey:
                        "VisualInspection.TapChangersDETC.IfDessicantCondition",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: desiccantCondition
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.Filter",
                        defaultMessage: "Filter"
                      },
                      fieldKey: "VisualInspection.TapChangersDETC.Filter",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: yesNo
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.FilterCondition",
                        defaultMessage: "Filter Condition"
                      },
                      fieldKey:
                        "VisualInspection.TapChangersDETC.FilterCondition",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: filterCondition
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.OilLevelSelectorCompartment",
                        defaultMessage: "Oil Level Selector Compartment"
                      },
                      fieldKey:
                        "VisualInspection.TapChangersDETC.OilLevelSelectorCompartment",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: oilLevel
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.SelectorCompartmentGuageCondition",
                        defaultMessage: "Selector Compartment Guage Condition"
                      },
                      fieldKey:
                        "VisualInspection.TapChangersDETC.SelectorCompartmentGuageCondition",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: deviceCondition
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.OilLevelArchingCompartment",
                        defaultMessage: "Oil Level Arching Compartment"
                      },
                      fieldKey:
                        "VisualInspection.TapChangersDETC.OilLevelArchingCompartment",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: oilLevel
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.ArchingCompartmentGuageCondition",
                        defaultMessage: "Arching Compartment Guage Condition"
                      },
                      fieldKey:
                        "VisualInspection.TapChangersDETC.ArchingCompartmentGuageCondition",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: deviceCondition
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.ArchingCompartmentCondition",
                        defaultMessage: "Arching Compartment Condition"
                      },
                      fieldKey:
                        "VisualInspection.TapChangersDETC.ArchingCompartmentCondition",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: archingCompartmentCondition
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.ArchingCompartmentGuagePosition",
                        defaultMessage: "Arching Compartment Guage Position"
                      },
                      fieldKey:
                        "VisualInspection.TapChangersDETC.ArchingCompartmentGuagePosition",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Decimal",
                      rules: [
                        {
                          type: "number"
                        }
                      ],
                      validationSchema: {
                        type: "number"
                      },
                      inputType: "Decimal"
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.InfraredReading",
                        defaultMessage: "Infrared Reading"
                      },
                      fieldKey:
                        "VisualInspection.TapChangersDETC.InfraredReading",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: yesNo
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.HottestSpotC",
                        defaultMessage: "Hottest Spot C"
                      },
                      fieldKey: "VisualInspection.TapChangersDETC.HottestSpotC",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Decimal",
                      rules: [
                        {
                          type: "number"
                        }
                      ],
                      validationSchema: {
                        type: "number"
                      },
                      inputType: "Decimal"
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          tabName: {
            defaultMessage: "Bushings",
            id: "configuration_tool.tab.visual_inspection.sub_tab.Bushings"
          },
          sections: [
            {
              sectionName: {
                defaultMessage: "Bunshing Inspection",
                id: "configuration_tool.tab.visual_inspection.section.bushing_inspection"
              },
              sectionType: "table",
              span: { xs: 24, lg: 16 },
              sectionConfiguration: {
                columns: [
                  {
                    labelName: {
                      id: "configuration_tool.tab.visual_inspection.label.cracked_shed",
                      defaultMessage: "Cracked Shed"
                    },
                    span: 3
                  },
                  {
                    labelName: {
                      id: "configuration_tool.tab.visual_inspection.label.dark_oil",
                      defaultMessage: "Dark Oil"
                    },
                    span: 3
                  },
                  {
                    labelName: {
                      id: "configuration_tool.tab.visual_inspection.label.dga_status",
                      defaultMessage: "DGA Status"
                    },
                    span: 3
                  },
                  {
                    labelName: {
                      id: "configuration_tool.tab.visual_inspection.label.dirty_shed",
                      defaultMessage: "Dirty Shed"
                    },
                    span: 3
                  },
                  {
                    labelName: {
                      id: "configuration_tool.tab.visual_inspection.label.high_low_oil_level",
                      defaultMessage: "High/Low Oil Level"
                    },
                    span: 3
                  },
                  {
                    labelName: {
                      id: "configuration_tool.tab.visual_inspection.label.oil_leak",
                      defaultMessage: "Oil Leak"
                    },
                    span: 3
                  },
                  {
                    labelName: {
                      id: "configuration_tool.tab.visual_inspection.label.thermal_scan_hot_spot",
                      defaultMessage: "Thermal Scan Hot Spot"
                    },
                    span: 3
                  }
                ],
                rows: [
                  {
                    labelName: {
                      id: "configuration_tool.label.h1",
                      defaultMessage: "H1"
                    }
                  },
                  {
                    labelName: {
                      id: "configuration_tool.label.h2",
                      defaultMessage: "H2"
                    }
                  },
                  {
                    labelName: {
                      id: "configuration_tool.label.h3",
                      defaultMessage: "H3"
                    }
                  },
                  {
                    labelName: {
                      id: "configuration_tool.label.x1",
                      defaultMessage: "X1"
                    }
                  },
                  {
                    labelName: {
                      id: "configuration_tool.label.x2",
                      defaultMessage: "X2"
                    }
                  },
                  {
                    labelName: {
                      id: "configuration_tool.label.x3",
                      defaultMessage: "X3"
                    }
                  },
                  {
                    labelName: {
                      id: "configuration_tool.label.y1",
                      defaultMessage: "Y1"
                    }
                  },
                  {
                    labelName: {
                      id: "configuration_tool.label.y2",
                      defaultMessage: "Y2"
                    }
                  },
                  {
                    labelName: {
                      id: "configuration_tool.label.y3",
                      defaultMessage: "Y3"
                    }
                  }
                ]
              },
              fields: [
                {
                  fieldKey: "Inspection.Bushing.H1.CrackedShed",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Bool",
                  inputType: "list",
                  listValues: boolValues
                },
                {
                  fieldKey: "Inspection.Bushing.H1.DarkOil",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Bool",
                  inputType: "list",
                  listValues: boolValues
                },
                {
                  fieldKey: "Inspection.Bushing.H1.DGAstatus",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: dgaStatus
                },
                {
                  fieldKey: "Inspection.Bushing.H1.DirtyShed",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Bool",
                  inputType: "list",
                  listValues: boolValues
                },
                {
                  fieldKey: "Inspection.Bushing.H1.OilLevelBad",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: yesNoUnknown
                },
                {
                  fieldKey: "Inspection.Bushing.H1.GaugeLeak",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: yesNoUnknown
                },
                {
                  fieldKey: "Inspection.Bushing.H1.ThermalScanHotSpot",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: yesNoUnknown
                },

                {
                  fieldKey: "Inspection.Bushing.H2.CrackedShed",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Bool",
                  inputType: "list",
                  listValues: boolValues
                },
                {
                  fieldKey: "Inspection.Bushing.H2.DarkOil",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Bool",
                  inputType: "list",
                  listValues: boolValues
                },
                {
                  fieldKey: "Inspection.Bushing.H2.DGAstatus",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: dgaStatus
                },
                {
                  fieldKey: "Inspection.Bushing.H2.DirtyShed",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Bool",
                  inputType: "list",
                  listValues: boolValues
                },
                {
                  fieldKey: "Inspection.Bushing.H2.OilLevelBad",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: yesNoUnknown
                },
                {
                  fieldKey: "Inspection.Bushing.H2.GaugeLeak",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: yesNoUnknown
                },
                {
                  fieldKey: "Inspection.Bushing.H2.ThermalScanHotSpot",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: yesNoUnknown
                },

                {
                  fieldKey: "Inspection.Bushing.H3.CrackedShed",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Bool",
                  inputType: "list",
                  listValues: boolValues
                },
                {
                  fieldKey: "Inspection.Bushing.H3.DarkOil",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Bool",
                  inputType: "list",
                  listValues: boolValues
                },
                {
                  fieldKey: "Inspection.Bushing.H3.DGAstatus",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: dgaStatus
                },
                {
                  fieldKey: "Inspection.Bushing.H3.DirtyShed",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Bool",
                  inputType: "list",
                  listValues: boolValues
                },
                {
                  fieldKey: "Inspection.Bushing.H3.OilLevelBad",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: yesNoUnknown
                },
                {
                  fieldKey: "Inspection.Bushing.H3.GaugeLeak",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: yesNoUnknown
                },
                {
                  fieldKey: "Inspection.Bushing.H3.ThermalScanHotSpot",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: yesNoUnknown
                },

                {
                  fieldKey: "Inspection.Bushing.X1.CrackedShed",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Bool",
                  inputType: "list",
                  listValues: boolValues
                },
                {
                  fieldKey: "Inspection.Bushing.X1.DarkOil",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Bool",
                  inputType: "list",
                  listValues: boolValues
                },
                {
                  fieldKey: "Inspection.Bushing.X1.DGAstatus",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: dgaStatus
                },
                {
                  fieldKey: "Inspection.Bushing.X1.DirtyShed",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Bool",
                  inputType: "list",
                  listValues: boolValues
                },
                {
                  fieldKey: "Inspection.Bushing.X1.OilLevelBad",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: yesNoUnknown
                },
                {
                  fieldKey: "Inspection.Bushing.X1.GaugeLeak",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: yesNoUnknown
                },
                {
                  fieldKey: "Inspection.Bushing.X1.ThermalScanHotSpot",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: yesNoUnknown
                },

                {
                  fieldKey: "Inspection.Bushing.X2.CrackedShed",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Bool",
                  inputType: "list",
                  listValues: boolValues
                },
                {
                  fieldKey: "Inspection.Bushing.X2.DarkOil",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Bool",
                  inputType: "list",
                  listValues: boolValues
                },
                {
                  fieldKey: "Inspection.Bushing.X2.DGAstatus",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: dgaStatus
                },
                {
                  fieldKey: "Inspection.Bushing.X2.DirtyShed",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Bool",
                  inputType: "list",
                  listValues: boolValues
                },
                {
                  fieldKey: "Inspection.Bushing.X2.OilLevelBad",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: yesNoUnknown
                },
                {
                  fieldKey: "Inspection.Bushing.X2.GaugeLeak",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: yesNoUnknown
                },
                {
                  fieldKey: "Inspection.Bushing.X2.ThermalScanHotSpot",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: yesNoUnknown
                },

                {
                  fieldKey: "Inspection.Bushing.X3.CrackedShed",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Bool",
                  inputType: "list",
                  listValues: boolValues
                },
                {
                  fieldKey: "Inspection.Bushing.X3.DarkOil",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Bool",
                  inputType: "list",
                  listValues: boolValues
                },
                {
                  fieldKey: "Inspection.Bushing.X3.DGAstatus",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: dgaStatus
                },
                {
                  fieldKey: "Inspection.Bushing.X3.DirtyShed",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Bool",
                  inputType: "list",
                  listValues: boolValues
                },
                {
                  fieldKey: "Inspection.Bushing.X3.OilLevelBad",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: yesNoUnknown
                },
                {
                  fieldKey: "Inspection.Bushing.X3.GaugeLeak",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: yesNoUnknown
                },
                {
                  fieldKey: "Inspection.Bushing.X3.ThermalScanHotSpot",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: yesNoUnknown
                },

                {
                  fieldKey: "Inspection.Bushing.Y1.CrackedShed",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Bool",
                  inputType: "list",
                  listValues: boolValues
                },
                {
                  fieldKey: "Inspection.Bushing.Y1.DarkOil",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Bool",
                  inputType: "list",
                  listValues: boolValues
                },
                {
                  fieldKey: "Inspection.Bushing.Y1.DGAstatus",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: dgaStatus
                },
                {
                  fieldKey: "Inspection.Bushing.Y1.DirtyShed",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Bool",
                  inputType: "list",
                  listValues: boolValues
                },
                {
                  fieldKey: "Inspection.Bushing.Y1.OilLevelBad",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: yesNoUnknown
                },
                {
                  fieldKey: "Inspection.Bushing.Y1.GaugeLeak",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: yesNoUnknown
                },
                {
                  fieldKey: "Inspection.Bushing.Y1.ThermalScanHotSpot",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: yesNoUnknown
                },

                {
                  fieldKey: "Inspection.Bushing.Y2.CrackedShed",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Bool",
                  inputType: "list",
                  listValues: boolValues
                },
                {
                  fieldKey: "Inspection.Bushing.Y2.DarkOil",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Bool",
                  inputType: "list",
                  listValues: boolValues
                },
                {
                  fieldKey: "Inspection.Bushing.Y2.DGAstatus",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: dgaStatus
                },
                {
                  fieldKey: "Inspection.Bushing.Y2.DirtyShed",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Bool",
                  inputType: "list",
                  listValues: boolValues
                },
                {
                  fieldKey: "Inspection.Bushing.Y2.OilLevelBad",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: yesNoUnknown
                },
                {
                  fieldKey: "Inspection.Bushing.Y2.GaugeLeak",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: yesNoUnknown
                },
                {
                  fieldKey: "Inspection.Bushing.Y2.ThermalScanHotSpot",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: yesNoUnknown
                },

                {
                  fieldKey: "Inspection.Bushing.Y3.CrackedShed",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Bool",
                  inputType: "list",
                  listValues: boolValues
                },
                {
                  fieldKey: "Inspection.Bushing.Y3.DarkOil",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Bool",
                  inputType: "list",
                  listValues: boolValues
                },
                {
                  fieldKey: "Inspection.Bushing.Y3.DGAstatus",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: dgaStatus
                },
                {
                  fieldKey: "Inspection.Bushing.Y3.DirtyShed",
                  fieldType: FieldTypes.Parameter,
                  dataType: "Bool",
                  inputType: "list",
                  listValues: boolValues
                },
                {
                  fieldKey: "Inspection.Bushing.Y3.OilLevelBad",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: yesNoUnknown
                },
                {
                  fieldKey: "Inspection.Bushing.Y3.GaugeLeak",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: yesNoUnknown
                },
                {
                  fieldKey: "Inspection.Bushing.Y3.ThermalScanHotSpot",
                  fieldType: FieldTypes.Parameter,
                  dataType: "String",
                  inputType: "list",
                  listValues: yesNoUnknown
                }
              ]
            },
            {
              span: { xs: 24, sm: 12, lg: 8 },
              sectionList: [
                {
                  sectionName: {
                    defaultMessage: "Bushing",
                    id: "configuration_tool.tab.visual_inspection.section.bushing"
                  },
                  sectionType: "list",
                  fields: [
                    {
                      fieldName: {
                        id: "AbbTransformerStandardModelV6.parameter.Inspection.Bushing.SynchroSwitch",
                        defaultMessage: "Synchronized Switching:"
                      },
                      fieldKey: "Inspection.Bushing.SynchroSwitch",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: yesNoUnknown
                    }
                  ]
                },
                {
                  sectionName: {
                    defaultMessage: "Bushings",
                    id: "configuration_tool.tab.visual_inspection.section.bushings"
                  },
                  sectionType: "list",
                  fields: [
                    {
                      fieldName: {
                        id: "AbbTransformerStandardModelV6.parameter.Inspection.Bushing.GisConnection",
                        defaultMessage: "Connected to GIS:"
                      },
                      fieldKey: "Inspection.Bushing.GisConnection",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: yesNoUnknown
                    },
                    {
                      fieldName: {
                        id: "AbbTransformerStandardModelV6.parameter.Inspection.Bushing.GisConnectedDate",
                        defaultMessage: "Date Connected to GIS:"
                      },
                      fieldKey: "Inspection.Bushing.GisConnectedDate",
                      fieldType: FieldTypes.Parameter,
                      dataType: "DateTime",
                      inputType: "DateTime",
                      disableFutureDates: true,
                      validationSchema: {
                        type: "string",
                        format: "date"
                      }
                    },
                    {
                      fieldName: {
                        id: "AbbTransformerStandardModelV6.parameter.Inspection.Bushing.Environment",
                        defaultMessage: "Environment:"
                      },
                      fieldKey: "Inspection.Bushing.Environment",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: environment
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          tabName: {
            defaultMessage: "History",
            id: "configuration_tool.tab.visual_inspection.sub_tab.History"
          },
          sections: [
            {
              span: { xs: 24, sm: 12, lg: 8 },
              sectionList: [
                {
                  sectionName: {
                    defaultMessage: "Loading and Age",
                    id: "configuration_tool.tab.visual_inspection.section.loading_and_age"
                  },
                  sectionType: "list",
                  fields: [
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.Typical",
                        defaultMessage: "Typical"
                      },
                      fieldKey: "VisualInspection.LoadingAndAge.Typical",
                      fieldType: FieldTypes.Parameter,
                      dataType: "String",
                      inputType: "list",
                      listValues: typical
                    },
                    {
                      fieldName: {
                        id: "AbbTransformerStandardModelV6.parameter.HistoryData.UnitAgeIssue",
                        defaultMessage: "Unit Age Issue"
                      },
                      fieldKey: "HistoryData.UnitAgeIssue",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Bool",
                      inputType: "list",
                      listValues: boolValues
                    },
                    {
                      fieldName: {
                        id: "configuration_tool.tab.visual_inspection.parameter.UnitYearsOfService",
                        defaultMessage: "Unit Years Of Service"
                      },
                      fieldKey:
                        "VisualInspection.LoadingAndAge.UnitYearsOfService",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Decimal",
                      rules: [
                        {
                          type: "number"
                        }
                      ],
                      validationSchema: {
                        type: "number"
                      },
                      inputType: "Decimal"
                    }
                  ]
                },
                {
                  sectionName: {
                    defaultMessage: "Failure Count",
                    id: "configuration_tool.tab.visual_inspection.section.failure_count"
                  },
                  sectionType: "list",
                  fields: [
                    {
                      fieldName: {
                        id: "AbbTransformerStandardModelV6.parameter.HistoryData.ThroughFaultsPerYear",
                        defaultMessage: "Through Faults Per Year"
                      },
                      fieldKey: "HistoryData.ThroughFaultsPerYear",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Decimal",
                      rules: [
                        {
                          type: "number"
                        }
                      ],
                      validationSchema: {
                        type: "number"
                      },
                      inputType: "Decimal"
                    },
                    {
                      fieldName: {
                        id: "AbbTransformerStandardModelV6.parameter.HistoryData.ThroughFaultIssue",
                        defaultMessage: "Through Fault Issue"
                      },
                      fieldKey: "HistoryData.ThroughFaultIssue",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Bool",
                      inputType: "list",
                      listValues: boolValues
                    },
                    {
                      fieldName: {
                        id: "AbbTransformerStandardModelV6.parameter.HistoryData.SisterUnitFailures",
                        defaultMessage: "Sister Unit Failures"
                      },
                      fieldKey: "HistoryData.SisterUnitFailures",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Decimal",
                      rules: [
                        {
                          type: "number"
                        }
                      ],
                      validationSchema: {
                        type: "number"
                      },
                      inputType: "Decimal"
                    },
                    {
                      fieldName: {
                        id: "AbbTransformerStandardModelV6.parameter.HistoryData.SisterUnitIssue",
                        defaultMessage: "Sister Unit Issue"
                      },
                      fieldKey: "HistoryData.SisterUnitIssue",
                      fieldType: FieldTypes.Parameter,
                      dataType: "Bool",
                      inputType: "list",
                      listValues: boolValues
                    }
                  ]
                }
              ]
            },
            {
              sectionName: {
                defaultMessage: "Maintenance Dates",
                id: "configuration_tool.tab.visual_inspection.section.maintenance_dates"
              },
              sectionType: "list",
              span: { xs: 24, sm: 12, lg: 8 },
              fields: [
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.LastCoolingMaintenanceDate",
                    defaultMessage: "Last Cooling Maintenance Date"
                  },
                  fieldKey:
                    "VisualInspection.MaintenanceDates.LastCoolingMaintenanceDate",
                  fieldType: FieldTypes.Parameter,
                  dataType: "DateTime",
                  inputType: "DateTime",
                  disableFutureDates: true,
                  validationSchema: {
                    type: "string",
                    format: "date"
                  }
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.LastCoolingReplacementDate",
                    defaultMessage: "Last Cooling Replacement Date"
                  },
                  fieldKey:
                    "VisualInspection.MaintenanceDates.LastCoolingReplacementDate",
                  fieldType: FieldTypes.Parameter,
                  dataType: "DateTime",
                  inputType: "DateTime",
                  disableFutureDates: true,
                  validationSchema: {
                    type: "string",
                    format: "date"
                  }
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.PumpReconditionDate",
                    defaultMessage: "Pump Recondition Date"
                  },
                  fieldKey:
                    "VisualInspection.MaintenanceDates.PumpReconditionDate",
                  fieldType: FieldTypes.Parameter,
                  dataType: "DateTime",
                  inputType: "DateTime",
                  disableFutureDates: true,
                  validationSchema: {
                    type: "string",
                    format: "date"
                  }
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.LastOilReclamationDate",
                    defaultMessage: "Last Oil Reclamation Date"
                  },
                  fieldKey:
                    "VisualInspection.MaintenanceDates.LastOilReclamationDate",
                  fieldType: FieldTypes.Parameter,
                  dataType: "DateTime",
                  inputType: "DateTime",
                  disableFutureDates: true,
                  validationSchema: {
                    type: "string",
                    format: "date"
                  }
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.LastOPSReplacementDate",
                    defaultMessage: "Last OPS Replacement Date"
                  },
                  fieldKey:
                    "VisualInspection.MaintenanceDates.LastOPSReplacementDate",
                  fieldType: FieldTypes.Parameter,
                  dataType: "DateTime",
                  inputType: "DateTime",
                  disableFutureDates: true,
                  validationSchema: {
                    type: "string",
                    format: "date"
                  }
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.TankLastOpenedDate",
                    defaultMessage: "Tank Last Opened Date"
                  },
                  fieldKey:
                    "VisualInspection.MaintenanceDates.TankLastOpenedDate",
                  fieldType: FieldTypes.Parameter,
                  dataType: "DateTime",
                  inputType: "DateTime",
                  disableFutureDates: true,
                  validationSchema: {
                    type: "string",
                    format: "date"
                  }
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.LastGasAccumTripDate",
                    defaultMessage: "Last Gas Accum Trip Date"
                  },
                  fieldKey:
                    "VisualInspection.MaintenanceDates.LastGasAccumTripDate",
                  fieldType: FieldTypes.Parameter,
                  dataType: "DateTime",
                  inputType: "DateTime",
                  disableFutureDates: true,
                  validationSchema: {
                    type: "string",
                    format: "date"
                  }
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.LastMechReliefTripDate",
                    defaultMessage: "Last Mech Relief Trip Date"
                  },
                  fieldKey:
                    "VisualInspection.MaintenanceDates.LastMechReliefTripDate",
                  fieldType: FieldTypes.Parameter,
                  dataType: "DateTime",
                  inputType: "DateTime",
                  disableFutureDates: true,
                  validationSchema: {
                    type: "string",
                    format: "date"
                  }
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.LastOilSurgeTripDate",
                    defaultMessage: "Last Oil Surge Trip Date"
                  },
                  fieldKey:
                    "VisualInspection.MaintenanceDates.LastOilSurgeTripDate",
                  fieldType: FieldTypes.Parameter,
                  dataType: "DateTime",
                  inputType: "DateTime",
                  disableFutureDates: true,
                  validationSchema: {
                    type: "string",
                    format: "date"
                  }
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.LastTopOilTemperatureTripDate",
                    defaultMessage: "Last Top Oil Temperature Trip Date"
                  },
                  fieldKey:
                    "VisualInspection.MaintenanceDates.LastTopOilTemperatureTripDate",
                  fieldType: FieldTypes.Parameter,
                  dataType: "DateTime",
                  inputType: "DateTime",
                  disableFutureDates: true,
                  validationSchema: {
                    type: "string",
                    format: "date"
                  }
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.LastBushingMaintenanceDate",
                    defaultMessage: "Last Bushing Maintenance Date"
                  },
                  fieldKey:
                    "VisualInspection.MaintenanceDates.LastBushingMaintenanceDate",
                  fieldType: FieldTypes.Parameter,
                  dataType: "DateTime",
                  inputType: "DateTime",
                  disableFutureDates: true,
                  validationSchema: {
                    type: "string",
                    format: "date"
                  }
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.LastTapChangerMaintenance",
                    defaultMessage: "Last Tap Changer Maintenance "
                  },
                  fieldKey:
                    "VisualInspection.MaintenanceDates.LastTapChangerMaintenance",
                  fieldType: FieldTypes.Parameter,
                  dataType: "DateTime",
                  inputType: "DateTime",
                  disableFutureDates: true,
                  validationSchema: {
                    type: "string",
                    format: "date"
                  }
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.ProtectionMaintenanceDate",
                    defaultMessage: "Protection Maintenance Date"
                  },
                  fieldKey:
                    "VisualInspection.MaintenanceDates.ProtectionMaintenanceDate",
                  fieldType: FieldTypes.Parameter,
                  dataType: "DateTime",
                  inputType: "DateTime",
                  disableFutureDates: true,
                  validationSchema: {
                    type: "string",
                    format: "date"
                  }
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.TankMaintenanceDate",
                    defaultMessage: "Tank Maintenance Date"
                  },
                  fieldKey:
                    "VisualInspection.MaintenanceDates.TankMaintenanceDate",
                  fieldType: FieldTypes.Parameter,
                  dataType: "DateTime",
                  inputType: "DateTime",
                  disableFutureDates: true,
                  validationSchema: {
                    type: "string",
                    format: "date"
                  }
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.OPSMaintenanceDate",
                    defaultMessage: "OPS Maintenance Date"
                  },
                  fieldKey:
                    "VisualInspection.MaintenanceDates.OPSMaintenanceDate",
                  fieldType: FieldTypes.Parameter,
                  dataType: "DateTime",
                  inputType: "DateTime",
                  disableFutureDates: true,
                  validationSchema: {
                    type: "string",
                    format: "date"
                  }
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.LastLTCMaintenanceDate",
                    defaultMessage: "Last LTC Maintenance Date"
                  },
                  fieldKey:
                    "VisualInspection.MaintenanceDates.LastLTCMaintenanceDate",
                  fieldType: FieldTypes.Parameter,
                  dataType: "DateTime",
                  inputType: "DateTime",
                  disableFutureDates: true,
                  validationSchema: {
                    type: "string",
                    format: "date"
                  }
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.LastDGABushingMaintenanceDate",
                    defaultMessage: "Last DGA Bushing Maintenance Date"
                  },
                  fieldKey:
                    "VisualInspection.MaintenanceDates.LastDGABushingMaintenanceDate",
                  fieldType: FieldTypes.Parameter,
                  dataType: "DateTime",
                  inputType: "DateTime",
                  disableFutureDates: true,
                  validationSchema: {
                    type: "string",
                    format: "date"
                  }
                },
                {
                  fieldName: {
                    id: "configuration_tool.tab.visual_inspection.parameter.LastDGALTCMaintenanceDate",
                    defaultMessage: "Last DGA LTC Maintenance Date"
                  },
                  fieldKey:
                    "VisualInspection.MaintenanceDates.LastDGALTCMaintenanceDate",
                  fieldType: FieldTypes.Parameter,
                  dataType: "DateTime",
                  inputType: "DateTime",
                  disableFutureDates: true,
                  validationSchema: {
                    type: "string",
                    format: "date"
                  }
                }
              ]
            }
          ]
        }
      ]
    },
    {
      formKey: "insulation",
      enterDataWithCurrentDate: false,
      sections: [
        {
          sectionName: {
            defaultMessage: "Insulation Test Results",
            id: "configuration_tool.tab.insulation.section.insulation_test_results"
          },
          sectionType: "list",
          span: { xs: 24, sm: 12, lg: 8 },
          fields: [
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.MTMP.InsulationData.HVtoXVCapacitance",
                defaultMessage: "Capacitance HV to XV"
              },
              fieldKey: "MTMP.InsulationData.HVtoXVCapacitance",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 10000
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 10000
              },
              inputType: "Decimal",
              unit: "pF"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.MTMP.InsulationData.HVtoYVCapacitance",
                defaultMessage: "Capacitance HV to YV"
              },
              fieldKey: "MTMP.InsulationData.HVtoYVCapacitance",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 10000
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 10000
              },
              inputType: "Decimal",
              unit: "pF"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.MTMP.InsulationData.XVtoYVCapacitance",
                defaultMessage: "Capacitance XV to YV"
              },
              fieldKey: "MTMP.InsulationData.XVtoYVCapacitance",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 10000
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 10000
              },
              inputType: "Decimal",
              unit: "pF"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.MTMP.InsulationData.DegreeOfPolymerization",
                defaultMessage: "Degree of Polymerization"
              },
              fieldKey: "MTMP.InsulationData.DegreeOfPolymerization",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number"
                }
              ],
              validationSchema: {
                type: "number"
              },
              inputType: "Decimal",
              unit: "%"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.MTMP.InsulationData.HVtoGround",
                defaultMessage: "Power Factor HV to Ground"
              },
              fieldKey: "MTMP.InsulationData.HVtoGround",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 10000000000
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 10000000000
              },
              inputType: "Decimal",
              unit: "%"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.MTMP.InsulationData.HVtoXV",
                defaultMessage: "Power Factor HV to XV"
              },
              fieldKey: "MTMP.InsulationData.HVtoXV",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 10000000000
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 10000000000
              },
              inputType: "Decimal",
              unit: "%"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.MTMP.InsulationData.HVtoYV",
                defaultMessage: "Power Factor HV to YV"
              },
              fieldKey: "MTMP.InsulationData.HVtoYV",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 10000000000
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 10000000000
              },
              inputType: "Decimal",
              unit: "%"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.MTMP.InsulationData.XVtoGround",
                defaultMessage: "Power Factor XV to Ground"
              },
              fieldKey: "MTMP.InsulationData.XVtoGround",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 10000000000
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 10000000000
              },
              inputType: "Decimal",
              unit: "%"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.MTMP.InsulationData.XVtoYV",
                defaultMessage: "Power Factor XV to YV"
              },
              fieldKey: "MTMP.InsulationData.XVtoYV",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 10000000000
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 10000000000
              },
              inputType: "Decimal",
              unit: "%"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.MTMP.InsulationData.YVtoGround",
                defaultMessage: "Power Factor YV to Ground"
              },
              fieldKey: "MTMP.InsulationData.YVtoGround",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 10000000000
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 10000000000
              },
              inputType: "Decimal",
              unit: "%"
            }
          ]
        }
      ]
    },
    {
      formKey: "modelFactors",
      enterDataWithCurrentDate: true,
      sections: [
        {
          sectionName: {
            defaultMessage: "Model factors",
            id: "configuration_tool.tab.model_factors.section.model_factors"
          },
          sectionType: "list",
          span: { xs: 24, sm: 12, lg: 8 },
          fields: [
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.MTMP.FactorMultipliers.Accessory",
                defaultMessage: "MTMP Accessory Risk Multiplier"
              },
              fieldKey: "MTMP.FactorMultipliers.Accessory",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 9999999999999
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 9999999999999
              },
              inputType: "Decimal"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.MTMP.FactorAdditives.Accessory",
                defaultMessage: "MTMP Additional Accessory Risk"
              },
              fieldKey: "MTMP.FactorAdditives.Accessory",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 9999999999999
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 9999999999999
              },
              inputType: "Decimal"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.MTMP.FactorAdditives.Dielectric",
                defaultMessage: "MTMP Additional Dielectric Risk"
              },
              fieldKey: "MTMP.FactorAdditives.Dielectric",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 9999999999999
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 9999999999999
              },
              inputType: "Decimal"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.MTMP.FactorAdditives.HighTemperature",
                defaultMessage: "MTMP Additional High Temperature Risk"
              },
              fieldKey: "MTMP.FactorAdditives.HighTemperature",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 9999999999999
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 9999999999999
              },
              inputType: "Decimal"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.MTMP.FactorAdditives.Miscellaneous",
                defaultMessage: "MTMP Additional Miscellaneous Risk"
              },
              fieldKey: "MTMP.FactorAdditives.Miscellaneous",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 9999999999999
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 9999999999999
              },
              inputType: "Decimal"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.MTMP.FactorAdditives.ShortCircuit",
                defaultMessage: "MTMP Additional Short Circuit Risk"
              },
              fieldKey: "MTMP.FactorAdditives.ShortCircuit",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 9999999999999
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 9999999999999
              },
              inputType: "Decimal"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.MTMP.FactorMultipliers.Dielectric",
                defaultMessage: "MTMP Dielectric Failure Multiplier"
              },
              fieldKey: "MTMP.FactorMultipliers.Dielectric",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 9999999999999
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 9999999999999
              },
              inputType: "Decimal"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.MTMP.FactorMultipliers.HighTemperature",
                defaultMessage: "MTMP High Temperature Multiplier"
              },
              fieldKey: "MTMP.FactorMultipliers.HighTemperature",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 9999999999999
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 9999999999999
              },
              inputType: "Decimal"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.MTMP.FactorMultipliers.Miscellaneous",
                defaultMessage: "MTMP Miscellaneous Failure Multiplier"
              },
              fieldKey: "MTMP.FactorMultipliers.Miscellaneous",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 9999999999999
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 9999999999999
              },
              inputType: "Decimal"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.MTMP.FactorMultipliers.ShortCircuit",
                defaultMessage: "MTMP Short Circuit Risk Multiplier"
              },
              fieldKey: "MTMP.FactorMultipliers.ShortCircuit",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 9999999999999
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 9999999999999
              },
              inputType: "Decimal"
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.MTMP.FactorMultipliers.TotalRisk",
                defaultMessage: "MTMP Total Risk Multiplier"
              },
              fieldKey: "MTMP.FactorMultipliers.TotalRisk",
              fieldType: FieldTypes.Parameter,
              dataType: "Decimal",
              rules: [
                {
                  type: "number",
                  min: 0,
                  max: 9999999999999
                }
              ],
              validationSchema: {
                type: "number",
                minimum: 0,
                maximum: 9999999999999
              },
              inputType: "Decimal"
            }
          ]
        },
        {
          sectionName: {
            defaultMessage: "Factor calculations",
            id: "configuration_tool.tab.model_factors.section.factor_calculations"
          },
          sectionType: "list",
          span: { xs: 24, sm: 12, lg: 8 },
          fields: [
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.FactorCalculation.DisableAccessoryAge",
                defaultMessage: "Disable accessory age factor"
              },
              fieldKey: "Common.FactorCalculation.DisableAccessoryAge",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.FactorCalculation.DisableAccessoryProbabilityOfFailure",
                defaultMessage: "Disable accessory probability of failure"
              },
              fieldKey:
                "Common.FactorCalculation.DisableAccessoryProbabilityOfFailure",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.FactorCalculation.DisableAge",
                defaultMessage: "Disable age factor"
              },
              fieldKey: "Common.FactorCalculation.DisableAge",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.FactorCalculation.DisableArcing",
                defaultMessage: "Disable arcing factor"
              },
              fieldKey: "Common.FactorCalculation.DisableArcing",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.FactorCalculation.DisableArresterRisk",
                defaultMessage: "Disable arrester risk factor"
              },
              fieldKey: "Common.FactorCalculation.DisableArresterRisk",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.FactorCalculation.DisableBasePower",
                defaultMessage: "Disable base power factor"
              },
              fieldKey: "Common.FactorCalculation.DisableBasePower",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.FactorCalculation.DisableBasicInsulationLevelDielectric",
                defaultMessage:
                  "Disable basic insulation level dielectric factor"
              },
              fieldKey:
                "Common.FactorCalculation.DisableBasicInsulationLevelDielectric",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.FactorCalculation.DisableBasicInsulationLevelShortCircuit",
                defaultMessage:
                  "Disable basic insulation level short circuit factor"
              },
              fieldKey:
                "Common.FactorCalculation.DisableBasicInsulationLevelShortCircuit",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.FactorCalculation.DisableBushingDielectric",
                defaultMessage: "Disable bushing dielectric factor"
              },
              fieldKey: "Common.FactorCalculation.DisableBushingDielectric",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.FactorCalculation.DisableBushingMaintenance",
                defaultMessage: "Disable bushing maintenance factor"
              },
              fieldKey: "Common.FactorCalculation.DisableBushingMaintenance",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.FactorCalculation.DisableBushingOperation",
                defaultMessage: "Disable bushing operation factor"
              },
              fieldKey: "Common.FactorCalculation.DisableBushingOperation",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.FactorCalculation.DisableBushingTimeConnectedToGis",
                defaultMessage: "Disable bushing time connected to gis factor"
              },
              fieldKey:
                "Common.FactorCalculation.DisableBushingTimeConnectedToGis",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.FactorCalculation.DisableBushingTimeInOperation",
                defaultMessage: "Disable bushing time in operation factor"
              },
              fieldKey:
                "Common.FactorCalculation.DisableBushingTimeInOperation",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.FactorCalculation.DisableBushingType",
                defaultMessage: "Disable bushing type factor"
              },
              fieldKey: "Common.FactorCalculation.DisableBushingType",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.FactorCalculation.DisableCarbonOxides",
                defaultMessage: "Disable carbon oxides factor"
              },
              fieldKey: "Common.FactorCalculation.DisableCarbonOxides",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.FactorCalculation.DisableConservatorType",
                defaultMessage: "Disable conservator type factor"
              },
              fieldKey: "Common.FactorCalculation.DisableConservatorType",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.FactorCalculation.DisableCoolingCondition",
                defaultMessage: "Disable cooling condition factor"
              },
              fieldKey: "Common.FactorCalculation.DisableCoolingCondition",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.FactorCalculation.DisableDiagnosticTest",
                defaultMessage: "Disable diagnostic test factor"
              },
              fieldKey: "Common.FactorCalculation.DisableDiagnosticTest",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.FactorCalculation.DisableDielectricDesign",
                defaultMessage: "Disable dielectric design factor"
              },
              fieldKey: "Common.FactorCalculation.DisableDielectricDesign",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.FactorCalculation.DisableDielectricProbabilityOfFailure",
                defaultMessage: "Disable dielectric probability of failure"
              },
              fieldKey:
                "Common.FactorCalculation.DisableDielectricProbabilityOfFailure",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.FactorCalculation.DisableHighRiskLocation",
                defaultMessage: "Disable high risk location factor"
              },
              fieldKey: "Common.FactorCalculation.DisableHighRiskLocation",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.FactorCalculation.DisableHighTemperatureProbabilityOfFailure",
                defaultMessage:
                  "Disable high temperature probability of failure"
              },
              fieldKey:
                "Common.FactorCalculation.DisableHighTemperatureProbabilityOfFailure",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.FactorCalculation.DisableHighVoltage",
                defaultMessage: "Disable high voltage factor"
              },
              fieldKey: "Common.FactorCalculation.DisableHighVoltage",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.FactorCalculation.DisableHotMetal",
                defaultMessage: "Disable hot metal factor"
              },
              fieldKey: "Common.FactorCalculation.DisableHotMetal",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.FactorCalculation.DisableHotWinding",
                defaultMessage: "Disable hot winding factor"
              },
              fieldKey: "Common.FactorCalculation.DisableHotWinding",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.FactorCalculation.DisableInsulationPowerFactor",
                defaultMessage: "Disable insulation power factor"
              },
              fieldKey: "Common.FactorCalculation.DisableInsulationPowerFactor",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.FactorCalculation.DisableLoadingBased",
                defaultMessage: "Disable loading based factor"
              },
              fieldKey: "Common.FactorCalculation.DisableLoadingBased",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.FactorCalculation.DisableLoadTapChangerDiagnostic",
                defaultMessage: "Disable load tap changer diagnostic factor"
              },
              fieldKey:
                "Common.FactorCalculation.DisableLoadTapChangerDiagnostic",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.FactorCalculation.DisableLoadTapChangerType",
                defaultMessage: "Disable load tap changer type factor"
              },
              fieldKey: "Common.FactorCalculation.DisableLoadTapChangerType",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.FactorCalculation.DisableLowVoltage",
                defaultMessage: "Disable low voltage factor"
              },
              fieldKey: "Common.FactorCalculation.DisableLowVoltage",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.FactorCalculation.DisableMiscellaneousProbabilityOfFailure",
                defaultMessage: "Disable miscellaneous probability of failure"
              },
              fieldKey:
                "Common.FactorCalculation.DisableMiscellaneousProbabilityOfFailure",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.FactorCalculation.DisableOilQuality",
                defaultMessage: "Disable oil quality factor"
              },
              fieldKey: "Common.FactorCalculation.DisableOilQuality",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.FactorCalculation.DisablePartialDischarge",
                defaultMessage: "Disable partial discharge factor"
              },
              fieldKey: "Common.FactorCalculation.DisablePartialDischarge",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.FactorCalculation.DisablePump",
                defaultMessage: "Disable pump factor"
              },
              fieldKey: "Common.FactorCalculation.DisablePump",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.FactorCalculation.DisableReclosingPractice",
                defaultMessage: "Disable reclosing practice factor"
              },
              fieldKey: "Common.FactorCalculation.DisableReclosingPractice",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.FactorCalculation.DisableSeismicRisk",
                defaultMessage: "Disable seismic risk factor"
              },
              fieldKey: "Common.FactorCalculation.DisableSeismicRisk",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.FactorCalculation.DisableShortCircuitDesign",
                defaultMessage: "Disable short circuit design factor"
              },
              fieldKey: "Common.FactorCalculation.DisableShortCircuitDesign",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.FactorCalculation.DisableShortCircuitProbabilityOfFailure",
                defaultMessage: "Disable short circuit probability of failure"
              },
              fieldKey:
                "Common.FactorCalculation.DisableShortCircuitProbabilityOfFailure",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.FactorCalculation.DisableStaticElectrification",
                defaultMessage: "Disable static electrification factor"
              },
              fieldKey: "Common.FactorCalculation.DisableStaticElectrification",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.FactorCalculation.DisableThermalDegradation",
                defaultMessage: "Disable thermal degradation factor"
              },
              fieldKey: "Common.FactorCalculation.DisableThermalDegradation",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.FactorCalculation.DisableThermalScan",
                defaultMessage: "Disable thermal scan factor"
              },
              fieldKey: "Common.FactorCalculation.DisableThermalScan",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.FactorCalculation.DisableThroughFaultsPerYear",
                defaultMessage: "Disable through faults per year factor"
              },
              fieldKey: "Common.FactorCalculation.DisableThroughFaultsPerYear",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            },
            {
              fieldName: {
                id: "AbbTransformerStandardModelV6.parameter.Common.FactorCalculation.DisableTotalProbabilityOfFailure",
                defaultMessage: "Disable total probability of failure"
              },
              fieldKey:
                "Common.FactorCalculation.DisableTotalProbabilityOfFailure",
              fieldType: FieldTypes.Parameter,
              dataType: "Bool",
              inputType: "list",
              listValues: boolValues
            }
          ]
        }
      ]
    }
  ]
};
