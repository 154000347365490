// Copyright 2016-2024 Hitachi Energy. All rights reserved.

/* eslint-disable jsx-a11y/anchor-is-valid */
import TooltipWrapper, {
  Themes,
  TooltipPosition
} from "common/tooltipwrapper/TooltipWrapper";
import useNavToAsset from "hooks/useNavToAsset";
import { Link } from "react-router-dom";
import "./SlidePanelTitle.less";

export interface ISlidePanelTitleProps {
  assetId: string;
  assetName: string;
}

const SlidePanelTitle = ({ assetId }: ISlidePanelTitleProps) => {
  const { navigateTo } = useNavToAsset();

  return (
    <TooltipWrapper
      Tooltip={() => <div>{assetId}</div>}
      theme={Themes.Dark}
      position={TooltipPosition.BottomCenter}
    >
      <Link
        data-qa="asset-header"
        className="slide-panel-title bold link-label"
        to={navigateTo(assetId)}
      >
        {assetId}
      </Link>
    </TooltipWrapper>
  );
};

export default SlidePanelTitle;
