// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import DataGrid, { IRow, IRowData } from "common/datagrid/DataGrid";
import DataGridWrapper from "common/datagrid/DataGridWrapper";
import { AssetRiskEnum } from "models/AssetRisks";
import styled from "styled-components";
import useComponentsGrid from "../hooks/useComponentsGrid";
import NavigateToComponents from "./NavigateToComponents";

interface IAssetsGridProps {
  className?: string;
  row: IRow<IRowData>;
  risk: AssetRiskEnum;
}

const SidePanelGrid = ({ className, row, risk }: IAssetsGridProps) => {
  const { dataEndpoint, columns } = useComponentsGrid({
    assetId: row.data["AssetId"],
    risk: risk
  });

  return (
    <SidePanelContent className={className}>
      <DataGridContainer>
        <DataGridWrapper>
          <DataGrid columns={columns} dataEndpoint={dataEndpoint} />
        </DataGridWrapper>
      </DataGridContainer>
      <NavigateToComponents assetId={row.data["AssetId"]} risk={risk} />
    </SidePanelContent>
  );
};

const SidePanelContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  max-height: 100%;
`;

const DataGridContainer = styled.div`
  height: 100%;
  position: relative;
  width: auto;

  .data-grid__parent {
    position: absolute;
    top: 0;
    border: 0;
    left: 0;
    right: 0;

    .container-content {
      padding: 0px;
    }
  }
`;

export default SidePanelGrid;
