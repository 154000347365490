// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import * as React from "react";
import DielectricContext from "../../contexts/DielectricContext";
import SF6MoistureChart from "../SF6MoistureChart";
import { SF6PressureChart } from "../SF6PressureChart";

export interface IModelDetails {
  modelId?: string;
  implementationId?: string;
}

interface IDielectricTab {
  assetId: string;
  model: IModelDetails;
}

const DielectricTab = ({ assetId, model }: IDielectricTab) => {
  return (
    <DielectricContext.Provider
      value={{
        assetId
      }}
    >
      <div className="dielectric-tab">
        <div className="container">
          <div className="bootstrap-row">
            <div className="col-12">
              <SF6PressureChart model={model} />
            </div>
            <div className="col-12">
              <SF6MoistureChart model={model} />
            </div>
          </div>
        </div>
      </div>
    </DielectricContext.Provider>
  );
};

export default DielectricTab;
