// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { SearchParams } from "@pg/common";
import Processing from "components/common/Processing";
import { UserRoles } from "core/app/components/auth/Authorization";
import {
  useFeature,
  VisualizationKeys
} from "core/app/components/FeatureToggle";
import {
  LayoutRoute,
  useAppNavigate
} from "core/app/components/RouterProvider";
import { IUser } from "core/app/reducers/settings/UserReducer";
import AuthorizationService from "core/app/services/AuthorizationService";
import Data, { Statuses } from "core/data/models/Data";
import IReport from "features/detailpage/models/IReport";
import { isEmpty, isNil } from "lodash";
import { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import buildAssetFilter from "../../reports/utils/buildAssetFilter";
import removeReportPrefix from "../../reports/utils/removeReportPrefix";
import NoReports from "../components/NoReports";
import PbiReportTab from "../components/PbiReportTab";

interface IUseReportsTabsOptions {
  assetId: string;
  assetType: string;
  pbiReports: Data<IReport[]>;
  user: IUser;
  loadPbiReports: (assetType: string) => void;
}

const useReportsRoutes = ({
  assetId,
  assetType,
  pbiReports,
  user,
  loadPbiReports
}: IUseReportsTabsOptions) => {
  const intl = useIntl();
  const { isAvailable } = useFeature();
  const navigate = useAppNavigate();

  const isAuthorized = useMemo(() => {
    return AuthorizationService.isAuthorized(user, [
      UserRoles.Administrator,
      UserRoles.Engineer,
      UserRoles.ViewerEngineer
    ]);
  }, [user]);

  const routes = useMemo<LayoutRoute[]>(() => {
    if (!isAuthorized) return [];

    return [
      isAvailable(VisualizationKeys.AssetDetailPage_Tabs_Parameters_Reports)
        ? {
            id: "Assets-DetailPage-Parameters-EmbeddedReports",
            route: "EmbeddedReports/*",
            to: {
              pathname: "/Assets/DetailPage/Parameters/EmbeddedReports",
              search: new SearchParams({ assetId }).toString()
            },
            displayName: intl.formatMessage({
              defaultMessage: "Embedded reports",
              id: "detail_page.widgets.embedded_reports"
            }),
            element: <PbiReportTab />,
            submenu:
              pbiReports?.status === Statuses.Loading ? (
                <Processing />
              ) : pbiReports?.status === Statuses.Succeeded &&
                !isNil(pbiReports?.data) &&
                !isEmpty(pbiReports?.data) ? (
                pbiReports?.data?.map((r) => ({
                  route: r.Id,
                  displayName: intl.formatMessage({
                    defaultMessage: removeReportPrefix(r.Name),
                    id: "reports." + r.Name
                  }),
                  onClick: () => {
                    const filters = buildAssetFilter(assetId);
                    const searchParams = new SearchParams({
                      assetId,
                      pbi_n: r.Name,
                      pbi_f: JSON.stringify(filters)
                    });

                    navigate({
                      pathname: `/Assets/DetailPage/Parameters/EmbeddedReports`,
                      search: searchParams.toString()
                    });
                  }
                }))
              ) : pbiReports?.status === Statuses.Failed ||
                pbiReports?.status === Statuses.NotFound ||
                (pbiReports?.status === Statuses.Succeeded &&
                  (isNil(pbiReports?.data) || isEmpty(pbiReports?.data))) ? (
                <NoReports />
              ) : undefined
          }
        : undefined
    ].filter(Boolean);
  }, [
    assetId,
    intl,
    isAuthorized,
    isAvailable,
    navigate,
    pbiReports?.data,
    pbiReports?.status
  ]);

  useEffect(() => {
    if (isAuthorized) loadPbiReports(assetType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorized, assetType]);

  return routes;
};

export default useReportsRoutes;
