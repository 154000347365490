// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";
import { IState } from "reducers/Index";

const getScatterPlotYDataSelector = createSelector(
  (state: IState) => state.familyAnalytics.scatterPlotYData,
  (scatterPlotYData) => scatterPlotYData
);

export default getScatterPlotYDataSelector;
