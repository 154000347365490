// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import AssetImplementationId from "features/detailpage/models/AssetImplementationId";
import EGasStandard from "../components/analytics/DuvalTriangle/models/EGasStandard";
import ERevision from "../components/analytics/DuvalTriangle/models/ERevision";

const getIsTransformerStandardModelEsters = (
  implementationId: string,
  gasStandard: EGasStandard,
  revision: ERevision
) =>
  implementationId?.toLowerCase() ===
    AssetImplementationId.AbbTransformerStandardModel &&
  gasStandard === EGasStandard.IEEE_ESTERS &&
  revision === ERevision.REV_2014;

export default getIsTransformerStandardModelEsters;
