// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import ChartGroup from "../models/ChartGroup";
import ChartType from "../models/ChartType";
import PhaseType from "../models/PhaseType";
import TransformerType from "../models/TransformerType";

export const chartToGroupMapper: { [key in ChartType]: ChartGroup } = {
  PercentagesHV: "Percentages",
  PercentagesXV: "Percentages",
  WarningCountsHV: "Counts",
  WarningCountsXV: "Counts",
  AlertCountsHV: "Counts",
  AlertCountsXV: "Counts",
  WarningDurationsHV: "Durations",
  WarningDurationsXV: "Durations",
  AlertDurationsHV: "Durations",
  AlertDurationsXV: "Durations"
};

export const chartToPhaseTypeGroupMapper: { [key in ChartType]: PhaseType } = {
  PercentagesHV: "HighVoltage",
  PercentagesXV: "LowVoltage",
  WarningCountsHV: "HighVoltage",
  WarningCountsXV: "LowVoltage",
  AlertCountsHV: "HighVoltage",
  AlertCountsXV: "LowVoltage",
  WarningDurationsHV: "HighVoltage",
  WarningDurationsXV: "LowVoltage",
  AlertDurationsHV: "HighVoltage",
  AlertDurationsXV: "LowVoltage"
};

export const chartUnit: { [key in ChartType]: string } = {
  PercentagesHV: "%",
  PercentagesXV: "%",
  WarningCountsHV: "",
  WarningCountsXV: "",
  AlertCountsHV: "",
  AlertCountsXV: "",
  WarningDurationsHV: "",
  WarningDurationsXV: "",
  AlertDurationsHV: "",
  AlertDurationsXV: ""
};

export const voltageStressSettings: {
  [key in TransformerType]: {
    [key in ChartGroup]: ChartType[];
  };
} = {
  Reactor: {
    Percentages: ["PercentagesHV"],
    Counts: ["WarningCountsHV", "AlertCountsHV"],
    Durations: ["WarningDurationsHV", "AlertDurationsHV"]
  },
  SinglePhaseTransformer: {
    Percentages: ["PercentagesHV", "PercentagesXV"],
    Counts: [
      "WarningCountsHV",
      "AlertCountsHV",
      "WarningCountsXV",
      "AlertCountsXV"
    ],
    Durations: [
      "WarningDurationsHV",
      "AlertDurationsHV",
      "WarningDurationsXV",
      "AlertDurationsXV"
    ]
  },
  ThreePhaseTransformer: {
    Percentages: ["PercentagesHV", "PercentagesXV"],
    Counts: [
      "WarningCountsHV",
      "AlertCountsHV",
      "WarningCountsXV",
      "AlertCountsXV"
    ],
    Durations: [
      "WarningDurationsHV",
      "AlertDurationsHV",
      "WarningDurationsXV",
      "AlertDurationsXV"
    ]
  }
};
