// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";
import { IState } from "reducers/Index";

import NotificationsService from "features/notifications/services/NotificationsService";

const getOrganizationsOptionsSelector = createSelector(
  (state: IState) => state.notifications.modelInput.data,
  data =>
    data && data.Organizations
      ? NotificationsService.convertIOrganizationsToITreeNode(
          data.Organizations
        )
      : []
);

export default getOrganizationsOptionsSelector;
