// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import PrognosticTrend, {
  ISeries,
  ITranslation
} from "@apm/widgets/build/widgets/PrognosticTrend";
import CenteredWrapper from "common/CenteredWrapper";
import { formatTwoDigitDateTime } from "common/DateTime/utils/dateFormatters";
import { NumericalValueService } from "common/formatters/NumericalValue";
import Processing from "components/common/Processing";
import { memo, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";

export interface IPrognosticChartProps {
  expanded: boolean;
  ready?: boolean;
  series?: ISeries[];
  prognosisSeries?: ISeries[];
  simulationSeries?: ISeries[];
}

const PrognosticChart = memo(
  ({
    expanded,
    simulationSeries = [],
    prognosisSeries = [],
    series = [],
    ready
  }: IPrognosticChartProps) => {
    const intl = useIntl();
    const formatValue = useCallback(
      (value: number) => {
        return NumericalValueService.formatWithoutRound(value, intl);
      },
      [intl]
    );

    const formatXTick = useCallback(
      (date: Date) => {
        return intl.formatDate(date);
      },
      [intl]
    );

    const formatTooltipTitle = useCallback(
      (date: Date) => formatTwoDigitDateTime(intl, date),
      [intl]
    );

    const translations = useMemo((): ITranslation => {
      return {
        calculation: intl.formatMessage({
          id: "detail_page.prognostic_trend.calculation",
          defaultMessage: "Calculation"
        }),
        today: intl.formatMessage({
          id: "detail_page.prognostic_trend.today",
          defaultMessage: "Today"
        }),
        checkAll: intl.formatMessage({
          id: "detail_page.risk_trend.prognostic_legend.select_all",
          defaultMessage: "Select all"
        }),
        expandButtonTooltip: intl.formatMessage({
          id: "global.chart.expand",
          defaultMessage: "Expand"
        }),
        empty: intl.formatMessage({
          id: "global.chart.no_data_available",
          defaultMessage: "No data available"
        }),
        simulation: intl.formatMessage({
          id: "detail_page.prognostic_trend.simulation",
          defaultMessage: "Simulation"
        })
      };
    }, [intl]);

    return ready ? (
      <PrognosticTrend
        expanded={expanded}
        series={series}
        prognosisSeries={prognosisSeries}
        simulationSeries={simulationSeries}
        formatValue={formatValue}
        formatXTick={formatXTick}
        formatTooltipTitle={formatTooltipTitle}
        translations={translations}
        xSplitNumber={4}
        height={190}
      />
    ) : (
      <CenteredWrapper>
        <Processing className="spinner small dark prognostic-grid-spinner" />
      </CenteredWrapper>
    );
  }
);

export default PrognosticChart;
