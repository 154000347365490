// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import $ from "jquery";

// Use this transport for "binary" data type
$.ajaxTransport("+binary", function (
  options: JQuery.AjaxSettings,
  originalOptions: JQuery.AjaxSettings,
  jqXHR: JQuery.jqXHR
): JQuery.Transport {
  // check for conditions and support for blob / arraybuffer response type
  if (
    (window as any).FormData &&
    ((options.dataType && options.dataType === "binary") ||
      (options.data &&
        (((window as any).ArrayBuffer && options.data instanceof ArrayBuffer) ||
          ((window as any).Blob && options.data instanceof Blob))))
  ) {
    return {
      // create new XMLHttpRequest
      send: function (
        headers: any,
        callback: (
          status: number,
          statusText: string,
          response: XMLHttpRequest,
          headers: string
        ) => void
      ) {
        // setup all variables
        const xhr = new XMLHttpRequest();
        const url = options.url;
        const type = options.type;
        const async = options.async || true;
        // blob or arraybuffer. Default is blob
        const dataType = (options as any).responseType || "blob";
        const data = options.data || null;
        const username = options.username || null;
        const password = options.password || null;

        xhr.addEventListener("load", function () {
          const response: any = {};
          response[options.dataType] = xhr.response;
          // make callback and send data
          callback(
            xhr.status,
            xhr.statusText,
            response,
            xhr.getAllResponseHeaders()
          );
        });

        xhr.open(type, url, async, username, password);

        // setup custom headers
        const keys = Object.keys(headers);
        for (const key of keys) {
          xhr.setRequestHeader(key, headers[key]);
        }

        xhr.responseType = dataType;
        xhr.send(data as any);
      },
      abort: function () {
        jqXHR.abort();
      }
    } as JQuery.Transport;
  } else {
    return null;
  }
});
