// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import { Typography } from "antd";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { colorStatusRed, colorStatusYellow } from "styles/ColorVariables";
import { spacingMedium } from "styles/StyleVariables";

interface IDateColumnProps {
  alarmType: "Caution" | "Problem";
  className?: string;
}

const AlarmTypeColumn = ({ alarmType, className }: IDateColumnProps) => {
  const alarmNameKey = useMemo(() => alarmType?.toLowerCase(), [alarmType]);

  return (
    <div className={className}>
      {alarmType === "Caution" ? (
        <Icon className={alarmNameKey} name="pg-status-committed" />
      ) : (
        <Icon className={alarmNameKey} name="report_problem" />
      )}
      <Typography.Text>
        <FormattedMessage
          defaultMessage={alarmType}
          id={`alarms.grid.columns.alarm-types.${alarmNameKey}`}
        />
      </Typography.Text>
    </div>
  );
};

const columnPadding = "10px";

export default styled(AlarmTypeColumn)`
  padding: ${columnPadding};
  display: flex;
  align-items: center;

  > * + * {
    margin-left: ${spacingMedium};
  }

  .caution {
    fill: ${colorStatusYellow};
  }

  .problem {
    color: ${colorStatusRed};
  }
`;
