// Copyright 2016-2024 Hitachi Energy. All rights reserved.

/* eslint-disable @typescript-eslint/no-restricted-imports */
import SearchParams from "@pg/common/build/models/SearchParams";
import { isNil } from "lodash";
import { useCallback, useContext, useEffect } from "react";
import { To, useLocation, useNavigate } from "react-router";
import RouterContext from "../contexts/RouterContext";
import parseUrl from "../utils/parseUrl";

export type NavigateOptions = { replace?: boolean; state?: any };

export type NavigateFn = (to: To | number, options?: NavigateOptions) => void;

const disableBingMapsSearchParam = "disableBingMaps";
const experimentalSearchParam = "experimental";
const localeSearchParam = "locale";

const useAppNavigate = () => {
  const {
    disableBingMaps,
    setDisableBingMaps,
    experimental,
    setExperimental,
    locale,
    setLocale,
    setHistoryLength
  } = useContext(RouterContext);

  const location = useLocation();
  const _navigate = useNavigate();

  const getSearch = useCallback(
    (search: string | undefined | null) => {
      const searchParams = new SearchParams(search || "");

      if (
        !isNil(disableBingMaps) &&
        !searchParams.has(disableBingMapsSearchParam)
      )
        searchParams.set(
          disableBingMapsSearchParam,
          disableBingMaps.toString()
        );

      if (!isNil(experimental) && !searchParams.has(experimentalSearchParam))
        searchParams.set(experimentalSearchParam, experimental.toString());

      if (!isNil(locale) && !searchParams.has(localeSearchParam))
        searchParams.set(localeSearchParam, locale);

      return searchParams.toString();
    },
    [disableBingMaps, experimental, locale]
  );

  const navigate = useCallback<NavigateFn>(
    (to: To | number, options?: NavigateOptions) => {
      if (typeof to === "number") {
        setHistoryLength((v) => v + to);
        _navigate(to);
      } else if (typeof to === "string") {
        const { pathname, search } = parseUrl(to);

        if (!options?.replace) setHistoryLength((v) => v + 1);

        _navigate(
          {
            pathname,
            search: getSearch(search)
          },
          options
        );
      } else {
        if (!options?.replace) setHistoryLength((v) => v + 1);

        _navigate(
          {
            ...to,
            search: getSearch(to.search)
          },
          options
        );
      }
    },
    [_navigate, getSearch, setHistoryLength]
  );

  useEffect(() => {
    const searchParams = new SearchParams(location.search);

    if (searchParams.has(disableBingMapsSearchParam)) {
      const value = searchParams.get(disableBingMapsSearchParam) === "true";
      if (value !== disableBingMaps) setDisableBingMaps(value);
    }

    if (searchParams.has(experimentalSearchParam)) {
      const value = searchParams.get(experimentalSearchParam) === "true";
      if (value !== experimental) setExperimental(value);
    }

    if (searchParams.has(localeSearchParam)) {
      const value = searchParams.get(localeSearchParam);
      if (value !== locale) setLocale(value);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return navigate;
};

export default useAppNavigate;
