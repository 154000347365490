// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { SelectedFilters } from "common/FilterBar";
import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { config } from "utils/AppConfig";
import { AlarmsTypeSummary } from "../models/AlarmsTypeSummary";

interface AlarmsTypeSummaryPayload {
  search: SelectedFilters["search"];
  filters: SelectedFilters["selects"];
  ranges: SelectedFilters["ranges"];
}

const alarmsTypeSummaryRead = (
  payload: AlarmsTypeSummaryPayload
): Promise<AlarmsTypeSummary> =>
  new Promise((resolve, reject) => {
    const url = UrlService.getApiUrl(config.api.alarms.count);
    EndpointService.post(
      url,
      resolve,
      reject,
      payload,
      "application/json; charset=utf-8"
    );
  });

export { alarmsTypeSummaryRead };
