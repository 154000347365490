// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { IAjaxAction } from "reducers/Index";
import { Action } from "redux";
import { AppDispatch } from "store";
import { config } from "utils/AppConfig";
import ISubfactorScore from "../models/ISubfactorScore";

export enum Actions {
  DetailPage_Subfactors_SetAssetSubfactors = "DetailPage_Subfactors_SetAssetSubfactors",
  DetailPage_Subfactors_ClearAssetSubfactors = "DetailPage_Subfactors_ClearAssetSubfactors"
}

export const setAssetSubfactors = (
  request: JQuery.jqXHR,
  data?: Array<ISubfactorScore>
): IAjaxAction<Array<ISubfactorScore>> => ({
  request,
  data,
  type: Actions.DetailPage_Subfactors_SetAssetSubfactors
});

export const clearAssetSubfactors = (): Action<string> => ({
  type: Actions.DetailPage_Subfactors_ClearAssetSubfactors
});

export const loadAssetSubfactors = (assetId: string) => {
  return (dispatch: AppDispatch) => {
    return new Promise<void>((resolve, reject) => {
      const url = UrlService.getApiUrl(
        config.api.detailPage.assetSubfactorsUrl,
        [
          {
            name: "assetId",
            value: assetId
          }
        ]
      );

      const request = EndpointService.getJson<Array<ISubfactorScore>>(
        url,
        (request, data) => {
          dispatch(setAssetSubfactors(request, data));
          resolve();
        },
        (request) => {
          dispatch(setAssetSubfactors(request));
          reject();
        }
      );

      dispatch(setAssetSubfactors(request));
    });
  };
};
