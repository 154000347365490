// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { useMemo } from "react";
import { FormattedMessage } from "react-intl";

import DataGrid, {
  IAction,
  IActionComponentProps,
  IColumnConfig,
  IRow,
  IRowData
} from "common/datagrid/DataGrid";
import SortOrders from "common/datagrid/models/SortOrders";
import Processing from "components/common/Processing";
import {
  WidgetErrorMessage,
  WidgetNoDataMessage
} from "components/common/widget/Widget";
import Data, { Statuses } from "core/data/models/Data";
import IAssetDetails from "models/IAssetDetails";
import IParameterInfo from "../../../models/IParameterInfo";
import IParameterInfoSummary from "../../../models/IParameterInfoSummary";
import useParametersGridSucceeded from "../hooks/useParametersGridSucceeded";
import compareByValue from "../utils/compareByValue";
import showFamilyInfo from "../utils/showFamilyInfo";
import showHistogram from "../utils/showHistogram";
import ActionFamilyInfo from "./ActionFamilyInfo";
import ActionHistogram from "./ActionHistogram";
import ColumnFactors from "./ColumnFactors";
import ColumnLastUpdate from "./ColumnLastUpdate";
import ColumnLastValue from "./ColumnLastValue";
import ColumnName from "./ColumnName";
import ColumnPreviousUpdate from "./ColumnPreviousUpdate";
import ColumnPreviousValue from "./ColumnPreviousValue";
import ColumnUnit from "./ColumnUnit";
import ExportToFile from "./ExportToFile";
import GridFooter from "./GridFooter";
import ParametersSearch from "./ParametersSearch";

import "./ParametersGrid.less";

interface IParametersGridProps {
  assetId: string;
  parameters: Data<IParameterInfoSummary>;
  assetDetails: IAssetDetails;
  onSelectionChanged?: (
    affectedRow: IRow<IParameterInfo>,
    selectedRows: IRow<IParameterInfo>[]
  ) => void;
  onSelectionCleared?: () => void;
  getDefaultRowSelected?: (data: IRowData) => boolean;
}

const ParametersGrid = ({
  assetId,
  parameters,
  assetDetails,
  onSelectionChanged,
  onSelectionCleared,
  getDefaultRowSelected
}: IParametersGridProps) => {
  return (
    <div className="parameters-list">
      {parameters?.status === Statuses.Loading && <ParametersGridLoading />}
      {parameters?.status === Statuses.Succeeded && (
        <ParametersGridSucceeded
          assetId={assetId}
          parameters={parameters}
          assetDetails={assetDetails}
          onSelectionChanged={onSelectionChanged}
          onSelectionCleared={onSelectionCleared}
          getDefaultRowSelected={getDefaultRowSelected}
        />
      )}
      {parameters?.status === Statuses.Failed && <ParametersGridErrorMessage />}
      {parameters?.status === Statuses.NotFound && <ParametersGridNoData />}
    </div>
  );
};

interface IParametersGridSucceededProps {
  assetId: string;
  parameters: Data<IParameterInfoSummary>;
  assetDetails: IAssetDetails;
  onSelectionChanged?: (
    affectedRow: IRow<IParameterInfo>,
    selectedRows: IRow<IParameterInfo>[]
  ) => void;
  onSelectionCleared?: () => void;
  getDefaultRowSelected?: (data: IRowData) => boolean;
}

const ParametersGridLoading = () => <Processing />;

const ParametersGridSucceeded = ({
  assetId,
  parameters,
  assetDetails,
  onSelectionChanged,
  onSelectionCleared,
  getDefaultRowSelected
}: IParametersGridSucceededProps) => {
  const {
    search,
    filterRows,
    handleSearch,
    handleSelectableRow,
    handleClear,
    gridKey
  } = useParametersGridSucceeded(onSelectionCleared);
  const columns = useMemo<IColumnConfig[]>(
    () => [
      {
        id: "Factors",
        component: ColumnFactors,
        frozen: true,
        message: {
          id: "detail_page.widgets.parameters_list.table.header_factor_name",
          defaultMessage: "Factor"
        },
        sortable: false,
        weight: 1.5
      },
      {
        id: "TranslatedName",
        compareFunction: (a: IParameterInfo, b: IParameterInfo) =>
          a.TranslatedName.toLowerCase().localeCompare(
            b.TranslatedName.toLowerCase()
          ),
        component: ColumnName,
        frozen: true,
        message: {
          id: "detail_page.widgets.parameters_list.table.header_name",
          defaultMessage: "Name"
        },
        weight: 5
      },
      {
        id: "Unit",
        compareFunction: (a: IParameterInfo, b: IParameterInfo) =>
          (a.Unit || "")
            .toLowerCase()
            .localeCompare((b.Unit || "").toLowerCase()),
        component: ColumnUnit,
        defaultSortOrder: SortOrders.Asc,
        defaultGroupOrder: 2,
        frozen: true,
        HeaderTooltip: () => (
          <FormattedMessage
            defaultMessage="Unit of measurement"
            id="detail_page.widgets.parameters_list.table.header_unit.tooltip"
          />
        ),
        message: {
          id: "detail_page.widgets.parameters_list.table.header_unit",
          defaultMessage: "Unit"
        }
      },
      {
        id: "PreviousUpdate",
        compareFunction: (a: IParameterInfo, b: IParameterInfo) =>
          (Date.parse(a.PreviousUpdate) || 0) -
          (Date.parse(b.PreviousUpdate) || 0),
        component: ColumnPreviousUpdate,
        frozen: true,
        HeaderTooltip: () => (
          <FormattedMessage
            defaultMessage="Previous measurement"
            id="detail_page.widgets.parameters_list.table.header_previous_measurement.tooltip"
          />
        ),
        message: {
          id: "detail_page.widgets.parameters_list.table.header_previous_measurement",
          defaultMessage: "Previous date"
        },
        weight: 1.5
      },
      {
        id: "PreviousValue",
        compareFunctionWithSortOrder: (
          a: IParameterInfo,
          b: IParameterInfo,
          order: SortOrders
        ) => compareByValue(a.PreviousValue, b.PreviousValue, order),
        component: ColumnPreviousValue,
        HeaderTooltip: () => (
          <FormattedMessage
            defaultMessage="Previous value"
            id="detail_page.widgets.parameters_list.table.header_previous_value.tooltip"
          />
        ),
        message: {
          id: "detail_page.widgets.parameters_list.table.header_previous_value",
          defaultMessage: "Previous value"
        }
      },
      {
        id: "LastUpdate",
        compareFunction: (a: IParameterInfo, b: IParameterInfo) =>
          (Date.parse(a.LastUpdate) || 0) - (Date.parse(b.LastUpdate) || 0),
        component: ColumnLastUpdate,
        defaultGroupOrder: 1,
        defaultSortOrder: SortOrders.Desc,
        frozen: true,
        HeaderTooltip: () => (
          <FormattedMessage
            defaultMessage="Current measurement"
            id="detail_page.widgets.parameters_list.table.header_current_measurement.tooltip"
          />
        ),
        message: {
          id: "detail_page.widgets.parameters_list.table.header_current_measurement",
          defaultMessage: "Current date"
        },
        weight: 1.5
      },
      {
        id: "LastValue",
        compareFunctionWithSortOrder: (
          a: IParameterInfo,
          b: IParameterInfo,
          order: SortOrders
        ) => compareByValue(a.LastValue, b.LastValue, order),
        component: ColumnLastValue,
        HeaderTooltip: () => (
          <FormattedMessage
            defaultMessage="Current value"
            id="detail_page.widgets.parameters_list.table.header_current_value.tooltip"
          />
        ),
        message: {
          id: "detail_page.widgets.parameters_list.table.header_current_value",
          defaultMessage: "Current value"
        }
      }
    ],
    []
  );

  const actions = useMemo<IAction[]>(
    () => [
      {
        Component: ({ row }: IActionComponentProps<IParameterInfo>) => {
          let parameterName =
            row.data.ParameterSource === "DerivativeParameter"
              ? row.data.Name
              : row.data.ExternalName;
          return (
            <ActionFamilyInfo
              assetId={assetId}
              parameterName={row && parameterName}
              translatedParameterName={row && row.data.TranslatedName}
              parameterSource={row.data.ParameterSource}
            />
          );
        },
        display: (row: IRow<IParameterInfo>) => {
          return showFamilyInfo(
            row,
            assetDetails?.IsInFamily,
            assetDetails?.NameplateWithModelInfo.LifecycleStatus
          );
        },
        id: "FamilyInfo"
      },
      {
        Component: ({ row }: IActionComponentProps<IParameterInfo>) => (
          <ActionHistogram
            assetId={assetId}
            parameterName={row && row.data.Name}
            parameterSource={row.data.ParameterSource}
            translatedParameterName={row && row.data.TranslatedName}
            unit={row && row.data.Unit}
          />
        ),
        display: (row: IRow<IParameterInfo>) => showHistogram(row),
        id: "ParametersHistogram"
      }
    ],
    [assetDetails, assetId]
  );

  return (
    <>
      <div className="parameters-actions">
        <ParametersSearch
          onSearch={handleSearch}
          onClear={handleClear}
          defaultSearch={search}
        />
        {parameters?.data.Parameters.length > 0 && (
          <ExportToFile
            assetId={assetId}
            canExportToExcel={parameters?.data.CanExportToExcel}
          />
        )}
      </div>
      <div className="parameters-grid data-grid__parent ">
        <div className="data-grid__scroll">
          <DataGrid
            key={gridKey}
            actions={actions}
            columns={columns}
            data={parameters?.data.Parameters}
            filterRows={filterRows}
            selectableRow={handleSelectableRow}
            onSelectionChanged={onSelectionChanged}
            getDefaultRowSelected={getDefaultRowSelected}
            footerComponent={(
              rows: IRow<IParameterInfo>[],
              rowsTotal: number
            ) => (
              <GridFooter
                filteredParamsLength={rows?.length || 0}
                allParamsLength={rowsTotal}
              />
            )}
          />
        </div>
      </div>
    </>
  );
};

const ParametersGridErrorMessage = () => {
  return (
    <WidgetErrorMessage
      messageId="detail_page.parameters_tab.parameters_grid.getting_data_failed"
      messageDefault="Getting data failed"
    />
  );
};

const ParametersGridNoData = () => {
  return <WidgetNoDataMessage />;
};

export default ParametersGrid;
