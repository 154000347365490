import { SearchParams } from "@pg/common";
import {
  VisualizationKeys,
  useFeature
} from "core/app/components/FeatureToggle";
import { LayoutRoute } from "core/app/components/RouterProvider";
import LifecycleStatus from "models/LifecycleStatus";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import AuxiliariesTab from "../../AuxiliariesTab";
import LogTab from "../../LogTab";
import MechanicalTab from "../../MechanicalTab";
import OperationsTab from "../../OperationsTab";
import WearTab from "../../WearTab";
import DielectricSF6Tab from "../../dielectric/components/DielectricSF6Tab";

interface IUseCircuitBreakerConditionMonitoringRoutesProps {
  modelId: string;
  implementationId: string;
  assetId: string;
  lifecycleStatus: LifecycleStatus;
}

const useCircuitBreakerConditionMonitoringRoutes = ({
  modelId,
  implementationId,
  assetId,
  lifecycleStatus
}: IUseCircuitBreakerConditionMonitoringRoutesProps) => {
  const { isAvailable } = useFeature();
  const intl = useIntl();
  const model = useMemo(
    () => ({ modelId, implementationId }),
    [implementationId, modelId]
  );
  const searchParams = useMemo(() => new SearchParams({ assetId }), [assetId]);

  const routes = useMemo<LayoutRoute[]>(() => {
    return [
      isAvailable(
        VisualizationKeys.AssetDetailPage_Tabs_Parameters_Dielectric_Parameters
      )
        ? {
            id: "Assets-DetailPage-Parameters-DielectricParameters",
            route: "DielectricParameters/*",
            displayName: intl.formatMessage({
              defaultMessage: "Dielectric",
              id: "detail_page.widgets.circuit_breaker.dielectric_parameters.tab_title"
            }),
            to: {
              pathname: "/Assets/DetailPage/Parameters/DielectricParameters",
              search: searchParams.toString()
            },
            element: (
              <DielectricSF6Tab
                assetId={assetId}
                lifecycleStatus={lifecycleStatus}
                model={model}
              />
            )
          }
        : undefined,
      isAvailable(VisualizationKeys.AssetDetailPage_Tabs_Parameters_Mechanical)
        ? {
            id: "Assets-DetailPage-Parameters-Mechanical",
            route: "Mechanical/*",
            displayName: intl.formatMessage({
              defaultMessage: "Mechanical",
              id: "detail_page.widgets.circuit_breaker.mechanical.tab_title"
            }),
            to: {
              pathname: "/Assets/DetailPage/Parameters/Mechanical",
              search: searchParams.toString()
            },
            element: (
              <MechanicalTab
                assetId={assetId}
                lifecycleStatus={lifecycleStatus}
              />
            )
          }
        : undefined,
      isAvailable(VisualizationKeys.AssetDetailPage_Tabs_Parameters_Wear)
        ? {
            id: "Assets-DetailPage-Parameters-Wear",
            route: "Wear/*",
            displayName: intl.formatMessage({
              defaultMessage: "Wear",
              id: "detail_page.widgets.circuit_breaker.wear.tab_title"
            }),
            to: {
              pathname: "/Assets/DetailPage/Parameters/Wear",
              search: searchParams.toString()
            },
            element: (
              <WearTab assetId={assetId} lifecycleStatus={lifecycleStatus} />
            )
          }
        : undefined,
      isAvailable(VisualizationKeys.AssetDetailPage_Tabs_Parameters_Auxiliaries)
        ? {
            id: "Assets-DetailPage-Parameters-Auxiliaries",
            route: "Auxiliaries/*",
            displayName: intl.formatMessage({
              defaultMessage: "Auxiliaries",
              id: "detail_page.widgets.circuit_breaker.auxiliaries.tab_title"
            }),
            to: {
              pathname: "/Assets/DetailPage/Parameters/Auxiliaries",
              search: searchParams.toString()
            },
            element: (
              <AuxiliariesTab
                assetId={assetId}
                lifecycleStatus={lifecycleStatus}
              />
            )
          }
        : undefined,
      isAvailable(VisualizationKeys.AssetDetailPage_Tabs_Parameters_Operations)
        ? {
            id: "Assets-DetailPage-Parameters-Operations",
            route: "Operations/*",
            displayName: intl.formatMessage({
              defaultMessage: "Operations",
              id: "detail_page.widgets.circuit_breaker.operations.tab_title"
            }),
            to: {
              pathname: "/Assets/DetailPage/Parameters/Operations",
              search: searchParams.toString()
            },
            element: <OperationsTab assetId={assetId} />
          }
        : undefined,
      isAvailable(VisualizationKeys.AssetDetailPage_Tabs_Parameters_Log)
        ? {
            id: "Assets-DetailPage-Parameters-Log",
            route: "Log/*",
            displayName: intl.formatMessage({
              defaultMessage: "Log",
              id: "detail_page.widgets.circuit_breaker.log.tab_title"
            }),
            to: {
              pathname: "/Assets/DetailPage/Parameters/Log",
              search: searchParams.toString()
            },
            element: <LogTab assetId={assetId} />
          }
        : undefined
    ].filter(Boolean);
  }, [assetId, intl, isAvailable, lifecycleStatus, model, searchParams]);

  return routes;
};
export default useCircuitBreakerConditionMonitoringRoutes;
