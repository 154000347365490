// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Processing from "components/common/Processing";
import {
  WidgetErrorMessage,
  WidgetNoDataMessage
} from "components/common/widget/Widget";
import { RouteNotFound } from "core/app/components/RouterProvider";
import Data, { Statuses } from "core/data/models/Data";
import useUrlSearchParams from "hooks/useUrlSearchParams";
import { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import { AppDispatch } from "store";
import styled from "styled-components";
import { spacingMedium } from "styles/StyleVariables";
import loadEvents from "../actions/loadEvents";
import getEventsNamesSelector from "../selectors/getEventsNamesSelector";
import OperationsBody from "./OperationsBody";
import OperationsEventDropdown from "./OperationsEventDropdown";

const eventSearchKey = "o_event";

interface IOperationsTabStateProps {
  events: Data<string[]>;
}

interface IOperationsTabActionProps {
  loadEvents: (assetId: string) => void;
}
interface IOperationsTabOwnProps {
  assetId: string;
  className?: string;
}

type OperationsTabProps = IOperationsTabOwnProps &
  IOperationsTabStateProps &
  IOperationsTabActionProps;

const OperationsTab = ({
  assetId,
  className,
  events,
  loadEvents
}: OperationsTabProps) => {
  const { getUrlParam, setUrlParam } = useUrlSearchParams();
  const [event, setEvent] = useState<string>(() => {
    const eventFromUrl = getUrlParam(eventSearchKey);
    if (eventFromUrl) {
      return eventFromUrl;
    }
    return "";
  });

  const responseText = useMemo(
    () => (events?.responseText ? JSON.parse(events.responseText) : undefined),
    [events]
  );

  const handleOnEventChange = useCallback(
    (value: string) => {
      setUrlParam(eventSearchKey, value);
      setEvent(value);
    },
    [setUrlParam]
  );

  useEffect(() => {
    loadEvents(assetId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId]);

  useEffect(() => {
    if (events?.status === Statuses.Succeeded && event === "") {
      const value = events?.data[0];
      setUrlParam(eventSearchKey, value);
      setEvent(value);
    }
  }, [event, events, setUrlParam]);

  return (
    <div className={className}>
      {events?.status === Statuses.Loading && <Processing />}
      {events?.status === Statuses.Succeeded && (
        <>
          <div className="event-dropdown">
            <OperationsEventDropdown
              onChange={handleOnEventChange}
              event={event}
              events={events?.data || []}
            />
          </div>
          {event && <OperationsBody assetId={assetId} event={event} />}
        </>
      )}
      {events?.status === Statuses.NotFound && <WidgetNoDataMessage />}
      {events?.status === Statuses.Failed && (
        <WidgetErrorMessage
          messageId={`detail_page.widgets.circuit_breaker.operations.${
            responseText || "UNKNOWN_ERROR"
          }`}
          messageDefault={responseText}
        />
      )}
      {events?.status === Statuses.Forbidden && <RouteNotFound />}
    </div>
  );
};

const StyledOperationsTab = styled(OperationsTab)`
  .event-dropdown {
    padding: ${spacingMedium};
  }
  .route-not-found {
    height: inherit;
  }
`;

const mapStateToProps = (
  state: IState,
  { assetId }: IOperationsTabOwnProps
): IOperationsTabStateProps => ({
  events: getEventsNamesSelector(state)(assetId)
});

const mapDispatchToProps = (
  dispatch: AppDispatch
): IOperationsTabActionProps => ({
  loadEvents: (assetId: string) => dispatch(loadEvents(assetId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StyledOperationsTab);
