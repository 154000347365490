// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import FilterBar, { SelectedFilters } from "common/FilterBar";
import { useState } from "react";
import styled from "styled-components";
import ComponentRiskSummaryGrid from "./ComponentRiskSummaryGrid/components/ComponentRiskSummaryGrid";

interface IComponentRiskSummaryProps {
  className?: string;
}

const ComponentRiskSummary = ({ className }: IComponentRiskSummaryProps) => {
  const [filters, setFilters] = useState<SelectedFilters>();

  return (
    <div className={className}>
      <FilterBar
        onFilter={setFilters}
        namespace="componentRiskSummary"
        useAssetOrganizationWithRegionAndLocation
        useAssetCompanyHierarchy
        useAssetTypeWithSubType
        useAssetImportance
      >
        {filters && <ComponentRiskSummaryGrid filters={filters} />}
      </FilterBar>
    </div>
  );
};

export default styled(ComponentRiskSummary)`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;

  > div:first-of-type {
    flex-shrink: 0;
  }
`;
