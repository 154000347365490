// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import AssetRiskCountsWidget from "@apm/widgets/build/widgets/AssetRiskCounts";
import { SelectedFilters } from "common/FilterBar";
import Processing from "components/common/Processing";
import { Statuses } from "core/data/models/Data";
import AssetRiskTypes from "models/AssetRiskTypes";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { AppDispatch } from "store";
import loadAssetRiskCounts from "../actions/loadAssetRiskCounts";
import removeAssetRiskCounts from "../actions/removeAssetRiskCounts";
import useAssetRiskCounts from "../hooks/useAssetRiskCounts";

interface IAssetRiskCountsActions {
  loadAssetRiskCounts: (
    type: AssetRiskTypes,
    filters: SelectedFilters,
    id?: string[]
  ) => void;
  removeAssetRiskCounts: (type: AssetRiskTypes, id?: string[]) => void;
}

interface IAssetRiskCountsState {}

interface IAssetRiskCountsOwnProps {
  className?: string;
  displayType?: string;
  filters: SelectedFilters;
  formatGroupName?: (groupKey: string, groupName: string) => string;
  type: AssetRiskTypes;
  dataQa?: string;
}

type AssetRiskCountsProps = IAssetRiskCountsActions &
  IAssetRiskCountsState &
  IAssetRiskCountsOwnProps;

const AssetRiskCounts = ({
  className,
  displayType,
  filters,
  formatGroupName,
  loadAssetRiskCounts,
  removeAssetRiskCounts,
  type,
  dataQa
}: AssetRiskCountsProps) => {
  const intl = useIntl();

  const {
    assetRiskJellyBeans,
    containerRef,
    handleCollapse,
    handleExpand,
    status
  } = useAssetRiskCounts(
    filters,
    type,
    loadAssetRiskCounts,
    removeAssetRiskCounts,
    formatGroupName
  );

  return (
    <div className={className} ref={containerRef} data-qa={dataQa}>
      {status === Statuses.Loading && <Processing />}
      {status === Statuses.Succeeded && (
        <AssetRiskCountsWidget
          name={displayType || type}
          formatValue={(value) => intl.formatNumber(value)}
          onCollapse={handleCollapse}
          onExpand={handleExpand}
          assetRiskJellyBeans={assetRiskJellyBeans}
        />
      )}
      {status === Statuses.Failed && <span>Failed</span>}
    </div>
  );
};

export default connect(
  (): IAssetRiskCountsState => ({}),
  (dispatch: AppDispatch): IAssetRiskCountsActions => ({
    loadAssetRiskCounts: (
      type: AssetRiskTypes,
      filters: SelectedFilters,
      id?: string[]
    ) => dispatch(loadAssetRiskCounts(type, filters, id)),
    removeAssetRiskCounts: (type: AssetRiskTypes, id?: string[]) =>
      dispatch(removeAssetRiskCounts(type, id))
  })
)(AssetRiskCounts);
