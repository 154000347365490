// Copyright 2016-2024 Hitachi Energy. All rights reserved.

const localeString = "locale=";

export function getLocaleSubstring(url: string) {
  const localeStart = url.search(localeString);
  const searchAfterLocales = url.substring(localeStart);
  const currentLocales = searchAfterLocales.substring(
    0,
    searchAfterLocales.search("&") > 0
      ? searchAfterLocales.search("&")
      : searchAfterLocales.length
  );
  return currentLocales;
}

export default function checkSearchLocales(location: { search: string }) {
  const prevHrefString = "prevHref";
  if (!location.search.includes(localeString)) {
    localStorage.setItem(prevHrefString, "");
  }

  const prevHref = localStorage.getItem(prevHrefString);

  const localeSearchSubstring = getLocaleSubstring(location.search);
  const localePrevHrefSubstring = getLocaleSubstring(prevHref);

  if (
    location.search.includes(localeString) &&
    localeSearchSubstring !== localePrevHrefSubstring
  ) {
    localStorage.setItem("prevHref", window.location.href);
    window.location.reload();
  }
}
