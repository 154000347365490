// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { ParameterService } from "common/formatters/ParameterName";
import { SubscoreService } from "common/formatters/SubscoreName";
import Data from "core/data/models/Data";
import { IntlShape } from "react-intl";
import { IState } from "reducers/Index";
import { createSelector } from "reselect";
import { getDataKey } from "../actions/loadParameters";
import IParameterInfoSummary from "../models/IParameterInfoSummary";
import IScoreFactor from "../models/IScoreFactor";

const getParametersSelector = createSelector(
  (state: IState) =>
    (
      assetId: string,
      modelId: string,
      implementationId: string,
      intl: IntlShape
    ) => {
      const parameters: Data<IParameterInfoSummary> =
        state.data[getDataKey(assetId)];

      if (parameters?.data) {
        parameters.data.Parameters.forEach((p) => {
          if (!p.TranslatedName)
            p.TranslatedName = ParameterService.format(
              p.Name,
              modelId,
              implementationId,
              intl,
              p.ParameterSource
            );

          translateFactors(p.Factors, modelId, implementationId, intl);

          if (!p.FactorsKeywords)
            p.FactorsKeywords = p.Factors?.map((f) => getKeywords(f)).join();
        });
      }

      return parameters;
    },
  (parameters) => parameters
);

const getKeywords = (factor: IScoreFactor): string => {
  const subfactorsKeywords = factor.Subfactors
    ? factor.Subfactors.map((s) => getKeywords(s)).join()
    : "";

  return [factor.TranslatedName, subfactorsKeywords].join();
};

const translateFactors = (
  factors: IScoreFactor[],
  modelId: string,
  implementationId: string,
  intl: IntlShape
) => {
  factors?.forEach((f) => {
    f.TranslatedName = SubscoreService.format(
      f.Name,
      modelId,
      implementationId,
      intl
    );
    translateFactors(f.Subfactors, modelId, implementationId, intl);
  });
};

export default getParametersSelector;
