// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Switch, { ISwitchItem } from "common/form/components/Switch";

export enum ViewType {
  All = "all",
  WatchList = "watchList"
}

const viewItems: ISwitchItem[] = [
  {
    id: ViewType.All,
    message: {
      id: "asset_query.view.all",
      defaultMessage: "All"
    }
  },
  {
    id: ViewType.WatchList,
    message: {
      id: "asset_query.view.watch_list",
      defaultMessage: "Watch List"
    }
  }
];

interface IAssetViewSwitchProps {
  viewType: ViewType;
  onViewChange?: (view: ViewType) => void;
}

const AssetViewSwitch = ({ viewType, onViewChange }: IAssetViewSwitchProps) => {
  return (
    <Switch
      defaultSelected={viewType}
      className="asset-view-type-switch"
      items={viewItems}
      onChange={onViewChange}
    />
  );
};

export default AssetViewSwitch;
