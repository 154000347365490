// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { FilterNames } from "@pg/common/build/components/FilterPanel";
import { SelectedFilters } from "common/FilterBar";
import loadData from "core/data/actions/loadData";
import UrlService from "core/data/services/UrlService";
import { isNil } from "lodash";
import AssetRiskTypes from "models/AssetRiskTypes";
import { config } from "utils/AppConfig";
import setupFilter from "utils/setupFilter";

export const dataKey = (type: AssetRiskTypes, id?: string[]) =>
  `AssetRiskDashboard_AssetRiskCounts-${type}${id ? `-${id.join("-")}` : ""}`;

const loadAssetRiskCounts = (
  type: AssetRiskTypes,
  filters: SelectedFilters,
  id?: string[]
) => {
  const url = UrlService.getApiUrl(
    config.api.assetRisk.assetRiskVisualization,
    undefined,
    { type }
  );

  if (!isNil(id)) {
    const filterName: FilterNames =
      type === "RiskByAgeRange"
        ? "AssetAge"
        : type === "RiskByAssetType"
        ? "AssetTypeWithSubType"
        : type === "RiskByCompanyHierarchy"
        ? "CompanyHierarchy"
        : type === "RiskByOrganization"
        ? "AssetOrganizationWithRegionAndLocation"
        : type === "RiskByVoltageClass"
        ? "AssetVoltage"
        : undefined;

    filters = setupFilter(filters, filterName, id);
  }

  return loadData({
    key: dataKey(type, id),
    url,
    method: "post",
    content: {
      search: filters.search,
      filters: filters.selects,
      level: !isNil(id) ? id.length : undefined
    }
  });
};

export default loadAssetRiskCounts;
