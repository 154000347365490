// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import SearchParams from "@pg/common/build/models/SearchParams";
import {
  useFeature,
  VisualizationKeys
} from "core/app/components/FeatureToggle";
import { LayoutRoute } from "core/app/components/RouterProvider";
import BatteryConductanceTab from "features/detailpage/features/batteryconductance/components/BatteryConductanceTab";
import BatteryVoltageTab from "features/detailpage/features/batteryvoltage/components/BatteryVoltageTab";
import { useMemo } from "react";
import { useIntl } from "react-intl";

interface IUseBatteryRoutesProps {
  assetId: string;
}

const useBatteryRoutes = ({ assetId }: IUseBatteryRoutesProps) => {
  const { isAvailable } = useFeature();
  const intl = useIntl();
  const searchParams = useMemo(() => new SearchParams({ assetId }), [assetId]);

  const routes = useMemo<LayoutRoute[]>(() => {
    return [
      isAvailable(
        VisualizationKeys.AssetDetailPage_Tabs_Parameters_Battery_Conductance
      )
        ? {
            id: "Assets-DetailPage-Parameters-Conductance",
            route: "Conductance/*",
            displayName: intl.formatMessage({
              id: "detail_page.widgets.conductance",
              defaultMessage: "Conductance"
            }),
            to: {
              pathname: "/Assets/DetailPage/Parameters/Conductance",
              search: searchParams.toString()
            },
            element: <BatteryConductanceTab assetId={assetId} />
          }
        : undefined,
      isAvailable(
        VisualizationKeys.AssetDetailPage_Tabs_Parameters_Battery_Voltage
      )
        ? {
            id: "Assets-DetailPage-Parameters-Voltage",
            route: "Voltage/*",
            displayName: intl.formatMessage({
              id: "detail_page.widgets.voltage",
              defaultMessage: "Voltage"
            }),
            to: {
              pathname: "/Assets/DetailPage/Parameters/Voltage",
              search: searchParams.toString()
            },
            element: <BatteryVoltageTab assetId={assetId} />
          }
        : undefined
    ].filter(Boolean);
  }, [assetId, intl, isAvailable, searchParams]);

  return routes;
};

export default useBatteryRoutes;
