// Copyright 2016-2024 Hitachi Energy. All rights reserved.

enum FormTypes {
  Nameplate = "nameplate",
  Inspection = "inspection",
  TabbedInspection = "tabbedInspection",
  CustomParameters = "customParameters",
  ConnectedDevices = "connectedDevices"
}

export default FormTypes;
