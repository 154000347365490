// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { IState } from "reducers/Index";

export const getLinkedReportsSelector = createSelector(
  (state: IState) => state.detailPage.reports.linkedReports,
  linkedReports => linkedReports
);

export default getLinkedReportsSelector;
