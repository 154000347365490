// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import ColumnRisk from "common/columns/Risk";
import { IColumnConfig } from "common/datagrid/DataGrid";
import SortOrders from "common/datagrid/models/SortOrders";
import ComponentColumn from "../components/ComponentColumn";
import ParentColumn from "../components/ParentColumn";

const gridColumnsConfig: IColumnConfig[] = [
  {
    component: (value) => <ComponentColumn componentId={value} />,
    id: "ComponentId",
    message: {
      defaultMessage: "Component",
      id: "components_page.grid.columns.component"
    },
    defaultGroupOrder: 2,
    defaultSortOrder: SortOrders.Asc,
    weight: 6
  },
  {
    id: "ComponentType",
    message: {
      defaultMessage: "Component Type",
      id: "components_page.grid.columns.component_type"
    },
    weight: 5
  },
  {
    component: (value) => <ColumnRisk risk={value} />,
    id: "ComponentRisk",
    frozen: true,
    message: {
      defaultMessage: "Component Risk",
      id: "components_page.grid.columns.component_risk"
    },
    defaultGroupOrder: 1,
    defaultSortOrder: SortOrders.Desc,
    weight: 2
  },
  {
    id: "ComponentManufacturer",
    message: {
      defaultMessage: "Component Manufacturer",
      id: "components_page.grid.columns.component_manufacturer"
    },
    weight: 3
  },
  {
    component: (_, row) => (
      <ParentColumn
        assetId={row.data["ParentId"]}
        assetName={row.data["ParentName"]}
        assetType={row.data["ParentType"]}
        location={row.data["ParentLocation"]}
      />
    ),
    id: "ParentAssetId",
    message: {
      defaultMessage: "Asset",
      id: "components_page.grid.columns.asset"
    },
    weight: 6
  }
];

export default gridColumnsConfig;