// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { IState } from "reducers/Index";

const getScoreCalculationSelector = createSelector(
  (state: IState) => state.detailPage.replacementScore.scoreCalculation,
  scoreCalculation => scoreCalculation
);

export default getScoreCalculationSelector;
