// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import UrlService from "core/data/services/UrlService";
import ParameterSources from "models/ParameterSources";
import { config } from "utils/AppConfig";

const getParameterUrl = (
  assetId: string,
  parameterName: string,
  parameterSource: ParameterSources
) => {
  if (parameterSource === "DerivativeParameter")
    return UrlService.getApiUrl(
      config.api.detailPage.derivativeParameterTrendUrl,
      {
        assetId,
        parameterName
      }
    );
  else
    return UrlService.getApiUrl(config.api.detailPage.assetParameterTrendUrl, {
      assetId,
      parameterName
    });
};

export default getParameterUrl;
