// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { IState } from "reducers/Index";

const getCustomerVisualizationsAssetGridSelector = createSelector(
  (state: IState) => state.app.customerVisualizationsAssetGrid,
  (customer) => customer
);

export default getCustomerVisualizationsAssetGridSelector;
