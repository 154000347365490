// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon/components/Icon";
import { notifications } from "@pg/common/build/components/Notifications";
import {
  colorGray0,
  colorWidgetBorder
} from "@pg/common/build/styles/ColorVariables";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Layout,
  Modal,
  Row,
  Typography
} from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import AssetModalContext from "features/ConfigurationTool/contexts/AssetModalContext";
import IConnectedDevice from "features/ConfigurationTool/models/connectedDevices/IConnectedDevice";
import { FC, useCallback, useContext, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import {
  defaultDrawerWidth,
  spacingLarge,
  spacingMedium,
  spacingXXLarge
} from "styles/StyleVariables";

const { Header, Footer, Content } = Layout;
const { Title } = Typography;

interface IImportConnectedDevicesProps {
  visible: boolean;
  onCancel?: () => void;
  uploadedData: IConnectedDevice[];
  className?: string;
}

type ImportConnectedDevicesOptionValue = "override" | "add";

interface ImportConnectedDevicesOptionConfig {
  label: string;
  value: ImportConnectedDevicesOptionValue;
}

const ImportConnectedDevices: FC<IImportConnectedDevicesProps> = ({
  visible,
  onCancel,
  uploadedData,
  className
}) => {
  const {
    connectedDevices,
    setConnectedDevices,
    mapToConfigurableConnectedDevices,
    setConnectedDeviceAction
  } = useContext(AssetModalContext);

  const [form] = Form.useForm();

  const { formatMessage } = useIntl();

  const options = useMemo(
    (): ImportConnectedDevicesOptionConfig[] => [
      {
        label: formatMessage({
          id: "configuration_tool.tab.connected_devices_import_json_override",
          defaultMessage: "Override"
        }),
        value: "override"
      },
      {
        label: formatMessage({
          id: "configuration_tool.tab.connected_devices_import_json_override_add_new_devices",
          defaultMessage: "Add new as new devices"
        }),
        value: "add"
      }
    ],
    [formatMessage]
  );

  const [activeOptionValue, setActiveOptionValue] =
    useState<ImportConnectedDevicesOptionValue>(options[0].value);

  const handleChange = useCallback((e: CheckboxChangeEvent) => {
    setActiveOptionValue(e.target.value);
  }, []);

  const handleSubmitOption = useCallback(() => {
    if (activeOptionValue === "override") {
      setConnectedDevices(
        mapToConfigurableConnectedDevices({
          connectedDevices: uploadedData,
          imported: true
        })
      );
    } else if (
      activeOptionValue === "add" &&
      connectedDevices.length + uploadedData.length <= 5
    ) {
      setConnectedDevices((connectedDevices) =>
        [
          ...connectedDevices.map((device) => ({ ...device, Imported: false })),
          ...mapToConfigurableConnectedDevices({
            connectedDevices: uploadedData,
            lastDeviceOrder: connectedDevices.length,
            imported: true
          })
        ].sort((a, b) => a.Order - b.Order)
      );
    } else {
      notifications.error({
        message: formatMessage({
          id: "configuration_tool.tab.connected_devices_add_new_device_max_limit",
          defaultMessage:
            "The maximum number of Connected Devices has been reached"
        })
      });
    }

    setConnectedDeviceAction("upload");
  }, [
    activeOptionValue,
    connectedDevices.length,
    formatMessage,
    mapToConfigurableConnectedDevices,
    setConnectedDeviceAction,
    setConnectedDevices,
    uploadedData
  ]);

  const handleSubmit = useCallback(() => {
    handleSubmitOption();
    onCancel();
    form.resetFields();
  }, [form, handleSubmitOption, onCancel]);

  return (
    <Modal
      open={visible}
      centered={true}
      width={defaultDrawerWidth}
      destroyOnClose={true}
      onCancel={onCancel}
      footer={null}
      data-qa="ImportConnectedDevicesModal"
      className={className}
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical" colon={false}>
        <Layout className="import-connected-devices-layout">
          <Header className="import-connected-devices-header">
            <Title level={5}>
              {formatMessage({
                id: "configuration_tool.tab.connected_devices_importing_configuration",
                defaultMessage: "Importing configuration"
              })}
            </Title>
          </Header>
          <Content className="import-connected-devices-content">
            <Col>
              {options.map(({ label, value }) => {
                return (
                  <Row key={value}>
                    <Form.Item>
                      <Checkbox
                        onChange={handleChange}
                        checked={value === activeOptionValue}
                        value={value}
                      >
                        {label}
                      </Checkbox>
                    </Form.Item>
                  </Row>
                );
              })}
            </Col>
          </Content>
        </Layout>

        <Footer className="import-connected-devices-footer">
          <Button type="default" className="btn-cancel" onClick={onCancel}>
            <Icon name="close" size="sm" />
            {formatMessage({
              defaultMessage: "Cancel",
              id: "configuration_tool.action.cancel"
            })}
          </Button>

          <Button type="primary" htmlType="submit">
            <Icon name="done" size="sm" />
            {formatMessage({
              id: "configuration_tool.action.import",
              defaultMessage: "Import"
            })}
          </Button>
        </Footer>
      </Form>
    </Modal>
  );
};

const StyledImportConnectedDevices = styled(ImportConnectedDevices)`
  .import-connected-devices-layout,
  .import-connected-devices-header,
  .import-connected-devices-footer {
    background-color: ${colorGray0};
  }

  .import-connected-devices-header {
    padding: 0px 0px ${spacingXXLarge} 0px;
  }

  .import-connected-devices-content {
    display: flex;
    flex-direction: column;
  }

  .import-connected-devices-footer {
    border-top: 1px solid ${colorWidgetBorder};
    display: flex;
    column-gap: ${spacingLarge};
    align-items: center;
    justify-content: flex-end;
    padding-right: 0;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: ${spacingMedium};
    }
  }
`;

export default StyledImportConnectedDevices;
