// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import FilterBar, { SelectedFilters } from "common/FilterBar";
import React, { useCallback, useState } from "react";
import styled from "styled-components";
import LocationsGrid from "./LocationsGrid";

interface ILocationsProps {
  className?: string;
}

const Locations = ({ className }: ILocationsProps) => {
  const [filters, setFilters] = useState<SelectedFilters>();

  const handleFilter = useCallback((filters: SelectedFilters) => {
    setFilters(filters);
  }, []);

  return (
    <div className={className}>
      <FilterBar
        onFilter={handleFilter}
        namespace="locations"
        useSearch
        useAssetOrganizationWithRegionAndLocation
      >
        {filters && <LocationsGrid filters={filters} />}
      </FilterBar>
    </div>
  );
};

export default styled(Locations)`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;

  > div:first-of-type {
    flex-shrink: 0;
  }
`;
