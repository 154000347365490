// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Issues, {
  IIssuesActions,
  IIssuesData,
  IIssuesOwnProps
} from "features/detailpage/features/issues/components/Issues";
import { expand } from "features/detailpage/features/rightpanel/actions/RightPanelActions";
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import { AppDispatch } from "store";
import {
  createNewIssue,
  loadClosedIssues,
  removeNewIssue,
  startProcessingIssues,
  switchIssueModeToView
} from "../actions/IssuesActions";

export const issuesLimit = 20;

export function mapStateToProps(state: IState): IIssuesData {
  return {
    newIssue: state.detailPage.issues.newIssue,
    newMeta: state.detailPage.issues.newMeta,
    activeIssues: state.detailPage.issues.activeIssues,
    activeMetas: state.detailPage.issues.activeMetas,
    closedIssues: state.detailPage.issues.closedIssues,
    closedMetas: state.detailPage.issues.closedMetas,
    statuses: state.detailPage.issues.statuses,
    urgencies: state.detailPage.issues.urgencies,
    user: state.app.user.data
  };
}

export function mapDispatchToProps(
  dispatch: AppDispatch,
  ownProps: IIssuesOwnProps
): IIssuesActions {
  return {
    createNewIssue: (
      userName: string,
      assetId: string,
      userSurname: string
    ): string => {
      return createNewIssue(
        dispatch,
        assetId,
        `${userName || ""}${userName && userSurname ? " " : ""}${
          userSurname || ""
        }`
      );
    },
    expandPanel: () => dispatch(expand()),
    loadClosedIssues: () => {
      loadClosedIssues(dispatch, ownProps.assetId);
    },
    loadClosedIssuesWithLimit: () => {
      loadClosedIssues(dispatch, ownProps.assetId, issuesLimit);
    },
    removeNewIssue: () => removeNewIssue(dispatch),
    switchIssueModeToView: (issueId: string) => {
      switchIssueModeToView(dispatch, issueId);
    },
    startProcessingIssues: () => dispatch(startProcessingIssues())
  };
}

export function mergeProps(
  stateProps: IIssuesData,
  dispatchProps: IIssuesActions,
  ownProps: IIssuesOwnProps
) {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps, {
  forwardRef: true
})(Issues);
