// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import {
  fontSizeLarge,
  spacingSmall,
  spacingXLarge
} from "styles/StyleVariables";
import { Menu, Typography } from "antd";
import { MenuItemType, SubMenuType } from "antd/lib/menu/hooks/useItems";
import { MenuInfo } from "rc-menu/lib/interface";
import { useCallback } from "react";
import styled from "styled-components";
import {
  colorAppBackground,
  colorStatusRed,
  colorTeal100,
  colorTextPrimaryOnLight,
  colorWidgetBackground
} from "styles/ColorVariables";
import { spacingLarge } from "styles/StyleVariables";

const { Text } = Typography;
export interface IMenuItem {
  key: string;
  title: string;
  iconName?: string;
  children?: Array<IMenuItem>;
  invalid?: boolean;
  touched?: boolean;
}

interface IAssetModalMenuProps {
  menuItems: Array<IMenuItem>;
  onMenuChange: (key: string, keyPath: string[]) => void;
  selectedMenu?: string;
  className?: string;
}

const AssetModalMenu = ({
  menuItems,
  onMenuChange,
  selectedMenu,
  className
}: IAssetModalMenuProps) => {
  const handleMenuClick = useCallback(
    (info: MenuInfo) => {
      onMenuChange(info.key, info.keyPath);
    },
    [onMenuChange]
  );

  return (
    <Menu
      style={{ width: 200 }}
      defaultOpenKeys={menuItems.map((item) => item.key)}
      selectedKeys={[selectedMenu]}
      mode="inline"
      onClick={handleMenuClick}
      inlineIndent={0}
      className={className}
      items={menuItems.map<SubMenuType>(({ key, title, children }) => {
        return {
          key,
          title,
          label: title,
          className,
          children: children.map<MenuItemType>(
            ({ key, title, iconName, invalid, touched }) => {
              return {
                key,
                title,
                icon: iconName ? (
                  <Icon className="menu-custom-icon" name={iconName} />
                ) : null,
                label: (
                  <>
                    <Text title={title}>{title}</Text>
                    {invalid ? (
                      <Icon
                        className="menu-validation-icon"
                        name="circle"
                        size="xs"
                      />
                    ) : touched ? (
                      <Icon
                        className="menu-touched-icon"
                        name="circle"
                        size="xs"
                      />
                    ) : null}
                  </>
                )
              };
            }
          )
        };
      })}
    />
  );
};

const StyledAssetModalMenu = styled(AssetModalMenu)`
  border-right: 1px solid ${colorAppBackground};
  padding: 0;

  .ant-menu-submenu-selected .ant-menu-submenu-title {
    color: ${colorTextPrimaryOnLight};
  }

  .ant-menu-item,
  .ant-menu-submenu-title {
    padding-inline: 0;
    margin-inline: 0;
    margin-block: 0;
    margin-top: ${spacingSmall};
    margin-bottom: ${spacingSmall};
    width: calc(100% + 1px);
  }

  .ant-menu-item.ant-menu-item-selected {
    background-color: ${colorWidgetBackground};

    &:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      border-right: 3px solid ${colorTextPrimaryOnLight};
    }
  }

  .ant-menu.ant-menu-sub.ant-menu-inline {
    background: transparent;

    .ant-menu-item {
      &:hover {
        background: transparent;
      }
    }
  }

  .ant-menu-submenu.ant-menu-submenu-inline {
    .ant-menu-submenu-title {
      &:hover {
        background: transparent;
      }
    }
  }

  .menu-custom-icon.ant-menu-item-icon {
    color: ${colorTextPrimaryOnLight};
    font-size: ${fontSizeLarge};

    svg {
      display: flex;
    }
  }

  .svg-icon.md {
    width: auto;
  }

  .ant-menu-title-content {
    display: flex;
    .ant-typography {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .menu-touched-icon {
      color: ${colorTeal100};
      margin: auto ${spacingLarge};
      padding-right: ${spacingXLarge};
    }

    .menu-validation-icon {
      color: ${colorStatusRed};
      margin: auto ${spacingLarge};
      padding-right: ${spacingXLarge};
    }
  }
`;

export default StyledAssetModalMenu;
