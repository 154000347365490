// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { ISeries } from "@apm/widgets/build/widgets/PrognosticTrend";
import IDegradationScoreTrend from "features/detailpage/features/risktrend/models/IDegradationScoreTrend";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import IScoreTrend from "../../../models/IScoreTrend";
import PrognosticChartSeriesCreator from "../services/PrognosticChartSeriesCreator";

interface IUsePrognosticTrendSeriesOptions {
  degradationScoreTrend?: IDegradationScoreTrend;
  simulationTrend?: IScoreTrend[];
  switchId?: string;
  modelId?: string;
  implementationId?: string;
}

interface IPrognosticChartState {
  series?: ISeries[];
  prognosisSeries?: ISeries[];
  simulationSeries?: ISeries[];
  ready?: boolean;
}

const usePrognosticTrendSeries = ({
  degradationScoreTrend,
  simulationTrend,
  modelId,
  implementationId
}: IUsePrognosticTrendSeriesOptions) => {
  const intl = useIntl();
  const [trendState, setTrendState] = useState<IPrognosticChartState>({
    series: [],
    prognosisSeries: [],
    simulationSeries: [],
    ready: false
  });
  useEffect(() => {
    const createSeries = async () => {
      if (!trendState?.ready) {
        const { series, prognosisSeries, simulationSeries } =
          await new PrognosticChartSeriesCreator(
            intl,
            modelId,
            implementationId
          ).create(
            degradationScoreTrend?.Series,
            degradationScoreTrend?.Prognosis,
            simulationTrend
          );
        setTrendState({
          series,
          prognosisSeries,
          simulationSeries,
          ready: true
        });
      }
    };

    createSeries();
  }, [
    degradationScoreTrend,
    implementationId,
    intl,
    modelId,
    simulationTrend,
    trendState
  ]);

  return trendState;
};

export default usePrognosticTrendSeries;
