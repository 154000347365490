// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import getAgingOperationsSelector from "features/detailpage/features/aging/selectors/getAgingOperationsSelector";
import { useAppSelector } from "store";

const useAgingOperations = () => {
  const agingOperations = useAppSelector(getAgingOperationsSelector);

  return {
    agingOperations
  };
};

export default useAgingOperations;
