// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import SearchParams from "@pg/common/build/models/SearchParams";
import {
  useFeature,
  VisualizationKeys
} from "core/app/components/FeatureToggle";
import { LayoutRoute } from "core/app/components/RouterProvider";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import ParametersTab from "../../ParametersTab";

interface IUseDefaultRoutesProps {
  assetId: string;
  implementationId: string;
  modelId: string;
}

const useDefaultRoutes = ({
  assetId,
  implementationId,
  modelId
}: IUseDefaultRoutesProps) => {
  const { isAvailable } = useFeature();
  const intl = useIntl();
  const searchParams = useMemo(() => new SearchParams({ assetId }), [assetId]);

  const routes = useMemo<LayoutRoute[]>(() => {
    return [
      isAvailable(VisualizationKeys.AssetDetailPage_Tabs_Parameters_Parameters)
        ? {
            id: "Assets-DetailPage-Parameters-Parameters",
            route: "Parameters/*",
            displayName: intl.formatMessage({
              id: "detail_page.widgets.parameters_list.title",
              defaultMessage: "Parameters"
            }),
            to: {
              pathname: "/Assets/DetailPage/Parameters/Parameters",
              search: searchParams.toString()
            },
            element: (
              <ParametersTab
                assetId={assetId}
                modelId={modelId}
                implementationId={implementationId}
              />
            )
          }
        : undefined
    ].filter(Boolean);
  }, [assetId, implementationId, intl, isAvailable, modelId, searchParams]);

  return routes;
};

export default useDefaultRoutes;
