// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import * as React from "react";

import Input, {
  IInputState,
  IInputActions,
  IInputOwnProps,
  IInputData
} from "./Input";
import Tree, { ITreeProps } from "./Tree";

export interface ITreeInputActions extends IInputActions {}

export interface ITreeInputData extends IInputData {
  guid: string;
}

export interface ITreeInputOwnProps
  extends IInputOwnProps<string[]>,
    ITreeProps {}

export interface ITreeInputProps
  extends ITreeInputActions,
    ITreeInputData,
    ITreeInputOwnProps {}

export interface ITreeInputState extends IInputState {}

export default class TreeInput extends Input<
  ITreeInputProps,
  ITreeInputState,
  string[]
> {
  render() {
    const { guid } = this.props;
    return (
      <Tree
        key={guid}
        {...this.props}
        onInit={this.handleInit.bind(this)}
        onChange={this.handleChange.bind(this)}
      />
    );
  }
}
