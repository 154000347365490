// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import IGasGroupSettings from "../models/IGasGroupSettings";

export const gasGroupSettings: IGasGroupSettings[] = [
  {
    name: "combustibleGases",
    defaultMessage: "Combustible Gases",
    id: "detail_page.widgets.analytics.transformers.dga.groups.combustible",
    gases: [
      "Acetylene",
      "Ethylene",
      "Ethane",
      "Methane",
      "Hydrogen",
      "CarbonMonoxide"
    ]
  },
  {
    name: "nonCombustibleGases",
    defaultMessage: "Noncombustible Gases",
    id: "detail_page.widgets.analytics.transformers.dga.groups.noncombustible",
    gases: ["CarbonDioxide", "Oxygen", "Nitrogen"]
  },
  {
    name: "gasRatiosAndComposites",
    defaultMessage: "Gas ratios and composites",
    id: "detail_page.widgets.analytics.transformers.dga.groups.ratios_and_composites",
    gases: ["OxideOfCarbonRatio", "Hydran", "TotalDissolvedCombustibleGases"]
  }
];

export default gasGroupSettings;
