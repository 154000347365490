// Copyright 2016-2024 Hitachi Energy. All rights reserved.

const bushingManufacturers = [
  "a_s",
  "abb",
  "asea",
  "bushco",
  "cge",
  "f_and_g",
  "fuji",
  "ge",
  "haefly",
  "hf",
  "hitachi",
  "hsp",
  "hyundai",
  "lapp",
  "locke",
  "mcgraw_edison",
  "micafil",
  "missing",
  "mitsubishi",
  "ngk",
  "ohio_brass",
  "other",
  "passon",
  "pcore",
  "solid",
  "toshiba",
  "trench",
  "westinghouse"
];

export default bushingManufacturers;
