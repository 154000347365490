// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import ISelectOption from "../../../models/ISelectOption";

const manufacturers: ISelectOption[] = [
  {
    value: "a_c",
    label: "a_c - Allis Chalmers"
  },
  {
    value: "abb",
    label: "abb - Asea Brown Boveri"
  },
  {
    value: "aeg",
    label: "aeg - AEG"
  },
  {
    value: "alst",
    label: "alst - Alstom"
  },
  {
    value: "ansa",
    label: "ansa - Ansaldo"
  },
  {
    value: "arda",
    label: "arda - Ardan"
  },
  {
    value: "areva",
    label: "areva - AREVA"
  },
  {
    value: "asea",
    label: "asea - Asea"
  },
  {
    value: "bbc",
    label: "bbc - Brown-Boveri"
  },
  {
    value: "betra",
    label: "betra - BETRA"
  },
  {
    value: "bhel",
    label: "bhel - Bharat Heavy Electricals"
  },
  {
    value: "btw",
    label: "btw - BTW"
  },
  {
    value: "burro",
    label: "burro - Burrows"
  },
  {
    value: "celme",
    label: "celme - CELME"
  },
  {
    value: "cemo",
    label: "cemo - Central Moloney"
  },
  {
    value: "cge",
    label: "cge - Canadian General Electric"
  },
  {
    value: "chang",
    label: "chang - Changzhou"
  },
  {
    value: "com",
    label: "com - Comtrafo"
  },
  {
    value: "coopr",
    label: "coopr - Cooper"
  },
  {
    value: "crmpg",
    label: "crmpg - Crompton Greaves"
  },
  {
    value: "crmpp",
    label: "crmpp - Crompton Parkinson"
  },
  {
    value: "cxdg",
    label: "cxdg - China XD Group"
  },
  {
    value: "dest",
    label: "dest - Delta Star"
  },
  {
    value: "efacec",
    label: "efacec - EFACEC"
  },
  {
    value: "elbu",
    label: "elbu - Elektrisk Bureau"
  },
  {
    value: "elco",
    label: "elco - Elco"
  },
  {
    value: "elin",
    label: "elin - Elin"
  },
  {
    value: "emtr",
    label: "emtr - Emirates Transformer"
  },
  {
    value: "enel",
    label: "enel - English Electric"
  },
  {
    value: "erma",
    label: "erma - Ercoli Marelli"
  },
  {
    value: "ernco",
    label: "ernco - Ernco"
  },
  {
    value: "euroe",
    label: "euroe - Euroelettro"
  },
  {
    value: "fayet",
    label: "fayet - Fayetteville"
  },
  {
    value: "fed_pac",
    label: "fed_pac - Federal Pacific"
  },
  {
    value: "fed_pio",
    label: "fed_pio - Federal Pioneer"
  },
  {
    value: "ferranti",
    label: "ferranti - Ferranti-Packard"
  },
  {
    value: "forte",
    label: "forte - Fortune Electric"
  },
  {
    value: "fuji",
    label: "fuji - Fuji"
  },
  {
    value: "ge",
    label: "ge - General Electric"
  },
  {
    value: "hangz",
    label: "hangz - Hangzhou"
  },
  {
    value: "hawks",
    label: "hawks - Hawker Siddeley"
  },
  {
    value: "hefel",
    label: "hefel - Hefel"
  },
  {
    value: "hico",
    label: "hico - HICO"
  },
  {
    value: "hitachi",
    label: "hitachi - Hitachi"
  },
  {
    value: "howa",
    label: "howa - Howard"
  },
  {
    value: "hps",
    label: "hps - HPS"
  },
  {
    value: "hyosu",
    label: "hyosu - HYOSUNG"
  },
  {
    value: "hyundai",
    label: "hyundai - Hyundai"
  },
  {
    value: "india",
    label: "india - Indiamart"
  },
  {
    value: "iniem",
    label: "iniem - Industrias IEM"
  },
  {
    value: "jaeps",
    label: "jaeps - Jaeps"
  },
  {
    value: "jesch",
    label: "jesch - Jeumont Schneider"
  },
  {
    value: "jshp",
    label: "jshp - JSHP"
  },
  {
    value: "jsttr",
    label: "jsttr - JST Transformers"
  },
  {
    value: "ketra",
    label: "ketra - Kolektor ETRA"
  },
  {
    value: "kirel",
    label: "kirel - Kirloskar Electric"
  },
  {
    value: "koncar",
    label: "koncar - KONCAR"
  },
  {
    value: "kuhlm",
    label: "kuhlm - Kuhlman"
  },
  {
    value: "legna",
    label: "legna - Legnano"
  },
  {
    value: "mace",
    label: "mace - Mace"
  },
  {
    value: "mag",
    label: "mag - Magnetek"
  },
  {
    value: "marke",
    label: "marke - Markham Electric"
  },
  {
    value: "mcgraw",
    label: "mcgraw - McGraw-Edison"
  },
  {
    value: "mec",
    label: "mec - Magnetic Electric Company"
  },
  {
    value: "mitsubishi",
    label: "mitsubishi - Mitsubishi"
  },
  {
    value: "moloney",
    label: "moloney - Moloney"
  },
  {
    value: "na",
    label: "na - North American Transformers"
  },
  {
    value: "natin",
    label: "natin - National Industri"
  },
  {
    value: "niatr",
    label: "niatr - Niagara Transformer"
  },
  {
    value: "oerli",
    label: "oerli - Oerlikon"
  },
  {
    value: "ohtr",
    label: "ohtr - Ohio Transformer"
  },
  {
    value: "other",
    label: "other"
  },
  {
    value: "pauw",
    label: "pauw - Pauwels"
  },
  {
    value: "penn",
    label: "penn - Pennsylvania Transformer"
  },
  {
    value: "pmeps",
    label: "pmeps - PME Power Solutions"
  },
  {
    value: "powtr",
    label: "powtr - Powertech Transformers"
  },
  {
    value: "prme",
    label: "prme - Prime Meiden"
  },
  {
    value: "prolec",
    label: "prolec - Prolec"
  },
  {
    value: "rte",
    label: "rte - RTE"
  },
  {
    value: "sanbi",
    label: "sanbi - Sanbian"
  },
  {
    value: "sea",
    label: "sea - SEA"
  },
  {
    value: "shang",
    label: "shang - Shangong"
  },
  {
    value: "siem",
    label: "siem - Siemens"
  },
  {
    value: "skot",
    label: "skot - Skot"
  },
  {
    value: "smit",
    label: "smit - Smit"
  },
  {
    value: "snc",
    label: "snc - SNC"
  },
  {
    value: "spx",
    label: "spx - SPX"
  },
  {
    value: "strom",
    label: "strom - Stromberg"
  },
  {
    value: "subo",
    label: "subo - Suzhou Boyuan"
  },
  {
    value: "swe",
    label: "swe - Southwest Electric"
  },
  {
    value: "tal",
    label: "tal - Technical Associates"
  },
  {
    value: "tami",
    label: "tami - Tamini"
  },
  {
    value: "tamu",
    label: "tamu - Tamura"
  },
  {
    value: "tbea",
    label: "tbea - TBEA"
  },
  {
    value: "toshiba",
    label: "toshiba - Toshiba"
  },
  {
    value: "trasf",
    label: "trasf - Trasfor"
  },
  {
    value: "trun",
    label: "trun - Trafo Union"
  },
  {
    value: "tti",
    label: "tti - TTI"
  },
  {
    value: "ust",
    label: "ust - US Transformer"
  },
  {
    value: "volta",
    label: "volta - Voltamp"
  },
  {
    value: "voro",
    label: "voro - VonRoll"
  },
  {
    value: "vtc",
    label: "vtc - Virginia Transformers"
  },
  {
    value: "vtech",
    label: "vtech - VA Tech"
  },
  {
    value: "vtran",
    label: "vtran - VanTran"
  },
  {
    value: "wagn",
    label: "wagn - Wagner"
  },
  {
    value: "wau",
    label: "wau - Waukesha"
  },
  {
    value: "weg",
    label: "weg - WEG"
  },
  {
    value: "west",
    label: "west - Westinghouse"
  },
  {
    value: "wils",
    label: "wils - Wilson"
  },
  {
    value: "xixia",
    label: "xixia - Xian Xiandian"
  },
  {
    value: "zaptr",
    label: "zaptr - Zaporozhtransformator"
  }
];

export default manufacturers;
