// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { isEmpty, last } from "lodash";

const removeReportPrefix = (name: string): string => {
  const splitted = name.split(".");
  if (!isEmpty(splitted)) return last(splitted);
  return "";
};

export default removeReportPrefix;
