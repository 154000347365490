// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Processing from "components/common/Processing";
import {
  WidgetErrorMessage,
  WidgetNoDataMessage
} from "components/common/widget/Widget";
import Data, { Statuses } from "core/data/models/Data";
import { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import { AppDispatch } from "store";
import loadChart from "../actions/loadChart";
import loadMeasurements from "../actions/loadMeasurements";
import loadRollingAverageForAsset from "../actions/loadRollingAverageForAsset";
import removeChart from "../actions/removeChart";
import removeMeasurements from "../actions/removeMeasurements";
import ChartNames from "../models/ChartNames";
import ICircleChartConfiguration from "../models/ICircleChartConfiguration";
import ICircleChartData from "../models/ICircleChartData";
import IDCChart from "../models/IDCChart";
import getChartSelector from "../selectors/getChartSelector";
import getCircleChartSelector from "../selectors/getCircleChartSelector";
import DCTabCharts from "./DCTabCharts";

interface IDCTabActions {
  loadChart: (assetId: string, chartName: ChartNames) => void;
  loadMeasurements: (
    assetId: string,
    parameters: string[],
    referenceLines: string[],
    chartName: ChartNames
  ) => void;
  loadRollingAverageForAsset: (
    assetId: string,
    chartConfiguration: { [key: string]: ICircleChartConfiguration },
    chartName: ChartNames
  ) => void;
  removeChart: (assetId: string, chartName: ChartNames) => void;
  removeMeasurements: (assetId: string, chartName: ChartNames) => void;
  removeRollingAverageForAsset: (
    assetId: string,
    chartName: ChartNames
  ) => void;
}

interface IDCTabState {
  measuredApparentCapacitanceChart: Data<IDCChart>;
  oilTemperatureChart: Data<IDCChart>;
  oltcOilLevelAndTemperatureChart: Data<IDCChart>;
  rollingAverageOfVectorLengthChart: Data<IDCChart>;
  coreEarthingCurrentChart: Data<IDCChart>;
  measuredMoistureChart: Data<IDCChart>;
  sf6PressureChart: Data<IDCChart>;
  sf6PressureVectorLengthChart: Data<IDCChart>;
  circleTemperatureChart: Data<ICircleChartData>;
  circleBushingChart: Data<ICircleChartData>;
  circleOltcOilLevelChart: Data<ICircleChartData>;
  circleCoreEarthingCurrentChart: Data<ICircleChartData>;
  moistureRollingAverageChart: Data<ICircleChartData>;
  sf6PressureRollingAverageChart: Data<ICircleChartData>;
}

interface IDCTabOwnProps {
  assetId: string;
}

interface IDCTabProps extends IDCTabActions, IDCTabState, IDCTabOwnProps {}

const DCTab = ({
  assetId,
  measuredApparentCapacitanceChart,
  oilTemperatureChart,
  oltcOilLevelAndTemperatureChart,
  rollingAverageOfVectorLengthChart,
  coreEarthingCurrentChart,
  measuredMoistureChart,
  sf6PressureChart,
  sf6PressureVectorLengthChart,
  circleTemperatureChart,
  circleBushingChart,
  circleOltcOilLevelChart,
  circleCoreEarthingCurrentChart,
  moistureRollingAverageChart,
  sf6PressureRollingAverageChart,
  loadChart,
  loadMeasurements,
  loadRollingAverageForAsset,
  removeChart,
  removeMeasurements,
  removeRollingAverageForAsset
}: IDCTabProps) => {
  const showLoading = useMemo(
    () =>
      measuredApparentCapacitanceChart?.status === Statuses.Loading ||
      oilTemperatureChart?.status === Statuses.Loading ||
      oltcOilLevelAndTemperatureChart?.status === Statuses.Loading ||
      coreEarthingCurrentChart?.status === Statuses.Loading ||
      measuredMoistureChart?.status === Statuses.Loading ||
      sf6PressureChart?.status === Statuses.Loading ||
      sf6PressureVectorLengthChart?.status === Statuses.Loading ||
      circleTemperatureChart?.status === Statuses.Loading ||
      circleBushingChart?.status === Statuses.Loading ||
      circleOltcOilLevelChart?.status === Statuses.Loading ||
      circleCoreEarthingCurrentChart?.status === Statuses.Loading ||
      moistureRollingAverageChart?.status === Statuses.Loading ||
      sf6PressureRollingAverageChart?.status === Statuses.Loading,
    [
      measuredApparentCapacitanceChart?.status,
      oilTemperatureChart?.status,
      oltcOilLevelAndTemperatureChart?.status,
      coreEarthingCurrentChart?.status,
      measuredMoistureChart?.status,
      sf6PressureChart?.status,
      sf6PressureVectorLengthChart?.status,
      circleTemperatureChart?.status,
      circleBushingChart?.status,
      circleOltcOilLevelChart?.status,
      circleCoreEarthingCurrentChart?.status,
      moistureRollingAverageChart?.status,
      sf6PressureRollingAverageChart?.status
    ]
  );

  const showSucceeded = useMemo(
    () =>
      !showLoading &&
      (measuredApparentCapacitanceChart?.status === Statuses.Succeeded ||
        oilTemperatureChart?.status === Statuses.Succeeded ||
        oltcOilLevelAndTemperatureChart?.status === Statuses.Succeeded ||
        coreEarthingCurrentChart?.status === Statuses.Succeeded ||
        measuredMoistureChart?.status === Statuses.Succeeded ||
        sf6PressureChart?.status === Statuses.Succeeded ||
        sf6PressureVectorLengthChart?.status === Statuses.Succeeded ||
        circleTemperatureChart?.status === Statuses.Succeeded ||
        circleBushingChart?.status === Statuses.Succeeded ||
        circleOltcOilLevelChart?.status === Statuses.Succeeded ||
        circleCoreEarthingCurrentChart?.status === Statuses.Succeeded ||
        moistureRollingAverageChart?.status === Statuses.Succeeded ||
        sf6PressureRollingAverageChart?.status === Statuses.Succeeded),
    [
      showLoading,
      measuredApparentCapacitanceChart?.status,
      oilTemperatureChart?.status,
      oltcOilLevelAndTemperatureChart?.status,
      coreEarthingCurrentChart?.status,
      measuredMoistureChart?.status,
      sf6PressureChart?.status,
      sf6PressureVectorLengthChart?.status,
      circleTemperatureChart?.status,
      circleBushingChart?.status,
      circleOltcOilLevelChart?.status,
      circleCoreEarthingCurrentChart?.status,
      moistureRollingAverageChart?.status,
      sf6PressureRollingAverageChart?.status
    ]
  );

  const showNotFound = useMemo(
    () =>
      !showLoading &&
      measuredApparentCapacitanceChart?.status === Statuses.NotFound &&
      oilTemperatureChart?.status === Statuses.NotFound &&
      oltcOilLevelAndTemperatureChart?.status === Statuses.NotFound &&
      coreEarthingCurrentChart?.status === Statuses.NotFound &&
      measuredMoistureChart?.status === Statuses.NotFound &&
      sf6PressureChart?.status === Statuses.NotFound &&
      sf6PressureVectorLengthChart?.status === Statuses.NotFound &&
      circleTemperatureChart?.status === Statuses.NotFound &&
      circleBushingChart?.status === Statuses.NotFound &&
      circleOltcOilLevelChart?.status === Statuses.NotFound &&
      circleCoreEarthingCurrentChart?.status === Statuses.NotFound &&
      moistureRollingAverageChart?.status === Statuses.NotFound &&
      sf6PressureRollingAverageChart?.status === Statuses.NotFound,
    [
      showLoading,
      measuredApparentCapacitanceChart?.status,
      oilTemperatureChart?.status,
      oltcOilLevelAndTemperatureChart?.status,
      coreEarthingCurrentChart?.status,
      measuredMoistureChart?.status,
      sf6PressureChart?.status,
      sf6PressureVectorLengthChart?.status,
      circleTemperatureChart?.status,
      circleBushingChart?.status,
      circleOltcOilLevelChart?.status,
      circleCoreEarthingCurrentChart?.status,
      moistureRollingAverageChart?.status,
      sf6PressureRollingAverageChart?.status
    ]
  );

  const showFailed = useMemo(
    () =>
      !showLoading &&
      measuredApparentCapacitanceChart?.status === Statuses.Failed &&
      oilTemperatureChart?.status === Statuses.Failed &&
      oltcOilLevelAndTemperatureChart?.status === Statuses.Failed &&
      coreEarthingCurrentChart?.status === Statuses.Failed &&
      measuredMoistureChart?.status === Statuses.Failed &&
      sf6PressureChart?.status === Statuses.Failed &&
      sf6PressureVectorLengthChart?.status === Statuses.Failed &&
      circleTemperatureChart?.status === Statuses.Failed &&
      circleBushingChart?.status === Statuses.Failed &&
      circleOltcOilLevelChart?.status === Statuses.Failed &&
      circleCoreEarthingCurrentChart?.status === Statuses.Failed &&
      moistureRollingAverageChart?.status === Statuses.Failed &&
      sf6PressureRollingAverageChart?.status === Statuses.Failed,
    [
      measuredApparentCapacitanceChart?.status,
      oilTemperatureChart?.status,
      oltcOilLevelAndTemperatureChart?.status,
      coreEarthingCurrentChart?.status,
      measuredMoistureChart?.status,
      sf6PressureChart?.status,
      sf6PressureVectorLengthChart?.status,
      circleTemperatureChart?.status,
      circleBushingChart?.status,
      circleOltcOilLevelChart?.status,
      circleCoreEarthingCurrentChart?.status,
      moistureRollingAverageChart?.status,
      sf6PressureRollingAverageChart?.status,
      showLoading
    ]
  );

  useEffect(() => {
    loadChart(assetId, "MeasuredApparentCapacitance");
    loadChart(assetId, "OilTemperature");
    loadChart(assetId, "OLTCOilLevelAndTemperature");
    loadChart(assetId, "CircleTemperature");
    loadChart(assetId, "CircleBushing");
    loadChart(assetId, "CircleOltcOilLevel");
    loadChart(assetId, "RollingAverageOfVectorLength");
    loadChart(assetId, "CircleCoreEarthingCurrent");
    loadMeasurements(
      assetId,
      [
        "HVDC.TapChanger.LTC1.Moisture.Reading",
        "HVDC.TapChanger.LTC2.Moisture.Reading",
        "HVDC.TapChanger.LTC3.Moisture.Reading",
        "HVDC.TapChanger.LTC4.Moisture.Reading"
      ],
      [],
      "MeasuredMoisture"
    );
    loadMeasurements(
      assetId,
      [
        "HVDC.Transformer.CoreEarthingCurrent.Reading",
        "HVDC.TapChanger.TapPosition"
      ],
      [],
      "CoreEarthingCurrent"
    );
    loadRollingAverageForAsset(
      assetId,
      {
        OLTC1: {
          startingPointXParameter:
            "HVDC.OltcMoistureContentNonVacuumDiagnostics.LTC1.RollingAverageDrift.Initial.X",
          startingPointYParameter:
            "HVDC.OltcMoistureContentNonVacuumDiagnostics.LTC1.RollingAverageDrift.Initial.Y",
          yellowThresholdSizeParameter:
            "HVDC.OltcMoistureContentNonVacuumDiagnostics.LTC1.RollingAverageDrift.Limit.Yellow",
          redThresholdSizeParameter:
            "HVDC.OltcMoistureContentNonVacuumDiagnostics.LTC1.RollingAverageDrift.Limit.Red",
          readingXParameter:
            "HVDC.OltcMoistureContentNonVacuumDiagnostics.LTC1.RollingAverageDrift.X",
          readingYParameter:
            "HVDC.OltcMoistureContentNonVacuumDiagnostics.LTC1.RollingAverageDrift.Y"
        },
        OLTC2: {
          startingPointXParameter:
            "HVDC.OltcMoistureContentNonVacuumDiagnostics.LTC2.RollingAverageDrift.Initial.X",
          startingPointYParameter:
            "HVDC.OltcMoistureContentNonVacuumDiagnostics.LTC2.RollingAverageDrift.Initial.Y",
          yellowThresholdSizeParameter:
            "HVDC.OltcMoistureContentNonVacuumDiagnostics.LTC2.RollingAverageDrift.Limit.Yellow",
          redThresholdSizeParameter:
            "HVDC.OltcMoistureContentNonVacuumDiagnostics.LTC2.RollingAverageDrift.Limit.Red",
          readingXParameter:
            "HVDC.OltcMoistureContentNonVacuumDiagnostics.LTC2.RollingAverageDrift.X",
          readingYParameter:
            "HVDC.OltcMoistureContentNonVacuumDiagnostics.LTC2.RollingAverageDrift.Y"
        },
        OLTC3: {
          startingPointXParameter:
            "HVDC.OltcMoistureContentNonVacuumDiagnostics.LTC3.RollingAverageDrift.Initial.X",
          startingPointYParameter:
            "HVDC.OltcMoistureContentNonVacuumDiagnostics.LTC3.RollingAverageDrift.Initial.Y",
          yellowThresholdSizeParameter:
            "HVDC.OltcMoistureContentNonVacuumDiagnostics.LTC3.RollingAverageDrift.Limit.Yellow",
          redThresholdSizeParameter:
            "HVDC.OltcMoistureContentNonVacuumDiagnostics.LTC3.RollingAverageDrift.Limit.Red",
          readingXParameter:
            "HVDC.OltcMoistureContentNonVacuumDiagnostics.LTC3.RollingAverageDrift.X",
          readingYParameter:
            "HVDC.OltcMoistureContentNonVacuumDiagnostics.LTC3.RollingAverageDrift.Y"
        },
        OLTC4: {
          startingPointXParameter:
            "HVDC.OltcMoistureContentNonVacuumDiagnostics.LTC4.RollingAverageDrift.Initial.X",
          startingPointYParameter:
            "HVDC.OltcMoistureContentNonVacuumDiagnostics.LTC4.RollingAverageDrift.Initial.Y",
          yellowThresholdSizeParameter:
            "HVDC.OltcMoistureContentNonVacuumDiagnostics.LTC4.RollingAverageDrift.Limit.Yellow",
          redThresholdSizeParameter:
            "HVDC.OltcMoistureContentNonVacuumDiagnostics.LTC4.RollingAverageDrift.Limit.Red",
          readingXParameter:
            "HVDC.OltcMoistureContentNonVacuumDiagnostics.LTC4.RollingAverageDrift.X",
          readingYParameter:
            "HVDC.OltcMoistureContentNonVacuumDiagnostics.LTC4.RollingAverageDrift.Y"
        }
      },
      "RollingAverageMoisture"
    );
    loadMeasurements(
      assetId,
      [
        "HVDC.Bushing.AC1.SF6Pressure.Reading",
        "HVDC.Bushing.AC2.SF6Pressure.Reading",
        "HVDC.Bushing.DC1.SF6Pressure.Reading",
        "HVDC.Bushing.DC2.SF6Pressure.Reading"
      ],
      [],
      "SF6Pressure"
    );
    loadMeasurements(
      assetId,
      [
        "HVDC.BushingSF6PressureDiagnostics.AC1.RollingAverageDrift.VectorLength",
        "HVDC.BushingSF6PressureDiagnostics.AC2.RollingAverageDrift.VectorLength",
        "HVDC.BushingSF6PressureDiagnostics.DC1.RollingAverageDrift.VectorLength",
        "HVDC.BushingSF6PressureDiagnostics.DC2.RollingAverageDrift.VectorLength"
      ],
      [],
      "SF6PressureVectorLength"
    );
    loadRollingAverageForAsset(
      assetId,
      {
        AC1: {
          startingPointXParameter:
            "HVDC.BushingSF6PressureDiagnostics.AC1.RollingAverageDrift.Initial.X",
          startingPointYParameter:
            "HVDC.BushingSF6PressureDiagnostics.AC1.RollingAverageDrift.Initial.Y",
          yellowThresholdSizeParameter:
            "HVDC.BushingSF6PressureDiagnostics.AC1.RollingAverageDrift.Limit.Yellow",
          redThresholdSizeParameter:
            "HVDC.BushingSF6PressureDiagnostics.AC1.RollingAverageDrift.Limit.Red",
          readingXParameter:
            "HVDC.BushingSF6PressureDiagnostics.AC1.RollingAverageDrift.X",
          readingYParameter:
            "HVDC.BushingSF6PressureDiagnostics.AC1.RollingAverageDrift.Y"
        },
        AC2: {
          startingPointXParameter:
            "HVDC.BushingSF6PressureDiagnostics.AC2.RollingAverageDrift.Initial.X",
          startingPointYParameter:
            "HVDC.BushingSF6PressureDiagnostics.AC2.RollingAverageDrift.Initial.Y",
          yellowThresholdSizeParameter:
            "HVDC.BushingSF6PressureDiagnostics.AC2.RollingAverageDrift.Limit.Yellow",
          redThresholdSizeParameter:
            "HVDC.BushingSF6PressureDiagnostics.AC2.RollingAverageDrift.Limit.Red",
          readingXParameter:
            "HVDC.BushingSF6PressureDiagnostics.AC2.RollingAverageDrift.X",
          readingYParameter:
            "HVDC.BushingSF6PressureDiagnostics.AC2.RollingAverageDrift.Y"
        },
        DC1: {
          startingPointXParameter:
            "HVDC.BushingSF6PressureDiagnostics.DC1.RollingAverageDrift.Initial.X",
          startingPointYParameter:
            "HVDC.BushingSF6PressureDiagnostics.DC1.RollingAverageDrift.Initial.Y",
          yellowThresholdSizeParameter:
            "HVDC.BushingSF6PressureDiagnostics.DC1.RollingAverageDrift.Limit.Yellow",
          redThresholdSizeParameter:
            "HVDC.BushingSF6PressureDiagnostics.DC1.RollingAverageDrift.Limit.Red",
          readingXParameter:
            "HVDC.BushingSF6PressureDiagnostics.DC1.RollingAverageDrift.X",
          readingYParameter:
            "HVDC.BushingSF6PressureDiagnostics.DC1.RollingAverageDrift.Y"
        },
        DC2: {
          startingPointXParameter:
            "HVDC.BushingSF6PressureDiagnostics.DC2.RollingAverageDrift.Initial.X",
          startingPointYParameter:
            "HVDC.BushingSF6PressureDiagnostics.DC2.RollingAverageDrift.Initial.Y",
          yellowThresholdSizeParameter:
            "HVDC.BushingSF6PressureDiagnostics.DC2.RollingAverageDrift.Limit.Yellow",
          redThresholdSizeParameter:
            "HVDC.BushingSF6PressureDiagnostics.DC2.RollingAverageDrift.Limit.Red",
          readingXParameter:
            "HVDC.BushingSF6PressureDiagnostics.DC2.RollingAverageDrift.X",
          readingYParameter:
            "HVDC.BushingSF6PressureDiagnostics.DC2.RollingAverageDrift.Y"
        }
      },
      "SF6PressureRollingAverage"
    );
    return () => {
      removeChart(assetId, "MeasuredApparentCapacitance");
      removeChart(assetId, "OilTemperature");
      removeChart(assetId, "OLTCOilLevelAndTemperature");
      removeChart(assetId, "CircleTemperature");
      removeChart(assetId, "CircleBushing");
      removeChart(assetId, "CircleOltcOilLevel");
      removeChart(assetId, "RollingAverageOfVectorLength");
      removeMeasurements(assetId, "CoreEarthingCurrent");
      removeChart(assetId, "CircleCoreEarthingCurrent");
      removeChart(assetId, "RollingAverageMoisture");
      removeMeasurements(assetId, "MeasuredMoisture");
      removeRollingAverageForAsset(assetId, "RollingAverageMoisture");
      removeMeasurements(assetId, "SF6Pressure");
      removeMeasurements(assetId, "SF6PressureVectorLength");
      removeRollingAverageForAsset(assetId, "SF6PressureRollingAverage");
    };
  }, [
    assetId,
    loadChart,
    loadMeasurements,
    loadRollingAverageForAsset,
    removeChart,
    removeMeasurements,
    removeRollingAverageForAsset
  ]);

  return (
    <>
      {showLoading && <DCLoading />}
      {showSucceeded && (
        <DCTabCharts
          assetId={assetId}
          measuredApparentCapacitanceChart={
            measuredApparentCapacitanceChart.data
          }
          oilTemperatureChart={oilTemperatureChart.data}
          oltcOilLevelAndTemperatureChart={oltcOilLevelAndTemperatureChart.data}
          rollingAverageOfVectorLengthChart={
            rollingAverageOfVectorLengthChart.data
          }
          coreEarthingCurrent={coreEarthingCurrentChart.data}
          measuredMoistureChart={measuredMoistureChart.data}
          sf6PressureChart={sf6PressureChart.data}
          sf6PressureVectorLengthChart={sf6PressureVectorLengthChart.data}
          circleTemperatureChart={circleTemperatureChart.data.Trends}
          circleBushingChart={circleBushingChart.data.Trends}
          circleOltCoilLevelChart={circleOltcOilLevelChart.data.Trends}
          circleCoreEarthingCurrentChart={
            circleCoreEarthingCurrentChart.data.Trends
          }
          moistureRollingAverage={moistureRollingAverageChart.data.Trends}
          sf6PressureRollingAverageChart={
            sf6PressureRollingAverageChart.data.Trends
          }
        />
      )}
      {showNotFound && <DCNotFound />}
      {showFailed && <DCFailed />}
    </>
  );
};

const DCLoading = () => <Processing />;

const DCNotFound = () => <WidgetNoDataMessage />;

const DCFailed = () => <WidgetErrorMessage />;

export default connect(
  (state: IState, ownProps: IDCTabOwnProps): IDCTabState => ({
    measuredApparentCapacitanceChart: getChartSelector(state)(
      ownProps.assetId,
      "MeasuredApparentCapacitance"
    ),
    oilTemperatureChart: getChartSelector(state)(
      ownProps.assetId,
      "OilTemperature"
    ),
    oltcOilLevelAndTemperatureChart: getChartSelector(state)(
      ownProps.assetId,
      "OLTCOilLevelAndTemperature"
    ),
    rollingAverageOfVectorLengthChart: getChartSelector(state)(
      ownProps.assetId,
      "RollingAverageOfVectorLength"
    ),
    coreEarthingCurrentChart: getChartSelector(state)(
      ownProps.assetId,
      "CoreEarthingCurrent"
    ),
    measuredMoistureChart: getChartSelector(state)(
      ownProps.assetId,
      "MeasuredMoisture"
    ),
    sf6PressureChart: getChartSelector(state)(ownProps.assetId, "SF6Pressure"),
    sf6PressureVectorLengthChart: getChartSelector(state)(
      ownProps.assetId,
      "SF6PressureVectorLength"
    ),
    circleTemperatureChart: getCircleChartSelector(state)(
      ownProps.assetId,
      "CircleTemperature"
    ),
    circleBushingChart: getCircleChartSelector(state)(
      ownProps.assetId,
      "CircleBushing"
    ),
    circleOltcOilLevelChart: getCircleChartSelector(state)(
      ownProps.assetId,
      "CircleOltcOilLevel"
    ),
    circleCoreEarthingCurrentChart: getCircleChartSelector(state)(
      ownProps.assetId,
      "CircleCoreEarthingCurrent"
    ),
    moistureRollingAverageChart: getCircleChartSelector(state)(
      ownProps.assetId,
      "RollingAverageMoisture"
    ),
    sf6PressureRollingAverageChart: getCircleChartSelector(state)(
      ownProps.assetId,
      "SF6PressureRollingAverage"
    )
  }),
  (dispatch: AppDispatch): IDCTabActions => ({
    loadChart: (assetId: string, chartName: ChartNames) =>
      dispatch(loadChart(assetId, chartName)),
    loadMeasurements: (
      assetId: string,
      parameters: string[],
      referenceLines: string[],
      chartName: ChartNames
    ) =>
      dispatch(
        loadMeasurements(assetId, parameters, referenceLines, chartName)
      ),
    loadRollingAverageForAsset: (
      assetId: string,
      chartConfiguration: { [key: string]: ICircleChartConfiguration },
      chartName: ChartNames
    ) =>
      dispatch(
        loadRollingAverageForAsset(assetId, chartConfiguration, chartName)
      ),
    removeChart: (assetId: string, chartName: ChartNames) =>
      dispatch(removeChart(assetId, chartName)),
    removeMeasurements: (assetId: string, chartName: ChartNames) =>
      dispatch(removeMeasurements(assetId, chartName)),
    removeRollingAverageForAsset: (assetId: string, chartName: ChartNames) =>
      dispatch(removeMeasurements(assetId, chartName))
  })
)(DCTab);
