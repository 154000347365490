// Copyright 2016-2024 Hitachi Energy. All rights reserved.

enum SotTypes {
  MINERAL_OIL_IEC_NETWORK_TRANSFORMER = "MineralOilIECNetworkTransformer",
  SILICONE_IEEE_NETWORK_TRANSFORMER = "SiliconeIEEENetworkTransformer",
  IEEE = "IEEE",
  IEC = "IEC"
}

export default SotTypes;
