// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { UserRoles } from "core/app/components/auth/Authorization";
import { IUser } from "core/app/reducers/settings/UserReducer";
import AuthorizationService from "core/app/services/AuthorizationService";
import Data from "core/data/models/Data";
import { useMemo } from "react";
import LifecycleStatus from "../../../models/LifecycleStatus";

const useIsReadOnlyData = (
  lifecycleStatus: LifecycleStatus,
  user: Data<IUser>
) =>
  useMemo(
    () =>
      lifecycleStatus === LifecycleStatus.Removed ||
      !AuthorizationService.isAuthorized(user.data, [
        UserRoles.Administrator,
        UserRoles.Engineer,
        UserRoles.LimitedEngineer
      ]),
    [user.data, lifecycleStatus]
  );

export default useIsReadOnlyData;
