// Copyright 2016-2024 Hitachi Energy. All rights reserved.

const coolingClassificationConfiguration = [
  "fans",
  "radiators",
  "pumps",
  "coolers"
];

export default coolingClassificationConfiguration;
