// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import ColumnAsset from "common/columns/Asset";
import ColumnRisk from "common/columns/Risk";
import { IColumnConfig } from "common/datagrid/DataGrid";
import SortOrders from "common/datagrid/models/SortOrders";
import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { config } from "utils/AppConfig";
import ColumnActions from "../components/Actions";
import ColumnCondition from "../components/Condition";
import ColumnIssue from "../components/Issue";
import ColumnIssueCreationDate from "../components/IssueCreationDate";
import ColumnMaintenancePriority from "../components/MaintenancePriority";
import ColumnStatus from "../components/Status";

export interface IOnMaintenancePriorityClickOptions {
  assetId: string;
  assetIssueId: string;
  assetName: string;
  maintenancePriority: number;
  maintenanceScore: number;
  normalizedScore: number;
  importance: number;
  costLevel: string;
  costFactor: number;
  urgencyLevel: string;
  urgencyFactor: number;
  impactLevel: string;
  impactFactor: number;
}

export interface IOnNumberOfActionsClickOptions {
  assetId: string;
  assetIssueId: string;
  assetName: string;
  assetType: string;
  assetLocation: string;
}

interface IUseIssuesGridColumnsConfigOptions {
  onMaintenancePriorityClick?: (
    options: IOnMaintenancePriorityClickOptions
  ) => void;
  onMaintenancePriorityRequest?: () => void;
  onNumberOfActionsClick?: (options: IOnNumberOfActionsClickOptions) => void;
}

const useIssuesGridColumnsConfig = (
  options?: IUseIssuesGridColumnsConfigOptions
) => {
  const columns: IColumnConfig[] = useMemo(
    () => [
      {
        component: (value, row) => (
          <ColumnAsset
            assetId={row.data["AssetId"]}
            assetName={row.data["AssetName"]}
            assetType={row.data["AssetType"]}
            location={row.data["Location"]}
          />
        ),
        id: "Asset",
        frozen: true,
        message: {
          defaultMessage: "Asset",
          id: "issues_page.grid.columns.asset"
        },
        weight: 5.5
      },
      {
        component: (value, row) => (
          <ColumnCondition value={row.data["DegradationScore"]} />
        ),
        defaultSortOrder: SortOrders.Desc,
        defaultGroupOrder: 3,
        id: "Condition",
        frozen: true,
        message: {
          defaultMessage: "Condition",
          id: "issues_page.grid.columns.condition"
        },
        weight: 2
      },
      {
        component: (value, row) => <ColumnRisk risk={value} />,
        id: "AssetRisk",
        frozen: true,
        message: {
          defaultMessage: "Risk",
          id: "issues_page.grid.columns.risk"
        },
        weight: 1.4
      },
      {
        component: (value, row) => (
          <ColumnIssue value={row.data["IssueSummary"]} />
        ),
        id: "Issue",
        message: {
          defaultMessage: "Issue",
          id: "issues_page.grid.columns.issue"
        },
        sortable: false,
        weight: 8
      },
      {
        component: (value, row) => (
          <ColumnMaintenancePriority
            onClick={async () => {
              options?.onMaintenancePriorityRequest?.();

              const assetId = row.data["AssetId"];
              const assetIssueId = row.data["AssetIssueId"];
              const assetName = row.data["AssetName"];

              const maintenanceUrl = UrlService.getApiUrl(
                config.api.watchlist.issuesMaintenancePriorityUrl,
                {
                  issueId: assetIssueId
                }
              );
              const maintenance = await EndpointService.getJson(maintenanceUrl);
              const {
                Score: maintenanceScore,
                PriorityScore: maintenancePriority
              } = maintenance;

              let normalizedScore: number;
              let importance: number;
              let costLevel: string;
              let costFactor: number;
              let urgencyLevel: string;
              let urgencyFactor: number;
              let impactLevel: string;
              let impactFactor: number;
              if (maintenance) {
                normalizedScore = maintenance["NormalizedScore"];
                importance = maintenance["Importance"];
                costLevel = maintenance["CostLevel"];
                costFactor = maintenance["CostFactor"];
                urgencyLevel = maintenance["UrgencyLevel"];
                urgencyFactor = maintenance["UrgencyFactor"];
                impactLevel = maintenance["ImpactLevel"];
                impactFactor = maintenance["ImpactFactor"];
              }

              options?.onMaintenancePriorityClick?.({
                assetId,
                assetIssueId,
                assetName,
                maintenancePriority,
                maintenanceScore,
                normalizedScore,
                importance,
                costLevel,
                costFactor,
                urgencyLevel,
                urgencyFactor,
                impactLevel,
                impactFactor
              });
            }}
            value={value}
          />
        ),
        defaultSortOrder: SortOrders.Desc,
        defaultGroupOrder: 2,
        id: "MaintenancePriority",
        message: {
          defaultMessage: "MP Score",
          id: "issues_page.grid.columns.maintenance_priority"
        },
        HeaderTooltip: () => (
          <FormattedMessage
            defaultMessage="Maintenance Priority"
            id="issues_page.grid.columns.maintenance_priority.tooltip"
          />
        ),
        weight: 1.9
      },
      {
        component: (value, row) => <ColumnStatus value={row.data["Status"]} />,
        defaultSortOrder: SortOrders.Asc,
        defaultGroupOrder: 1,
        id: "Status",
        message: {
          defaultMessage: "Status",
          id: "issues_page.grid.columns.status"
        },
        weight: 1.6
      },
      {
        component: (value) => <ColumnIssueCreationDate date={value} />,
        id: "IssueCreationDate",
        frozen: true,
        message: {
          defaultMessage: "Issue Creation Date",
          id: "issues_page.grid.columns.issue_creation_date"
        },
        weight: 2.8
      },
      {
        component: (value, row) => (
          <ColumnActions
            onClick={() => {
              const assetId = row.data["AssetId"];
              const assetIssueId = row.data["AssetIssueId"];
              const assetName = row.data["AssetName"];
              const assetType = row.data["AssetType"];
              const assetLocation = row.data["Location"];

              if (options && options.onNumberOfActionsClick)
                options.onNumberOfActionsClick({
                  assetId,
                  assetIssueId,
                  assetName,
                  assetType,
                  assetLocation
                });
            }}
            value={row.data["NumberOfActions"]}
          />
        ),
        id: "Actions",
        message: {
          defaultMessage: "Actions",
          id: "issues_page.grid.columns.actions"
        },
        weight: 1.8
      }
    ],
    [options]
  );

  return columns;
};

export default useIssuesGridColumnsConfig;
