// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import SearchParams from "@pg/common/build/models/SearchParams";
import {
  useFeature,
  VisualizationKeys
} from "core/app/components/FeatureToggle";
import { LayoutRoute } from "core/app/components/RouterProvider";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import DCTab from "../../DCTab";

interface IUseTransformerDCRoutesProps {
  assetId: string;
}

const useTransformerDCRoutes = ({ assetId }: IUseTransformerDCRoutesProps) => {
  const { isAvailable } = useFeature();
  const intl = useIntl();
  const searchParams = useMemo(() => new SearchParams({ assetId }), [assetId]);

  const routes = useMemo<LayoutRoute[]>(() => {
    return [
      isAvailable(VisualizationKeys.AssetDetailPage_Tabs_Parameters_DC)
        ? {
            id: "Assets-DetailPage-Parameters-DC",
            route: "DC/*",
            displayName: intl.formatMessage({
              defaultMessage: "DC",
              id: "detail_page.widgets.circuit_breaker.dc.tab_title"
            }),
            to: {
              pathname: "/Assets/DetailPage/Parameters/DC",
              search: searchParams.toString()
            },
            element: <DCTab assetId={assetId} />
          }
        : undefined
    ].filter(Boolean);
  }, [assetId, intl, isAvailable, searchParams]);

  return routes;
};

export default useTransformerDCRoutes;
