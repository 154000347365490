// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { notifications } from "@pg/common/build/components/Notifications";
import EndpointService from "core/data/services/EndpointService";
import { trim } from "lodash";
import { IntlShape } from "react-intl";

export default class DocumentService {
  static saveOrOpen(url: string, contentType: string, intl: IntlShape) {
    EndpointService.get<ArrayBuffer>(
      url,
      (request, data) => {
        let fileName = request
          .getResponseHeader("content-disposition")
          .split("filename=")[1]
          .split(";")[0];
        fileName = trim(fileName, '"');
        DocumentService.saveOrOpenDocument(data, fileName, contentType);
      },
      (request) => {
        DocumentService.showError(intl);
      },
      contentType,
      "binary"
    );
  }

  private static saveOrOpenDocument(
    buffer: ArrayBuffer,
    filename: string,
    contentType: string
  ) {
    const blob = new Blob([buffer], { type: "application/pdf" });
    if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
      (window.navigator as any).msSaveOrOpenBlob(blob, filename);
    } else {
      const objectUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = objectUrl;
      link.type = contentType;
      link.download = filename;
      link.click();
      window.URL.revokeObjectURL(objectUrl);
    }
  }

  private static showError(intl: IntlShape) {
    notifications.error({
      message: intl.formatMessage({
        id: "external-panel.document.loading_failed",
        defaultMessage: "Loading document failed"
      })
    });
  }
}
