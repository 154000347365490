// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Icon } from "@pg/common";
import { PropsWithChildren, useEffect, useState } from "react";
import { styled } from "styled-components";
import { colorGray10 } from "styles/ColorVariables";
import {
  fontSizeMedium,
  spacingLarge,
  spacingMedium,
  spacingXLarge
} from "styles/StyleVariables";

const STORAGE_KEY_PREFIX = "isDropDownOpened_";

type DropDownProps = PropsWithChildren<{
  title: string;
  maxHeight: number;
  Footer?: React.ReactNode;
  className?: string;
  storageKey?: string;
  isOpenedByDefault?: boolean;
  changeHandler?: (isOpened: boolean) => void;
}>;

const getIsInitiallyOpened = (
  storageKey: string,
  isOpenedByDefault?: boolean
) => {
  const storedValue = localStorage.getItem(STORAGE_KEY_PREFIX + storageKey);

  switch (true) {
    case storedValue === "true":
      return true;
    case storedValue === "false":
      return false;
    default:
      return isOpenedByDefault;
  }
};

const DropDown = ({
  title,
  maxHeight,
  className,
  storageKey,
  isOpenedByDefault,
  Footer,
  changeHandler,
  children
}: DropDownProps) => {
  const [isOpened, setIsOpened] = useState(
    getIsInitiallyOpened(storageKey, isOpenedByDefault)
  );

  useEffect(() => {
    localStorage.setItem(STORAGE_KEY_PREFIX + storageKey, isOpened.toString());
    changeHandler?.(isOpened);
  }, [changeHandler, isOpened, storageKey]);

  return (
    <div className={className}>
      <Title
        onClick={() => setIsOpened(!isOpened)}
        data-qa="watchlist-tile-header"
      >
        <span>{title}</span>
        <Arrow
          className={isOpened ? "top" : ""}
          dataQa="expand-watch-list-icon"
          name="chevron_right"
          size="lg"
        />
      </Title>
      <Body
        className={`${isOpened && "visible"} watchlist-tile-body`}
        maxHeight={maxHeight}
      >
        {children}
      </Body>
      {Footer && (
        <FooterWrapper
          className={isOpened && "visible"}
          data-qa="watchlist-tile-footer"
        >
          {Footer}
        </FooterWrapper>
      )}
    </div>
  );
};

const Title = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${fontSizeMedium};
  height: 60px;
  padding: ${spacingLarge} ${spacingXLarge};
  cursor: pointer;
  border-bottom: 1px solid ${colorGray10};
`;

const Body = styled("div")<{ maxHeight: number }>`
  display: none;
  overflow-x: hidden;
  overflow-y: auto;
  padding: ${spacingXLarge};
  max-height: ${(props) => props.maxHeight}px;
  opacity: 0;
  animation: 0.3s body-animation ease-out;

  &.visible {
    display: block;
    opacity: 1;
  }

  @keyframes body-animation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const Arrow = styled(Icon)`
  transform: rotate(90deg);

  &.top {
    transform: rotate(-90deg);
  }
`;

const FooterWrapper = styled.div`
  display: none;
  padding: ${spacingMedium} ${spacingXLarge};
  border-top: 1px solid ${colorGray10};

  &.visible {
    display: block;
  }
`;

export default DropDown;
