// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { List, Typography } from "antd";
import dayjs from "dayjs";
import { FormattedDate, useIntl } from "react-intl";
import styled from "styled-components";
import {
  colorAppBackground,
  colorGray90,
  colorPrimary,
  colorStatusOrange
} from "styles/ColorVariables";
import {
  spacingBase,
  spacingLarge,
  spacingXLarge
} from "styles/StyleVariables";
import IIssue from "../models/Issue";

const { Text } = Typography;

interface IIssuesListAssetInfoIssuesProps {
  className?: string;
  issues: IIssue[];
}

const IssuesListAssetInfoIssues = ({
  className = "",
  issues
}: IIssuesListAssetInfoIssuesProps) => {
  const { formatMessage } = useIntl();

  return (
    <div className={className}>
      <List
        dataSource={issues}
        renderItem={(item) => (
          <List.Item
            key={item.AssetIssueId}
            className="list-item"
            data-qa="asset-issue"
          >
            <div className="issue-info">
              <Text className="status" strong>
                {formatMessage({
                  id: `locations_page.side_panel.issue.status.${item.Status}`,
                  defaultMessage: "Status"
                })}
              </Text>
              <div className="issue-update-date-wrapper">
                <FormattedDate value={dayjs(item.UpdateDate).toDate()} />
              </div>
            </div>
            <Text strong className="header">
              {item.HeaderText}
            </Text>
            <Text className="condition">{item.ConditionText}</Text>
          </List.Item>
        )}
      />
    </div>
  );
};

export default styled(IssuesListAssetInfoIssues)`
  .ant-list-items {
    display: flex;
    flex-direction: column;
    width: 100%;

    .list-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-left: ${spacingLarge};

      &:first-child {
        border-top: 1px solid ${colorAppBackground};
      }

      .issue-info {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .new-issue-indicator {
          color: ${colorStatusOrange};
          padding: 0px 10px 2px 0px;
        }

        .status {
          font-size: ${spacingLarge};
          color: ${colorPrimary};
          text-transform: uppercase;
        }

        .issue-update-date-wrapper {
          color: ${colorGray90};
          font-weight: 600;
          padding-right: ${spacingXLarge};
        }
      }

      .header {
        font-size: ${spacingLarge};
      }

      .condition {
        font-size: ${spacingLarge};
        padding-top: ${spacingBase};
      }
    }
  }
`;
