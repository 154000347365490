// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Map } from "immutable";
import { Reducer, Action } from "redux";

import {
  Actions,
  ICreateFormAction,
  IResetFormAction,
  IRemoveFormAction,
  ICreateInputAction,
  ISetInputValue
} from "../actions/FormActions";
import Form from "../models/Form";
import IState from "../models/IState";
import FormService from "../services/FormService";

const formReducer: Reducer<IState> = (
  state = {
    forms: Map<string, Form>()
  },
  action: Action
) => {
  switch (action.type) {
    case Actions.Form_CreateForm:
      return FormService.createForm(
        state,
        (action as ICreateFormAction).formName
      );
    case Actions.Form_ResetForm:
      return FormService.resetForm(
        state,
        (action as IResetFormAction).formName
      );
    case Actions.Form_RemoveForm:
      return FormService.removeForm(
        state,
        (action as IRemoveFormAction).formName
      );
    case Actions.Form_CreateInput:
      return FormService.createInput(
        state,
        (action as ICreateInputAction<any>).formName,
        (action as ICreateInputAction<any>).inputName,
        (action as ICreateInputAction<any>).defaultValue,
        (action as ICreateInputAction<any>).value
      );
    case Actions.Form_SetInputValue:
      state = FormService.setInputValue(
        state,
        (action as ISetInputValue<any>).formName,
        (action as ISetInputValue<any>).inputName,
        (action as ISetInputValue<any>).value,
        (action as ISetInputValue<any>).validators
      );
      state = FormService.setFormProps(
        state,
        (action as ISetInputValue<any>).formName
      );
      return state;
    default:
      return state;
  }
};

export default formReducer;
