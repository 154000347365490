// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Scale from "../models/Scale";
import Svg from "../models/Svg";

class PhaseAngleService {
  static drawPhaseAngles = (
    svg: Svg,
    width: number,
    height: number,
    phaseAColor: string,
    phaseBColor: string,
    phaseCColor: string,
    scale: Scale,
    range: number
  ) => {
    const drawPhaseAngle = (color: string, offset: number) =>
      PhaseAngleService.drawPhaseAngle(
        svg,
        width,
        height,
        color,
        offset,
        scale,
        range
      );

    drawPhaseAngle(phaseAColor, 9 / 12);
    drawPhaseAngle(phaseBColor, 5 / 12);
    drawPhaseAngle(phaseCColor, 1 / 12);
  };

  private static drawPhaseAngle = (
    svg: Svg,
    width: number,
    height: number,
    color: string,
    offset: number,
    scale: Scale,
    range: number
  ) => {
    const r = scale(range * 1.03);
    const usedPart = 3 / 12;
    const freePart = 9 / 12;

    svg
      .append("circle")
      .attr("cx", width / 2)
      .attr("cy", height / 2)
      .attr("fill", "none")
      .attr("stroke", color)
      .attr(
        "stroke-dasharray",
        `${usedPart * r * Math.PI} ${freePart * r * Math.PI}`
      )
      .attr("stroke-dashoffset", -(offset * r * Math.PI))
      .attr("stroke-width", r)
      .attr("r", r / 2);
  };
}

export default PhaseAngleService;
