// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { FormattedMessage, FormattedNumber } from "react-intl";
import styled from "styled-components";
import Label from "./Label";
import NumberValue from "./NumberValue";

interface IParametersProps {
  parametersCount: number;
}

const Parameters = ({ parametersCount }: IParametersProps) => {
  return (
    <CenteredColumnWrapper className="asset-ribbon-section">
      <CenteredRibbonColumn className="asset-ribbon-column">
        <div data-qa="header-parameters-label">
          <Label>
            <FormattedMessage
              id="asset_detail_page.header.parameters"
              defaultMessage="Parameters"
            />
          </Label>
        </div>
        <div data-qa="header-parameters-count">
          <NumberValue className="blue-accent">
            <FormattedNumber value={parametersCount} />
          </NumberValue>
        </div>
      </CenteredRibbonColumn>
    </CenteredColumnWrapper>
  );
};

const CenteredColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CenteredRibbonColumn = styled.div`
  align-items: center;
`;

export default Parameters;
