// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Col, Row } from "antd";
import DataSource from "common/DuvalAnalysis/models/DataSource";
import DuvalPentagonChart from "common/DuvalPentagon/components/DuvalPentagonChart";
import Processing from "components/common/Processing";
import {
  WidgetErrorMessage,
  WidgetNoDataMessage
} from "components/common/widget/Widget";
import { Statuses } from "core/data/models/Data";
import { useIntl } from "react-intl";
import useDuvalPentagonTab from "../hooks/useDuvalPentagonTab";

interface IDuvalPentagonTabProps {
  assetId: string;
  dataSource: DataSource;
}

const DuvalPentagonTab = ({ assetId, dataSource }: IDuvalPentagonTabProps) => {
  const intl = useIntl();
  const { duvalPoints } = useDuvalPentagonTab(assetId, dataSource);

  switch (duvalPoints.status) {
    case Statuses.Loading:
      return <Processing />;
    case Statuses.Succeeded:
      return (
        <div className="analytics duval-pentagons" data-qa="duval-pentagons">
          <Row>
            <Col span={8}>
              <DuvalPentagonChart
                type={3}
                points={duvalPoints.data}
                dataSource={dataSource}
              />
            </Col>
            <Col span={8}>
              <DuvalPentagonChart
                type={1}
                points={duvalPoints.data}
                dataSource={dataSource}
              />
            </Col>
            <Col span={8}>
              <DuvalPentagonChart
                type={2}
                points={duvalPoints.data}
                dataSource={dataSource}
              />
            </Col>
          </Row>
        </div>
      );
    case Statuses.NotFound:
      return (
        <div className="analytics duval-pentagons" data-qa="duval-pentagons">
          {duvalPoints.responseJSON ? (
            <WidgetErrorMessage
              messageId={`detail_page.widgets.analytics.transformers.error.${duvalPoints.responseJSON}`}
              messageDefault={intl.formatMessage({
                id: "detail_page.widgets.analytics.transformers.error.visualization_is_not_available",
                defaultMessage: "The visualization is not available"
              })}
            />
          ) : (
            <WidgetNoDataMessage />
          )}
        </div>
      );
    case Statuses.Failed:
      return (
        <div className="analytics duval-pentagons" data-qa="duval-pentagons">
          <WidgetErrorMessage
            messageId="global.empty"
            messageDefault={""}
            messageValues={""}
          />
        </div>
      );
    default:
      return (
        <div className="analytics duval-pentagons" data-qa="duval-pentagons">
          <div className="tile">
            <div className="tile-content empty" />
          </div>
        </div>
      );
  }
};

export default DuvalPentagonTab;
