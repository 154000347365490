// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import SearchParams from "@pg/common/build/models/SearchParams";
import {
  useFeature,
  VisualizationKeys
} from "core/app/components/FeatureToggle";
import { LayoutRoute } from "core/app/components/RouterProvider";
import AgingTab from "features/detailpage/features/aging/components/AgingTab";
import DielectricTab from "features/detailpage/features/dielectric/components/DielectricTab";
import { useMemo } from "react";
import { useIntl } from "react-intl";

interface IUseCircuitBreakerRoutesProps {
  assetId: string;
  implementationId: string;
  modelId: string;
}

const useCircuitBreakerRoutes = ({
  assetId,
  implementationId,
  modelId
}: IUseCircuitBreakerRoutesProps) => {
  const { isAvailable } = useFeature();
  const intl = useIntl();
  const model = useMemo(
    () => ({ modelId, implementationId }),
    [implementationId, modelId]
  );
  const searchParams = useMemo(() => new SearchParams({ assetId }), [assetId]);

  const routes = useMemo<LayoutRoute[]>(() => {
    return [
      isAvailable(VisualizationKeys.AssetDetailPage_Tabs_Parameters_Aging)
        ? {
            id: "Assets-DetailPage-Parameters-Aging",
            route: "Aging/*",
            displayName: intl.formatMessage({
              defaultMessage: "Aging",
              id: "detail_page.widgets.circuit_breaker.aging.tab_title"
            }),
            to: {
              pathname: "/Assets/DetailPage/Parameters/Aging",
              search: searchParams.toString()
            },
            element: <AgingTab assetId={assetId} />
          }
        : undefined,
      isAvailable(VisualizationKeys.AssetDetailPage_Tabs_Parameters_Dielectric)
        ? {
            id: "Assets-DetailPage-Parameters-Dielectric",
            route: "Dielectric/*",
            displayName: intl.formatMessage({
              defaultMessage: "Dielectric",
              id: "detail_page.widgets.circuit_breaker.dielectric.tab_title"
            }),
            to: {
              pathname: "/Assets/DetailPage/Parameters/Dielectric",
              search: searchParams.toString()
            },
            element: <DielectricTab assetId={assetId} model={model} />
          }
        : undefined
    ].filter(Boolean);
  }, [isAvailable, intl, searchParams, assetId, model]);

  return routes;
};

export default useCircuitBreakerRoutes;
