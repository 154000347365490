// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import IRegisterConfiguration from "./IRegisterConfiguration";

export enum ConnectedDeviceKey {
  DeviceType = "DeviceType",
  DeviceName = "DeviceName",
  DeviceHost = "DeviceHost",
  DevicePort = "DevicePort",
  UnitId = "UnitId",
  RegisterAddressOffset = "RegisterAddressOffset",
  RegisterConfiguration = "RegisterConfiguration"
}

interface IConnectedDevice {
  [ConnectedDeviceKey.DeviceType]: string;
  [ConnectedDeviceKey.DeviceName]: string;
  [ConnectedDeviceKey.DeviceHost]: string;
  [ConnectedDeviceKey.DevicePort]: number;
  [ConnectedDeviceKey.UnitId]: number;
  [ConnectedDeviceKey.RegisterAddressOffset]: number;
  [ConnectedDeviceKey.RegisterConfiguration]: IRegisterConfiguration[];
}

export default IConnectedDevice;
