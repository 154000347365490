// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { IState } from "reducers/Index";

const getAssetTreeSelector = createSelector(
  (state: IState) => state.detailPage.assetTree,
  (assetTree) => {
    return assetTree;
  }
);

export default getAssetTreeSelector;
