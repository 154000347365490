// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Messages, {
  IMessagesActions,
  IMessagesData,
  IMessagesOwnProps
} from "features/detailpage/features/messages/components/Messages";
import getAssetDetailsSelector from "features/detailpage/selectors/getAssetDetailsSelector";
import { IntlShape } from "react-intl";
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import { AppDispatch } from "store";
import { loadMessages } from "../actions/MessagesActions";
import getMessagesSelector from "../selectors/getMessagesSelector";

export function mapStateToProps(
  state: IState,
  { assetId }: IMessagesOwnProps
): IMessagesData {
  return {
    messages: getMessagesSelector(state),
    assetDetails: getAssetDetailsSelector(state)(assetId)
  };
}

export function mapDispatchToProps(dispatch: AppDispatch): IMessagesActions {
  return {
    loadMessages: (intl: IntlShape, assetId: string) =>
      loadMessages(dispatch, intl, assetId)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
