// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { EventEmitter } from "events";
import { findIndex } from "lodash";

export const scrollEvent = "scroll";

interface IObject {
  id: string;
  eventEmitter: EventEmitter;
}

class EventHub {
  private objects: IObject[];

  constructor() {
    this.objects = [];
  }

  add(objectId: string, eventEmitter: EventEmitter): void {
    this.remove(objectId);
    this.objects.push({
      id: objectId,
      eventEmitter: eventEmitter
    });
  }

  get(objectId: string): EventEmitter {
    const objects = this.objects.filter(o => o.id === objectId);
    if (objects.length > 0) return objects[0].eventEmitter;
    return undefined;
  }

  remove(objectId: string): void {
    const i = findIndex(this.objects, o => o.id === objectId);
    if (i >= 0) this.objects.splice(i, 1);
  }
}

export default new EventHub();
