// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import ICompanyHierarchyNode from "models/ICompanyHierarchyNode";

const getCompanyHierarchyName = (
  companyHierarchyNodes: ICompanyHierarchyNode[],
  assetHierarchyCode: string
) => {
  if (companyHierarchyNodes) {
    const filteredNodes = companyHierarchyNodes.find(
      (x) => x.HierarchyCode === assetHierarchyCode
    );

    if (filteredNodes) {
      return filteredNodes.Name;
    }
  }
  return "-";
};

export default getCompanyHierarchyName;
