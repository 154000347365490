// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { loadActions } from "features/issues/actions/IssuesActions";
import { useCallback } from "react";
import { useAppDispatch } from "store";

const useLoadActions = () => {
  const dispatch = useAppDispatch();

  return useCallback(
    (assetIssueId: string) => loadActions(dispatch, assetIssueId),
    [dispatch]
  );
};

export default useLoadActions;
