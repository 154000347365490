// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { FormattedMessage } from "react-intl";

const DuvalTriangleType3Note = () => {
  return (
    <div className="duval-triangle-note _small">
      <span>
        <FormattedMessage
          id="detail_page.widgets.analytics.transformers.DuvalTriangle.important_note.type1.text"
          defaultMessage="A fault appearing in zone D2 may also be due in some cases to a mixture of faults D1 and T3."
        />
      </span>
    </div>
  );
};

export default DuvalTriangleType3Note;
