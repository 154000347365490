// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Reducer } from "redux/index";

import { Actions } from "core/app/actions/settings/TenantActions";
import Data from "core/data/models/Data";
import { IAjaxAction } from "reducers/Index";

export interface ITenant {
  DisplayName: string;
  InternalName: string;
}

export interface IState {
  tenants: Data<ITenant[]>;
}

const tenantReducer: Reducer<IState> = (
  state: IState,
  action: IAjaxAction<ITenant[]>
) => {
  switch (action.type) {
    case Actions.Settings_SetTenantList:
      state = Object.assign({}, state, {
        tenants: new Data(action.request, action.data)
      });
      return state;
    default:
      return state;
  }
};

export default tenantReducer;
