// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { IData } from "@apm/widgets/build/widgets/OperationsCharts";
import {
  IGroup,
  IRowUpdated,
  IState as IOperationsParametersState
} from "@apm/widgets/build/widgets/OperationsParameters";
import { useCallback, useEffect, useRef, useState } from "react";

import IOperation from "../models/IOperation";

import useUrlSearchParams from "hooks/useUrlSearchParams";
import useParametersUrl from "../hooks/useParametersUrl";
import OperationsChartCreator from "../services/OperationsChartCreator";
import OperationsParametersCreator from "../services/OperationsParametersCreator";
import { getParametersDefaultState } from "../utils/getParametersDefaultState";
import mergeParametersWidgetState from "../utils/mergeParametersWidgetState";

const sliderTimesParamKey = "o_slider";

interface IUseOperationsSuccededProps {
  operations: IOperation;
}

const useOperationsSucceded = ({ operations }: IUseOperationsSuccededProps) => {
  const chartCreator = useRef<OperationsChartCreator>(
    new OperationsChartCreator()
  );
  const groupsCreator = useRef<OperationsParametersCreator>(
    new OperationsParametersCreator()
  );

  const { getUrlParam, setUrlParam } = useUrlSearchParams();
  const { getParamsFromUrl, setParamsInUrl } = useParametersUrl();

  const [group, setGroup] = useState<IGroup[]>();
  const [parametersState, setParametersState] =
    useState<IOperationsParametersState>(() => {
      const parametersState = getParamsFromUrl() || getParametersDefaultState();
      return parametersState;
    });
  const [chartData, setChartData] = useState<IData[]>([]);
  const [sliderTimes, setSliderTimes] = useState<{
    slopeTime: number;
    valueTime: number;
  }>(() => {
    try {
      const [valueTime, slopeTime] = JSON.parse(
        getUrlParam(sliderTimesParamKey)
      );
      return {
        valueTime: valueTime || 30,
        slopeTime: slopeTime || 50
      };
    } catch (e) {}

    return {
      valueTime: 30,
      slopeTime: 50
    };
  });

  const handleOnParametersChange = useCallback(
    (rowUpdated: IRowUpdated) => {
      let newParametersState = { ...parametersState };
      newParametersState = mergeParametersWidgetState(
        newParametersState,
        rowUpdated
      );
      setParamsInUrl(newParametersState);
      setParametersState(newParametersState);
    },
    [parametersState, setParamsInUrl]
  );

  const handleOnTimesLinesAfterChange = useCallback(
    (valueTime: number, slopeTime: number) => {
      setUrlParam(sliderTimesParamKey, JSON.stringify([valueTime, slopeTime]));
      setSliderTimes({ valueTime, slopeTime });
    },
    [setUrlParam]
  );

  useEffect(() => {
    const createChartData = async () => {
      const data = await chartCreator.current.create(
        operations,
        parametersState
      );
      setChartData(data);
    };

    createChartData();
  }, [operations, parametersState]);

  useEffect(() => {
    const createParametersData = async () => {
      const { slopeTime, valueTime } = sliderTimes;
      const group = await groupsCreator.current.create(
        operations,
        valueTime,
        slopeTime
      );
      setGroup(group);
    };

    createParametersData();
  }, [operations, sliderTimes]);

  return {
    group,
    chartData,
    sliderTimes,
    parametersState,
    handleOnParametersChange,
    handleOnTimesLinesAfterChange
  };
};

export default useOperationsSucceded;
