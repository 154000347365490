// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { IThreshold } from "@apm/widgets/build/widgets/ParameterCharts";
import {
  colorStatusGreen,
  colorStatusOrange,
  colorStatusRed,
  colorStatusYellow
} from "@pg/common/build/styles/ColorVariables";
import { IntlShape } from "react-intl";
import IThresholds from "../models/IThresholds";

const isCondition = (key: string, n: string) =>
  key.toLowerCase().endsWith(`condition ${n}`) ||
  key.toLowerCase().endsWith(`condition ${n} online`);

const isCondition1 = (key: string) => isCondition(key, "1");
const isCondition2 = (key: string) => isCondition(key, "2");
const isCondition3 = (key: string) => isCondition(key, "3");
const isCondition4 = (key: string) => isCondition(key, "4");
const isRatioThreshold = (key: string, level: "low" | "high") =>
  key.toLowerCase().endsWith(`ratio ${level} threshold`) ||
  key.toLowerCase().endsWith(`ratio ${level} threshold online`);

const getColor = (
  name: string,
  gasStandard: string,
  gasStandardRevision: string
) => {
  const isIEEE2019 =
    gasStandard === "IEEE" && gasStandardRevision?.indexOf("2019") >= 0;

  return isCondition1(name)
    ? colorStatusGreen
    : isCondition2(name)
    ? colorStatusYellow
    : isCondition3(name) && isIEEE2019
    ? colorStatusRed
    : isCondition3(name)
    ? colorStatusOrange
    : isCondition4(name)
    ? colorStatusRed
    : isRatioThreshold(name, "low")
    ? colorStatusOrange
    : isRatioThreshold(name, "high")
    ? colorStatusOrange
    : undefined;
};

const getConditionN = (key: string) =>
  isCondition1(key)
    ? 1
    : isCondition2(key)
    ? 2
    : isCondition3(key)
    ? 3
    : isCondition4(key)
    ? 4
    : undefined;

const getDisplayName = (intl: IntlShape, key: string, index: number) => {
  let condition = getConditionN(key);

  // if the "condition" is bigger then "index + 1" then we have a gap in the conditions (e.g. 1, 3, 4)
  if (condition !== index + 1) {
    condition = index + 1;
  }

  return intl.formatMessage(
    {
      defaultMessage: "Condition {n}",
      id: "detail_page.widgets.analytics.transformers.dga.condition"
    },
    {
      n: condition
    }
  );
};

const getCondition1 = (thresholds: IThresholds) => {
  if (thresholds) {
    const keys = Object.keys(thresholds);
    if (!keys.length) return;

    return {
      "Condition 1": thresholds[keys[0]].map((t) => ({
        ...t,
        Value: 0
      }))
    };
  }

  return undefined;
};

const mapToThresholds = (
  intl: IntlShape,
  gasName: string,
  gasStandard: string,
  gasStandardRevision: string,
  thresholds: IThresholds
) => {
  if (thresholds) {
    const condition1 =
      gasName !== "OxideOfCarbonRatio" ? getCondition1(thresholds) : undefined;

    const computedThresholds = condition1
      ? { ...condition1, ...thresholds }
      : thresholds;

    return Object.keys(computedThresholds).map<IThreshold>((name, index) => ({
      name,
      displayName: getDisplayName(intl, name, index),
      color: getColor(name, gasStandard, gasStandardRevision),
      sections: computedThresholds[name].map((t) => ({
        start: t.Start,
        end: t.End,
        value: t.Value
      }))
    }));
  }
};

export default mapToThresholds;
