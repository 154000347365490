// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { ReactNode } from "react";
import { FormattedNumber } from "react-intl";

export interface IValueColumnProps {
  children?: ReactNode;
  value: number;
}

const ValueColumn = ({ value, children }: IValueColumnProps) => (
  <div className="grid-cell value">
    {value && (
      <FormattedNumber
        value={value}
        minimumFractionDigits={1}
        maximumFractionDigits={3}
      />
    )}
    {children}
  </div>
);

export default ValueColumn;
