// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { FilterNames } from "@pg/common/build/components/FilterPanel";
import { SelectedFilters } from "common/FilterBar";
import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { isEmpty, isNil } from "lodash";
import AssetRiskTypes from "models/AssetRiskTypes";
import IAssetRiskCountsTree from "models/IAssetRiskCountsTree";
import LifecycleStatus from "models/LifecycleStatus";
import { config } from "utils/AppConfig";
import setupFilter from "utils/setupFilter";

const loadAssetsDistribution = (type: AssetRiskTypes, id?: string[]) => {
  return new Promise<IAssetRiskCountsTree[]>((resolve, reject) => {
    const url = UrlService.getApiUrl(
      config.api.homepage.assetRiskVisualization,
      undefined,
      { type }
    );

    let filters: SelectedFilters = {
      selects: []
    };

    if (!isNil(id) && !isEmpty(id)) {
      const filterName: FilterNames =
        type === "RiskByAssetType"
          ? "AssetTypeWithSubType"
          : type === "RiskByCompanyHierarchy"
          ? "CompanyHierarchy"
          : type === "RiskByOrganization"
          ? "AssetOrganizationWithRegionAndLocation"
          : undefined;

      filters = setupFilter(filters, filterName, id);
    }

    filters = setupFilter(filters, "AssetStatus", [LifecycleStatus.InService]);

    EndpointService.postJson<IAssetRiskCountsTree[]>(
      url,
      (request, data) => {
        resolve(data);
      },
      (request) => {
        reject();
      },
      {
        search: filters.search,
        filters: filters.selects,
        level: !isNil(id) ? id.length : undefined
      }
    );
  });
};

export default loadAssetsDistribution;
