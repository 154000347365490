// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Data from "core/data/models/Data";
import { IState } from "reducers/Index";
import { createSelector } from "reselect";
import { dataKey } from "../actions/AssetRiskDashboardActions";
import TrendChart from "../models/TrendChart";

const getTrendChartSelector = createSelector(
  (state: IState) => state.data[dataKey] as Data<TrendChart>,
  (stationRisk) => stationRisk
);

export default getTrendChartSelector;
