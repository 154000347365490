// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { ChartOptions } from "billboard.js";
import { IAgingOperationsChartLegendItem } from "features/detailpage/features/aging/models/IAgingOperationsChartLegendItem";
import getDefaultConfiguration, {
  AgingAlarmLegendId,
  AgingWarningLegendId,
  colors
} from "./DefaultConfiguration";

export default class AgingOperationsService {
  public static getChartConfiguration(
    agingOperations: number,
    warningThreshold: number,
    alarmThreshold: number
  ): ChartOptions {
    return getDefaultConfiguration(
      agingOperations,
      warningThreshold,
      alarmThreshold
    );
  }

  public static getChartLegendData(): IAgingOperationsChartLegendItem[] {
    return [
      {
        name: "Warning",
        color: colors.warning,
        id: AgingWarningLegendId,
        isGridLine: true
      },
      {
        name: "Alarm",
        color: colors.alarm,
        id: AgingAlarmLegendId,
        isGridLine: true
      }
    ];
  }
}
