// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import ISelectOption from "../../../models/ISelectOption";

const dataTypeOptions: ISelectOption[] = [
  {
    value: "int",
    label: "int"
  },
  {
    value: "float",
    label: "float"
  }
];

export default dataTypeOptions;
