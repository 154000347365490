// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { LegendItem } from "common/ChartLegend";
import useLegend from "common/ChartLegend/hooks/useLegend";
import { IAgingOperationsChartLegendItem } from "features/detailpage/features/aging/models/IAgingOperationsChartLegendItem";
import * as React from "react";
import "./AgingOperationsBarChartLegend.less";
import AgingOperationsBarChartLegendItem from "./AgingOperationsBarChartLegendItem";

interface IAgingOperationsBarChartLegendProps {
  plots: IAgingOperationsChartLegendItem[];
  series: string;
  onFocus?: (series: string, plot: string) => void;
  onRevert?: (series: string) => void;
  onToggle?: (series: string, plot: string) => void;
}

const AgingOperationsBarChartLegend = ({
  plots,
  series,
  onFocus,
  onRevert,
  onToggle
}: IAgingOperationsBarChartLegendProps) => {
  const items = plots.map<LegendItem>((p: IAgingOperationsChartLegendItem) => ({
    id: p.id,
    name: p.name,
    color: p.color,
    defaultSelected: !p.isHidden
  }));

  const { statuses } = useLegend(items);

  return (
    <div className="container aging-operations chart-legend">
      <div className="bootstrap-row aging-operations__row">
        {plots.map((item: IAgingOperationsChartLegendItem, index: number) => (
          <div className="item text-right" key={item.id}>
            <AgingOperationsBarChartLegendItem
              item={item}
              status={statuses[item.id]}
              key={`${series}_${item.id}_${index}`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default AgingOperationsBarChartLegend;
