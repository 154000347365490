// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Icon } from "@pg/common";
import { Typography } from "antd";
import { MessageDescriptor, useIntl } from "react-intl";
import styled from "styled-components";
import {
  fontSizeMedium,
  spacingLarge,
  spacingXLarge
} from "styles/StyleVariables";
const { Text } = Typography;

interface ISimulationMessageProps {
  className?: string;
  messages: MessageDescriptor[];
  icon?: Icon;
}

const SimulationMessage = ({
  className,
  messages,
  icon
}: ISimulationMessageProps) => {
  const intl = useIntl();
  return (
    <div className={className}>
      {messages.map((message, i) => (
        <Text type="secondary" className="simulation-message" key={i}>
          {intl.formatMessage({
            defaultMessage: message.defaultMessage,
            id: message.id
          })}
        </Text>
      ))}
      {icon && (
        <Text type="secondary" className="simulation-icon">
          {icon}
        </Text>
      )}
    </div>
  );
};

const StyledSimulationMessage = styled(SimulationMessage)`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .simulation-message {
    font-size: ${fontSizeMedium};
    padding-bottom: ${spacingLarge};
  }

  .simulation-icon {
    padding-top: ${spacingXLarge};
  }
`;

export default StyledSimulationMessage;
