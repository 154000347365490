// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import NotificationsService from "core/app/services/NotificationsService";
import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { IntlShape } from "react-intl";
import { IAjaxAction } from "reducers/Index";
import { AppDispatch } from "store";
import { config } from "utils/AppConfig";
import IAssetTree from "../models/IAssetTree";

export const Actions = {
  DetailPage_AssetTree_SetAssetTree: "DetailPage_AssetTree_SetAssetTree"
};

export const setAssetTreeAction = (
  request: JQuery.jqXHR,
  data?: IAssetTree
): IAjaxAction<IAssetTree> => {
  const action: IAjaxAction<IAssetTree> = {
    type: Actions.DetailPage_AssetTree_SetAssetTree,
    request,
    data
  };
  return action;
};

export const loadAssetTree = (
  dispatch: AppDispatch,
  intl: IntlShape,
  assetId: string
) =>
  new Promise<IAssetTree>((resolve, reject) => {
    const url = UrlService.getApiUrl(config.api.detailPage.assetTreeUrl, [
      {
        name: "assetId",
        value: assetId
      }
    ]);

    const request = EndpointService.getJson<IAssetTree>(
      url,
      (request, data) => {
        dispatch(setAssetTreeAction(request, data));
        resolve(data);
      },
      (request) => {
        dispatch(setAssetTreeAction(request));
        NotificationsService.notifyIfRequestFailed(intl, request, {
          id: "detailpage.side_panel.asset_tree.load.failed_message",
          defaultMessage: "Cannot load asset tree. Response code: {code}"
        });
        reject();
      }
    );

    dispatch(setAssetTreeAction(request));
  });
