// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Icon } from "@pg/common";
import { Layout, Typography } from "antd";
import CenteredWrapper from "common/CenteredWrapper";
import Processing from "components/common/Processing";
import { WidgetErrorMessage } from "components/common/widget/Widget";
import Data, { Statuses } from "core/data/models/Data";
import { isNil } from "lodash";
import { useEffect } from "react";
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import { AppDispatch } from "store";
import styled from "styled-components";
import { spacingXLarge, spacingXXXLarge } from "styles/StyleVariables";
import IScoreTrend from "../../../models/IScoreTrend";
import loadSimulationTrend from "../actions/loadSimulationTrend";
import removeSimulationTrend from "../actions/removeSimulationTrend";
import IValues from "../models/IValues";
import getSimulationTrendsSelector from "../selectors/getSimulationTrendsSelector";
import SimulationModalChart from "./SimulationChart";
import SimulationMessage from "./SimulationMessage";
const { Content } = Layout;
const { Title } = Typography;

interface ISimulationResultsProps {
  className?: string;
  simulationId: string;
  simulationName: string;
  status: string;
  beginValue: number;
  endValue: number;
}

interface ISimulationResultsActions {
  loadSimulationTrendData: (simulationId: string) => void;
  removeSimulationTrendData: (simulationId: string) => void;
}

interface ISimulationResultsData {
  simulationTrendData?: Data<IValues<IScoreTrend>>;
}

type SimulationResultsProps = ISimulationResultsProps &
  ISimulationResultsActions &
  ISimulationResultsData;

const SimulationResults = ({
  className,
  simulationId,
  simulationName,
  status,
  beginValue,
  endValue,
  simulationTrendData,
  loadSimulationTrendData,
  removeSimulationTrendData
}: SimulationResultsProps) => {
  useEffect(() => {
    if (status === "Completed") loadSimulationTrendData(simulationId);
    return () => {
      removeSimulationTrendData(simulationId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [simulationId, status]);

  return (
    <Layout className={className}>
      <Title level={4} className="label-title">
        {simulationName}
      </Title>
      <Content>
        {status === "InProgress" && (
          <SimulationMessage
            messages={[
              {
                defaultMessage: "Simulation calculating.",
                id: "detail_page.risk_trend.simulation_modal.message.simulation_calculating"
              },
              {
                defaultMessage: "Please stand by.",
                id: "detail_page.risk_trend.simulation_modal.message.please_stand_by"
              }
            ]}
            icon={<Icon name="refresh" size="lg" spin />}
          />
        )}
        {status === "Completed" && (
          <>
            {simulationTrendData?.status === Statuses.Loading && (
              <CenteredWrapper>
                <Processing className="spinner small dark" />
              </CenteredWrapper>
            )}
            {simulationTrendData?.status === Statuses.Succeeded &&
              (!simulationTrendData?.data.Values ||
                simulationTrendData?.data.Values.length === 0) && (
                <SimulationMessage
                  messages={[
                    {
                      defaultMessage: "The result of simulation is empty",
                      id: "detail_page.risk_trend.simulation_modal.message.simulation_is_empty"
                    }
                  ]}
                  icon={<Icon name="report_problem" size="lg" />}
                />
              )}
            {simulationTrendData?.status === Statuses.Succeeded &&
              simulationTrendData?.data.Values.length > 0 && (
                <SimulationModalChart
                  simulationTrend={simulationTrendData.data.Values}
                  simulationMaxValue={endValue}
                  simulationMinValue={beginValue}
                />
              )}
            {simulationTrendData?.status === Statuses.Failed && (
              <CenteredWrapper>
                <WidgetErrorMessage />
              </CenteredWrapper>
            )}
          </>
        )}
        {(status === null ||
          simulationTrendData?.status === Statuses.NotFound) && (
          <CenteredWrapper>
            <WidgetErrorMessage
              messageId="detail_page.risk_trend.simulation_modal.message.simulation_no_longer_exists"
              messageDefault="Simulation no longer exists. Please refresh the page."
            />
          </CenteredWrapper>
        )}
        {status === undefined && (
          <CenteredWrapper>
            <WidgetErrorMessage
              messageId="detail_page.risk_trend.simulation_modal.message.simulation_does_not_exist"
              messageDefault="Simulation does not exist. Please select existing one."
            />
          </CenteredWrapper>
        )}
      </Content>
    </Layout>
  );
};

const mapStateToProps = (
  state: IState,
  { simulationId }: ISimulationResultsProps
): ISimulationResultsData => ({
  simulationTrendData: getSimulationTrendsSelector(state)(simulationId)
});

const mapDispatchToProps = (
  dispatch: AppDispatch
): ISimulationResultsActions => {
  const loadSimulationTrendData = (simulationId: string) => {
    if (!isNil(simulationId)) dispatch(loadSimulationTrend(simulationId));
  };
  const removeSimulationTrendData = (simulationId: string) => {
    if (!isNil(simulationId)) dispatch(removeSimulationTrend(simulationId));
  };
  return {
    loadSimulationTrendData,
    removeSimulationTrendData
  };
};

const SimulationResultsWithData = connect(
  mapStateToProps,
  mapDispatchToProps
)(SimulationResults);

const StyledSimulationResultsWithData = styled(SimulationResultsWithData)`
  height: 100%;
  padding-bottom: ${spacingXXXLarge};

  .label-title {
    padding: 0px ${spacingXLarge} ${spacingXLarge};
    display: block;
    min-height: ${spacingXXXLarge};
  }
`;

export default StyledSimulationResultsWithData;
