// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import {
  area,
  AxisType,
  ChartOptions,
  ChartTypes,
  DataItem,
  line,
  scatter,
  XTickConfiguration,
  YTickConfiguration
} from "billboard.js";
import IChartSize from "common/AssetRiskMatrix/models/IChartSize";
import ITierCluster from "common/AssetRiskMatrix/models/ITierCluster";
import getRiskAreas, {
  IGetRiskAreaOptions
} from "common/AssetRiskMatrix/utils/getRiskAreas";
import { IDataRegions } from "common/chart/components/BBChart";
import namedSeries from "common/chart/utils/namedSeries";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import useAssetRiskMatrixClusterTiers from "./useAssetRiskMatrixClusterTiers";
import useAssetRiskMatrixColors from "./useAssetRiskMatrixColors";

export interface IDataRow {
  x: number;
  value: number | null;
  id: string;
  index: number;
  name?: string;
}

interface IUseAssetRiskMatrixConfigurationOptions extends IGetRiskAreaOptions {
  groupedClusters: ITierCluster;
  chartSize?: IChartSize;
  grayscale?: boolean;
  onChartDataClick?: (point: DataItem) => void;
  getTooltipContent: (data: IDataRow[]) => string;
}

const useAssetRiskMatrixConfiguration = ({
  otherAssets,
  currentAsset,
  clusters,
  groupedClusters,
  prognosticPoints,
  showLines,
  thresholds,
  onChartDataClick,
  getTooltipContent,
  chartSize,
  grayscale
}: IUseAssetRiskMatrixConfigurationOptions) => {
  const intl = useIntl();
  const {
    lowToMediumLine,
    mediumToHighLine,
    stackedValues,
    xTicks,
    yTicks,
    regionCrucialPoints
  } = getRiskAreas({
    thresholds,
    currentAsset,
    otherAssets,
    clusters,
    prognosticPoints,
    showLines
  });

  const colors = useAssetRiskMatrixColors();

  const {
    clustersNamedSeries,
    clustersXs,
    clustersChartType,
    clustersAxes,
    clustersClasses
  } = useAssetRiskMatrixClusterTiers({
    groupedClusters
  });

  const columns = useMemo(
    () => [
      namedSeries(
        "score_stack",
        stackedValues.map<number>((point) => point.x as number)
      ),
      namedSeries(
        "risk_low_stack",
        stackedValues.map<number>((point) => point.yLow as number)
      ),
      namedSeries(
        "risk_med_stack",
        stackedValues.map<number>((point) => point.yMedium as number)
      ),
      namedSeries(
        "risk_hig_stack",
        stackedValues.map<number>((point) => point.yHigh as number)
      ),
      namedSeries(
        "score_low",
        showLines ? lowToMediumLine.map<number>((p) => p.x as number) : []
      ),
      namedSeries(
        "importance_low",
        showLines
          ? lowToMediumLine.map<number>((point) => point.y as number)
          : []
      ),
      namedSeries(
        "score_high",
        showLines
          ? mediumToHighLine.map<number>((point) => point.x as number)
          : []
      ),
      namedSeries(
        "importance_high",
        showLines
          ? mediumToHighLine.map<number>((point) => point.y as number)
          : []
      ),
      ...Array.from(clustersNamedSeries),
      namedSeries(
        "x_prognostic_score",
        prognosticPoints ? prognosticPoints.map((x) => x.Score) : []
      ),
      namedSeries(
        "prognostic",
        prognosticPoints ? prognosticPoints.map((x) => x.Importance) : []
      ),
      namedSeries(
        "x_other_score",
        otherAssets ? otherAssets.map((x) => x.Score) : []
      ),
      namedSeries(
        "other_assets",
        otherAssets ? otherAssets.map((x) => x.Importance) : []
      ),
      namedSeries("x_current_score", [
        currentAsset ? currentAsset.Score : null
      ]),
      namedSeries("current_asset", [
        currentAsset ? currentAsset.Importance : null
      ])
    ],
    [
      stackedValues,
      showLines,
      lowToMediumLine,
      mediumToHighLine,
      clustersNamedSeries,
      prognosticPoints,
      otherAssets,
      currentAsset
    ]
  );

  const xs = useMemo(
    () => ({
      ...{
        other_assets: "x_other_score",
        current_asset: "x_current_score",
        prognostic: "x_prognostic_score",
        risk_low_stack: "score_stack",
        risk_med_stack: "score_stack",
        risk_hig_stack: "score_stack",
        importance_low: "score_low",
        importance_high: "score_high"
      },
      ...clustersXs
    }),
    [clustersXs]
  );

  const axes: { [key: string]: AxisType } = useMemo(
    () => ({
      ...{
        risk_low_stack: "y",
        risk_med_stack: "y",
        risk_hig_stack: "y",
        other_assets: "y",
        current_asset: "y",
        prognostic: "y",
        importance_high: "y",
        importance_low: "y"
      },
      ...clustersAxes
    }),
    [clustersAxes]
  );

  const types: { [key: string]: ChartTypes } = useMemo(
    () => ({
      ...{
        other_assets: scatter(),
        current_asset: scatter(),
        prognostic: scatter(),
        risk_low_stack: area(),
        risk_med_stack: area(),
        risk_hig_stack: area(),
        importance_low: line(),
        importance_high: line()
      },
      ...clustersChartType
    }),
    [clustersChartType]
  );

  const classes = useMemo(
    () => ({
      ...{
        risk_low_stack: "risk-low-stack",
        risk_med_stack: "risk-med-stack",
        risk_hig_stack: "risk-hig-stack",
        other_assets: "other-assets",
        current_asset: "current-asset",
        prognostic: "prognostic",
        importance_high: "importance-high",
        importance_low: "importance-low"
      },
      ...clustersClasses
    }),
    [clustersClasses]
  );

  const yTickConfiguration: YTickConfiguration = useMemo(
    () => ({
      values: showLines ? yTicks : [],
      outer: showLines
    }),
    [showLines, yTicks]
  );

  const xTickConfiguration = useMemo(
    (): XTickConfiguration => ({
      values: showLines ? xTicks : [],
      outer: showLines,
      fit: true,
      format: (v: number | Date): string =>
        intl.formatNumber(Math.round((v as number) * 1000.0) / 1000.0)
    }),
    [intl, showLines, xTicks]
  );

  const regions = useMemo((): IDataRegions => {
    if (regionCrucialPoints.length >= 2) {
      return {
        importance_low: [
          {
            start: regionCrucialPoints[0].x,
            end: regionCrucialPoints[regionCrucialPoints.length - 1].x,
            style: { dasharray: "2 2" }
          }
        ]
      };
    }

    return {};
  }, [regionCrucialPoints]);

  const padding = useMemo<{
    right?: number;
  }>(() => (showLines ? { right: 25 } : {}), [showLines]);

  const axisPadding = useMemo(
    () => (grayscale && xTicks.length === 0 ? 2.5 : xTicks[0] / 10),
    [grayscale, xTicks]
  );

  const configuration: ChartOptions = {
    size: chartSize,
    padding,
    zoom: {
      enabled: false
    },
    data: {
      type: line(),
      types,
      xs,
      groups: [["risk_low_stack", "risk_med_stack", "risk_hig_stack"]],
      order: null,
      axes,
      columns,
      classes,
      colors,
      onclick: onChartDataClick,
      regions
    },
    area: {
      zerobased: true
    },
    line: {
      connectNull: false
    },
    axis: {
      x: {
        label: {
          text: intl.formatMessage({
            id: "asset_risk_chart.x_axis.label",
            defaultMessage: "Condition"
          }),
          position: "outer-center"
        },
        min: 0,
        tick: xTickConfiguration,
        padding: {
          left: axisPadding,
          right: axisPadding
        },
        clipPath: false
      },
      y: {
        label: {
          text: intl.formatMessage({
            id: "asset_risk_chart.y_axis.label",
            defaultMessage: "Importance"
          }),
          position: "outer-middle"
        },
        min: 0,
        tick: yTickConfiguration,
        padding: {
          top: 20,
          bottom: 20
        }
      }
    },
    transition: {
      duration: 0
    },
    legend: {
      show: false
    },
    point: {
      r: 4,
      focus: {
        expand: {
          enabled: false
        }
      }
    },
    tooltip: {
      show: true,
      grouped: false,
      contents: getTooltipContent
    },
    grid: {
      x: {
        lines: xTicks.map((x) => ({ value: x }))
      },
      y: {
        lines: yTicks.map((x) => ({ value: x }))
      }
    }
  };

  return { configuration };
};

export default useAssetRiskMatrixConfiguration;
