// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import {
  loadTrendChart,
  removeTrendChart
} from "features/assetriskdashboard/actions/AssetRiskDashboardActions";
import AssetRiskDashboardBody from "features/assetriskdashboard/components/AssetRiskDashboardBody";
import getTrendChartSelector from "features/assetriskdashboard/selectors/getTrendChartSelector";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store";
import "./AssetRiskDashboard.less";

const AssetRiskDashboard = () => {
  const dispatch = useAppDispatch();
  const trendChart = useAppSelector(getTrendChartSelector);

  useEffect(() => {
    dispatch(loadTrendChart());
    return () => {
      dispatch(removeTrendChart());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="asset-risk-dashboard-page">
      <AssetRiskDashboardBody trendChart={trendChart} />
    </div>
  );
};

export default AssetRiskDashboard;
