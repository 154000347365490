// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Container from "common/Container";
import ErrorMessage from "common/messages/ErrorMessage";
import NoDataMessage from "common/messages/NoDataMessage";
import Processing from "components/common/Processing";
import Data, { Statuses } from "core/data/models/Data";
import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { config } from "utils/AppConfig";
import "./LicensesFile.less";

const LicenseFile = () => {
  const { licenseType, fileName } = useParams();
  const [license, setLicense] = useState<Data<string>>(new Data());

  useEffect(() => {
    const url = UrlService.getApiUrl(config.api.licenses.licenseText, {
      licenseType: licenseType,
      fileName: fileName
    });

    const request = EndpointService.getJson<string>(
      url,
      (request, data) => {
        setLicense(new Data(request, data));
      },
      (request) => {
        setLicense(new Data(request));
      }
    );

    setLicense(new Data(request));
  }, [licenseType, fileName]);

  return (
    <div className="licenses-file">
      <Container>
        {license.status === Statuses.Loading && <Processing />}
        {license.status === Statuses.Succeeded && <pre>{license.data}</pre>}
        {license.status === Statuses.Failed && (
          <ErrorMessage
            message={{
              defaultMessage: "Getting license file failed",
              id: "licenses.file.getting.failed"
            }}
          />
        )}
        {license.status === Statuses.NotFound && (
          <NoDataMessage
            message={{
              defaultMessage: `The content of ${fileName} file cannot be retrieved.`,
              id: "licenses.file.getting.no_data"
            }}
          />
        )}
      </Container>
    </div>
  );
};

export default LicenseFile;
