// Copyright 2016-2024 Hitachi Energy. All rights reserved.

const _ = require("lodash");

import { useEffect, useState } from "react";

export default function useShowTextForNameplateIcon() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const ENDPOINT = 1570;

  useEffect(() => {
    const windowResizeListener = _.throttle(() => {
      setWindowWidth(window.innerWidth);
    }, 200);

    window.addEventListener("resize", windowResizeListener);

    return () => {
      window.removeEventListener("resize", windowResizeListener);
    };
  }, []);

  const showTextForNameplateIcon = windowWidth > ENDPOINT;

  return showTextForNameplateIcon;
}
