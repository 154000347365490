// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React, { useCallback, useMemo } from "react";
import { Button as AntdButton, Row, Col, Typography } from "antd";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import LogType from "../models/LogType";
import Button from "./Button";

import { spacingLarge } from "styles/StyleVariables";

const { Group } = AntdButton;
const { Text } = Typography;

interface IShowButtonProps {
  activeButtons: string[];
  className?: string;
  onClick?: (type: LogType) => void;
  onAllClick?: () => void;
}

const ShowButtons = ({
  className,
  activeButtons,
  onClick,
  onAllClick
}: IShowButtonProps) => {
  const isActive = useCallback(
    (id: string) => {
      return activeButtons.some((x) => x === id);
    },
    [activeButtons]
  );

  const isAllActive = useMemo(() => {
    const maximumActiveLength = Object.keys(LogType).length;
    return activeButtons.length === maximumActiveLength;
  }, [activeButtons]);

  const handleClicked = useCallback(
    (type: LogType) => {
      if (onClick) onClick(type);
    },
    [onClick]
  );

  return (
    <Row align="middle" className={className}>
      <Col>
        <Text className="show-label">
          <FormattedMessage
            defaultMessage="Show"
            id="detail_page.widgets.circuit_breaker.log.buttons.show"
          />
        </Text>
      </Col>
      <Col>
        <Group>
          <Button
            messageDescription={{
              id: "detail_page.widgets.circuit_breaker.log.buttons.all",
              defaultMessage: "All"
            }}
            onClick={onAllClick}
            active={isAllActive}
          />
          <Button
            onClick={() => handleClicked(LogType.Operations)}
            active={isActive(LogType.Operations)}
            messageDescription={{
              id: "detail_page.widgets.circuit_breaker.log.buttons.operations",
              defaultMessage: "Operations"
            }}
          />
          <Button
            onClick={() => handleClicked(LogType.Alarms)}
            active={isActive(LogType.Alarms)}
            messageDescription={{
              id: "detail_page.widgets.circuit_breaker.log.buttons.alarms",
              defaultMessage: "Alarms"
            }}
          />
          <Button
            onClick={() => handleClicked(LogType.SensorChange)}
            active={isActive(LogType.SensorChange)}
            messageDescription={{
              id: "detail_page.widgets.circuit_breaker.log.buttons.sensor_change",
              defaultMessage: "Sensor Change"
            }}
          />
          <Button
            onClick={() => handleClicked(LogType.Exceptions)}
            active={isActive(LogType.Exceptions)}
            messageDescription={{
              id: "detail_page.widgets.circuit_breaker.log.buttons.exceptions",
              defaultMessage: "Exceptions"
            }}
          />
          <Button
            onClick={() => handleClicked(LogType.Notifications)}
            active={isActive(LogType.Notifications)}
            messageDescription={{
              id: "detail_page.widgets.circuit_breaker.log.buttons.notifications",
              defaultMessage: "Notifications"
            }}
          />
          <Button
            onClick={() => handleClicked(LogType.Interventions)}
            active={isActive(LogType.Interventions)}
            messageDescription={{
              id: "detail_page.widgets.circuit_breaker.log.buttons.interventions",
              defaultMessage: "Interventions"
            }}
          />
          <Button
            onClick={() => handleClicked(LogType.Other)}
            active={isActive(LogType.Other)}
            messageDescription={{
              id: "detail_page.widgets.circuit_breaker.log.buttons.other",
              defaultMessage: "Other"
            }}
          />
        </Group>
      </Col>
    </Row>
  );
};

const StyledShowButton = styled(ShowButtons)`
  .show-label {
    padding-left: ${spacingLarge};
    padding-right: ${spacingLarge};
  }
`;

export default StyledShowButton;
