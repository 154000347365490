// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { useMemo } from "react";

const useRiskInfo = (risk: string) => {
  const getRiskBorderColorClassName = (risk: string) => {
    switch (risk) {
      case "High":
        return "high";
      case "Medium":
        return "medium";
      case "Low":
        return "low";
      case "Unknown":
        return "unknown";
      default:
        return "not-determined";
    }
  };

  const classNames = useMemo(
    () =>
      [
        "asset-detail-risk-icon asset-risk-bar-value",
        getRiskBorderColorClassName(risk)
      ].join(" "),
    [risk]
  );

  return { classNames };
};

export default useRiskInfo;
