// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import ICustomer from "core/app/models/ICustomer";
import IIssueStatusChangeSettings from "core/app/models/IIssueStatusChangeSettings";
import IVisualizationsAssetGrid from "core/app/models/IVisualizationsAssetGrid";
import IVisualizationsCommandIntegration from "core/app/models/IVisualizationsCommandIntegration";
import IVisualizationsComponentGrid from "core/app/models/IVisualizationsComponentGrid";
import IVisualizationsConfiguration from "core/app/models/IVisualizationsConfiguration";
import IVisualizationsIssueGrid from "core/app/models/IVisualizationsIssueGrid";
import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { IAjaxAction } from "reducers/Index";
import { AppDispatch } from "store";
import { config } from "utils/AppConfig";

export enum Actions {
  Settings_SetCustomer = "Settings_SetCustomer",
  Settings_SetCustomerVisualizations = "Settings_SetCustomerVisualizations",
  Settings_SetCustomerVisualizationsAssetGrid = "Settings_SetCustomerVisualizationsAssetGrid",
  Settings_SetCustomerVisualizationsIssueGrid = "Settings_SetCustomerVisualizationsIssueGrid",
  Settings_SetCustomerVisualizationsComponentGrid = "Settings_SetCustomerVisualizationsComponentGrid",
  Settings_SetCustomerVisualizationsCommandIntegration = "Settings_SetCustomerVisualizationsCommandIntegration",
  Settings_SetCustomerIssueSettings = "Settings_SetCustomerIssueSettings",
  Settings_SetWatchListLimit = "Settings_SetWatchListLimit"
}

const setCustomerAction = (
  request: JQueryXHR,
  data?: ICustomer
): IAjaxAction<ICustomer> => {
  const action: IAjaxAction<ICustomer> = {
    type: Actions.Settings_SetCustomer,
    data,
    request
  };
  return action;
};

const setCustomerVisualizationsAction = (
  request: JQueryXHR,
  data?: IVisualizationsConfiguration
): IAjaxAction<IVisualizationsConfiguration> => {
  const action: IAjaxAction<IVisualizationsConfiguration> = {
    type: Actions.Settings_SetCustomerVisualizations,
    data,
    request
  };
  return action;
};

const setCustomerVisualizationsAssetGridAction = (
  request: JQueryXHR,
  data?: IVisualizationsAssetGrid
): IAjaxAction<IVisualizationsAssetGrid> => {
  const action: IAjaxAction<IVisualizationsAssetGrid> = {
    type: Actions.Settings_SetCustomerVisualizationsAssetGrid,
    data,
    request
  };
  return action;
};

const setCustomerVisualizationsIssueGridAction = (
  request: JQueryXHR,
  data?: IVisualizationsIssueGrid
): IAjaxAction<IVisualizationsIssueGrid> => {
  const action: IAjaxAction<IVisualizationsIssueGrid> = {
    type: Actions.Settings_SetCustomerVisualizationsIssueGrid,
    data,
    request
  };
  return action;
};

const setCustomerVisualizationsComponentGridAction = (
  request: JQueryXHR,
  data?: IVisualizationsComponentGrid
): IAjaxAction<IVisualizationsComponentGrid> => {
  const action: IAjaxAction<IVisualizationsComponentGrid> = {
    type: Actions.Settings_SetCustomerVisualizationsComponentGrid,
    data,
    request
  };
  return action;
};

const setCustomerVisualizationsCommandIntegrationAction = (
  request: JQueryXHR,
  data?: IVisualizationsCommandIntegration
): IAjaxAction<IVisualizationsCommandIntegration> => {
  const action: IAjaxAction<IVisualizationsCommandIntegration> = {
    type: Actions.Settings_SetCustomerVisualizationsCommandIntegration,
    data,
    request
  };
  return action;
};

const setCustomerIssueSettingsAction = (
  request: JQueryXHR,
  data?: IIssueStatusChangeSettings
): IAjaxAction<IIssueStatusChangeSettings> => {
  const action: IAjaxAction<IIssueStatusChangeSettings> = {
    type: Actions.Settings_SetCustomerIssueSettings,
    data,
    request
  };
  return action;
};

const setWatchListLimitAction = (
  request: JQueryXHR,
  data?: number
): IAjaxAction<number> => {
  const action: IAjaxAction<number> = {
    type: Actions.Settings_SetWatchListLimit,
    data,
    request
  };
  return action;
};

export const loadCustomer = (dispatch: AppDispatch) => {
  const url = UrlService.getApiUrl(config.api.user.customerUrl);
  const request = EndpointService.getJson<ICustomer>(
    url,
    (request, data) => {
      dispatch(setCustomerAction(request, data));
    },
    (request) => {
      dispatch(setCustomerAction(request));
    }
  );

  dispatch(setCustomerAction(request));
};

export const loadCustomerVisualizations = (dispatch: AppDispatch) => {
  const url = UrlService.getApiUrl(config.api.user.visualizationsUrl);
  const request = EndpointService.getJson<IVisualizationsConfiguration>(
    url,
    (request, data) => {
      dispatch(setCustomerVisualizationsAction(request, data));
    },
    (request) => {
      dispatch(setCustomerVisualizationsAction(request));
    }
  );

  dispatch(setCustomerVisualizationsAction(request));
};

export const loadCustomerVisualizationsAssetGrid = (dispatch: AppDispatch) => {
  const url = UrlService.getApiUrl(config.api.user.visualizationsAssetGridUrl);
  const request = EndpointService.getJson<IVisualizationsAssetGrid>(
    url,
    (request, data) => {
      dispatch(setCustomerVisualizationsAssetGridAction(request, data));
    },
    (request) => {
      dispatch(setCustomerVisualizationsAssetGridAction(request));
    }
  );

  dispatch(setCustomerVisualizationsAssetGridAction(request));
};

export const loadCustomerVisualizationsIssueGrid = (dispatch: AppDispatch) => {
  const url = UrlService.getApiUrl(config.api.user.visualizationsIssuesGridUrl);
  const request = EndpointService.getJson<IVisualizationsIssueGrid>(
    url,
    (request, data) => {
      dispatch(setCustomerVisualizationsIssueGridAction(request, data));
    },
    (request) => {
      dispatch(setCustomerVisualizationsIssueGridAction(request));
    }
  );

  dispatch(setCustomerVisualizationsIssueGridAction(request));
};

export const loadCustomerVisualizationsComponentGrid = (
  dispatch: AppDispatch
) => {
  const url = UrlService.getApiUrl(
    config.api.user.visualizationsComponentsGridUrl
  );
  const request = EndpointService.getJson<IVisualizationsComponentGrid>(
    url,
    (request, data) => {
      dispatch(setCustomerVisualizationsComponentGridAction(request, data));
    },
    (request) => {
      dispatch(setCustomerVisualizationsComponentGridAction(request));
    }
  );

  dispatch(setCustomerVisualizationsComponentGridAction(request));
};

export const loadCustomerVisualizationsCommandIntegration = (
  dispatch: AppDispatch
) => {
  const url = UrlService.getApiUrl(
    config.api.user.visualizationsCommandIntegrationUrl
  );
  const request = EndpointService.getJson<IVisualizationsCommandIntegration>(
    url,
    (request, data) => {
      dispatch(
        setCustomerVisualizationsCommandIntegrationAction(request, data)
      );
    },
    (request) => {
      dispatch(setCustomerVisualizationsCommandIntegrationAction(request));
    }
  );

  dispatch(setCustomerVisualizationsCommandIntegrationAction(request));
};

export const loadCustomerIssueSettings = (dispatch: AppDispatch) => {
  const url = UrlService.getApiUrl(config.api.user.issueSettingsUrl);
  const request = EndpointService.getJson<IIssueStatusChangeSettings>(
    url,
    (request, data) => {
      dispatch(setCustomerIssueSettingsAction(request, data));
    },
    (request) => {
      dispatch(setCustomerIssueSettingsAction(request));
    }
  );

  dispatch(setCustomerIssueSettingsAction(request));
};

export const loadWatchListLimit = (dispatch: AppDispatch) => {
  const url = UrlService.getApiUrl(config.api.assetWatchList.watchListLimitUrl);
  const request = EndpointService.getJson<number>(
    url,
    (request, data) => {
      dispatch(setWatchListLimitAction(request, data));
    },
    (request) => {
      dispatch(setWatchListLimitAction(request));
    }
  );

  dispatch(setWatchListLimitAction(request));
};
