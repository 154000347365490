// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { createContext } from "react";

export interface IDetailPageWidgetContext {
  isParametersListWidgetVisible?: boolean;
  isAssetNameplateWidgetVisible?: boolean;
  setIsAssetNameplateWidgetVisible?: (value: boolean) => void;
}

const DetailPageWidgetContext = createContext<IDetailPageWidgetContext>({});

export default DetailPageWidgetContext;
