// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Container from "common/Container";
import { ReactNode } from "react";
import styled from "styled-components";

interface IDataGridWrapperProps {
  className?: string;
  children?: ReactNode;
}

const DataGridWrapper = ({ className, children }: IDataGridWrapperProps) => {
  return (
    <MainWrapper className={`${className} data-grid__parent`}>
      <Scroll className="data-grid__scroll">
        <Container>{children}</Container>
      </Scroll>
    </MainWrapper>
  );
};

const MainWrapper = styled.div`
  overflow: hidden;
  height: 100%;
`;

const Scroll = styled.div`
  height: 100%;
`;

export default DataGridWrapper;
