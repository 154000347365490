// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import IDGAOperations from "features/detailpage/features/ltc/models/IDGAOperations";
import { IAjaxAction } from "reducers/Index";
import { AppDispatch } from "store";
import { config } from "utils/AppConfig";

export enum Actions {
  DetailPage_Ltc_SetDga = "DetailPage_Ltc_SetDga"
}

export const setDgaAction = (
  request: JQuery.jqXHR,
  data?: IDGAOperations
): IAjaxAction<IDGAOperations> => ({
  request,
  data,
  type: Actions.DetailPage_Ltc_SetDga
});

export const loadDgaOperationsAction =
  (assetId: string) => (dispatch: AppDispatch) =>
    fetchDgaOperations(dispatch, assetId);

function fetchDgaOperations(
  dispatch: AppDispatch,
  assetId: string
): Promise<void> {
  return new Promise((resolve, reject) => {
    const url = UrlService.getApiUrl(config.api.detailPage.dgaOperationsUrl, [
      {
        name: "assetId",
        value: assetId
      }
    ]);
    const request = EndpointService.getJson<IDGAOperations>(
      url,
      (request, data) => {
        dispatch(setDgaAction(request, data));
        resolve();
      },
      (request) => {
        dispatch(setDgaAction(request));
        reject();
      }
    );

    dispatch(setDgaAction(request));
  });
}
