// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Data from "core/data/models/Data";
import { getDataKey } from "features/ConfigurationTool/actions/loadAssetDetails";
import IAssetDetails from "models/IAssetDetails";
import { IState } from "reducers/Index";
import { createSelector } from "reselect";

const getNameplateSelector = createSelector(
  (state: IState) => (assetId: string) =>
    state.data[getDataKey(assetId)] as Data<IAssetDetails>,
  (assetDetails) => assetDetails
);

export default getNameplateSelector;
