// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import * as React from "react";
import "./FamilyContentsWidget.less";
import { Statuses } from "core/data/models/Data";
import { IScatterPlotWidgetOwnProps } from "./ScatterPlotWidget";
import Processing from "components/common/Processing";
import FamilyContents from "./FamilyContents";
import useFamilyContentsWidget from "../hooks/useFamilyContentsWidget";
import { WidgetNoDataMessage } from "components/common/widget/Widget";

const FamilyContentsWidget = ({ parameters }: IScatterPlotWidgetOwnProps) => {
  const {
    scatterPlotXData,
    scatterPlotYData,
    status
  } = useFamilyContentsWidget({
    parameters
  });
  return (
    <>
      {status === Statuses.Loading && <Processing />}
      {status === Statuses.Succeeded && (
        <FamilyContents
          scatterPlotXData={scatterPlotXData}
          scatterPlotYData={scatterPlotYData}
        />
      )}
      {(status === Statuses.Failed || status === Statuses.NotFound) && (
        <WidgetNoDataMessage />
      )}
    </>
  );
};

export default FamilyContentsWidget;
