// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Statuses } from "core/data/models/Data";
import { loadDgaOperationsAction } from "features/detailpage/features/ltc/actions/DgaOperationsActions";
import DGAOperations from "features/detailpage/features/ltc/components/DGAOperations";
import { useDGAOperations } from "features/detailpage/features/ltc/reducers/LTCReducer";
import { isEmpty } from "lodash";
import React from "react";
import { useAppDispatch } from "store";

interface IDGAOperationsContainerProps {
  assetId: string;
  modelId: string;
  implementationId: string;
}

const DGAOperationsContainer = ({
  assetId,
  modelId,
  implementationId
}: IDGAOperationsContainerProps) => {
  const dispatch = useAppDispatch();
  const { dgaOperations } = useDGAOperations();

  React.useEffect(() => {
    if (
      dgaOperations.status !== Statuses.Succeeded ||
      isEmpty(dgaOperations.data)
    ) {
      dispatch(loadDgaOperationsAction(assetId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DGAOperations
      dgaOperations={dgaOperations}
      modelId={modelId}
      implementationId={implementationId}
    />
  );
};

export default DGAOperationsContainer;
