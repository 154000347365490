// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Legend } from "@apm/widgets/build/components/BBChart";
import {
  colorsChartSeries,
  colorStatusYellow
} from "@pg/common/build/styles/ColorVariables";
import { IntlShape } from "react-intl";
import { colorStatusOrange } from "styles/ColorVariables";
import ChartGroup from "../models/ChartGroup";
import { ThresholdTypeEnum } from "../models/ThresholdType";
import TransformerType from "../models/TransformerType";
import { TrendTypeEnum } from "../models/TrendType";

const getLegend =
  (intl: IntlShape, transformerType: TransformerType, chartGroup: ChartGroup) =>
  () => {
    const legend: Legend = [];

    legend.push({
      name: TrendTypeEnum.Phase1,
      displayName: intl.formatMessage({
        defaultMessage: TrendTypeEnum.Phase1,
        id: `detail_page.widgets.analytics.transformers.voltage_stress.legend.${TrendTypeEnum.Phase1}`
      }),
      isDataSeries: true,
      type: "solidLine",
      color: colorsChartSeries[0]
    });

    if (transformerType === "ThreePhaseTransformer") {
      legend.push({
        name: TrendTypeEnum.Phase2,
        displayName: intl.formatMessage({
          defaultMessage: TrendTypeEnum.Phase2,
          id: `detail_page.widgets.analytics.transformers.voltage_stress.legend.${TrendTypeEnum.Phase2}`
        }),
        isDataSeries: true,
        type: "solidLine",
        color: colorsChartSeries[1]
      });

      legend.push({
        name: TrendTypeEnum.Phase3,
        displayName: intl.formatMessage({
          defaultMessage: TrendTypeEnum.Phase3,
          id: `detail_page.widgets.analytics.transformers.voltage_stress.legend.${TrendTypeEnum.Phase3}`
        }),
        isDataSeries: true,
        type: "solidLine",
        color: colorsChartSeries[2]
      });
    }

    if (chartGroup === "Percentages") {
      legend.push({
        name: "Warning",
        displayName: intl.formatMessage({
          defaultMessage: "Warning",
          id: `detail_page.widgets.analytics.transformers.voltage_stress.legend.${ThresholdTypeEnum.Warning}`
        }),
        isDataSeries: false,
        type: "region",
        color: colorStatusYellow
      });

      legend.push({
        name: "Alert",
        displayName: intl.formatMessage({
          defaultMessage: "Alert",
          id: `detail_page.widgets.analytics.transformers.voltage_stress.legend.${ThresholdTypeEnum.Alert}`
        }),
        isDataSeries: false,
        type: "region",
        color: colorStatusOrange
      });
    } else {
      legend.push({
        name: "High",
        displayName: intl.formatMessage({
          defaultMessage: "High",
          id: `detail_page.widgets.analytics.transformers.voltage_stress.legend.${ThresholdTypeEnum.High}`
        }),
        isDataSeries: false,
        type: "region",
        color: colorStatusYellow
      });

      legend.push({
        name: "VeryHigh",
        displayName: intl.formatMessage({
          defaultMessage: "Very High",
          id: `detail_page.widgets.analytics.transformers.voltage_stress.legend.${ThresholdTypeEnum.VeryHigh}`
        }),
        isDataSeries: false,
        type: "region",
        color: colorStatusOrange
      });
    }

    return legend;
  };

export default getLegend;
