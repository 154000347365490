// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import React from "react";
import { FormattedMessage, MessageDescriptor } from "react-intl";

import "./ErrorMessage.less";

interface IErrorMessageProps {
  className?: string;
  message?: MessageDescriptor;
}

const ErrorMessage = ({ className, message }: IErrorMessageProps) => {
  const computedMessage = message || {
    defaultMessage: "Error",
    id: "common.error_message"
  };

  return (
    <div
      className={`
        common-error-message
        ${className}
      `}
    >
      <Icon name="error" />
      <FormattedMessage {...computedMessage} />
    </div>
  );
};

export default ErrorMessage;
