// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { SelectedFilters } from "common/FilterBar";
import loadData from "core/data/actions/loadData";
import removeData from "core/data/actions/removeData";
import UrlService from "core/data/services/UrlService";

import { config } from "utils/AppConfig";

export const dataKey = "RiskMatrixDashboard_StationRisk";

export const loadStationRisk = (filters?: SelectedFilters) => {
  const url = UrlService.getApiUrl(
    config.api.stationRisk.filteredLocationsRisks
  );

  const content = {
    dayOfWeek: new Date().getDay(),
    filters: filters?.selects || []
  };

  return loadData({ key: dataKey, url, content, method: "post" });
};

export const removeStationRisk = () => removeData(dataKey);
