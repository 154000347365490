// Copyright 2016-2024 Hitachi Energy. All rights reserved.

export enum TypedValueEnum {
  Decimal = "Decimal",
  String = "String",
  DateTime = "DateTime",
  Bool = "Bool"
}
export type TypedValue = keyof typeof TypedValueEnum;

interface ITypedValue {
  Type: TypedValue;
  Value: number | boolean | string;
}

export default ITypedValue;
