// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { IState } from "reducers/Index";

const getIssuesSelector = createSelector(
  (state: IState) => state.detailPage.issues,
  issues => issues
);

export default getIssuesSelector;
