// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { useAppSelector } from "store";
import AssetTypes from "../components/AssetTypes";
import getAllAssetTypesCheckedSelector from "../selectors/getAllAssetTypesCheckedSelector";
import getAssetTypesCheckedKeys from "../selectors/getAssetTypesCheckedKeys";
import getAssetTypesOptionsSelector from "../selectors/getAssetTypesOptionsSelector";
import getNotificationsEnabledSelector from "../selectors/getNotificationsEnabledSelector";

const AssetTypesContainer = () => {
  const notificationsEnabled = useAppSelector(getNotificationsEnabledSelector);
  const items = useAppSelector(getAssetTypesOptionsSelector);
  const defaultCheckedKeys = useAppSelector(getAssetTypesCheckedKeys);
  const allAssetTypesChecked = useAppSelector(getAllAssetTypesCheckedSelector);
  return (
    <AssetTypes
      notificationsEnabled={notificationsEnabled}
      items={items}
      defaultCheckedKeys={defaultCheckedKeys}
      allAssetTypesChecked={allAssetTypesChecked}
    />
  );
};

export default AssetTypesContainer;
