// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import {
  bar,
  ChartOptions,
  DataItem,
  GridLineOptions,
  PrimitiveArray,
  RegionOptions
} from "billboard.js";
import { useState } from "react";
import { useIntl } from "react-intl";
import IConductanceData from "../models/IConductanceData";

const dataColor = "#ADADAD";
const warningDataColor = "rgba(255, 216, 0, 0.75)";
const alertDataColor = "rgba(255, 115, 0, 0.75)";

const useChartConfiguration = (
  conductanceData: IConductanceData,
  maxArrowSize: number
) => {
  const intl = useIntl();
  const xs = conductanceData.Cells.map((i) => i.Index.toString());
  const values = conductanceData.Cells.map((c) => c.Conductance);
  const columns: PrimitiveArray[] = [
    ["x", ...xs],
    ["conductance", ...values]
  ];

  const alertLevel = conductanceData.AlertLevel;
  const warningLevel = conductanceData.WarningLevel;
  const referenceLevel = conductanceData.ReferenceLevel;

  let lines: GridLineOptions[];
  let regions: RegionOptions[];
  if (
    (alertLevel !== undefined && alertLevel != null) ||
    (warningLevel !== undefined && warningLevel != null) ||
    (referenceLevel !== undefined && referenceLevel != null)
  ) {
    lines = [
      {
        value: alertLevel,
        class: "alert-level",
        text: intl.formatNumber(alertLevel),
        position: "start"
      },
      {
        value: warningLevel,
        class: "warning-level",
        text: intl.formatNumber(warningLevel),
        position: "start"
      },
      {
        value: referenceLevel,
        class: "reference-level",
        text: intl.formatNumber(referenceLevel),
        position: "start"
      }
    ];

    regions = [
      {
        axis: "y",
        class: "warning-area",
        start: alertLevel,
        end: warningLevel
      },
      {
        axis: "y",
        class: "alert-area",
        end: alertLevel
      }
    ];
  }

  const configuration: ChartOptions = {
    data: {
      x: "x",
      columns,
      type: bar(),
      colors: {
        conductance: function (d: DataItem) {
          if (d.value < warningLevel && d.value > alertLevel)
            return warningDataColor;
          else if (d.value < alertLevel) return alertDataColor;
          else return dataColor;
        }
      }
    },
    line: {
      connectNull: true
    },
    bar: {
      width: {
        ratio: 0.5
      }
    },
    grid: {
      y: {
        show: true,
        lines
      },
      x: {
        show: false
      }
    },
    axis: {
      x: {
        type: "category",
        show: true,
        label: {
          text: intl.formatMessage({
            defaultMessage: "Cell #",
            id: "detail_page.parameters.battery_conductance.x_axis_label"
          }),
          position: "outer-right"
        },
        tick: {
          rotate: values.length > 32 ? 75 : 0,
          multiline: false
        }
      },
      y: {
        show: true,
        label: {
          text: intl.formatMessage({
            defaultMessage: "S",
            id: "detail_page.parameters.battery_conductance.y_axis_label"
          }),
          position: "outer-top"
        },
        tick: {
          format: function (v: number) {
            return intl.formatNumber(v);
          }
        },
        padding: {
          top: maxArrowSize
        }
      }
    },
    legend: {
      show: false
    },
    padding: {
      top: 20,
      right: 50
    },
    tooltip: {
      format: {
        name: (name) =>
          intl.formatMessage({
            defaultMessage: name,
            id: `detail_page.parameters.battery_conductance.${name}_tooltip`
          })
      }
    }
  };

  if (regions) configuration.regions = regions;
  if (lines) configuration.grid.y.lines = lines;

  return useState(configuration);
};

export default useChartConfiguration;
