// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Typography } from "antd";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

interface ILocationColumnProps {
  className?: string;
  location: string;
  region: string;
  organization: string;
}

const LocationColumn = ({
  className,
  organization,
  region,
  location
}: ILocationColumnProps) => {
  return (
    <Typography.Text className={className}>
      <FormattedMessage
        defaultMessage="{organization}, {region}, {location}"
        id="alarms.grid.columns.location.value"
        values={{
          organization,
          region,
          location
        }}
      />
    </Typography.Text>
  );
};

const columnPadding = "10px";

export default styled(LocationColumn)`
  display: block;
  padding: ${columnPadding};
`;
