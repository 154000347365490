// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import * as React from "react";
import ValueColumn, { IValueColumnProps } from "./ValueColumn";

interface ICurrentValueColumnProps extends IValueColumnProps {
  change: string;
}

const CurrentValueColumn = ({ value, change }: ICurrentValueColumnProps) => (
  <ValueColumn value={value}>
    <Icon className={change} name="arrow_upward" size="sm" />
  </ValueColumn>
);

export default CurrentValueColumn;
