// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import SortOrders from "common/datagrid/models/SortOrders";
import ITypedValue from "../../../models/ITypedValue";

const getParameterValue = (typedValue: ITypedValue) =>
  typedValue && typedValue.Type === "Decimal"
    ? (typedValue.Value as number)
    : null;

const compareByValue = (a: ITypedValue, b: ITypedValue, order: SortOrders) => {
  const aValue = getParameterValue(a);
  const bValue = getParameterValue(b);

  if (SortOrders.None) return 0;

  if (aValue === null && bValue === null) {
    return 0;
  } else if (aValue === null && bValue !== null) {
    return 1;
  } else if (aValue !== null && bValue === null) {
    return -1;
  } else {
    const result = aValue - bValue;
    return order === SortOrders.Asc ? result : -result;
  }
};

export default compareByValue;
