// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React from "react";

import { IRow } from "common/datagrid/DataGrid";
import { Link } from "react-router-dom";
import Tooltip from "common/Tooltip";

interface IProjectWebsiteColumnProps {
  value: any;
  row: IRow;
}

const ProjectWebsiteColumn = ({ value, row }: IProjectWebsiteColumnProps) => {
  return (
    <div className="grid-cell-content">
      {value && (
        <Tooltip title={<div>{value}</div>}>
          <Link
            data-qa="link"
            className="link-label"
            target="_blank"
            rel="noopener noreferrer"
            to={row.data["projectWebsite"]}
          >
            {value}
          </Link>
        </Tooltip>
      )}
      {!value && null}
    </div>
  );
};

export default ProjectWebsiteColumn;
