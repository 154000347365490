// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React from "react";
import { useIntl } from "react-intl";

import { NumericalValueFormatter } from "common/formatters/NumericalValue";

export interface IReplacementScoreProps {
  value: number;
}

const ReplacementScore = ({ value }: IReplacementScoreProps) => {
  const intl = useIntl();
  const formatter = new NumericalValueFormatter("-");
  const formattedValue = formatter.format(value, intl);
  return <span>{formattedValue}</span>;
};

export default ReplacementScore;
