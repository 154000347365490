// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import ColumnRisk from "common/columns/Risk";
import { IColumnConfig } from "common/datagrid/DataGrid";
import SortOrders from "common/datagrid/models/SortOrders";
import ComponentColumn from "features/Components/components/ComponentColumn";
import { useMemo } from "react";

const useComponentsGridColumnsConfig = () => {
  const columns: IColumnConfig[] = useMemo(
    () => [
      {
        component: (value) => <ComponentColumn componentId={value} />,
        id: "ComponentId",
        message: {
          defaultMessage: "Component",
          id: "component_risk_summary_page.side_panel.grid.columns.component"
        },
        defaultGroupOrder: 2,
        defaultSortOrder: SortOrders.Asc,
        weight: 6
      },
      {
        component: (value) => <ColumnRisk risk={value} />,
        id: "ComponentRisk",
        frozen: true,
        message: {
          defaultMessage: "Component Risk",
          id: "component_risk_summary_page.side_panel.grid.columns.component_risk"
        },
        defaultGroupOrder: 1,
        defaultSortOrder: SortOrders.Desc,
        weight: 2
      }
    ],
    []
  );

  return columns;
};

export default useComponentsGridColumnsConfig;
