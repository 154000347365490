// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import {
  loadStationRisk,
  removeStationRisk
} from "./actions/StationRiskActions";
import RiskMapDashboard from "./components/RiskMapDashboard";
import getStationRiskSelector from "./selectors/getStationRiskSelector";
import ILocationRisk from "./models/ILocationRisk";

export type LocationRisk = ILocationRisk;

export default RiskMapDashboard;
export { getStationRiskSelector, loadStationRisk, removeStationRisk };
