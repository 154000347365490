// Copyright 2016-2024 Hitachi Energy. All rights reserved.

const consoleError = console.error.bind(console);

console.error = (message?: any, ...args: any[]): void => {
  if (
    typeof message === "object" &&
    "code" in message &&
    message.code === "MISSING_TRANSLATION"
  )
    return;

  consoleError(message, ...args);
};

export {};
