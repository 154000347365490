// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import AssetsBy, { Option, Risk } from "@apm/widgets/build/widgets/AssetsBy";
import SearchParams from "@pg/common/build/models/SearchParams";
import { Typography } from "antd";
import Processing from "components/common/Processing";
import { WidgetErrorMessage } from "components/common/widget/Widget";
import { routes } from "core/app/components/AppRoutes";
import { useAppNavigate } from "core/app/components/RouterProvider";
import { capitalize, isEmpty, isNil, startCase } from "lodash";
import AssetRiskTypes from "models/AssetRiskTypes";
import IAssetRiskCountsTree from "models/IAssetRiskCountsTree";
import { ReactNode, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import styled from "styled-components";
import loadAssetsDistribution from "../actions/loadAssetsDistribution";
import mapDistributionToRisk from "../utils/mapDistributionToRisk";

const { Title } = Typography;

interface IAssetDistributionActions {
  loadAssetsDistribution: (
    type: AssetRiskTypes,
    id: string[]
  ) => Promise<IAssetRiskCountsTree[]>;
}
interface IAssetDistributionState {}
interface IAssetDistributionOwnProps {
  className?: string;
}

type AssetsDistributionProps = IAssetDistributionActions &
  IAssetDistributionState &
  IAssetDistributionOwnProps;

const AssetsDistribution = ({
  className,
  loadAssetsDistribution
}: AssetsDistributionProps) => {
  const navigate = useAppNavigate();
  const intl = useIntl();

  const getRisks = useCallback(
    (option: Option, id: string[]) =>
      new Promise<Risk[]>((resolve, reject) => {
        return loadAssetsDistribution(
          option === "AssetType"
            ? "RiskByAssetType"
            : option === "Organization"
            ? "RiskByOrganization"
            : option === "CompanyHierarchy"
            ? "RiskByCompanyHierarchy"
            : undefined,
          id
        )
          .then((data) => {
            resolve(mapDistributionToRisk(option, data, intl));
          })
          .catch(() => reject());
      }),
    [intl, loadAssetsDistribution]
  );

  return (
    <div className={`${className} tile`} data-qa="asset-risk-distribution">
      <div className="tile-content">
        <AssetsBy
          customSpinner={<Processing />}
          errorMessage={<WidgetErrorMessage />}
          options={["AssetType", "Organization", "CompanyHierarchy"]}
          getRisks={getRisks}
          formatValue={intl.formatNumber}
          header={
            <Title className="assets-distribution-title title" level={5}>
              <FormattedMessage
                defaultMessage="Summary"
                id={`homepage.main_dashboard.assets_distribution.title`}
              />
            </Title>
          }
          onClick={(option, beanId, riskName) => {
            const searchParams = new SearchParams();
            searchParams.set("f_as", JSON.stringify(["InService"]));

            if (!isNil(riskName))
              searchParams.set("f_ar", JSON.stringify([capitalize(riskName)]));

            const paramName =
              option === "AssetType"
                ? "f_at"
                : option === "Organization"
                ? "f_ao"
                : option === "CompanyHierarchy"
                ? "f_ch"
                : undefined;

            beanId = beanId.map((value) => (isEmpty(value) ? null : value));
            if (paramName) searchParams.set(paramName, JSON.stringify(beanId));

            navigate({
              pathname: routes.assets.pathname,
              search: searchParams.toString()
            });
          }}
          translations={{
            assets: intl.formatMessage({
              defaultMessage: "Assets",
              id: "homepage.main_dashboard.assets_distribution.assets"
            }),
            assetsDistribution: intl.formatMessage({
              defaultMessage: "Summary",
              id: `homepage.main_dashboard.assets_distribution.title`
            }),
            expandButtonTooltip: intl.formatMessage({
              defaultMessage: "Expand",
              id: "global.chart.expand"
            }),
            noValues: intl.formatMessage({
              defaultMessage: "No values",
              id: "homepage.main_dashboard.assets_distribution.no_values"
            }),
            option: (option: Option) =>
              intl.formatMessage({
                defaultMessage: startCase(option),
                id: `homepage.main_dashboard.assets_distribution.distribution.${option}`
              }),
            riskName: (risk) =>
              intl.formatMessage({
                defaultMessage: risk,
                id: `risk.${capitalize(risk)}`
              }),
            riskSummary: (beanName: ReactNode) => (
              <FormattedMessage
                defaultMessage="{name} - assets"
                id="homepage.main_dashboard.assets_distribution.general_risk"
                values={{
                  name: beanName
                }}
              />
            ),
            riskValue: (value: number) => intl.formatNumber(value)
          }}
        />
      </div>
    </div>
  );
};

const StyledAssetsDistribution = styled(AssetsDistribution)`
  .assets-distribution-title {
    margin-bottom: 0;
  }
`;

export default connect(
  (): IAssetDistributionState => ({}),
  (): IAssetDistributionActions => ({
    loadAssetsDistribution: (type: AssetRiskTypes, id: string[]) =>
      loadAssetsDistribution(type, id)
  })
)(StyledAssetsDistribution);
