// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { IDataGridProps, IDataGridState } from "common/datagrid/DataGrid";
import ElementService from "common/datagrid/services/ElementService";

export interface IProperties {
  parentTop: number;
  parentHeight: number;
  headerTop: number;
  headerHeight: number;
  headerWidth: number;
  footerTop: number;
  footerHeight: number;
  footerWidth: number;
}

export default class PropertyService {
  static getProperties(
    component: React.Component<IDataGridProps, IDataGridState>,
    probe = 1
  ): IProperties | undefined {
    const { gridId } = component.state;

    if ((component as any)._mounted) {
      const parentElement = ElementService.getParentElement(gridId);
      const headerElement = ElementService.getHeaderElement(gridId);
      const footerElement = ElementService.getFooterElement(gridId);

      let properties: IProperties;

      if (!(parentElement && headerElement && footerElement)) {
        if (probe <= 5) {
          setTimeout(() => {
            properties = PropertyService.getProperties(component, probe + 1);
          }, 200 * probe);

          return properties;
        } else {
          return undefined;
        }
      }

      const parentTop = ElementService.getParentTop(parentElement);
      const parentHeight = ElementService.getParentHeight(parentElement);
      const headerTop = ElementService.getHeaderTop(headerElement);
      const headerHeight = ElementService.getHeaderHeight(headerElement);
      const headerWidth = ElementService.getHeaderWidth(headerElement);
      const footerTop = ElementService.getFooterTop(footerElement);
      const footerHeight = ElementService.getFooterHeight(footerElement);
      const footerWidth = ElementService.getFooterWidth(footerElement);

      properties = {
        parentTop: parentTop,
        parentHeight: parentHeight,
        headerTop: headerTop,
        headerHeight: headerHeight,
        headerWidth: headerWidth,
        footerTop: footerTop,
        footerHeight: footerHeight,
        footerWidth: footerWidth
      };

      if (properties !== undefined) {
        return properties;
      } else {
        console.warn(
          "The grid was not rendered correctly. " +
            "Make sure that the grid has all of required components."
        );
      }
    }

    return undefined;
  }
}
