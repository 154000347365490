// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import OperationsCharts, {
  IData
} from "@apm/widgets/build/widgets/OperationsCharts";
import { memo } from "react";

interface IChartsProps {
  data: IData[];
  onTimesLinesAfterChange?: (valueTime: number, slopeTime: number) => void;
  defaultSlopeTime: number;
  defaultValueTime: number;
}

const Charts = memo(
  ({
    data,
    defaultSlopeTime,
    defaultValueTime,
    onTimesLinesAfterChange
  }: IChartsProps) => {
    return (
      <OperationsCharts
        data={data}
        maxTime={500}
        defaultSlopeTime={defaultSlopeTime}
        defaultValueTime={defaultValueTime}
        onTimeLinesAfterChange={onTimesLinesAfterChange}
        xValueFormatter={(v: number) => `${v} ms`}
      />
    );
  }
);

export default Charts;
