// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Reducer } from "redux";

import Data from "core/data/models/Data";
import { IAjaxAction } from "reducers/Index";
import { Actions } from "../actions/AgingActions";
import IAgingVisualization from "../models/IAgingVisualization";

export interface IState {
  agingVisualization: Data<IAgingVisualization>;
}

export const defaultState: IState = {
  agingVisualization: new Data()
};

const reducer: Reducer<IState> = (
  state = defaultState,
  action: IAjaxAction<IAgingVisualization>
): IState => {
  switch (action.type) {
    case Actions.DetailPage_ReplacementScore_SetAgingVisualizationAction:
      return {
        ...state,
        agingVisualization: new Data(
          (action as IAjaxAction<IAgingVisualization>).request,
          (action as IAjaxAction<IAgingVisualization>).data
        )
      };
    default:
      return state;
  }
};

export default reducer;
