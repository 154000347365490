// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { ConnectedDeviceKey } from "./IConnectedDevice";

export const ConnectedDeviceModelKeys: ConnectedDeviceKey[] = [
  ConnectedDeviceKey.DeviceType,
  ConnectedDeviceKey.DeviceName,
  ConnectedDeviceKey.DeviceHost,
  ConnectedDeviceKey.DevicePort,
  ConnectedDeviceKey.UnitId,
  ConnectedDeviceKey.RegisterAddressOffset,
  ConnectedDeviceKey.RegisterConfiguration
];

export const isConnectedDeviceModel = (
  key: string
): key is ConnectedDeviceKey =>
  ConnectedDeviceModelKeys.includes(key as ConnectedDeviceKey);
