// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Typography } from "antd";
import HistorySwitchingChart from "features/detailpage/features/ltc/components/HistorySwitchingChart";
import { IHistorySwitchingOperationsData } from "features/detailpage/features/ltc/models/IHistorySwitichingOperations";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import {
  fontSizeMedium,
  spacingMedium,
  spacingXLarge
} from "styles/StyleVariables";

const { Text } = Typography;

interface IHistorySwitchingContentProps {
  historySwitching: IHistorySwitchingOperationsData;
  className?: string;
}

const HistorySwitchingContent = ({
  historySwitching,
  className
}: IHistorySwitchingContentProps) => (
  <div className={className} data-qa="history-switching">
    <Text strong className="chart-title">
      <FormattedMessage
        id="detail_page.widgets.analytics.transformers.ltc.history_operations_name"
        defaultMessage="History of Switching Operations"
      />
    </Text>
    <HistorySwitchingChart historySwitching={historySwitching} />
  </div>
);

export default styled(HistorySwitchingContent)`
  height: 100%;
  display: flex;
  flex-direction: column;

  .chart-title {
    padding: 0 ${spacingMedium} ${spacingXLarge};
    font-size: ${fontSizeMedium};
  }
`;
