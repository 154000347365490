// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import ILTCOperations from "features/detailpage/features/ltc/models/ILTCOperations";
import { IAjaxAction } from "reducers/Index";
import { AppDispatch } from "store";
import { config } from "utils/AppConfig";

export enum Actions {
  DetailPage_Ltc_SetOperations = "DetailPage_Ltc_SetOperations",
  RiskMapDashboard_SetLocationRisk = "RiskMapDashboard_SetLocationRisk"
}

export const setOperationsAction = (
  request: JQuery.jqXHR,
  data?: ILTCOperations
): IAjaxAction<ILTCOperations> => ({
  request,
  data,
  type: Actions.DetailPage_Ltc_SetOperations
});

export const loadLtcOperationsAction =
  (assetId: string) => (dispatch: AppDispatch) =>
    fetchLtcOperations(dispatch, assetId);

function fetchLtcOperations(
  dispatch: AppDispatch,
  assetId: string
): Promise<void> {
  return new Promise((resolve, reject) => {
    const url = UrlService.getApiUrl(config.api.detailPage.ltcOperationsUrl, [
      {
        name: "assetId",
        value: assetId
      }
    ]);
    const request = EndpointService.getJson<ILTCOperations>(
      url,
      (request, data) => {
        dispatch(setOperationsAction(request, data));
        resolve();
      },
      (request) => {
        dispatch(setOperationsAction(request));
        reject();
      }
    );

    dispatch(setOperationsAction(request));
  });
}
