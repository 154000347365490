// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import FilterBar from "common/FilterBar";
import IssuesGrid from "features/issues/components/IssuesGrid";
import IssueSlidePanel from "features/issues/components/IssuesSlidePanel";
import useIssues from "../hooks/useIssues";

import "./Issues.less";

const Issues = () => {
  const {
    filters,
    handleFilter,
    handleMaintenancePriorityClick,
    handleMaintenancePriorityRequestClick,
    handleNumberOfActionsClick,
    issueSlidePanelMode,
    slidePanelData,
    slidePanelRef
  } = useIssues();

  return (
    <div className="issues-page">
      <FilterBar
        onFilter={handleFilter}
        namespace="issues"
        useSearch
        useAssetOrganizationWithRegionAndLocation
        useAssetTypeWithSubType
        useAssetImportance
        useAssetRisk
        useAssetCompanyHierarchy
        useIssueStatus
        useAssetStatus
      >
        {filters && (
          <IssuesGrid
            filters={filters}
            onMaintenancePriorityClick={handleMaintenancePriorityClick}
            onMaintenancePriorityRequest={handleMaintenancePriorityRequestClick}
            onNumberOfActionsClick={handleNumberOfActionsClick}
          />
        )}
      </FilterBar>
      <IssueSlidePanel
        slidePanelRef={slidePanelRef}
        issueSlidePanelMode={issueSlidePanelMode}
        slidePanelData={slidePanelData}
      />
    </div>
  );
};

export default Issues;
