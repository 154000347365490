// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { IntlShape } from "react-intl";

function getHeaderLabel(
  manufacturer: string | null,
  model: string | null,
  installDate: string | null,
  intl: IntlShape
): string {
  const manufacturerLabel = manufacturer ? manufacturer : "";
  const modelLabel = model ? `${model},` : "";
  const installDateLabel = installDate
    ? `${intl.formatMessage({
        id: "detail_page.widgets.analytics.transformers.bushings_installed",
        defaultMessage: "installed"
      })}: ${intl.formatDate(installDate, {
        year: "numeric"
      })}`
    : "";

  return `${manufacturerLabel} ${modelLabel} ${installDateLabel}`;
}

export default getHeaderLabel;
