// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { config } from "utils/AppConfig";

const useTriggerModel = () => {
  const getUrl = (modelId: string, assetId?: string) => {
    if (assetId) {
      return UrlService.getApiUrl(config.api.modelTrigger.asset, [
        {
          name: "modelId",
          value: modelId
        },
        {
          name: "assetId",
          value: assetId
        }
      ]);
    }

    return UrlService.getApiUrl(config.api.modelTrigger.model, [
      {
        name: "modelId",
        value: modelId
      }
    ]);
  };

  const triggerModel = (modelId: string, assetId?: string) =>
    new Promise((resolve, reject) => {
      const url = getUrl(modelId, assetId);
      EndpointService.post(url, resolve, reject);
    });

  return { triggerModel };
};

export default useTriggerModel;
