// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import { Tooltip } from "antd";
import { ButtonType } from "antd/lib/button/index";
import ButtonWithIcon from "common/button/ButtonWithIcon";
import TooltipWrapper, {
  Themes,
  TooltipPosition
} from "common/tooltipwrapper/TooltipWrapper";
import useShowTextForNameplateIcon from "features/detailpage/components/nameplateIcons/hooks/useShowTextForNameplateIcon";
import { memo } from "react";
import useRecalculateButton from "../hooks/useRecalculateButton";
import "./RecalculateButton.less";
import RecalculateButtonTooltip from "./RecalculateButtonTooltip";

interface IRecalculateButton {
  modelId: string;
  assetId?: string;
  type?: ButtonType;
  disabled?: boolean;
  tooltipPosition?: TooltipPosition;
}

const RecalculateButton = ({
  type,
  disabled,
  assetId,
  modelId,
  tooltipPosition = TooltipPosition.BottomCenter
}: IRecalculateButton) => {
  const { handleClick, Label, isRecalculating } = useRecalculateButton({
    assetId,
    modelId
  });
  const showTextForIcon = useShowTextForNameplateIcon();

  const Button = () => (
    <div>
      <ButtonWithIcon
        className={`recalculate-button ${isRecalculating ? "calculating" : ""}`}
        disabled={isRecalculating || disabled}
        onClick={handleClick}
        type={type}
        icon={
          showTextForIcon ? (
            <Icon name="refresh" />
          ) : (
            <Tooltip placement="top" title={<Label />}>
              <span>
                <Icon name="refresh" />
              </span>
            </Tooltip>
          )
        }
      >
        {showTextForIcon && <Label />}
      </ButtonWithIcon>
    </div>
  );

  return isRecalculating ? (
    <TooltipWrapper
      Tooltip={RecalculateButtonTooltip}
      theme={Themes.Dark}
      position={tooltipPosition}
    >
      <div>
        <Button />
      </div>
    </TooltipWrapper>
  ) : (
    <Button />
  );
};

export default memo(RecalculateButton);
