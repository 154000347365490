// Copyright 2016-2024 Hitachi Energy. All rights reserved.

enum AssetImplementationId {
  Battery = "abbbatterymodel",
  AbbTransformerStandardModel = "abbtransformerstandardmodelv6",
  DcTransformer = "abbtransformerhvdcmodelv6",
  KinectricsTransformerSiliconeIEEE = "kinectricssiliconeieeenetworktransformer",
  KinectricsTransformerMineralOilIEC = "kinectricsmineraloiliecnetworktransformer",
  CircuitBreaker = "abbcircuitbreakerprofessional"
}

export default AssetImplementationId;
