// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { IRegion } from "@apm/widgets/build/widgets/RogersRatio2D/models/IRegion";
import {
  colorBlue30,
  colorBlue40,
  colorBlue60,
  colorBlue70,
  colorBlue80
} from "@pg/common/build/styles/ColorVariables";

const regionsChart1: IRegion[] = [
  {
    name: "T1",
    color: colorBlue30,
    vertices: [
      { x: 0.01, y: 0.001 },
      { x: 1, y: 0.001 },
      { x: 1, y: 0.01 },
      { x: 0.01, y: 0.01 }
    ]
  },
  {
    name: "T2",
    color: colorBlue80,
    vertices: [
      { x: 1, y: 0.001 },
      { x: 4, y: 0.001 },
      { x: 4, y: 0.01 },
      { x: 1, y: 0.01 }
    ]
  },
  {
    name: "T3",
    color: colorBlue40,
    vertices: [
      { x: 4, y: 0.001 },
      { x: 1000, y: 0.001 },
      { x: 1000, y: 0.01 },
      { x: 4, y: 0.01 }
    ]
  },
  {
    name: "DT",
    color: colorBlue60,
    vertices: [
      { x: 0.01, y: 0.01 },
      { x: 1000, y: 0.01 },
      { x: 1000, y: 0.2 },
      { x: 0.01, y: 0.2 }
    ]
  },
  {
    name: "D2",
    color: colorBlue70,
    vertices: [
      { x: 0.01, y: 0.2 },
      { x: 1000, y: 0.2 },
      { x: 1000, y: 1000 },
      { x: 0.01, y: 1000 }
    ]
  }
];

export default regionsChart1;
