// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Chart } from "billboard.js";
import BBChart from "common/chart/components/BBChart";
import ResizeWrapper from "common/ResizeWrapper";
import * as d3 from "d3";
import React from "react";
import { colorBlue50 } from "styles/ColorVariables";
import useChartConfiguration from "../hooks/useChartConfiguration";
import useChartExtensions from "../hooks/useChartExtensions";
import IConductanceData from "../models/IConductanceData";
import "./BatteryConductanceChart.less";
import BatteryConductanceChartInfo from "./BatteryConductanceChartInfo";

const minArrowSize = 12;
const maxArrowSize = 60;

interface IBatteryConductanceChartProps {
  conductanceData: IConductanceData;
}

const BatteryConductanceChart = ({
  conductanceData
}: IBatteryConductanceChartProps) => {
  const [configuration] = useChartConfiguration(conductanceData, maxArrowSize);
  const [extensions] = useChartExtensions(conductanceData);

  const handleRendered = (chart: Chart, container: HTMLDivElement) => {
    const barRects: SVGRect[] = d3
      .select(container)
      .selectAll(".bb-chart .bb-chart-bars .bb-chart-bar .bb-bars path")
      .nodes()
      .map((n: any) => n.getBBox());

    conductanceData.Cells.forEach((c, i) => {
      if (c.DropMagnitude) {
        const barSize = barRects[i].width;
        let arrowSize = barSize * 0.75;
        if (arrowSize >= maxArrowSize) arrowSize = maxArrowSize;
        if (arrowSize <= minArrowSize) arrowSize = minArrowSize;
        const arrowSizeMap = {
          Decrease30: arrowSize,
          Decrease20: arrowSize * 0.75,
          Decrease10: arrowSize * 0.45
        };

        const computedArrowSize = arrowSizeMap[c.DropMagnitude];
        const x = barRects[i].x + (barSize / 2 - computedArrowSize / 2);
        const y = barRects[i].y - computedArrowSize - 10;

        const gs = d3
          .select(container)
          .selectAll(".bb-chart .bb-chart-bars .bb-chart-bar .bb-bars")
          .append("g")
          .attr("transform", `translate(${x},${y})`);

        gs.append("path")
          .attr(
            "d",
            `M 0 ${0.3 * computedArrowSize}
               L ${0.5 * computedArrowSize} ${computedArrowSize}
               L ${computedArrowSize} ${0.3 * computedArrowSize}
               Z`
          )
          .attr(
            "style",
            `fill: ${colorBlue50};
               shape-rendering: geometricPrecision;
               stroke: ${colorBlue50};`
          );
      }
    });
  };

  return (
    <div className="battery-conductance-chart">
      <ResizeWrapper>
        <BBChart
          configuration={configuration}
          extensions={extensions}
          onRendered={handleRendered}
        />
      </ResizeWrapper>
      <BatteryConductanceChartInfo />
    </div>
  );
};

export default BatteryConductanceChart;
