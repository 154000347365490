// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { List, Record } from "immutable";
import { MessageDescriptor } from "react-intl";

const InputRecord = Record({
  defaultValue: null,
  errorMessages: List<MessageDescriptor>(),
  valid: true,
  value: null,
  guid: null
});

export default InputRecord;
