// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { IntlShape } from "react-intl";
import { gasGroupSettings } from "../constants/gasGroupSettings";
import IDGAData from "../models/IDGAData";
import mapToChart from "./mapToChart";

const getChartGroups = (intl: IntlShape, dgaData: IDGAData) => {
  return gasGroupSettings.map((g) => ({
    name: g.name,
    title: intl.formatMessage({
      defaultMessage: g.defaultMessage,
      id: g.id
    }),
    charts: g.gases
      ?.map((gasName) =>
        mapToChart(
          intl,
          dgaData?.GasData ? dgaData.GasData[gasName] : undefined,
          dgaData?.GasStandard,
          dgaData?.GasStandardRevision,
          gasName
        )
      )
      .filter((p) => p.values)
  }));
};

export default getChartGroups;
