// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { FormattedMessage } from "react-intl";

import TextFieldInputContainer from "common/form/containers/TextFieldInputContainer";
import IIssue from "features/detailpage/features/issues/models/IIssue";
import IIssueMeta from "features/detailpage/features/issues/models/IIssueMeta";
import IssueModes from "features/detailpage/features/issues/models/IssueModes";
import { issueDetailsFormName } from "./IssueDetails";

interface IIssueDetailsRecommendationProps {
  issue: IIssue;
  meta: IIssueMeta;
}

const IssueDetailsRecommendation = ({
  issue,
  meta
}: IIssueDetailsRecommendationProps) => {
  const recommendation = issue.RecommendationText;
  const timeframe = issue.TimeframeText;

  const value =
    recommendation && timeframe
      ? `${recommendation}\n\n${timeframe}`
      : recommendation
      ? recommendation
      : timeframe
      ? timeframe
      : null;

  return meta.mode === IssueModes.View ? (
    <div>
      <div className="default-grey-label">
        <FormattedMessage
          defaultMessage="Recommendation"
          id="detail_page.issues.issue_details.recommendation"
        />
      </div>
      <div className="value">{value}</div>
    </div>
  ) : meta.mode === IssueModes.Create || meta.mode === IssueModes.Edit ? (
    <div>
      <TextFieldInputContainer
        defaultValue={value}
        formName={issueDetailsFormName}
        inputName="recommendation"
        label={{
          defaultMessage: "Recommendation",
          id: "detail_page.issues.issue_details.form.recommendation"
        }}
        maxLength={10000}
        multiline={true}
      />
    </div>
  ) : null;
};

export default IssueDetailsRecommendation;
