// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { loadMapProvider } from "core/app/actions/MapProviderActions";
import {
  loadCustomer,
  loadCustomerIssueSettings,
  loadCustomerVisualizations,
  loadCustomerVisualizationsAssetGrid,
  loadCustomerVisualizationsCommandIntegration,
  loadCustomerVisualizationsComponentGrid,
  loadCustomerVisualizationsIssueGrid,
  loadWatchListLimit
} from "core/app/actions/settings/CustomerActions";
import { loadEnvironment } from "core/app/actions/settings/EnvironmentActions";
import { loadTenantList } from "core/app/actions/settings/TenantActions";
import { loadUser } from "core/app/actions/settings/UserActions";
import SettingsProvider, {
  ISettingsProviderActions
} from "core/app/components/SettingsProvider";
import { connect } from "react-redux";
import { AppDispatch } from "store";

function mapDispatchToProps(dispatch: AppDispatch): ISettingsProviderActions {
  return {
    loadCustomer: () => loadCustomer(dispatch),
    loadEnvironment: () => loadEnvironment(dispatch),
    loadMapProvider: () => loadMapProvider(dispatch),
    loadTenantList: () => loadTenantList(dispatch),
    loadUser: () => loadUser(dispatch),
    loadCustomerVisualizations: () => loadCustomerVisualizations(dispatch),
    loadCustomerVisualizationsAssetGrid: () =>
      loadCustomerVisualizationsAssetGrid(dispatch),
    loadCustomerVisualizationsIssueGrid: () =>
      loadCustomerVisualizationsIssueGrid(dispatch),
    loadCustomerVisualizationsComponentGrid: () =>
      loadCustomerVisualizationsComponentGrid(dispatch),
    loadCustomerVisualizationsCommandIntegration: () =>
      loadCustomerVisualizationsCommandIntegration(dispatch),
    loadCustomerIssueSettings: () => loadCustomerIssueSettings(dispatch),
    loadWatchListLimit: () => loadWatchListLimit(dispatch)
  };
}

const SettingsProviderContainer = connect(
  undefined,
  mapDispatchToProps
)(SettingsProvider);

export default SettingsProviderContainer;
