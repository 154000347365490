// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import Data from "core/data/models/Data";
import { IState } from "reducers/Index";

import IDielectricData from "../../../models/IDielectricData";
import { dataKey } from "../actions/loadDielectricPressure";

const getDielectricPressureSelector = createSelector(
  (state: IState) => state.data[dataKey] as Data<IDielectricData>,
  (pressure) => pressure || new Data<IDielectricData>()
);

export default getDielectricPressureSelector;
