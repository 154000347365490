// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { IChart } from "@apm/widgets/build/widgets/ParameterCharts";
import { ReactNode, useEffect, useMemo } from "react";
import { IntlShape, useIntl } from "react-intl";
import { colorBlue60, colorStatusOrange } from "styles/ColorVariables";
import chartSettingsIEC from "../constants/chartSettingsIEC";
import chartSettingsIEEE from "../constants/chartSettingsIEEE";
import chartSettingsSinglePowerFactor from "../constants/chartSettingsSinglePowerFactor";
import { OilTest } from "../models/IOilSettings";
import { OilTestSinglePowerFactor } from "../models/IOilSettingsSinglePowerFactor";
import IPowerFactorSeries from "../models/IPowerFactorSeries";
import IPowerFactorThresholds from "../models/IPowerFactorThresholds";
import { IChartsCollection } from "../models/ISOTData";
import { PowerFactorType } from "../models/PowerFactorTypeEnums";
import SotTypes from "../models/SotTypes";
import getSotType from "../utils/getSotType";
import isKntIeee from "../utils/isKntIeee";
import mapToChart from "../utils/mapToChart";

const getDisplayName = (
  intl: IntlShape,
  oilTest: OilTest,
  sotType: string
): ReactNode => {
  return (
    <span>
      {intl.formatMessage({
        defaultMessage: `${oilTest}`,
        id: `detail_page.widgets.analytics.transformers.StandardOilTests.${getSotType(
          sotType
        )}.${oilTest}.Title`
      })}
    </span>
  );
};

const getPowerFactorSeries = (chartsData: IChartsCollection) => {
  const oilName = chartsData ? Object.keys(chartsData) : null;
  const series: IPowerFactorSeries[] = [];

  oilName?.forEach((name) => {
    if (name.includes("PowerFactor")) {
      series.push({
        [name]: chartsData[name].OfflineTrends
      });
    }
  });

  return series;
};

const getPowerFactorThresholds = (chartsData: IChartsCollection) => {
  const oilName = chartsData ? Object.keys(chartsData) : null;
  const thresholds: IPowerFactorThresholds[] = [];

  oilName?.forEach((name) => {
    if (name.includes("PowerFactor")) {
      const key = Object.keys(chartsData[name].Thresholds)[0];
      thresholds.push({
        [name]: chartsData[name].Thresholds[key]
      });
    }
  });

  return thresholds;
};

const createChartsData = (chartsData: IChartsCollection): IChartsCollection => {
  const oilSettings = Object.keys(chartsData);
  const powerFactorTypes: string[] = [
    PowerFactorType.PF100,
    PowerFactorType.PF25,
    PowerFactorType.PF90
  ];

  const isPowerFactor = oilSettings.some((oilSetting) =>
    powerFactorTypes.includes(oilSetting)
  );

  const powerFactorOilTypes = oilSettings.filter((oilSetting) =>
    powerFactorTypes.includes(oilSetting)
  );

  if (isPowerFactor && powerFactorOilTypes.length > 1) {
    return {
      ...chartsData,
      PowerFactor: { Thresholds: {}, OfflineTrends: {}, OnlineTrends: {} }
    };
  }

  return chartsData;
};

const useSOT = (
  assetId: string,
  loadSOTData: (assetId: string) => void,
  removeSOTData: (assetId: string) => void,
  chartsData: IChartsCollection,
  sotType: SotTypes
) => {
  const intl = useIntl();
  const oils = useMemo<IChart[]>(() => {
    if (!chartsData) return;

    const newChartsData = createChartsData(chartsData);
    const powerFactorSeries = getPowerFactorSeries(chartsData);
    const powerFactorThresholds = getPowerFactorThresholds(chartsData);

    switch (true) {
      case powerFactorSeries.length > 1:
        return Object.keys(chartSettingsSinglePowerFactor)
          .map((oilTest) =>
            mapToChart(
              newChartsData[oilTest],
              oilTest as OilTestSinglePowerFactor,
              getDisplayName(
                intl,
                oilTest as OilTestSinglePowerFactor,
                sotType
              ),
              sotType,
              intl,
              powerFactorSeries,
              powerFactorThresholds
            )
          )
          .filter((p) => p.values);
      case isKntIeee(sotType):
        return Object.keys(chartSettingsIEEE)
          .map((oilTest) =>
            mapToChart(
              chartsData[oilTest],
              oilTest as OilTest,
              getDisplayName(intl, oilTest as OilTest, sotType),
              sotType,
              intl
            )
          )
          .filter((p) => p.values);
      default:
        return Object.keys(chartSettingsIEC)
          .map((oilTest) =>
            mapToChart(
              chartsData[oilTest],
              oilTest as OilTest,
              getDisplayName(intl, oilTest as OilTest, sotType),
              sotType,
              intl
            )
          )
          .filter((p) => p.values);
    }
  }, [chartsData, intl, sotType]);

  const getSeriesColor = (seriesName: string) => {
    if (seriesName.includes("online")) {
      return colorBlue60;
    } else {
      const powerFactorSeries = getPowerFactorSeries(chartsData);
      const distinctColors = powerFactorSeries.length > 1;
      if (!distinctColors) return "black";

      switch (seriesName) {
        case `${PowerFactorType.PF100}_offline`:
        case PowerFactorType.PF100:
        case `${PowerFactorType.PF90}_offline`:
        case PowerFactorType.PF90:
          return colorBlue60;
        case `${PowerFactorType.PF25}_offline`:
        case PowerFactorType.PF25:
          return colorStatusOrange;
        default:
          return "black";
      }
    }
  };

  useEffect(() => {
    loadSOTData(assetId);
    return () => {
      removeSOTData(assetId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId]);

  return {
    oils,
    getSeriesColor,
    sotType
  };
};

export default useSOT;
