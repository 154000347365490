// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Modal as AntdModal } from "antd";
import { ModalProps } from "antd/lib/modal";
import { Children, ReactNode } from "react";

interface IModalProps extends ModalProps {
  children: ReactNode;
}

const Modal = ({ children, ...antdProps }: IModalProps) => (
  <AntdModal {...(antdProps as ModalProps)}>
    {Children.toArray(children)}
  </AntdModal>
);

export default Modal;
