// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import SotTypes from "../models/SotTypes";

const getSotType = (sotType: string) => {
  let shortSotType: string;

  switch (sotType) {
    case SotTypes.MINERAL_OIL_IEC_NETWORK_TRANSFORMER:
      shortSotType = "IEC";
      break;
    case SotTypes.SILICONE_IEEE_NETWORK_TRANSFORMER:
      shortSotType = "IEEE";
      break;
    default:
      shortSotType = sotType;
  }

  return shortSotType;
};

export default getSotType;
