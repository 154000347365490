// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Switch from "./Switch";

import {
  ISwitchItem as _ISwitchItem,
  ISwitchProps as _ISwitchProps
} from "./Switch";

export default Switch;
export type ISwitchItem = _ISwitchItem;
export type ISwitchProps = _ISwitchProps;
