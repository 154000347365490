// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import * as React from "react";

import SlidePanel from "common/slidepanel/SlidePanel";

import useIssuesSlidePanel, {
  ISlidePanelData,
  IssueSlidePanelMode
} from "../hooks/useIssuesSlidePanel";

interface IIssueSlidePanelProps {
  slidePanelRef: React.MutableRefObject<SlidePanel>;
  issueSlidePanelMode: IssueSlidePanelMode;
  slidePanelData: ISlidePanelData;
}

const IssuesSlidePanel = ({
  slidePanelRef,
  issueSlidePanelMode,
  slidePanelData
}: IIssueSlidePanelProps) => {
  const { ContentComponent, PanelComponent } = useIssuesSlidePanel(
    issueSlidePanelMode,
    slidePanelData
  );
  return (
    <SlidePanel
      PanelTitle={
        issueSlidePanelMode !== IssueSlidePanelMode.Loading
          ? PanelComponent
          : null
      }
      ref={(ref: any) => {
        slidePanelRef.current = ref;
      }}
    >
      {ContentComponent && <ContentComponent />}
    </SlidePanel>
  );
};

export default IssuesSlidePanel;
