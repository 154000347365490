// Copyright 2016-2024 Hitachi Energy. All rights reserved.

enum EDuvalType {
  Type1 = "Type1",
  Type3 = "Type3",
  Type4 = "Type4",
  Type5 = "Type5"
}

export default EDuvalType;
