// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import DataSource from "common/DuvalAnalysis/models/DataSource";
import { Statuses } from "core/data/models/Data";
import UrlService from "core/data/services/UrlService";
import { ITriangleData } from "features/detailpage/components/analytics/DuvalTriangle/DuvalTrianglesTab";
import IDuvalTabConfiguration from "features/detailpage/components/analytics/DuvalTriangle/models/IDuvalTabConfiguration";
import $ from "jquery";
import { useCallback, useEffect, useState } from "react";
import { config } from "utils/AppConfig";
import { IData } from "utils/WebService";
import getDuvalTabs from "../utils/getDuvalTabs";

const DEFAULT_DUVAL_DATA: IData<ITriangleData> = {
  data: {
    fluidType: null,
    gasStandard: null,
    revision: null,
    type1: [],
    type3: [],
    type4: [],
    type5: []
  },
  message: "",
  status: Statuses.Loading
};

const useDuvalLoadData = (
  assetId: string,
  dataSource: DataSource,
  implementationId: string
) => {
  const [duvalConfiguration, setDuvalConfiguration] =
    useState<IDuvalTabConfiguration>(null);
  const [isDuvalType3HasNote, setIsDuvalType3HasNote] =
    useState<boolean>(false);
  const [triangleData, setTriangleData] =
    useState<IData<ITriangleData>>(DEFAULT_DUVAL_DATA);
  const [showPentagons, setShowPentagons] = useState<boolean>(false);

  const onSuccess = useCallback(
    (data: ITriangleData): void => {
      const { fluidType, gasStandard, revision } = data;

      const { duvalConfiguration, isDuvalType3HasNote, showPentagons } =
        getDuvalTabs(implementationId, gasStandard, revision, fluidType);

      setShowPentagons(showPentagons);
      setTriangleData({
        data,
        status: Statuses.Succeeded,
        message: ""
      });
      setDuvalConfiguration(duvalConfiguration);
      setIsDuvalType3HasNote(isDuvalType3HasNote);
    },
    [implementationId]
  );

  const onError = useCallback((xhr: JQueryXHR, status: any, err: any): void => {
    setTriangleData({
      data: {
        fluidType: null,
        gasStandard: null,
        revision: null,
        type1: [],
        type3: [],
        type4: [],
        type5: []
      },
      status: xhr.status === 404 ? Statuses.NotFound : Statuses.Failed,
      message: xhr.responseJSON
    });
    setDuvalConfiguration(null);
    setIsDuvalType3HasNote(false);
    setShowPentagons(true);
  }, []);

  useEffect(() => {
    setTriangleData(DEFAULT_DUVAL_DATA);
    queryForParameters(assetId, dataSource, onSuccess, onError);
  }, [assetId, dataSource, onError, onSuccess]);

  return {
    duvalConfiguration,
    triangleData,
    isDuvalType3HasNote,
    showPentagons
  };
};

function queryForParameters(
  assetId: string,
  dataSource: string,
  onSuccess: (data: ITriangleData | null | undefined) => void,
  onError: (xhr: JQueryXHR, status: any, err: any) => void
): void {
  const url = UrlService.getApiUrl(
    config.api.detailPage.assetDuvalTrianglesGasesConcentrationUrl,
    [
      {
        name: "assetId",
        value: assetId
      },
      {
        name: "dataSource",
        value: dataSource
      }
    ]
  );
  $.ajax({
    url: url,
    type: "GET",
    dataType: "json",
    cache: false,
    success: onSuccess,
    error: onError,
    contentType: "application/json; charset=UTF-8",
    processData: false
  });
}

export default useDuvalLoadData;
