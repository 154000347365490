// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import * as React from "react";
import { FormattedMessage } from "react-intl";

import ToggleItem from "./ToggleItem";

import { NotificationsFormFields } from "features/notifications/selectors/getNotificationsFormSelector";

import "./DegradedToggleView.less";
import Guid from "core/guid/Guid";

interface IDegradedToggleViewStateProps {
  notificationsEnabled: boolean;
  degradedToRed: boolean;
  degradedToYellow: boolean;
  improvedToGreen: boolean;
  improvedToYellow: boolean;
  allDegradedChecked: boolean;
  onDegradedToggleChanged?: () => void;
}

interface IDegradedToggleViewProps extends IDegradedToggleViewStateProps {}

const DegradedToggleView = ({
  notificationsEnabled,
  degradedToRed,
  degradedToYellow,
  improvedToGreen,
  improvedToYellow,
  onDegradedToggleChanged,
  allDegradedChecked
}: IDegradedToggleViewProps) => {
  const onChange = React.useCallback(() => {
    if (onDegradedToggleChanged) onDegradedToggleChanged();
  }, [onDegradedToggleChanged]);

  const [key, setKey] = React.useState(Guid.getUniqGuid());
  React.useEffect(() => {
    if (allDegradedChecked !== undefined) {
      setKey(Guid.getUniqGuid());
    }
  }, [allDegradedChecked]);

  return (
    <div className="degraded-toggle-view" key={key}>
      <div className="notifications__header-required">
        <FormattedMessage
          id="notifications.degraded_toogle.notify_me_by_email_when"
          defaultMessage="Notify me by e-mail when"
        />
        <FormattedMessage defaultMessage="*" id="global.star" />
      </div>
      <div className="degraded-toggle-view__toggles">
        <ToggleItem
          notificationsEnabled={notificationsEnabled}
          toggleValue={degradedToRed}
          message={{
            defaultMessage: "Any asset degrades to red",
            id: "notifications.degraded_toogle.any_asset_degrades_red"
          }}
          inputName={NotificationsFormFields.degradesRed}
          onChange={onChange}
        />
        <ToggleItem
          notificationsEnabled={notificationsEnabled}
          toggleValue={degradedToYellow}
          message={{
            defaultMessage: "Any asset degrades to yellow",
            id: "notifications.degraded_toogle.any_asset_degrades_yellow"
          }}
          inputName={NotificationsFormFields.degradesYellow}
          onChange={onChange}
        />
        <ToggleItem
          notificationsEnabled={notificationsEnabled}
          toggleValue={improvedToGreen}
          message={{
            defaultMessage: "Any asset improves to green",
            id: "notifications.degraded_toogle.any_asset_improves_green"
          }}
          inputName={NotificationsFormFields.improvesGreen}
          onChange={onChange}
        />
        <ToggleItem
          notificationsEnabled={notificationsEnabled}
          toggleValue={improvedToYellow}
          message={{
            defaultMessage: "Any asset improves to yellow",
            id: "notifications.degraded_toogle.any_asset_improves_yellow"
          }}
          inputName={NotificationsFormFields.improvesYellow}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default DegradedToggleView;
