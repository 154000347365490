// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { isNil } from "lodash";
import { useEffect, useMemo } from "react";

import getTenantSelector from "core/selectors/getTenantSelector";
import getAssetRiskConfigurationSelector from "features/detailpage/features/riskmatrix/selectors/getAssetRiskConfigurationSelector";
import getRiskMatrixConfigurationSelector from "features/detailpage/features/riskmatrix/selectors/getRiskMatrixConfigurationSelector";
import getRiskMatrixClustersSelector from "../../../selectors/getRiskMatrixClustersSelector";

import Data, { Statuses } from "core/data/models/Data";

import useRiskMatrixConfiguration from "features/detailpage/features/riskmatrix/hooks/useRiskMatrixConfiguration";
import IAssetDetails from "models/IAssetDetails";
import { useAppSelector } from "store";
import useLoadAssetRiskConfig from "../../../hooks/useLoadAssetRiskConfig";
import useLoadClusters from "../../../hooks/useLoadClusters";
import useLoadRiskMatrixConfiguration from "../../../hooks/useLoadRiskMatrixConfiguration";
import useSetIsComponentAction from "../../../hooks/useSetIsComponentAction";

interface IUseRiskMatrixWidget {
  assetId: string;
  assetDetails: Data<IAssetDetails>;
}

const useRiskMatrixWidget = ({
  assetDetails,
  assetId
}: IUseRiskMatrixWidget) => {
  const { getRiskMatrixConfiguration } = useRiskMatrixConfiguration();

  const loadClusters = useLoadClusters();
  const loadRiskMatrixConfiguration = useLoadRiskMatrixConfiguration();
  const loadAssetRiskConfig = useLoadAssetRiskConfig();
  const setIsComponent = useSetIsComponentAction(
    assetDetails?.data?.NameplateWithModelInfo
  );

  const clusters = useAppSelector(getRiskMatrixClustersSelector);
  const assetRiskConfiguration = useAppSelector(
    getAssetRiskConfigurationSelector
  );
  const riskMatrixConfiguration = useAppSelector(
    getRiskMatrixConfigurationSelector
  );
  const tenant = useAppSelector(getTenantSelector);

  const showLoadingComponent =
    (clusters && clusters.status === Statuses.Loading) ||
    (assetRiskConfiguration &&
      assetRiskConfiguration.status === Statuses.Loading) ||
    (riskMatrixConfiguration &&
      riskMatrixConfiguration.status === Statuses.Loading) ||
    assetDetails?.status === Statuses.Loading;

  const showRiskMatrix =
    riskMatrixConfiguration &&
    riskMatrixConfiguration.status === Statuses.Succeeded &&
    assetDetails?.status === Statuses.Succeeded;
  const showNotEmptyRiskMatrix =
    showRiskMatrix &&
    clusters &&
    clusters.status === Statuses.Succeeded &&
    assetRiskConfiguration &&
    assetRiskConfiguration.status === Statuses.Succeeded &&
    !isNil(assetDetails?.data?.NameplateWithModelInfo?.Importance) &&
    !isNil(assetDetails?.data?.DegradationScore);

  const showEmptyRiskMatrix =
    showRiskMatrix &&
    (isNil(assetDetails?.data?.NameplateWithModelInfo?.Importance) ||
      isNil(assetDetails?.data?.DegradationScore));

  const showErrorComponent =
    (clusters && clusters.status === Statuses.Failed) ||
    (assetRiskConfiguration &&
      assetRiskConfiguration.status === Statuses.Failed) ||
    (riskMatrixConfiguration &&
      riskMatrixConfiguration.status === Statuses.Failed) ||
    assetDetails?.status === Statuses.Failed;

  const errorMessage = useMemo(() => {
    if (showErrorComponent) {
      let message: string;
      [
        clusters,
        assetRiskConfiguration,
        riskMatrixConfiguration,
        assetDetails
      ].forEach((x) => {
        if (!message && x && x.status === Statuses.Failed) {
          message = x.message;
        }
      });

      return message;
    }

    return null;
  }, [
    showErrorComponent,
    clusters,
    assetRiskConfiguration,
    riskMatrixConfiguration,
    assetDetails
  ]);

  useEffect(() => {
    if (assetDetails?.data?.NameplateWithModelInfo) {
      setIsComponent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetDetails]);

  useEffect(() => {
    loadRiskMatrixConfiguration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isNil(assetDetails?.data?.NameplateWithModelInfo?.ModelId))
      loadAssetRiskConfig(assetDetails?.data?.NameplateWithModelInfo?.ModelId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetDetails]);

  useEffect(() => {
    const nameplateStatus = assetDetails?.status;
    const configurationStatus = riskMatrixConfiguration?.status;
    const nameplate = assetDetails?.data?.NameplateWithModelInfo;

    if (
      nameplateStatus === Statuses.Succeeded &&
      configurationStatus === Statuses.Succeeded &&
      nameplate.ModelId
    ) {
      const configuration = getRiskMatrixConfiguration(
        riskMatrixConfiguration.data,
        tenant
      );

      loadClusters(
        configuration.DefaultScope,
        nameplate.ModelId,
        nameplate.LifecycleStatus,
        nameplate.Organization,
        nameplate.Region,
        nameplate.Location
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetDetails, riskMatrixConfiguration]);

  return {
    showLoadingComponent,
    showNotEmptyRiskMatrix,
    showErrorComponent,
    errorMessage,
    showEmptyRiskMatrix
  };
};

export default useRiskMatrixWidget;
