// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { IAjaxAction } from "reducers/Index";
import { AppDispatch } from "store";
import { config } from "utils/AppConfig";
import IOpenIssuesInfo from "../models/IOpenIssuesInfo";

export enum Actions {
  OpenIssues_LoadOpenIssues = "OpenIssues_LoadOpenIssues"
}

const setOpenIssuesInfo = (
  request: JQuery.jqXHR,
  data?: IOpenIssuesInfo
): IAjaxAction<IOpenIssuesInfo> => ({
  type: Actions.OpenIssues_LoadOpenIssues,
  request,
  data
});

export const loadOpenIssuesInfo = (dispatch: AppDispatch) => {
  const url: string = UrlService.getApiUrl(config.api.homepage.openIssuesInfo);

  const request = EndpointService.getJson<IOpenIssuesInfo>(
    url,
    (request, data) => dispatch(setOpenIssuesInfo(request, data)),
    (request) => dispatch(setOpenIssuesInfo(request))
  );

  dispatch(setOpenIssuesInfo(request));
};
