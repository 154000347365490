// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { FormattedDate } from "react-intl";
import styled from "styled-components";

interface IDateColumnProps {
  className?: string;
  date: Date;
}

const DateColumn = ({ className, date }: IDateColumnProps) => {
  return (
    <div className={className}>
      <FormattedDate
        value={date}
        year="numeric"
        month="numeric"
        day="numeric"
        hour="numeric"
        minute="numeric"
      />
    </div>
  );
};

const columnPadding = "10px";

export default styled(DateColumn)`
  padding: ${columnPadding};
`;
