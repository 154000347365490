// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Typography } from "antd";
import ICompanyHierarchyNode from "models/ICompanyHierarchyNode";

const { Text } = Typography;

interface ICompanyHierarchyTreeProps {
  level: number;
  path: ICompanyHierarchyNode[];
  selectedHierarchyCode: string;
}

const CompanyHierarchyTree = ({
  level,
  path,
  selectedHierarchyCode
}: ICompanyHierarchyTreeProps) => {
  return (
    <div className={`tree-level-${level}`}>
      <Text strong={selectedHierarchyCode === path[level].HierarchyCode}>
        {path[level].Name}
      </Text>
      {path.length - 1 > level && (
        <CompanyHierarchyTree
          level={level + 1}
          path={path}
          selectedHierarchyCode={selectedHierarchyCode}
        />
      )}
    </div>
  );
};

export default CompanyHierarchyTree;
