// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { useCallback } from "react";
import { useAppDispatch } from "store";
import { setAllAssetTypesCheckedAction } from "../actions/NotificationsActions";

const useSetAllAssetTypesChecked = () => {
  const dispatch = useAppDispatch();
  const setAllAssetTypesChecked = useCallback(
    (value: boolean) => {
      dispatch(setAllAssetTypesCheckedAction(value));
    },
    [dispatch]
  );

  return { setAllAssetTypesChecked };
};

export default useSetAllAssetTypesChecked;
