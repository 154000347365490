// Copyright 2016-2024 Hitachi Energy. All rights reserved.

const membraneLeaksPresent = [
  "serious",
  "moderate",
  "minor damp none",
  "unknown"
];

export default membraneLeaksPresent;
