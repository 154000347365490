import Tooltip from "common/Tooltip";
import { Link } from "react-router-dom";

export default function FamilyContentLink(props: {
  value: string;
  navigateTo: { pathname: string; search: string };
}) {
  const { value, navigateTo } = props;
  return (
    <div className="grid-cell-content">
      <Tooltip title={value}>
        <Link data-qa="link" className="link-label" to={navigateTo}>
          {value}
        </Link>
      </Tooltip>
    </div>
  );
}
