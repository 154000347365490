// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import * as React from "react";

import { IColumn } from "common/datagrid/DataGrid";

interface ISkeletonCellProps {
  column: IColumn;
  singleLine: boolean;
}

const SkeletonCell = (props: ISkeletonCellProps) => {
  const { column, singleLine } = props;

  const style = {
    width: column.state.calculatedWidth,
    minWidth: column.state.calculatedWidth,
    maxWidth: column.state.calculatedWidth
  };

  return (
    <div className="grid-cell" style={style}>
      <div className="first">
        <div className="gradient element1" />
        <div className="gradient element2" />
        <div className="gradient element3" />
        <div className="gradient element4" />
        <div className="gradient element5" />
      </div>
      {!singleLine && <div className="second" />}
    </div>
  );
};

export default SkeletonCell;
