// Copyright 2016-2024 Hitachi Energy. All rights reserved.

const constructionTypes = [
  "din",
  "hvdc",
  "oip",
  "resin_bonded",
  "rip",
  "sf6",
  "solid",
  "unknown"
];

export default constructionTypes;
