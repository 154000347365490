// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Icon } from "@pg/common";
import { colorGray0 } from "@pg/common/build/styles/ColorVariables";
import { Button, Checkbox, Spin } from "antd";
import React, { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import {
  fontSizeMedium,
  spacingMedium,
  spacingXLarge,
  spacingXSmall
} from "styles/StyleVariables";

interface IGridsFooterProps {
  errorsCount: number;
  warningsCount: number;
  onCancel: () => void;
  onSubmit: () => Promise<void>;
  submittingInProgress: boolean;
  className?: string;
}

const GridsFooter = ({
  errorsCount,
  warningsCount,
  onCancel,
  onSubmit,
  submittingInProgress,
  className
}: IGridsFooterProps) => {
  const [allowWarningsSubmit, setAllowWarningsSubmit] = useState<boolean>(true);

  const submitDisabled = useMemo(
    () => errorsCount > 0 || (!allowWarningsSubmit && warningsCount > 0),
    [allowWarningsSubmit, errorsCount, warningsCount]
  );

  return (
    <div className={className}>
      <div>
        <Checkbox
          checked={allowWarningsSubmit}
          onChange={(e) => {
            setAllowWarningsSubmit(e.target.checked);
          }}
          data-qa="invalid-parameter-import"
        >
          <FormattedMessage
            defaultMessage="Allow invalid parameter name import"
            id="bulk_inspection.allow_invalid_parameter_name_import"
          />
        </Checkbox>
      </div>
      <div className="buttons">
        <Button type="default" onClick={onCancel}>
          <Icon name="close" size="sm" />
          <FormattedMessage
            defaultMessage="Cancel"
            id="bulk_inspection.action.cancel"
          />
        </Button>
        <Button
          type="primary"
          onClick={onSubmit}
          disabled={submitDisabled || submittingInProgress}
        >
          {submittingInProgress ? <Spin /> : <Icon name="done" size="sm" />}
          <FormattedMessage
            defaultMessage="Submit"
            id="bulk_inspection.action.submit"
          />
        </Button>
      </div>
    </div>
  );
};

const StyledGridsFooter = styled(GridsFooter)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;

    button {
      .icon {
        margin-right: ${spacingMedium};
      }
      .ant-spin {
        margin-right: ${spacingMedium};
        width: ${fontSizeMedium};

        .ant-spin-dot-item {
          background-color: ${colorGray0};
        }

        .ant-spin-dot {
          font-size: ${fontSizeMedium};
          margin-bottom: -${spacingXSmall};
        }
      }
    }

    button + button {
      margin-left: ${spacingXLarge};
    }
  }
`;

export default StyledGridsFooter;
