// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import loadData from "core/data/actions/loadData";
import UrlService from "core/data/services/UrlService";
import { config } from "utils/AppConfig";

export const getDataKey = (assetId: string) =>
  `DetailPage_Parameters_Wear_Chart_Data-${assetId}`;

const loadChartData = (assetId: string) => {
  const url = UrlService.getApiUrl(config.api.detailPage.agingContactWearUrl, {
    assetId
  });

  return loadData({ key: getDataKey(assetId), url, method: "get" });
};

export default loadChartData;
