// Copyright 2016-2024 Hitachi Energy. All rights reserved.

const pumpsManufacturer = [
  "cardinal",
  "harley by cardinal",
  "tecsonics",
  "qe",
  "other"
];

export default pumpsManufacturer;
