// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { IFamilyInfo } from "features/detailpage/features/familyinfo/reducers/FamilyInfoReducer";
import { IAjaxAction } from "reducers/Index";
import { Action } from "redux";
import { AppDispatch } from "store";
import { config } from "utils/AppConfig";

export enum Actions {
  Parameters_SetFamilyInfo = "Parameters_SetFamilyInfo",
  DerivativeParameters_SetFamilyInfo = "DerivativeParameters_SetFamilyInfo",
  DegradationScore_SetFamilyInfo = "DegradationScore_SetFamilyInfo",
  ImportanceScore_SetFamilyInfo = "ImportanceScore_SetFamilyInfo",
  ReplacementScore_SetFamilyInfo = "ReplacementScore_SetFamilyInfo",
  DegradationSubScore_SetFamilyInfo = "Actions.DegradationSubScore_SetFamilyInfo",
  ResetFamilyInfo = "ResetFamilyInfo"
}

export const setParameterFamilyInfoAction = (
  request: JQueryXHR,
  data?: IFamilyInfo
): IAjaxAction<IFamilyInfo> => {
  const action: IAjaxAction<IFamilyInfo> = {
    type: Actions.Parameters_SetFamilyInfo,
    request,
    data
  };
  return action;
};

export const setDerivativeParameterFamilyInfoAction = (
  request: JQueryXHR,
  data?: IFamilyInfo
): IAjaxAction<IFamilyInfo> => {
  const action: IAjaxAction<IFamilyInfo> = {
    type: Actions.DerivativeParameters_SetFamilyInfo,
    request,
    data
  };
  return action;
};

export const setDegradationScoreFamilyInfoAction = (
  request: JQueryXHR,
  data?: IFamilyInfo
): IAjaxAction<IFamilyInfo> => {
  const action: IAjaxAction<IFamilyInfo> = {
    type: Actions.DegradationScore_SetFamilyInfo,
    request,
    data
  };
  return action;
};

export const setDegradationSubScoreFamilyInfoAction = (
  request: JQueryXHR,
  data?: IFamilyInfo
): IAjaxAction<IFamilyInfo> => {
  const action: IAjaxAction<IFamilyInfo> = {
    type: Actions.DegradationSubScore_SetFamilyInfo,
    request,
    data
  };
  return action;
};

export const setReplacementScoreFamilyInfoAction = (
  request: JQueryXHR,
  data?: IFamilyInfo
): IAjaxAction<IFamilyInfo> => {
  const action: IAjaxAction<IFamilyInfo> = {
    type: Actions.ReplacementScore_SetFamilyInfo,
    request,
    data
  };
  return action;
};

export const setImportanceFamilyInfoAction = (
  request: JQueryXHR,
  data?: IFamilyInfo
): IAjaxAction<IFamilyInfo> => {
  const action: IAjaxAction<IFamilyInfo> = {
    type: Actions.ImportanceScore_SetFamilyInfo,
    request,
    data
  };
  return action;
};

export const loadParameterFamilyInfoAction = (
  assetId: string,
  parameterName: string
) => {
  return (dispatch: AppDispatch) => {
    return new Promise<void>((resolve, reject) => {
      const url = UrlService.getApiUrl(
        config.api.detailPage.parameterFamilyInfoUrl,
        {
          assetId,
          parameterName
        }
      );

      const request = EndpointService.getJson<IFamilyInfo>(
        url,
        (request, data) => {
          dispatch(setParameterFamilyInfoAction(request, data));
          resolve();
        },
        (request) => {
          dispatch(setParameterFamilyInfoAction(request));
          reject();
        }
      );

      dispatch(setParameterFamilyInfoAction(request));
    });
  };
};

export const loadDerivativeParameterFamilyInfoAction = (
  assetId: string,
  parameterName: string
) => {
  return (dispatch: AppDispatch) => {
    return new Promise<void>((resolve, reject) => {
      const url = UrlService.getApiUrl(
        config.api.detailPage.derivativeParameterFamilyInfoUrl,
        {
          assetId,
          parameterName
        }
      );

      const request = EndpointService.getJson<IFamilyInfo>(
        url,
        (request, data) => {
          dispatch(setDerivativeParameterFamilyInfoAction(request, data));
          resolve();
        },
        (request) => {
          dispatch(setDerivativeParameterFamilyInfoAction(request));
          reject();
        }
      );

      dispatch(setDerivativeParameterFamilyInfoAction(request));
    });
  };
};

export const loadDegradationScoreFamilyInfoAction = (assetId: string) => {
  return (dispatch: AppDispatch) => {
    return new Promise<void>((resolve, reject) => {
      const url = UrlService.getApiUrl(
        config.api.detailPage.degradationScoreFamilyInfoUrl,
        {
          assetId
        }
      );

      const request = EndpointService.getJson<IFamilyInfo>(
        url,
        (request, data) => {
          dispatch(setDegradationScoreFamilyInfoAction(request, data));
          resolve();
        },
        (request) => {
          dispatch(setDegradationScoreFamilyInfoAction(request));
          reject();
        }
      );

      dispatch(setDegradationScoreFamilyInfoAction(request));
    });
  };
};

export const loadDegradationSubScoreFamilyInfoAction = (
  assetId: string,
  subScoreName: string,
  level: string
) => {
  return (dispatch: AppDispatch) => {
    return new Promise<void>((resolve, reject) => {
      const url = UrlService.getApiUrl(
        config.api.detailPage.degradationSubScoreFamilyInfoUrl,
        [
          {
            name: "assetId",
            value: assetId
          },
          {
            name: "subScoreName",
            value: subScoreName
          },
          {
            name: "level",
            value: level
          }
        ]
      );

      const request = EndpointService.getJson<IFamilyInfo>(
        url,
        (request, data) => {
          dispatch(setDegradationScoreFamilyInfoAction(request, data));
          resolve();
        },
        (request) => {
          dispatch(setDegradationScoreFamilyInfoAction(request));
          reject();
        }
      );

      dispatch(setDegradationScoreFamilyInfoAction(request));
    });
  };
};

export const loadReplacementScoreFamilyInfoAction = (assetId: string) => {
  return (dispatch: AppDispatch) => {
    return new Promise<void>((resolve, reject) => {
      const url = UrlService.getApiUrl(
        config.api.detailPage.replacementScoreFamilyInfoUrl,
        {
          assetId
        }
      );

      const request = EndpointService.getJson<IFamilyInfo>(
        url,
        (request, data) => {
          dispatch(setReplacementScoreFamilyInfoAction(request, data));
          resolve();
        },
        (request) => {
          dispatch(setReplacementScoreFamilyInfoAction(request));
          reject();
        }
      );

      dispatch(setReplacementScoreFamilyInfoAction(request));
    });
  };
};

export const loadImportanceScoreFamilyInfoAction = (assetId: string) => {
  return (dispatch: AppDispatch) => {
    return new Promise<void>((resolve, reject) => {
      const url = UrlService.getApiUrl(
        config.api.detailPage.importanceScoreFamilyInfoUrl,
        {
          assetId
        }
      );

      const request = EndpointService.getJson<IFamilyInfo>(
        url,
        (request, data) => {
          dispatch(setImportanceFamilyInfoAction(request, data));
          resolve();
        },
        (request) => {
          dispatch(setImportanceFamilyInfoAction(request));
          reject();
        }
      );

      dispatch(setImportanceFamilyInfoAction(request));
    });
  };
};

export const resetFamilyInfoAction = (): Action<Actions> => ({
  type: Actions.ResetFamilyInfo
});
