// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { AppDispatch } from "store";
import removeChartData from "./removeChartData";
import removeWear from "./removeWear";

const removeWearData = (dispatch: AppDispatch, assetId: string) => {
  dispatch(removeWear(assetId));
  dispatch(removeChartData(assetId));
};

export default removeWearData;
