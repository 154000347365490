// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { SearchParams } from "@pg/common";
import CenteredWrapper from "common/CenteredWrapper";
import Processing from "components/common/Processing";
import { WidgetErrorMessage } from "components/common/widget/Widget";
import { useAppNavigate } from "core/app/components/RouterProvider";
import Data, { Statuses } from "core/data/models/Data";
import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import update from "immutability-helper";
import { isNil } from "lodash";
import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { IState } from "reducers/Index";
import { AppDispatch } from "store";
import { config } from "utils/AppConfig";
import IDegradationScoreTrend from "../../../models/IDegradationScoreTrend";
import getRiskTrendSimulationConfigSelector from "../../../selectors/getRiskTrendSimulationConfigSelector";
import loadSimulations from "../actions/loadSimulations";
import removeSimulations from "../actions/removeSimulations";
import SimulationModalContext from "../contexts/SimulationModalContext";
import IPinInfo from "../models/IPinInfo";
import ISimulationConfig from "../models/ISimulationConfig";
import ISimulationRequest from "../models/ISimulationRequest";
import ISimulationSummary from "../models/ISimulationSummary";
import IValues from "../models/IValues";
import getSimulationsSelector from "../selectors/getSimulationsSelector";

interface ISimulationModalContextProviderProps {
  children?: ReactNode;
  assetId: string;
  degradationScoreTrend: IDegradationScoreTrend;
  modelId: string;
  implementationId: string;
}

interface ISimulationModalContextProviderActions {
  loadSimulationsSummary: (assetId: string) => void;
  removeSimulationsSummary: (assetId: string) => void;
}

interface ISimulationModalContextProviderData {
  simulationsSummary: Data<IValues<ISimulationSummary>>;
  simulationConfigData: Data<ISimulationConfig>;
}

type SimulationModalContextProviderProps =
  ISimulationModalContextProviderProps &
    ISimulationModalContextProviderActions &
    ISimulationModalContextProviderData;

const updateDelay = 5000;

const SimulationModalContextProvider = ({
  children,
  assetId,
  degradationScoreTrend,
  modelId,
  implementationId,
  simulationsSummary,
  simulationConfigData,
  loadSimulationsSummary,
  removeSimulationsSummary
}: SimulationModalContextProviderProps) => {
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [simulations, setSimulations] = useState<ISimulationSummary[]>([]);

  const navigate = useAppNavigate();
  const location = useLocation();

  useEffect(() => {
    loadSimulationsSummary(assetId);
    return () => {
      removeSimulationsSummary(assetId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId]);

  const sortSimulation = useCallback((simulation: ISimulationSummary[]) => {
    return simulation.sort((a, b) => {
      if (a.IsPinned === b.IsPinned) {
        return a.CreatedBy < b.CreatedBy ? -1 : 1;
      } else {
        return a.IsPinned < b.IsPinned ? 1 : -1;
      }
    });
  }, []);

  useEffect(() => {
    if (simulationsSummary?.status === Statuses.Succeeded) {
      if (!dataLoaded) {
        setDataLoaded(true);

        const newSimulations = simulationsSummary.data.Values || [];
        setSimulations(sortSimulation(newSimulations));

        const simulationIdFromUrl = new SearchParams(location.search).get(
          "simulationId"
        );
        if (!simulationIdFromUrl) changeSearchParams(newSimulations);
      } else {
        if (simulations.length > 0)
          updateSimulationStatuses(simulations, simulationsSummary.data.Values);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [simulationsSummary?.data, simulationsSummary?.status]);

  const updateIntervalRef = useRef<number>();

  useEffect(() => {
    const clearUpdateInterval = () => {
      if (updateIntervalRef.current !== undefined) {
        clearInterval(updateIntervalRef.current);
        updateIntervalRef.current = undefined;
      }
    };

    const setUpdateInterval = () => {
      if (simulations.some((s) => s.Status === "InProgress")) {
        updateIntervalRef.current = window.setInterval(() => {
          loadSimulationsSummary(assetId);
        }, updateDelay);
      }
    };

    clearUpdateInterval();
    setUpdateInterval();
    return clearUpdateInterval;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId, simulations]);

  const updateSearchParams = useCallback(
    (simulationId: string) => {
      const searchParams = new SearchParams(location.search);
      if (simulationId) searchParams.set("simulationId", simulationId);
      else searchParams.delete("simulationId");

      navigate(
        {
          search: searchParams.toString()
        },
        { replace: true }
      );
    },
    [navigate, location.search]
  );

  const changeSearchParams = useCallback(
    (simulations: ISimulationSummary[]) => {
      const firstSimulationId =
        simulations.length > 0 ? simulations[0].Id.toString() : undefined;
      updateSearchParams(firstSimulationId);
    },
    [updateSearchParams]
  );

  const updateSimulationStatuses = useCallback(
    (
      simulations: ISimulationSummary[],
      updatedSimulations: ISimulationSummary[]
    ) => {
      let newSimulations = simulations;
      simulations.forEach((s, i) => {
        if (s.Status === "InProgress") {
          const simulation = updatedSimulations.find((d) => d.Id === s.Id);
          if (!simulation || simulation.Status === "Completed") {
            newSimulations = update(newSimulations, {
              [i]: { Status: { $set: simulation?.Status || null } }
            });
          }
        }
      });
      setSimulations(newSimulations);
    },
    []
  );

  const saveSimulation = useCallback(
    (simulation: ISimulationSummary) => {
      setSimulations(update(simulations, { $unshift: [simulation] }));
      updateSearchParams(simulation.Id.toString());
    },
    [updateSearchParams, simulations]
  );

  const postSimulation = useCallback(
    (request: ISimulationRequest) => {
      const url = UrlService.getApiUrl(
        config.api.detailPage.simulation.requestUrl
      );

      return new Promise<string>((resolve, reject) => {
        EndpointService.postJson<ISimulationSummary>(
          url,
          (_, data) => {
            saveSimulation(data);
            resolve(data.Id.toString());
          },
          (request) => {
            reject(request.responseJSON);
          },
          request
        );
      });
    },
    [saveSimulation]
  );

  const removeSimulation = useCallback(
    (simulationId: string, active: boolean) => {
      const newSimulations = simulations.filter(
        (s) => s.Id.toString() !== simulationId
      );
      setSimulations(newSimulations);
      if (active) changeSearchParams(newSimulations);
    },
    [simulations, changeSearchParams]
  );

  const deleteSimulation = useCallback(
    (simulationId: string, active: boolean) => {
      const url = UrlService.getApiUrl(
        config.api.detailPage.simulation.deleteUrl,
        { simulationId }
      );

      return new Promise<void>((resolve, reject) => {
        EndpointService.delete(
          url,
          () => {
            removeSimulation(simulationId, active);
            resolve();
          },
          (request) => {
            reject(request.responseJSON);
          }
        );
      });
    },
    [removeSimulation]
  );

  const updatePinInfo = useCallback(
    (simulationId: number, isPinned: boolean, pinInfo?: IPinInfo) => {
      const simulationIdx = simulations.findIndex((d) => d.Id === simulationId);
      const newSimulations = update(simulations, {
        [simulationIdx]: {
          $merge: {
            IsPinned: isPinned,
            PinnedBy: pinInfo ? pinInfo.PinnedBy : null,
            PinnedDate: pinInfo ? pinInfo.PinnedDate : null
          }
        }
      });
      setSimulations(newSimulations);
    },
    [simulations]
  );

  const pinSimulation = useCallback(
    (simulationId: number) => {
      const url = UrlService.getApiUrl(
        config.api.detailPage.simulation.pinUrl,
        {
          simulationId: simulationId.toString()
        }
      );

      return new Promise<void>((resolve, reject) => {
        EndpointService.putJson<IPinInfo>(
          url,
          (_, data: IPinInfo) => {
            updatePinInfo(simulationId, true, data);
            resolve();
          },
          (request) => {
            reject(request.responseJSON);
          }
        );
      });
    },
    [updatePinInfo]
  );

  const unpinSimulation = useCallback(
    (simulationId: number) => {
      const url = UrlService.getApiUrl(
        config.api.detailPage.simulation.pinUrl,
        {
          simulationId: simulationId.toString()
        }
      );

      return new Promise<void>((resolve, reject) => {
        EndpointService.delete<string>(
          url,
          () => {
            updatePinInfo(simulationId, false);
            resolve();
          },
          (request) => {
            reject(request.responseJSON);
          }
        );
      });
    },
    [updatePinInfo]
  );

  return (
    <>
      {((simulationsSummary?.status === Statuses.Loading && !dataLoaded) ||
        simulationConfigData?.status === Statuses.Loading) && (
        <CenteredWrapper>
          <Processing className="spinner small dark" />
        </CenteredWrapper>
      )}
      {dataLoaded && simulationConfigData?.status === Statuses.Succeeded && (
        <SimulationModalContext.Provider
          value={{
            assetId,
            degradationScoreTrend,
            modelId,
            implementationId,
            simulationConfig: simulationConfigData.data,
            simulations,
            deleteSimulation,
            postSimulation,
            updateSearchParams,
            pinSimulation,
            unpinSimulation
          }}
        >
          {children}
        </SimulationModalContext.Provider>
      )}
      {((simulationsSummary?.status === Statuses.Failed && !dataLoaded) ||
        simulationConfigData?.status === Statuses.Failed) && (
        <CenteredWrapper>
          <WidgetErrorMessage />
        </CenteredWrapper>
      )}
      {simulationConfigData?.status === Statuses.NotFound && (
        <CenteredWrapper>
          <WidgetErrorMessage
            messageId="detail_page.risk_trend.simulation_modal.message.invalid_configuration"
            messageDefault="Configuration is invalid"
          />
        </CenteredWrapper>
      )}
    </>
  );
};

const mapStateToProps = (
  state: IState,
  { assetId }: ISimulationModalContextProviderProps
): ISimulationModalContextProviderData => ({
  simulationsSummary: getSimulationsSelector(state)(assetId),
  simulationConfigData: getRiskTrendSimulationConfigSelector(state)
});

const mapDispatchToProps = (
  dispatch: AppDispatch
): ISimulationModalContextProviderActions => {
  const loadSimulationsSummary = (assetId: string) => {
    if (!isNil(assetId)) dispatch(loadSimulations(assetId));
  };
  const removeSimulationsSummary = (assetId: string) => {
    if (!isNil(assetId)) dispatch(removeSimulations(assetId));
  };
  return {
    loadSimulationsSummary,
    removeSimulationsSummary
  };
};

const SimulationModalContextProviderWithData = connect(
  mapStateToProps,
  mapDispatchToProps
)(SimulationModalContextProvider);

export default SimulationModalContextProviderWithData;
