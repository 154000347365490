// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import SearchParams from "@pg/common/build/models/SearchParams";
import FilterBar, { SelectedFilters } from "common/FilterBar";
import { useAppNavigate } from "core/app/components/RouterProvider";
import { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import "./AssetQuery.less";
import AssetQueryContent from "./AssetQueryContent";
import { ViewType } from "./AssetViewSwitch";
import assetWatchListFilterId from "./constants/assetWatchListFilterId";

const AssetQuery = () => {
  const intl = useIntl();
  const navigate = useAppNavigate();
  const location = useLocation();
  const [filters, setFilters] = useState<SelectedFilters>();

  const handleFilter = (filters: SelectedFilters) => {
    setFilters(filters);
  };

  const isWatchList = useCallback(() => {
    const watchListFilter = filters?.selects?.filter(
      (f) => f.Id === assetWatchListFilterId
    );
    return watchListFilter?.length === 1;
  }, [filters]);

  const handleViewChange = useCallback(
    (currentView: ViewType) => {
      const search = new SearchParams(location.search);
      if (currentView === ViewType.WatchList) {
        search.set("f_awl", JSON.stringify(["true"]));
      } else {
        search.delete("f_awl");
      }
      navigate(
        {
          search: search.toString()
        },
        { replace: true }
      );
    },
    [location.search, navigate]
  );

  return (
    <div className="asset-query">
      <FilterBar
        onFilter={handleFilter}
        disabled={isWatchList()}
        disabledTooltip={intl.formatMessage({
          defaultMessage: "Filters are disabled for Asset Watch List",
          id: "asset_watch_list.filters.disabled_tooltip"
        })}
        namespace="assets"
        useSearch
        useAssetOrganizationWithRegionAndLocation
        useAssetCompanyHierarchy
        useAssetTypeWithSubType
        useAssetImportance
        useAssetImportanceRange
        useAssetRisk
        useAssetManufacturerWithModel
        useAssetVoltage
        useAssetAge
        useAssetStatus
        useDegradationScoreValueRange
        useAssetWatchList={{ hidden: true, hideOnStatusPanel: true }}
      >
        {filters && (
          <AssetQueryContent
            filters={filters}
            isAssetWatchList={isWatchList()}
            onViewChange={handleViewChange}
          />
        )}
      </FilterBar>
    </div>
  );
};

export default AssetQuery;
