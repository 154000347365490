// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { AxisType, ChartTypes } from "billboard.js";
import ITierCluster from "common/AssetRiskMatrix/models/ITierCluster";
import ClusterService from "common/AssetRiskMatrix/services/ClustersService";
import { isEmpty } from "lodash";
import { useMemo } from "react";

interface IUseAssetRiskMatrixClusterTiersOptions {
  groupedClusters: ITierCluster;
}

const useAssetRiskMatrixClusterTiers = ({
  groupedClusters
}: IUseAssetRiskMatrixClusterTiersOptions) => {
  const clustersNamedSeries = useMemo(
    () =>
      !isEmpty(groupedClusters)
        ? ClusterService.getClustersNamesSeries(groupedClusters)
        : [],
    [groupedClusters]
  );

  const clustersXs = useMemo(
    () =>
      !isEmpty(groupedClusters)
        ? ClusterService.getClustersXs(groupedClusters)
        : {},
    [groupedClusters]
  );

  const clustersChartType = useMemo(
    () =>
      !isEmpty(groupedClusters)
        ? (ClusterService.getClustersPropertyDictionary(
            groupedClusters,
            "scatter"
          ) as { [key: string]: ChartTypes })
        : {},
    [groupedClusters]
  );

  const clustersAxes = useMemo(
    () =>
      !isEmpty(groupedClusters)
        ? (ClusterService.getClustersPropertyDictionary(
            groupedClusters,
            "y"
          ) as {
            [key: string]: AxisType;
          })
        : {},
    [groupedClusters]
  );

  const clustersClasses = useMemo(
    () =>
      !isEmpty(groupedClusters)
        ? ClusterService.getClustersClasses(groupedClusters)
        : {},
    [groupedClusters]
  );

  return {
    clustersNamedSeries,
    clustersXs,
    clustersChartType,
    clustersAxes,
    clustersClasses
  };
};

export default useAssetRiskMatrixClusterTiers;
