// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import loadData from "core/data/actions/loadData";
import UrlService from "core/data/services/UrlService";
import { config } from "utils/AppConfig";

export const dataKey = "DetailPage_Dielectric_Pressure_Parameters";

const loadPressureParameters = (assetId: string) => {
  const url = UrlService.getApiUrl(
    config.api.detailPage.dielectricPressureParametersUrl,
    { assetId }
  );

  return loadData({ key: dataKey, url });
};

export default loadPressureParameters;
