// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Data from "core/data/models/Data";
import { IState } from "reducers/Index";
import { createSelector } from "reselect";
import { getDataKey } from "../actions/loadOperations";
import IOperation from "../models/IOperation";

const getOperationsSelector = createSelector(
  (state: IState) => (assetId: string, eventId: string) =>
    state.data[getDataKey(assetId, eventId)] as Data<IOperation>,
  (operations) => operations
);

export default getOperationsSelector;
