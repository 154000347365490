// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import {
  IColumn,
  IColumnConfig,
  IRow,
  IRowData,
  IRowState
} from "common/datagrid/DataGrid";
import { IColumnOrder } from "common/datagrid/models/IColumnOrder";
import { IEndpoint } from "common/datagrid/models/IEndpoint";
import SortOrders from "common/datagrid/models/SortOrders";
import DataExportService from "common/datagrid/services/DataExportService";
import EndpointService from "common/datagrid/services/EndpointService";
import { sumBy } from "lodash";
import { IntlShape } from "react-intl";

export const initialChunkSize = 50;
export const nextChunkSize = 30;

export enum ExportTypes {
  Excel = "Excel",
  Csv = "Csv"
}

export enum ContentTypes {
  Assets = "Assets",
  Components = "Components",
  Logs = "Logs"
}

export default class DataService {
  static loadData(
    endpoint: IEndpoint,
    columns: IColumn[],
    rows: IRow[],
    onSuccess: (
      rows: IRow[],
      rowsTotal: number,
      rowsNew: number | null,
      response: JQueryXHR
    ) => void,
    onError: (response: JQueryXHR) => void,
    getRowClassName?: (data: IRowData) => string
  ): JQueryXHR {
    const order = columns
      .filter((c) => c.state.sortOrder !== SortOrders.None)
      .sort((a, b) => a.state.groupOrder - b.state.groupOrder)
      .map<IColumnOrder>((c) => ({
        columnId: c.config.id,
        sortOrder: DataService.sortOrderToString(c.state.sortOrder)
      }));

    return EndpointService.loadNext(
      rows,
      endpoint,
      order,
      (response, data) => {
        const startId = rows ? rows.length : 0;
        const loadedRows = DataService.processData(
          data.rows,
          startId,
          undefined,
          getRowClassName
        );
        const connectedRows = rows ? rows.concat(loadedRows) : loadedRows;

        onSuccess(connectedRows, data.rowsTotal, data.rowsNew, response);
      },
      (response) => {
        onError(response);
      }
    );
  }

  static processColumns(
    columns: IColumnConfig[],
    showCheckboxColumn: boolean = false
  ): IColumn[] {
    return columns.map<IColumn>((c) => ({
      config: c,
      state: {
        sortOrder: c.defaultSortOrder || SortOrders.None,
        groupOrder: c.defaultGroupOrder || undefined,
        calculatedWidth: DataService.getColumnWidth(
          c,
          columns,
          showCheckboxColumn
        )
      }
    }));
  }

  static processRows(
    data: IRowData[],
    columns: IColumn[],
    getDefaultRowSelected?: (data: IRowData) => boolean,
    getRowClassName?: (data: IRowData) => string
  ): IRow[] {
    const rows = DataService.processData(
      data,
      0,
      getDefaultRowSelected,
      getRowClassName
    );
    const sortedRows = DataService.sortRows(columns, rows);

    return sortedRows;
  }

  static sortOrderToString(sortOrder: SortOrders): string {
    switch (sortOrder) {
      case SortOrders.Asc:
        return "asc";
      case SortOrders.Desc:
        return "desc";
      default:
        return "none";
    }
  }

  static sortRows(columns: IColumn[], rows: IRow[]): IRow[] {
    const sortedRows = rows.sort((r1, r2) => {
      let compareResult: number;
      const orderBy = columns
        .concat()
        .sort(
          (c1, c2) => (c1.state.groupOrder || 0) - (c2.state.groupOrder || 0)
        )
        .filter((c) => c.config.sortable !== false && c.state.sortOrder);

      if (orderBy && orderBy.length > 0) {
        compareResult = 0;

        for (const o of orderBy) {
          if (
            o.config.compareFunction ||
            o.config.compareFunctionWithSortOrder
          ) {
            compareResult = this.sortByCompareFunction(o, r1.data, r2.data);
          } else {
            const r1Value = r1.data[o.config.id];
            const r2Value = r2.data[o.config.id];
            if (
              (r1Value !== undefined && r1Value != null) ||
              (r2Value !== undefined && r2Value != null)
            ) {
              if (isNaN(r1Value) || isNaN(r2Value)) {
                compareResult =
                  o.state.sortOrder === SortOrders.Asc
                    ? (r1Value || "").localeCompare(r2Value || "")
                    : (r2Value || "").localeCompare(r1Value || "");
              } else {
                compareResult =
                  o.state.sortOrder === SortOrders.Asc
                    ? r1Value - r2Value
                    : r2Value - r1Value;
              }
            }
          }

          if (compareResult !== 0) break;
        }
      } else {
        compareResult = r1.rowId - r2.rowId;
      }
      return compareResult;
    });

    return sortedRows;
  }

  static sortByCompareFunction(
    {
      config: { compareFunction, compareFunctionWithSortOrder },
      state: { sortOrder }
    }: IColumn,
    row1Data: IRowData,
    row2Data: IRowData
  ) {
    if (compareFunction && !compareFunctionWithSortOrder) {
      let result = compareFunction(row1Data, row2Data);
      if (sortOrder === SortOrders.Desc) {
        result = -result;
      }

      return result;
    } else if (compareFunctionWithSortOrder && !compareFunction) {
      return compareFunctionWithSortOrder(row1Data, row2Data, sortOrder);
    } else {
      console.warn(
        "Both compare function defined. Will use compareFunctionWithSortOrder"
      );
      return compareFunctionWithSortOrder(row1Data, row2Data, sortOrder);
    }
  }

  static stringToSortOrder(text: string): SortOrders {
    switch (text) {
      case "asc":
        return SortOrders.Asc;
      case "desc":
        return SortOrders.Desc;
      default:
        return SortOrders.None;
    }
  }

  static exportData(
    endpoint: IEndpoint,
    columns: IColumn[],
    type: ExportTypes,
    contentType: ContentTypes,
    assetId: string,
    intl: IntlShape
  ) {
    const order = columns
      .filter((c) => c.state.sortOrder !== SortOrders.None)
      .sort((a, b) => a.state.groupOrder - b.state.groupOrder)
      .map<IColumnOrder>((c) => ({
        columnId: c.config.id,
        sortOrder: DataService.sortOrderToString(c.state.sortOrder)
      }));

    if (type === ExportTypes.Excel) {
      return DataExportService.exportToExcel({
        endpoint,
        order,
        intl,
        assetId,
        contentType
      });
    }

    return DataExportService.exportToCsv({
      endpoint,
      order,
      intl,
      assetId,
      contentType
    });
  }

  private static getColumnWidth(
    config: IColumnConfig,
    configs: IColumnConfig[],
    showCheckboxColumn: boolean
  ): string {
    if (config.width !== undefined && config.width !== null) {
      return `${config.width}px`;
    }

    const columnsWithDynamicWidth = configs.filter(
      (c) => c.width === undefined || c.width === null
    );
    const columnsWithStaticWidth = configs.filter(
      (c) => c.width !== undefined && c.width !== null
    );

    const checkboxColumnWidth = showCheckboxColumn ? 55 : 0;

    const sumOfWeight = sumBy(columnsWithDynamicWidth, (c) => c.weight || 1);
    const sumOfWidth =
      sumBy(columnsWithStaticWidth, (c) => c.width || 0) + checkboxColumnWidth;

    const calculatedWidth =
      Math.floor(((config.weight || 1) / sumOfWeight) * 10000) / 100;
    const marginPerColumn = sumOfWidth / columnsWithDynamicWidth.length;

    return `calc(${calculatedWidth}% - ${marginPerColumn}px)`;
  }

  private static processData(
    data: IRowData[],
    startId = 0,
    getDefaultRowSelected?: (data: IRowData) => boolean,
    getRowClassName?: (data: IRowData) => string
  ): IRow[] {
    const getDefaultRowState = (d: IRowData): IRowState => {
      const state: IRowState = {};

      if (getDefaultRowSelected) state.selected = getDefaultRowSelected(d);
      if (getRowClassName) state.className = getRowClassName(d);

      return state;
    };
    const rows: IRow[] = data.map<IRow>((d, i) => ({
      rowId: startId + i,
      data: d,
      state: getDefaultRowState(d)
    }));

    return rows;
  }
}
