// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import IAssetNameplate from "models/IAssetNameplate";
import LifecycleStatus from "models/LifecycleStatus";
import { useMemo } from "react";

const useNameplate = () => {
  const defaultNameplate = useMemo<IAssetNameplate>(() => {
    return {
      AssetType: "Transformer",
      ModelId: "ABB.TransformerStandard",
      ModelImplementationId: "AbbTransformerStandardModelV6",
      Attributes: {},
      Importance: null,
      Facility: null,
      Name: null,
      System: null,
      Unit: null,
      Organization: null,
      Region: null,
      AssetSubType: null,
      RatedPower: null,
      Description: null,
      Location: null,
      Manufacturer: null,
      ManufacturerModel: null,
      ManufacturedDate: null,
      Longitude: null,
      Latitude: null,
      Voltage: null,
      VoltageClass: null,
      InstallDate: null,
      EamEquipmentNumber: null,
      IsObsolete: false,
      CustomerExpectedLife: null,
      ManufacturerExpectedLife: null,
      ReplacementScoreModelId: null,
      ParentId: null,
      Status: null,
      LifecycleStatus: LifecycleStatus.InService,
      SerialNumber: null,
      ModelConfigurationName: null,
      ShowModelConfigurationTable: null,
      ReplacementScoreModelImplementationId: null,
      AssetSpatialType: null,
      Territory: null,
      NameplateReferenceDate: null,
      AssetModelGroupName: null,
      CompanyHierarchyCode: null,
      CompanyHierarchyName: null
    };
  }, []);

  return {
    defaultNameplate
  };
};

export default useNameplate;
