// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { EventEmitter } from "events";

const changeEvent = "change";

export interface IModal {
  open: boolean;
  processing: boolean;
  closeOnOverlayClick: boolean;
  messages: {
    [name: string]: IMessage[];
  };
}

export interface IMessage {
  id: number;
  message: string;
  buttons: IMessageButton[];
}

export interface IMessageButton {
  onClick: Function;
  label: string;
}

class ModalWindowStore extends EventEmitter {
  modals: { [name: string]: IModal };
  id: number;

  constructor() {
    super();
    this.modals = {};
    this.id = 0;
  }

  addChangeListener(callback: () => void) {
    this.on(changeEvent, callback);
  }

  removeChangeListener(callback: () => void) {
    this.removeListener(changeEvent, callback);
  }

  emitChange() {
    this.emit(changeEvent);
  }

  getModalState(modalId: string) {
    if (this.modals.hasOwnProperty(modalId)) {
      return this.modals[modalId];
    } else {
      return null;
    }
  }

  showProcessing(modalId: string) {
    if (this.modals.hasOwnProperty(modalId)) {
      this.modals[modalId].processing = true;
      this.modals[modalId].closeOnOverlayClick = false;

      this.emitChange();
    }
  }

  hideProcessing(modalId: string) {
    if (this.modals.hasOwnProperty(modalId)) {
      this.modals[modalId].processing = false;
      this.modals[modalId].closeOnOverlayClick = true;

      this.emitChange();
    }
  }

  initStore(modalId: string) {
    this.modals[modalId] = {
      open: false,
      processing: false,
      closeOnOverlayClick: false,
      messages: {}
    };
  }
  openModal(modalId: string) {
    if (this.modals.hasOwnProperty(modalId)) {
      this.modals[modalId].open = true;
      this.modals[modalId].processing = false;
      this.modals[modalId].closeOnOverlayClick = true;
      this.modals[modalId].messages = {};

      this.emitChange();
    }
  }
  closeModal(modalId: string) {
    if (this.modals.hasOwnProperty(modalId)) {
      this.modals[modalId].open = false;
      document.body.style.overflow = "auto";

      this.emitChange();
    }
  }
}

const modalWindowStore = new ModalWindowStore();

export default modalWindowStore;
