// Copyright 2016-2024 Hitachi Energy. All rights reserved.

/* eslint-disable jsx-a11y/anchor-is-valid */
import { Icon } from "@pg/common";
import useNavToAsset from "hooks/useNavToAsset";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { colorPrimary } from "styles/ColorVariables";
import {
  spacingBase,
  spacingLarge,
  spacingXLarge,
  spacingXSmall
} from "styles/StyleVariables";
import IssuesListAssetInfoIssues from "../components/IssuesListAssetInfoIssues";
import IAssetWithIssues from "../models/AssetWithIssues";
import Tooltip from "common/Tooltip";

interface IIssuesListAssetInfoProps {
  asset: IAssetWithIssues;
  className?: string;
}

const IssuesListAssetInfo = ({
  asset,
  className = ""
}: IIssuesListAssetInfoProps) => {
  const [issueListVisible, setIssueListVisible] = useState<boolean>(true);

  const { navigateTo } = useNavToAsset();

  const Text = (
    <>{asset.Name ? `${asset.AssetId} - ${asset.Name}` : asset.AssetId}</>
  );

  return (
    <div
      className={`${className} ${!issueListVisible && "list-item-collapsed"}`}
      onClick={() => {
        setIssueListVisible((isVisible) => !isVisible);
      }}
    >
      <div className="asset-info-wrapper">
        <div className="asset-info">
          <Tooltip title={<div>{Text}</div>}>
            <Link
              data-qa="link"
              className="asset-id link-label"
              to={navigateTo(asset.AssetId)}
            >
              {Text}
            </Link>
          </Tooltip>
          {!issueListVisible && (
            <FormattedMessage
              defaultMessage="{count} Issues"
              id="locations_page.side_panel.issues.issues_count"
              values={{
                count: asset.Issues.length
              }}
            />
          )}
        </div>

        <Icon name={issueListVisible ? "expand_less" : "expand_more"} />
      </div>
      {issueListVisible && <IssuesListAssetInfoIssues issues={asset.Issues} />}
    </div>
  );
};

export default styled(IssuesListAssetInfo)`
  display: flex;
  flex-direction: column;
  width: 100%;

  &.list-item-collapsed {
    cursor: pointer;
  }

  .asset-info-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    padding: ${spacingLarge} 0 ${spacingLarge} 0;

    .asset-info {
      display: flex;
      flex-direction: column;
      row-gap: ${spacingBase};
      width: 100%;
    }

    .asset-id {
      font-size: ${spacingXLarge};
      color: ${colorPrimary};
      padding-bottom: ${spacingXSmall};
    }

    .issue-count {
      font-size: ${spacingXLarge};
    }
  }
`;
