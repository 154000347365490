// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useState
} from "react";

import update from "immutability-helper";
import ChartLegendItem from "./ChartLegendItem";
import useLegend, { LegendItem } from "./hooks/useLegend";

import "./ChartLegend.less";

export interface IChartLegendProps {
  apiRef?: MutableRefObject<IChartLegendRef>;
  items: LegendItem[];
  itemClassName?: string;
  itemMessageIdPrefix: string;
  messageIdToPascalCase?: boolean;
  onFocus?: (itemId: string) => void;
  onRevert?: () => void;
  onToggle?: (itemId: string) => void;
}

export interface IChartLegendRef {
  reset: () => void;
}
interface IStatus {
  id: string;
  status: number;
}

const ChartLegend: React.FC<IChartLegendProps> = ({
  apiRef,
  items,
  itemClassName,
  itemMessageIdPrefix,
  messageIdToPascalCase,
  onFocus,
  onRevert,
  onToggle
}) => {
  const { focus, reset, revert, toggle } = useLegend(items || []);

  useEffect(() => {
    if (apiRef)
      apiRef.current = {
        reset: () => {
          reset();
        }
      };
  }, [apiRef, reset]);

  const [statuses, setStatuses] = useState<IStatus[]>([]);

  useEffect(() => {
    const statuses = items?.map((item) => ({ id: item.id, status: 0 }));
    setStatuses(statuses);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeStatus = useCallback(
    (id: string) => {
      const statusIndex = statuses.findIndex((obj) => obj.id === id);
      const newStatuses = update(statuses, {
        [statusIndex]: {
          status: { $set: statuses[statusIndex].status === 0 ? 2 : 0 }
        }
      });
      setStatuses(newStatuses);
    },
    [statuses]
  );

  return (
    <div className="chart-legend">
      {items?.map((item: LegendItem, i: number) => {
        return (
          <ChartLegendItem
            className={itemClassName}
            index={i}
            item={item}
            key={`${item.id}_${i}`}
            messageIdPrefix={itemMessageIdPrefix}
            messageIdToPascalCase={messageIdToPascalCase}
            onMouseOver={() => {
              focus(item.id);
              if (onFocus) onFocus(item.id);
            }}
            onMouseOut={() => {
              revert();
              if (onRevert) onRevert();
            }}
            onClick={() => {
              toggle(item.id);
              if (onToggle) onToggle(item.id);
              changeStatus(item.id);
            }}
            status={statuses[i]?.status}
          />
        );
      })}
    </div>
  );
};

export default ChartLegend;
