// Copyright 2016-2024 Hitachi Energy. All rights reserved.

const archingCompartmentCondition = [
  "new",
  "normal",
  "unusable",
  "bypassed",
  "questionable"
];

export default archingCompartmentCondition;
