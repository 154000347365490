// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Typography } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";

import "components/common/SectionName.less";

const { Title } = Typography;

interface ISectionNameProps {
  messageId?: string;
  messageDefault?: string;
  messageValues?: any;
  message?: string;
}

class SectionName extends React.Component<ISectionNameProps, {}> {
  public render() {
    return (
      <Title level={3} className="section-name">
        {this.props.messageId ? (
          <FormattedMessage
            id={this.props.messageId}
            defaultMessage={this.props.messageDefault}
            values={this.props.messageValues}
          />
        ) : (
          <span>{this.props.message}</span>
        )}
      </Title>
    );
  }
}

export default SectionName;
