// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Children, FC, ReactNode } from "react";
import styled from "styled-components";
import { spacingLarge } from "styles/StyleVariables";

type Size = "md" | "lg";

interface IContainerProps {
  className?: string;
  children?: ReactNode;
  size?: Size;
}

const Container: FC<IContainerProps> = ({
  className,
  children,
  size = "md"
}) => {
  return (
    <div className={`${className} ${size}`}>
      <div className="container-content">{Children.toArray(children)}</div>
    </div>
  );
};

const StyledContainer = styled(Container)`
  & {
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    &.md {
      @media (min-width: 1600px) {
        max-width: 1600px;
      }
    }

    &.lg {
      @media (min-width: 1760px) {
        max-width: 1760px;
      }
    }

    .container-content {
      padding: ${spacingLarge};
    }
  }

  & + & {
    padding-top: 0;
  }
`;

export default StyledContainer;
