// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { IThreshold } from "@apm/widgets/build/widgets/ParameterCharts";
import {
  colorBlue60,
  colorStatusGreen,
  colorStatusOrange,
  colorStatusRed
} from "@pg/common/build/styles/ColorVariables";
import { IntlShape } from "react-intl";
import IPowerFactorThresholds from "../models/IPowerFactorThresholds";
import IThresholds from "../models/IThresholds";
import { PowerFactorType } from "../models/PowerFactorTypeEnums";
import getSotType from "./getSotType";

const getColor = (name: string) => {
  switch (name) {
    case "Alarm":
    case "Poor":
      return colorStatusRed;
    case "Alert":
    case "Fair":
    case "PowerFactor25C":
      return colorStatusOrange;
    case "Good":
      return colorStatusGreen;
    case "PowerFactor90C":
    case "PowerFactor100C":
      return colorBlue60;
    case "Min":
    case "Max":
      return colorBlue60;
    default:
      return undefined;
  }
};

const getSections = (thresholds: IThresholds, name: string): IThreshold => {
  return thresholds[name]?.map((t) => ({
    start: t.Start,
    end: t.End,
    value: t.Value
  }));
};

const getPowerFactorSections = (
  thresholds: IThreshold[] | IPowerFactorThresholds[],
  name: string
): IThreshold => {
  const powerFactorSection = thresholds.filter((t) =>
    Object.keys(t).toString() === name ? t : null
  );

  switch (name) {
    case PowerFactorType.PF25:
      const pf25C = powerFactorSection[0].PowerFactor25C;
      return pf25C.map((t: IThreshold) => ({
        start: t.Start,
        end: t.End,
        value: t.Value
      }));
    case PowerFactorType.PF100:
      const pf100C = powerFactorSection[0].PowerFactor100C;
      return pf100C.map((t: IThreshold) => ({
        start: t.Start,
        end: t.End,
        value: t.Value
      }));
    case PowerFactorType.PF90:
      const pf90C = powerFactorSection[0].PowerFactor90C;
      return pf90C.map((t: IThreshold) => ({
        start: t.Start,
        end: t.End,
        value: t.Value
      }));
  }
};

const getDisplayName = (
  name: string,
  sotType: string,
  thresholdNamePrefix: string,
  intl: IntlShape
) =>
  intl.formatMessage({
    defaultMessage: name,
    id: `detail_page.widgets.analytics.transformers.StandardOilTests.${getSotType(
      sotType
    )}.${thresholdNamePrefix}_${name.toLowerCase()}`
  });

const mapToThresholds = (
  thresholds: IThresholds | IPowerFactorThresholds[],
  intl: IntlShape,
  thresholdNamePrefix: string,
  sotType: string
) => {
  if (Array.isArray(thresholds) && thresholds.length > 1) {
    const thresholdList = (thresholds as IThresholds[]).map((t) => {
      const tName = Object.keys(t).toString();
      return {
        name: `${thresholdNamePrefix}_${tName}`,
        displayName: getDisplayName(tName, sotType, thresholdNamePrefix, intl),
        seriesName: `${tName}_offline`,
        color: getColor(tName),
        isAreaColored: false,
        sections: getPowerFactorSections(
          thresholds as IPowerFactorThresholds[],
          tName
        )
      };
    });

    return thresholdList?.sort(
      (a, b) => a.sections?.[0]?.value - b.sections?.[0]?.value
    );
  }

  const thresholdList: IThreshold[] = Object.keys(thresholds)?.map<IThreshold>(
    (name, i) => {
      return {
        name: `${thresholdNamePrefix}_${name}`,
        displayName: getDisplayName(name, sotType, thresholdNamePrefix, intl),
        color: getColor(name),
        isAreaColored: false,
        sections: getSections(thresholds as IThresholds, name)
      };
    }
  );

  return thresholdList?.sort(
    (a, b) => a.sections?.[0]?.value - b.sections?.[0]?.value
  );
};

export default mapToThresholds;
