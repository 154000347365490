// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { IState } from "reducers/Index";

const getCustomerVisualizationsCommandIntegrationSelector = createSelector(
  (state: IState) => state.app.customerVisualizationsCommandIntegration,
  (customer) => customer
);

export default getCustomerVisualizationsCommandIntegrationSelector;
