// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import FamilyInfo, {
  IFamilyInfoData,
  IFamilyInfoOwnProps
} from "features/detailpage/features/familyinfo/components/FamilyInfo";
import { getFamilyInfoSelector } from "features/detailpage/features/familyinfo/reducers/FamilyInfoReducer";
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import { AppDispatch } from "store";

export function mapStateToProps(state: IState): IFamilyInfoData {
  return {
    familyInfo: getFamilyInfoSelector(state)
  };
}

export function mapDispatchToProps(
  dispatch: AppDispatch,
  ownProps: IFamilyInfoOwnProps
): {} {
  return {};
}

const FamilyInfoContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FamilyInfo);

export default FamilyInfoContainer;
