// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { IColumnConfig, IRow, IRowData } from "common/datagrid/DataGrid";
import SortOrders from "common/datagrid/models/SortOrders";
import { AssetRiskEnum } from "models/AssetRisks";
import { useCallback, useMemo } from "react";
import AssetWithLocationColumn from "../components/AssetWithLocationColumn";
import ComponentsCountColumn from "../components/ComponentsCountColumn";
import RiskColumnHeader from "../components/RiskColumnHeader";

interface IUseComponentSummaryColumnsConfigOptions {
  handleRiskClick: (row: IRow<IRowData>, risk: AssetRiskEnum) => void;
}
const useComponentSummaryColumnsConfig = ({
  handleRiskClick
}: IUseComponentSummaryColumnsConfigOptions) => {
  const columns: IColumnConfig[] = useMemo(
    () => [
      {
        component: (_, row) => (
          <AssetWithLocationColumn
            assetId={row.data["AssetId"]}
            location={row.data["AssetLocation"]}
            type={row.data["AssetType"]}
            name={row.data["Name"]}
          />
        ),
        id: "AssetId",
        message: {
          defaultMessage: "Asset",
          id: "component_risk_summary_page.grid.columns.asset"
        },
        weight: 3
      },
      {
        id: "Description",
        message: {
          defaultMessage: "Description",
          id: "component_risk_summary_page.grid.columns.description"
        },
        weight: 4
      },
      {
        component: (_, row) => (
          <ComponentsCountColumn
            row={row}
            count={row.data["ComponentsCount"]}
            onRiskClick={handleRiskClick}
          />
        ),
        id: "ComponentsCount",
        message: {
          defaultMessage: "Number of Components",
          id: "component_risk_summary_page.grid.columns.number_of_components"
        },
        weight: 3
      },
      {
        headerComponent: () => (
          <RiskColumnHeader
            message={{
              defaultMessage: "High",
              id: "component_risk_summary_page.grid.columns.risk_high"
            }}
            risk="high"
          />
        ),
        message: {
          defaultMessage: "High",
          id: "component_risk_summary_page.grid.columns.risk_high"
        },
        component: (_, row) => (
          <ComponentsCountColumn
            row={row}
            risk={AssetRiskEnum.HIGH}
            count={row.data["HighRiskCount"]}
            onRiskClick={handleRiskClick}
          />
        ),
        id: "HighRiskCount",
        weight: 2,
        defaultSortOrder: SortOrders.Desc
      },
      {
        headerComponent: () => (
          <RiskColumnHeader
            message={{
              defaultMessage: "Medium",
              id: "component_risk_summary_page.grid.columns.risk_medium"
            }}
            risk="medium"
          />
        ),
        message: {
          defaultMessage: "Medium",
          id: "component_risk_summary_page.grid.columns.risk_medium"
        },
        component: (_, row) => (
          <ComponentsCountColumn
            row={row}
            risk={AssetRiskEnum.MEDIUM}
            count={row.data["MediumRiskCount"]}
            onRiskClick={handleRiskClick}
          />
        ),
        id: "MediumRiskCount",
        weight: 2
      },
      {
        headerComponent: () => (
          <RiskColumnHeader
            message={{
              defaultMessage: "Low",
              id: "component_risk_summary_page.grid.columns.risk_low"
            }}
            risk="low"
          />
        ),
        message: {
          defaultMessage: "Low",
          id: "component_risk_summary_page.grid.columns.risk_low"
        },
        component: (_, row) => (
          <ComponentsCountColumn
            row={row}
            risk={AssetRiskEnum.LOW}
            count={row.data["LowRiskCount"]}
            onRiskClick={handleRiskClick}
          />
        ),
        id: "LowRiskCount",
        weight: 2
      },
      {
        headerComponent: () => (
          <RiskColumnHeader
            message={{
              defaultMessage: "Unknown",
              id: "component_risk_summary_page.grid.columns.risk_unknown"
            }}
            risk="unknown"
          />
        ),
        message: {
          defaultMessage: "Unknown",
          id: "component_risk_summary_page.grid.columns.risk_unknown"
        },
        component: (_, row) => (
          <ComponentsCountColumn
            row={row}
            risk={AssetRiskEnum.UNKNOWN}
            count={row.data["UnknownRiskCount"]}
            onRiskClick={handleRiskClick}
          />
        ),
        id: "UnknownRiskCount",
        weight: 2
      }
    ],
    [handleRiskClick]
  );

  const getColumnsConfig = useCallback(() => {
    enum ConfigItemNames {
      AssetId = "AssetId",
      ComponentsCount = "ComponentsCount"
    }

    const getIndex = (columnId: string) =>
      columns.findIndex((column) => column?.id === columnId);

    const newGridColumnsConfig = [...columns];
    const unusedTdSpace = 0.9;

    newGridColumnsConfig[getIndex(ConfigItemNames.AssetId)] = {
      ...newGridColumnsConfig[getIndex(ConfigItemNames.AssetId)],
      weight:
        newGridColumnsConfig[getIndex(ConfigItemNames.AssetId)].weight +
        unusedTdSpace
    };

    newGridColumnsConfig[getIndex(ConfigItemNames.ComponentsCount)] = {
      ...newGridColumnsConfig[getIndex(ConfigItemNames.ComponentsCount)],
      weight:
        newGridColumnsConfig[getIndex(ConfigItemNames.ComponentsCount)].weight -
        unusedTdSpace
    };

    return newGridColumnsConfig;
  }, [columns]);

  return getColumnsConfig;
};

export default useComponentSummaryColumnsConfig;
