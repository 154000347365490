// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { IRegion } from "@apm/widgets/build/widgets/ParameterCharts";
import {
  colorStatusOrange,
  colorStatusYellow
} from "@pg/common/build/styles/ColorVariables";
import { IntlShape } from "react-intl";
import ChartGroup from "../models/ChartGroup";
import IThresholds from "../models/IThresholds";
import { ThresholdTypeEnum } from "../models/ThresholdType";

const mapToRegions = (
  intl: IntlShape,
  thresholds: IThresholds,
  chartGroup: ChartGroup
): IRegion[] => {
  if (thresholds) {
    const getRegion = (
      thresholdType: ThresholdTypeEnum,
      color: string
    ): IRegion => {
      const threshold =
        thresholds[thresholdType].length > 0
          ? thresholds[thresholdType][0]
          : null;
      return {
        name: thresholdType,
        displayName: intl.formatMessage({
          defaultMessage: thresholdType,
          id: `detail_page.widgets.analytics.transformers.voltage_stress.legend.${thresholdType}`
        }),
        color: color,
        start: threshold?.Value,
        end: null
      };
    };

    const regions: IRegion[] = [];

    if (chartGroup === "Percentages") {
      regions.push(getRegion(ThresholdTypeEnum.Warning, colorStatusYellow));
      regions.push(getRegion(ThresholdTypeEnum.Alert, colorStatusOrange));
    } else {
      regions.push(getRegion(ThresholdTypeEnum.High, colorStatusYellow));
      regions.push(getRegion(ThresholdTypeEnum.VeryHigh, colorStatusOrange));
    }
    return regions;
  }
};

export default mapToRegions;
