// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { ReactNode } from "react";
import { FormattedNumber } from "react-intl";

export interface IValueColumnProps {
  children?: ReactNode;
  value: number;
}

const ConfidenceColumn = ({ value }: IValueColumnProps) => (
  <div className="grid-cell confidence">
    {value && (
      <FormattedNumber
        value={value / 100}
        maximumFractionDigits={3}
        // eslint-disable-next-line react/style-prop-object
        style="percent"
      />
    )}
  </div>
);

export default ConfidenceColumn;
