// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import CenteredWrapper from "common/CenteredWrapper";
import Processing from "components/common/Processing";
import {
  WidgetErrorMessage,
  WidgetNoDataMessage
} from "components/common/widget/Widget";
import Data, { Statuses } from "core/data/models/Data";
import { IHistorySwitchingOperationsData } from "features/detailpage/features/ltc/models/IHistorySwitichingOperations";
import { isEmpty } from "lodash";
import HistorySwitchingComponent from "./HistorySwitchingContent";

interface IHistorySwitchingStateProps {
  historySwitching: Data<IHistorySwitchingOperationsData>;
}

interface IHistorySwitchingProps extends IHistorySwitchingStateProps {}

export default ({ historySwitching }: IHistorySwitchingProps) => {
  switch (historySwitching.status) {
    case Statuses.Loading:
      return (
        <CenteredWrapper>
          <Processing className="spinner small dark" />
        </CenteredWrapper>
      );
    case Statuses.Succeeded:
      return isEmpty(historySwitching.data) ? (
        <CenteredWrapper>
          <WidgetNoDataMessage />
        </CenteredWrapper>
      ) : (
        <HistorySwitchingComponent historySwitching={historySwitching.data} />
      );
    case Statuses.NotFound:
      return (
        <CenteredWrapper>
          <WidgetNoDataMessage />
        </CenteredWrapper>
      );
    case Statuses.Failed:
      return (
        <CenteredWrapper>
          <WidgetErrorMessage />
        </CenteredWrapper>
      );
    default:
      return (
        <div className="tile">
          <div className="tile-content empty" />
        </div>
      );
  }
};
