// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { IAjaxAction } from "reducers/Index";
import { AppDispatch } from "store";
import { config } from "utils/AppConfig";
import IAgingVisualization from "../models/IAgingVisualization";

export enum Actions {
  DetailPage_ReplacementScore_SetAgingVisualizationAction = "DetailPage_ReplacementScore_SetAgingVisualizationAction"
}

const setAgingVisualizationAction = (
  request: JQuery.jqXHR,
  data?: IAgingVisualization
): IAjaxAction<IAgingVisualization> => ({
  type: Actions.DetailPage_ReplacementScore_SetAgingVisualizationAction,
  request,
  data
});

export const loadAgingVisualization = (
  dispatch: AppDispatch,
  assetId: string
) => {
  const url = UrlService.getApiUrl(
    config.api.detailPage.agingVisualizationUrl,
    [
      {
        name: "assetId",
        value: assetId
      }
    ]
  );

  const request = EndpointService.getJson<IAgingVisualization>(
    url,
    (request, data) => {
      dispatch(setAgingVisualizationAction(request, data));
    },
    (request) => {
      dispatch(setAgingVisualizationAction(request));
    }
  );

  dispatch(setAgingVisualizationAction(request));
};
