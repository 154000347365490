// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { config } from "utils/AppConfig";

const getAssetWatchState = (assetId: string): Promise<boolean> => {
  const url = UrlService.getApiUrl(
    config.api.assetWatchList.assetWatchStateUrl,
    {
      assetId
    }
  );

  return new Promise<boolean>(function (resolve, reject) {
    EndpointService.get(
      url,
      (request, data: boolean) => {
        resolve(data);
      },
      (request) => {
        reject(request);
      },
      "application/json; charset=utf-8"
    );
  });
};

export default getAssetWatchState;
