// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import WidgetStore, { IWidget } from "store/common/WidgetStore";
import "./Widget.less";

enum WidgetSizes {
  Col12,
  Col9,
  Col6,
  Col3
}

export interface IWidgetProps {
  size?: WidgetSizes;
  titleId: string;
  titleDefault: string;
  hideHeader?: boolean;
  widgetId: string;
  initExpanded?: boolean;
  className?: string;
}

export interface IContentWidgetProps extends IWidgetProps {
  element?: JSX.Element;
  elementExpanded?: JSX.Element;
}

export interface IWidgetState {
  widget: IWidget;
}

/**
 * @deprecated Use WidgetCart component from @apm/widget package
 */
class Widget extends React.Component<IContentWidgetProps, IWidgetState> {
  static defaultProps: IWidgetProps = {
    size: WidgetSizes.Col6,
    initExpanded: false,
    titleId: null,
    titleDefault: null,
    widgetId: null
  };

  constructor(props: IContentWidgetProps) {
    super(props);

    this.state = {
      widget: {
        id: null,
        expanded: false
      }
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleExpandClick = this.handleExpandClick.bind(this);
  }

  componentDidMount() {
    WidgetStore.initStore(this.props.widgetId);

    const widget = WidgetStore.getWidget(this.props.widgetId);
    widget.expanded = this.props.initExpanded;

    WidgetStore.addChangeListener(this.handleChange);

    this.setState((prevState, props) => {
      return Object.assign({}, prevState, {
        widget: widget
      });
    });
  }

  componentWillUnmount() {
    WidgetStore.removeChangeListener(this.handleChange);
  }

  render() {
    let sizeClassName = "";
    if (this.props.size === WidgetSizes.Col12) {
      sizeClassName = "col12vw";
    } else if (this.props.size === WidgetSizes.Col9) {
      sizeClassName = "col9vw";
    } else if (this.props.size === WidgetSizes.Col6) {
      sizeClassName = "col6vw";
    } else if (this.props.size === WidgetSizes.Col3) {
      sizeClassName = "col3vw";
    }

    const className = ["widget", sizeClassName, this.props.className].join(" ");

    return (
      <div className={className}>
        <div
          className="widget-content"
          data-qa={`widget-${this.props.titleId}`}
        >
          {!this.props.hideHeader && (
            <div className="widget-header">
              <div className="title" data-qa="title">
                <FormattedMessage
                  id={this.props.titleId}
                  defaultMessage={this.props.titleDefault}
                />
              </div>
              {this.props.elementExpanded ? (
                <div
                  className="expand"
                  data-qa="expand"
                  onClick={this.handleExpandClick}
                >
                  <Icon name="add" />
                </div>
              ) : null}
            </div>
          )}
          <div className="widget-body" data-qa="widget">
            {this.state.widget.expanded
              ? this.props.elementExpanded
              : this.props.element}
          </div>
        </div>
      </div>
    );
  }

  private handleChange() {
    this.setState((prevState, props) => {
      return Object.assign({}, prevState, {
        widget: WidgetStore.getWidget(props.widgetId)
      });
    });
  }

  private handleExpandClick(e: React.MouseEvent<HTMLDivElement>) {
    WidgetStore.toggleExpand(this.props.widgetId);
  }
}

interface IWidgetMessageProps {
  messageId?: string;
  messageDefault?: string;
  messageValues?: any;
  iconName?: string;
  iconClassName?: string;
}

class WidgetMessage<P extends IWidgetMessageProps> extends React.Component<
  P,
  {}
> {
  render() {
    return (
      <div className="widget-message">
        <Icon className={this.props.iconClassName} name={this.props.iconName} />
        <FormattedMessage
          id={this.props.messageId}
          defaultMessage={this.props.messageDefault}
          values={this.props.messageValues}
        />
      </div>
    );
  }
}

class WidgetEmpty extends React.Component<{}, {}> {
  render() {
    return <div className="widget-message" />;
  }
}

class WidgetNoDataMessage extends WidgetMessage<IWidgetMessageProps> {
  static defaultProps: IWidgetMessageProps = {
    iconName: "info",
    iconClassName: "no-data",
    messageId: "widget.messages.no_data",
    messageDefault: "No data available"
  };
}

class WidgetErrorMessage extends WidgetMessage<IWidgetMessageProps> {
  static defaultProps: IWidgetMessageProps = {
    iconName: "error",
    iconClassName: "error",
    messageId: "widget.messages.error",
    messageDefault: "Internal error"
  };
}

export default Widget;
export {
  WidgetEmpty,
  WidgetNoDataMessage,
  WidgetErrorMessage,
  WidgetMessage,
  WidgetSizes
};
