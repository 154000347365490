// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import * as d3 from "d3";

import Svg from "../models/Svg";

class SvgService {
  static createSvg = (chartId: string, width: number, height: number) =>
    d3
      .select(`#${chartId}`)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

  static getSvg = (chartId: string) => {
    return d3.select(`#${chartId} svg`) as Svg;
  };

  static removeSvg = (chartId: string) => {
    const svg = d3.select(`#${chartId} svg`);
    if (svg) svg.remove();
  };
}

export default SvgService;
