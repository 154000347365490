// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Col, Row } from "antd";
import { LegendItem } from "common/ChartLegend";
import useLegend from "common/ChartLegend/hooks/useLegend";
import ILTCOperationsChartLegendItem from "features/detailpage/features/ltc/models/ILTCOperationsChartLegendItem";
import LTCOperationsBarChartLegendItem from "./LTCOperationsBarChartLegendItem";

interface ILTCOperationsBarChartLegendProps {
  plots: ILTCOperationsChartLegendItem[];
  series: string;
  className?: string;
}

const LTCOperationsBarChartLegend = ({
  plots,
  series,
  className
}: ILTCOperationsBarChartLegendProps) => {
  const items = plots.map<LegendItem>((p) => ({
    id: p.id,
    name: p.name,
    color: p.color,
    defaultSelected: !p.isHidden
  }));

  const { statuses } = useLegend(items);

  return (
    <Row className={`chart-legend ${className ?? ""}`}>
      {plots.map((l: ILTCOperationsChartLegendItem, index: number) => (
        <Col xs={index === 0 ? 10 : 7} md={index === 0 ? 14 : 5} key={l.id}>
          <LTCOperationsBarChartLegendItem
            item={l}
            status={statuses[l.id]}
            key={`${series}_${l.id}_${index}`}
          />
        </Col>
      ))}
    </Row>
  );
};

export default LTCOperationsBarChartLegend;
