// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { IState } from "reducers/Index";

const getCustomerVisualizationsComponentGridSelector = createSelector(
  (state: IState) => state.app.customerVisualizationsComponentGrid,
  (customer) => customer
);

export default getCustomerVisualizationsComponentGridSelector;
