// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import {
  IChart,
  IChartSeries
} from "@apm/widgets/build/widgets/ParameterCharts";
import { isEmpty } from "lodash";
import { ReactNode } from "react";
import { IntlShape } from "react-intl";
import chartSettingsIEC from "../constants/chartSettingsIEC";
import chartSettingsIEEE from "../constants/chartSettingsIEEE";
import IChartData from "../models/IChartData";
import { OilTest } from "../models/IOilSettings";
import IPowerFactorSeries from "../models/IPowerFactorSeries";
import IPowerFactorThresholds from "../models/IPowerFactorThresholds";
import { PowerFactorType } from "../models/PowerFactorTypeEnums";
import SotTypes from "../models/SotTypes";
import isKntIec from "./isKntIec";
import mapToThresholds from "./mapToThresholds";

const mapToChart = (
  chartData: IChartData | undefined,
  name: OilTest,
  displayName: ReactNode,
  sotType: SotTypes,
  intl: IntlShape,
  powerFactorSeries?: IPowerFactorSeries[],
  powerFactorThresholds?: IPowerFactorThresholds[]
): IChart => {
  let chart: IChart;
  const areThresholdsEmpty =
    chartData?.Thresholds && isEmpty(Object.values(chartData?.Thresholds)[0]);

  if (name === PowerFactorType.PF) {
    chart = {
      name,
      displayName,
      adjustYSizeToPositiveValues: false,
      adjustYSizeToThresholds: true
    };
  } else {
    chart = {
      name,
      displayName,
      adjustYSizeToPositiveValues: areThresholdsEmpty ? true : false,
      adjustYSizeToThresholds: true
    };
  }

  const createPowerFactorSeries = (powerFactorSeries: IPowerFactorSeries[]) => {
    const series: IChartSeries[] = [];
    powerFactorSeries.forEach((s) => {
      const seriesName = Object.keys(s);
      const seriesValues = Object.values(s);
      series.push({
        name: `${seriesName}_offline`,
        unit: (isKntIec(sotType) ? chartSettingsIEC : chartSettingsIEEE)[
          seriesName[0] as OilTest
        ].unit,
        values: seriesValues[0]
      });
    });

    return series;
  };

  const createOfflineSeries = (chartData: IChartData) => {
    const series: IChartSeries[] = [];
    chartData.OfflineTrends &&
      series.push({
        name: `${name}_offline`,
        unit: (isKntIec(sotType) ? chartSettingsIEC : chartSettingsIEEE)[name]
          .unit,
        values: chartData.OfflineTrends
      });

    return series;
  };

  const createOnlineSeries = (chartData: IChartData) => {
    const series: IChartSeries[] = [];
    chartData.OnlineTrends &&
      series.push({
        name: `${name}_online`,
        unit: (isKntIec(sotType) ? chartSettingsIEC : chartSettingsIEEE)[name]
          .unit,
        values: chartData.OnlineTrends
      });

    return series;
  };

  if (!chartData) return chart;

  if (name === PowerFactorType.PF && powerFactorSeries?.length > 1) {
    const areSeriesEmpty = createPowerFactorSeries(powerFactorSeries).every(
      (s) => isEmpty(s.values)
    );

    const pfSeries = createPowerFactorSeries(powerFactorSeries);
    const pfThresholds = mapToThresholds(
      powerFactorThresholds,
      intl,
      name,
      sotType
    );

    const sortedThresholds = pfThresholds?.sort((a, b) => {
      return a.sections?.[0]?.value - b.sections?.[0]?.value;
    });

    const areSeriesInRightOrder = pfSeries[0].name.includes(
      sortedThresholds[0].name
    );

    chart.values = {
      offline: {
        series: !areSeriesInRightOrder ? pfSeries.reverse() : pfSeries,
        thresholds: !areSeriesEmpty ? sortedThresholds : null
      }
    };
  } else {
    chart.values = {
      offline: {
        series: createOfflineSeries(chartData),
        thresholds:
          chartData.Thresholds && !isEmpty(chartData.OfflineTrends)
            ? mapToThresholds(chartData.Thresholds, intl, name, sotType)
            : null
      },
      online: {
        series: createOnlineSeries(chartData),
        thresholds:
          chartData.Thresholds && !isEmpty(chartData.OnlineTrends)
            ? mapToThresholds(chartData.Thresholds, intl, name, sotType)
            : null
      }
    };
  }

  return chart;
};

export default mapToChart;
