// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import IWorkOrder from "features/detailpage/models/IWorkOrder";
import { IState } from "features/detailpage/features/actions/reducers/ActionsReducer";
import Data from "core/data/models/Data";

export default class ActionsService {
  static setWorkOrders(
    state: IState,
    request: JQuery.jqXHR,
    workOrders: IWorkOrder[]
  ): IState {
    return {
      ...state,
      actions: {
        ...state.actions,
        workOrders: new Data(request, workOrders)
      }
    };
  }
}
