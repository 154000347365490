// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import styled from "styled-components";
import { spacingXXLarge, spacingXXXLarge } from "styles/StyleVariables";
import ICompanyHierarchyNode from "../../../../models/ICompanyHierarchyNode";
import CompanyHierarchyTree from "./CompanyHierarchyTree";

interface ICompanyHierarchyTooltipProps {
  companyHierarchyNodes: ICompanyHierarchyNode[];
  assetHierarchyCode: string;
  className?: string;
}

const CompanyHierarchyTooltip = ({
  companyHierarchyNodes,
  assetHierarchyCode,
  className
}: ICompanyHierarchyTooltipProps) => {
  return (
    <div className={`${className} hierarchy-tooltip`}>
      <CompanyHierarchyTree
        level={0}
        path={companyHierarchyNodes.map((n) => n)}
        selectedHierarchyCode={assetHierarchyCode}
      />
    </div>
  );
};

const StyledCompanyHierarchyTooltip = styled(CompanyHierarchyTooltip)`
  div.tree-level-1 span.ant-typography {
    padding-left: ${spacingXXLarge};
  }

  div.tree-level-2 span.ant-typography {
    padding-left: ${spacingXXXLarge};
  }
`;

export default StyledCompanyHierarchyTooltip;
