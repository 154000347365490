// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Typography } from "antd";
import * as React from "react";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { fontSizeSmall, spacingSmall } from "styles/StyleVariables";

const { Text } = Typography;

interface ILocationProps {
  className?: string;
  location: string | undefined;
  region: string | undefined;
  organization: string | undefined;
}

const Location = ({
  location,
  region,
  organization,
  className
}: ILocationProps) => {
  const showLongMessage = useMemo(
    () => organization !== undefined && region !== undefined,
    [organization, region]
  );
  const showShortMessage = useMemo(() => !showLongMessage, [showLongMessage]);

  return (
    <div className={className}>
      <Text strong ellipsis>
        {location}
      </Text>
      <Text ellipsis className="details">
        {showLongMessage && (
          <FormattedMessage
            defaultMessage="{organization} / {region}"
            id="locations_page.grid.columns.organization_region"
            values={{
              organization: organization,
              region: region
            }}
          />
        )}
        {showShortMessage && (
          <FormattedMessage
            defaultMessage="{value}"
            id="locations_page.grid.columns.organization_region_short"
            values={{
              value: organization || region
            }}
          />
        )}
      </Text>
    </div>
  );
};

const defaultGridPadding = "10px";

export default styled(Location)`
  padding: ${defaultGridPadding};
  display: flex;
  flex-direction: column;
  font-size: ${fontSizeSmall};

  .details {
    margin-top: ${spacingSmall};
  }
`;
