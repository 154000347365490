// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { IDataEndpoint } from "common/datagrid/DataGrid";
import { SelectedFilters } from "common/FilterBar";
import UrlService from "core/data/services/UrlService";
import { useMemo, useState } from "react";
import { config } from "utils/AppConfig";

interface IUseGridOptions {
  filters: SelectedFilters;
}

const useGridData = ({ filters }: IUseGridOptions) => {
  const [rowsTotal, setRowsTotal] = useState<number>();

  const dataEndpoint: IDataEndpoint = useMemo(
    () => ({
      url: UrlService.getApiUrl(config.api.components.componentsRangeUrl),
      type: "POST",
      content: {
        search: filters.search,
        filters: filters.selects
      },
      onDataLoaded: setRowsTotal
    }),
    [filters]
  );

  return {
    dataEndpoint,
    rowsTotal
  };
};

export default useGridData;
