// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import { Button } from "antd";
import { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import useAppNavigate from "../hooks/useAppNavigate";
import "./RouteNotFound.less";

const RouteNotFound = () => {
  const navigate = useAppNavigate();

  const handleClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <div className="route-not-found">
      <div className="content">
        <div className="warning-icon">
          <Icon name="warning" />
        </div>
        <div className="container">
          <div className="title">
            <FormattedMessage
              id="route_not_found.title"
              defaultMessage="Oh no"
            />
          </div>
          <div className="not-found-container">
            <div>
              <FormattedMessage
                id="route_not_found.message_content"
                defaultMessage="The page you were looking for {doesntExists} or {cannotBeAccessed}."
                values={{
                  doesntExists: (
                    <strong>
                      <FormattedMessage
                        defaultMessage="does not exist"
                        id="route_not_found.message.doesnt_exists"
                      />
                    </strong>
                  ),
                  cannotBeAccessed: (
                    <strong>
                      <FormattedMessage
                        defaultMessage="cannot be accessed"
                        id="route_not_found.message.cannot_be_accessed"
                      />
                    </strong>
                  )
                }}
              />
            </div>
            <div>
              <FormattedMessage
                id="route_not_found.message_tip"
                defaultMessage="If you think this is an error, contact your local administrator."
              />
            </div>
          </div>
          <Button
            type="primary"
            className="homepage-button"
            size="large"
            onClick={handleClick}
          >
            <Icon name="home" size="sm" />
            <FormattedMessage
              id="route_not_found.return_to_home_page"
              defaultMessage="Back to Homepage"
            />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RouteNotFound;
