// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import SearchParams from "@pg/common/build/models/SearchParams";

export interface IUrlParam {
  name: string;
  value: string;
}

export interface IUrlParams {
  [name: string]: string | string[];
}

export const apiPrefix = "/api";

export default class UrlService {
  public static getApiUrl(
    url: string,
    params?: IUrlParam[] | IUrlParams,
    search?: IUrlParam[] | IUrlParams
  ): string {
    const urlWithParams = UrlService.getUrl(url, params, search);
    return `${apiPrefix}/${urlWithParams}`;
  }

  public static getUrl(
    url: string,
    params?: IUrlParam[] | IUrlParams,
    search?: IUrlParam[] | IUrlParams
  ): string {
    if (params) {
      params = Array.isArray(params) ? params : UrlService.mapToArray(params);
      params.forEach((param: IUrlParam) => {
        url = url.replace(`{${param.name}}`, encodeURIComponent(param.value));
      });
    }

    if (search) {
      search = Array.isArray(search) ? search : UrlService.mapToArray(search);
      const searchParams = new SearchParams();
      search.forEach((s) => {
        searchParams.append(s.name, s.value);
      });
      const separator = url.indexOf("?") >= 0 ? "&" : "?";
      url = `${url}${separator}${searchParams.toString()}`;
    }

    if (/\{.*\}/.test(url)) {
      const params = /\{.*\}/.exec(url);
      throw new Error(`Missing parameter value. Parameters: ${params}`);
    }

    return url;
  }

  private static mapToArray(params: IUrlParams): IUrlParam[] {
    if (params) {
      const keys = Object.keys(params);
      const array: IUrlParam[] = [];
      keys.forEach((k) => {
        const value = params[k];
        const isString = typeof value === "string";
        if (isString) {
          array.push({
            name: k,
            value: value as string
          });
        } else {
          for (const v of value) {
            array.push({
              name: k,
              value: v
            });
          }
        }
      });

      return array;
    }

    return [];
  }
}
