// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { MessageDescriptor } from "react-intl";
import ImportedParameterKey from "../models/connectedDevices/ImportedParameterKey";
interface IFieldTranslationConfig {
  fieldKey: ImportedParameterKey;
  fieldName: MessageDescriptor;
}

const fieldsTranslationConfig: IFieldTranslationConfig[] = [
  {
    fieldKey: ImportedParameterKey.DisplayName,
    fieldName: {
      defaultMessage: "Display Name",
      id: "configuration_tool.tab.connected_devices_importing_parameter_display_name"
    }
  },
  {
    fieldKey: ImportedParameterKey.TagName,
    fieldName: {
      defaultMessage: "Tag Name",
      id: "configuration_tool.tab.connected_devices_importing_parameter_tag_name"
    }
  },
  {
    fieldKey: ImportedParameterKey.DataType,
    fieldName: {
      defaultMessage: "Data Type",
      id: "configuration_tool.tab.connected_devices_importing_parameter_data_type"
    }
  },
  {
    fieldKey: ImportedParameterKey.PartitionModbus,
    fieldName: {
      defaultMessage: "Partition Modbus",
      id: "configuration_tool.tab.connected_devices_importing_parameter_partition_modbus"
    }
  },
  {
    fieldKey: ImportedParameterKey.IdModbus,
    fieldName: {
      defaultMessage: "ID Modbus",
      id: "configuration_tool.tab.connected_devices_importing_parameter_id_modbus"
    }
  },
  {
    fieldKey: ImportedParameterKey.Scale,
    fieldName: {
      defaultMessage: "Scale",
      id: "configuration_tool.tab.connected_devices_importing_parameter_scale"
    }
  },
  {
    fieldKey: ImportedParameterKey.Offset,
    fieldName: {
      defaultMessage: "Offset",
      id: "configuration_tool.tab.connected_devices_importing_parameter_offset"
    }
  }
];

export default fieldsTranslationConfig;
