// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import getAssetWatchState from "common/watchIcon/actions/getAssetWatchState";
import useWatchIcon from "common/watchIcon/hooks/useWatchIcon";
import Processing from "components/common/Processing";
import { isNil } from "lodash";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import WatchIcon from "./WatchIcon";

interface IWatchIconContainerProps {
  assetId: string;
}

const WatchIconContainer = ({ assetId }: IWatchIconContainerProps) => {
  const [assetWatched, setAssetWatched] = useState<boolean>();
  const [watchedErrorStatus, setWatchedErrorStatus] = useState<boolean>(false);

  const {
    translations,
    limitReached,
    decreaseWatchListCount,
    increaseWatchListCount,
    getAddErrorMessage,
    getRemoveErrorMessage,
    countErrorStatus
  } = useWatchIcon();

  useEffect(() => {
    getAssetWatchState(assetId)
      .then((isWatched) => {
        setAssetWatched(isWatched);
        setWatchedErrorStatus(false);
      })
      .catch(() => {
        setWatchedErrorStatus(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showError = useMemo(
    () => watchedErrorStatus || countErrorStatus,
    [countErrorStatus, watchedErrorStatus]
  );

  return (
    <StyledAssetWatchIconContainer data-qa="watch-list-button">
      {(isNil(assetWatched) || isNil(limitReached)) && !showError ? (
        <Processing />
      ) : (
        <WatchIcon
          assetId={assetId}
          assetWatched={assetWatched}
          limitReached={limitReached}
          onAddAssetToWatchList={increaseWatchListCount}
          onRemoveAssetFromWatchList={decreaseWatchListCount}
          getAddErrorMessage={getAddErrorMessage}
          getRemoveErrorMessage={getRemoveErrorMessage}
          translations={translations}
          showError={showError}
        />
      )}
    </StyledAssetWatchIconContainer>
  );
};

const StyledAssetWatchIconContainer = styled.div`
  .spinner {
    width: initial;

    & > div:nth-of-type(3) ~ * {
      display: none;
    }
  }
`;

export default WatchIconContainer;
