// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { useAppSelector } from "store";
import getScatterPlotFilters from "../selectors/getScatterPlotFiltersSelector";
import getScatterPlotXDataSelector from "../selectors/getScatterPlotXDataSelector";
import getScatterPlotYDataSelector from "../selectors/getScatterPlotYDataSelector";

const useFamilyAnalyticsWidgetContainer = () => {
  const scatterPlotFilters = useAppSelector(getScatterPlotFilters);
  const scatterPlotYData = useAppSelector(getScatterPlotYDataSelector);
  const scatterPlotXData = useAppSelector(getScatterPlotXDataSelector);

  return {
    scatterPlotFilters,
    scatterPlotYData,
    scatterPlotXData
  };
};

export default useFamilyAnalyticsWidgetContainer;
