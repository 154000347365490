// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { ISwitchItem } from "common/form/components/Switch";

export const riskSwitchOptions: ISwitchItem[] = [
  {
    id: undefined,
    message: {
      defaultMessage: "All",
      id: "component_risk_summary_page.side_panel.switch.All"
    }
  },
  {
    id: "High",
    message: {
      defaultMessage: "High",
      id: "component_risk_summary_page.side_panel.switch.High"
    }
  },
  {
    id: "Medium",
    message: {
      defaultMessage: "Medium",
      id: "component_risk_summary_page.side_panel.switch.Medium"
    }
  },
  {
    id: "Low",
    message: {
      defaultMessage: "Low",
      id: "component_risk_summary_page.side_panel.switch.Low"
    }
  },
  {
    id: "Unknown",
    message: {
      defaultMessage: "Unknown",
      id: "component_risk_summary_page.side_panel.switch.Unknown"
    }
  }
];
