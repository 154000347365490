// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Statuses } from "core/data/models/Data";
import { isNil } from "lodash";
import AssetRiskTypes from "models/AssetRiskTypes";
import { IState } from "reducers/Index";
import { createSelector } from "reselect";
import { dataKey } from "../actions/loadAssetRiskCounts";

const getAssetRiskCountsStatus = createSelector(
  (state: IState) => (type: AssetRiskTypes, ids: string[][]) => {
    const result = state.data[dataKey(type)];
    const parentStatus: Statuses = result?.status;

    const childStatuses = ids.map((id) => {
      const result = state.data[dataKey(type, id)];
      return result?.status as Statuses;
    });

    const statuses = [parentStatus, ...childStatuses].filter((s) => !isNil(s));
    const isLoading = statuses.findIndex((s) => s === Statuses.Loading) >= 0;
    const isFailed = statuses.findIndex((s) => s === Statuses.Failed) >= 0;
    const isSucceeded =
      statuses.filter((s) => s === Statuses.Succeeded).length ===
      statuses.length;

    return isSucceeded
      ? Statuses.Succeeded
      : isLoading
      ? Statuses.Loading
      : isFailed
      ? Statuses.Failed
      : null;
  },
  (status) => status
);

export default getAssetRiskCountsStatus;
