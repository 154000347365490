// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import ISection from "features/ConfigurationTool/models/ISection";
import FieldTypes from "models/FieldTypes";

export const modelParametersMappingConfiguration: ISection = {
  sectionName: {
    defaultMessage: "Model Parameters Mapping",
    id: "data_integration.model_config.model_parameters_mapping"
  },
  sectionType: "grid",
  fields: [
    {
      fieldName: {
        defaultMessage: "Model Parameter",
        id: "data_integration.model_config.model_parameters_mapping.model_param"
      },
      fieldKey: "ModelParameter",
      fieldType: FieldTypes.Parameter,
      dataType: "String",
      inputType: "String",
      order: 1
    },
    {
      fieldName: {
        id: "data_integration.model_config.model_parameters_mapping.source",
        defaultMessage: "Source"
      },
      fieldKey: "Source",
      fieldType: FieldTypes.Parameter,
      dataType: "String",
      inputType: "list",
      order: 2
    },
    {
      fieldName: {
        id: "data_integration.model_config.model_parameters_mapping.input",
        defaultMessage: "Input"
      },
      fieldKey: "Input",
      fieldType: FieldTypes.Parameter,
      dataType: "String",
      inputType: "autoComplete",
      order: 3
    }
  ]
};
