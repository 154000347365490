// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import IssueSlidePanel from "./components/IssuesSlidePanel";
import {
  ISlidePanelData as _ISlidePanelData,
  IssueSlidePanelMode
} from "./hooks/useIssuesSlidePanel";
export default IssueSlidePanel;
export type ISlidePanelData = _ISlidePanelData;
export { IssueSlidePanelMode };
