// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React, { useMemo } from "react";

import Container from "common/Container";
import ErrorMessage from "common/messages/ErrorMessage";
import Processing from "components/common/Processing";
import { Statuses } from "core/data/models/Data";
import UrlService from "core/data/services/UrlService";
import { config } from "utils/AppConfig";
import LicensesList from "./LicensesList";
import useLicenses from "../hooks/useLicenses";
import ICommonLicense from "../models/ICommonLicense";
import IMavenLicense from "../models/IMavenLicense";
import INpmLicenses from "../models/INpmLicenses";
import INugetLicense from "../models/INugetLicense";
import SectionName from "../../../components/common/SectionName";

import "./Licenses.less";

const Licenses = () => {
  const [commonLicenses] = useLicenses<ICommonLicense[]>(
    UrlService.getApiUrl(config.api.licenses.common)
  );
  const [npmLicenses] = useLicenses<INpmLicenses>(
    "/static/licenses/licenses.npm.json"
  );
  const [mavenLicenses] = useLicenses<IMavenLicense[]>(
    UrlService.getApiUrl(config.api.licenses.maven)
  );
  const [nugetLicenses] = useLicenses<INugetLicense[]>(
    UrlService.getApiUrl(config.api.licenses.nuget)
  );

  const computedStatus = useMemo(() => {
    const statuses = [
      commonLicenses.status,
      mavenLicenses.status,
      npmLicenses.status,
      nugetLicenses.status
    ];

    const isLoading = statuses.findIndex((s) => s === Statuses.Loading) >= 0;
    const isSucceeded =
      statuses.filter((s) => s === Statuses.Succeeded).length ===
      statuses.length;
    const isFailed = statuses.findIndex((s) => s === Statuses.Failed) >= 0;
    const isNotFound = statuses.findIndex((s) => s === Statuses.NotFound) >= 0;

    return isLoading
      ? Statuses.Loading
      : isFailed
      ? Statuses.Failed
      : isNotFound
      ? Statuses.NotFound
      : isSucceeded
      ? Statuses.Succeeded
      : null;
  }, [
    commonLicenses.status,
    mavenLicenses.status,
    npmLicenses.status,
    nugetLicenses.status
  ]);

  return (
    <div className="data-grid__parent">
      <div className="data-grid__scroll">
        <Container className="licenses">
          <SectionName
            messageId="licenses.external_libraries"
            messageDefault="External libraries"
          />
          <div>
            {computedStatus === Statuses.Loading && <Processing />}
            {(computedStatus === Statuses.Failed ||
              computedStatus === Statuses.NotFound) && (
              <ErrorMessage
                message={{
                  defaultMessage: "Getting licenses failed.",
                  id: "licenses.getting.failed"
                }}
              />
            )}
            {(computedStatus === Statuses.Succeeded ||
              computedStatus === Statuses.NotFound) && (
              <LicensesList
                commonLicenses={commonLicenses.data}
                mavenLicenses={mavenLicenses.data}
                npmLicenses={npmLicenses.data}
                nugetLicenses={nugetLicenses.data}
              />
            )}
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Licenses;
