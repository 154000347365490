// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import RouteNotFound from "./components/RouteNotFound";
import RouterLayout from "./components/RouterLayout";
import RouterProvider from "./components/RouterProvider";
import RouterSwitch from "./components/RouterSwitch";
import RouterContext from "./contexts/RouterContext";
import useAppNavigate, {
  NavigateFn as _NavigateFn,
  NavigateOptions as _NavigateOptions
} from "./hooks/useAppNavigate";
import ILayoutRoute from "./models/ILayoutRoute";
import IRoute from "./models/IRoute";

export type NavigateFn = _NavigateFn;
export type NavigateOptions = _NavigateOptions;
export type LayoutRoute = ILayoutRoute;
export type Route = IRoute;

export {
  RouteNotFound,
  RouterContext,
  RouterLayout,
  RouterSwitch,
  useAppNavigate
};

export default RouterProvider;
