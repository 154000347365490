// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Data from "core/data/models/Data";
import { IState } from "reducers/Index";
import { createSelector } from "reselect";
import { getDataKey } from "../actions/loadMechanical";
import IMechanicalPoles from "../models/IMechanicalPoles";

const getMechanicalSelector = createSelector(
  (state: IState) => (assetId: string) =>
    state.data[getDataKey(assetId)] as Data<IMechanicalPoles>,
  (mechanical) => mechanical
);

export default getMechanicalSelector;
