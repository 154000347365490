// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Legend from "@apm/widgets/build/models/Legend";
import {
  colorBlue60,
  colorStatusOrange,
  colorStatusRed
} from "@pg/common/build/styles/ColorVariables";
import { IntlShape } from "react-intl";
import SotTypes from "../models/SotTypes";

const formatSotTypeLegendName = (sotType: SotTypes) => {
  switch (sotType) {
    case SotTypes.MINERAL_OIL_IEC_NETWORK_TRANSFORMER:
      return "Mineral Oil IEC";
    case SotTypes.SILICONE_IEEE_NETWORK_TRANSFORMER:
      return "Silicone IEEE";
    case SotTypes.IEEE:
      return "Transformer IEEE";
    case SotTypes.IEC:
      return "Transformer IEC";
  }
};

const getSotTypeTranslation = (intl: IntlShape, sotType: SotTypes) => {
  return intl.formatMessage(
    {
      defaultMessage: "{sotType}",
      id: `detail_page.widgets.analytics.transformers.sot.${sotType}`
    },
    {
      sotType: formatSotTypeLegendName(sotType)
    }
  );
};

const getLegend =
  (sotType: SotTypes, intl: IntlShape) =>
  (_chartGroupName: string, dataSource: string) => {
    const legend: Legend = [];
    legend.push({
      name: sotType,
      displayName: getSotTypeTranslation(intl, sotType),
      isDataSeries: false,
      type: "info"
    });

    const isIEC = sotType === SotTypes.IEC;
    const isIEEE = sotType === SotTypes.IEEE;
    const isMineralOilIECNetworkTransformer =
      sotType === SotTypes.MINERAL_OIL_IEC_NETWORK_TRANSFORMER;
    const isSiliconeIEEENetworkTransformer =
      sotType === SotTypes.SILICONE_IEEE_NETWORK_TRANSFORMER;

    if (dataSource === "offline") {
      legend.push({
        name: "offline_data",
        displayName: intl.formatMessage({
          defaultMessage: "Offline data",
          id: "detail_page.widgets.analytics.transformers.sot.offline_data"
        }),
        isDataSeries: false,
        type: "square",
        color: "black"
      });
    }

    if (dataSource === "online") {
      legend.push({
        name: "online_data",
        displayName: intl.formatMessage({
          defaultMessage: "Online data",
          id: "detail_page.widgets.analytics.transformers.sot.online_data"
        }),
        isDataSeries: false,
        type: "square",
        color: colorBlue60
      });
    }

    if (isIEEE) {
      legend.push({
        name: "PowerFactor100C_offline",
        displayName: intl.formatMessage({
          defaultMessage: "PF 100 (°C)",
          id: "detail_page.widgets.analytics.transformers.sot.PowerFactor100C_offline"
        }),
        isDataSeries: true,
        type: "square",
        color: colorBlue60
      });

      legend.push({
        name: "PowerFactor25C_offline",
        displayName: intl.formatMessage({
          defaultMessage: "PF 25 (°C)",
          id: "detail_page.widgets.analytics.transformers.sot.PowerFactor25C_offline"
        }),
        isDataSeries: true,
        type: "square",
        color: colorStatusOrange
      });
    }

    if (isMineralOilIECNetworkTransformer || isSiliconeIEEENetworkTransformer) {
      legend.push(
        {
          name: "Alarm",
          displayName: intl.formatMessage({
            defaultMessage: "Alarm",
            id: "detail_page.widgets.analytics.transformers.sot.alarm"
          }),
          isDataSeries: false,
          type: "region",
          color: colorStatusRed
        },
        {
          name: "Alert",
          displayName: intl.formatMessage({
            defaultMessage: "Alert",
            id: "detail_page.widgets.analytics.transformers.sot.alert"
          }),
          isDataSeries: false,
          type: "region",
          color: colorStatusOrange
        }
      );
    }

    if (isIEC) {
      legend.push(
        {
          name: "Poor",
          displayName: intl.formatMessage({
            defaultMessage: "Poor",
            id: "detail_page.widgets.analytics.transformers.sot.poor"
          }),
          isDataSeries: false,
          type: "region",
          color: colorStatusRed
        },
        {
          name: "Fair",
          displayName: intl.formatMessage({
            defaultMessage: "Fair",
            id: "detail_page.widgets.analytics.transformers.sot.fair"
          }),
          isDataSeries: false,
          type: "region",
          color: colorStatusOrange
        }
      );
    }
    return legend;
  };

export default getLegend;
