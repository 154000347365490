// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Data from "core/data/models/Data";
import { IState } from "reducers/Index";
import { createSelector } from "reselect";
import dataKey from "../constants/configurationDataKey";
import IConfigurationData from "../models/IConfigurationData";

const getConfigurationDataSelector = createSelector(
  (state: IState) => (modelId: string, configurationName: string) =>
    state.data[dataKey(modelId, configurationName)] as Data<IConfigurationData>,
  (configurationData) => configurationData
);

export default getConfigurationDataSelector;
