// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import * as React from "react";
import { FormattedMessage } from "react-intl";

const BatteryConductanceTitle = () => (
  <div className="chart-name">
    <FormattedMessage
      defaultMessage="{conductance} - {recent}"
      id="detail_page.parameters.battery_conductance.chart_title"
      values={{
        conductance: (
          <span className="bold">
            <FormattedMessage
              defaultMessage="Conductance"
              id="detail_page.parameters.battery_conductance.conductance"
            />
          </span>
        ),
        recent: (
          <FormattedMessage
            defaultMessage="Recent"
            id="detail_page.parameters.battery_conductance.recent"
          />
        )
      }}
    />
  </div>
);

export default BatteryConductanceTitle;
