// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { useEffect } from "react";
import { NavigateProps, To, useParams } from "react-router";
import useAppNavigate from "../hooks/useAppNavigate";

const Navigate = ({ to, relative, replace, state }: NavigateProps) => {
  const navigate = useAppNavigate();
  const params = useParams();

  useEffect(() => {
    const keys = Object.keys(params);
    let redirectTo: To;

    if (typeof to === "string") {
      let tmpTo = to;
      keys.forEach((k) => {
        tmpTo = tmpTo.replace(`:${k}`, params[k]);
      });
      redirectTo = tmpTo;
    } else {
      let tmpTo = { ...to };
      keys.forEach((k) => {
        tmpTo.pathname = tmpTo.pathname.replace(`:${k}`, params[k]);
      });
      redirectTo = tmpTo;
    }

    navigate(redirectTo, { replace, state });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div />;
};

export default Navigate;
