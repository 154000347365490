// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { useIntl } from "react-intl";

import RogersRatio2D, {
  IRogersRatio2DChart
} from "@apm/widgets/build/widgets/RogersRatio2D";
import { capitalize } from "lodash";
import { useMemo } from "react";
import RogersRatio2DData from "../../models/RogersRatio2DData";
import dataSourceConfig from "./constants/dataSourceConfig";
import regionsChart1 from "./constants/regionsChart1";
import regionsChart2 from "./constants/regionsChart2";
import getLegend from "./utils/getLegend";
import mapToChart1Points from "./utils/mapToChart1Points";
import mapToChart2Points from "./utils/mapToChart2Points";

interface IRogerRatio2DChartProps {
  data: RogersRatio2DData;
}

const RogersRatio2DChart = ({ data }: IRogerRatio2DChartProps) => {
  const intl = useIntl();

  const charts: IRogersRatio2DChart[] = useMemo(
    () => [
      {
        values: {
          offline: mapToChart1Points(data.OfflinePoints),
          online: mapToChart1Points(data.OnlinePoints)
        },
        regions: regionsChart1,
        xAxisLabel: "C2H4 / C2H6",
        yAxisLabel: "C2H2 / C2H4"
      },
      {
        values: {
          offline: mapToChart2Points(data.OfflinePoints),
          online: mapToChart2Points(data.OnlinePoints)
        },
        regions: regionsChart2,
        xAxisLabel: "C2H4 / C2H6",
        yAxisLabel: "C2H2 / C2H6"
      }
    ],
    [data.OfflinePoints, data.OnlinePoints]
  );

  const legend = useMemo(() => getLegend(intl), [intl]);

  const translations = useMemo(
    () => ({
      noData: intl.formatMessage({
        defaultMessage: "No data",
        id: "common.no_data_message"
      }),
      dataSourceLabel: intl.formatMessage({
        defaultMessage: "Data source",
        id: `global.data_source`
      }),
      getDataSourceOptionTranslation: (dataSource: string) =>
        intl.formatMessage({
          defaultMessage: capitalize(dataSource),
          id: `detail_page.widgets.analytics.transformers.rogers_ratio.data_source.${dataSource}`
        })
    }),
    [intl]
  );

  return (
    <RogersRatio2D
      charts={charts}
      legend={legend}
      dataSourceConfig={dataSourceConfig}
      translations={translations}
      dateFormatter={(date) => intl.formatDate(date)}
      valueFormatter={(value) => value.toFixed(2)}
      maxChartSize={850}
    />
  );
};

export default RogersRatio2DChart;
