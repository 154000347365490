// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import PrognosticRiskGrid from "@apm/widgets/build/widgets/PrognosticRiskGrid";
import SearchParams from "@pg/common/build/models/SearchParams";
import Processing from "components/common/Processing";
import { WidgetNoDataMessage } from "components/common/widget/Widget";
import { useAppNavigate } from "core/app/components/RouterProvider";
import ActionFamilyInfo, {
  IActionFamilyInfoOwnProps
} from "features/detailpage/features/ParametersTab/components/ParametersGrid/components/ActionFamilyInfo";
import ActionHistogram, {
  IActionHistogramOwnProps
} from "features/detailpage/features/ParametersTab/components/ParametersGrid/components/ActionHistogram";
import { isEmpty } from "lodash";
import { memo, useCallback } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import IPrognosticGridRow from "../../PrognosticWidget/models/IPrognosticGridRow";

interface IPrognosticGridProps {
  className?: string;
  rowsReady?: boolean;
  rows?: IPrognosticGridRow[];
  modelId: string;
  assetId: string;
}

const indicativeParameterMessageType = "parameter";
const modelOutputParameterNameMessageType = "derivative-parameter";
const parametersPath: string = "/Assets/DetailPage/Parameters/Parameters";
export const searchParamsNames = [
  "assetId",
  "c_LastUpdate",
  "c_Unit",
  "p_selected"
];

const PrognosticGrid = memo(
  ({ className, rows, rowsReady, modelId, assetId }: IPrognosticGridProps) => {
    const { formatMessage } = useIntl();
    const navigate = useAppNavigate();
    const location = useLocation();
    const handleParametersPageOpenClick = useCallback(
      (parameterName: string) => {
        const valuesArray = [assetId, "desc", "asc", `["${parameterName}"]`];
        const resultSearchArray = searchParamsNames.map((item, index) => {
          return [item, valuesArray[index]];
        });
        const search = new SearchParams(resultSearchArray).toString();

        navigate(
          {
            pathname: parametersPath,
            search
          },
          { replace: true, state: location.state }
        );
      },
      [assetId, navigate, location.state]
    );

    return (
      <div className={className}>
        {rowsReady ? (
          isEmpty(rows) ? (
            <WidgetNoDataMessage />
          ) : (
            <PrognosticRiskGrid
              rows={rows}
              defaultShowPof={true}
              onParametersPageOpenClick={handleParametersPageOpenClick}
              FamilyInfo={({
                parameterName,
                translatedParameterName,
                parameterSource
              }: IActionFamilyInfoOwnProps) => (
                <ActionFamilyInfo
                  assetId={assetId}
                  parameterName={parameterName}
                  translatedParameterName={translatedParameterName}
                  parameterSource={parameterSource}
                ></ActionFamilyInfo>
              )}
              ActionHistogram={({
                parameterName,
                translatedParameterName,
                parameterSource,
                unit
              }: IActionHistogramOwnProps) => (
                <ActionHistogram
                  assetId={assetId}
                  parameterName={parameterName}
                  translatedParameterName={translatedParameterName}
                  parameterSource={parameterSource}
                  unit={unit}
                ></ActionHistogram>
              )}
              translations={{
                checkboxLabelTranslation: formatMessage({
                  defaultMessage: "Show PoF value",
                  id: "detail_page.risk_trend.grid.pof_checkbox"
                }),
                pofTranslation: formatMessage({
                  defaultMessage: "PoF Today",
                  id: "detail_page.risk_trend.grid.pof_header"
                }),
                indicativeParameters: formatMessage({
                  defaultMessage: "Indicative Parameters",
                  id: "detail_page.risk_trend.grid.tooltip_indicative_parameters_header"
                }),
                otherParameters: formatMessage({
                  defaultMessage: "Other Parameters",
                  id: "detail_page.risk_trend.grid.modal_other_parameters_header"
                }),
                unitHeader: formatMessage({
                  defaultMessage: "Unit",
                  id: "detail_page.risk_trend.grid.modal_unit_header"
                }),
                firstIndicativeHeader: formatMessage({
                  defaultMessage: "First indicative",
                  id: "detail_page.risk_trend.grid.modal_first_indicative_header"
                }),
                formatTooltipTitle: (name: JSX.Element, date: JSX.Element) =>
                  formatMessage(
                    {
                      defaultMessage: "{name} - {date}",
                      id: "detail_page.risk_trend.grid.tooltip_header"
                    },
                    {
                      name,
                      date
                    }
                  ),
                malfunctionHeader: (name: string) =>
                  formatMessage(
                    {
                      defaultMessage: "{name} - Malfunction Mode preview",
                      id: "detail_page.risk_trend.grid.modal_malfunction_header"
                    },
                    {
                      name
                    }
                  ),
                indicativeParameterName: (parameter: string) =>
                  formatMessage({
                    id: `${modelId}.${indicativeParameterMessageType}.${parameter}`,
                    defaultMessage: parameter
                  }),
                modelOutputParameterName: (parameter: string) =>
                  formatMessage({
                    id: `${modelId}.${modelOutputParameterNameMessageType}.${parameter}`,
                    defaultMessage: parameter
                  })
              }}
            />
          )
        ) : (
          <Processing className="spinner small dark prognostic-grid-spinner" />
        )}
      </div>
    );
  }
);

const StyledPrognosticGrid = styled(PrognosticGrid)`
  height: 100%;
  .prognostic-grid-spinner {
    width: 100%;
    height: 100%;
  }

  .widget-message {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export default StyledPrognosticGrid;
