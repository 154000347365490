// Copyright 2016-2024 Hitachi Energy. All rights reserved.

enum ImportedParameterKey {
  DisplayName = "DisplayName",
  TagName = "TagName",
  DataType = "DataType",
  PartitionModbus = "PartitionModbus",
  IdModbus = "IdModbus",
  Scale = "Scale",
  Offset = "Offset"
}

export default ImportedParameterKey;
