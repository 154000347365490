// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import * as d3 from "d3";
import { useLayoutEffect, useRef } from "react";
import { useIntl } from "react-intl";
import IDuvalPoint from "../models/IDuvalPoint";
import createDuvalPentagonAxis from "../utils/createDuvalPentagonAxis";
import createDuvalPentagonLabels from "../utils/createDuvalPentagonLabels";
import createDuvalPentagonPoints from "../utils/createDuvalPentagonPoints";
import createDuvalPentagonRegions from "../utils/createDuvalPentagonRegions";
import DataSource from "common/DuvalAnalysis/models/DataSource";

interface IUseDuvalPentagonProps {
  points: IDuvalPoint[];
  type: number;
  dataSource: DataSource;
}

const useDuvalPentagon = ({
  points,
  type,
  dataSource
}: IUseDuvalPentagonProps) => {
  const svgRef = useRef<SVGSVGElement>();
  const tooltipRef = useRef<HTMLDivElement>();
  const intl = useIntl();

  useLayoutEffect(() => {
    const root = d3.select(svgRef.current);
    const tooltip = d3.select(tooltipRef.current);
    root.selectAll("*").remove();
    const pentagons = createDuvalPentagonRegions({
      type,
      root
    });
    createDuvalPentagonAxis({ root });
    createDuvalPentagonLabels({ root, pentagons });
    createDuvalPentagonPoints({ root, tooltip, points, intl, dataSource });
  }, [points, type, dataSource, intl, svgRef]);

  return { svgRef, tooltipRef };
};

export default useDuvalPentagon;
