// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { IssueStatuses } from "common/issueStatus/models/IssueStatuses";
import { editIssueStatus } from "features/detailpage/features/issues/actions/IssuesActions";
import IssueDetailsHeader, {
  IIssueDetailsHeaderActions
} from "features/detailpage/features/issues/components/IssueDetailsHeader";
import { IntlShape } from "react-intl";
import { connect } from "react-redux";
import { AppDispatch } from "store";

export function mapDispatchToProps(
  dispatch: AppDispatch
): IIssueDetailsHeaderActions {
  return {
    editIssueStatus: (
      issueId: string,
      currentStatus: IssueStatuses,
      status: IssueStatuses,
      comment: string,
      issueStatusTranslation: { [key in IssueStatuses]: string },
      intl: IntlShape
    ) =>
      editIssueStatus(
        dispatch,
        issueId,
        currentStatus,
        status,
        comment,
        issueStatusTranslation,
        intl
      )
  };
}

export default connect(undefined, mapDispatchToProps)(IssueDetailsHeader);
