// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { colorPrimary } from "@pg/common/build/styles/ColorVariables";
import { Typography } from "antd";
import styled from "styled-components";
import { spacingBase, spacingSmall } from "styles/StyleVariables";

interface IAssetIdColumnProps {
  assetId: string;
  className?: string;
  isNew: boolean | undefined;
}

const AssetIdColumn = ({ assetId, className }: IAssetIdColumnProps) => {
  return (
    <Typography.Text className={className} strong>
      {assetId}
    </Typography.Text>
  );
};

const columnPadding = "10px";

export default styled(AssetIdColumn)`
  border-left: ${({ isNew }) =>
    !isNew ? undefined : `solid ${spacingSmall} ${colorPrimary}`};
  display: block;
  color: ${colorPrimary};
  height: 100%;
  padding: ${({ isNew }) =>
    !isNew
      ? columnPadding
      : `${columnPadding} ${columnPadding} ${columnPadding} ${spacingBase}`};
`;
