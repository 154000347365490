// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { loadAssetRiskConfig } from "features/detailpage/features/riskmatrix/actions/RiskMatrixActions";
import { useCallback } from "react";
import { useAppDispatch } from "store";

const useLoadAssetRiskConfig = () => {
  const dispatch = useAppDispatch();
  return useCallback(
    (modelId: string) => loadAssetRiskConfig(dispatch, modelId),
    [dispatch]
  );
};

export default useLoadAssetRiskConfig;
