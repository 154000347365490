// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import { Button } from "antd";
import { FormattedMessage } from "react-intl";

import IssueModes from "features/detailpage/features/issues/models/IssueModes";

interface IIssueDetailsHeaderActionsProps {
  allowSave: boolean;
  className?: string;
  mode: IssueModes;
  onEditButtonClick?: () => void;
  onSaveButtonClick?: () => void;
  onCancelButtonClick?: () => void;
}

const IssueDetailsHeaderActions = ({
  allowSave,
  className,
  mode,
  onEditButtonClick,
  onSaveButtonClick,
  onCancelButtonClick
}: IIssueDetailsHeaderActionsProps) => {
  return mode === IssueModes.View ? (
    <div className={className}>
      <Button
        type="primary"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (onEditButtonClick) onEditButtonClick();
        }}
      >
        <Icon name="edit" size="sm" />
        <FormattedMessage
          defaultMessage="Edit"
          id="detail_page.issues.issue_details.btn_edit"
        />
      </Button>
    </div>
  ) : mode === IssueModes.Create || mode === IssueModes.Edit ? (
    <div className="issue-actions">
      <Button
        type="primary"
        disabled={!allowSave}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (onSaveButtonClick) onSaveButtonClick();
        }}
      >
        <Icon name="save" size="sm" />
        <FormattedMessage
          defaultMessage="Save"
          id="detail_page.issues.issue_details.btn_save"
        />
      </Button>
      <Button
        type="link"
        className="dark"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (onCancelButtonClick) onCancelButtonClick();
        }}
      >
        <FormattedMessage
          defaultMessage="Cancel"
          id="detail_page.issues.issue_details.btn_cancel"
        />
      </Button>
    </div>
  ) : null;
};

export default IssueDetailsHeaderActions;
