// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React from "react";

import { Statuses } from "core/data/models/Data";
import ResizeWrapper from "common/ResizeWrapper";
import Processing from "components/common/Processing";
import { WidgetNoDataMessage } from "components/common/widget/Widget";

import { IScatterPlotData } from "../reducers/FamilyAnalyticsReducer";
import ScatterPlotChart from "features/familyanalytics/components/ScatterPlotChart";
import { DataTypes } from "../services/ScatterPlotWidgetService";

import "./ScatterPlotWidget.less";
import useScatterPlotWidget from "../hooks/useScatterPlotWidget";

export interface IScatterPlotWidgetData {
  scatterPlotYData: IScatterPlotData;
  scatterPlotXData: IScatterPlotData;
}

export interface IScatterPlotWidgetOwnProps {
  parameters: ScatterPlotParameters;
}

export type ScatterPlotParameters = {
  assetId: string;
  dataTypeY: DataTypes;
  seriesNameY: string;
  dataTypeX?: DataTypes;
  seriesNameX?: string;
  showCorrelation?: string;
};

export interface IScatterPlotWidgetProps
  extends IScatterPlotWidgetOwnProps,
    IScatterPlotWidgetData {}

const ScatterPlotWidget = ({
  scatterPlotYData,
  scatterPlotXData,
  parameters
}: IScatterPlotWidgetProps) => {
  const { getDataStatus } = useScatterPlotWidget(
    parameters,
    scatterPlotYData,
    scatterPlotXData
  );

  const dataStatus = getDataStatus();
  const showCorrelation = parameters.showCorrelation === "1";
  return (
    <>
      {dataStatus === Statuses.Loading && <ScatterPlotWidgetProcessing />}
      {dataStatus === Statuses.Succeeded && (
        <ScatterPlotWidgetSucceeded
          scatterPlotYData={scatterPlotYData}
          scatterPlotXData={scatterPlotXData}
          showCorrelation={showCorrelation}
        />
      )}
      {(dataStatus === Statuses.NotFound || dataStatus === Statuses.Failed) && (
        <div className="scatter-plot-widget no-data-message">
          <WidgetNoDataMessage />
        </div>
      )}
    </>
  );
};

const ScatterPlotWidgetProcessing = () => <Processing />;

interface IScatterPlotWidgetSucceededProps {
  scatterPlotYData: IScatterPlotData;
  scatterPlotXData: IScatterPlotData;
  showCorrelation: boolean;
}
const ScatterPlotWidgetSucceeded = ({
  scatterPlotYData,
  scatterPlotXData,
  showCorrelation
}: IScatterPlotWidgetSucceededProps) => {
  return (
    <div className="scatter-plot-widget">
      <ResizeWrapper>
        <ScatterPlotChart
          assetId={scatterPlotYData.assetId}
          labelX={scatterPlotXData.labelX}
          labelY={scatterPlotYData.labelY}
          scatterPlotYData={scatterPlotYData.dataPoints.data}
          scatterPlotXData={scatterPlotXData.dataPoints.data}
          showCorrelation={showCorrelation}
        />
      </ResizeWrapper>
    </div>
  );
};

export default ScatterPlotWidget;
