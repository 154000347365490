// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import * as d3 from "d3";
import createDuvalPentagonConvertedCoordinates from "./createDuvalPentagonConvertedCoordinates";

interface IDuvalPentagonRegionsProps {
  type: number;
  root: d3.Selection<SVGSVGElement, unknown, null, undefined>;
}

interface IPoint {
  x: number;
  y: number;
}

type d3Selection<T extends d3.BaseType> = d3.Selection<
  T,
  unknown,
  null,
  undefined
>;

const createDuvalPentagonRegions = ({
  type,
  root
}: IDuvalPentagonRegionsProps) => {
  return drawDuvalRegions(type, root);
};

function getRegion(type: number, name: string): IPoint[] {
  const points: IPoint[] = [];
  switch (`T${type}.${name}`) {
    case "T1.PD":
      points.push({ x: -1, y: 33 });
      points.push({ x: 0, y: 33 });
      points.push({ x: 0, y: 24.5 });
      points.push({ x: -1, y: 24.5 });
      return points;
    case "T1.D1":
      points.push({ x: 0, y: 40 });
      points.push({ x: 38, y: 12.4 });
      points.push({ x: 32, y: -6.1 });
      points.push({ x: 4, y: 16 });
      points.push({ x: 0, y: 1.5 });
      return points;
    case "T1.D2":
      points.push({ x: 0, y: 1.5 });
      points.push({ x: 4, y: 16 });
      points.push({ x: 32, y: -6.1 });
      points.push({ x: 24.3, y: -30 });
      points.push({ x: 0, y: -3 });
      return points;
    case "T1.T1":
      points.push({ x: 0, y: 1.5 });
      points.push({ x: 0, y: -3 });
      points.push({ x: -6, y: -4 });
      points.push({ x: -22.5, y: -32.4 });
      points.push({ x: -23.5, y: -32.4 });
      points.push({ x: -35, y: 3.1 });
      return points;
    case "T1.T2":
      points.push({ x: -22.5, y: -32.4 });
      points.push({ x: 1, y: -32.4 });
      points.push({ x: -6, y: -4 });
      return points;
    case "T1.T3":
      points.push({ x: -6, y: -4 });
      points.push({ x: 0, y: -3 });
      points.push({ x: 24.3, y: -30 });
      points.push({ x: 23.5, y: -32.4 });
      points.push({ x: 1, y: -32.4 });
      return points;
    case "T1.S":
      points.push({ x: -35, y: 3.1 });
      points.push({ x: -38, y: 12.4 });
      points.push({ x: 0, y: 40 });
      points.push({ x: 0, y: 33 });
      points.push({ x: -1, y: 33 });
      points.push({ x: -1, y: 24.5 });
      points.push({ x: 0, y: 24.5 });
      points.push({ x: 0, y: 1.5 });
      return points;

    case "T2.PD":
      points.push({ x: -1, y: 33 });
      points.push({ x: 0, y: 33 });
      points.push({ x: 0, y: 24.5 });
      points.push({ x: -1, y: 24.5 });
      return points;
    case "T2.D1":
      points.push({ x: 0, y: 40 });
      points.push({ x: 38, y: 12.4 });
      points.push({ x: 32, y: -6.1 });
      points.push({ x: 4, y: 16 });
      points.push({ x: 0, y: 1.5 });
      return points;
    case "T2.D2":
      points.push({ x: 0, y: 1.5 });
      points.push({ x: 4, y: 16 });
      points.push({ x: 32, y: -6.1 });
      points.push({ x: 24.3, y: -30 });
      points.push({ x: 0, y: -3 });
      return points;
    case "T2.T3-H":
      points.push({ x: 0, y: -3 });
      points.push({ x: -3.5, y: -3.5 });
      points.push({ x: 2.5, y: -32.4 });
      points.push({ x: 23.5, y: -32.4 });
      points.push({ x: 24.3, y: -30 });
      return points;
    case "T2.C":
      points.push({ x: -21.5, y: -32.4 });
      points.push({ x: 2.5, y: -32.4 });
      points.push({ x: -3.5, y: -3.5 });
      points.push({ x: -6, y: -4 });
      points.push({ x: -11, y: -8 });
      return points;
    case "T2.O":
      points.push({ x: 0, y: 1.5 });
      points.push({ x: 0, y: -3 });
      points.push({ x: -6, y: -4 });
      points.push({ x: -11, y: -8 });
      points.push({ x: -21.5, y: -32.4 });
      points.push({ x: -23.5, y: -32.4 });
      points.push({ x: -35, y: 3.1 });
      return points;
    case "T2.S":
      points.push({ x: -35, y: 3.1 });
      points.push({ x: -38, y: 12.4 });
      points.push({ x: 0, y: 40 });
      points.push({ x: 0, y: 33 });
      points.push({ x: -1, y: 33 });
      points.push({ x: -1, y: 24.5 });
      points.push({ x: 0, y: 24.5 });
      points.push({ x: 0, y: 1.5 });
      return points;

    case "T3.PD":
      points.push({ x: -1, y: 33 });
      points.push({ x: 0, y: 33 });
      points.push({ x: 0, y: 24.5 });
      points.push({ x: -1, y: 24.5 });
      return points;
    case "T3.D1":
      points.push({ x: 0, y: 40 });
      points.push({ x: 38, y: 12.4 });
      points.push({ x: 32, y: -6.1 });
      points.push({ x: 4, y: 16 });
      points.push({ x: 0, y: 1.5 });
      return points;
    case "T3.D2":
      points.push({ x: 0, y: 1.5 });
      points.push({ x: 4, y: 16 });
      points.push({ x: 32, y: -6.1 });
      points.push({ x: 24.3, y: -30 });
      points.push({ x: 0, y: -3 });
      return points;
    case "T3.T3-H":
      points.push({ x: 0, y: -3 });
      points.push({ x: -3.5, y: -3.5 });
      points.push({ x: 2.5, y: -32.4 });
      points.push({ x: 23.5, y: -32.4 });
      points.push({ x: 24.3, y: -30 });
      return points;
    case "T3.T3-C":
      points.push({ x: -3.5, y: -3.5 });
      points.push({ x: -6, y: -4 });
      points.push({ x: 2.5, y: -32.4 });
      points.push({ x: 3, y: -32.4 });
      return points;
    case "T3.T2-C":
      points.push({ x: -22.5, y: -32.4 });
      points.push({ x: 1, y: -32.4 });
      points.push({ x: -6, y: -4 });
      return points;
    case "T3.T1-O":
      points.push({ x: 0, y: 1.5 });
      points.push({ x: 0, y: -3 });
      points.push({ x: -6, y: -4 });
      points.push({ x: -11, y: -8 });
      points.push({ x: -21.5, y: -32.4 });
      points.push({ x: -23.5, y: -32.4 });
      points.push({ x: -35, y: 3.1 });
      return points;
    case "T3.T1-C/T2-O":
      points.push({ x: -6, y: -4 });
      points.push({ x: -11, y: -8 });
      points.push({ x: -21.5, y: -32.4 });
      points.push({ x: -22.5, y: -32.4 });
      return points;
    case "T3.S":
      points.push({ x: -35, y: 3.1 });
      points.push({ x: -38, y: 12.4 });
      points.push({ x: 0, y: 40 });
      points.push({ x: 0, y: 33 });
      points.push({ x: -1, y: 33 });
      points.push({ x: -1, y: 24.5 });
      points.push({ x: 0, y: 24.5 });
      points.push({ x: 0, y: 1.5 });
      return points;
    default:
      return points;
  }
}

function drawDuvalRegions(type: number, root: d3Selection<SVGElement>) {
  let coords: { x: number; y: number };
  let pentagons: d3Selection<SVGElement>[] = [];
  switch (type) {
    case 1:
      coords = createDuvalPentagonConvertedCoordinates({
        point: { x: 15, y: 15 }
      });
      pentagons.push(
        drawDuvalRegion(
          getRegion(type, "D1"),
          "#D2EDFA",
          "D1",
          coords.x,
          coords.y,
          root
        )
      );
      coords = createDuvalPentagonConvertedCoordinates({
        point: { x: 15, y: -6 }
      });
      pentagons.push(
        drawDuvalRegion(
          getRegion(type, "D2"),
          "#48A6EB",
          "D2",
          coords.x,
          coords.y,
          root
        )
      );
      coords = createDuvalPentagonConvertedCoordinates({
        point: { x: -7, y: 27 }
      });
      pentagons.push(
        drawDuvalRegion(
          getRegion(type, "PD"),
          "#5287F3",
          "PD",
          coords.x,
          coords.y,
          root
        )
      );
      coords = createDuvalPentagonConvertedCoordinates({
        point: { x: -20, y: -10 }
      });
      pentagons.push(
        drawDuvalRegion(
          getRegion(type, "T1"),
          "#98D2E7",
          "T1",
          coords.x,
          coords.y,
          root
        )
      );
      coords = createDuvalPentagonConvertedCoordinates({
        point: { x: -10, y: -27 }
      });
      pentagons.push(
        drawDuvalRegion(
          getRegion(type, "T2"),
          "#96B7F7",
          "T2",
          coords.x,
          coords.y,
          root
        )
      );
      coords = createDuvalPentagonConvertedCoordinates({
        point: { x: 5, y: -27 }
      });
      pentagons.push(
        drawDuvalRegion(
          getRegion(type, "T3"),
          "#DCE7FC",
          "T3",
          coords.x,
          coords.y,
          root
        )
      );
      coords = createDuvalPentagonConvertedCoordinates({
        point: { x: -15, y: 16 }
      });
      pentagons.push(
        drawDuvalRegion(
          getRegion(type, "S"),
          "#ADECE1",
          "S",
          coords.x,
          coords.y,
          root
        )
      );
      return pentagons;

    case 2:
      coords = createDuvalPentagonConvertedCoordinates({
        point: { x: -20, y: -10 }
      });
      pentagons.push(
        drawDuvalRegion(
          getRegion(type, "O"),
          "#75C37E",
          "O",
          coords.x,
          coords.y,
          root
        )
      );
      coords = createDuvalPentagonConvertedCoordinates({
        point: { x: -10, y: -27 }
      });
      pentagons.push(
        drawDuvalRegion(
          getRegion(type, "C"),
          "#6DB1AA",
          "C",
          coords.x,
          coords.y,
          root
        )
      );
      coords = createDuvalPentagonConvertedCoordinates({
        point: { x: -7, y: 27 }
      });
      pentagons.push(
        drawDuvalRegion(
          getRegion(type, "PD"),
          "#5287F3",
          "PD",
          coords.x,
          coords.y,
          root
        )
      );
      coords = createDuvalPentagonConvertedCoordinates({
        point: { x: -15, y: 16 }
      });
      pentagons.push(
        drawDuvalRegion(
          getRegion(type, "S"),
          "#ADECE1",
          "S",
          coords.x,
          coords.y,
          root
        )
      );
      coords = createDuvalPentagonConvertedCoordinates({
        point: { x: 15, y: 15 }
      });
      pentagons.push(
        drawDuvalRegion(
          getRegion(type, "D1"),
          "#D2EDFA",
          "D1",
          coords.x,
          coords.y,
          root
        )
      );
      coords = createDuvalPentagonConvertedCoordinates({
        point: { x: 15, y: -6 }
      });
      pentagons.push(
        drawDuvalRegion(
          getRegion(type, "D2"),
          "#48A6EB",
          "D2",
          coords.x,
          coords.y,
          root
        )
      );
      coords = createDuvalPentagonConvertedCoordinates({
        point: { x: 7, y: -27 }
      });
      pentagons.push(
        drawDuvalRegion(
          getRegion(type, "T3-H"),
          "#DBC1E2",
          "T3-H",
          coords.x,
          coords.y,
          root
        )
      );
      return pentagons;

    case 3:
      coords = createDuvalPentagonConvertedCoordinates({
        point: { x: -25, y: -10 }
      });
      pentagons.push(
        drawDuvalRegion(
          getRegion(type, "T1-O"),
          "#A0D6B8",
          "T1-O",
          coords.x,
          coords.y,
          root
        )
      );
      coords = createDuvalPentagonConvertedCoordinates({
        point: { x: -12, y: -27 }
      });
      pentagons.push(
        drawDuvalRegion(
          getRegion(type, "T2-C"),
          "#9BC6D2",
          "T2-C",
          coords.x,
          coords.y,
          root
        )
      );
      coords = createDuvalPentagonConvertedCoordinates({
        point: { x: -30, y: -40 }
      });
      pentagons.push(
        drawDuvalRegion(
          getRegion(type, "T1-C/T2-O"),
          "#FFFFFF",
          "T2-O",
          coords.x,
          coords.y,
          root
        )
      );
      let lineCoordsStart = createDuvalPentagonConvertedCoordinates({
        point: { x: -25, y: -37 }
      });
      let lineCoordsEnd = createDuvalPentagonConvertedCoordinates({
        point: { x: -21.5, y: -32 }
      });
      root
        .append("line")
        .attr("stroke", "black")
        .attr("stroke-width", 1)
        .attr("x1", lineCoordsStart.x)
        .attr("y1", lineCoordsStart.y)
        .attr("x2", lineCoordsEnd.x)
        .attr("y2", lineCoordsEnd.y);
      coords = createDuvalPentagonConvertedCoordinates({
        point: { x: -15, y: -5 }
      });
      pentagons.push(
        drawDuvalRegion(
          getRegion(type, "T1-C/T2-O"),
          "#FFFFFF",
          "T1-C",
          coords.x,
          coords.y,
          root
        )
      );
      lineCoordsStart = createDuvalPentagonConvertedCoordinates({
        point: { x: -10.5, y: -5.5 }
      });
      lineCoordsEnd = createDuvalPentagonConvertedCoordinates({
        point: { x: -11, y: -11 }
      });
      root
        .append("line")
        .attr("stroke", "black")
        .attr("stroke-width", 1)
        .attr("x1", lineCoordsStart.x)
        .attr("y1", lineCoordsStart.y)
        .attr("x2", lineCoordsEnd.x)
        .attr("y2", lineCoordsEnd.y);
      coords = createDuvalPentagonConvertedCoordinates({
        point: { x: 7, y: -27 }
      });
      pentagons.push(
        drawDuvalRegion(
          getRegion(type, "T3-H"),
          "#DBC1E2",
          "T3-H",
          coords.x,
          coords.y,
          root
        )
      );
      coords = createDuvalPentagonConvertedCoordinates({
        point: { x: 0, y: -15 }
      });
      pentagons.push(
        drawDuvalRegion(
          getRegion(type, "T3-C"),
          "#FFFFFF",
          "T3-C",
          coords.x,
          coords.y,
          root
        )
      );
      lineCoordsStart = createDuvalPentagonConvertedCoordinates({
        point: { x: 3, y: -15.5 }
      });
      lineCoordsEnd = createDuvalPentagonConvertedCoordinates({
        point: { x: -1, y: -18 }
      });
      root
        .append("line")
        .attr("stroke", "black")
        .attr("stroke-width", 1)
        .attr("x1", lineCoordsStart.x)
        .attr("y1", lineCoordsStart.y)
        .attr("x2", lineCoordsEnd.x)
        .attr("y2", lineCoordsEnd.y);
      coords = createDuvalPentagonConvertedCoordinates({
        point: { x: -7, y: 27 }
      });
      pentagons.push(
        drawDuvalRegion(
          getRegion(type, "PD"),
          "#5287F3",
          "PD",
          coords.x,
          coords.y,
          root
        )
      );
      coords = createDuvalPentagonConvertedCoordinates({
        point: { x: -15, y: 16 }
      });
      pentagons.push(
        drawDuvalRegion(
          getRegion(type, "S"),
          "#ADECE1",
          "S",
          coords.x,
          coords.y,
          root
        )
      );
      coords = createDuvalPentagonConvertedCoordinates({
        point: { x: 15, y: 15 }
      });
      pentagons.push(
        drawDuvalRegion(
          getRegion(type, "D1"),
          "#D2EDFA",
          "D1",
          coords.x,
          coords.y,
          root
        )
      );
      coords = createDuvalPentagonConvertedCoordinates({
        point: { x: 15, y: -6 }
      });
      pentagons.push(
        drawDuvalRegion(
          getRegion(type, "D2"),
          "#48A6EB",
          "D2",
          coords.x,
          coords.y,
          root
        )
      );
      return pentagons;
    default:
      return pentagons;
  }
}

function drawDuvalRegion(
  points: IPoint[],
  color: string,
  label: string,
  labelx: number,
  labely: number,
  root: d3Selection<SVGElement>
) {
  const vertices = new Array<IPoint>();

  points.forEach((point) => {
    vertices.push(
      createDuvalPentagonConvertedCoordinates({
        point: point
      })
    );
  });

  const pentagon = root.append("polygon");
  pentagon
    .attr("style", `fill:${color};stroke:none`)
    .attr("id", label)
    .attr("x", labelx)
    .attr("y", labely);

  setPentagonPoints(pentagon, vertices);
  return pentagon;
}

function setPentagonPoints(
  pentagon: d3Selection<SVGElement>,
  verticees: IPoint[]
) {
  let coordsString = "";
  verticees.forEach((vertex) => {
    coordsString += vertex.x + "," + vertex.y + " ";
  });
  pentagon.attr("points", coordsString);
}

export default createDuvalPentagonRegions;
