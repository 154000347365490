// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Processing from "components/common/Processing";
import {
  WidgetErrorMessage,
  WidgetMessage
} from "components/common/widget/Widget";
import {
  RouteNotFound,
  RouterLayout
} from "core/app/components/RouterProvider";
import IVisualizationsConfiguration from "core/app/models/IVisualizationsConfiguration";
import { getUserSelector } from "core/app/reducers/AppReducer";
import { IUser } from "core/app/reducers/settings/UserReducer";
import Data, { Statuses } from "core/data/models/Data";
import getCustomerVisualizationsSelector from "core/selectors/getCustomerVisualizationsSelector";
import { loadPbiReports } from "features/detailpage/features/reports/actions/ReportsActions";
import IReport from "features/detailpage/models/IReport";
import { isEmpty } from "lodash";
import LifecycleStatus from "models/LifecycleStatus";
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import { AppDispatch } from "store";
import getPbiReportsSelector from "../../reports/selectors/getPbiReportsSelector";
import useParametersTabs from "../hooks/useParametersTabs";
import "./ParametersTabs.less";

interface IParametersTabsDataProps {
  pbiReports: Data<IReport[]>;
  visualizationsConfiguration: Data<IVisualizationsConfiguration>;
  user: Data<IUser>;
}

interface IParametersTabsActionProps {
  loadPbiReports: (assetType: string) => void;
}

interface IParametersTabsOwnProps {
  modelId: string;
  implementationId: string;
  assetId: string;
  assetType: string;
  analyticsAvailable: boolean;
  dcTransformerAvailable: boolean;
  kinectricsNetworkTransformerAvailable: boolean;
  batteryAvailable: boolean;
  cbkAvailable: boolean;
  cbkConditionMonitoringAvailable: boolean;
  lifecycleStatus: LifecycleStatus;
}

type ParametersTabsProps = IParametersTabsDataProps &
  IParametersTabsOwnProps &
  IParametersTabsActionProps;

const ParametersTabs = ({
  analyticsAvailable,
  dcTransformerAvailable,
  kinectricsNetworkTransformerAvailable,
  assetId,
  assetType,
  batteryAvailable,
  cbkAvailable,
  cbkConditionMonitoringAvailable,
  modelId,
  implementationId,
  pbiReports,
  visualizationsConfiguration,
  loadPbiReports,
  user,
  lifecycleStatus
}: ParametersTabsProps) => {
  const routes = useParametersTabs({
    assetType,
    analyticsAvailable,
    dcTransformerAvailable,
    kinectricsNetworkTransformerAvailable,
    assetId,
    batteryAvailable,
    cbkAvailable,
    cbkConditionMonitoringAvailable,
    modelId,
    implementationId,
    pbiReports,
    loadPbiReports,
    user: user.data,
    lifecycleStatus
  });

  switch (visualizationsConfiguration?.status) {
    case Statuses.Loading:
      return <Processing />;
    case Statuses.Succeeded:
      if (!isEmpty(routes)) {
        return <RouterLayout routes={routes} />;
      } else {
        return (
          <div className="no-visualization-message">
            <WidgetMessage
              iconName="info"
              iconClassName="no-visualizations"
              messageId="detail_page.widgets.no_tabs_configuration"
              messageDefault="Please provide a valid configuration for visualizations"
            />
          </div>
        );
      }
    case Statuses.NotFound:
      return <WidgetErrorMessage />;
    case Statuses.Failed:
      return <WidgetErrorMessage />;
    case Statuses.Forbidden:
      return <RouteNotFound />;
    default:
      return null;
  }
};

const mapStateToProps = (state: IState): IParametersTabsDataProps => ({
  pbiReports: getPbiReportsSelector(state),
  visualizationsConfiguration: getCustomerVisualizationsSelector(state),
  user: getUserSelector(state)
});

const mapDispatchToProps = (
  dispatch: AppDispatch
): IParametersTabsActionProps => ({
  loadPbiReports: (assetType: string) => loadPbiReports(dispatch, assetType)
});

export default connect(mapStateToProps, mapDispatchToProps)(ParametersTabs);
