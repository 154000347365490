// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Data from "core/data/models/Data";
import { IState } from "reducers/Index";
import { createSelector } from "reselect";
import { getDataKey } from "../actions/loadChart";
import ChartNames from "../models/ChartNames";
import ICircleChartData from "../models/ICircleChartData";

const getCircleChartSelector = createSelector(
  (state: IState) => (assetId: string, chartName: ChartNames) =>
    state.data[getDataKey(assetId, chartName)] as Data<ICircleChartData>,
  (charts) => charts
);

export default getCircleChartSelector;
