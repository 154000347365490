// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Data, { Statuses } from "core/data/models/Data";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IntlShape, useIntl } from "react-intl";
import IAssetDetails from "../../../models/IAssetDetails";
import IAssetTree from "../features/assettree/models/IAssetTree";
import dgaDataSourceKey from "../features/DGA/constants/dataSourceKey";
import IssueModes from "../features/issues/models/IssueModes";
import { IIssues } from "../features/issues/reducers/IssuesReducer";
import { voltageStressDataSourceKey } from "../features/VoltageStress";

interface IUseDetailPageDashboardOptions {
  assetId: string;
  rightPanelExpanded: boolean;
  assetDetails: Data<IAssetDetails>;
  assetTree: Data<IAssetTree>;
  issues: IIssues;
  loadDetailPageData: (intl: IntlShape, assetId: string) => void;
  cleanDetailPageData: (assetId: string) => void;
  expandRightPanel: () => void;
  collapseRightPanel: () => void;
  processingIssues: boolean;
  endProcessingIssues: () => void;
}

const useDetailPageDashboard = ({
  assetId,
  issues,
  assetDetails,
  assetTree,
  rightPanelExpanded,
  cleanDetailPageData,
  expandRightPanel,
  loadDetailPageData,
  collapseRightPanel,
  processingIssues,
  endProcessingIssues
}: IUseDetailPageDashboardOptions) => {
  const intl = useIntl();
  const [allowToCollapse, setAllowToCollapse] = useState(false);
  const [sidePanelExpanded, setSidePanelExpanded] = useState(false);

  const hasNewIssue = useMemo(
    () =>
      issues.newIssue &&
      issues.newMeta &&
      issues.newMeta.mode === IssueModes.Create,
    [issues.newIssue, issues.newMeta]
  );

  const hasEditedIssues = useMemo(
    () =>
      issues.activeMetas &&
      issues.activeMetas.filter((i) => i.mode !== IssueModes.View).size > 0,
    [issues]
  );

  const handleRightPanelExpanded = useCallback(() => {
    expandRightPanel();
    setSidePanelExpanded(false);
  }, [expandRightPanel, setSidePanelExpanded]);

  useEffect(() => {
    if (sidePanelExpanded) collapseRightPanel();
  }, [sidePanelExpanded, collapseRightPanel]);

  useEffect(() => {
    if (rightPanelExpanded || processingIssues) {
      setSidePanelExpanded(false);
    }
  }, [rightPanelExpanded, processingIssues]);

  useEffect(() => {
    if (!rightPanelExpanded) endProcessingIssues();
  }, [rightPanelExpanded, endProcessingIssues]);

  useEffect(() => {
    loadDetailPageData(intl, assetId);

    return () => {
      cleanDetailPageData(assetId);
      sessionStorage.removeItem(dgaDataSourceKey);
      sessionStorage.removeItem(voltageStressDataSourceKey);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId]);

  useEffect(() => {
    if (
      assetDetails?.status === Statuses.Succeeded &&
      assetTree?.status === Statuses.Succeeded
    ) {
      setSidePanelExpanded(Boolean(assetTree.data?.Components?.length));
    }
  }, [assetDetails, assetTree, setSidePanelExpanded]);

  useEffect(() => {
    setAllowToCollapse(!hasNewIssue && !hasEditedIssues);
  }, [hasNewIssue, hasEditedIssues]);

  return {
    allowToCollapse,
    sidePanelExpanded,
    setSidePanelExpanded,
    handleRightPanelExpanded
  };
};

export default useDetailPageDashboard;
