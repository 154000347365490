// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import SearchParams from "@pg/common/build/models/SearchParams";
import { routes } from "core/app/components/AppRoutes";
import { getLocaleSubstring } from "core/app/utils/checkSearchLocales";
import { capitalize } from "lodash";
import { useLocation } from "react-router";

const useNavToComponents = () => {
  const location = useLocation();
  const navigateTo = (assetId: string, componentRisk?: string) => {
    const searchParams = new SearchParams();
    searchParams.set("f_aid", JSON.stringify([assetId]));
    if (componentRisk)
      searchParams.set("f_cr", JSON.stringify([capitalize(componentRisk)]));

    const nav = {
      pathname: routes.components.pathname,
      search: `${searchParams.toString()}&${getLocaleSubstring(
        location.search
      )}`
    };

    return nav;
  };

  return { navigateTo };
};

export default useNavToComponents;
