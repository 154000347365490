// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React from "react";
import ContactWear from "@apm/widgets/build/widgets/ContactWear/components/ContactWear";

import { ITrendChart } from "common/chart/components/TrendLineChart";

import useWearI2tChart from "../hooks/useWearI2tChart";

interface IContactWearChartProps {
  title?: string;
  showWidgetCard?: boolean;
  height?: number;
  trendChart: ITrendChart;
  showModalHeaderOnly?: boolean;
}

const WearI2tChart = ({
  title,
  trendChart,
  height,
  showWidgetCard,
  showModalHeaderOnly = false
}: IContactWearChartProps) => {
  const {
    formatTooltipTitle,
    formatValue,
    formatXTick,
    lines,
    regions,
    series
  } = useWearI2tChart({ trendChart });
  return (
    <ContactWear
      title={title}
      lines={lines}
      series={series}
      regions={regions}
      formatXTick={formatXTick}
      formatValue={formatValue}
      formatTooltipTitle={formatTooltipTitle}
      showWidgetCard={showWidgetCard}
      height={height}
      showModalHeaderOnly={showModalHeaderOnly}
    />
  );
};

export default WearI2tChart;
