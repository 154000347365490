// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { IState } from "reducers/Index";

const getCustomerSelector = createSelector(
  (state: IState) => state.app.customer,
  customer => customer
);

export default getCustomerSelector;
