// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { PopoverProps } from "antd/lib/popover";
import { Popover as AntdPopover } from "antd";
import { TooltipPlacement } from "antd/lib/tooltip";
import { ReactNode, useCallback, useMemo } from "react";

type ActionType = "hover" | "focus" | "click" | "contextMenu";

interface IPopoverProps {
  children?: JSX.Element | string;
  className?: string;
  content?: ReactNode;
  onShow?: () => void;
  onHide?: () => void;
  overlayClassName?: string;
  placement?: TooltipPlacement;
  title?: string;
  trigger?: ActionType | ActionType[];
  visible?: boolean;
  destroyTooltipOnHide?: boolean;
}

export const popoverClick = "popover-click";

const Popover = ({
  children,
  className,
  content,
  onHide,
  onShow,
  overlayClassName,
  placement = "right",
  title,
  trigger = "click",
  visible,
  destroyTooltipOnHide
}: IPopoverProps) => {
  const handleVisibleChange = useCallback(
    (visible: boolean) => {
      if (visible && onShow) {
        onShow();
      } else if (!visible && onHide) {
        onHide();
      }
    },
    [onShow, onHide]
  );

  const popoverProps = useMemo(() => {
    const props: PopoverProps = {
      content,
      title,
      trigger,
      placement,
      destroyTooltipOnHide,
      onOpenChange: handleVisibleChange,
      overlayClassName: overlayClassName
    };

    // If visible prop is used directly in Antd Popover component, popover is not displayed.
    if (visible !== undefined) props.open = visible;

    return props;
  }, [
    content,
    title,
    trigger,
    placement,
    handleVisibleChange,
    overlayClassName,
    visible,
    destroyTooltipOnHide
  ]);

  return (
    <span className={`popover-container ${className}`}>
      <AntdPopover {...popoverProps}>{children}</AntdPopover>
    </span>
  );
};

export default Popover;
