// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { DatePicker, Form } from "antd";
import { isNil } from "lodash";
import { TypedValue } from "models/ITypedValue";
import dayjs from "dayjs";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FormattedDate, useIntl } from "react-intl";
import styled from "styled-components";
import {
  spacingMedium,
  spacingSmall,
  spacingXLarge
} from "styles/StyleVariables";
import isValidDateString from "utils/isValidDateString";
import IField from "../../../models/IField";
import AssetModalContext from "../contexts/AssetModalContext";
import FormContext from "../contexts/FormContext";
import { IFormItem } from "../hooks/useForms";
import IFormValues from "../models/IFormValues";
import disableFutureDates from "../utils/disableFutureDates";
import CustomSections from "./CustomSections";
import { formatDateForDatePicker } from "common/DateTime/utils/dateFormatters";

interface IInspectionProps {
  className?: string;
  formInfo: IFormItem;
}

const Inspection = ({ className, formInfo }: IInspectionProps) => {
  const intl = useIntl();

  const {
    getFormInfo,
    registerForm,
    updateFormItemTouchedInfo,
    parameters,
    parametersRestorePoint,
    validateMessages,
    changeInspectionField,
    changeInspectionDateForFields,
    isReadOnlyMode,
    handlePreloadedParametersChange
  } = useContext(AssetModalContext);

  const form = Form.useForm<IFormValues>(
    getFormInfo(formInfo.formKey)?.form
  )[0];
  const inspectionDateKey = useMemo(() => {
    return `DateOfInspection_${formInfo.formKey}`;
  }, [formInfo.formKey]);

  const [inspectionDate, setInspectionDate] = useState<Date>();

  useEffect(() => {
    registerForm(formInfo.formKey, form);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  useEffect(() => {
    if (formInfo.invalid) {
      form.validateFields();
    }
  }, [form, formInfo.invalid]);

  const fieldsInfo = useMemo(() => {
    const dataTypes: { [name: string]: TypedValue } = {};
    const fieldKeys: string[] = [];
    formInfo.formConfiguration.sections.forEach((sectionElement) => {
      if (sectionElement.fields) {
        sectionElement.fields.forEach((field: IField) => {
          dataTypes[field.fieldKey] = field.dataType;
          fieldKeys.push(field.fieldKey);
        });
      }
      if (sectionElement.sectionList)
        sectionElement.sectionList.forEach((section) => {
          section.fields.forEach((field: IField) => {
            dataTypes[field.fieldKey] = field.dataType;
            fieldKeys.push(field.fieldKey);
          });
        });
    });
    return { dataTypes, fieldKeys };
  }, [formInfo.formConfiguration.sections]);

  const handleParameterChange = useCallback(
    (
      fieldName: string,
      fieldValue: string | number | boolean | Date,
      fieldType: TypedValue
    ) => {
      changeInspectionField(
        fieldName,
        fieldType === "Bool" && !isNil(fieldValue)
          ? fieldValue === "true"
          : fieldValue,
        fieldType,
        inspectionDate
      );
      updateFormItemTouchedInfo(formInfo.formKey);
      handlePreloadedParametersChange({
        InputName: fieldName,
        Value: fieldValue,
        ValueType: fieldType,
        Timestamp: undefined
      });
    },
    [
      changeInspectionField,
      formInfo.formKey,
      handlePreloadedParametersChange,
      inspectionDate,
      updateFormItemTouchedInfo
    ]
  );

  const handleValuesChange = useCallback(
    (changedValues: Partial<IFormValues>, values: IFormValues) => {
      Object.keys(changedValues || {}).forEach((key) => {
        if (key === inspectionDateKey) {
          const inspDate = changedValues[key] as Date;
          changeInspectionDateForFields(inspDate, fieldsInfo.fieldKeys);
          setInspectionDate(inspDate);
        } else {
          handleParameterChange(
            key,
            changedValues[key],
            fieldsInfo.dataTypes[key]
          );
        }
        updateFormItemTouchedInfo(formInfo.formKey);
      });
    },
    [
      inspectionDateKey,
      updateFormItemTouchedInfo,
      formInfo.formKey,
      changeInspectionDateForFields,
      fieldsInfo.fieldKeys,
      fieldsInfo.dataTypes,
      handleParameterChange
    ]
  );

  const maxDate = useMemo(() => {
    if (!isNil(parameters)) {
      const dateValues = Object.values(parameters)
        .filter((p) => fieldsInfo.fieldKeys.includes(p.InputName))
        .filter((p) => !isNil(p) && !isNil(p.Timestamp))
        .map((p) => new Date(p.Timestamp));

      if (dateValues.length > 0)
        return new Date(Math.max.apply(null, dateValues));
    }
  }, [fieldsInfo.fieldKeys, parameters]);

  const fieldIsRequired = useCallback(
    (fieldName: string): boolean => {
      return (
        !isNil(parametersRestorePoint) &&
        !isNil(parametersRestorePoint[fieldName]?.Value)
      );
    },
    [parametersRestorePoint]
  );

  const getInitialValue = useCallback(
    (parameterName: string) => {
      if (isNil(parameters) || isNil(parameters[parameterName])) return null;

      if (
        fieldsInfo.dataTypes[parameterName] === "Bool" &&
        !isNil(parameters[parameterName].Value)
      ) {
        return parameters[parameterName].Value.toString();
      } else if (fieldsInfo.dataTypes[parameterName] === "DateTime") {
        if (isValidDateString(parameters[parameterName].Value))
          return dayjs(parameters[parameterName].Value.toString());
        else return null;
      }

      return parameters[parameterName].Value;
    },
    [parameters, fieldsInfo.dataTypes]
  );

  const datePickerModificationDateClassName =
    formInfo.formConfiguration.datePickerLabel?.defaultMessage &&
    formInfo.formConfiguration.datePickerLabel?.id &&
    formInfo.formConfiguration.showLastModificationDate &&
    "date-picker-modification-date";

  const datePickerLabel = useMemo(() => {
    if (formInfo.formConfiguration.enterDataWithCurrentDate) {
      return null;
    } else if (
      formInfo.formConfiguration.datePickerLabel?.id &&
      formInfo.formConfiguration.datePickerLabel?.defaultMessage
    ) {
      return intl.formatMessage({
        ...formInfo.formConfiguration.datePickerLabel
      });
    } else {
      return intl.formatMessage({
        id: "configuration_tool.label.date_of_inspection",
        defaultMessage: "Date of inspection"
      });
    }
  }, [
    formInfo.formConfiguration.datePickerLabel,
    formInfo.formConfiguration.enterDataWithCurrentDate,
    intl
  ]);

  return (
    <div className={className}>
      {formInfo.formConfiguration.showLastModificationDate && (
        <div className={`labeled-row ${datePickerModificationDateClassName}`}>
          <span className="labeled-label">
            {intl.formatMessage({
              id: "configuration_tool.label.last_modification_date",
              defaultMessage: "Date of last modification"
            })}
            :
          </span>
          {maxDate && (
            <span className="value">
              <FormattedDate
                value={maxDate}
                day="numeric"
                month="numeric"
                year="numeric"
                hour="numeric"
                minute="numeric"
                second="numeric"
              />
            </span>
          )}
        </div>
      )}
      <Form
        form={form}
        layout="horizontal"
        onValuesChange={handleValuesChange}
        colon={false}
        requiredMark={false}
        validateMessages={validateMessages}
      >
        {!isNil(datePickerLabel) && (
          <Form.Item
            label={datePickerLabel}
            className="date-of-inspection"
            name={inspectionDateKey}
            rules={[{ required: true }]}
          >
            <DatePicker
              disabledDate={disableFutureDates}
              format={formatDateForDatePicker(intl)}
              data-qa="InspectionDate"
              disabled={isReadOnlyMode}
            />
          </Form.Item>
        )}
        <FormContext.Provider value={form}>
          <CustomSections
            configuration={formInfo.formConfiguration.sections}
            fieldIsRequired={fieldIsRequired}
            getInitialValue={getInitialValue}
            onParameterChange={handleParameterChange}
          />
        </FormContext.Provider>
      </Form>
    </div>
  );
};

const StyledPreloadedInspection = styled(Inspection)`
  .labeled-row {
    text-align: right;
    margin-right: ${spacingMedium};
    margin-bottom: ${spacingXLarge};
  }

  .date-picker-modification-date {
    margin-top: ${spacingSmall};
    width: 400px;
    float: right;
  }

  .labeled-label {
    text-transform: uppercase;
  }
`;

export default StyledPreloadedInspection;
