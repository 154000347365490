// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { IValidationResult } from "@apm/widgets/build/widgets/DataGrid/components/DataGrid";
import { ISelectOption } from "@apm/widgets/build/widgets/DataGrid/models/IGridColumn";
import dayjs from "dayjs";
import { isEmpty, isNil } from "lodash";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { buildYup } from "schema-to-yup";
import IField from "../../../models/IField";
import ValueType from "../models/ValueType";
import isSelectOption from "../utils/isSelectOption";

const useDataValidation = () => {
  const intl = useIntl();

  const defaultMessages = useMemo(() => {
    return {
      required: intl.formatMessage({
        id: "configuration_tool.validation.value_is_required",
        defaultMessage: "Value is required"
      }),
      exclusiveMinimum: intl.formatMessage({
        id: "configuration_tool.validation.value_greater_than",
        // eslint-disable-next-line no-template-curly-in-string
        defaultMessage: "Value must be greater than ${more}"
      }),
      exclusiveMaximum: intl.formatMessage({
        id: "configuration_tool.validation.value_less_than",
        // eslint-disable-next-line no-template-curly-in-string
        defaultMessage: "Value must be less than ${less}"
      }),
      minimum: intl.formatMessage({
        id: "configuration_tool.validation.value_greater_or_equal_to",
        // eslint-disable-next-line no-template-curly-in-string
        defaultMessage: "Value must be greater or equal to ${min}"
      }),
      maximum: intl.formatMessage({
        id: "configuration_tool.validation.value_lower_or_equal_to",
        // eslint-disable-next-line no-template-curly-in-string
        defaultMessage: "Value must be lower or equal to ${max}"
      }),
      number: intl.formatMessage({
        id: "configuration_tool.validation.value_number",
        defaultMessage: "Value must be a number"
      }),
      integer: intl.formatMessage({
        id: "configuration_tool.validation.value_whole_number",
        defaultMessage: "Value must be a whole number"
      }),
      date: intl.formatMessage({
        id: "configuration_tool.validation.value_date",
        defaultMessage: "Value must be a date"
      }),
      dateFromPast: intl.formatMessage({
        id: "configuration_tool.validation.date_not_from_future",
        defaultMessage: "The date cannot be in the future"
      }),
      unsupported: intl.formatMessage({
        id: "configuration_tool.validation.unsupported_error_type",
        defaultMessage: "Unsupported error type"
      })
    };
  }, [intl]);

  const getValuesFromListValues = useCallback(
    (listValues: string[] | boolean[] | number[] | ISelectOption[]) => {
      if (isSelectOption(listValues)) return listValues.map((val) => val.value);
      return listValues.map((item) => {
        return typeof item === "boolean" ? item.toString() : item;
      });
    },
    []
  );

  const defaultSchema = useMemo(() => {
    return {
      $schema: "http://json-schema.org/draft-07/schema#",
      $id: "http://example.com/person.schema.json",
      type: "object"
    };
  }, []);

  const validateDate = useCallback(
    (
      value: ValueType,
      futureDatesDisabled: boolean = false
    ): IValidationResult => {
      if (typeof value === "boolean")
        return { valid: false, validationMessage: defaultMessages.date };

      if (!value)
        return { valid: false, validationMessage: defaultMessages.required };

      let result =
        typeof value === "object"
          ? { valid: true }
          : { valid: false, validationMessage: defaultMessages.date };

      if (result.valid && futureDatesDisabled)
        result = dayjs(value).isAfter(dayjs())
          ? { valid: false, validationMessage: defaultMessages.dateFromPast }
          : { valid: true };
      return result;
    },
    [defaultMessages]
  );

  const validateValue = useCallback(
    (
      newValue: ValueType,
      fieldConfiguration: IField,
      isRequired: boolean
    ): IValidationResult => {
      if (
        isNil(newValue) ||
        (fieldConfiguration.dataType === "String" && isEmpty(newValue))
      )
        if (isRequired)
          return { valid: false, validationMessage: defaultMessages.required };
        else return { valid: true };

      let validationResult: IValidationResult = {
        valid: true
      };

      if (!isNil(fieldConfiguration)) {
        if (fieldConfiguration.validationSchema) {
          if (
            fieldConfiguration.validationSchema.type === "integer" &&
            !Number.isInteger(newValue)
          )
            return (validationResult = {
              valid: false,
              validationMessage: defaultMessages.integer
            });

          const schema = {
            ...defaultSchema,
            properties: {
              value: { ...fieldConfiguration.validationSchema }
            }
          };
          const yupSchema = buildYup(schema, {
            errMessages: {
              value: {
                ...defaultMessages
              }
            }
          });
          try {
            yupSchema.validateSync({ value: newValue });
            validationResult = { valid: true };
          } catch (e) {
            return (validationResult = {
              valid: false,
              validationMessage: e.errors
            });
          }
          if (fieldConfiguration.disableFutureDates) {
            validationResult = validateDate(
              newValue,
              fieldConfiguration.disableFutureDates
            );
          }
        } else if (
          fieldConfiguration.inputType === "list" &&
          typeof newValue !== "object"
        ) {
          const values = getValuesFromListValues(fieldConfiguration.listValues);
          if (
            values.includes(
              typeof newValue === "boolean" ? newValue.toString() : newValue
            )
          )
            validationResult = { valid: true };
          else
            return (validationResult = {
              valid: false,
              validationMessage: defaultMessages.required
            });
        }
      }
      return validationResult;
    },
    [defaultMessages, defaultSchema, getValuesFromListValues, validateDate]
  );

  return {
    defaultMessages,
    validateDate,
    validateValue
  };
};

export default useDataValidation;
