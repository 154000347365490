import React, { useCallback, useEffect, useRef, useState } from "react";
import { Tooltip as AntdTooltip } from "antd";
import styled from "styled-components";
import _ from "lodash";

interface ITooltip {
  title: string | JSX.Element;
  children: React.ReactNode;
}

const RESIZE_THROTTLE_DELAY = 200;

const Tooltip: React.FC<ITooltip> = ({ title, children }) => {
  const [truncated, setTruncated] = useState(false);
  const ref = useRef(null);
  const tooltipMaxWidth = "66vw"; //1267.2px (2/3 for full-hd)

  const doesNeedTooltip = useCallback(() => {
    const refElementWidth = ref?.current.clientWidth;
    const parentElement = ref?.current?.parentElement;
    const parentElementPadding =
      Number(
        window
          .getComputedStyle(parentElement, null)
          .getPropertyValue("padding-left")
          .replace("px", "")
      ) +
      Number(
        window
          .getComputedStyle(parentElement, null)
          .getPropertyValue("padding-right")
          .replace("px", "")
      );
    const parentElementWidthWithoutPaddings =
      parentElement.clientWidth - parentElementPadding;

    if (refElementWidth >= parentElementWidthWithoutPaddings)
      setTruncated(true);
    else setTruncated(false);
  }, []);

  useEffect(() => {
    const windowResizeListener = _.throttle(() => doesNeedTooltip(), RESIZE_THROTTLE_DELAY);
    doesNeedTooltip();
    window.addEventListener("resize", windowResizeListener);

    return () => {
      window.removeEventListener("resize", windowResizeListener);
    };
  }, [doesNeedTooltip, truncated]);

  return (
    <AntdTooltip
      title={truncated ? title : null}
      placement="topLeft"
      overlayStyle={{ maxWidth: tooltipMaxWidth }}
    >
      <TextWrapperStyled>
        <TextStyled ref={ref}>{children}</TextStyled>
      </TextWrapperStyled>
    </AntdTooltip>
  );
};

const TextWrapperStyled = styled.div`
  display: flex;
`;

const TextStyled = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  .ant-typography {
    display: flex;
  }
`;

export default Tooltip;
