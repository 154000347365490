// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { config } from "utils/AppConfig";

const read = (messageId: string): Promise<void> =>
  new Promise((resolve, reject) => {
    const url = UrlService.getApiUrl(config.api.alarms.read);
    EndpointService.post(
      url,
      resolve,
      reject,
      [messageId],
      "application/json; charset=utf-8"
    );
  });

export default read;
