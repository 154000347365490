// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Processing from "components/common/Processing";
import { WidgetErrorMessage } from "components/common/widget/Widget";
import { RouteNotFound } from "core/app/components/RouterProvider";
import { Statuses } from "core/data/models/Data";
import { reset as resetDetailPage } from "features/detailpage/actions/DetailPageActions";
import { removeAuxiliariesData } from "features/detailpage/features/AuxiliariesTab";
import removeDuvalPentagons from "features/detailpage/features/duval/actions/removeDuvalPentagons";
import { removeWearData } from "features/detailpage/features/WearTab";
import { FC } from "react";
import { IntlShape } from "react-intl";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { IState } from "reducers/Index";
import { AppDispatch } from "store";
import loadAssetDetails from "../actions/loadAssetDetails";
import removeAssetDetails from "../actions/removeAssetDetails";
import { loadAssetTree } from "../features/assettree/actions/AssetTreeActions";
import getAssetTreeSelector from "../features/assettree/selectors/getAssetTreeSelector";
import removeDielectricMoisture from "../features/dielectric/components/SF6MoistureChart/actions/removeDielectricMoisture";
import removePressureParameters from "../features/dielectric/components/SF6Parameters/actions/removePressureParameters";
import removeDielectricPressure from "../features/dielectric/components/SF6PressureChart/actions/removeDielectricPressure";
import removeDielectricTimedPressure from "../features/dielectric/components/SF6PressureChart/actions/removeDielectricTimedPressure";
import {
  endProcessingIssues,
  loadActiveIssues,
  loadStatuses,
  loadUrgencies
} from "../features/issues/actions/IssuesActions";
import getIssuesSelector from "../features/issues/selectors/getIssuesSelector";
import { processingIssuesSelector } from "../features/issues/selectors/processingIssuesSelector";
import { removeParameters } from "../features/ParametersTab";
import {
  collapse as collapseRightPanelAction,
  expand as expandRightPanelAction
} from "../features/rightpanel/actions/RightPanelActions";
import { getExpandedSelector } from "../features/rightpanel/reducers/RightPanelReducer";
import useAssetId from "../hooks/useAssetId";
import useDetailPageDashboard from "../hooks/useDetailPageDashboard";
import getAssetDetailsSelector from "../selectors/getAssetDetailsSelector";
import Dashboard from "./Dashboard";
import "./DetailPage.less";
import DetailPageContent from "./DetailPageContent";
import SidePanel from "./SidePanel";

interface IDetailPageOwnProps {
  assetId: string;
}

type DetailPageProps = ReturnType<typeof mapStateToProps> &
  IDetailPageOwnProps &
  ReturnType<typeof mapDispatchToProps>;

const DetailPage: FC<DetailPageProps> = ({
  rightPanelExpanded,
  assetDetails,
  assetId,
  assetTree,
  issues,
  loadDetailPageData,
  cleanDetailPageData,
  expandRightPanel,
  collapseRightPanel,
  processingIssues,
  endProcessingIssues
}) => {
  const {
    allowToCollapse,
    sidePanelExpanded,
    setSidePanelExpanded,
    handleRightPanelExpanded
  } = useDetailPageDashboard({
    assetDetails,
    assetId,
    assetTree,
    cleanDetailPageData,
    expandRightPanel,
    issues,
    loadDetailPageData,
    rightPanelExpanded,
    collapseRightPanel,
    processingIssues,
    endProcessingIssues
  });

  return (
    <>
      {assetDetails?.status === Statuses.Loading && <Processing />}
      {assetDetails?.status === Statuses.Succeeded && (
        <Dashboard
          expanded={sidePanelExpanded}
          onChange={setSidePanelExpanded}
          sidePanelNode={<SidePanel />}
        >
          <DetailPageContent
            assetDetails={assetDetails?.data}
            allowToCollapse={allowToCollapse}
            assetId={assetId}
            sidePanelExpanded={sidePanelExpanded}
            rightPanelExpanded={rightPanelExpanded}
            expandRightPanel={handleRightPanelExpanded}
            collapseRightPanel={collapseRightPanel}
          />
        </Dashboard>
      )}
      {(assetDetails?.status === Statuses.NotFound ||
        assetDetails?.status === Statuses.Forbidden) && <RouteNotFound />}
      {assetDetails?.status === Statuses.Failed && <WidgetErrorMessage />}
    </>
  );
};

const mapStateToProps = (state: IState, { assetId }: IDetailPageOwnProps) => ({
  assetDetails: getAssetDetailsSelector(state)(assetId),
  rightPanelExpanded: getExpandedSelector(state),
  assetTree: getAssetTreeSelector(state),
  issues: getIssuesSelector(state),
  processingIssues: processingIssuesSelector(state)
});

const mapDispatchToProps = (dispatch: AppDispatch) => {
  const loadDetailPageData = (intl: IntlShape, assetId: string) => {
    dispatch(loadAssetDetails(assetId));
    loadAssetTree(dispatch, intl, assetId);
    loadActiveIssues(dispatch, assetId);
    loadStatuses(dispatch);
    loadUrgencies(dispatch);
  };
  const cleanDetailPageData = (assetId: string) => {
    dispatch(removeAssetDetails(assetId));
    dispatch(removeParameters(assetId));
    dispatch(removeDuvalPentagons());
    removeWearData(dispatch, assetId);
    dispatch(removeDielectricPressure());
    dispatch(removeDielectricTimedPressure());
    dispatch(removeDielectricMoisture());
    dispatch(removePressureParameters());
    removeAuxiliariesData(dispatch, assetId);
    dispatch(resetDetailPage());
  };
  const collapseRightPanel = () => {
    dispatch(collapseRightPanelAction());
  };
  const expandRightPanel = () => {
    dispatch(expandRightPanelAction());
  };

  return {
    loadDetailPageData,
    cleanDetailPageData,
    collapseRightPanel,
    expandRightPanel,
    endProcessingIssues: () => dispatch(endProcessingIssues())
  };
};

const DetailPageWithData = connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailPage);

export default () => {
  const location = useLocation();
  const assetId = useAssetId(location);
  return <DetailPageWithData assetId={assetId} />;
};
