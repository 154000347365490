// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { IState } from "reducers/Index";

import Data from "core/data/models/Data";
import { getDataKey } from "../actions/loadElectrical";
import IPartialDischargeResult from "../models/IPartialDischargeResult";

const getElectricalSelector = createSelector(
  (state: IState) => (assetId: string) => {
    return state.data[getDataKey(assetId)] as Data<IPartialDischargeResult>;
  },
  (electrical) => electrical
);

export default getElectricalSelector;
