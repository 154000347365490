// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Reducer } from "redux/index";

import { Actions } from "core/app/actions/settings/UserActions";
import Data from "core/data/models/Data";
import { IAjaxAction } from "reducers/Index";

export interface IUser {
  CustomerId: string;
  EmailAddress: string;
  Login: string;
  Name: string;
  Surname: string;
  Roles: string[];
  IsAuthenticated: boolean;
  ObjectIdentifier: string;
}

export interface IState {
  user: Data<IUser>;
}

const userReducer: Reducer<IState> = (
  state: IState,
  action: IAjaxAction<IUser>
) => {
  switch (action.type) {
    case Actions.Settings_SetUser:
      state = Object.assign({}, state, {
        user: new Data(action.request, action.data)
      } as IState);
      return state;
    default:
      return state;
  }
};

export default userReducer;
