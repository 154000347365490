// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import * as d3 from "d3";
import { IntlShape } from "react-intl";

import CoordinateService from "./CoordinateService";
import SvgService from "./SvgService";
import ISeries from "../models/ISeries";
import ISeriesState from "../models/ISeriesState";
import Svg from "../models/Svg";
import Scale from "../models/Scale";

class SeriesService {
  static drawSeries = (
    svg: Svg,
    tooltip: HTMLDivElement,
    intl: IntlShape,
    width: number,
    height: number,
    scale: Scale,
    series: ISeries[]
  ) => {
    series.forEach((s) => {
      s.points.forEach((p, i) => {
        const isLast = i === s.points.length - 1;
        const coordinate = CoordinateService.pointToCoordinate(
          p,
          width,
          height,
          scale
        );

        if (isLast) {
          SeriesService.drawStar(
            svg,
            tooltip,
            intl,
            s.id,
            p.date,
            p.value,
            p.degrees,
            s.id,
            s.color,
            coordinate.x,
            coordinate.y
          );
        } else {
          SeriesService.drawPoint(
            svg,
            tooltip,
            intl,
            s.id,
            p.date,
            p.value,
            p.degrees,
            s.id,
            s.color,
            coordinate.x,
            coordinate.y
          );
        }
      });
    });
  };

  static updateSeries = (chartId: string, seriesState: ISeriesState[]) => {
    const isAny = (filterFunction: (s: ISeriesState) => boolean) =>
      seriesState && seriesState.filter(filterFunction).length > 0;

    const setOpacity = (svg: Svg, seriesId: string, opacity: string) => {
      svg.selectAll(`.point.series-${seriesId}`).attr("fill-opacity", opacity);
    };

    const isAnyFocused = isAny((s) => s.focused);

    if (seriesState) {
      const svg = SvgService.getSvg(chartId);
      seriesState.forEach((s) => {
        if (!s.selected) {
          setOpacity(svg, s.id, "0");
        } else if (isAnyFocused && !s.focused) {
          setOpacity(svg, s.id, "0.4");
        } else {
          setOpacity(svg, s.id, "1");
        }
      });
    }
  };

  private static drawPoint = (
    svg: Svg,
    tooltip: HTMLDivElement,
    intl: IntlShape,
    seriesId: string,
    date: string,
    magnitude: number,
    phaseAngle: number,
    voltage: string,
    color: string,
    x: number,
    y: number
  ) => {
    svg
      .append("circle")
      .attr("class", `point series-${seriesId}`)
      .attr("cx", x)
      .attr("cy", y)
      .attr("fill", color)
      .attr("r", 4)
      .on("mouseover", function () {
        SeriesService.createTooltip(
          tooltip,
          intl,
          date,
          magnitude,
          phaseAngle,
          voltage
        );
        SeriesService.showTooltip(tooltip);
      })
      .on("mousemove", function () {
        const e = event as any; // eslint-disable-line no-restricted-globals
        SeriesService.moveTooltip(tooltip, e.pageX, e.pageY);
      })
      .on("mouseout", function () {
        SeriesService.hideTooltip(tooltip);
      });
  };

  private static drawStar = (
    svg: Svg,
    tooltip: HTMLDivElement,
    intl: IntlShape,
    seriesId: string,
    date: string,
    magnitude: number,
    phaseAngle: number,
    voltage: string,
    color: string,
    x: number,
    y: number
  ) => {
    svg
      .append("svg")
      .attr("height", 60)
      .attr("width", 60)
      .attr("x", x - 9)
      .attr("y", y - 9)
      .append("polygon")
      .attr(
        "points",
        "20,10 30,0 40,10 50,10 50,20 60,30 50,40 50,50 40,50 30,60 20,50 10,50 10,40 0,30 10,20 10,10"
      )
      .attr("transform", "scale(0.3 0.3)")
      .attr("class", `point series-${seriesId}`)
      .attr("fill", color)
      .on("mouseover", function () {
        SeriesService.createTooltip(
          tooltip,
          intl,
          date,
          magnitude,
          phaseAngle,
          voltage
        );
        SeriesService.showTooltip(tooltip);
      })
      .on("mousemove", function () {
        const e = event as any; // eslint-disable-line no-restricted-globals
        SeriesService.moveTooltip(tooltip, e.pageX, e.pageY);
      })
      .on("mouseout", function () {
        SeriesService.hideTooltip(tooltip);
      });
  };

  private static createTooltip = (
    tooltipRef: HTMLDivElement,
    intl: IntlShape,
    date: string,
    magnitude: number,
    phaseAngle: number,
    voltage: string
  ) => {
    const createTooltipRow = (
      tooltip: d3.Selection<HTMLDivElement, unknown, null, undefined>,
      label: string,
      value: string
    ) => {
      const row = tooltip.append("div").attr("class", "tooltip-row");
      row.append("span").attr("class", "tooltip-label").text(label);
      row.append("span").attr("class", "tooltip-value").text(value);
    };

    const tooltip = d3.select(tooltipRef);
    tooltip.selectAll("*").remove();

    const formattedDateLabel = intl.formatMessage({
      defaultMessage: "Date:",
      id: "chart.phase_angle.tooltip.date_label"
    });
    const formattedDate = intl.formatDate(date);

    const formattedMagnitudeLabel = intl.formatMessage({
      defaultMessage: "Magnitude:",
      id: "chart.phase_angle.tooltip.magnitude_label"
    });
    const formattedMagnitude = intl.formatMessage(
      {
        defaultMessage: "{value}%",
        id: "chart.phase_angle.tooltip.magnitude_value"
      },
      { value: intl.formatNumber(magnitude) }
    );

    const formattedPhaseAngleLabel = intl.formatMessage({
      defaultMessage: "Phase Angle:",
      id: "chart.phase_angle.tooltip.phase_angle_label"
    });
    const formattedPhaseAngle = intl.formatMessage(
      {
        defaultMessage: "{phaseAngle}°",
        id: "chart.phase_angle.tooltip.phase_angle_value"
      },
      {
        phaseAngle: intl.formatNumber(phaseAngle)
      }
    );

    const formattedVoltageLabel = intl.formatMessage({
      defaultMessage: "Voltage:",
      id: "chart.phase_angle.tooltip.voltage_label"
    });
    const formattedVoltage = intl.formatMessage({
      defaultMessage: voltage,
      id: `chart.phase_angle.tooltip.voltage.${voltage}`
    });

    createTooltipRow(tooltip, formattedDateLabel, formattedDate);
    createTooltipRow(tooltip, formattedMagnitudeLabel, formattedMagnitude);
    createTooltipRow(tooltip, formattedPhaseAngleLabel, formattedPhaseAngle);
    createTooltipRow(tooltip, formattedVoltageLabel, formattedVoltage);
  };

  private static showTooltip = (tooltip: HTMLDivElement) => {
    tooltip.style.visibility = "visible";
  };

  private static moveTooltip = (
    tooltip: HTMLDivElement,
    x: number,
    y: number
  ) => {
    tooltip.style.top = `${y - 8}px`;
    tooltip.style.left = `${x + 16}px`;
  };

  private static hideTooltip = (tooltip: HTMLDivElement) => {
    tooltip.style.visibility = "hidden";
  };
}

export default SeriesService;
