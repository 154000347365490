// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Icon } from "@pg/common";
import { routes } from "core/app/components/AppRoutes";
import { getLocaleSubstring } from "core/app/utils/checkSearchLocales";
import { FormattedMessage } from "react-intl";
import { NavLink, useLocation } from "react-router-dom";
import { styled } from "styled-components";

const Footer = () => {
  const location = useLocation();

  return (
    <Link
      className="explore anchor"
      data-qa="link"
      to={{
        pathname: routes.assets.pathname,
        search: `f_awl=%5B"true"%5D&${getLocaleSubstring(location.search)}`
      }}
    >
      <FormattedMessage
        id="homepage.watch_list.explore"
        defaultMessage="Explore"
      />
      <Icon name="arrow_right_alt" />
    </Link>
  );
};

const Link = styled(NavLink)`
  display: flex;
  align-items: center;
`;

export default Footer;
