// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Switch, { ISwitchItem } from "common/form/components/Switch";
import { useCallback, useEffect } from "react";

export enum IssueSwitchType {
  History = "history",
  Active = "active"
}

const items: ISwitchItem[] = [
  {
    id: IssueSwitchType.Active,
    message: {
      id: "detail_page.issues.issue_status.active",
      defaultMessage: "Active"
    }
  },
  {
    id: IssueSwitchType.History,
    message: {
      id: "detail_page.issues.issue_status.history",
      defaultMessage: "History"
    }
  }
];

interface IIssueTypeSwitchProps {
  disabled?: boolean;
  type: IssueSwitchType;
  onActiveSelected?: () => void;
  onHistorySelected?: () => void;
}

const IssueTypeSwitch = ({
  disabled,
  type,
  onActiveSelected,
  onHistorySelected
}: IIssueTypeSwitchProps) => {
  const handleSwitchChanged = useCallback(
    (id: string) => {
      if (id === IssueSwitchType.Active && onActiveSelected) {
        onActiveSelected();
      }
      if (id === IssueSwitchType.History && onHistorySelected) {
        onHistorySelected();
      }
    },
    [onActiveSelected, onHistorySelected]
  );

  useEffect(() => {
    handleSwitchChanged(type);
  }, [handleSwitchChanged, type]);

  return (
    <Switch
      defaultSelected={type}
      className="issue-type-switch"
      disabled={disabled}
      items={items}
      onChange={handleSwitchChanged}
    />
  );
};

export default IssueTypeSwitch;
