// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { IState } from "reducers/Index";

const getMapProviderSelector = createSelector(
  (state: IState) => state.app.mapProvider.data,
  mapProviderData => mapProviderData
);

export default getMapProviderSelector;
