// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import IOilSettingsSinglePowerFactor, {
  OilTestSinglePowerFactor
} from "../models/IOilSettingsSinglePowerFactor";

const chartSettings: {
  [key in OilTestSinglePowerFactor]: IOilSettingsSinglePowerFactor;
} = {
  InterfacialTension: { unit: "mN/m" },
  Moisture: { unit: "ppm" },
  PowerFactor: { unit: "%" },
  DielectricStrength: { unit: "kV" },
  AcidNumber: { unit: "mg KOH/g" },
  InhibitorContent: { unit: "%" },
  Color: { unit: "" },
  DissipationFactor: { unit: "%" },
  WaterContent: { unit: "mg/kg" }
};

export default chartSettings;
