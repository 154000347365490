// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { ISelectOption, Themes } from "common/form/components/Select";
import SelectInputContainer from "common/form/containers/SelectInputContainer";
import { IssueStatuses } from "common/issueStatus/models/IssueStatuses";
import { useCallback, useMemo } from "react";

export interface IIssueStatusSelectData {
  statuses: IssueStatuses[];
}

export interface IIssueStatusSelectOwnProps {
  disabled?: boolean;
  formName: string;
  issueStatus: IssueStatuses;
  onChange?: (status: IssueStatuses, reason?: string) => Promise<void>;
  preventValueChange: boolean;
}

interface IIssueStatusSelectProps
  extends IIssueStatusSelectData,
    IIssueStatusSelectOwnProps {}

const IssueStatusSelect = ({
  disabled,
  formName,
  issueStatus,
  onChange,
  statuses,
  preventValueChange
}: IIssueStatusSelectProps) => {
  const options = useMemo(
    () =>
      statuses.map<ISelectOption>((status) => ({
        label: {
          defaultMessage: status,
          id: `detail_page.issues.statuses.${status}`
        },
        value: status,
        hidden: status === "New"
      })),
    [statuses]
  );

  const handleChange = useCallback(
    (status: IssueStatuses) => onChange(status),
    [onChange]
  );

  return (
    <>
      <SelectInputContainer
        defaultValue={issueStatus}
        disabled={disabled}
        formName={formName}
        inputName="status"
        options={options}
        theme={Themes.Light}
        onChange={handleChange}
        preventValueChange={preventValueChange}
      />
    </>
  );
};

export default IssueStatusSelect;
