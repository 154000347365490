// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { removeReportPrefix } from "common/powerbi/PbiReport";
import { Statuses } from "core/data/models/Data";
import UrlService from "core/data/services/UrlService";
import { EventEmitter } from "events";
import IReport from "features/detailpage/models/IReport";
import $ from "jquery";
import store from "store";
import { config } from "utils/AppConfig";
import { IData } from "utils/WebService";

const changeEvent = "change";
const pbiReportsPrefix = "Dashboard";

export interface IReportState {
  showReports: boolean;
  pbiReport: IData<IReport[] | null>;
  linkedReport: IData<ILinkedReport[] | null>;
}

interface ILinkedReport {
  DisplayName: string;
  Url: string;
  Type: string;
}

class ReportsStore extends EventEmitter {
  public removeReportPrefix(name: string): string {
    return removeReportPrefix(name);
  }

  private reportState: IReportState = {
    showReports: false,
    pbiReport: {
      status: null,
      data: null,
      message: null
    },
    linkedReport: {
      status: null,
      data: null,
      message: null
    }
  };

  public addChangeListener(callback: () => void): void {
    this.on(changeEvent, callback);
  }

  public removeChangeListener(callback: () => void): void {
    this.removeListener(changeEvent, callback);
  }

  private emitChange(): void {
    this.emit(changeEvent);
  }

  private getReportsByPrefix(
    prefix: string,
    onSuccess: Function,
    onError: Function
  ): JQueryXHR {
    const url = UrlService.getApiUrl(config.api.powerbiembed.reportsByPrefix, [
      {
        name: "prefix",
        value: prefix
      }
    ]);

    return $.ajax({
      url: url,
      type: "GET",
      dataType: "json",
      cache: false,
      success: function (data: IReport[]): void {
        if (onSuccess) onSuccess(data);
      },
      error: function (xhr: JQueryXHR, status: string, err: string): void {
        if (onError) onError(url, xhr, status, err);
      }
    });
  }

  public loadPbiReports(): void {
    this.reportState.pbiReport.status = Statuses.Loading;
    this.emitChange();

    this.getReportsByPrefix(
      pbiReportsPrefix,
      (data: IReport[]) => {
        this.reportState.pbiReport.status = Statuses.Succeeded;
        this.reportState.pbiReport.message = null;
        this.reportState.pbiReport.data =
          data !== undefined && data != null
            ? data.sort((a: IReport, b: IReport): number => {
                return a.Name.localeCompare(b.Name);
              })
            : null;
        this.emitChange();
      },
      (url: string, xhr: JQueryXHR, status: string, err: string) => {
        this.reportState.pbiReport.status = Statuses.Failed;
        this.reportState.pbiReport.message = url + " " + status + " " + err;
        this.reportState.pbiReport.data = null;
        this.emitChange();
      }
    );
  }

  public loadLinkedReports(): void {
    const self = this;
    const url = UrlService.getApiUrl(
      config.api.reports.dashboardExternalReports,
      [
        {
          name: "reportType",
          value: "Dashboard"
        }
      ]
    );

    $.ajax({
      url: url,
      type: "GET",
      dataType: "json",
      cache: false,
      success: function (data: ILinkedReport[]) {
        self.reportState.linkedReport.status = Statuses.Succeeded;
        self.reportState.linkedReport.message = null;
        self.reportState.linkedReport.data =
          data !== undefined && data != null
            ? data.sort((a: ILinkedReport, b: ILinkedReport): number => {
                return a.DisplayName.localeCompare(b.DisplayName);
              })
            : null;
        self.emitChange();
      },
      error: function (xhr: JQueryXHR, status: string, err: string) {
        self.reportState.linkedReport.status = Statuses.Failed;
        self.reportState.linkedReport.message = url + " " + status + " " + err;
        self.reportState.linkedReport.data = null;
        self.emitChange();
      }
    });
  }

  public getReportState(): IReportState {
    return this.reportState;
  }

  public toggleReports(): void {
    const state = store.getState();
    const powerBiEmbeddedPremiumWorkspaceId =
      state.app.customer.data &&
      state.app.customer.data.PowerBiEmbeddedPremiumWorkspaceId;

    this.reportState.showReports = !this.reportState.showReports;

    if (this.reportState.showReports) {
      if (
        !this.reportState.pbiReport.status &&
        powerBiEmbeddedPremiumWorkspaceId
      )
        this.loadPbiReports();

      if (!this.reportState.linkedReport.status) this.loadLinkedReports();
    }

    this.emitChange();
  }

  public hideReports(): void {
    if (this.reportState.showReports) {
      this.reportState.showReports = false;
      this.emitChange();
    }
  }
}

const reportStore = new ReportsStore();

export default reportStore;
export { changeEvent };
