// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import * as React from "react";

import { WidgetEmpty } from "components/common/widget/Widget";

import MessageWithContactInfo from "./MessageWithContactInfo";
import Message from "./Message";

import "./ActiveDirectoryEmailStatus.less";

interface IActiveDirectoryEmailStatus {
  userHasEmail: boolean;
  adSupportContactUrl: string;
}

const ActiveDirectoryEmailStatus = ({
  userHasEmail,
  adSupportContactUrl
}: IActiveDirectoryEmailStatus) => {
  if (userHasEmail) {
    return <WidgetEmpty />;
  } else {
    if (adSupportContactUrl) {
      return <MessageWithContactInfo />;
    }
    return <Message />;
  }
};

export default ActiveDirectoryEmailStatus;
