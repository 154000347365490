// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";
import Data from "../../../core/data/models/Data";
import { IState } from "../../../reducers/Index";
import { dataKey } from "../actions/loadReports";
import { IReportInfo } from "../models/IReportInfo";

const getReportsSelector = createSelector(
  (state: IState) => state.data[dataKey] as Data<IReportInfo[]>,
  (reports) => reports
);

export default getReportsSelector;
