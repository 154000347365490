// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import FormContainer from "common/form/containers/FormContainer";
import TextFieldInputContainer from "common/form/containers/TextFieldInputContainer";
import FormModel from "common/form/models/Form";
import NoDataMessage from "common/messages/NoDataMessage";
import IComment from "features/detailpage/models/IComment";
import ICommentAuthor from "features/detailpage/models/ICommentAuthor";
import ICommentForm from "features/detailpage/models/ICommentForm";
import * as React from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import styled from "styled-components";
import "./IssueDetailsCommentsModal.less";

export interface IIssueDetailsCommentsModalActions {
  createComment: (comment: ICommentForm) => Promise<void>;
  hideProcessing: () => void;
  resetForm: (formName: string) => void;
  showProcessing: () => void;
  closeModal: () => void;
}

export interface IIssueDetailsCommentsModalData {}

export interface IIssueDetailsCommentsModalOwnProps {
  comments: IComment[];
  issueId: string;
  modalId: string;
  readonly: boolean;
  onCommentAdded?: () => void;
}

interface IIssueDetailsCommentsModalProps
  extends IIssueDetailsCommentsModalActions,
    IIssueDetailsCommentsModalData,
    IIssueDetailsCommentsModalOwnProps {}

const IssueDetailsCommentsModal = ({
  closeModal,
  comments,
  createComment,
  onCommentAdded,
  hideProcessing,
  readonly,
  resetForm,
  showProcessing
}: IIssueDetailsCommentsModalProps) => (
  <div className="issue-details-comments-modal">
    <CommentsWrapper>
      {!readonly && (
        <CommentForm
          closeModal={closeModal}
          onCommentAdded={onCommentAdded}
          createComment={createComment}
          hideProcessing={hideProcessing}
          resetForm={resetForm}
          showProcessing={showProcessing}
        />
      )}
      <Comments comments={comments} />
    </CommentsWrapper>
  </div>
);

interface ICommentProps {
  closeModal: () => void;
  onCommentAdded?: () => void;
  createComment: (comment: ICommentForm) => Promise<void>;
  disabled?: boolean;
  hideProcessing: () => void;
  resetForm: (formName: string) => void;
  showProcessing: () => void;
}

class CommentForm extends React.Component<ICommentProps> {
  render() {
    const {
      closeModal,
      onCommentAdded,
      createComment,
      disabled,
      hideProcessing,
      resetForm,
      showProcessing
    } = this.props;

    const formName = "DetailPage_IssuesCommentsForm";

    return (
      <FormContainer
        buttonCancelLabel={{
          defaultMessage: "Cancel",
          id: "detail_page.issues.comments.form.cancel"
        }}
        buttonSubmitLabel={{
          defaultMessage: "Add",
          id: "detail_page.issues.comments.form.submit"
        }}
        disabled={disabled}
        name="DetailPage_IssuesCommentsForm"
        onCancel={closeModal}
        onSubmit={(form: FormModel) => {
          const comment: ICommentForm = {
            Comment: form.inputs.get("comment").value
          };

          showProcessing();
          createComment(comment)
            .then(() => {
              resetForm(formName);
              hideProcessing();
              if (onCommentAdded) onCommentAdded();
            })
            .catch(hideProcessing);
        }}
      >
        <TextFieldInputContainer
          disabled={disabled}
          formName={formName}
          inputName="comment"
          label={{
            defaultMessage: "Comment",
            id: "detail_page.issues.comments.comment_label"
          }}
          maxLength={1000}
          multiline={true}
          required={true}
        />
      </FormContainer>
    );
  }
}

const CommentsWrapper = styled("div")`
  padding: 20px 10px 20px 20px;
  overflow: auto;
`;

interface ICommentsProps {
  comments: IComment[];
}

const getDisplayName = (author: ICommentAuthor) =>
  `${author.Name || ""}${author.Name && author.Surname ? " " : ""}${
    author.Surname || ""
  }` || author.AuthorId;

const Comments = ({ comments }: ICommentsProps) => {
  return comments && comments.length > 0 ? (
    <div className="comments">
      {comments.map((c: IComment, i: number) => {
        return (
          <div key={i} className="comment">
            <div className="author bold" title={c.Author.AuthorId}>
              {getDisplayName(c.Author) || (
                <FormattedMessage
                  defaultMessage="SYSTEM"
                  id="detail_page.issues_list.created_date.system"
                />
              )}
            </div>
            <div className="date default-grey-label">
              <FormattedDate
                value={new Date(c.Date)}
                day="numeric"
                month="numeric"
                year="numeric"
                hour="numeric"
                minute="numeric"
              />
            </div>
            <div className="comment-text">{c.CommentText.linkify()}</div>
          </div>
        );
      })}
    </div>
  ) : (
    <span className="comments no-comments">
      <NoDataMessage
        message={{
          defaultMessage: "No Comments",
          id: "detail_page.issues.comments.no_comments"
        }}
        withBorder
      />
    </span>
  );
};

export default IssueDetailsCommentsModal;
