// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { IActionConfig, IRow } from "common/datagrid/DataGrid";
import { IssueStatuses } from "common/issueStatus/models/IssueStatuses";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";

export interface IGridActionCallbacks {
  onStarted: () => void;
  onSucceeded: () => void;
  onFailed: () => void;
}

type callbackType = (
  row: IRow,
  onStarted: () => void,
  onSucceeded: () => void,
  onFailed: () => void
) => void;

const useIssuesGridActionConfig = (
  showCloseIssueModal: (
    assetIssueId: string,
    currentStatus: IssueStatuses,
    title: string,
    gridActionCallbacks: IGridActionCallbacks
  ) => void
) => {
  const intl = useIntl();

  const callback: callbackType = useCallback(
    (row, onStarted, onSucceeded, onFailed) => {
      const assetIssueId = row.data["AssetIssueId"];
      const currentStatus = row.data["Status"];
      const numberOfActions: number = row.data["NumberOfActions"];

      const title = intl.formatMessage({
        id: numberOfActions
          ? "issues_page.issue.close.opened_actions_confirmation"
          : "issues_page.issue.close.confirmation",
        defaultMessage: numberOfActions
          ? "This issue has opened actions, are you sure you want to close it?"
          : "Are you sure you want to close the issue?"
      });

      showCloseIssueModal(assetIssueId, currentStatus, title, {
        onStarted,
        onSucceeded,
        onFailed
      });
    },
    [intl, showCloseIssueModal]
  );

  const actions: IActionConfig[] = useMemo(
    () => [
      {
        callback,
        iconName: "clear",
        id: "close"
      }
    ],
    [callback]
  );

  return actions;
};

export default useIssuesGridActionConfig;
