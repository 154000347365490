// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Data from "core/data/models/Data";
import { IState } from "reducers/Index";
import { createSelector } from "reselect";
import dataKey from "../constants/sotDataKey";
import ISOTData from "../models/ISOTData";

const getSOTDataSelector = createSelector(
  (state: IState) => (assetId: string) =>
    state.data[dataKey(assetId)] as Data<ISOTData>,
  (sotData) => sotData
);

export default getSOTDataSelector;
