// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import useLoadActions from "features/issues/hooks/useLoadActions";
import getActionsSelector from "features/issues/selectors/getActionsSelector";
import { useEffect } from "react";
import { useAppSelector } from "store";

const useSlidePanelActions = (assetIssueId: string) => {
  const actions = useAppSelector(getActionsSelector);
  const loadActions = useLoadActions();

  useEffect(() => {
    loadActions(assetIssueId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    actions
  };
};

export default useSlidePanelActions;
