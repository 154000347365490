// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import * as pbi from "powerbi-client";
import React from "react";

import "./PbiEmbeddedReport.less";

const configTemplate: pbi.IEmbedConfiguration = {
  type: "report",
  tokenType: pbi.models.TokenType.Embed,
  accessToken: "",
  embedUrl: "",
  id: "",
  permissions: pbi.models.Permissions.Read,
  settings: {
    filterPaneEnabled: true,
    navContentPaneEnabled: true
  }
};

const service = new pbi.service.Service(
  pbi.factories.hpmFactory,
  pbi.factories.wpmpFactory,
  pbi.factories.routerFactory
);

export type PbiEmbeddedReportFilter = pbi.models.IBasicFilter;

export interface IPbiEmbeddedReportProps {
  accessToken: string;
  embedUrl: string;
  filters?: PbiEmbeddedReportFilter[];
  reportId: string;
  styleHeight?: string;
}

export default class PbiEmbeddedReport extends React.Component<IPbiEmbeddedReportProps> {
  private ref: HTMLDivElement;

  render() {
    const { styleHeight } = this.props;
    return (
      <div
        className="pbi-embedded-report"
        style={{ height: styleHeight }}
        ref={(r) => {
          this.ref = r;
        }}
      />
    );
  }

  componentDidMount() {
    this.embedReport(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps: IPbiEmbeddedReportProps) {
    const prevProps = this.props;

    if (
      prevProps.accessToken !== nextProps.accessToken ||
      prevProps.embedUrl !== nextProps.embedUrl ||
      prevProps.reportId !== nextProps.reportId
    )
      this.embedReport(nextProps);
  }

  private embedReport(props: IPbiEmbeddedReportProps) {
    const { accessToken, embedUrl, filters, reportId } = props;

    const config = Object.assign({}, configTemplate, {
      accessToken,
      embedUrl,
      id: reportId
    });

    const report = service.embed(this.ref, config) as pbi.Report;
    if (filters) {
      report.on("loaded", () => {
        report.setFilters(filters);
      });
    }
  }
}
