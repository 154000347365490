// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { default as DataSourceWidget } from "@apm/widgets/build/widgets/DataSource";
import SearchParams from "@pg/common/build/models/SearchParams";
import DataSource from "common/DuvalAnalysis/models/DataSource";
import { RouterLayout } from "core/app/components/RouterProvider";
import { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import DuvalTrianglesTab from "../../../components/analytics/DuvalTriangle/DuvalTrianglesTab";
import useDuvalLoadData from "../hooks/useDuvalLoadData";
import DuvalPentagonTab from "./DuvalPentagonTab";

interface IDuvalTabsProps {
  assetId: string;
  className: string;
  dataSources: DataSource[];
  implementationId: string;
  defaultDataSource?: DataSource;
}

const DuvalTabs = ({
  assetId,
  className,
  implementationId,
  dataSources,
  defaultDataSource
}: IDuvalTabsProps) => {
  const [dataSource, setDataSource] = useState<DataSource>(defaultDataSource);
  const intl = useIntl();
  const searchParams = useMemo(() => new SearchParams({ assetId }), [assetId]);

  const {
    duvalConfiguration,
    triangleData,
    isDuvalType3HasNote,
    showPentagons
  } = useDuvalLoadData(assetId, dataSource, implementationId);

  return (
    <div className={className}>
      {dataSources.length > 1 && (
        <div className="data-source-form">
          <DataSourceWidget
            className={className}
            activeDataSource={dataSource}
            dataSources={dataSources}
            showLabel
            translations={{
              dataSourceLabel: intl.formatMessage({
                defaultMessage: "Data source",
                id: `global.data_source`
              }),
              getDataSourceOptionTranslation: (dataSource: string) =>
                intl.formatMessage({
                  defaultMessage: dataSource,
                  id: `detail_page.widgets.analytics.transformers.duval.data_source.${
                    dataSource === DataSource.Online ? "default" : dataSource
                  }`
                })
            }}
            onDataSourceChange={(newDataSource: DataSource) => {
              setDataSource(newDataSource);
            }}
          />
        </div>
      )}
      <RouterLayout
        routes={[
          {
            id: "Assets-DetailPage-Parameters-Duval-Triangles",
            route: "Triangles",
            displayName: intl.formatMessage({
              defaultMessage: "Triangles",
              id: "detail_page.widgets.analytics.transformers.duval_triangles"
            }),
            to: {
              pathname: "/Assets/DetailPage/Parameters/Duval/Triangles",
              search: searchParams.toString()
            },
            element: (
              <DuvalTrianglesTab
                duvalConfiguration={duvalConfiguration}
                triangleData={triangleData}
                isDuvalType3HasNote={isDuvalType3HasNote}
                dataSource={dataSource}
              />
            )
          },
          ...(showPentagons
            ? [
                {
                  id: "Assets-DetailPage-Parameters-Duval-Pentagons",
                  route: "Pentagons",
                  displayName: intl.formatMessage({
                    defaultMessage: "Pentagons",
                    id: "detail_page.widgets.analytics.transformers.duval_pentagons"
                  }),
                  to: {
                    pathname: "/Assets/DetailPage/Parameters/Duval/Pentagons",
                    search: searchParams.toString()
                  },
                  element: (
                    <DuvalPentagonTab
                      assetId={assetId}
                      dataSource={dataSource}
                    />
                  )
                }
              ]
            : [])
        ]}
      />
    </div>
  );
};

export default styled(DuvalTabs)`
  .data-source-form {
    display: flex;
    padding-right: 10px;
    justify-content: flex-end;
    margin-bottom: -74px;

    .duval-tabs {
      padding: 27px 10px 15px 15px;
      .data-source-label {
        align-self: center;
      }
    }
  }
`;
