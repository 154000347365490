// Copyright 2016-2024 Hitachi Energy. All rights reserved.

/// <reference path="../types/intl-pluralrules.d.ts" />

import { shouldPolyfill } from "@formatjs/intl-pluralrules/should-polyfill";

const polyfillPluralRules = (locale: string): Promise<any> => {
  if (!shouldPolyfill()) return Promise.resolve();
  return Promise.all([
    import("@formatjs/intl-pluralrules/polyfill"),
    import("@formatjs/intl-pluralrules/locale-data/es"),
    import("@formatjs/intl-pluralrules/locale-data/pl"),
    import("@formatjs/intl-pluralrules/locale-data/zh"),
    import("@formatjs/intl-pluralrules/locale-data/ja"),
    import("@formatjs/intl-pluralrules/locale-data/pt"),
    import("@formatjs/intl-pluralrules/locale-data/de"),
    import("@formatjs/intl-pluralrules/locale-data/en")
  ]);
};

export default polyfillPluralRules;
