// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import { TooltipPlacement } from "antd/lib/tooltip";
import Popover, { popoverClick } from "common/popover/Popover";
import { loadImportanceScoreFamilyInfoAction } from "features/detailpage/features/familyinfo/actions/FamilyInfoActions";
import FamilyInfoContainer from "features/detailpage/features/familyinfo/containers/FamilyInfoContainer";
import { useCallback } from "react";
import { useAppDispatch } from "store";

interface IFamilyInfoImportanceScoreIconProps {
  assetId: string;
  placement?: TooltipPlacement;
}

const ImportanceScoreFamilyIcon = ({
  assetId,
  placement
}: IFamilyInfoImportanceScoreIconProps) => {
  const dispatch = useAppDispatch();

  const handleShow = useCallback(() => {
    dispatch(loadImportanceScoreFamilyInfoAction(assetId));
  }, [assetId, dispatch]);

  return (
    <Popover
      content={<FamilyInfoContainer assetId={assetId} />}
      onShow={handleShow}
      overlayClassName="family-info-importance-popover family-info-popover"
      placement={placement}
      destroyTooltipOnHide
    >
      <span>
        <Icon
          className={`family-info-popover-icon ${popoverClick}`}
          dataQa="header-importance-family-icon"
          name="speed"
          size="sm"
        />
      </span>
    </Popover>
  );
};

export default ImportanceScoreFamilyIcon;
