// Copyright 2016-2024 Hitachi Energy. All rights reserved.

enum EGasStandard {
  IEEE = "IEEE",
  IEC = "IEC",
  ETRA_MINERAL = "ETRA_MINERAL",
  IEEE_SILICONE = "IEEE_SILICONE",
  IEEE_ESTERS = "IEEE_ESTERS"
}

export default EGasStandard;
