// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Button } from "antd";
import { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { IColumn } from "./DataGrid";

interface IExportToExcelButtonProps {
  disabled?: boolean;
  onClick?: (columns: IColumn[]) => void;
  columns?: IColumn[];
}

const ExportToExcelButton = ({
  disabled,
  onClick,
  columns
}: IExportToExcelButtonProps) => {
  const handleExportDataClick = useCallback(() => {
    onClick?.(columns);
  }, [columns, onClick]);

  return (
    <Button
      className="light"
      disabled={disabled}
      type="link"
      onClick={handleExportDataClick}
      data-qa="export-excel-button"
    >
      <FormattedMessage
        defaultMessage="Export to Excel"
        id="data_grid.footer.export.excel"
      />
    </Button>
  );
};

export default ExportToExcelButton;
