// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Route, RouterSwitch } from "core/app/components/RouterProvider";
import Alarms from "features/Alarms";
import AssetQuery from "features/AssetQuery";
import AssetRiskChanges from "features/AssetRiskChanges";
import AssetRiskDashboard from "features/assetriskdashboard/components/AssetRiskDashboard";
import AssetRiskSummary from "features/AssetRiskSummary";
import ComponentRiskSummary from "features/ComponentRiskSummary";
import Components from "features/Components";
import DataIntegration from "features/dataintegration/DataIntegration";
import DetailPage from "features/detailpage/components/DetailPage";
import FamilyAnalytics from "features/familyanalytics/components/FamilyAnalytics";
import Issues from "features/issues/components/Issues";
import Licenses from "features/Licenses";
import LicensesFile from "features/LicensesFile";
import Locations from "features/Locations";
import MainDashboard from "features/MainDashboard";
import NotificationsContainer from "features/notifications/containers/NotificationsContainer";
import Reports from "features/Reports";
import RiskMapDashboard from "features/RiskMapDashboard";
import { first } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import { useAppSelector } from "store";
import { getUserSelector } from "../reducers/AppReducer";
import { IUser } from "../reducers/settings/UserReducer";
import AuthorizationService from "../services/AuthorizationService";
import { UserRoles } from "./auth/Authorization";
import { useFeature, VisualizationKeys } from "./FeatureToggle";
import { useLocation } from "react-router";
import checkSearchLocales from "../utils/checkSearchLocales";

export const routes = {
  alarms: {
    route: "/Alarms",
    pathname: "/Alarms"
  },
  assets: {
    route: "/Assets/*",
    pathname: "/Assets"
  },
  components: {
    route: "/Components/*",
    pathname: "/Components"
  },
  dashboard: {
    route: "/Dashboard",
    pathname: "/Dashboard"
  },
  dashboardAssetRiskChanges: {
    route: "/Dashboard/AssetRiskChanges",
    pathname: "/Dashboard/AssetRiskChanges"
  },
  dashboardAssetRiskDashboard: {
    route: "/Dashboard/AssetRiskDashboard",
    pathname: "/Dashboard/AssetRiskDashboard"
  },
  dashboardAssetRiskSummary: {
    route: "/Dashboard/AssetRiskSummary",
    pathname: "/Dashboard/AssetRiskSummary"
  },
  dashboardComponentRiskSummary: {
    route: "/Dashboard/ComponentRiskSummary",
    pathname: "/Dashboard/ComponentRiskSummary"
  },
  dashboardMain: {
    route: "/Dashboard/MainDashboard",
    pathname: "/Dashboard/MainDashboard"
  },
  dashboardSpatialView: {
    route: "/Dashboard/SpatialView",
    pathname: "/Dashboard/SpatialView"
  },
  detailPage: {
    route: "/Assets/DetailPage/*",
    pathname: "/Assets/DetailPage"
  },
  familyAnalytics: {
    route: "/FamilyAnalytics",
    pathname: "/FamilyAnalytics"
  },
  issues: {
    route: "/Issues",
    pathname: "/Issues"
  },
  licenses: {
    route: "/Licenses",
    pathname: "/Licenses"
  },
  licensesFile: {
    route: "/Licenses/:licenseType/:fileName",
    pathname: "/Licenses/:licenseType/:fileName"
  },
  locations: {
    route: "/Locations",
    pathname: "/Locations"
  },
  modelAdmin: {
    route: "/ModelAdmin",
    pathname: "/ModelAdmin"
  },
  notifications: {
    route: "/Notifications",
    pathname: "/Notifications"
  },
  reports: {
    route: "/Reports/:reportName",
    pathname: "/Reports/:reportName"
  }
};

const AppRoutes = () => {
  const { isAvailable } = useFeature();
  const user = useAppSelector(getUserSelector);
  const location = useLocation();

  useEffect(() => {
    checkSearchLocales(location);
  }, [location, location.search]);

  const getAccessedRoutes = useCallback(
    (routes: Route[], userRoles: UserRoles[]) => {
      const hasAccess = AuthorizationService.isAuthorized(
        user.data as IUser,
        userRoles
      );
      return hasAccess ? routes : [];
    },
    [user.data]
  );

  const allRoutes = useMemo<Route[]>(() => {
    const getId = (pathname: string) => pathname.replace(/\//g, "-");

    return [
      ...getAccessedRoutes(
        [
          {
            id: getId(routes.dashboardMain.pathname),
            route: routes.dashboardMain.route,
            to: routes.dashboardMain.pathname,
            element: <MainDashboard />
          },
          {
            id: getId(routes.dashboardSpatialView.pathname),
            route: routes.dashboardSpatialView.route,
            to: routes.dashboardSpatialView.pathname,
            element: <RiskMapDashboard />
          },
          {
            id: getId(routes.dashboardAssetRiskChanges.pathname),
            route: routes.dashboardAssetRiskChanges.route,
            to: routes.dashboardAssetRiskChanges.pathname,
            element: <AssetRiskChanges />
          },
          {
            id: getId(routes.dashboardAssetRiskDashboard.pathname),
            route: routes.dashboardAssetRiskDashboard.route,
            to: routes.dashboardAssetRiskDashboard.pathname,
            element: <AssetRiskDashboard />
          },
          {
            id: getId(routes.dashboardAssetRiskSummary.pathname),
            route: routes.dashboardAssetRiskSummary.route,
            to: routes.dashboardAssetRiskSummary.pathname,
            element: <AssetRiskSummary />
          },
          {
            id: getId(routes.dashboardComponentRiskSummary.pathname),
            route: routes.dashboardComponentRiskSummary.route,
            to: routes.dashboardComponentRiskSummary.pathname,
            element: <ComponentRiskSummary />
          }
        ],
        [
          UserRoles.Administrator,
          UserRoles.Engineer,
          UserRoles.LimitedEngineer,
          UserRoles.ViewerEngineer
        ]
      ),
      ...getAccessedRoutes(
        [
          {
            id: getId(routes.modelAdmin.pathname),
            route: routes.modelAdmin.route,
            to: routes.modelAdmin.pathname,
            element: <DataIntegration />
          }
        ],
        [UserRoles.Administrator]
      ),
      {
        id: getId(routes.notifications.pathname),
        route: routes.notifications.route,
        to: routes.notifications.pathname,
        element: <NotificationsContainer />
      },
      {
        id: getId(routes.detailPage.pathname),
        route: routes.detailPage.route,
        to: routes.detailPage.pathname,
        element: <DetailPage />
      },
      {
        id: getId(routes.assets.pathname),
        route: routes.assets.route,
        to: routes.assets.pathname,
        element: <AssetQuery />
      },
      {
        id: getId(routes.components.pathname),
        route: routes.components.route,
        to: routes.components.pathname,
        element: <Components />
      },
      {
        id: getId(routes.locations.pathname),
        route: routes.locations.route,
        to: routes.locations.pathname,
        element: <Locations />
      },
      {
        id: getId(routes.issues.pathname),
        route: routes.issues.route,
        to: routes.issues.pathname,
        element: <Issues />
      },
      {
        id: getId(routes.familyAnalytics.pathname),
        route: routes.familyAnalytics.route,
        to: routes.familyAnalytics.pathname,
        element: <FamilyAnalytics />
      },
      {
        id: getId(routes.reports.pathname),
        route: routes.reports.route,
        to: routes.reports.pathname,
        element: <Reports />
      },
      {
        id: getId(routes.licenses.pathname),
        route: routes.licenses.route,
        to: routes.licenses.pathname,
        element: <Licenses />
      },
      {
        id: getId(routes.licensesFile.pathname),
        route: routes.licensesFile.route,
        to: routes.licensesFile.pathname,
        element: <LicensesFile />
      },
      isAvailable(VisualizationKeys.AlarmsPage_Tab)
        ? {
            id: getId(routes.alarms.pathname),
            route: routes.alarms.route,
            to: routes.alarms.pathname,
            element: <Alarms />
          }
        : undefined
    ].filter(Boolean);
  }, [getAccessedRoutes, isAvailable]);

  const defaultRoute = useMemo(() => {
    const accessedRoutes = getAccessedRoutes(
      allRoutes.filter(
        (r) =>
          r.route === routes.dashboardMain.route ||
          r.route === routes.assets.route
      ),
      [
        UserRoles.Administrator,
        UserRoles.Engineer,
        UserRoles.LimitedEngineer,
        UserRoles.ViewerEngineer
      ]
    );

    return first(accessedRoutes);
  }, [getAccessedRoutes, allRoutes]);

  return (
    <RouterSwitch
      defaultRoute={{
        from: "/",
        route: defaultRoute
      }}
      routes={allRoutes}
      redirects={[
        {
          path: "/Assets/:assetId",
          to: `${routes.detailPage.pathname}?assetId=:assetId`
        }
      ]}
    />
  );
};

export default AppRoutes;
