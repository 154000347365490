// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { IState } from "reducers/Index";

const getRiskTrendSimulationConfigSelector = createSelector(
  (state: IState) => state.detailPage.riskSimulationConfig,
  riskSimulationConfig => riskSimulationConfig
);

export default getRiskTrendSimulationConfigSelector;
