// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React from "react";
import { FormattedMessage } from "react-intl";

import TextFieldInputContainer from "common/form/containers/TextFieldInputContainer";
import IIssue from "features/detailpage/features/issues/models/IIssue";
import IIssueMeta from "features/detailpage/features/issues/models/IIssueMeta";
import IssueModes from "features/detailpage/features/issues/models/IssueModes";
import { issueDetailsFormName } from "./IssueDetails";

interface IIssueDetailsConditionProps {
  issue: IIssue;

  meta: IIssueMeta;
}

const IssueDetailsCondition = ({
  issue,
  meta
}: IIssueDetailsConditionProps) => {
  const condition = issue.ConditionText;

  return meta.mode === IssueModes.View ? (
    <div>
      <div className="default-grey-label">
        <FormattedMessage
          defaultMessage="Condition"
          id="detail_page.issues.issue_details.condition"
        />
      </div>
      <div className="value">{condition}</div>
    </div>
  ) : meta.mode === IssueModes.Create || meta.mode === IssueModes.Edit ? (
    <TextFieldInputContainer
      defaultValue={condition}
      formName={issueDetailsFormName}
      inputName="condition"
      label={{
        defaultMessage: "Condition",
        id: "detail_page.issues.issue_details.form.condition"
      }}
      maxLength={10000}
      multiline={true}
      required={true}
    />
  ) : null;
};

export default IssueDetailsCondition;
