// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { SelectedFilters } from "common/FilterBar";
import { IDataEndpoint, IRow, IRowData } from "common/datagrid/DataGrid";
import UrlService from "core/data/services/UrlService";
import { AssetRiskEnum } from "models/AssetRisks";
import { useMemo, useState } from "react";
import { config } from "utils/AppConfig";
import useComponentSummaryColumnsConfig from "./useComponentSummaryColumnsConfig";

interface IComponentSummaryGridOptions {
  filters: SelectedFilters;
  handleRiskClick: (row: IRow<IRowData>, risk: AssetRiskEnum) => void;
}

const useComponentSummaryGrid = ({
  filters,
  handleRiskClick
}: IComponentSummaryGridOptions) => {
  const [rowsTotal, setRowsTotal] = useState<number>();
  const getColumnsConfig = useComponentSummaryColumnsConfig({
    handleRiskClick
  });

  const dataEndpoint: IDataEndpoint = useMemo(
    () => ({
      url: UrlService.getApiUrl(config.api.assetRisk.componentRiskSummary),
      type: "POST",
      content: {
        search: filters.search,
        filters: filters.selects
      },
      onDataLoaded: (total) => {
        setRowsTotal(total);
      }
    }),
    [filters]
  );

  return {
    dataEndpoint,
    rowsTotal,
    getColumnsConfig
  };
};

export default useComponentSummaryGrid;
