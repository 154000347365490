// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { PbiEmbeddedReportFilter } from "common/powerbi/PbiEmbeddedReport";
import { models } from "powerbi-client";
import { config } from "utils/AppConfig";

const buildAssetFilter = (assetId: string): PbiEmbeddedReportFilter[] => {
  const assetIds: string[] = [assetId];
  const filters: PbiEmbeddedReportFilter[] = [
    {
      operator: "In",
      filterType: models.FilterType.Basic,
      values: assetIds,
      $schema: null,
      target: {
        table: config.powerbi.reports.views.assetParameters,
        column: config.powerbi.reports.columns.assetId
      }
    }
  ];

  return filters;
};

export default buildAssetFilter;
