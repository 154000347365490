// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React from "react";

import "./Issue.less";

interface IIssueProps {
  value: string;
}

const Issue = ({ value }: IIssueProps) => {
  return <div className="column issue">{value}</div>;
};

export default Issue;
