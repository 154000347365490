// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import BBChart from "common/chart/components/BBChart";
import { FC } from "react";
import useOpenIssuesChart from "../hooks/useOpenIssuesChart";
import IOpenIssueInfo from "../models/IOpenIssuesInfo";
interface IOpenIssueChartProps {
  openIssues: IOpenIssueInfo;
}

const OpenIssuesChart: FC<IOpenIssueChartProps> = ({ openIssues }) => {
  const { chartConfig, nodeRef, handleChartRendered } = useOpenIssuesChart({
    openIssues
  });

  return (
    <div ref={nodeRef}>
      <BBChart configuration={chartConfig} onRendered={handleChartRendered} />
    </div>
  );
};

export default OpenIssuesChart;
