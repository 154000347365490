// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Chart } from "billboard.js";
import BBChart from "common/chart/components/BBChart";
import AgingOperationsBarChartLegend from "features/detailpage/features/aging/components/AgingOperationsBarChartLegend";
import useAgingOperationsConfiguration from "features/detailpage/features/aging/hooks/useAgingOperationsConfiguration";
import * as React from "react";
import "./AgingOperationsBarChart.less";

interface IAgingOperationsBarChartProps {
  agingOperations: number;
  warningThreshold: number;
  alarmThreshold: number;
  isFaultOperations?: boolean;
}

const AgingOperationsBarChart = ({
  agingOperations,
  warningThreshold,
  alarmThreshold,
  isFaultOperations = false
}: IAgingOperationsBarChartProps) => {
  const { configuration, legendData } = useAgingOperationsConfiguration({
    agingOperations,
    warningThreshold,
    alarmThreshold
  });
  let chartApi: Chart;

  return (
    <>
      <div
        className={`${
          isFaultOperations
            ? "aging-operations-chart--fault"
            : "aging-operations-chart--total"
        } aging-operations-chart`}
      >
        <BBChart
          configuration={configuration}
          onRendered={(chart) => (chartApi = chart)}
        />
      </div>
      <AgingOperationsBarChartLegend
        plots={legendData}
        series={"Aging Total Operations"}
        onFocus={(series: string, id: string) => {
          chartApi.focus(id);
        }}
        onRevert={(series: string) => {
          chartApi.revert();
        }}
      />
    </>
  );
};

export default AgingOperationsBarChart;
