// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { IState } from "reducers/Index";

const getDegradedToYellowSelector = createSelector(
  (state: IState) => state.notifications.modelInput.data,
  (state: IState) => state.notifications.notificationsEnabled,
  (state: IState) => state.notifications.allDegradedChecked,
  (data, notificationsEnabled, allDegradedChecked) =>
    data && notificationsEnabled
      ? allDegradedChecked || data.DegradedToYellow
      : false
);

export default getDegradedToYellowSelector;
