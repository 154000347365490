// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import PhaseAngleChart, {
  IConfiguration
} from "common/chart/components/PhaseAngleChart";
import Processing from "components/common/Processing";
import Data, { Statuses } from "core/data/models/Data";
import { isNil } from "lodash";
import * as React from "react";
import { useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import IOnlineBushingData from "../models/IOnlineBushingData";
import IReading from "../models/IReading";
import "./BushingVisualization.less";
import BushingVisualizationFailed from "./BushingVisualizationFailed";
import BushingVisualizationNoData from "./BushingVisualizationNoData";
import BushingWeeklyChart from "./BushingWeeklyChart";

export const highVoltageSeriesColor = "#7C71A7";
export const lowVoltageSeriesColor = "#47A092";

interface IBushingVisualizationProps {
  onlineBushingData: Data<IOnlineBushingData>;
}

const BushingVisualization: React.FC<IBushingVisualizationProps> = ({
  onlineBushingData
}) => {
  switch (onlineBushingData.status) {
    case Statuses.Loading:
      return <BushingVisualizationProcessing />;
    case Statuses.Succeeded:
      return (
        <BushingVisualizationSucceeded
          onlineBushingData={onlineBushingData.data}
        />
      );
    case Statuses.Failed:
      return <BushingVisualizationFailed />;
    case Statuses.NotFound:
      return <BushingVisualizationNoData />;
    default:
      return null;
  }
};

const BushingVisualizationProcessing = () => (
  <div className="bushing-visualization container">
    <Processing />
  </div>
);

interface IBushingVisualizationSucceededProps {
  onlineBushingData: IOnlineBushingData;
}

const BushingVisualizationSucceeded = ({
  onlineBushingData
}: IBushingVisualizationSucceededProps) => {
  const getPoints = useCallback(
    (readings: IReading[]) =>
      readings
        ?.filter(({ PhaseAngle }) => !isNil(PhaseAngle))
        .map((reading) => ({
          degrees: reading.PhaseAngle,
          value: reading.Magnitude,
          date: reading.Date
        })),
    []
  );

  const hvReadingWithMagnitude = useMemo(
    () =>
      onlineBushingData?.HighVoltageSumCurrentReadings?.filter(
        ({ Magnitude }) => !isNil(Magnitude)
      ),
    [onlineBushingData?.HighVoltageSumCurrentReadings]
  );

  const lvReadingWithMagnitude = useMemo(
    () =>
      onlineBushingData?.LowVoltageSumCurrentReadings?.filter(
        ({ Magnitude }) => !isNil(Magnitude)
      ),
    [onlineBushingData?.LowVoltageSumCurrentReadings]
  );

  const configuration: IConfiguration = useMemo(
    () =>
      onlineBushingData && {
        series: [
          {
            id: "LowVoltage",
            points: getPoints(lvReadingWithMagnitude),
            color: lowVoltageSeriesColor
          },
          {
            id: "HighVoltage",
            points: getPoints(hvReadingWithMagnitude),
            color: highVoltageSeriesColor
          }
        ],
        alertValue: onlineBushingData.SumCurrentAlertLevel,
        warningValue: onlineBushingData.SumCurrentWarningLevel
      },
    [
      getPoints,
      hvReadingWithMagnitude,
      lvReadingWithMagnitude,
      onlineBushingData
    ]
  );

  const filteredOnlineBushingData: IOnlineBushingData = useMemo(
    () => ({
      ...onlineBushingData,
      HighVoltageSumCurrentReadings: hvReadingWithMagnitude,
      LowVoltageSumCurrentReadings: lvReadingWithMagnitude
    }),
    [hvReadingWithMagnitude, lvReadingWithMagnitude, onlineBushingData]
  );

  const drawPhaseAngleChart = useMemo(
    () =>
      configuration.series[0].points.length > 0 ||
      configuration.series[1].points.length > 0,
    [configuration.series]
  );

  const drawBushingWeeklyChart = useMemo(
    () =>
      lvReadingWithMagnitude.length > 0 || hvReadingWithMagnitude.length > 0,
    [hvReadingWithMagnitude.length, lvReadingWithMagnitude.length]
  );

  return drawPhaseAngleChart || drawBushingWeeklyChart ? (
    <div className="bushing-visualization container">
      <div className="bootstrap-row">
        <div className="col-10 chart" data-qa="sum-current-phase-chart">
          <div className="chart-name">
            <span className="bold">
              <FormattedMessage
                defaultMessage="Sum Current & Phase Angle"
                id="detail_page.parameters.bushing_visualization.phase_angle_chart_title"
              />
            </span>
          </div>
          {drawPhaseAngleChart ? (
            <PhaseAngleChart configuration={configuration} />
          ) : (
            <BushingVisualizationNoData />
          )}
        </div>
        <div className="col-14 chart" data-qa="sum-current-weekly-chart">
          <div className="chart-name">
            <FormattedMessage
              defaultMessage="{sumCurrent} - {weekly}"
              id="detail_page.parameters.bushing_visualization.sum_current_chart_title"
              values={{
                sumCurrent: (
                  <span className="bold">
                    <FormattedMessage
                      defaultMessage="Sum Current"
                      id="detail_page.parameters.bushing_visualization.sum_current"
                    />
                  </span>
                ),
                weekly: (
                  <FormattedMessage
                    defaultMessage="weekly"
                    id="detail_page.parameters.bushing_visualization.weekly"
                  />
                )
              }}
            />
          </div>
          {drawBushingWeeklyChart ? (
            <BushingWeeklyChart onlineBushingData={filteredOnlineBushingData} />
          ) : (
            <BushingVisualizationNoData />
          )}
        </div>
      </div>
    </div>
  ) : (
    <BushingVisualizationNoData />
  );
};

export default BushingVisualization;
