// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { JellyBeans } from "@apm/widgets/build/components/JellyBeans";
import { SearchParams } from "@pg/common";
import { IRow, IRowData } from "common/datagrid/DataGrid";
import { routes } from "core/app/components/AppRoutes";
import { useAppNavigate } from "core/app/components/RouterProvider";
import { capitalize } from "lodash";
import { AssetRiskEnum } from "models/AssetRisks";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import {
  colorRiskHigh,
  colorRiskLow,
  colorRiskMedium,
  colorRiskUnknown
} from "styles/ColorVariables";
import { spacingXXLarge } from "styles/StyleVariables";

interface IAssetRisksProps {
  row: IRow<IRowData>;
}

const ComponentsRisks = ({ row }: IAssetRisksProps) => {
  const intl = useIntl();
  const navigate = useAppNavigate();

  const handleJellyBeanItemClick = useCallback(
    (riskId: string) => {
      const searchParams = new SearchParams();
      searchParams.set("f_aid", JSON.stringify([row.data["AssetId"]]));
      if (riskId)
        searchParams.set("f_cr", JSON.stringify([capitalize(riskId)]));

      navigate({
        pathname: routes.components.pathname,
        search: searchParams.toString()
      });
    },
    [navigate, row.data]
  );

  return (
    <Wrapper data-qa="risk-bar">
      <JellyBeans
        formatValue={intl.formatNumber}
        jellyBeans={[
          {
            values: [
              {
                name: AssetRiskEnum.HIGH,
                color: colorRiskHigh,
                value: row.data["HighRiskCount"],
                onClick: handleJellyBeanItemClick
              },
              {
                name: AssetRiskEnum.MEDIUM,
                color: colorRiskMedium,
                value: row.data["MediumRiskCount"],
                onClick: handleJellyBeanItemClick
              },
              {
                name: AssetRiskEnum.LOW,
                color: colorRiskLow,
                value: row.data["LowRiskCount"],
                onClick: handleJellyBeanItemClick
              },
              {
                name: AssetRiskEnum.UNKNOWN,
                color: colorRiskUnknown,
                value: row.data["UnknownRiskCount"],
                onClick: handleJellyBeanItemClick
              }
            ]
          }
        ]}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 0 ${spacingXXLarge};
`;

export default ComponentsRisks;
