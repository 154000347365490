// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import LifecycleStatus from "models/LifecycleStatus";

const isExactLifecycleStatus = (
  lifecycleStatus: LifecycleStatus,
  compareStatus: LifecycleStatus
): boolean => lifecycleStatus === compareStatus;

export default isExactLifecycleStatus;
