// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import ParameterHistogram from "./components/ParameterHistogram";
import loadParameterHistogram, {
  dataKey
} from "./actions/loadParameterHistogram";
import removeParametersHistogram from "./actions/removeParametersHistogram";

export default ParameterHistogram;
export { loadParameterHistogram, removeParametersHistogram, dataKey };
