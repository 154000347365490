// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import ThermalChart from "@apm/widgets/build/widgets/ThermalChart";
import Processing from "components/common/Processing";
import {
  WidgetErrorMessage,
  WidgetNoDataMessage
} from "components/common/widget/Widget";
import Data, { Statuses } from "core/data/models/Data";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import { AppDispatch } from "store";
import styled from "styled-components";
import { spacingXXLarge } from "styles/StyleVariables";
import loadThermal from "../actions/loadThermal";
import removeThermal from "../actions/removeThermal";
import useThermalVisualizationTab from "../hooks/useThermalVisualizationTab";
import IThermal from "../models/IThermal";
import getThermalSelector from "../selectors/getThermalSelector";

interface IThermalVisualizationTabActions {
  loadThermal: () => void;
  removeThermal: () => void;
}

interface IThermalVisualizationTabData {
  thermal: Data<IThermal>;
}

interface IThermalVisualizationTabOwnProps {
  assetId: string;
}

interface IThermalVisualizationTabProps
  extends IThermalVisualizationTabOwnProps,
    IThermalVisualizationTabActions,
    IThermalVisualizationTabData {
  className?: string;
}

const ThermalVisualizationTab = ({
  className,
  loadThermal,
  removeThermal,
  thermal
}: IThermalVisualizationTabProps) => {
  const intl = useIntl();

  const {
    lines,
    series,
    formatXTick,
    formatYTick,
    formatTooltipTitle,
    sortByHiddenThenByName
  } = useThermalVisualizationTab(thermal);

  useEffect(() => {
    loadThermal();

    return () => {
      removeThermal();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={className}>
      {thermal?.status === Statuses.Loading && <Processing />}
      {thermal?.status === Statuses.Succeeded && (
        <ThermalChart
          title={intl.formatMessage({
            defaultMessage: "Thermal Model",
            id: "detail_page.thermal_visualization_tab.title"
          })}
          height={604}
          lines={lines}
          series={series}
          showWidgetCard={false}
          formatXTick={formatXTick}
          formatYTick={formatYTick}
          formatTooltipTitle={formatTooltipTitle}
          legendSeriesComparer={sortByHiddenThenByName}
          translations={{
            adjustToThresholds: intl.formatMessage({
              defaultMessage: "Adjust to thresholds",
              id: "detail_page.adjust_to_thresholds"
            }),
            expandButtonTooltip: intl.formatMessage({
              defaultMessage: "Expand",
              id: "global.chart.expand"
            })
          }}
        />
      )}
      {thermal?.status === Statuses.NotFound && <WidgetNoDataMessage />}
      {thermal?.status === Statuses.Failed && (
        <WidgetErrorMessage
          messageId="global.empty"
          messageDefault={thermal?.message}
        />
      )}
    </div>
  );
};

const StyledThermalVisualizationTab = styled(ThermalVisualizationTab)`
  padding: ${spacingXXLarge};
`;

export default connect(
  (
    state: IState,
    { assetId }: IThermalVisualizationTabOwnProps
  ): IThermalVisualizationTabData => ({
    thermal: getThermalSelector(state)(assetId)
  }),
  (
    dispatch: AppDispatch,
    { assetId }: IThermalVisualizationTabOwnProps
  ): IThermalVisualizationTabActions => ({
    loadThermal: () => dispatch(loadThermal(assetId)),
    removeThermal: () => dispatch(removeThermal(assetId))
  })
)(StyledThermalVisualizationTab);
