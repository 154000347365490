// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import DataGrid, {
  IDataGridProps
} from "@apm/widgets/build/widgets/DataGrid/components/DataGrid";
import { PropsWithChildren } from "react";
import { useIntl } from "react-intl";

export interface ICustomSectionGridProps<T extends object> {
  gridConfig: IDataGridProps<T>;
}

export const CustomSectionGrid = <T extends object>({
  gridConfig
}: PropsWithChildren<ICustomSectionGridProps<T>>) => {
  const {
    columns,
    data,
    gridHeaderSection,
    onRowDelete,
    onCellChanged,
    highlightAfterUpdatedRows,
    dataQa = "",
    disabledRowsDeletion
  } = gridConfig;

  const { formatMessage } = useIntl();

  return (
    <DataGrid<T>
      columns={columns}
      data={data}
      gridHeaderTitle={formatMessage({
        id: "configuration_tool.tab.connected_devices_parameter_mapping",
        defaultMessage: "Parameter mapping"
      })}
      gridHeight="340px"
      gridWidth="100%"
      gridHeaderSection={gridHeaderSection}
      onRowDelete={onRowDelete}
      onCellChanged={onCellChanged}
      highlightAfterUpdatedRows={highlightAfterUpdatedRows}
      dataQa={dataQa}
      noRowsMessage={formatMessage({
        id: "data_grid.no_data_found",
        defaultMessage: "No data available"
      })}
      disabledRowsDeletion={disabledRowsDeletion}
    />
  );
};
