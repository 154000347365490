// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { bb, Chart, ChartOptions } from "billboard.js";
import Guid from "core/guid/Guid";
import * as React from "react";
import IConfigurationExtensions from "../models/IConfigurationExtensions";
import BarChartService from "../services/BarChartService";

export interface IBBChartProps {
  className?: string;
  configuration: ChartOptions;
  extensions?: IConfigurationExtensions;
  onRendered?: (chart: Chart, container: HTMLDivElement) => void;
  style?: React.CSSProperties;
  chartRef?: React.MutableRefObject<Chart>;
}

export default class BBChart extends React.Component<IBBChartProps> {
  constructor(props: IBBChartProps) {
    super(props);

    this.containerId = `chart-${Guid.getUniqGuid()}`;
  }

  componentDidMount(): void {
    this.generateChart(this.containerId);
  }

  componentDidUpdate(prevProps: IBBChartProps): void {
    if (prevProps.configuration !== this.props.configuration) {
      this.destroyChart();
      this.generateChart(this.containerId);
    }
  }

  componentWillUnmount(): void {
    this.destroyChart();
  }

  render() {
    const { className, configuration, style } = this.props;
    if (!configuration) {
      console.error("Chart configuration is not defined.");
      return null;
    }

    configuration.bindto = "#" + this.containerId;

    return (
      <div
        className={`${className} bb-chart`}
        id={this.containerId}
        ref={this.containerRef}
        style={style}
      />
    );
  }

  private containerId: string;
  private containerRef: React.RefObject<HTMLDivElement> = React.createRef();
  private chart: Chart | null = null;
  private timeout: number | null = null;

  private generateChart(containerId: string, numberOfTry: number = 0): void {
    const { applyExtensions } = this;
    const { configuration, extensions, onRendered } = this.props;
    if (!configuration) return;

    if (!numberOfTry) numberOfTry = 1;

    try {
      this.chart = bb.generate(configuration);

      if (this.chart && extensions)
        applyExtensions(containerId, configuration, extensions);
      if (this.chart && this.containerRef.current && onRendered)
        onRendered(this.chart, this.containerRef.current);
    } catch (e) {
      if (numberOfTry <= 3) {
        this.setTimeout(() => {
          this.generateChart(containerId, numberOfTry + 1);
        }, numberOfTry);
      } else {
        console.error("Error when generating chart", e);
      }
    }
  }

  private applyExtensions(
    containerId: string,
    configuration: ChartOptions,
    extensions: IConfigurationExtensions
  ) {
    const { addClassNameToBar } = BarChartService;

    if (
      configuration.data.type === "bar" &&
      extensions &&
      extensions.data &&
      extensions.data.class
    ) {
      addClassNameToBar("#" + containerId, extensions.data.class);
    }
  }

  private destroyChart(): void {
    this.clearTimeout();

    try {
      if (this.chart) {
        this.chart.destroy();
        this.chart = null;
      }
    } catch (e) {
      console.error("Error when destroying chart", e);
    }
  }

  private clearTimeout(): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  }

  private setTimeout(callback: () => void, numberOfTry: number) {
    this.clearTimeout();
    this.timeout = window.setTimeout(() => {
      this.clearTimeout();
      callback();
    }, 200 * numberOfTry);
  }
}
