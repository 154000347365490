// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import {
  loadLinkedReports,
  loadPbiReports
} from "features/detailpage/features/reports/actions/ReportsActions";
import Reports, {
  IReportsActions,
  IReportsStateProps
} from "features/detailpage/features/reports/components/Reports";
import getLinkedReportsSelector from "features/detailpage/features/reports/selectors/getLinkedReportsSelector";
import getPbiReportsSelector from "features/detailpage/features/reports/selectors/getPbiReportsSelector";
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import { AppDispatch } from "store";

function mapStateToProps(state: IState): IReportsStateProps {
  return {
    pbiReports: getPbiReportsSelector(state),
    linkedReports: getLinkedReportsSelector(state)
  };
}

function mapDispatchToProps(dispatch: AppDispatch): IReportsActions {
  return {
    loadPbiReports: (assetType: string) => loadPbiReports(dispatch, assetType),
    loadLinkedReports: (assetId: string) => loadLinkedReports(dispatch, assetId)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
