// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import dayjs from "dayjs";
import { FormattedDate } from "react-intl";
import styled from "styled-components";
import { spacingMedium } from "styles/StyleVariables";

interface IIssueCreationDateProps {
  date: string;
  className?: string;
}

const IssueCreationDate = (props: IIssueCreationDateProps) => {
  const { date, className } = props;

  return (
    <div className={`${className} column issue-creation-date`}>
      {date ? (
        <FormattedDate
          value={dayjs(date).toDate()}
          year="numeric"
          month="numeric"
          day="numeric"
          hour="numeric"
          minute="numeric"
        />
      ) : (
        <span>-</span>
      )}
    </div>
  );
};

const StyledIssueCreationDate = styled(IssueCreationDate)`
  &.column.issue-creation-date {
    padding: ${spacingMedium};
  }
`;

export default StyledIssueCreationDate;
