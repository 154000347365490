// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import {
  bar,
  ChartOptions,
  DataItem,
  GridLineOptions,
  PrimitiveArray,
  RegionOptions
} from "billboard.js";
import { useState } from "react";
import { useIntl } from "react-intl";
import IAgingVisualization from "../models/IAgingVisualization";

const ageBarColor = "rgba(46, 146, 250, 0.5)";
const adjustedAgeColor = "rgba(32, 99, 152, 0.5)";

const useAgingVisualizationChartConfiguration = (
  agingData: IAgingVisualization
) => {
  const intl = useIntl();
  const age = agingData.Age || null;
  const adjustedAge = agingData.AdjustedAge || null;
  const customerExpectedLifeThreshold = agingData.CustomerExpectedLife;
  const manufacturerExpectedLifeThreshold = agingData.ManufacturerExpectedLife;

  const maxValue = Math.max(
    age,
    adjustedAge,
    customerExpectedLifeThreshold,
    manufacturerExpectedLifeThreshold
  );
  const maxXAxisValue = maxValue * 1.1;

  let columns: PrimitiveArray[];

  if (
    (age === null || age === undefined) &&
    adjustedAge !== null &&
    adjustedAge !== undefined
  ) {
    columns = [["x", adjustedAge]];
  } else if (
    (adjustedAge === null || adjustedAge === undefined) &&
    age !== null &&
    age !== undefined
  ) {
    columns = [["x", age]];
  } else columns = [["x", age, adjustedAge]];

  let regions: RegionOptions[];
  let lines: GridLineOptions[];

  const addLine = (...lineOptions: GridLineOptions[]) => {
    if (!lines) lines = [];
    lines.push(...lineOptions);
  };

  if (
    customerExpectedLifeThreshold !== undefined &&
    customerExpectedLifeThreshold !== null
  ) {
    addLine({
      value: customerExpectedLifeThreshold,
      class: "customer-expected-life-threshold"
    });
  }

  if (
    manufacturerExpectedLifeThreshold !== undefined &&
    manufacturerExpectedLifeThreshold !== null
  ) {
    addLine({
      value: manufacturerExpectedLifeThreshold,
      class: "manufacturer-expected-life-threshold"
    });
  }

  if (age !== undefined && age !== null) {
    addLine({
      value: age,
      class: "age-threshold"
    });
  }

  if (adjustedAge !== undefined && adjustedAge !== null) {
    addLine({
      value: adjustedAge,
      class: "adjusted-age-threshold"
    });
  }

  const addRegion = (...regionOptions: RegionOptions[]) => {
    if (!regions) regions = [];
    regions.push(...regionOptions);
  };

  if (
    customerExpectedLifeThreshold !== null &&
    customerExpectedLifeThreshold !== undefined &&
    manufacturerExpectedLifeThreshold !== null &&
    manufacturerExpectedLifeThreshold !== undefined
  ) {
    if (customerExpectedLifeThreshold > manufacturerExpectedLifeThreshold) {
      addRegion({
        axis: "y",
        class: "customer-expected-life-region",
        start: customerExpectedLifeThreshold
      });

      addRegion({
        axis: "y",
        class: "manufacturer-expected-life-region",
        start: manufacturerExpectedLifeThreshold,
        end: customerExpectedLifeThreshold
      });
    } else {
      addRegion({
        axis: "y",
        class: "customer-expected-life-region",
        start: customerExpectedLifeThreshold,
        end: manufacturerExpectedLifeThreshold
      });

      addRegion({
        axis: "y",
        class: "manufacturer-expected-life-region",
        start: manufacturerExpectedLifeThreshold
      });
    }
  }

  if (
    (customerExpectedLifeThreshold === null ||
      customerExpectedLifeThreshold === undefined) &&
    manufacturerExpectedLifeThreshold !== null &&
    manufacturerExpectedLifeThreshold !== undefined
  ) {
    addRegion({
      axis: "y",
      class: "manufacturer-expected-life-region",
      start: manufacturerExpectedLifeThreshold
    });
  }

  if (
    (manufacturerExpectedLifeThreshold === null ||
      manufacturerExpectedLifeThreshold === undefined) &&
    customerExpectedLifeThreshold !== null &&
    customerExpectedLifeThreshold !== undefined
  ) {
    addRegion({
      axis: "y",
      class: "customer-expected-life-region",
      start: customerExpectedLifeThreshold
    });
  }

  const configuration: ChartOptions = {
    size: {
      height: 200
    },
    padding: { left: 10, right: 30 },
    tooltip: {
      show: false
    },
    data: {
      columns,
      type: bar(),
      colors: {
        x: function (d: DataItem) {
          if (d.value === age) return ageBarColor;
          else return adjustedAgeColor;
        }
      }
    },
    bar: {
      width: {
        ratio: 0.5
      }
    },
    axis: {
      rotated: true,
      y: {
        min: 0,
        max: maxXAxisValue,
        padding: { top: 50, bottom: 0 },
        label: {
          text: intl.formatMessage({
            defaultMessage: "yr",
            id: "detail_page.replacement_priority.aging_visualization.x_axis_label"
          }),
          position: "outer-top"
        }
      }
    },
    legend: {
      hide: true
    },
    grid: {
      y: {
        show: true
      },
      x: {
        show: false
      }
    }
  };

  if (regions) configuration.regions = regions;
  if (lines) configuration.grid.y.lines = lines;

  return useState(configuration);
};

export default useAgingVisualizationChartConfiguration;
