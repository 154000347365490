// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { IDataGridRowProps } from "@apm/widgets/build/widgets/DataGrid";
import { IOnCellChangedConfig } from "@apm/widgets/build/widgets/DataGrid/components/DataGrid";
import { notifications } from "@pg/common/build/components/Notifications";
import { Statuses } from "core/data/models/Data";
import UrlService from "core/data/services/UrlService";
import $ from "jquery";
import { isEmpty } from "lodash";
import { IntlShape } from "react-intl";
import { BaseStore } from "store/BaseStore";
import { config } from "utils/AppConfig";
import { IData } from "utils/WebService";
import validator from "validator";

const newModelId = "New.Model";

enum ModelMode {
  View,
  Add,
  Edit
}

enum ParameterMode {
  View,
  Add,
  Edit
}

class ModelType {
  public static readonly Standard = "Standard";
  public static readonly Rest = "Rest";
}

export interface IModelPossibleValues {
  PossibleValues: { [sourceName: string]: string[] };
}

export interface IModelParameter {
  _Mode: ParameterMode;
  ModelParameter: string;
  Source: string;
  Input: string;
}

export type IConfigurableModelParameter = IDataGridRowProps<IModelParameter>;

export interface IDirectionalLine {
  Slope: number | null | undefined;
  Intercept: number | null | undefined;
}

export interface IRevision {
  Revision: string;
}

export type ApiVersion = "Unknown" | "V1" | "V2" | "V3" | "V4" | "V5";

export interface IModelConfig {
  ModelId: string;
  ModelParameters: IModelParameter[];
  ModelUrl: string;
  Metadata: IRevision | null;
  RestModelApiVersion?: ApiVersion;
  LowToMediumRiskLineThreshold?: IDirectionalLine | null | undefined;
  MediumToHighRiskLineThreshold?: IDirectionalLine | null | undefined;
  IsThresholdEditable: Boolean;
}

export interface IModelConfigWithConfigurableParameters
  extends Omit<IModelConfig, "ModelParameters"> {
  ConfigurableModelParameters: IConfigurableModelParameter[];
}

export interface IModel {
  _Collector: IData<any>;
  _Mode: ModelMode;
  _Update: IData<any>;
  ModelId: string;
  ImplementationId: string;
  ModelType: string;
  ModelConfig: IData<IModelConfig>;
  ModelConfigBackup: IData<IModelConfig>;
}

export interface IConfigurableModel
  extends Omit<IModel, "ModelConfig" | "ModelConfigBackup"> {
  ConfigurableModelConfig: IData<IModelConfigWithConfigurableParameters>;
  ConfigurableModelConfigBackup: IData<IModelConfigWithConfigurableParameters>;
}

class DataIntegrationStore extends BaseStore {
  private xhrLoadModelConfig: JQueryXHR;
  private xhrLoadPossibleValues: JQueryXHR;

  private modelPossibleValues: IData<IModelPossibleValues>;
  private models: IData<IModel[]>;
  private selectedModel: IConfigurableModel;
  private sources: IData<string[]>;

  public initStore() {
    this.initSelectedModel();
    this.models = {
      status: null,
      data: null,
      message: null
    };
    this.sources = {
      status: null,
      data: null,
      message: null
    };

    this.emitChange();
  }

  public mapToConfigurableModelConfig(
    modelConfig: IData<IModelConfig>
  ): IData<IModelConfigWithConfigurableParameters> {
    const configurableModelConfig: IData<IModelConfigWithConfigurableParameters> =
      {
        status: modelConfig?.status,
        message: modelConfig?.message,
        data: {
          ModelId: modelConfig?.data?.ModelId,
          ModelUrl: modelConfig?.data?.ModelUrl,
          Metadata: modelConfig?.data?.Metadata,
          RestModelApiVersion: modelConfig?.data?.RestModelApiVersion,
          LowToMediumRiskLineThreshold:
            modelConfig?.data?.LowToMediumRiskLineThreshold,
          MediumToHighRiskLineThreshold:
            modelConfig?.data?.MediumToHighRiskLineThreshold,
          IsThresholdEditable: modelConfig?.data?.IsThresholdEditable,
          ConfigurableModelParameters: modelConfig?.data?.ModelParameters
            ? this.mapToConfigurableModelParameters(
                modelConfig?.data?.ModelParameters
              )
            : null
        }
      };
    return configurableModelConfig;
  }

  public mapToModelConfig(
    config: IModelConfigWithConfigurableParameters
  ): IModelConfig {
    const { ConfigurableModelParameters, ...rest } = config;

    const modelConfig: IModelConfig = {
      ...rest,
      ModelParameters: ConfigurableModelParameters.map<IModelParameter>(
        (parameter) => {
          return parameter.cells.reduce((acc, val) => {
            return {
              ...acc,
              [val.cellName]: val.cellValue
            };
          }, {} as IModelParameter);
        }
      )
    };

    return modelConfig;
  }

  public mapToConfigurableModelParameters(
    modelParameters: IModelParameter[]
  ): IConfigurableModelParameter[] {
    return modelParameters?.map<IConfigurableModelParameter>((param, idx) => {
      return {
        id: idx,
        isNew: false,
        added: false,
        cells: Object.entries(param).map<
          IConfigurableModelParameter["cells"][0]
        >(([key, value]) => {
          return {
            cellName: key as keyof IModelParameter,
            cellValue: value,
            valid: true,
            modified: false
          };
        })
      };
    });
  }

  public mapToModelParameter(
    cells: IConfigurableModelParameter["cells"]
  ): IModelParameter {
    return cells.reduce((acc, val) => {
      return {
        ...acc,
        [val.cellName]: val.cellValue
      };
    }, {} as IModelParameter);
  }

  public mapToModelParameters(
    configurableModelParameters: IConfigurableModelParameter[]
  ): IModelParameter[] {
    return configurableModelParameters.map(({ cells }) => {
      return this.mapToModelParameter(cells);
    });
  }

  public mapToConfigurableModel(model: IModel): IConfigurableModel {
    const { ModelConfig, ModelConfigBackup, ...rest } = model;

    const configurableModel: IConfigurableModel = {
      ...rest,
      ConfigurableModelConfig: this.mapToConfigurableModelConfig(ModelConfig),
      ConfigurableModelConfigBackup:
        this.mapToConfigurableModelConfig(ModelConfig)
    };

    return configurableModel;
  }

  public loadModels(intl: IntlShape) {
    const self = this;
    const url = UrlService.getApiUrl(config.api.dataIntegration.modelsUrl);
    this.models.status = Statuses.Loading;
    this.emitChange();
    $.ajax({
      url: url,
      type: "GET",
      dataType: "json",
      cache: false,
      success: function (
        data: {
          ModelId: string;
          ImplementationId: string;
          ModelType: string;
        }[]
      ) {
        self.models.status = Statuses.Succeeded;
        self.models.data = data.map<IModel>((d) => ({
          _Collector: {
            status: null,
            data: null,
            message: null
          },
          _Mode: ModelMode.View,
          _Update: {
            status: null,
            data: null,
            message: null
          },
          ModelId: d.ModelId,
          ImplementationId: d.ImplementationId,
          ModelType: d.ModelType,
          ModelConfig: {
            status: null,
            data: null,
            message: null
          },
          ModelConfigBackup: null
        }));
        self.models.message = null;

        if (data && data.length > 0) {
          if (self.selectedModel?.ModelId) {
            self.selectModel(self.selectedModel.ModelId, intl);
          } else {
            self.selectModel(data[0].ModelId, intl);
          }
        }

        self.emitChange();
      },
      error: function (xhr: JQueryXHR, status: any, err: any) {
        self.models.status = Statuses.Failed;
        self.models.data = null;
        self.models.message = `${xhr} ${status} ${err}`;
        self.selectedModel = null;
        self.emitChange();

        notifications.error({
          message: intl.formatMessage({
            id: "data_integration.load_models.failed",
            defaultMessage: "Loading model list failed"
          })
        });
      }
    });
  }

  public loadSources() {
    const self = this;
    const url = UrlService.getApiUrl(
      config.api.settings.parameter.modelParameterSourcesUrl
    );

    this.sources.status = Statuses.Loading;
    this.emitChange();

    $.ajax({
      url: url,
      type: "GET",
      dataType: "json",
      cache: false,
      success: function (data: string[]) {
        self.sources.status = Statuses.Succeeded;
        self.sources.data = data;
        self.sources.message = null;
        self.emitChange();
      },
      error: function (xhr: JQueryXHR, status: string, err: string) {
        self.sources.status = Statuses.Failed;
        self.sources.data = null;
        self.sources.message = `${xhr} ${status} ${err}`;
        self.emitChange();
      }
    });
  }

  public dropModel(intl: IntlShape) {
    if (this.selectedModel) {
      notifications.confirm({
        title: intl.formatMessage({
          id: "data_integration.drop_model.drop_question",
          defaultMessage: "Do you want to delete model?"
        }),
        onOk: () => {
          const self = this;
          const url = UrlService.getApiUrl(
            config.api.settings.parameter.dropModelUrl,
            [
              {
                name: "modelId",
                value: this.selectedModel.ModelId
              }
            ]
          );

          const model = this.selectedModel;
          model._Update.status = Statuses.Loading;
          this.emitChange();

          $.ajax({
            url: url,
            type: "DELETE",
            cache: false,
            success: function () {
              let key = null;
              for (let i = 0; i < self.models.data.length; i++) {
                if (
                  self.models.data[i].ModelId === self.selectedModel.ModelId
                ) {
                  key = i;
                  break;
                }
              }
              if (key != null) {
                self.models.data.splice(key, 1);
              }

              model._Update.status = Statuses.Succeeded;
              model._Update.message = null;
              self.emitChange();

              if (self.models.data.length > key) {
                self.selectModel(self.models.data[key].ModelId, intl);
              } else if (self.models.data.length > 0) {
                self.selectModel(
                  self.models.data[self.models.data.length - 1].ModelId,
                  intl
                );
              }

              notifications.success({
                message: intl.formatMessage({
                  id: "data_integration.drop_model.succeeded",
                  defaultMessage: "Model was deleted"
                })
              });
            },
            error: function (xhr: JQueryXHR, status: string, err: string) {
              model._Update.status = Statuses.Failed;
              model._Update.message = `${xhr} ${status} ${err}`;
              self.emitChange();

              notifications.error({
                message: intl.formatMessage({
                  id: "data_integration.drop_model.failed",
                  defaultMessage: "Deleting model failed"
                })
              });
            }
          });
        }
      });
    }
  }

  public storeModel(intl: IntlShape) {
    if (
      this.selectedModel &&
      this.isAllParametersValid(
        this.selectedModel.ConfigurableModelConfig.data
          .ConfigurableModelParameters
      )
    ) {
      if (this.selectedModel._Mode === ModelMode.Add) {
        this.storeNewModel(intl, this.selectedModel.ModelType);
      } else if (
        this.selectedModel._Mode === ModelMode.Edit &&
        this.selectedModel.ModelType === ModelType.Rest
      ) {
        this.storeExistingPredefinedModel(intl);
      } else if (
        this.selectedModel._Mode === ModelMode.Edit &&
        this.selectedModel.ModelType === ModelType.Standard
      ) {
        this.storeExistingModel(intl);
      }
    }
  }

  public addNewModel(modelType: string, intl: IntlShape) {
    const prepareModel = () => {
      const model = {
        _Collector: {
          status: null,
          data: null,
          message: null
        },
        _Mode: ModelMode.Add,
        _Update: {
          status: null,
          data: null,
          message: null
        },
        ModelId: newModelId,
        ModelType: modelType,
        ModelConfig: {
          status: null,
          data: null,
          message: null
        },
        ModelConfigBackup: null
      } as IModel;

      if (!this.models.data) this.models.data = [];
      this.models.data.unshift(model);

      const configurableModel = this.mapToConfigurableModel(model);

      this.setSelectedModel(configurableModel);
      this.emitChange();
    };

    if (this.selectedModel && this.selectedModel._Mode === ModelMode.Edit) {
      notifications.confirm({
        title: intl.formatMessage({
          id: "data_integration.select_model.model_in_add_edit_mode",
          defaultMessage: "The model is not saved. Do you want to leave?"
        }),
        onOk: () => {
          this.cancelModelEditing();
          prepareModel();
        }
      });
    } else if (
      this.selectedModel &&
      this.selectedModel._Mode === ModelMode.View
    ) {
      prepareModel();
    } else if (!this.selectedModel) {
      prepareModel();
    }
  }

  public getExistingNewRow() {
    if (this.selectedModel && this.selectedModel.ConfigurableModelConfig.data) {
      const node = (
        this.selectedModel.ConfigurableModelConfig.data
          .ConfigurableModelParameters || []
      ).find(
        ({ added, cells }) =>
          added &&
          cells
            .filter((cell) =>
              ["ModelParameter", "Source", "Input"].includes(cell.cellName)
            )
            .every(({ cellValue }) => !cellValue)
      );
      return node;
    }
  }

  public addNewParameter() {
    if (this.selectedModel && this.selectedModel.ConfigurableModelConfig.data) {
      if (
        !this.selectedModel.ConfigurableModelConfig.data
          .ConfigurableModelParameters
      )
        this.selectedModel.ConfigurableModelConfig.data.ConfigurableModelParameters =
          [];
      this.setModelToEditMode();
      const rowId =
        Math.max(
          0,
          ...this.selectedModel.ConfigurableModelConfig.data.ConfigurableModelParameters.map(
            (x) => x.id
          )
        ) + 1;
      const newParameters = [
        ...this.selectedModel.ConfigurableModelConfig.data.ConfigurableModelParameters.map(
          (row) => {
            return {
              ...row,
              isNew: false
            };
          }
        ),
        {
          id: rowId,
          cells: [
            {
              cellName: "ModelParameter" as keyof IModelParameter,
              cellValue: "",
              valid: false
            },
            {
              cellName: "Source" as keyof IModelParameter,
              cellValue: null,
              valid: true
            },
            {
              cellName: "Input" as keyof IModelParameter,
              cellValue: null,
              valid: true
            },
            {
              cellName: "_Mode" as keyof IModelParameter,
              cellValue: ParameterMode.Add
            }
          ],
          added: true,
          isNew: true
        }
      ];

      this.selectedModel.ConfigurableModelConfig.data.ConfigurableModelParameters =
        newParameters;

      this.emitChange();
    }
  }

  public deleteParameter(rowId: number) {
    this.setParameterToEditMode(rowId);

    this.selectedModel.ConfigurableModelConfig.data.ConfigurableModelParameters =
      this.selectedModel.ConfigurableModelConfig.data.ConfigurableModelParameters.filter(
        ({ id }) => rowId !== id
      ).map(({ id, cells, added, isNew }) => ({
        cells,
        id,
        added,
        isNew
      }));

    this.emitChange();
  }

  public selectModel(modelId: string, intl: IntlShape) {
    if (this.selectedModel && this.selectedModel.ModelId !== modelId) {
      if (
        this.selectedModel._Mode === ModelMode.Add ||
        this.selectedModel._Mode === ModelMode.Edit
      ) {
        notifications.confirm({
          title: intl.formatMessage({
            id: "data_integration.select_model.model_in_add_edit_mode",
            defaultMessage: "The model is not saved. Do you want to leave?"
          }),
          onOk: () => {
            const model = this.getModel(modelId);
            if (model) {
              this.cancelModelEditing();
              const configurableModel = this.mapToConfigurableModel(model);
              this.setSelectedModel(configurableModel);
              this.emitChange();
            }
          }
        });
      } else {
        const model = this.getModel(modelId);
        if (model) {
          const configurableModel = this.mapToConfigurableModel(model);
          this.setSelectedModel(configurableModel);
          this.emitChange();
        }
      }
    } else {
      const model = this.getModel(modelId);
      if (model) {
        const configurableModel = this.mapToConfigurableModel(model);

        this.setSelectedModel(configurableModel);
        this.emitChange();
      }
    }
  }

  public setParameterToEditMode(i: number) {
    if (this.selectedModel) {
      const parameter = this.getParameter(i);
      const parameters = this.getParameters();
      if (
        parameter &&
        !parameter.cells.find(
          ({ cellName, cellValue }) => cellName === "_Mode" && cellValue
        )
      ) {
        this.setModelToEditMode();
        this.resetAddParametersMode();
        if (
          this.selectedModel._Mode === ModelMode.Add ||
          !this.selectedModel.ConfigurableModelConfigBackup.data.ConfigurableModelParameters.find(
            ({ cells }) =>
              cells.find(({ cellName }) => cellName === "ModelParameter")
                ?.cellValue ===
              parameter.cells.find(
                ({ cellName }) => cellName === "ModelParameter"
              )?.cellValue
          )
        ) {
          parameters.forEach((param) => {
            param.isNew = false;
            if (param.id === parameter.id) {
              param.cells.forEach((cell) => {
                if (cell.cellName === "_Mode") {
                  cell.cellValue = ParameterMode.Edit;
                }
              });
            }
          });
        } else if (this.selectedModel._Mode === ModelMode.Edit) {
          parameters.forEach((param) => {
            param.isNew = false;
            if (param.id === parameter.id) {
              param.cells.forEach((cell) => {
                if (cell.cellName === "_Mode") {
                  cell.cellValue = ParameterMode.Edit;
                }
              });
            }
          });
        } else {
          parameters.forEach((param) => {
            param.isNew = false;
          });
        }

        this.emitChange();
      }
    }
  }

  public setModelToEditMode() {
    if (this.selectedModel && !this.selectedModel._Mode) {
      this.selectedModel._Mode = ModelMode.Edit;
      this.emitChange();
    }
  }

  public cancelEditing() {
    if (this.selectedModel) {
      if (this.selectedModel._Mode === ModelMode.Add) {
        this.cancelModelEditing();
        if (this.models && this.models.data && this.models.data.length > 0) {
          this.setSelectedModel(
            this.mapToConfigurableModel(this.models.data[0])
          );
        }
      } else {
        this.cancelModelEditing();
      }

      this.emitChange();
    }
  }

  public setModelId(modelId: string) {
    if (this.selectedModel) {
      this.setModelToEditMode();
      this.selectedModel.ConfigurableModelConfig.data.ModelId = modelId;
      this.emitChange();
    }
  }

  public setUrl(url: string) {
    if (this.selectedModel && this.selectedModel.ConfigurableModelConfig) {
      if (this.selectedModel.ConfigurableModelConfig.data.ModelUrl !== url) {
        this.setModelToEditMode();
        this.selectedModel.ConfigurableModelConfig.data.ModelUrl = url;
        this.emitChange();
      }
    }
  }

  public setApiVersion(version: ApiVersion) {
    if (this.selectedModel && this.selectedModel.ConfigurableModelConfig) {
      if (
        this.selectedModel.ConfigurableModelConfig.data.RestModelApiVersion !==
        version
      ) {
        this.setModelToEditMode();
        this.selectedModel.ConfigurableModelConfig.data.RestModelApiVersion =
          version;
        this.emitChange();
      }
    }
  }

  public setAssetRiskConfig(
    LowToMediumRiskLineThreshold: IDirectionalLine | undefined | null,
    MediumToHighRiskLineThreshold: IDirectionalLine | undefined | null
  ) {
    if (this.selectedModel && this.selectedModel.ConfigurableModelConfig) {
      this.setModelToEditMode();
      this.selectedModel.ConfigurableModelConfig.data.LowToMediumRiskLineThreshold =
        LowToMediumRiskLineThreshold;
      this.selectedModel.ConfigurableModelConfig.data.MediumToHighRiskLineThreshold =
        MediumToHighRiskLineThreshold;
      this.emitChange();
    }
  }

  public setParameterField({
    rowId,
    cellName,
    newValue,
    modified,
    valid,
    validationMessage
  }: IOnCellChangedConfig) {
    if (this.selectedModel && this.selectedModel.ConfigurableModelConfig.data) {
      if (
        this.selectedModel.ConfigurableModelConfig.data
          .ConfigurableModelParameters
      ) {
        this.selectedModel.ConfigurableModelConfig.data.ConfigurableModelParameters =
          this.selectedModel.ConfigurableModelConfig.data.ConfigurableModelParameters.map(
            (param) => {
              if (param.id === rowId) {
                return {
                  ...param,
                  isNew: false,
                  cells: param.cells.map((cell) => {
                    if (cell.cellName === cellName) {
                      return {
                        cellName,
                        cellValue: newValue,
                        modified,
                        valid,
                        validationMessage
                      };
                    }
                    return cell;
                  })
                };
              }
              return {
                ...param,
                isNew: false
              };
            }
          );
        this.emitChange();
      }
    }
  }

  public getSelectedModel(): IConfigurableModel {
    return this.selectedModel;
  }

  public getModelPossibleValues(): IData<IModelPossibleValues> {
    return this.modelPossibleValues;
  }

  public getModels(): IData<IModel[]> {
    return this.models;
  }

  public getSources(): IData<string[]> {
    return this.sources;
  }

  public isModelValid(model: IConfigurableModel): boolean {
    return (
      (model.ModelType === ModelType.Standard ||
        (model.ModelType === ModelType.Rest &&
          this.isModelIdValid(model.ConfigurableModelConfig.data.ModelId) &&
          this.isModelUrlValid(model.ConfigurableModelConfig.data.ModelUrl) &&
          this.isModelRestApiVersionValid(
            model.ConfigurableModelConfig.data.RestModelApiVersion
          ))) &&
      this.isAllParametersValid(
        model.ConfigurableModelConfig.data.ConfigurableModelParameters
      ) &&
      this.isRiskThresholdsValid(model.ConfigurableModelConfig.data)
    );
  }

  public isModelIdValid(modelId: string): boolean {
    const matchRegExp = /^[a-zA-Z][a-zA-Z0-9.-]*$/;
    return validator.matches(modelId || "", matchRegExp);
  }

  public isModelIdUnique(modelId: string): boolean {
    return this.models.data.findIndex((x) => x.ModelId === modelId) === -1;
  }

  public modelIdStartsWithLetter(modelId: string): boolean {
    return validator.matches(modelId || "", /^[a-zA-Z]/);
  }

  public isModelUrlValid(modelUrl: string): boolean {
    return (
      !validator.isEmpty(modelUrl || "") &&
      validator.isURL(modelUrl || "") &&
      validator.matches(modelUrl, /^(https?:\/\/){1}/)
    );
  }

  public isModelRestApiVersionValid(version: ApiVersion): boolean {
    return !isEmpty(version) && version !== "Unknown";
  }

  public isAllParametersValid(
    configurableModelParameters: IConfigurableModelParameter[]
  ): boolean {
    const modelParameters = this.mapToModelParameters(
      configurableModelParameters
    );
    for (const modelParameter of modelParameters) {
      if (!this.isParameterValid(modelParameter)) return false;
    }
    return true;
  }

  public isParameterValid(modelParameter: IModelParameter): boolean {
    return (
      this.isParameterIdValid(modelParameter.ModelParameter) &&
      this.isParameterSourceValid(
        modelParameter.Source,
        modelParameter.Input
      ) &&
      this.isParameterInputValid(modelParameter.Source, modelParameter.Input)
    );
  }

  public isParameterIdValid(parameterId: string): boolean {
    return (
      !this.isParameterIdEmpty(parameterId) &&
      !this.isParameterIdDuplicated(parameterId)
    );
  }

  public isParameterIdEmpty(parameterId: string): boolean {
    return validator.isEmpty(parameterId || "");
  }

  public isParameterIdDuplicated(parameterId: string): boolean {
    const parameterIds = (
      this.selectedModel.ConfigurableModelConfig.data
        .ConfigurableModelParameters || []
    ).map(
      (row) =>
        row.cells.find((cell) => cell.cellName === "ModelParameter")
          .cellValue as string
    );
    return parameterIds.filter((p) => p === parameterId).length > 1;
  }

  public isParameterSourceValid(source: string, input: string): boolean {
    if (!validator.isEmpty(source || "")) {
      return true;
    } else if (
      validator.isEmpty(source || "") &&
      validator.isEmpty(input || "")
    ) {
      return true;
    } else {
      return false;
    }
  }

  public isParameterInputValid(source: string, input: string): boolean {
    if (
      !validator.isEmpty(input || "") &&
      validator.matches(input || "", /^[a-zA-Z0-9_.]+( [a-zA-Z0-9_.]+)*$/)
    ) {
      return true;
    } else if (
      validator.isEmpty(source || "") &&
      validator.isEmpty(input || "")
    ) {
      return true;
    } else {
      return false;
    }
  }

  public isRiskThresholdsValid(
    modelConfig: IModelConfigWithConfigurableParameters
  ): boolean {
    if (!modelConfig) return false;
    return (
      this.isRiskThresholdLineValid(modelConfig.LowToMediumRiskLineThreshold) &&
      this.isRiskThresholdLineValid(modelConfig.MediumToHighRiskLineThreshold)
    );
  }

  public isRiskThresholdLineValid(
    line: IDirectionalLine | null | undefined
  ): boolean {
    if (!line) return false;
    if (line.Slope === undefined || line.Slope === null || line.Slope > 0.0)
      return false;
    if (line.Intercept === undefined || line.Intercept === null) return false;
    return true;
  }

  private initSelectedModel() {
    this.selectedModel = null;
    this.modelPossibleValues = {
      status: null,
      data: null,
      message: null
    };
  }

  private loadModelConfig() {
    const self = this;
    const model = this.selectedModel;
    const url = UrlService.getApiUrl(
      config.api.settings.parameter.modelConfigUrl,
      [
        {
          name: "modelId",
          value: model.ModelId
        }
      ]
    );

    if (this.xhrLoadModelConfig) this.xhrLoadModelConfig.abort();

    model.ConfigurableModelConfig.status = Statuses.Loading;
    this.emitChange();

    this.xhrLoadModelConfig = $.ajax({
      url: url,
      type: "GET",
      dataType: "json",
      cache: false,
      success: function (data: IModelConfig) {
        model.ConfigurableModelConfig.status = Statuses.Succeeded;
        model.ConfigurableModelConfig.data = self.mapToConfigurableModelConfig({
          data,
          message: null,
          status: Statuses.Succeeded
        })?.data;
        model.ConfigurableModelConfigBackup.status = Statuses.Succeeded;
        model.ConfigurableModelConfigBackup.data =
          self.mapToConfigurableModelConfig({
            data,
            message: null,
            status: Statuses.Succeeded
          })?.data;
        model.ConfigurableModelConfig.message = null;

        self.emitChange();
      },
      error: function (xhr: JQueryXHR, status: string, err: string) {
        model.ConfigurableModelConfig.status = Statuses.Failed;
        model.ConfigurableModelConfig.data = null;
        model.ConfigurableModelConfig.message = `${xhr} ${status} ${err}`;
        self.emitChange();
      }
    });
  }

  private loadModelPossibleValues() {
    const self = this;
    const url = UrlService.getApiUrl(
      config.api.settings.parameter.modelParameterInputPossibleValuesUrl,
      [
        {
          name: "modelId",
          value: this.selectedModel.ModelId
        }
      ]
    );

    if (this.xhrLoadPossibleValues) this.xhrLoadPossibleValues.abort();

    this.modelPossibleValues.status = Statuses.Loading;
    this.emitChange();

    this.xhrLoadPossibleValues = $.ajax({
      url: url,
      type: "GET",
      dataType: "json",
      cache: false,
      success: function (data: IModelPossibleValues) {
        self.modelPossibleValues.status = Statuses.Succeeded;
        self.modelPossibleValues.data = data;
        self.modelPossibleValues.message = null;
        self.emitChange();
      },
      error: function (xhr: JQueryXHR, status: string, err: string) {
        self.modelPossibleValues.status = Statuses.Failed;
        self.modelPossibleValues.data = null;
        self.modelPossibleValues.message = `${xhr} ${status} ${err}`;
        self.emitChange();
      }
    });
  }

  private storeNewModel(intl: IntlShape, modelType: string) {
    const self = this;
    const url = UrlService.getApiUrl(
      config.api.settings.parameter.createModelConfigUrl,
      [
        {
          name: "modelType",
          value: modelType
        }
      ]
    );

    this.selectedModel._Update.status = Statuses.Loading;
    this.emitChange();

    const modelConfig = this.mapToModelConfig(
      this.selectedModel.ConfigurableModelConfig.data
    );

    $.ajax({
      url: url,
      type: "PUT",
      contentType: "application/json; charset=UTF-8",
      data: JSON.stringify(modelConfig),
      success: function () {
        self.resetEditParametersMode();
        self.selectedModel._Mode = ModelMode.View;
        self.selectedModel._Update.status = Statuses.Succeeded;
        self.selectedModel._Update.message = null;
        self.selectedModel.ModelId =
          self.selectedModel.ConfigurableModelConfig.data.ModelId;

        self.updateBackup(modelConfig);
        self.models.data = self.models.data.map((item) => {
          if (item.ModelId === "New.Model") {
            return {
              ...item,
              ModelId: self.selectedModel.ConfigurableModelConfig.data.ModelId,
              _Mode: ModelMode.View
            };
          }

          return item;
        });

        self.emitChange();

        notifications.success({
          message: intl.formatMessage({
            id: "data_integration.store_new_rest_model.succeed",
            defaultMessage: "New model was created"
          })
        });
      },
      error: function (xhr: JQueryXHR, status: any, err: any) {
        self.selectedModel._Update.status = Statuses.Failed;
        self.selectedModel._Update.message = `${xhr} ${status} ${err}`;
        self.emitChange();

        const messageId = DataIntegrationStore.safeJsonParse(xhr?.responseText);
        notifications.error({
          message: intl.formatMessage({
            id:
              typeof messageId === "string"
                ? `data_integration.model_config.save.failed.${messageId}`
                : "data_integration.store_new_rest_model.failed",
            defaultMessage: "Model creation process failed"
          })
        });
      }
    });
  }

  private storeExistingPredefinedModel(intl: IntlShape) {
    const self = this;
    const url = UrlService.getApiUrl(
      config.api.settings.parameter.updateExternalModelConfigUrl
    );

    this.selectedModel._Update.status = Statuses.Loading;
    this.emitChange();

    const modelConfig = this.mapToModelConfig(
      this.selectedModel.ConfigurableModelConfig.data
    );

    $.ajax({
      url: url,
      type: "PUT",
      contentType: "application/json; charset=UTF-8",
      data: JSON.stringify(modelConfig),
      success: function () {
        self.resetEditParametersMode();
        self.selectedModel._Mode = ModelMode.View;
        self.selectedModel._Update.status = Statuses.Succeeded;
        self.selectedModel._Update.message = null;
        self.updateBackup(modelConfig);
        self.emitChange();

        notifications.success({
          message: intl.formatMessage({
            id: "data_integration.update_new_rest_model.succeed",
            defaultMessage: "Model was updated"
          })
        });
      },
      error: function (xhr: JQueryXHR, status: any, err: any) {
        self.selectedModel._Update.status = Statuses.Failed;
        self.selectedModel._Update.message = `${xhr} ${status} ${err}`;
        self.emitChange();

        const messageId = DataIntegrationStore.safeJsonParse(xhr?.responseText);
        notifications.error({
          message: intl.formatMessage({
            id:
              typeof messageId === "string"
                ? `data_integration.model_config.save.failed.${messageId}`
                : "data_integration.update_new_rest_model.failed",
            defaultMessage: "Model updating failed"
          })
        });
      }
    });
  }

  private storeExistingModel(intl: IntlShape) {
    const self = this;
    const url = UrlService.getApiUrl(
      config.api.settings.parameter.updateModelConfigUrl
    );

    this.selectedModel._Update.status = Statuses.Loading;
    this.emitChange();

    const modelConfig = this.mapToModelConfig(
      this.selectedModel.ConfigurableModelConfig.data
    );

    $.ajax({
      url: url,
      type: "PUT",
      contentType: "application/json; charset=UTF-8",
      data: JSON.stringify(modelConfig),
      success: function () {
        self.selectedModel._Mode = ModelMode.View;
        self.selectedModel._Update.status = Statuses.Succeeded;
        self.selectedModel._Update.message = null;
        self.resetAddParametersMode();
        self.resetEditParametersMode();
        self.updateBackup(modelConfig);
        self.emitChange();

        notifications.success({
          message: intl.formatMessage({
            id: "data_integration.store_existing_model.succeed",
            defaultMessage: "Model was updated"
          })
        });
      },
      error: function (xhr: JQueryXHR, status: any, err: any) {
        self.selectedModel._Update.status = Statuses.Failed;
        self.selectedModel._Update.message = `${xhr} ${status} ${err}`;
        self.emitChange();

        const messageId = DataIntegrationStore.safeJsonParse(xhr?.responseText);
        notifications.error({
          message: intl.formatMessage({
            id:
              typeof messageId === "string"
                ? `data_integration.model_config.save.failed.${messageId}`
                : "data_integration.store_existing_model.failed",
            defaultMessage: "Model updating process failed"
          })
        });
      }
    });
  }

  private cancelModelEditing() {
    if (this.selectedModel._Mode === ModelMode.Edit) {
      this.selectedModel._Mode = ModelMode.View;
      this.selectedModel.ConfigurableModelConfig = Object.deepClone(
        this.selectedModel.ConfigurableModelConfigBackup
      );
    } else if (this.selectedModel._Mode === ModelMode.Add) {
      this.removeModel(this.selectedModel.ModelId);
    }
  }

  private getModel(modelId: string): IModel {
    const models = (this.models.data || []).filter(
      (m) => m.ModelId === modelId
    );

    return models.length > 0 ? models[0] : null;
  }

  private removeModel(modelId: string): number {
    let key: number = null;
    for (let i = 0; i < this.models.data.length; i++) {
      if (this.models.data[i].ModelId === modelId) {
        key = i;
        break;
      }
    }

    if (key != null) this.models.data.splice(key, 1);
    return key;
  }

  private setSelectedModel(model: IConfigurableModel) {
    this.selectedModel = model;

    this.loadModelPossibleValues();
    if (model._Mode === ModelMode.View) {
      this.loadModelConfig();
    } else if (model._Mode === ModelMode.Add) {
      this.addNewModelConfig();
      this.addNewParameter();
    }
  }

  private addNewModelConfig() {
    this.selectedModel.ConfigurableModelConfig = {
      status: Statuses.Succeeded,
      data: {
        ModelId: null,
        ConfigurableModelParameters: [],
        ModelUrl: null,
        Metadata: null,
        RestModelApiVersion: null,
        LowToMediumRiskLineThreshold: {
          Slope: -1,
          Intercept: 33
        },
        MediumToHighRiskLineThreshold: {
          Slope: -1,
          Intercept: 67
        },
        IsThresholdEditable: true
      },
      message: null
    };
    this.emitChange();
  }

  private getParameters(): IConfigurableModelParameter[] {
    return this.selectedModel.ConfigurableModelConfig.data
      .ConfigurableModelParameters;
  }

  private getParameter(i: number): IConfigurableModelParameter {
    if (
      this.selectedModel?.ConfigurableModelConfig?.data
        ?.ConfigurableModelParameters?.length > 0
    ) {
      return this.selectedModel.ConfigurableModelConfig.data
        .ConfigurableModelParameters[i];
    }
    return null;
  }

  private resetAddParametersMode() {
    const parameters =
      this.selectedModel.ConfigurableModelConfig.data.ConfigurableModelParameters.filter(
        ({ cells }) =>
          cells.filter(
            ({ cellName, cellValue }) =>
              cellName === "_Mode" && cellValue === ParameterMode.Add
          )
      );
    for (const parameter of parameters) {
      parameter.cells.forEach((cell) => {
        if (cell.cellName === "_Mode") {
          cell.cellValue = ParameterMode.View;
        }
      });
    }
  }
  private updateBackup(modelConfig: IModelConfig) {
    this.selectedModel.ConfigurableModelConfigBackup.status =
      Statuses.Succeeded;
    this.selectedModel.ConfigurableModelConfigBackup.data =
      this.mapToConfigurableModelConfig({
        data: modelConfig,
        message: null,
        status: Statuses.Succeeded
      })?.data;
  }

  private resetEditParametersMode() {
    this.selectedModel.ConfigurableModelConfig.data.ConfigurableModelParameters =
      this.selectedModel.ConfigurableModelConfig.data.ConfigurableModelParameters.map(
        (row) => {
          return {
            ...row,
            added: false,
            isNew: false,
            cells: row.cells.map((cell) => {
              if (cell.cellName === "_Mode") {
                return {
                  ...cell,
                  cellValue: ParameterMode.View,
                  modified: false
                };
              }

              return {
                ...cell,
                valid: true,
                modified: false
              };
            })
          };
        }
      );
  }

  private static safeJsonParse(text: string): any {
    try {
      return JSON.parse(text);
    } catch (e) {}
    return null;
  }
}

const dataIntegrationStore = new DataIntegrationStore();

export default dataIntegrationStore;
export { ModelMode, ParameterMode, ModelType };
