// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { MessageDescriptor } from "react-intl";
import { Error } from "../models/IUpdateIssueStatusResponse";

const messageTranslationKeys: { [key in string]: MessageDescriptor } = {
  Success: {
    id: "issues_page.issues.update.success",
    defaultMessage:
      "Status of {num, plural, zero {# issues} one {# issue} other {# issues} } was changed to {status}"
  },
  GeneralError: {
    id: "issues_page.issues.update.failed",
    defaultMessage:
      "Status of {num, plural, zero {# issues} one {# issue} other {# issues}} cannot change to {status}."
  },
  RefreshPageMessage: {
    id: "global.messages.refresh_the_page",
    defaultMessage: "Refresh the page."
  },
  [Error.ISSUE_NOT_FOUND]: {
    id: "issues_page.issues.update.error.not_found_issue_to_edit",
    defaultMessage:
      "{ num, plural, zero {# issue statuses cannot change. The issue or the asset with this issue does not exist.} one {# issue status cannot change. The issue or the asset with this issue does not exist.} other {# issue statuses cannot change. The issues or the assets with these issues do not exist.} }"
  },
  [Error.ASSET_STATUS_IS_REMOVED]: {
    id: "issues_page.issues.update.error.unable_to_change_issue_status_when_asset_is_removed",
    defaultMessage:
      "{ num, plural, zero {# issue statuses cannot change.} one {# issue status cannot change.} other {# issue statuses cannot change.} } You cannot change the issue status when the asset with the issue is removed."
  },
  [Error.ISSUE_STATUS_IS_CLOSED]: {
    id: "issues_page.issues.update.error.unable_to_change_issue_status_when_asset_is_closed",
    defaultMessage:
      "{ num, plural, zero {# issue statuses cannot change. The issue is closed.} one {# issue status cannot change. The issue is closed.} other {# issue statuses cannot change. The issues are closed.} }"
  },
  [Error.ISSUE_HAS_THE_SAME_STATUS]: {
    id: "issues_page.issues.update.error.unable_to_change_status_to_the_same_one",
    defaultMessage:
      "{ num, plural, zero {# issue statuses cannot change.} one {# issue status cannot change} other {# issue statuses cannot change.} } You cannot change the issue status to the same status."
  },
  [Error.ISSUE_MODIFIED]: {
    id: "issues_page.issues.update.error.unable_to_change_status_when_modified",
    defaultMessage:
      "{ num, plural, zero {# issue statuses cannot change. This issue status changed before you completed your changes.} one {# issue status cannot change. This issue status changed before you completed your changes.} other {# issue statuses cannot change. These issue statuses changed before you completed your changes.} }"
  },
  [Error.COMMENT_IS_MANDATORY]: {
    id: "issues_page.issues.update.error.comment_is_mandatory",
    defaultMessage:
      "{ num, plural, zero {# issue status cannot change.} one {# issue status cannot change.} other {# issue statuses cannot change.} } A comment is mandatory."
  }
};

export default messageTranslationKeys;
