// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import {
  IChart,
  IChartGroup
} from "@apm/widgets/build/widgets/ParameterCharts";
import { IntlShape } from "react-intl";
import { voltageStressSettings } from "../constants/voltageStressSettings";
import ChartGroup from "../models/ChartGroup";
import IVoltageStressData from "../models/IVoltageStressData";
import mapToChart from "../utils/mapToChart";

const getChartGroups = (
  intl: IntlShape,
  voltageStressData: IVoltageStressData,
  chartGroup: ChartGroup
): IChartGroup[] => {
  const charts: IChartGroup[] = [];

  if (voltageStressData) {
    const groupConfig =
      voltageStressSettings[voltageStressData.TransformerType][chartGroup];
    const chartList: IChart[] = groupConfig.map((chartName) =>
      mapToChart(
        intl,
        chartName,
        voltageStressData.Charts[chartName],
        chartGroup
      )
    );

    charts.push({
      name: "VoltageStress",
      charts: chartList,
      chartsInRow: chartGroup === "Percentages" ? 1 : 2
    });
  }

  return charts;
};

export default getChartGroups;
