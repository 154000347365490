// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { WidgetNoDataMessage } from "components/common/widget/Widget";

const BushingVisualizationNoData = () => (
  <div className="bushing-visualization container">
    <WidgetNoDataMessage />
  </div>
);

export default BushingVisualizationNoData;
