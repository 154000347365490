// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import dayjs from "dayjs";
import React from "react";
import {
  FormattedDate,
  FormattedNumber,
  injectIntl,
  IntlShape
} from "react-intl";
import isValidDateString from "utils/isValidDateString";

interface IFormattedValueProps {
  intl: IntlShape;
  value: string | number;
  truncate?: boolean;
  unit?: string;
  additionalData?: string;
}

class FormattedValue extends React.Component<IFormattedValueProps, {}> {
  private getFormattedNumber(
    value: number,
    unit: string,
    additionalData: string
  ): JSX.Element {
    if (additionalData !== null && additionalData !== undefined)
      return (
        <span>
          <FormattedNumber value={value} /> {unit} ({additionalData})
        </span>
      );
    else
      return (
        <span>
          <FormattedNumber value={value} /> {unit}
        </span>
      );
  }

  private getFormatted(
    value: string | number,
    unit: string,
    additionalData: string
  ): JSX.Element {
    if (typeof value === "number") {
      return this.getFormattedNumber(value, unit, additionalData);
    } else if (isValidDateString(value)) {
      const dt = dayjs.utc(value);
      if (
        (dt.hour() === 0 &&
          dt.minute() === 0 &&
          dt.second() === 0 &&
          dt.millisecond() === 0) ||
        this.props.truncate
      ) {
        return <FormattedDate value={dt.toDate()} />;
      } else {
        dt.local();
        if (dt.second() === 0) {
          return (
            <FormattedDate
              value={dt.toDate()}
              year="numeric"
              month="numeric"
              day="numeric"
              hour="numeric"
              minute="numeric"
            />
          );
        } else {
          return (
            <FormattedDate
              value={dt.toDate()}
              year="numeric"
              month="numeric"
              day="numeric"
              hour="numeric"
              minute="numeric"
              second="numeric"
            />
          );
        }
      }
    } else if (typeof value === "string") {
      return <span>{value.linkify()}</span>;
    } else {
      return <span>{value}</span>;
    }
  }

  public render(): JSX.Element {
    return this.getFormatted(
      this.props.value,
      this.props.unit,
      this.props.additionalData
    );
  }
}

export default injectIntl(FormattedValue);
