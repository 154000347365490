// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import * as React from "react";

const TileContentEmpty = (): JSX.Element => (
  <div className="tile">
    <div className="tile-content empty" />
  </div>
);

export default TileContentEmpty;
