// Copyright 2016-2024 Hitachi Energy. All rights reserved.

const ltcOilPreservations = [
  "desiccant_breather",
  "free_breather",
  "none",
  "sealed",
  "selector",
  "unknown",
  "vacuum"
];

export default ltcOilPreservations;
