// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import AgingSubTabs from "features/detailpage/features/aging/components/AgingSubTabs";
import AgingTotalOperationsContainer from "features/detailpage/features/aging/containers/AgingOperationsContainer";
import { PureComponent, ReactNode } from "react";
import "./AgingTab.less";

interface IAgingWidgetProps {
  children?: ReactNode;
}

const AgingWidget = ({ children }: IAgingWidgetProps) => (
  <div className="card">
    <div className="card-body">{children}</div>
  </div>
);

interface IAgingTabProps {
  assetId: string;
  modelId?: string;
}

class AgingTab extends PureComponent<IAgingTabProps> {
  render() {
    const { assetId } = this.props;

    return (
      <div className="aging-tab">
        <div>
          <AgingWidget>
            <AgingTotalOperationsContainer assetId={assetId} />
          </AgingWidget>
        </div>
        <div>
          <AgingSubTabs assetId={assetId} />
        </div>
      </div>
    );
  }
}

export default AgingTab;
