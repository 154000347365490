// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React, { ReactNode } from "react";
import { Typography } from "antd";

import "./Label.less";

const { Title } = Typography;

interface ILabelProps {
  children?: ReactNode;
}

const Label = ({ children }: ILabelProps) => {
  return (
    <Title level={4} className="asset-ribbon-label">
      {children}
    </Title>
  );
};

export default Label;
