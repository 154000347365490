// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import loadData from "core/data/actions/loadData";
import UrlService from "core/data/services/UrlService";
import { config } from "utils/AppConfig";

export const dataKey = `MainDashboard_AssetRiskIndicators`;

const loadAssetRiskIndicators = () => {
  const url = UrlService.getApiUrl(config.api.homepage.assetRiskIndicators);
  return loadData({ key: dataKey, url, method: "get" });
};

export default loadAssetRiskIndicators;
