// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Radio } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import styled from "styled-components";

export interface ISwitchItem {
  id: string;
  message: MessageDescriptor;
}

export interface ISwitchProps {
  className?: string;
  defaultSelected?: string;
  disabled?: boolean;
  items: ISwitchItem[];
  label?: MessageDescriptor;
  onChange?: (id: string) => void;
}

const Switch: FC<ISwitchProps> = ({
  className = "",
  defaultSelected,
  disabled,
  items,
  label,
  onChange
}) => {
  const [selected, setSelected] = useState(defaultSelected);

  const handleChange = useCallback(
    (e: RadioChangeEvent) => {
      setSelected(e.target.value);
      if (onChange) onChange(e.target.value);
    },
    [onChange]
  );

  useEffect(() => {
    setSelected(defaultSelected);
  }, [defaultSelected]);

  const renderItems = useMemo(() => {
    return items.map((i: ISwitchItem) => {
      return (
        <Radio.Button key={i.id} value={i.id} disabled={disabled}>
          <FormattedMessage {...i.message} />
        </Radio.Button>
      );
    });
  }, [items, disabled]);

  return (
    <div className={`switch-control ${className}`}>
      {label && (
        <div className="control-label default-grey-label">
          <FormattedMessage {...label} />
        </div>
      )}
      <StyledRadioGroup
        defaultValue={selected}
        onChange={handleChange}
        value={selected}
      >
        {renderItems}
      </StyledRadioGroup>
    </div>
  );
};

const StyledRadioGroup = styled(Radio.Group)`
  & {
    white-space: nowrap;
  }
`;

export default Switch;
