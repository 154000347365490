// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Data from "core/data/models/Data";
import update from "immutability-helper";
import { isNil } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IntlShape, useIntl } from "react-intl";
import IThresholdCorrection from "../../../models/IThresholdCorrection";
import IMechanicalPoleGroup from "../models/IMechanicalPoleGroup";
import IMechanicalPoles from "../models/IMechanicalPoles";

const useMechanicalTab = (
  assetId: string,
  mechanical: Data<IMechanicalPoles> | undefined | null,
  adjustExpectedRange: (
    intl: IntlShape,
    parameterName: string,
    success?: (correction: IThresholdCorrection) => void,
    error?: () => void
  ) => void,
  acknowledge: (
    intl: IntlShape,
    parameterName: string,
    success?: (correction: IThresholdCorrection) => void,
    error?: () => void
  ) => void,
  loadMechanical: () => void,
  removeMechanical: () => void
) => {
  const intl = useIntl();
  const [thresholdsCorrections, setThresholdsCorrections] = useState<
    {
      parameterName: string;
      correction: IThresholdCorrection;
    }[]
  >([]);

  const mechanicalStatus = useMemo(
    () => mechanical?.status,
    [mechanical?.status]
  );

  const mechanicalData = useMemo(() => {
    if (isNil(mechanical?.data)) return undefined;
    if (!thresholdsCorrections.length) return mechanical?.data;

    const updates: {
      poleId: string;
      groupId: number;
      parameterId: number;
      valueId: number;
      correction: IThresholdCorrection;
    }[] = [];

    Object.entries(mechanical.data).forEach((entry) => {
      const poleId = entry[0];
      const poleValue = entry[1] as IMechanicalPoleGroup[] | undefined | null;

      poleValue?.forEach((group, i) => {
        group.Parameters?.forEach((parameter, j) => {
          parameter.Values?.forEach((value, k) => {
            const l = thresholdsCorrections.findIndex(
              (c) => c.parameterName === value.ExternalId
            );

            if (l >= 0) {
              updates.push({
                poleId,
                groupId: i,
                parameterId: j,
                valueId: k,
                correction: thresholdsCorrections[l].correction
              });
            }
          });
        });
      });
    });

    let newValue = mechanical.data;
    updates.forEach((u) => {
      newValue = update(newValue, {
        [u.poleId]: {
          [u.groupId]: {
            Parameters: {
              [u.parameterId]: {
                Values: {
                  [u.valueId]: {
                    UserThresholdCorrection: { $set: u.correction }
                  }
                }
              }
            }
          }
        }
      });
    });

    return newValue;
  }, [mechanical?.data, thresholdsCorrections]);

  useEffect(() => {
    loadMechanical();
    setThresholdsCorrections([]);
    return () => {
      removeMechanical();
    };
  }, [assetId, loadMechanical, removeMechanical]);

  const updateThresholdsCorrections = useCallback(
    (parameterName: string, correction: IThresholdCorrection) => {
      const i = thresholdsCorrections.findIndex(
        (c) => c.parameterName === parameterName
      );
      if (i < 0) {
        const newValue = update(thresholdsCorrections, {
          $push: [
            {
              parameterName,
              correction
            }
          ]
        });

        setThresholdsCorrections(newValue);
      } else {
        const newValue = update(thresholdsCorrections, {
          [i]: { correction: { $set: correction } }
        });

        setThresholdsCorrections(newValue);
      }
    },
    [thresholdsCorrections]
  );

  const handleAdjustExpectedRangeClick = useCallback(
    (parameterName: string, success?: () => void, error?: () => void) => {
      adjustExpectedRange(
        intl,
        parameterName,
        (correction: IThresholdCorrection) => {
          updateThresholdsCorrections(parameterName, correction);
          if (success) success();
        },
        () => {
          if (error) error();
        }
      );
    },
    [adjustExpectedRange, intl, updateThresholdsCorrections]
  );

  const handleAcknowledgeClick = useCallback(
    (parameterName: string, success?: () => void, error?: () => void) => {
      acknowledge(
        intl,
        parameterName,
        (correction: IThresholdCorrection) => {
          updateThresholdsCorrections(parameterName, correction);
          if (success) success();
        },
        error
      );
    },
    [acknowledge, intl, updateThresholdsCorrections]
  );

  return {
    handleAdjustExpectedRangeClick,
    handleAcknowledgeClick,
    mechanicalStatus,
    mechanicalData
  };
};

export default useMechanicalTab;
