// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Data from "core/data/models/Data";
import { IState } from "reducers/Index";
import { createSelector } from "reselect";
import { getDataKey } from "../actions/loadParameters";
import IParameterInfo from "../models/IParameterInfo";

const getParametersCountSelector = createSelector(
  (state: IState) => (assetId: string) => {
    const parameters = state.data[getDataKey(assetId)] as Data<
      IParameterInfo[]
    >;
    return parameters?.data?.length || 0;
  },
  (parameters) => parameters
);

export default getParametersCountSelector;
