// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState
} from "react";

interface IRouterContextValue {
  disableBingMaps: boolean;
  setDisableBingMaps: (value: boolean) => void;
  experimental: boolean;
  setExperimental: (value: boolean) => void;
  locale: string;
  setLocale: (value: string) => void;
  historyLength: number;
  setHistoryLength: Dispatch<SetStateAction<number>>;
}

const RouterContext = createContext<IRouterContextValue>({
  disableBingMaps: undefined,
  setDisableBingMaps: () => {},
  experimental: undefined,
  setExperimental: () => {},
  locale: undefined,
  setLocale: () => {},
  historyLength: undefined,
  setHistoryLength: () => {}
});

interface IRouterContextProviderProps {
  children?: ReactNode;
}

export const RouterContextProvider = ({
  children
}: IRouterContextProviderProps) => {
  const [historyLength, setHistoryLength] = useState(0);
  const [disableBingMaps, setDisableBingMaps] = useState<boolean>();
  const [experimental, setExperimental] = useState<boolean>();
  const [locale, setLocale] = useState<string>();

  return (
    <RouterContext.Provider
      value={{
        disableBingMaps,
        setDisableBingMaps,
        experimental,
        setExperimental,
        locale,
        setLocale,
        historyLength,
        setHistoryLength
      }}
    >
      {children}
    </RouterContext.Provider>
  );
};

export default RouterContext;
