// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import ResizeWrapper from "common/ResizeWrapper";
import RogersRatio3DChart from "features/detailpage/features/rogersratio/components/RogersRatio3D/RogersRatio3DChart";
import useProcessRogersRatio3DData from "features/detailpage/features/rogersratio/hooks/useProcessRogersRatio3DData";
import useBoolean from "hooks/useBoolean";
import { isEqual } from "lodash";
import { useEffect } from "react";
import { RogersRatioData } from "../../models/RogersRatioData";
import RogersRatioDataSourceSwitch from "./RogersRatioDataSourceSwitch";

type RogersRatio3DChartWrapperProps = {
  rogersRatioData: RogersRatioData;
};

const RogersRatio3DChartWrapper = ({
  rogersRatioData
}: RogersRatio3DChartWrapperProps) => {
  const { rogersRatioZones, tracePoints, lastDataSource } =
    useProcessRogersRatio3DData(rogersRatioData);

  const {
    value: showOfflineOnly,
    toggle: toggleShowOfflineOnly,
    setValue: setShowOfflineOnly
  } = useBoolean(true);

  useEffect(() => {
    if (isEqual(lastDataSource, "Online")) {
      setShowOfflineOnly(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastDataSource]);

  return (
    <>
      <RogersRatioDataSourceSwitch
        toggleShowOfflineOnly={toggleShowOfflineOnly}
      />
      {rogersRatioZones.length > 0 ? (
        <ResizeWrapper>
          <RogersRatio3DChart
            rogersRatioData={rogersRatioData}
            rogersRatioZones={rogersRatioZones}
            tracePoints={tracePoints}
            showOfflineOnly={showOfflineOnly}
          />
        </ResizeWrapper>
      ) : (
        <div className="tile">
          <div className="tile-content empty" />
        </div>
      )}
    </>
  );
};

export default RogersRatio3DChartWrapper;
