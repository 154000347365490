// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React from "react";
import styled from "styled-components";

import FilterBar from "common/FilterBar";
import AssetRiskChangesGrid from "./AssetRiskChangesGrid";
import useAssetRiskChanges from "../hooks/useAssetRiskChanges";

interface IAssetRiskChangesProps {
  className?: string;
}

const AssetRiskChanges = ({ className }: IAssetRiskChangesProps) => {
  const { filters, handleFilter } = useAssetRiskChanges();

  return (
    <div className={`${className} asset-risk-changes`}>
      <FilterBar
        onFilter={handleFilter}
        namespace="asset-risk-changes"
        useAssetOrganizationWithRegionAndLocation
        useAssetTypeWithSubType
        useAssetCompanyHierarchy
      >
        {filters && <AssetRiskChangesGrid filters={filters} />}
      </FilterBar>
    </div>
  );
};

const StyledAssetRiskChanges = styled(AssetRiskChanges)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export default StyledAssetRiskChanges;
