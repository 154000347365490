// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import AssetRiskKPI from "@apm/widgets/build/widgets/AssetRiskKPI";
import SearchParams from "@pg/common/build/models/SearchParams";
import {
  colorRiskHigh,
  colorRiskLow,
  colorRiskMedium,
  colorRiskUnknown
} from "@pg/common/build/styles/ColorVariables";
import { Col } from "antd";
import Processing from "components/common/Processing";
import {
  WidgetErrorMessage,
  WidgetNoDataMessage
} from "components/common/widget/Widget";
import { routes } from "core/app/components/AppRoutes";
import { useAppNavigate } from "core/app/components/RouterProvider";
import Data, { Statuses } from "core/data/models/Data";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router";
import IAssetRiskTrend from "../models/IAssetRiskTrend";
import TrendTypes from "../models/TrendTypes";

interface IAssetRiskTrendsProps {
  trends: Data<IAssetRiskTrend[]>;
}

const AssetRiskTrends = ({ trends }: IAssetRiskTrendsProps) => {
  const intl = useIntl();
  const navigate = useAppNavigate();
  const location = useLocation();

  const handleClick = useCallback(
    (trendType: TrendTypes) => {
      const searchParams = new SearchParams(location.search);
      searchParams.set("f_ar", `["${trendType}"]`);

      navigate({
        pathname: routes.assets.pathname,
        search: searchParams.toString()
      });
    },
    [navigate, location.search]
  );

  return (
    <>
      {trends?.status === Statuses.Loading && (
        <Col span={24}>
          <Processing />
        </Col>
      )}
      {trends?.status === Statuses.Succeeded &&
        trends?.data?.map((t) => (
          <Col key={t.Type} span={6} data-qa={`tile-${t.Type}`}>
            <AssetRiskKPI
              color={
                t.Type === "High"
                  ? colorRiskHigh
                  : t.Type === "Medium"
                  ? colorRiskMedium
                  : t.Type === "Low"
                  ? colorRiskLow
                  : colorRiskUnknown
              }
              formatXTick={(date: Date) =>
                intl.formatDate(date, {
                  year: "numeric",
                  month: "short"
                })
              }
              formatYTick={(value: number) => intl.formatNumber(value)}
              id={`asset-risk-summary-${t.Type}`}
              onClick={() => handleClick(t.Type)}
              trend={t.Items.map((i) => {
                const year = i.Year.toString();
                const month = i.Month.toString().padStart(2, "0");

                return {
                  date: new Date(`${year}-${month}`),
                  assetCount: i.NumberOfAssets
                };
              })}
              translations={{
                risk: intl.formatMessage({
                  defaultMessage: t.Type,
                  id: `assets-summary.trends.trend.${t.Type}`
                })
              }}
            />
          </Col>
        ))}
      {trends?.status === Statuses.Failed && (
        <Col span={24}>
          <WidgetErrorMessage />
        </Col>
      )}
      {trends?.status === Statuses.NotFound && (
        <Col span={24}>
          <WidgetNoDataMessage />
        </Col>
      )}
    </>
  );
};

export default AssetRiskTrends;
