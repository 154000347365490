// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import ISetDataAction from "../models/ISetDataAction";

import Actions from "../models/Actions";
import Data from "../models/Data";

const setDataAction = <T>(
  key: string,
  request: JQueryXHR,
  data?: T
): ISetDataAction<T> => {
  const action: ISetDataAction<T> = {
    type: Actions.SetData,
    key,
    data: new Data(request, data)
  };
  return action;
};

export default setDataAction;
