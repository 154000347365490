// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { isEqual } from "lodash";
import * as React from "react";

// utils
import Data, { Statuses } from "core/data/models/Data";
import TrendChart from "features/assetriskdashboard/models/TrendChart";

// components
import TileErrorMessage from "common/tile/TileErrorMessage";
import AssetRiskDashboardTrend from "features/assetriskdashboard/components/AssetRiskDashboardTrend";
import AssetRiskDashboardLoading from "features/assetriskdashboard/components/common/AssetRiskDashboardLoading";
import AssetRiskDashboardWrapper from "features/assetriskdashboard/components/common/AssetRiskDashboardWrapper";
import ContainerWrapper from "features/assetriskdashboard/components/common/ContainerWrapper";
import TileContentEmpty from "features/assetriskdashboard/components/common/TileContentEmpty";

interface IAssetRiskDashboardBodyProps {
  trendChart: Data<TrendChart>;
}

class AssetRiskDashboardBody extends React.Component<IAssetRiskDashboardBodyProps> {
  render() {
    const { trendChart } = this.props;
    const { Loading, Succeeded, Failed } = Statuses;

    return trendChart ? (
      <AssetRiskDashboardWrapper trendChartData={trendChart.data}>
        {isEqual(trendChart.status, Loading) ? (
          <AssetRiskDashboardLoading />
        ) : isEqual(trendChart.status, Succeeded) ? (
          <AssetRiskDashboardTrend trendChart={trendChart} />
        ) : isEqual(trendChart.status, Failed) ? (
          <ContainerWrapper>
            <TileErrorMessage
              messageId="homepage.server_error"
              defaultMessage="Sorry, we have problems with the server connection."
            />
          </ContainerWrapper>
        ) : (
          <ContainerWrapper>
            <TileContentEmpty />
          </ContainerWrapper>
        )}
      </AssetRiskDashboardWrapper>
    ) : null;
  }
}

export default AssetRiskDashboardBody;
