// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Action, Reducer } from "redux/index";

import customerReducer, {
  IState as ICustomerState
} from "core/app/reducers/settings/CustomerReducer";
import environmentReducer, {
  IState as IEnvironmentState
} from "core/app/reducers/settings/EnvironmentReducer";
import mapProviderReducer, {
  IState as IMapProviderState
} from "core/app/reducers/settings/MapProviderReducer";
import tenantReducer, {
  IState as ITenantState
} from "core/app/reducers/settings/TenantReducer";
import userReducer, {
  IState as IUserState
} from "core/app/reducers/settings/UserReducer";

export interface IState
  extends ICustomerState,
    IEnvironmentState,
    IMapProviderState,
    IUserState,
    ITenantState {}

const settingsReducer: Reducer<IState> = (state: IState, action: Action) => {
  state = customerReducer(state, action) as IState;
  state = environmentReducer(state, action) as IState;
  state = mapProviderReducer(state, action) as IState;
  state = tenantReducer(state, action) as IState;
  state = userReducer(state, action) as IState;
  return state;
};

export default settingsReducer;
