// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Modal from "common/Modal";
import styled from "styled-components";
import IAssetParameter from "../models/IAssetParameter";
import ModalContent from "./ModalContent";

interface IBulkInspectionModalProps {
  assetParameters: IAssetParameter[];
  visible: boolean;
  onCancel?: () => void;
  className?: string;
}

const BulkInspectionModal = ({
  assetParameters,
  visible,
  onCancel,
  className
}: IBulkInspectionModalProps) => {
  return (
    <Modal
      centered={true}
      width="75%"
      bodyStyle={{
        height: "85vh"
      }}
      open={visible}
      footer={null}
      destroyOnClose={true}
      onCancel={onCancel}
      className={className}
      data-qa="BulkInspectionModal"
    >
      <ModalContent assetParameters={assetParameters} onCancel={onCancel} />
    </Modal>
  );
};

const StyledBulkInspectionModal = styled(BulkInspectionModal)`
  .ant-modal-body {
    display: flex;
    flex-direction: row;
  }
`;

export default StyledBulkInspectionModal;
