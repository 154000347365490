// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import BBChart from "common/chart/components/BBChart";
import ResizeWrapper from "common/ResizeWrapper";
import React from "react";
import useAgingVisualizationChartConfiguration from "../../hooks/useAgingVisualizationChartConfiguration";
import IAgingVisualization from "../../models/IAgingVisualization";
import "./AgingVisualizationChart.less";
import AgingVisualizationChartLegend from "./AgingVisualizationChartLegend";

interface IAgingVisualizationChartProps {
  agingData: IAgingVisualization;
}

const AgingVisualizationChart = ({
  agingData
}: IAgingVisualizationChartProps) => {
  const [configuration] = useAgingVisualizationChartConfiguration(agingData);

  return (
    <div className="aging-visualization-chart">
      <ResizeWrapper>
        <BBChart configuration={configuration}></BBChart>
      </ResizeWrapper>
      <AgingVisualizationChartLegend />
    </div>
  );
};

export default AgingVisualizationChart;
