// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Tooltip from "common/Tooltip";
import useNavToAsset from "hooks/useNavToAsset";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { fontSizeSmall, spacingSmall } from "styles/StyleVariables";

interface IAssetWithLocationColumnProps {
  assetId: string;
  location?: string;
  type?: string;
  name?: string;
  className?: string;
}

const AssetWithLocationColumn = ({
  assetId,
  location,
  type,
  name,
  className
}: IAssetWithLocationColumnProps) => {
  const { navigateTo } = useNavToAsset();

  return (
    <div className={className}>
      <Tooltip
        title={
          <div>
            {assetId && <span>{assetId}</span>}
            {assetId && name && <span> - </span>}
            {name && <span>{name}</span>}
          </div>
        }
      >
        <Link
          data-qa="link"
          className="anchor id link-label"
          to={navigateTo(assetId)}
        >
          {assetId && <span>{assetId}</span>}
          {assetId && name && <span> - </span>}
          {name && <span>{name}</span>}
        </Link>
      </Tooltip>
      {location && <span className="details">{location}</span>}
      {location && type && <span className="details"> / </span>}
      {type && <span className="details">{type}</span>}
    </div>
  );
};

const gridPadding = "10px";

export default styled(AssetWithLocationColumn)`
  padding: ${gridPadding};

  .details {
    margin-top: ${spacingSmall};
    font-size: ${fontSizeSmall};
  }
`;
