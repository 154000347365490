// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { IState } from "reducers/Index";

const getAssetRiskConfigurationSelector = createSelector(
  (state: IState) => state.detailPage.riskMatrix.assetRiskConfiguration,
  assetRiskConfiguration => assetRiskConfiguration
);

export default getAssetRiskConfigurationSelector;
