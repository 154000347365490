// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import SearchParams from "@pg/common/build/models/SearchParams";
import ButtonWithIcon from "common/button/ButtonWithIcon";
import LabeledRow from "components/common/LabeledRow";
import Processing from "components/common/Processing";
import { WidgetErrorMessage } from "components/common/widget/Widget";
import { routes } from "core/app/components/AppRoutes";
import { useAppNavigate } from "core/app/components/RouterProvider";
import Data, { Statuses } from "core/data/models/Data";
import { BoxPlotData } from "features/detailpage/features/familyinfo/components/BoxPlot";
import BoxPlotChart from "features/detailpage/features/familyinfo/components/BoxPlotChart";
import { IFamilyInfo } from "features/detailpage/features/familyinfo/reducers/FamilyInfoReducer";
import { DataTypes as ScatterPlotDataTypes } from "features/familyanalytics/services/ScatterPlotWidgetService";
import { MouseEvent, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import "./FamilyInfo.less";
import ResizeWrapper from "common/ResizeWrapper";
import styled from "styled-components";

export interface IFamilyInfoData {
  familyInfo: Data<IFamilyInfo>;
}

export interface IFamilyInfoOwnProps {
  assetId: string;
  title?: string;
  familyAnalyticsDataType?: ScatterPlotDataTypes;
  familyAnalyticsSeriesName?: string;
}

export interface IFamilyInfoProps
  extends IFamilyInfoOwnProps,
    IFamilyInfoData {}

const FamilyInfo = ({
  assetId,
  familyInfo,
  familyAnalyticsDataType,
  familyAnalyticsSeriesName,
  title
}: IFamilyInfoProps) => {
  const navigate = useAppNavigate();

  const getBoxPlotData = useCallback((familyInfo: IFamilyInfo) => {
    return new BoxPlotData(
      familyInfo.Value,
      familyInfo.Q1,
      familyInfo.Median,
      familyInfo.Q3,
      familyInfo.UpperExtreme,
      familyInfo.LowerExtreme
    );
  }, []);

  const handleClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  }, []);

  const openFamilyAnalytics = useCallback(() => {
    const { Manufacturer, ManufacturerModel } = familyInfo.data;

    const searchParams = new SearchParams({
      assetId,
      dataTypeY: familyAnalyticsDataType,
      seriesNameY: familyAnalyticsSeriesName
    });

    if (Manufacturer && ManufacturerModel)
      searchParams.append(`f_am`, `["${Manufacturer}","${ManufacturerModel}"]`);
    else if (Manufacturer) searchParams.append(`f_am`, `["${Manufacturer}"]`);
    searchParams.append(`f_as`, JSON.stringify(["InService"]));
    navigate({
      pathname: routes.familyAnalytics.pathname,
      search: searchParams.toString()
    });
  }, [
    assetId,
    familyAnalyticsDataType,
    familyAnalyticsSeriesName,
    familyInfo.data,
    navigate
  ]);

  return (
    <>
      {familyInfo.status === Statuses.Loading && <Processing />}
      {familyInfo.status === Statuses.Succeeded && (
        <div
          className="family-info-popover-container"
          data-qa="family-info-popover-container"
          onClick={handleClick}
        >
          {title && <span className="large-content">{title}</span>}
          <LabeledRow
            label={{
              id: "asset.nameplate.manufacturer",
              defaultMessage: "Manufacturer"
            }}
            value={familyInfo.data.Manufacturer}
          />
          <LabeledRow
            label={{
              id: "asset.nameplate.manufacturer_model",
              defaultMessage: "Manufacturer model"
            }}
            value={familyInfo.data.ManufacturerModel}
          />
          <div className="labeled-row">
            <span className="label">
              <FormattedMessage
                id={"detail_page.family_info.family_count"}
                defaultMessage="Family count"
              />
            </span>
            <span className="value">
              <FormattedMessage
                id="detail_page.family_info.family_count_value"
                defaultMessage="{count} of {familyCount}"
                values={{
                  count: familyInfo.data.Count,
                  familyCount: familyInfo.data.FamilyCount
                }}
              />
            </span>
          </div>
          <ChartWrapper>
            <ResizeWrapper>
              <BoxPlotChart data={getBoxPlotData(familyInfo.data)} />
            </ResizeWrapper>
          </ChartWrapper>

          {familyAnalyticsDataType && (
            <div className="compare-button-container">
              <ButtonWithIcon
                type="default"
                className="compare-button"
                onClick={openFamilyAnalytics}
                icon={<Icon name="speed" />}
                dataQa="compare-button"
              >
                <FormattedMessage
                  id="detail_page.family_info.compare_to_family"
                  defaultMessage="Compare to Family"
                />
              </ButtonWithIcon>
            </div>
          )}
        </div>
      )}
      {(familyInfo.status === Statuses.Failed ||
        familyInfo.status === Statuses.NotFound) && (
        <div className="error-message">
          <WidgetErrorMessage
            messageId="global.empty"
            messageDefault={familyInfo.message}
            messageValues={familyInfo.message}
          />
        </div>
      )}
    </>
  );
};

const ChartWrapper = styled.div`
  width: 300px;
  height: 120px;
`;

export default FamilyInfo;
