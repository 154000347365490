// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import { Spin } from "antd";
import Popover, { popoverClick } from "common/popover/Popover";
import {
  WidgetErrorMessage,
  WidgetNoDataMessage
} from "components/common/widget/Widget";
import Data, { Statuses } from "core/data/models/Data";
import {
  loadDerivativeParameterFamilyInfoAction,
  loadParameterFamilyInfoAction
} from "features/detailpage/features/familyinfo/actions/FamilyInfoActions";
import FamilyInfo from "features/detailpage/features/familyinfo/components/FamilyInfo";
import {
  getFamilyInfoSelector,
  IFamilyInfo
} from "features/detailpage/features/familyinfo/reducers/FamilyInfoReducer";
import ParameterSources from "models/ParameterSources";
import { useCallback, useState } from "react";
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import { AppDispatch } from "store";
import usePopoverHideOnScroll from "../../ParameterHistogram/hooks/usePopoverHideOnScroll";

interface IActionFamilyInfoActions {
  loadDerivativeParameterFamilyInfoAction: () => void;
  loadParameterFamilyInfoAction: () => void;
}

export interface IFamilyInfoData {
  familyInfo: Data<IFamilyInfo>;
}

export interface IActionFamilyInfoOwnProps {
  assetId: string;
  parameterName: string;
  translatedParameterName: string;
  parameterSource: ParameterSources;
}

type ActionFamilyInfoProps = IActionFamilyInfoOwnProps &
  IFamilyInfoData &
  IActionFamilyInfoActions;

const ActionFamilyInfo = ({
  assetId,
  parameterName,
  translatedParameterName,
  parameterSource,
  familyInfo,
  loadDerivativeParameterFamilyInfoAction,
  loadParameterFamilyInfoAction
}: ActionFamilyInfoProps) => {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const assetParameterDoesNotExistsMessage = "ASSET_PARAMETER_DOES_NOT_EXISTS";

  const scrollHandle = () => {
    setPopoverVisible(false);
  };

  usePopoverHideOnScroll(popoverVisible, scrollHandle);

  const handleActionClick = useCallback(() => {
    if (!popoverVisible) {
      if (parameterSource === "DerivativeParameter")
        Promise.all([loadDerivativeParameterFamilyInfoAction()]).finally(() => {
          setPopoverVisible(true);
        });
      else
        Promise.all([loadParameterFamilyInfoAction()]).finally(() => {
          setPopoverVisible(true);
        });
    }
  }, [
    loadDerivativeParameterFamilyInfoAction,
    loadParameterFamilyInfoAction,
    parameterSource,
    popoverVisible
  ]);

  const handlePopoverHide = useCallback(() => {
    setPopoverVisible(false);
  }, []);

  return (
    <Popover
      content={
        familyInfo?.status === Statuses.NotFound ||
        (familyInfo?.status === Statuses.Failed &&
          familyInfo?.responseJSON === assetParameterDoesNotExistsMessage) ? (
          <WidgetNoDataMessage />
        ) : familyInfo?.status === Statuses.Failed ? (
          <WidgetErrorMessage />
        ) : (
          <FamilyInfo
            familyInfo={familyInfo}
            assetId={assetId}
            title={translatedParameterName}
            familyAnalyticsDataType={
              parameterSource === "DerivativeParameter"
                ? "DerivativeParameter"
                : "Parameter"
            }
            familyAnalyticsSeriesName={parameterName}
          />
        )
      }
      onHide={handlePopoverHide}
      overlayClassName="family-info-parameters-popover family-info-popover"
      placement="topRight"
      visible={popoverVisible}
      destroyTooltipOnHide
    >
      <span
        className="action-family-info-popover-icon"
        onClick={handleActionClick}
      >
        {familyInfo?.status === Statuses.Loading ? (
          <Spin className="icon" />
        ) : (
          <Icon
            dataQa="family-info-popover"
            name="speed"
            className={`${popoverClick}`}
          />
        )}
      </span>
    </Popover>
  );
};

export function mapStateToProps(state: IState): IFamilyInfoData {
  return {
    familyInfo: getFamilyInfoSelector(state)
  };
}

const mapDispatchToProps = (
  dispatch: AppDispatch,
  ownProps: IActionFamilyInfoOwnProps
) => {
  return {
    loadDerivativeParameterFamilyInfoAction: () =>
      dispatch(
        loadDerivativeParameterFamilyInfoAction(
          ownProps.assetId,
          ownProps.parameterName
        )
      ),
    loadParameterFamilyInfoAction: () =>
      dispatch(
        loadParameterFamilyInfoAction(ownProps.assetId, ownProps.parameterName)
      )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionFamilyInfo);
