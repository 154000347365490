// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { RouterContext } from "core/app/components/RouterProvider";
import { Children, ReactNode, useContext, useEffect } from "react";

export interface ITranslationsProviderActions {
  loadTranslations: (locale: string) => void;
}

export interface ITranslationsProviderData {}

export interface ITranslationsProviderProps
  extends ITranslationsProviderActions,
    ITranslationsProviderData {
  children?: ReactNode;
}

const TranslationsProvider = ({
  children,
  loadTranslations
}: ITranslationsProviderProps) => {
  const { locale } = useContext(RouterContext);

  useEffect(() => {
    loadTranslations(locale);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  return <>{Children.toArray(children)}</>;
};

export default TranslationsProvider;
