// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import ISF6ChartLine from "@apm/widgets/build/widgets/SF6Chart/models/ISF6ChartLine";
import ISF6ChartRegion from "@apm/widgets/build/widgets/SF6Chart/models/ISF6ChartRegion";
import ISF6ChartSeries from "@apm/widgets/build/widgets/SF6Chart/models/ISF6ChartSeries";
import RegionClasses from "@apm/widgets/build/widgets/SF6Chart/models/RegionClasses";
import { colorStatusBlue } from "@pg/common/build/styles/ColorVariables";
import { ITrendChart } from "common/chart/components/TrendLineChart";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { IModelDetails } from "../../DielectricTab/DielectricTab";
import { formatTwoDigitDateTime } from "common/DateTime/utils/dateFormatters";

const useSF6PressureChartProps = (
  model: IModelDetails,
  trendChart: ITrendChart
) => {
  const intl = useIntl();
  const { modelId, implementationId } = model;

  const calculateHeight = () => window.innerHeight - 500;
  const [height, setHeight] = useState<number>(calculateHeight());

  const { DataSeries, Regions, Lines, Unit } = trendChart;

  const series: ISF6ChartSeries[] = useMemo(() => {
    const series: ISF6ChartSeries[] = [];

    DataSeries &&
      Object.keys(DataSeries).forEach((key) => {
        series.push({
          name: key,
          displayName: intl.formatMessage({
            id: `${modelId}.parameter.${key}`,
            defaultMessage: intl.formatMessage({
              id: `${implementationId}.parameter.${key}`,
              defaultMessage: intl.formatMessage({
                id: key,
                defaultMessage: key
              })
            })
          }),
          values: DataSeries[key],
          unit: Unit
        });
      });

    return series;
  }, [DataSeries, intl, modelId, implementationId, Unit]);

  const regions: ISF6ChartRegion[] = useMemo(() => {
    return (
      (!isEmpty(series) &&
        Regions?.map<ISF6ChartRegion>((r) => ({
          regionClass: r.Class as RegionClasses,
          displayName: intl.formatMessage({
            id: `threshold.${r.Class}`,
            defaultMessage: r.Class
          }),
          name: r.Class,
          start: r.Start,
          end: r.End
        }))) ||
      []
    );
  }, [Regions, intl, series]);

  const lines: ISF6ChartLine[] = useMemo(() => {
    return (
      (!isEmpty(series) &&
        Lines?.map<ISF6ChartLine>((l) => ({
          color: colorStatusBlue,
          displayName: intl.formatMessage({
            id: `threshold.${l.Description}`,
            defaultMessage: l.Description
          }),
          name: l.Class,
          value: l.Value
        }))) ||
      []
    );
  }, [Lines, intl, series]);

  const formatXTick = useCallback(
    (date: Date) => intl.formatDate(date),
    [intl]
  );

  const formatTooltipTitle = useCallback(
    (date: Date) => formatTwoDigitDateTime(intl, date),
    [intl]
  );

  const title = useMemo(
    () =>
      intl.formatMessage({
        id: "detail_page.widgets.circuit_breaker.dielectric.title.pressure",
        defaultMessage: "SF6 Pressure"
      }),
    [intl]
  );

  const handleResize = () => {
    setHeight(calculateHeight());
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    formatXTick,
    formatTooltipTitle,
    height,
    lines,
    regions,
    series,
    title
  };
};

export default useSF6PressureChartProps;
