// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import UrlService from "core/data/services/UrlService";
import { config } from "utils/AppConfig";
import loadData from "core/data/actions/loadData";

export const dataKey = "DuvalPentagons_Data";

export const loadDuvalPentagons = (assetId: string, dataSource: string) => {
  const url = UrlService.getApiUrl(
    config.api.detailPage.assetDuvalPentagonsGasesConcentrationUrl,
    [
      {
        name: "assetId",
        value: assetId
      },
      {
        name: "dataSource",
        value: dataSource
      }
    ]
  );

  return loadData({ key: dataKey, url, method: "get" });
};
