// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { useContext } from "react";
import styled from "styled-components";
import IScoreTrend from "../../../models/IScoreTrend";
import PrognosticChart from "../../PrognosticChart";
import usePrognosticTrendSeries from "../../PrognosticWidget/hooks/usePrognosticTrendSeries";
import SimulationModalContext from "../contexts/SimulationModalContext";
import SimulationStaticLegend from "./SimulationStaticLegend";

interface ISimulationChartProps {
  className?: string;
  simulationTrend: IScoreTrend[];
  simulationMaxValue: number;
  simulationMinValue: number;
}

const SimulationChart = ({
  className,
  simulationTrend,
  simulationMaxValue,
  simulationMinValue
}: ISimulationChartProps) => {
  const { modelId, implementationId, degradationScoreTrend, simulationConfig } =
    useContext(SimulationModalContext);

  const trendInfo = usePrognosticTrendSeries({
    degradationScoreTrend,
    simulationTrend,
    modelId,
    implementationId
  });

  return (
    <div className={className}>
      <div className="simulation-chart-wrapper">
        <PrognosticChart
          expanded={true}
          simulationSeries={trendInfo.simulationSeries}
          prognosisSeries={trendInfo.prognosisSeries}
          series={trendInfo.series}
          ready={trendInfo.ready}
        />
      </div>
      <SimulationStaticLegend
        modelId={modelId}
        implementationId={implementationId}
        defaultBeginValue={simulationConfig.DefaultBeginValue}
        defaultEndValue={simulationConfig.DefaultEndValue}
        maxValue={simulationMaxValue}
        minValue={simulationMinValue}
        parameterName={simulationConfig.ParameterName}
        unit={simulationConfig.Unit}
      />
    </div>
  );
};

const StyledSimulationChart = styled(SimulationChart)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .simulation-chart-wrapper {
    height: 90%;
  }
`;

export default StyledSimulationChart;
