// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React, { useCallback } from "react";

import { ITreeNode } from "common/form/components/Tree";
import ConfigurationBoxContainer from "features/notifications/containers/ConfigurationBoxContainer";

import useSetAllAssetTypesChecked from "features/notifications/hooks/useSetAllAssetTypesChecked";

import { NotificationsFormFields } from "features/notifications/selectors/getNotificationsFormSelector";

export interface IAssetTypesProps {
  notificationsEnabled: boolean;
  items: ITreeNode[];
  allAssetTypesChecked: boolean;
  defaultCheckedKeys?: string[];
}

const AssetTypes = ({
  items,
  notificationsEnabled,
  defaultCheckedKeys,
  allAssetTypesChecked
}: IAssetTypesProps) => {
  const { setAllAssetTypesChecked } = useSetAllAssetTypesChecked();
  const handleAllChecked = useCallback(
    (checked: boolean) => {
      setAllAssetTypesChecked(checked);
    },
    [setAllAssetTypesChecked]
  );

  return (
    <ConfigurationBoxContainer
      headerMessage={{
        id: "notifications.filter.assettype",
        description: "Asset Type",
        defaultMessage: "Asset Type"
      }}
      options={items}
      disabled={!notificationsEnabled}
      defaultCheckedKeys={defaultCheckedKeys}
      inputName={NotificationsFormFields.assetTypes}
      dataQa="AssetType"
      allChecked={allAssetTypesChecked}
      handleOnAllChecked={handleAllChecked}
    ></ConfigurationBoxContainer>
  );
};

export default AssetTypes;
