// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { useAppSelector } from "store";
import { loadKeyRiskChangesInfo as loadKeyRiskChangesInfoAction } from "../actions/KeyRiskChangesActions";
import { getKeyRiskChangesInfoSelector } from "../reducers/KeyRiskChangesReducer";

const useKeyRiskChanges = () => {
  const keyRiskChangesInfo = useAppSelector(getKeyRiskChangesInfoSelector);
  return { keyRiskChangesInfo, loadKeyRiskChangesInfoAction };
};

export default useKeyRiskChanges;
