// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { IState } from "reducers/Index";

const getCanBeSavedSelector = createSelector(
  (state: IState) => state.notifications.canBeSaved,
  data => data
);

export default getCanBeSavedSelector;
