// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Record } from "immutable";

import IssueModes from "./IssueModes";

const IssueMetaRecord = Record({
  id: "",
  mode: IssueModes.View,
  createRequest: null,
  commentCreateRequest: null,
  editRequest: null,
  editStatusRequest: null
});

export default IssueMetaRecord;
