// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import SearchParams from "@pg/common/build/models/SearchParams";
import { useMemo } from "react";
import { Location } from "react-router";

const assetIdSearchParam = "assetId";

const useAssetId = (location: Location) => {
  const searchParams = useMemo(
    () => new SearchParams(location.search),
    [location]
  );

  const assetId = useMemo(
    () =>
      searchParams.has(assetIdSearchParam)
        ? searchParams.get(assetIdSearchParam)
        : null,
    [searchParams]
  );

  return assetId;
};

export default useAssetId;
