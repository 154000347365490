// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { IModelDetails } from "features/detailpage/features/dielectric/components/DielectricTab/DielectricTab";
import React from "react";
import { IntlShape, useIntl } from "react-intl";

export interface IPartialDischargeReadingName {
  nameId: string;
  model: IModelDetails;
}

const PartialDischargeReadingName = ({
  nameId,
  model
}: IPartialDischargeReadingName) => {
  const intl = useIntl();
  const formattedValue = PartialDischargeReadingService.format(
    model.modelId,
    model.implementationId,
    nameId,
    intl
  );
  return <span>{formattedValue}</span>;
};

export default PartialDischargeReadingName;

export class PartialDischargeReadingService {
  private static readonly partialDischargeMessageType = "parameter";
  static format(
    modelPrefix: string,
    implementationPrefix: string,
    nameId: string,
    intl: IntlShape
  ): string {
    return intl.formatMessage({
      id: `${modelPrefix}.${this.partialDischargeMessageType}.${nameId}`,
      defaultMessage: intl.formatMessage({
        id: `${implementationPrefix}.${this.partialDischargeMessageType}.${nameId}`,
        defaultMessage: nameId
      })
    });
  }
}
