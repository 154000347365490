// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import {
  IConditionTrendSeries,
  ISubscore
} from "@apm/widgets/build/widgets/ConditionTrend";
import { SubscoreService } from "common/formatters/SubscoreName";
import { isEmpty } from "lodash";
import { IntlShape } from "react-intl";
import IDegradationScore from "../../../models/IDegradationScore";
import IScoreTrend from "../../../models/IScoreTrend";

export interface ICreateResult {
  trendSeries: IConditionTrendSeries[];
  subscores: ISubscore[];
}

export default class ConditionTrendSeriesCreator {
  constructor(
    private intl: IntlShape,
    private modelId: string,
    private implementationId: string
  ) {}

  create(
    series: IScoreTrend[],
    latestDegradationScores: IDegradationScore,
    isFailed?: boolean
  ): Promise<ICreateResult> {
    return new Promise((resolve) => {
      if (isFailed) resolve(this.getFailedFakeData());

      setTimeout(async () => {
        const [trendSeries, subscores] = await Promise.all([
          this.createConditionTrendSeries(series),
          this.createLatestSubscores(latestDegradationScores)
        ]);
        resolve({
          trendSeries,
          subscores
        });
      });
    });
  }

  private createConditionTrendSeries(
    series: IScoreTrend[]
  ): Promise<IConditionTrendSeries[]> {
    return new Promise((resolve) => {
      const trendSeries = series?.slice(1).map(
        ({ Name, Dates, Values }): IConditionTrendSeries => ({
          name: Name,
          displayName: SubscoreService.format(
            Name,
            this.modelId,
            this.implementationId,
            this.intl
          ),
          values: this.createConditionTrendSeriesValues(Dates, Values)
        })
      );

      resolve(trendSeries);
    });
  }

  private createConditionTrendSeriesValues(dates: string[], values: number[]) {
    const valuesDict: { [key: string]: number } = {};
    for (let i = 0; i < dates.length; i++) {
      const key = dates[i];
      valuesDict[key] = values[i];
    }

    return valuesDict;
  }

  private createLatestSubscores(
    latestDegradationScores: IDegradationScore
  ): Promise<ISubscore[]> {
    return new Promise((resolve) => {
      const subscores = latestDegradationScores?.SubScores?.sort((a, b) =>
        a.NameId.localeCompare(b.NameId)
      ).map(
        ({ NameId, Value, SubScores }): ISubscore => ({
          id: NameId,
          name: SubscoreService.format(
            NameId,
            this.modelId,
            this.implementationId,
            this.intl
          ),
          value: Value,
          hasSubscores: !isEmpty(SubScores)
        })
      );

      resolve(subscores);
    });
  }

  private getFailedFakeData(): ICreateResult {
    return {
      trendSeries: this.getFakeTrendSeries(),
      subscores: this.getFakeSubscores()
    };
  }

  private getFakeTrendSeries(): IConditionTrendSeries[] {
    const trendSeries: IConditionTrendSeries[] = [];
    const fakeSeriesLength = 4;
    const values = {
      "1995-12-31T00:00:00Z": 10,
      "1996-12-31T00:00:00Z": 20,
      "1997-12-31T00:00:00Z": 15,
      "1998-12-31T00:00:00Z": 12,
      "1999-12-31T00:00:00Z": 25
    };

    for (let i = 0; i < fakeSeriesLength; i++) {
      trendSeries.push({
        name: `series${i + 1}`,
        displayName: `series${i + 1}`,
        values
      });
    }

    return trendSeries;
  }

  private getFakeSubscores(): ISubscore[] {
    const subscores: ISubscore[] = [];
    const fakeSubscoresLength = 4;
    for (let i = 0; i < fakeSubscoresLength; i++) {
      subscores.push({
        id: `subscore_${i + 1}`,
        name: `Subscore ${i + 1}`,
        value: 20
      });
    }

    return subscores;
  }
}
