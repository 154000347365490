// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Container from "common/Container";
import DataGrid, { IRow, IRowData } from "common/datagrid/DataGrid";
import DataGridWrapper from "common/datagrid/DataGridWrapper";
import { SelectedFilters, StatusBar } from "common/FilterBar";
import SectionName from "components/common/SectionName";
import { isNil } from "lodash";
import { AssetRiskEnum } from "models/AssetRisks";
import { useCallback, useState } from "react";
import styled from "styled-components";
import { spacingXLarge } from "styles/StyleVariables";
import useComponentSummaryGrid from "../hooks/useComponentSummaryGrid";
import useSidePanel from "../hooks/useSidePanel";
import SidePanelContent, { ISidePanelStateProps } from "./SidePanelContent";

interface IComponentRiskSummaryGridProps {
  className?: string;
  filters: SelectedFilters;
}

const ComponentRiskSummaryGrid = ({
  className,
  filters
}: IComponentRiskSummaryGridProps) => {
  const [sidePanelState, setSidePanelState] = useState<ISidePanelStateProps>();
  const { handleSidePanelOpen, handleSidePanelClose, isSidePanelVisible } =
    useSidePanel();

  const handleRiskClick = useCallback(
    (row: IRow<IRowData>, risk: AssetRiskEnum) => {
      setSidePanelState({
        row,
        risk
      });
      handleSidePanelOpen();
    },
    [handleSidePanelOpen]
  );

  const { dataEndpoint, rowsTotal, getColumnsConfig } = useComponentSummaryGrid(
    {
      filters,
      handleRiskClick
    }
  );

  return (
    <DataGridWrapper className={className}>
      <Container>
        <Header>
          <Title>
            {!isNil(rowsTotal) ? (
              <SectionName
                messageDefault="Assets by Components / { numberOfAssets, plural, zero {no items} one {# item} other {# items} }"
                messageId="component_risk_summary_page.header.assets_by_components_total"
                messageValues={{
                  numberOfAssets: rowsTotal
                }}
              />
            ) : (
              <SectionName
                messageDefault="Assets by Components"
                messageId="component_risk_summary_page.header.assets_by_components"
              />
            )}
            <StatusBar />
          </Title>
        </Header>
        <DataGrid columns={getColumnsConfig()} dataEndpoint={dataEndpoint} />

        <SidePanelContent
          {...sidePanelState}
          handleSidePanelClose={handleSidePanelClose}
          isSidePanelVisible={isSidePanelVisible}
        />
      </Container>
    </DataGridWrapper>
  );
};

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  > *:first-child {
    margin-right: ${spacingXLarge};
  }
`;

export default ComponentRiskSummaryGrid;
