// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Statuses } from "core/data/models/Data";
import { loadHistorySwitchingOperationsAction } from "features/detailpage/features/ltc/actions/HistorySwitchingActions";
import HistorySwitching from "features/detailpage/features/ltc/components/HistorySwitching";
import { useHistorySwitchingOperations } from "features/detailpage/features/ltc/reducers/LTCReducer";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { useAppDispatch } from "store";

interface IHistorySwitchingContainerProps {
  assetId: string;
}

const HistorySwitchingContainer = ({
  assetId
}: IHistorySwitchingContainerProps) => {
  const dispatch = useAppDispatch();
  const { historySwitching } = useHistorySwitchingOperations();

  useEffect(() => {
    if (
      historySwitching.status !== Statuses.Succeeded ||
      isEmpty(historySwitching.data)
    ) {
      dispatch(loadHistorySwitchingOperationsAction(assetId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <HistorySwitching historySwitching={historySwitching} />;
};

export default HistorySwitchingContainer;
