// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { ReactNode } from "react";

interface IMessageProps {
  children: ReactNode;
}

const Message = ({ children }: IMessageProps) => <div>{children}</div>;

export default Message;
