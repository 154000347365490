// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { useContext, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useAppDispatch, useAppSelector } from "store";
import DielectricContext from "../../../contexts/DielectricContext";
import shouldLoadDielectricData from "../../../utils/shouldLoadDielectricData";
import loadDielectricMoisture from "../actions/loadDielectricMoisture";
import getDielectricMoistureSelector from "../selectors/getDielectricMoistureSelector";

const useSF6MoistureChart = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { assetId } = useContext(DielectricContext);

  const moisture = useAppSelector(getDielectricMoistureSelector);

  const title = useMemo(
    () =>
      intl.formatMessage({
        id: "detail_page.widgets.circuit_breaker.dielectric.title.moisture",
        defaultMessage: "SF6 Moisture"
      }),
    [intl]
  );

  useEffect(() => {
    if (shouldLoadDielectricData(moisture))
      dispatch(loadDielectricMoisture(assetId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const calculateHeight = () => window.innerHeight - 400;
  const [height, setHeight] = useState<number>(calculateHeight());

  const onResize = () => {
    setHeight(calculateHeight());
  };

  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { moisture, title, height };
};

export default useSF6MoistureChart;
