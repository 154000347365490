// Copyright 2016-2024 Hitachi Energy. All rights reserved.

const windingConfiguration = [
  "Single phase Y",
  "Single phase delta",
  "zig-zag",
  "Y",
  "Delta",
  "Other"
];

export default windingConfiguration;
