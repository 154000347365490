// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { IState } from "reducers/Index";

const getNotificationsModelInputSelector = createSelector(
  (state: IState) => state.notifications.modelInput,
  modelInput => modelInput
);

export default getNotificationsModelInputSelector;
