// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Data from "core/data/models/Data";
import { IAjaxAction } from "reducers/Index";
import { Action, Reducer } from "redux";
import {
  Actions,
  ISetSimulationModalVisible
} from "../actions/RiskSimulationSummaryActions";
import ISimulationConfig from "../components/SimulationModal/models/ISimulationConfig";
import IDegradationScoreTrend from "../models/IDegradationScoreTrend";

export interface IState {
  riskSimulationConfig: Data<ISimulationConfig>;
  degradationScore: Data<IDegradationScoreTrend>;
  simulationModalVisible: boolean;
}

export const defaultState: IState = {
  riskSimulationConfig: new Data(),
  degradationScore: new Data(),
  simulationModalVisible: false
};

const reducer: Reducer<IState> = (
  state = defaultState,
  action:
    | IAjaxAction<ISimulationConfig>
    | IAjaxAction<IDegradationScoreTrend>
    | ISetSimulationModalVisible
    | Action
): IState => {
  switch (action.type) {
    case Actions.DetailPage_RiskSimulationSummary_SetConfig:
      return {
        ...state,
        riskSimulationConfig: new Data(
          (action as IAjaxAction<ISimulationConfig>).request,
          (action as IAjaxAction<ISimulationConfig>).data
        )
      };
    case Actions.DetailPage_RiskSimulationSummary_SetDegradationScore:
      return {
        ...state,
        degradationScore: new Data(
          (action as IAjaxAction<IDegradationScoreTrend>).request,
          (action as IAjaxAction<IDegradationScoreTrend>).data
        )
      };
    case Actions.DetailPage_RiskSimulationSummary_SetSimulationModalVisible: {
      return {
        ...state,
        simulationModalVisible: (action as ISetSimulationModalVisible).visible
      };
    }
    default:
      return state;
  }
};

export default reducer;
