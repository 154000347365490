// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Col, Row, Typography } from "antd";
import ParameterName from "common/formatters/ParameterName";
import FormattedValue from "components/common/FormattedValue";
import { FormattedDate } from "react-intl";
import styled from "styled-components";
import IOperations from "../../models/IOperations";

const { Text } = Typography;

interface IDGAOperationsRowProps {
  operation: IOperations;
  modelId: string;
  implementationId: string;
  className?: string;
}

const DGAOperationsRow = ({
  operation,
  modelId,
  implementationId,
  className
}: IDGAOperationsRowProps) => (
  <Row className={className}>
    <Col span={5}>
      <ParameterName
        nameId={operation.Name}
        modelId={modelId}
        implementationId={implementationId}
      />
    </Col>
    <Col span={3}>{operation.Unit && <Text>{operation.Unit}</Text>}</Col>
    <Col span={4} className="date">
      {operation.PreviousDate !== null && (
        <>
          <FormattedDate
            value={operation.PreviousDate}
            year="numeric"
            month="numeric"
            day="numeric"
          />
          <FormattedDate
            value={operation.PreviousDate}
            minute="numeric"
            hour="numeric"
            second="numeric"
          />
        </>
      )}
    </Col>
    <Col span={4}>
      {operation.PreviousValue !== null && (
        <Text strong>
          <FormattedValue value={operation.PreviousValue} />
        </Text>
      )}
    </Col>
    <Col span={4} className="date">
      {operation.CurrentDate !== null && (
        <>
          <FormattedDate
            value={operation.CurrentDate}
            year="numeric"
            month="numeric"
            day="numeric"
          />
          <FormattedDate
            value={operation.CurrentDate}
            minute="numeric"
            hour="numeric"
            second="numeric"
          />
        </>
      )}
    </Col>
    <Col span={4}>
      {operation.CurrentValue !== null && (
        <Text strong>
          <FormattedValue value={operation.CurrentValue} />
        </Text>
      )}
    </Col>
  </Row>
);

export default styled(DGAOperationsRow)`
  .ant-col {
    &.date > span {
      display: block;
    }
  }
`;
