// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import ISelectedFilterSelect from "common/FilterBar/models/ISelectedFilterSelect";
import { IDataEndpoint } from "common/datagrid/DataGrid";
import UrlService from "core/data/services/UrlService";
import { AssetRiskEnum } from "models/AssetRisks";
import { useMemo } from "react";
import { config } from "utils/AppConfig";
import useComponentsGridColumnsConfig from "./useComponentsGridColumnsConfig";

interface IUseComponentsGridOptions {
  assetId: string;
  risk?: AssetRiskEnum;
}

const useComponentsGrid = ({ assetId, risk }: IUseComponentsGridOptions) => {
  const selectedFilters: ISelectedFilterSelect[] = useMemo(
    () => [
      {
        Id: "AssetId",
        Options: [
          {
            Id: assetId
          }
        ]
      },
      ...(risk
        ? [
            {
              Id: "ComponentRisk",
              Options: [
                {
                  Id: risk
                }
              ]
            }
          ]
        : [])
    ],
    [assetId, risk]
  );

  const columns = useComponentsGridColumnsConfig();

  const dataEndpoint: IDataEndpoint = useMemo(
    () => ({
      url: UrlService.getApiUrl(config.api.components.componentsRangeUrl),
      type: "POST",
      content: {
        filters: selectedFilters
      }
    }),
    [selectedFilters]
  );

  return {
    dataEndpoint,
    columns
  };
};

export default useComponentsGrid;
