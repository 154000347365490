// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import Text from "antd/lib/typography/Text";
import useNavToComponents from "hooks/useNavToComponents";
import { AssetRiskEnum } from "models/AssetRisks";
import { useIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { colorTeal90 } from "styles/ColorVariables";
import { spacingLarge, spacingXSmall } from "styles/StyleVariables";

interface INavigateToComponentsProps {
  assetId: string;
  risk?: AssetRiskEnum;
}

const NavigateToComponents = ({
  assetId,
  risk
}: INavigateToComponentsProps) => {
  const intl = useIntl();

  const { navigateTo } = useNavToComponents();

  return (
    <Wrapper>
      <Link
        key="navigate-to-components"
        to={navigateTo(assetId, risk)}
        data-qa="view-in-button"
      >
        <OpenIcon name="open_in_new" />
        <Label>
          {intl.formatMessage({
            defaultMessage: "View in Components",
            id: "component_risk_summary_page.side_panel.view_in_components"
          })}
        </Label>
      </Link>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  border-top: ${spacingXSmall} solid ${colorTeal90};
  width: 100%;
  padding-top: ${spacingLarge};
`;

const Link = styled(NavLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const OpenIcon = styled(Icon)`
  color: ${colorTeal90};
`;

const Label = styled(Text)`
  color: ${colorTeal90};
`;
export default NavigateToComponents;
