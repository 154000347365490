// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import * as React from "react";
import { injectIntl, IntlShape } from "react-intl";

import CostConverter from "common/converters/CostConverter";
import ImpactConverter from "common/converters/ImpactConverter";
import UrgencyConverter from "common/converters/UrgencyConverter";
import Equation from "common/equation/Equation";
import Fraction from "common/equation/Fraction";
import { NumericalValueService } from "common/formatters/NumericalValue";

import "./SlidePanelMaintenancePriority.less";

class EquationSymbols {
  public static normalizedCondition = "NC";
  public static importance = "Im";
  public static impact = "I";
  public static cost = "C";
  public static urgency = "U";
  public static maintenancePriority = "MP";
  public static maintenanceScore = "MS";
}

class MPEquationStructure {
  public static fractionNumerator = `${EquationSymbols.maintenanceScore}`;
  public static fractionDenominator = `max(${EquationSymbols.maintenanceScore})`;
  public static multiplier = "100 \u00B7 ";
}

class MSEquationStructure {
  public static fractionNumerator = `${EquationSymbols.normalizedCondition} \u00B7 ${EquationSymbols.importance} \u00B7 ${EquationSymbols.impact}`;
  public static fractionDenominator = `${EquationSymbols.cost} \u00B7 ${EquationSymbols.urgency}`;
}

interface ISlidePanelMaintenancePriorityProps {
  intl: IntlShape;
  score: number;
  rawScore: number;
  normalizedScore: number;
  importance: number;
  costLevel: string;
  costFactor: number;
  urgencyLevel: string;
  urgencyFactor: number;
  impactLevel: string;
  impactFactor: number;
}

interface ISlidePanelMaintenancePriorityState {
  score: string;
  rawScore: string;
  normalizedScore: string;
  importance: string;
  cost: string;
  costFactor: string;
  urgency: string;
  urgencyFactor: string;
  impact: string;
  impactFactor: string;
}

class SlidePanelMaintenancePriority extends React.Component<
  ISlidePanelMaintenancePriorityProps,
  ISlidePanelMaintenancePriorityState
> {
  constructor(props: ISlidePanelMaintenancePriorityProps) {
    super(props);

    this.state = SlidePanelMaintenancePriority.mapPropsToState(props);
  }

  render() {
    const { intl } = this.props;
    const { rawScore, score } = this.state;

    const componentsRows = this.getComponentsRows().map((row, i) => (
      <tr key={i}>
        <td className="value">
          <span className="bold">{row.symbol}</span> - {row.name}:
        </td>
        <td className="value">{row.value1}</td>
        <td className="value">{row.value2}</td>
      </tr>
    ));
    const maintenanceScoreText = intl.formatMessage({
      id: "issue.maintenance_priority.details.maintenance_score",
      defaultMessage: "Maintenance Score"
    });
    const maintenancePriorityText = intl.formatMessage({
      id: "issue.maintenance_priority.details.maintenance_priority",
      defaultMessage: "Maintenance Priority"
    });

    return (
      <div
        className="maintenance-score-details"
        data-qa="maintenance-score-details"
      >
        <MSEquationComponent />
        <MPEquationComponent />
        <table className="components" data-qa="legend">
          <tbody>
            {componentsRows}
            <tr>
              <td className="divider-cell" colSpan={3}>
                <div className="divider" />
              </td>
            </tr>
            <tr>
              <td className="value">
                <span className="bold">{EquationSymbols.maintenanceScore}</span>{" "}
                - {maintenanceScoreText}:
              </td>
              <td className="value" />
              <td className="value">
                <span className="bold">{rawScore}</span>
              </td>
            </tr>
            <tr>
              <td className="value">
                <span className="bold">
                  {EquationSymbols.maintenancePriority}
                </span>{" "}
                - {maintenancePriorityText}:
              </td>
              <td className="value" />
              <td className="value">
                <span className="bold">{score}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  private getComponentsRows(): {
    symbol: string;
    name: string;
    value1: string;
    value2: string;
  }[] {
    const { intl } = this.props;
    const {
      cost,
      costFactor,
      impact,
      impactFactor,
      importance,
      normalizedScore,
      urgency,
      urgencyFactor
    } = this.state;

    return [
      {
        symbol: EquationSymbols.normalizedCondition,
        name: intl.formatMessage({
          id: "issue.maintenance_priority.details.normalized_condition",
          defaultMessage: "Normalized Condition"
        }),
        value1: "",
        value2: normalizedScore
      },
      {
        symbol: EquationSymbols.importance,
        name: intl.formatMessage({
          id: "issue.maintenance_priority.details.importance",
          defaultMessage: "Importance"
        }),
        value1: "",
        value2: importance
      },
      {
        symbol: EquationSymbols.impact,
        name: intl.formatMessage({
          id: "issue.maintenance_priority.details.impact",
          defaultMessage: "Impact"
        }),
        value1: impact,
        value2: impactFactor
      },
      {
        symbol: EquationSymbols.cost,
        name: intl.formatMessage({
          id: "issue.maintenance_priority.details.cost",
          defaultMessage: "Cost"
        }),
        value1: cost,
        value2: costFactor
      },
      {
        symbol: EquationSymbols.urgency,
        name: intl.formatMessage({
          id: "issue.maintenance_priority.details.urgency",
          defaultMessage: "Urgency"
        }),
        value1: urgency,
        value2: urgencyFactor
      }
    ];
  }

  private static formatIssueLevel(
    prefix: string,
    level: string,
    intl: IntlShape
  ) {
    return intl.formatMessage({
      id: prefix + "." + level,
      defaultMessage: level
    });
  }

  private static mapPropsToState(
    props: ISlidePanelMaintenancePriorityProps
  ): ISlidePanelMaintenancePriorityState {
    return {
      score: NumericalValueService.format(props.score, props.intl),
      rawScore: NumericalValueService.format(props.rawScore, props.intl),
      normalizedScore: NumericalValueService.format(
        props.normalizedScore,
        props.intl
      ),
      importance: NumericalValueService.format(props.importance, props.intl),
      cost: SlidePanelMaintenancePriority.formatIssueLevel(
        "cost",
        CostConverter.convertCostLevelToCost(props.costLevel),
        props.intl
      ),
      costFactor: NumericalValueService.format(props.costFactor, props.intl),
      urgency: SlidePanelMaintenancePriority.formatIssueLevel(
        "urgency",
        UrgencyConverter.convertApiUrgencyToUiUrgency(props.urgencyLevel),
        props.intl
      ),
      urgencyFactor: NumericalValueService.format(
        props.urgencyFactor,
        props.intl
      ),
      impact: SlidePanelMaintenancePriority.formatIssueLevel(
        "impact",
        ImpactConverter.convertImpactLevelToImpact(props.impactLevel),
        props.intl
      ),
      impactFactor: NumericalValueService.format(props.impactFactor, props.intl)
    };
  }
}

const MPEquationComponent = () => (
  <div className="mp-equation bold" data-qa="maintenance-equation">
    <Equation
      left={EquationSymbols.maintenancePriority}
      right={
        <Fraction
          numerator={MPEquationStructure.fractionNumerator}
          denominator={MPEquationStructure.fractionDenominator}
        />
      }
      multiplier={MPEquationStructure.multiplier}
    />
  </div>
);

const MSEquationComponent = () => (
  <div className="mp-equation bold" data-qa="maintenance-equation">
    <Equation
      left={EquationSymbols.maintenanceScore}
      right={
        <Fraction
          numerator={MSEquationStructure.fractionNumerator}
          denominator={MSEquationStructure.fractionDenominator}
        />
      }
    />
  </div>
);

export default injectIntl(SlidePanelMaintenancePriority);
