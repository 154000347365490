// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { AppDispatch } from "store";
import removeHeaterStatus from "./removeHeaterStatus";
import removeMotorStarts from "./removeMotorStarts";
import removeOperatingMechanismMotor from "./removeOperatingMechanismMotor";

const removeAuxiliariesData = (dispatch: AppDispatch, assetId: string) => {
  dispatch(removeHeaterStatus(assetId));
  dispatch(removeOperatingMechanismMotor(assetId));
  dispatch(removeMotorStarts(assetId));
};

export default removeAuxiliariesData;
