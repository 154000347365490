// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Data from "core/data/models/Data";
import { IState } from "reducers/Index";
import { createSelector } from "reselect";
import { getDataKey } from "../actions/loadSimulations";
import ISimulationSummary from "../models/ISimulationSummary";
import IValues from "../models/IValues";

const getSimulationsSelector = createSelector(
  (state: IState) => (assetId: string) =>
    state.data[getDataKey(assetId)] as Data<IValues<ISimulationSummary>>,
  (simulationsData) => simulationsData
);

export default getSimulationsSelector;
