// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React from "react";
import { FormattedMessage } from "react-intl";
import Switch, { ISwitchItem } from "common/form/components/Switch";

type RogersRatioDataSourceSwitchProps = {
  toggleShowOfflineOnly: () => void;
};

const RogersRatioDataSourceSwitch = ({
  toggleShowOfflineOnly
}: RogersRatioDataSourceSwitchProps) => {
  const DataSource = {
    online: "Online",
    offline: "Offline"
  };

  const items: ISwitchItem[] = [
    {
      id: DataSource.online,
      message: {
        defaultMessage: DataSource.online,
        id: `global.data_source.${DataSource.online}`
      }
    },
    {
      id: DataSource.offline,
      message: {
        defaultMessage: DataSource.offline,
        id: `global.data_source.${DataSource.offline}`
      }
    }
  ];

  return (
    <div className="data-source-form">
      <div className="label" data-qa="data-source-label">
        <FormattedMessage
          defaultMessage="Data Source"
          id="global.data_source"
        />
      </div>
      <Switch
        defaultSelected={DataSource.offline}
        items={items}
        onChange={toggleShowOfflineOnly}
      />
    </div>
  );
};

export default RogersRatioDataSourceSwitch;
