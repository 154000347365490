// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React from "react";

import { IRow } from "common/datagrid/DataGrid";
import IParameterInfo from "../../../models/IParameterInfo";
import ITypedValue from "../../../models/ITypedValue";
import Value from "./Value";

const ColumnPreviousValue = (
  previousValue: ITypedValue,
  row: IRow<IParameterInfo>
) => {
  return <Value value={previousValue} row={row} />;
};

export default ColumnPreviousValue;
