// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import removeData from "core/data/actions/removeData";
import ChartNames from "../models/ChartNames";
import { getDataKey } from "./loadChart";

const removeChart = (assetId: string, chartName: ChartNames) =>
  removeData(getDataKey(assetId, chartName));

export default removeChart;
