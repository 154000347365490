// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Reducer } from "redux/index";

import Data from "core/data/models/Data";
import { IAjaxAction } from "reducers/Index";
import { Actions } from "core/app/actions/VersionActions";

export interface IVersion {
  Major: string | number;
  Minor: string | number;
  Sprint: string | number;
  Build: string | number;
}

export interface IState {
  version: {
    ui: Data<IVersion>;
  };
}

const versionReducer: Reducer<IState> = (
  state: IState,
  action: IAjaxAction<any>
) => {
  switch (action.type) {
    case Actions.Version_SetUiVersion:
      state = Object.assign({}, state, {
        version: Object.assign({}, state.version, {
          ui: new Data(action.request, action.data)
        })
      } as IState);
      return state;
    default:
      return state;
  }
};

export default versionReducer;
