// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { ISeries } from "@apm/widgets/build/widgets/MotorStartsBarChart";
import ITrendChartDataSeries from "common/chart/components/TrendLineChart/models/ITrendChartDataSeries";
import Data from "core/data/models/Data";
import isDataAvailable from "core/data/utils/isDataAvailable";
import { useCallback, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import IMotorStarts from "../models/IMotorStarts";

interface IUseMotorStarts {
  assetId: string;
  motorStarts?: Data<IMotorStarts>;
  loadMotorStarts: (assetId: string) => void;
}

const useMotorStarts = ({
  assetId,
  motorStarts,
  loadMotorStarts
}: IUseMotorStarts) => {
  const intl = useIntl();

  const mapDataSeries = useCallback(
    (dataSeries: ITrendChartDataSeries): ISeries[] => {
      const series: ISeries[] = [];
      Object.keys(dataSeries).forEach((key) => {
        series.push({
          name: key,
          displayName: intl.formatMessage({
            id: `detail_page.widgets.circuit_breaker.auxiliaries.motor_starts_series.${key}`,
            defaultMessage: key
          }),
          values: dataSeries[key] || {}
        });
      });
      return series;
    },
    [intl]
  );

  const series: ISeries[] = useMemo(() => {
    if (motorStarts && motorStarts.data) {
      const { DataSeries } = motorStarts.data;
      return mapDataSeries(DataSeries);
    }

    return [];
  }, [motorStarts, mapDataSeries]);

  const title = useMemo(
    () =>
      intl.formatMessage({
        id: "detail_page.widgets.circuit_breaker.auxiliaries.motor_starts_7_days",
        defaultMessage: "Motor starts in last 7 days"
      }),
    [intl]
  );

  const formatXTick = (date: Date) => intl.formatDate(date);

  const formatYTick = (value: number) => {
    return value % 1 === 0 ? intl.formatNumber(value) : "";
  };

  const emptyTranslation = useMemo(
    () =>
      intl.formatMessage({
        id: "global.chart.no_data_available",
        defaultMessage: "No data available"
      }),
    [intl]
  );

  useEffect(() => {
    if (!isDataAvailable(motorStarts)) {
      loadMotorStarts(assetId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { title, series, emptyTranslation, formatXTick, formatYTick };
};

export default useMotorStarts;
