// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { getStaticMarkup } from "common/DuvalAnalysis/components/DuvalAnalysisTooltip";
import DataSource from "common/DuvalAnalysis/models/DataSource";
import { createDuvalPoints } from "common/DuvalAnalysis/utils/duvalAnalysisHelper";
import * as d3 from "d3";
import { IntlShape } from "react-intl";
import IDuvalRegion from "./models/IDuvalRegion";

type D3Selection<T extends d3.BaseType> = d3.Selection<T, any, any, any>;
export enum DuvalDataType {
  Type1 = 1,
  Type3 = 3,
  Type4 = 4,
  Type5 = 5
}

class VisualDuvalTriangle {
  private root: D3Selection<SVGElement>;
  private tooltip: D3Selection<HTMLElement>;
  constructor(root: SVGElement, tooltip: HTMLElement = null) {
    this.root = d3.select(root);
    this.tooltip = d3.select(tooltip);
  }

  public draw(settings: ISettings, intl: IntlShape) {
    const drawer = new DuvalTriangleDrawer(this.root, this.tooltip, intl);
    drawer.visualize(settings);
  }
}

export interface IDuvalTriangleSettings {
  type: DuvalDataType;
  dataSource: DataSource;
  sortedPoints: DuvalDataPoint[];
  regions: IDuvalRegion[];
}

export interface ITriangleLabels {
  left: string;
  right: string;
  base: string;
}

interface ISettings extends IDuvalTriangleSettings {
  labels: ITriangleLabels;
}

class DuvalTriangleDrawer {
  root: D3Selection<SVGElement>;
  tooltip: D3Selection<HTMLElement>;
  polygons: D3Selection<SVGElement>[];
  ticks: D3Selection<SVGElement>[];
  viewport: IViewport;
  drawRegion: Rect;

  constructor(
    root: D3Selection<SVGElement>,
    tooltip: D3Selection<HTMLElement>,
    private intl: IntlShape
  ) {
    this.root = root;
    this.tooltip = tooltip;
    this.viewport = this.getViewport();
    this.polygons = new Array<D3Selection<SVGElement>>();
  }

  visualize(settings: ISettings) {
    this.clear();

    this.drawRegion = this.getTriangleCoordinates();

    this.drawDuvalRegions(settings.regions);

    this.drawAxisWithTicks();

    this.drawLabels(settings.labels);

    this.drawPoints(settings);
  }

  clear() {
    this.root.selectAll("*").remove();
  }

  private setPolygonPoints(
    polygon: D3Selection<SVGElement>,
    vertices: Point[]
  ) {
    let coordsString = "";
    vertices.forEach((vertex) => {
      coordsString += vertex.x + "," + vertex.y + " ";
    });
    polygon.attr("points", coordsString);
  }
  
  private showTooltip(settings: ISettings, pointEvent: MouseEvent, sortedPoints: DuvalDataPoint) {
    if (!this.tooltip) return;

    const html = getStaticMarkup({
      intl: this.intl,
      date: new Date(sortedPoints.date),
      dataSource: settings.dataSource,
      gases: [
        {
          label: settings.labels.base,
          ppm: sortedPoints.base,
          percent: Math.round(100 * sortedPoints.getBasePercent())
        },
        {
          label: settings.labels.left,
          ppm: sortedPoints.left,
          percent: Math.round(100 * sortedPoints.getLeftPercent())
        },
        {
          label: settings.labels.right,
          ppm: sortedPoints.right,
          percent: Math.round(100 * sortedPoints.getRightPercent())
        }
      ]
    });

    this.tooltip
      .transition()
      .duration(200)
      .style("opacity", 0.95)
      .style("display", "block");
    this.tooltip
      .html(html)
      .style("left", pointEvent.pageX + 10 + "px")
      .style("top", pointEvent.pageY + 10 + "px");
  }

  private hideTooltip() {
    this.tooltip
      .transition()
      .duration(500)
      .style("opacity", 0)
      .style("display", "none");
  }

  drawPoints(settings: ISettings) {
    createDuvalPoints({
      root: this.root,
      sortedPoints: settings.sortedPoints.sort((a, b) =>
        a?.date < b?.date ? -1 : a?.date > b?.date ? 1 : 0
      ),
      dataSource: settings.dataSource,
      showTooltip: (d: MouseEvent, sortedPoints: any) => this.showTooltip(settings, d, sortedPoints),
      hideTooltip: () => this.hideTooltip(),
      getCoords: (duvalPoint: DuvalDataPoint) =>
        this.getPointCoordinates(duvalPoint)
    });
  }

  drawDuvalRegions(regions: IDuvalRegion[]) {
    regions.forEach((region) => {
      const coords = this.getLabelCoordinates(
        region.LabelCenter,
        region.LabelXOffset,
        region.LabelYOffset
      );
      this.drawDuvalRegion(
        region.Points,
        region.Color,
        region.Name,
        coords.x,
        coords.y
      );
    });
  }

  drawTick(p1: DuvalPoint, p2: DuvalPoint, value: number, side: string) {
    const _p1 = this.getPointCoordinates(p1);
    const _p2 = this.getPointCoordinates(p2);

    this.ticks.push(
      this.root
        .append("line")
        .attr("value", value)
        .attr("stroke", "black")
        .attr("stroke-width", 1)
        .attr("x1", _p1.x)
        .attr("y1", _p1.y)
        .attr("x2", _p2.x)
        .attr("y2", _p2.y)
    );

    if (value % 20 === 0 && value !== 0)
      this.root
        .append("text")
        .text(value)
        .attr("x", _p1.x + (side === "left" ? -20 : side === "right" ? 10 : 0))
        .attr("y", _p1.y + (side === "base" ? 20 : 0))
        .style("font-size", "12px");
  }

  drawLabel(
    label: string,
    point: DuvalPoint,
    offsetX: number,
    offsetY: number,
    angle = 0
  ) {
    const p = this.getPointCoordinates(point);
    const x = p.x + offsetX;
    const y = p.y + offsetY;
    this.root
      .append("text")
      .text(`% ${label}`)
      .attr("x", x)
      .attr("y", y)
      .attr("transform", `rotate(${angle},${x},${y})`)
      .attr("class", "label")
      .style("font-size", "20px");
  }

  drawLabels(labels: ITriangleLabels) {
    this.drawLabel(labels.base, new DuvalPoint(0, 50, 50), -40, 45);
    this.drawLabel(labels.left, new DuvalPoint(45, 0, 55), -40, -5, -55);
    this.drawLabel(labels.right, new DuvalPoint(58, 42, 0), 35, -20, 55);

    this.polygons.forEach((polygon) => {
      this.root
        .append("text")
        .text(polygon.attr("id"))
        .style("font-size", "14px")
        .attr("x", polygon.attr("x"))
        .attr("y", polygon.attr("y"));
    });
  }

  drawAxisWithTicks() {
    const tickLength = 1;
    if (this.ticks) this.ticks.forEach((tick) => tick.remove());

    const axis = this.root.append("polygon");
    axis.attr("style", "fill:none;stroke:black;stroke-width:1");

    const vertices = [
      this.getPointCoordinates(new DuvalPoint(0, 0, 100)),
      this.getPointCoordinates(new DuvalPoint(100, 0, 0)),
      this.getPointCoordinates(new DuvalPoint(0, 100, 0))
    ];

    this.setPolygonPoints(axis, vertices);

    this.ticks = new Array<D3Selection<SVGElement>>();
    const tl = 100 - tickLength;
    for (let i = 10; i < 100; i += 10) {
      this.drawTick(
        new DuvalPoint(0, 100 - i, i),
        new DuvalPoint(tickLength, tl - i, i),
        i,
        "base"
      );
      this.drawTick(
        new DuvalPoint(i, 0, 100 - i),
        new DuvalPoint(i, tickLength, tl - i),
        i,
        "left"
      );
      this.drawTick(
        new DuvalPoint(100 - i, i, 0),
        new DuvalPoint(tl - i, i, tickLength),
        i,
        "right"
      );
    }
  }

  drawDuvalRegion(
    points: DuvalPoint[],
    color: string,
    label: string,
    labelX: number,
    labelY: number
  ) {
    const vertices = new Array<Point>();

    points.forEach((point) => {
      vertices.push(this.getPointCoordinates(point));
    });

    const polygon = this.root.append("polygon");
    polygon
      .attr("style", `fill:${color};stroke:none`)
      .attr("id", label)
      .attr("x", labelX)
      .attr("y", labelY);

    this.polygons.push(polygon);
    this.setPolygonPoints(polygon, vertices);
  }

  getTriangleCoordinates() {
    const hToWRatio = 0.86;
    const r = new Rect();
    const vHeight = this.viewport.height;

    if (vHeight / hToWRatio > this.viewport.width) {
      r.height = this.viewport.width * hToWRatio;
      r.width = this.viewport.width;
    } else {
      r.height = vHeight;
      r.width = vHeight / hToWRatio;
    }
    r.x = this.viewport.x + (this.viewport.width - r.width) / 2;
    r.y = this.viewport.y + ((vHeight - r.height) / 2 - 50);
    return r;
  }

  getLabelCoordinates(point: DuvalPoint, xOffset: number, yOffset: number) {
    const r = this.getPointCoordinates(point);
    return {
      x: r.x + xOffset,
      y: r.y + yOffset
    };
  }

  getPolygonCentroidCoordinates(points: DuvalPoint[]) {
    const pointsArray = new Array<Point>();

    points.forEach((point) => {
      pointsArray.push(this.getPointCoordinates(point));
    });

    let x = 0;
    let y = 0;

    pointsArray.forEach((point) => {
      x += point.x;
      y += point.y;
    });

    return {
      x: Math.round(x / pointsArray.length),
      y: Math.round(y / pointsArray.length)
    };
  }

  getPointCoordinates(point: DuvalPoint) {
    // 60 degrees in radians.
    const sixtyDegrees = (60.0 * Math.PI) / 180.0;

    // Calculate Cartesian point from gasLeft and gasRight.
    let x = 0.0;
    let y = 0.0;

    x =
      point.getRightPercent() + point.getLeftPercent() * Math.cos(sixtyDegrees);
    y = point.getLeftPercent() * Math.sin(sixtyDegrees);

    // Scale it up to triangle size on screen.
    x = x * this.drawRegion.width;
    y = y * this.drawRegion.height;

    // Flip the image to be in the correct coordinate system.
    y = this.drawRegion.height - y;

    // Move the image into the draw region.
    x += this.drawRegion.x;
    y += this.drawRegion.y;

    return {
      x: x,
      y: y
    };
  }

  private getViewport(): IViewport {
    const element = this.root.node();
    const bcr = (element as any).getBoundingClientRect();
    return { x: 10, width: bcr.width - 20, height: bcr.height, y: 0 };
  }
}

class DuvalTriangleLegend {
  public labels: { [name: string]: string } = {};
  constructor(type: number) {
    switch (type) {
      case DuvalDataType.Type1:
        this.labels["PD"] = "Corona partial discharges";
        this.labels["D1"] = "Electrical discharges of Low energy";
        this.labels["D2"] = "Electrical discharges of High energy";
        this.labels["T1"] = "Thermal faults; Temp <300\u2103";
        this.labels["T2"] = "Thermal faults; Temp >300\u2103 & <700\u2103";
        this.labels["T3"] = "Thermal faults; Temp >700\u2103";
        this.labels["DT"] = "Mixtures of electrical & thermal faults";
        return;
      case DuvalDataType.Type3:
        this.labels["PD"] = "Corona partial discharges";
        this.labels["D1"] = "Electrical discharges of Low energy";
        this.labels["D2"] = "Electrical discharges of High energy";
        this.labels["T1"] = "Thermal faults; Temp <300\u2103";
        this.labels["T2"] = "Thermal faults; Temp >300\u2103 & <700\u2103";
        this.labels["T3"] = "Thermal faults; Temp >700\u2103";
        this.labels["DT"] = "Mixtures of electrical & thermal faults";
        return;
      case DuvalDataType.Type4:
        this.labels["PD"] = "Corona partial discharges";
        this.labels["S"] = "Stray Gassing of mineral oil; Temp <200\u2103";
        this.labels["C"] =
          "Hot spots with carbonization of paper; Temp >300\u2103";
        this.labels["O"] = "Overheating; Temp <250\u2103";
        this.labels["ND"] = "Not determined";
        return;
      case DuvalDataType.Type5:
        this.labels["PD"] = "Corona partial discharges";
        this.labels["S"] = "Stray Gassing of mineral oil; Temp <200\u2103";
        this.labels["C"] =
          "Hot spots with carbonization of paper; Temp >300\u2103";
        this.labels["O"] = "Overheating; Temp <250\u2103";
        this.labels["T2"] = "Thermal faults; Temp >300\u2103 & <700\u2103";
        this.labels["T3"] = "Thermal faults; Temp >700\u2103";
        this.labels["ND"] = "Not determined";
        return;
      default:
        return;
    }
  }
}

class DuvalPoint {
  left: number;
  right: number;
  base: number;

  constructor(left: number, right: number, base: number) {
    this.left = left;
    this.right = right;
    this.base = base;
  }

  getLeftPercent() {
    return this.getRelativePercent(this.left);
  }

  getRightPercent() {
    return this.getRelativePercent(this.right);
  }

  getBasePercent() {
    return this.getRelativePercent(this.base);
  }

  getRelativePercent(value: number) {
    return value / this.getTotal();
  }

  getTotal() {
    return this.left + this.right + this.base;
  }
}

class DuvalDataPoint extends DuvalPoint {
  date: Date;

  getDate() {
    return this.date;
  }

  constructor(left: number, right: number, base: number, date: Date) {
    super(left, right, base);
    this.date = date;
  }
}

class Rect {
  x: number;
  y: number;
  height: number;
  width: number;
}

class Point {
  x: number;
  y: number;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }
}

interface IViewport {
  height: number;
  width: number;
  x: number;
  y: number;
}

export default VisualDuvalTriangle;

export { DuvalDataPoint, DuvalPoint, DuvalTriangleLegend };
