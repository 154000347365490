// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { JellyBeans } from "@apm/widgets/build/components/JellyBeans";
import SearchParams from "@pg/common/build/models/SearchParams";
import {
  colorRiskHigh,
  colorRiskLow,
  colorRiskMedium,
  colorRiskUnknown
} from "@pg/common/build/styles/ColorVariables";
import { IRow, IRowData } from "common/datagrid/DataGrid";
import { routes } from "core/app/components/AppRoutes";
import { useAppNavigate } from "core/app/components/RouterProvider";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router";
import styled from "styled-components";
import { spacingXXLarge } from "styles/StyleVariables";
import addFilterSearchParam from "utils/addFilterSearchParam";

interface IAssetRisksProps {
  className?: string;
  row: IRow<IRowData>;
}

const AssetRisks = ({ className, row }: IAssetRisksProps) => {
  const intl = useIntl();
  const location = useLocation();
  const navigate = useAppNavigate();

  const handleJellyBeanItemClick = useCallback(
    (id: string, value: number) => {
      const searchParams = new SearchParams(location.search);
      searchParams.delete("f_s");
      searchParams.set("f_ar", JSON.stringify([id]));
      searchParams.set("f_as", JSON.stringify(["InService"]));

      addFilterSearchParam(searchParams, "f_ao", [
        row.data["Organization"],
        row.data["Region"],
        row.data["Location"]
      ]);

      navigate({
        pathname: routes.assets.pathname,
        search: searchParams.toString()
      });
    },
    [navigate, location.search, row.data]
  );

  return (
    <div className={className} data-qa="risk-bar">
      <JellyBeans
        formatValue={intl.formatNumber}
        jellyBeans={[
          {
            values: [
              {
                name: "High",
                color: colorRiskHigh,
                value: row.data["HighRiskCount"],
                onClick: handleJellyBeanItemClick
              },
              {
                name: "Medium",
                color: colorRiskMedium,
                value: row.data["MediumRiskCount"],
                onClick: handleJellyBeanItemClick
              },
              {
                name: "Low",
                color: colorRiskLow,
                value: row.data["LowRiskCount"],
                onClick: handleJellyBeanItemClick
              },
              {
                name: "Unknown",
                color: colorRiskUnknown,
                value: row.data["UnknownRiskCount"],
                onClick: handleJellyBeanItemClick
              }
            ]
          }
        ]}
      />
    </div>
  );
};

export default styled(AssetRisks)`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 ${spacingXXLarge};
`;
