// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { IssueStatuses } from "common/issueStatus/models/IssueStatuses";
import { IUpdateIssueStatusRequest } from "common/issueStatusUpdate/models/IUpdateIssueStatusRequest";
import IUpdateIssueStatusResponse from "common/issueStatusUpdate/models/IUpdateIssueStatusResponse";
import {
  showErrorNotifications,
  showSuccessNotifications
} from "common/issueStatusUpdate/utils/showNotifications";
import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { IntlShape } from "react-intl";
import { config } from "utils/AppConfig";

const bulkUpdateIssuesStatusAction = (
  requestData: IUpdateIssueStatusRequest,
  issueStatusTranslation: { [key in IssueStatuses]: string },
  intl: IntlShape
): Promise<IUpdateIssueStatusResponse> =>
  new Promise((resolve, reject) => {
    const url = UrlService.getApiUrl(
      config.api.issues.bulkUpdateIssueStatusUrl
    );
    const issueCount = Object.keys(requestData.issuesToUpdate).length;
    const onSuccess: (
      request: JQuery.jqXHR,
      data: IUpdateIssueStatusResponse
    ) => void = (request, data) => {
      showSuccessNotifications(
        data,
        issueCount,
        requestData.status,
        issueStatusTranslation,
        intl
      );
      resolve(data);
    };

    const onError: (request: JQuery.jqXHR) => void = (request) => {
      showErrorNotifications(request.responseJSON, issueCount, intl);
      reject();
    };

    EndpointService.put(
      url,
      onSuccess,
      onError,
      null,
      requestData,
      "application/json; charset=utf-8"
    );
  });

export default bulkUpdateIssuesStatusAction;
