// Copyright 2016-2024 Hitachi Energy. All rights reserved.

/* eslint-disable jsx-a11y/anchor-is-valid */
import SearchParams from "@pg/common/build/models/SearchParams";
import isPopoverClickEvent from "@pg/common/build/utils/isPopoverClickEvent";
import { useAppNavigate } from "core/app/components/RouterProvider";
import React, { memo, ReactNode } from "react";
import { matchPath, useLocation } from "react-router-dom";
import "./RibbonToggle.less";

interface IRibbonToggleInjectedProps {
  active: boolean;
}

interface IRibbonToggleOwnProps {
  children?: ReactNode;
  dataQa?: string;
  to: string;
  assetId: string;
}

type RibbonToggleProps = IRibbonToggleInjectedProps & IRibbonToggleOwnProps;

const RibbonToggle = memo(
  ({ children, dataQa, to, active, assetId }: RibbonToggleProps) => {
    const navigate = useAppNavigate();

    return (
      <a
        onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
          if (isPopoverClickEvent(e)) return;

          navigate({
            pathname: to,
            search: new SearchParams({ assetId }).toString()
          });
        }}
      >
        <div
          className={`asset-ribbon-toggle${active ? " active" : ""}`}
          data-qa={dataQa}
        >
          {children}
        </div>
      </a>
    );
  }
);

export default (props: IRibbonToggleOwnProps) => {
  const { pathname } = useLocation();
  const { to } = props;
  const newTo = `/Assets/DetailPage/${to}`;
  const active = matchPath(`${newTo}/*`, pathname) !== null;
  return <RibbonToggle {...props} to={newTo} active={active} />;
};
