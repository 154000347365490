// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import {
  FilterNames,
  IFilter,
  IFilterOption
} from "@pg/common/build/components/FilterPanel";
import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { IntlShape } from "react-intl";
import { config } from "utils/AppConfig";
import ApiFilter from "../models/IFilter";
import ApiFilterOption from "../models/IFilterOption";

const mapToOption = (
  filterName: FilterNames,
  filterOption: ApiFilterOption,
  intl: IntlShape
): IFilterOption => ({
  id: filterOption.Id,
  displayName: filterOption.DisplayName,
  options: filterOption.Options?.map((o) => mapToOption(filterName, o, intl))
});

const loadFilter = (filterName: FilterNames, intl: IntlShape) =>
  new Promise<IFilter>((resolve, reject) => {
    const url = UrlService.getApiUrl(config.api.filters, null, {
      filterNames: [filterName]
    });

    EndpointService.getJson<ApiFilter[]>(
      url,
      (request, data) => {
        const filters = data?.map((f) => {
          const filter: IFilter = {
            id: f.Id,
            options: f.Options?.map((o) => mapToOption(filterName, o, intl))
          };

          return filter;
        });

        resolve(!!filters ? filters[0] : null);
      },
      (request) => {
        reject();
      }
    );
  });

export default loadFilter;
