// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import BBChart from "common/chart/components/BBChart";
import React from "react";
import useChartConfiguration from "../hooks/useChartConfiguration";
import IParameterHistogram from "../models/IParameterHistogram";
import "./ParameterHistogramChart.less";
import ResizeWrapper from "common/ResizeWrapper";

interface IParameterHistogramChartProps {
  parameterHistogramData: IParameterHistogram[];
  unit: string;
}

const ParameterHistogramChart = ({
  parameterHistogramData,
  unit
}: IParameterHistogramChartProps) => {
  const configuration = useChartConfiguration(parameterHistogramData, unit);

  return (
    <div className="parameter-histogram-chart">
      <ResizeWrapper>
        <BBChart configuration={configuration}></BBChart>
      </ResizeWrapper>
    </div>
  );
};

export default ParameterHistogramChart;
