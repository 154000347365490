// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React from "react";
import { useIntl, IntlShape } from "react-intl";
import { ILocalizedText } from "../../core/app/services/IntlService";

export interface IModelMessage {
  localizedText: ILocalizedText;
  modelId: string;
  implementationId: string;
}

const ModelMessage = ({ localizedText, modelId, implementationId }: IModelMessage) => {
  const intl = useIntl();
  const formattedValue = ModelMessageService.format(
    localizedText,
    implementationId,
    modelId,
    intl
  );
  return <span>{formattedValue}</span>;
};

export default ModelMessage;

export class ModelMessageService {
  private static readonly subscoreIntlPrefix = "message";

  static format(
    localizedText: ILocalizedText,
    modelId: string,
    implementationId: string,
    intl: IntlShape
  ): string {
    if (!localizedText || !localizedText.TextId) return "";

    const messageId = localizedText.TextId.trim();
    if (!localizedText.TextValues) {
      return intl.formatMessage({
        id: `${modelId}.${this.subscoreIntlPrefix}.${messageId}`,
        defaultMessage: intl.formatMessage({
          id: `${implementationId}.${this.subscoreIntlPrefix}.${messageId}`,
          defaultMessage: intl.formatMessage({
            id: messageId,
            defaultMessage: messageId
          })
        })
      });
    } else {
      return intl.formatMessage(
        {
          id: `${modelId}.${this.subscoreIntlPrefix}.${messageId}`,
          defaultMessage: intl.formatMessage({
              id: `${implementationId}.${this.subscoreIntlPrefix}.${messageId}`,
              defaultMessage: intl.formatMessage({
                id: messageId,
                defaultMessage: messageId
              },
              localizedText.TextValues
            )},
            localizedText.TextValues
          )
        },
        localizedText.TextValues
      );
    }
  }
}
