// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { throttle } from "lodash";
import * as React from "react";

import IValidator from "../validators/IValidator";

import "./Input.less";

export interface IInputActions {
  createInput: () => void;
  setInputValue: (value: any) => void;
}

export interface IInputData {}

export interface IInputOwnProps<T> {
  formName: string;
  inputName: string;
  onInit?: (value: T) => void;
  onChange?: (value: T) => void;
  validators?: IValidator<T>[];
}

export interface IInputProps<T>
  extends IInputActions,
    IInputData,
    IInputOwnProps<T> {}

export interface IInputState {}

abstract class Input<
  TP extends IInputProps<TV>,
  TS extends IInputState,
  TV
> extends React.Component<TP, TS> {
  constructor(props: TP) {
    super(props);
    this.throttle();
    props.createInput();
  }

  componentDidUpdate(prevProps: TP) {
    this.throttle();
  }

  protected handleInit(value: TV) {
    const { onInit } = this.props;
    if (onInit) onInit(value);
    if (this.throttledSetInputValue) this.throttledSetInputValue(value);
  }

  protected handleChange(value: TV) {
    if (this.throttledOnChange) this.throttledOnChange(value);
    if (this.throttledSetInputValue) this.throttledSetInputValue(value);
  }

  private throttle = () => {
    const wait = 500;

    this.throttledOnChange = this.props.onChange
      ? throttle(this.props.onChange, wait)
      : null;

    this.throttledSetInputValue = this.props.setInputValue
      ? throttle(this.props.setInputValue, wait)
      : null;
  };

  private throttledOnChange: (value: TV) => void;

  private throttledSetInputValue: (value: TV) => void;
}

export default Input;
