// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import { Typography } from "antd";
import NameplateIcons from "features/detailpage/components/nameplateIcons/NameplateIcons";
import { isNil } from "lodash";
import IAssetStatus from "models/IAssetStatus";
import { useContext, useMemo } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import {
  colorGray0,
  colorStatusGray,
  colorStatusLightGreen,
  colorStatusPurple
} from "styles/ColorVariables";
import {
  spacingBase,
  spacingMedium,
  spacingSmall,
  spacingXLarge,
  spacingXSmall
} from "styles/StyleVariables";
import getLifecycleStatusStyle from "../../../utils/getLifecycleStatusStyle";
import AssetRibbonContext from "../contexts/AssetRibbonContext";

const { Paragraph, Text } = Typography;

interface IDetailsProps {
  className?: string;
  parentId?: string;
  name?: string;
  modelId?: string;
  assetType?: string;
  status?: string;
  lifecycleStatus?: IAssetStatus["LifecycleStatus"];
}

const Details = ({
  className,
  parentId,
  name,
  modelId,
  assetType,
  status,
  lifecycleStatus
}: IDetailsProps) => {
  const intl = useIntl();
  const { assetId } = useContext(AssetRibbonContext);
  const assetLabel = useMemo(() => {
    if (name) {
      return `${assetId} - ${name}`;
    }

    return assetId;
  }, [assetId, name]);

  const statusStyle = useMemo(
    () => getLifecycleStatusStyle(lifecycleStatus),
    [lifecycleStatus]
  );

  return (
    <div className={`${className} asset-details asset-ribbon-section`}>
      <div className="asset-details-header">
        <div className="asset-details-labels">
          {!isNil(parentId) && (
            <Paragraph
              strong={true}
              className="asset-parent-id"
              data-qa="header-parent-id"
              ellipsis={true}
            >
              {parentId}
            </Paragraph>
          )}
          <Paragraph
            className="asset-label"
            data-qa="header-asset-id-with-name"
            ellipsis={{ rows: 2 }}
          >
            {assetLabel}
          </Paragraph>
        </div>
        {statusStyle && (
          <div className="asset-details-status">
            <span className={`asset-status ${statusStyle.className}`}>
              {statusStyle?.iconName && (
                <Icon
                  variant="outlined"
                  name={statusStyle.iconName}
                  dataQa="header-status-icon"
                />
              )}
              <Text ellipsis={true} strong={true} data-qa="header-status-text">
                {status ||
                  intl.formatMessage({
                    defaultMessage: lifecycleStatus,
                    id: `asset.nameplate.lifecycle_status.${lifecycleStatus}`
                  })}
              </Text>
            </span>
          </div>
        )}
      </div>
      <NameplateIcons
        assetId={assetId}
        parentId={parentId}
        modelId={modelId}
        assetType={assetType}
        lifecycleStatus={lifecycleStatus}
      />
    </div>
  );
};

const StyledDetails = styled(Details)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .asset-details-header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    .asset-details-labels {
      display: flex;
      flex-direction: column;
      font-size: 9pt;
      min-width: 60%;

      .ant-typography {
        margin-bottom: 0;
      }
    }

    .asset-details-status {
      min-width: 40%;
      padding-left: ${spacingMedium};
      text-align: right;

      .asset-status {
        max-width: 100%;
        display: inline-flex;
        align-items: center;
        margin-top: ${spacingSmall};
        padding: ${spacingXSmall} ${spacingXLarge};
        border-radius: 20px;

        &.in-service {
          background-color: ${colorStatusLightGreen};
        }

        &.removed {
          background-color: ${colorStatusGray};
        }

        &.in-reserve {
          background-color: ${colorStatusPurple};
        }
        > * {
          &:first-child {
            padding-right: ${spacingBase};
          }
          color: ${colorGray0};
        }
      }
    }
  }

  &.asset-ribbon-section {
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    flex-wrap: nowrap;
  }
`;

export default StyledDetails;
