// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import dayjs from "dayjs";
import { FormattedDate } from "react-intl";
import DayjsInput from "models/Dayjs";

interface ITooltipFormattedDate {
  date: DayjsInput;
}

const TooltipFormattedDate = ({ date }: ITooltipFormattedDate) => {
  return (
    <span className="date">
      {date === undefined || date == null ? (
        <span>-</span>
      ) : (
        <FormattedDate
          value={dayjs(date).toDate()}
          year="numeric"
          month="numeric"
          day="numeric"
          hour="numeric"
          minute="numeric"
        />
      )}
    </span>
  );
};

export default TooltipFormattedDate;
