// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Tooltip from "common/Tooltip";
import useNavToAsset from "hooks/useNavToAsset";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import styled from "styled-components";

interface IParentColumnProps {
  assetId: string;
  assetName: string;
  assetType?: string;
  location?: string;
  className?: string;
}

const ParentColumn = ({
  assetId,
  assetName,
  assetType,
  location,
  className
}: IParentColumnProps) => {
  const { navigateTo } = useNavToAsset();

  const showLongMessage = assetType !== undefined && location !== undefined;
  const showShortMessage = !showLongMessage;
  const assetInfo = assetName ? `${assetId} - ${assetName}` : assetId;

  return (
    <ColumnWrapper className={className}>
      {assetId && (
        <Tooltip title={<div>{assetInfo}</div>}>
          <Link
            data-qa="parentComponentId"
            className="anchor id link-label"
            to={navigateTo(assetId)}
          >
            {assetInfo}
          </Link>
        </Tooltip>
      )}
      {showLongMessage && (
        <FormattedMessage
          defaultMessage="{location} / {assetType}"
          id="components_page.grid.columns.asset.location_asset_type"
          values={{
            assetType: assetType,
            location: location
          }}
        />
      )}
      {showShortMessage && (
        <FormattedMessage
          defaultMessage="{value}"
          id="components_page.grid.columns.asset.location_asset_type_short"
          values={{
            value: assetType || location
          }}
        />
      )}
    </ColumnWrapper>
  );
};

const gridPadding = "10px";

const ColumnWrapper = styled.div`
  padding: ${gridPadding};

  .id {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export default ParentColumn;
