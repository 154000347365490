// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Typography } from "antd";
import Tooltip from "common/Tooltip";
import RiskComponent from "common/columns/Risk";
import useNavToAsset from "hooks/useNavToAsset";
import { isNil } from "lodash";
import { useMemo } from "react";
import { NavLink } from "react-router-dom";
import { styled } from "styled-components";
import { colorGray10 } from "styles/ColorVariables";
import {
  fontSizeSmall,
  iconFontSizeSmall,
  spacingLarge,
  spacingXSmall,
  spacingXXLarge
} from "styles/StyleVariables";

const { Text } = Typography;
export interface IWatchListItem {
  AssetId: string;
  AssetRisk: string;
  AssetType: string;
  AssetLocation: string;
  AssetDescription: string;
}

const Item = ({
  AssetId,
  AssetRisk,
  AssetType,
  AssetLocation,
  AssetDescription
}: IWatchListItem) => {
  const { navigateTo } = useNavToAsset();
  const locationTypeText = useMemo(() => {
    switch (true) {
      case !isNil(AssetLocation) && !isNil(AssetType):
        return `${AssetLocation} / ${AssetType}`;
      case !isNil(AssetLocation):
        return AssetLocation;
      case !isNil(AssetType):
        return AssetType;
      default:
        return "";
    }
  }, [AssetLocation, AssetType]);

  return (
    <Wrapper key={AssetId} data-qa="watchlist-item">
      {AssetRisk && <Risk risk={AssetRisk} />}
      <TextArea>
        {locationTypeText && (
          <Tooltip title={locationTypeText}>
              <LocationType strong data-qa="location">
                {locationTypeText}
              </LocationType>
          </Tooltip>
        )}
        <Tooltip title={AssetId}>
            <IdLink className="anchor" data-qa="link" to={navigateTo(AssetId)}>
              {AssetId}
            </IdLink>
        </Tooltip>
        {AssetDescription && (
          <Tooltip title={AssetDescription}>
            <Description data-qa="description">{AssetDescription}</Description>
          </Tooltip>
        )}
      </TextArea>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: ${spacingLarge};
  border-bottom: 1px solid ${colorGray10};

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
`;

const Risk = styled(RiskComponent)`
  width: 16px !important;
  margin-right: ${spacingXXLarge};
`;

const TextArea = styled.div`
  width: calc(100% - 80px);
`;

const LocationType = styled(Text)`
  display: block;
  font-size: ${iconFontSizeSmall};
`;

const IdLink = styled(NavLink)`
  font-size: ${fontSizeSmall};
  margin-top: ${spacingXSmall};

  &:hover {
    text-decoration: underline;
  }

  .ant-typography {
    color: inherit;
  }
`;

const Description = styled.div`
  font-size: ${iconFontSizeSmall};
  font-weight: 300;
  margin-top: ${spacingXSmall};
`;

export default Item;
