// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Button } from "antd";
import { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { IColumn } from "./DataGrid";

interface IExportToCsvButtonProps {
  onClick?: (columns: IColumn[]) => void;
  disabled?: boolean;
  columns?: IColumn[];
}

const ExportToCsvButton = ({
  onClick,
  disabled,
  columns
}: IExportToCsvButtonProps) => {
  const handleExportCsvClick = useCallback(() => {
    onClick?.(columns);
  }, [columns, onClick]);

  return (
    <Button
      className="light"
      disabled={disabled}
      type="link"
      onClick={handleExportCsvClick}
      data-qa="export-csv-button"
    >
      <FormattedMessage defaultMessage="Csv" id="data_grid.footer.export.csv" />
    </Button>
  );
};

export default ExportToCsvButton;
