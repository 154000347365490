// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import SearchParams from "@pg/common/build/models/SearchParams";
import DataSource from "common/DuvalAnalysis/models/DataSource";
import {
  useFeature,
  VisualizationKeys
} from "core/app/components/FeatureToggle";
import { LayoutRoute } from "core/app/components/RouterProvider";
import BushingOfflineTab from "features/detailpage/features/bushingsoffline/components/BushingOfflineTab";
import BushingVisualizationTab from "features/detailpage/features/bushingvisualization/components/BushingVisualizationTab";
import DGA from "features/detailpage/features/DGA";
import DuvalTabs from "features/detailpage/features/duval/components/DuvalTabs";
import LTCTab from "features/detailpage/features/ltc/components/LTCTab";
import PartialDischarge from "features/detailpage/features/PartialDischarge";
import RogersRatioTab from "features/detailpage/features/rogersratio/components/RogersRatioTab";
import SubscoresGridContainer from "features/detailpage/features/subfactorsgrid/containers/SubfactorsGridContainer";
import ThermalVisualizationTab from "features/detailpage/features/ThermalVisualizationTab";
import VoltageStressTabs from "features/detailpage/features/VoltageStress";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import SOT from "../../SOT";

interface IUseTransformerRoutesProps {
  assetId: string;
  implementationId: string;
  modelId: string;
}

const useTransformerRoutes = ({
  assetId,
  implementationId,
  modelId
}: IUseTransformerRoutesProps) => {
  const { isAvailable } = useFeature();
  const intl = useIntl();
  const searchParams = useMemo(() => new SearchParams({ assetId }), [assetId]);

  const routes = useMemo<LayoutRoute[]>(() => {
    return [
      isAvailable(VisualizationKeys.AssetDetailPage_Tabs_Parameters_Subfactors)
        ? {
            id: "Assets-DetailPage-Parameters-Factors",
            route: "Factors/*",
            displayName: intl.formatMessage({
              defaultMessage: "Factors",
              id: "detail_page.widgets.subfactors"
            }),
            to: {
              pathname: "/Assets/DetailPage/Parameters/Factors",
              search: searchParams.toString()
            },
            element: (
              <SubscoresGridContainer
                assetId={assetId}
                modelId={modelId}
                implementationId={implementationId}
              />
            )
          }
        : undefined,
      isAvailable(VisualizationKeys.AssetDetailPage_Tabs_Parameters_Dga)
        ? {
            id: "Assets-DetailPage-Parameters-DGA",
            route: "DGA/*",
            displayName: intl.formatMessage({
              defaultMessage: "DGA",
              id: "detail_page.widgets.analytics.transformers.dga"
            }),
            to: {
              pathname: "/Assets/DetailPage/Parameters/DGA",
              search: searchParams.toString()
            },
            element: (
              <DGA
                assetId={assetId}
                dataSources={["default", "advanced", "offline"]}
                defaultDataSource={"offline"}
              />
            )
          }
        : undefined,
      isAvailable(VisualizationKeys.AssetDetailPage_Tabs_Parameters_Duval)
        ? {
            id: "Assets-DetailPage-Parameters-Duval",
            route: "Duval/*",
            displayName: intl.formatMessage({
              defaultMessage: "Duval",
              id: "detail_page.widgets.analytics.transformers.duval"
            }),
            to: {
              pathname: "/Assets/DetailPage/Parameters/Duval",
              search: searchParams.toString()
            },
            element: (
              <DuvalTabs
                className="duval-tabs"
                assetId={assetId}
                defaultDataSource={DataSource.Offline}
                dataSources={[
                  DataSource.Online,
                  DataSource.Advanced,
                  DataSource.Offline
                ]}
                implementationId={implementationId}
              />
            )
          }
        : undefined,
      isAvailable(
        VisualizationKeys.AssetDetailPage_Tabs_Parameters_Rogers_Ratio
      )
        ? {
            id: "Assets-DetailPage-Parameters-RogersRatio",
            route: "RogersRatio/*",
            displayName: intl.formatMessage({
              defaultMessage: "Rogers Ratio",
              id: "detail_page.widgets.analytics.transformers.rogers_ratio"
            }),
            to: {
              pathname: "/Assets/DetailPage/Parameters/RogersRatio",
              search: searchParams.toString()
            },
            element: <RogersRatioTab assetId={assetId} />
          }
        : undefined,
      isAvailable(
        VisualizationKeys.AssetDetailPage_Tabs_Parameters_Standard_Oil_Tests
      )
        ? {
            id: "Assets-DetailPage-Parameters-StandardOilTests",
            route: "StandardOilTests/*",
            displayName: intl.formatMessage({
              defaultMessage: "Standard Oil Tests",
              id: "detail_page.widgets.analytics.transformers.standard_oil_tests"
            }),
            to: {
              pathname: "/Assets/DetailPage/Parameters/StandardOilTests",
              search: searchParams.toString()
            },
            element: (
              <SOT
                assetId={assetId}
                dataSources={["offline", "online"]}
                defaultDataSource={"offline"}
              />
            )
          }
        : undefined,
      isAvailable(
        VisualizationKeys.AssetDetailPage_Tabs_Parameters_Bushing_Offline
      )
        ? {
            id: "Assets-DetailPage-Parameters-Bushings",
            route: "Bushings/*",
            displayName: intl.formatMessage({
              defaultMessage: "Bushings",
              id: "detail_page.widgets.analytics.transformers.bushings_offline"
            }),
            to: {
              pathname: "/Assets/DetailPage/Parameters/Bushings",
              search: searchParams.toString()
            },
            element: <BushingOfflineTab assetId={assetId} />
          }
        : undefined,
      isAvailable(
        VisualizationKeys.AssetDetailPage_Tabs_Parameters_Bushing_Visualization
      )
        ? {
            id: "Assets-DetailPage-Parameters-OnlineBushings",
            route: "OnlineBushings/*",
            displayName: intl.formatMessage({
              defaultMessage: "Online Bushings",
              id: "detail_page.widgets.analytics.transformers.bushing_visualization"
            }),
            to: {
              pathname: "/Assets/DetailPage/Parameters/OnlineBushings",
              search: searchParams.toString()
            },
            element: <BushingVisualizationTab assetId={assetId} />
          }
        : undefined,
      isAvailable(
        VisualizationKeys.AssetDetailPage_Tabs_Parameters_Thermal_Visualization
      )
        ? {
            id: "Assets-DetailPage-Parameters-Thermal",
            route: "Thermal/*",
            displayName: intl.formatMessage({
              defaultMessage: "Thermal",
              id: "detail_page.widgets.analytics.transformers.thermal_visualization"
            }),
            to: {
              pathname: "/Assets/DetailPage/Parameters/Thermal",
              search: searchParams.toString()
            },
            element: <ThermalVisualizationTab assetId={assetId} />
          }
        : undefined,
      isAvailable(VisualizationKeys.AssetDetailPage_Tabs_Parameters_Ltc)
        ? {
            id: "Assets-DetailPage-Parameters-LTC",
            route: "LTC/*",
            displayName: intl.formatMessage({
              defaultMessage: "LTC",
              id: "detail_page.widgets.analytics.transformers.ltc"
            }),
            to: {
              pathname: "/Assets/DetailPage/Parameters/LTC",
              search: searchParams.toString()
            },
            element: (
              <LTCTab
                assetId={assetId}
                modelId={modelId}
                implementationId={implementationId}
              />
            )
          }
        : undefined,
      isAvailable(
        VisualizationKeys.AssetDetailPage_Tabs_Parameters_Partial_Discharge
      )
        ? {
            id: "Assets-DetailPage-Parameters-PartialDischarge",
            route: "PartialDischarge/*",
            displayName: intl.formatMessage({
              defaultMessage: "Partial Discharge",
              id: "detail_page.widgets.analytics.transformers.partial_discharge"
            }),
            to: {
              pathname: "/Assets/DetailPage/Parameters/PartialDischarge",
              search: searchParams.toString()
            },
            element: (
              <PartialDischarge
                assetId={assetId}
                modelId={modelId}
                modelImplementationId={implementationId}
              />
            )
          }
        : undefined,
      isAvailable(
        VisualizationKeys.AssetDetailPage_Tabs_Parameters_Voltage_Stress
      )
        ? {
            id: "Assets-DetailPage-Parameters-VoltageStress",
            route: "VoltageStress/*",
            displayName: intl.formatMessage({
              defaultMessage: "Voltage Stress",
              id: "detail_page.widgets.analytics.transformers.voltage_stress"
            }),
            to: {
              pathname: "/Assets/DetailPage/Parameters/VoltageStress",
              search: searchParams.toString()
            },
            element: (
              <VoltageStressTabs
                assetId={assetId}
                implementationId={implementationId}
              />
            )
          }
        : undefined
    ].filter(Boolean);
  }, [assetId, implementationId, intl, isAvailable, modelId, searchParams]);

  return routes;
};

export default useTransformerRoutes;
