// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { resetForm } from "common/form/actions/FormActions";
import { createComment } from "features/detailpage/features/issues/actions/IssuesActions";
import IssueDetailsCommentsModal, {
  IIssueDetailsCommentsModalActions,
  IIssueDetailsCommentsModalData,
  IIssueDetailsCommentsModalOwnProps
} from "features/detailpage/features/issues/components/IssueDetailsCommentsModal";
import ICommentForm from "features/detailpage/models/ICommentForm";
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import { AppDispatch } from "store";
import modalWindowStore from "store/common/modal/ModalWindowStore";

export function mapStateToProps(state: IState): IIssueDetailsCommentsModalData {
  return {};
}

export function mapDispatchToProps(
  dispatch: AppDispatch,
  ownProps: IIssueDetailsCommentsModalOwnProps
): IIssueDetailsCommentsModalActions {
  return {
    closeModal: () => {
      modalWindowStore.closeModal(ownProps.modalId);
    },
    createComment: (comment: ICommentForm): Promise<void> => {
      return createComment(dispatch, ownProps.issueId, comment);
    },
    hideProcessing: () => {
      modalWindowStore.hideProcessing(ownProps.modalId);
    },
    resetForm: (formName: string) => {
      resetForm(dispatch, formName);
    },
    showProcessing: () => {
      modalWindowStore.showProcessing(ownProps.modalId);
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IssueDetailsCommentsModal);
