// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import * as React from "react";

import "./Manufacturer.less";
import Tooltip from "common/Tooltip";

interface IManufacturerProps {
  manufacturer: string | undefined;
  model: string | undefined;
}

const Manufacturer = ({ manufacturer, model }: IManufacturerProps) => (
  <div className="column manufacturer">
    <Tooltip title={<div>{manufacturer}</div>}>
      <div className="manufacturer-name default-grey-label">{manufacturer}</div>
    </Tooltip>
    <Tooltip title={<div>{model}</div>}>
      <div className="model" title={model}>
        {model}
      </div>
    </Tooltip>
  </div>
);

export default Manufacturer;
