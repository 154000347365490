// Copyright 2016-2024 Hitachi Energy. All rights reserved.

enum PowerFactorType {
  PF = "PowerFactor",
  PF100 = "PowerFactor100C",
  PF90 = "PowerFactor90C",
  PF25 = "PowerFactor25C"
}

export { PowerFactorType };
