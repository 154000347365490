// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import $ from "jquery";

export default class EndpointService {
  static get<T>(
    url: string,
    success: (request: JQuery.jqXHR, data: T) => void = null,
    error: (request: JQuery.jqXHR) => void = null,
    contentType: string = null,
    dataType: string = null
  ): JQuery.jqXHR {
    const settings = EndpointService.getAjaxSettings(
      url,
      "GET",
      success,
      error
    );
    if (contentType) settings.contentType = contentType;
    if (dataType) settings.dataType = dataType;

    const request = $.ajax(settings);
    return request;
  }

  static getJson<T>(
    url: string,
    success: (request: JQuery.jqXHR, data: T) => void = null,
    error: (request: JQuery.jqXHR) => void = null
  ): JQuery.jqXHR {
    return EndpointService.get(url, success, error, undefined, "json");
  }

  public static post<T>(
    url: string,
    success: (request: JQuery.jqXHR, data: T) => void = null,
    error: (request: JQuery.jqXHR) => void = null,
    content: JQuery.PlainObject | string = null,
    contentType: string = null,
    dataType: string = null,
    processData: boolean = true
  ): JQuery.jqXHR {
    const settings = EndpointService.getAjaxSettings(
      url,
      "POST",
      success,
      error
    );

    if (dataType) settings.dataType = dataType;
    if (content) settings.data = JSON.stringify(content);
    if (contentType) settings.contentType = contentType;
    if (processData) settings.processData = processData;

    const request = $.ajax(settings);
    return request;
  }

  // The method posts a json object, but expects the returning object to be json as well
  static postJson<T>(
    url: string,
    success: (request: JQuery.jqXHR, data: T) => void = null,
    error: (request: JQuery.jqXHR) => void = null,
    content: JQuery.PlainObject | string = null
  ): JQuery.jqXHR {
    return EndpointService.post(
      url,
      success,
      error,
      content,
      "application/json; charset=utf-8",
      "json"
    );
  }

  // The method posts a binary object, but expects the returning object to be binary as well
  static postBinary<T>(
    url: string,
    success: (request: JQuery.jqXHR, data: T) => void = null,
    error: (request: JQuery.jqXHR) => void = null,
    content: JQuery.PlainObject | string = null
  ): JQuery.jqXHR {
    return EndpointService.post(
      url,
      success,
      error,
      content,
      "application/json; charset=utf-8",
      "binary",
      false
    );
  }

  static put<T, K = T>(
    url: string,
    success: (request: JQuery.jqXHR, data: K) => void = null,
    error: (request: JQuery.jqXHR) => void = null,
    progress: (percent: number) => void = null,
    content: JQuery.PlainObject | string = null,
    contentType: string = null,
    dataType: string = null,
    processData: boolean = true
  ): JQuery.jqXHR {
    const settings = EndpointService.getAjaxSettings(
      url,
      "PUT",
      success,
      error,
      progress
    );

    if (dataType) settings.dataType = dataType;
    if (content) settings.data = JSON.stringify(content);
    if (contentType) settings.contentType = contentType;
    if (processData) settings.processData = processData;

    const request = $.ajax(settings);
    return request;
  }

  // The method puts a json object, but expects the returning object to be json as well
  static putJson<T>(
    url: string,
    success: (request: JQuery.jqXHR, data: T) => void = null,
    error: (request: JQuery.jqXHR) => void = null,
    progress: (percent: number) => void = null,
    content: JQuery.PlainObject | string = null
  ): JQuery.jqXHR {
    return EndpointService.put(
      url,
      success,
      error,
      progress,
      content,
      "application/json; charset=utf-8",
      "json"
    );
  }

  static delete<T>(
    url: string,
    success: (request: JQuery.jqXHR, data: T) => void = null,
    error: (request: JQuery.jqXHR) => void = null
  ): JQuery.jqXHR {
    const settings = EndpointService.getAjaxSettings(
      url,
      "DELETE",
      success,
      error
    );

    const request = $.ajax(settings);
    return request;
  }

  private static getAjaxSettings<T>(
    url: string,
    type: string,
    success: (request: JQuery.jqXHR, data: T) => void = null,
    error: (request: JQuery.jqXHR) => void = null,
    progress: (percent: number) => void = null
  ): JQuery.AjaxSettings {
    const settings: JQuery.AjaxSettings = {
      url,
      type,
      cache: false,
      success: (data: T, status: string, xhr: JQuery.jqXHR) => {
        if (success) success(xhr, data);
      },
      error: (xhr: JQuery.jqXHR, status: string, err: string) => {
        if (error) error(xhr);
      }
    };
    if (progress) {
      settings.xhr = () => {
        const xhr = new window.XMLHttpRequest();
        xhr.upload.onprogress = (e) => {
          progress(e.loaded / e.total);
        };
        return xhr;
      };
    }
    return settings;
  }
}

// For QA purpose only
if (!(global as any).apm) (global as any).apm = {};
(global as any).apm.endpoint = EndpointService;
