// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import { FormattedMessage } from "react-intl";

const BushingVisualizationFailed = () => (
  <div className="bushing-visualization container">
    <div className="failed">
      <Icon name="warning" />
      <FormattedMessage
        defaultMessage="Data loading failed"
        id="detail_page.parameters.bushing_visualization.data_loading_failed"
      />
    </div>
  </div>
);

export default BushingVisualizationFailed;
