// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { useState } from "react";

export interface IChartSize {
  height: number;
  width: number;
}

const useChartSize = () => useState<IChartSize>(null);

export default useChartSize;
