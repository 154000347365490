// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { shouldPolyfill } from "@formatjs/intl-locale/should-polyfill";

const polyfillLocale = (locale: string): Promise<any> => {
  if (!shouldPolyfill()) return Promise.resolve();
  return import("@formatjs/intl-locale/polyfill");
};

export default polyfillLocale;
