// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { IEnvironment } from "core/app/reducers/settings/EnvironmentReducer";
import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { IAjaxAction } from "reducers/Index";
import { AppDispatch } from "store";
import { config } from "utils/AppConfig";

export enum Actions {
  Settings_SetEnvironment = "Settings_SetEnvironment"
}

const setEnvironmentAction = (
  request: JQueryXHR,
  data?: IEnvironment
): IAjaxAction<IEnvironment> => {
  const action: IAjaxAction<IEnvironment> = {
    type: Actions.Settings_SetEnvironment,
    data,
    request
  };
  return action;
};

export const loadEnvironment = (dispatch: AppDispatch) => {
  const url = UrlService.getApiUrl(config.api.user.environmentUrl);
  const request = EndpointService.getJson<IEnvironment>(
    url,
    (request, data) => {
      dispatch(setEnvironmentAction(request, data));
    },
    (request) => {
      dispatch(setEnvironmentAction(request));
    }
  );

  dispatch(setEnvironmentAction(request));
};
