// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { AppDispatch } from "store";
import EndpointService from "../services/EndpointService";
import setDataAction from "./setDataAction";

interface ILoadDataPayload<T> {
  key: string;
  url: string;
  content?: T;
  method?: "get" | "post";
}

const loadData =
  <T1, T2>({ key, url, content, method = "get" }: ILoadDataPayload<T2>) =>
  (dispatch: AppDispatch) =>
    new Promise<any>((resolve, reject) => {
      const success = (request: JQuery.jqXHR<any>, data: T1) => {
        dispatch(setDataAction(key, request, data));
        resolve(data);
      };

      const error = (request: JQuery.jqXHR<any>) => {
        dispatch(setDataAction(key, request));
        reject();
      };

      const request =
        method === "get"
          ? EndpointService.getJson<T1>(url, success, error)
          : method === "post"
          ? EndpointService.postJson<T1>(url, success, error, content)
          : undefined;

      dispatch(setDataAction(key, request));
    });

export default loadData;
