// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { connect } from "react-redux";

import Processing from "components/common/Processing";
import { WidgetErrorMessage } from "components/common/widget/Widget";
import Data from "core/data/models/Data";

import { IState } from "reducers/Index";

import RiskMatrix from "features/detailpage/features/riskmatrix/components/RiskMatrix/components/RiskMatrix";
import getAssetDetailsSelector from "features/detailpage/selectors/getAssetDetailsSelector";
import IAssetDetails from "models/IAssetDetails";
import useRiskMatrixWidget from "../hooks/useRiskMatrixWidget";

interface IRiskMatrixWidgetDataProps {
  assetDetails: Data<IAssetDetails>;
}

interface IRiskMatrixWidgetOwnProps {
  assetId: string;
}

type RiskMatrixWidgetProps = IRiskMatrixWidgetDataProps &
  IRiskMatrixWidgetOwnProps;

const RiskMatrixWidget = ({ assetId, assetDetails }: RiskMatrixWidgetProps) => {
  const {
    showLoadingComponent,
    showNotEmptyRiskMatrix,
    showEmptyRiskMatrix,
    showErrorComponent,
    errorMessage
  } = useRiskMatrixWidget({
    assetId,
    assetDetails
  });

  return (
    <>
      {showLoadingComponent && <Processing />}
      {showNotEmptyRiskMatrix && (
        <RiskMatrix
          overlay={false}
          assetId={assetId}
          assetDetails={assetDetails}
        />
      )}
      {showEmptyRiskMatrix && (
        <RiskMatrix
          overlay={true}
          assetId={assetId}
          assetDetails={assetDetails}
        />
      )}
      {showErrorComponent && (
        <WidgetErrorMessage
          messageId="global.empty"
          messageDefault={errorMessage}
          messageValues={errorMessage}
        />
      )}
    </>
  );
};

const mapStateToProps = (
  state: IState,
  { assetId }: IRiskMatrixWidgetOwnProps
): IRiskMatrixWidgetDataProps => ({
  assetDetails: getAssetDetailsSelector(state)(assetId)
});

export default connect(mapStateToProps)(RiskMatrixWidget);
