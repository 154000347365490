// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import {
  RouterContext,
  useAppNavigate
} from "core/app/components/RouterProvider";
import { MouseEvent, useContext, useRef } from "react";
import "./Back.less";

const Back = () => {
  const navigate = useAppNavigate();
  const { historyLength } = useContext(RouterContext);
  const historyLengthRef = useRef(historyLength);

  const handleBackClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const n = historyLengthRef.current - historyLength - 1;
    navigate(n);
  };

  return (
    <div
      className="asset-ribbon-back"
      data-qa="return-to-previous-site"
      onClick={handleBackClick}
    >
      <Icon name="chevron_left" size="lg" />
    </div>
  );
};

export default Back;
