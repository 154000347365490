// Copyright 2016-2024 Hitachi Energy. All rights reserved.

/* eslint-disable jsx-a11y/anchor-is-valid */
import useNavToAsset from "hooks/useNavToAsset";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import IKeyRiskChangesInfo from "../../models/IKeyRiskChangesInfo";
import Tooltip from "common/Tooltip";

interface IKeyRiskItem {
  item: IKeyRiskChangesInfo;
}

const KeyRiskItem = ({ item }: IKeyRiskItem) => {
  const assetDisplayedName = useMemo(
    () => [item.AssetId, item.AssetName].filter(Boolean).join(" - "),
    [item.AssetId, item.AssetName]
  );

  const { navigateTo } = useNavToAsset();

  return (
    <div className="key-risk-item">
      <div
        className={
          "border-left asset-risk-border " + item.AssetRisk.toLowerCase()
        }
      >
        <div className="asset-id">
          <Tooltip title={assetDisplayedName}>
            <Link
              data-qa="link"
              className="explore anchor link-label"
              to={navigateTo(item.AssetId)}
            >
              {assetDisplayedName}
            </Link>
          </Tooltip>
        </div>
        <div className="asset-details">
          <span>{item.Location}</span>
          <span> / </span>
          <span>{item.AssetType}</span>
        </div>
      </div>
    </div>
  );
};

interface IKeyRiskItemsGroupProps {
  itemsData: IKeyRiskChangesInfo[];
}

const KeyRiskItemsGroup = ({ itemsData }: IKeyRiskItemsGroupProps) => (
  <>
    {itemsData.map((item, index) => (
      <KeyRiskItem item={item} key={index} />
    ))}
  </>
);

export default KeyRiskItemsGroup;
