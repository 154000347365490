// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Data from "core/data/models/Data";
import { IState } from "reducers/Index";
import { createSelector } from "reselect";
import dataKey from "../constants/dgaDataKey";
import IDGAData from "../models/IDGAData";

const getDGADataSelector = createSelector(
  (state: IState) => (assetId: string) =>
    state.data[dataKey(assetId)] as Data<IDGAData>,
  (dgaData) => dgaData
);

export default getDGADataSelector;
