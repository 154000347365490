// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import OperatingMechanismMotor from "@apm/widgets/build/widgets/OperatingMechanismMotor";
import Processing from "components/common/Processing";
import ProcessingModal from "components/common/ProcessingModal";
import {
  WidgetErrorMessage,
  WidgetNoDataMessage
} from "components/common/widget/Widget";
import { getUserSelector } from "core/app/reducers/AppReducer";
import { IUser } from "core/app/reducers/settings/UserReducer";
import Data, { Statuses } from "core/data/models/Data";
import useIsCommandIntegrationEnabledData from "features/detailpage/hooks/useIsCommandIntegrationEnabledData";
import LifecycleStatus from "models/LifecycleStatus";
import { IntlShape } from "react-intl";
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import { AppDispatch } from "store";
import acknowledge from "../../../actions/acknowledge";
import IThresholdCorrection from "../../../models/IThresholdCorrection";
import loadOperatingMechanismMotor from "../actions/loadOperatingMechanismMotor";
import useOperatingMechanismMotorParameters from "../hooks/useOperatingMechanismMotorParameters";
import IOperatingMechanismMotor from "../models/IOperatingMechanismMotor";
import getOperatingMechanismMotorSelector from "../selectors/getOperatingMechanismMotorSelector";

interface IOperatingMechanismMotorData {
  operatingMechanismMotor: Data<IOperatingMechanismMotor>;
  user: Data<IUser>;
}

interface IOperatingMechanismMotorActions {
  loadOperatingMechanismMotor: (assetId: string) => void;
  acknowledge: (
    intl: IntlShape,
    parameterName: string,
    success: (correction: IThresholdCorrection) => void,
    error: () => void
  ) => void;
}

interface IOperatingMechanismMotorOwnProps {
  assetId: string;
  lifecycleStatus: LifecycleStatus;
  getAcknowledgeUrl: (assetId: string, parameterName: string) => string;
}

type OperatingMechanismMotorInfoProps = IOperatingMechanismMotorOwnProps &
  IOperatingMechanismMotorData &
  IOperatingMechanismMotorActions;

const OperatingMechanismMotorInfo = ({
  acknowledge,
  assetId,
  loadOperatingMechanismMotor,
  operatingMechanismMotor,
  user,
  lifecycleStatus
}: OperatingMechanismMotorInfoProps) => {
  const {
    handleAcknowledgeClick,
    parametersGroup,
    processing,
    thresholdCorrectionParams,
    translations
  } = useOperatingMechanismMotorParameters({
    assetId,
    operatingMechanismMotor,
    loadOperatingMechanismMotor,
    acknowledge
  });
  const isReadOnlyMode = useIsCommandIntegrationEnabledData(
    lifecycleStatus,
    user
  );
  return (
    <>
      {operatingMechanismMotor &&
        operatingMechanismMotor.status === Statuses.Loading && <Processing />}
      {operatingMechanismMotor &&
        operatingMechanismMotor.status === Statuses.Succeeded &&
        operatingMechanismMotor.data && (
          <OperatingMechanismMotor
            onAcknowledgeClick={handleAcknowledgeClick}
            parametersGroups={[parametersGroup]}
            thresholdCorrectionParams={thresholdCorrectionParams}
            translations={translations}
            readonly={isReadOnlyMode}
          />
        )}
      {((operatingMechanismMotor &&
        operatingMechanismMotor.status === Statuses.NotFound) ||
        (operatingMechanismMotor === null &&
          operatingMechanismMotor.status === Statuses.Succeeded)) && (
        <WidgetNoDataMessage />
      )}
      {operatingMechanismMotor &&
        operatingMechanismMotor.status === Statuses.Failed && (
          <WidgetErrorMessage />
        )}
      {processing && <ProcessingModal />}
    </>
  );
};

const mapStateToProps = (
  state: IState,
  { assetId }: IOperatingMechanismMotorOwnProps
): IOperatingMechanismMotorData => ({
  operatingMechanismMotor: getOperatingMechanismMotorSelector(state)(assetId),
  user: getUserSelector(state)
});

const mapDispatchToProps = (
  dispatch: AppDispatch,
  { assetId, getAcknowledgeUrl }: IOperatingMechanismMotorOwnProps
): IOperatingMechanismMotorActions => ({
  loadOperatingMechanismMotor: (assetId: string) =>
    dispatch(loadOperatingMechanismMotor(assetId)),
  acknowledge: (
    intl: IntlShape,
    parameterName: string,
    success: (correction: IThresholdCorrection) => void,
    error: () => void
  ) =>
    acknowledge({
      intl,
      assetId,
      parameterName,
      success,
      error,
      url: getAcknowledgeUrl(assetId, parameterName)
    })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OperatingMechanismMotorInfo);
