// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { useCallback } from "react";

import IAssetPoint from "common/AssetRiskMatrix/models/IAssetPoint";

const useAssetRiskMatrixTooltip = () => {
  const getRiskCssClass = useCallback(({ Risk }: IAssetPoint) => {
    switch (Risk) {
      case "High":
        return "high";
      case "Medium":
        return "medium";
      case "Low":
        return "low";
      default:
        return "unknown";
    }
  }, []);

  return { getRiskCssClass };
};

export default useAssetRiskMatrixTooltip;
