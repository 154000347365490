// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { useMemo } from "react";
import { Typography } from "antd";
import Title from "antd/lib/typography/Title";
import DayjsInput from "models/Dayjs";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import {
  spacingLarge,
  spacingMedium,
  spacingSmall,
  spacingXLarge
} from "styles/StyleVariables";
import IAssetStatusUpdateInfo from "models/IAssetStatusUpdateInfo";
import TooltipFormattedDate from "./TooltipFormattedDate";

const { Text } = Typography;

interface IAssetRiskExecutionDateTooltipProps {
  lastUpdatedBefore: string;
  conditionCalculationDate: DayjsInput;
  conditionDate: DayjsInput;
  latestParameterDate: DayjsInput;
  statusUpdateInfo: IAssetStatusUpdateInfo;
  className?: string;
}

const AssetRiskExecutionDateTooltipExtended = ({
  lastUpdatedBefore,
  conditionCalculationDate,
  conditionDate,
  latestParameterDate,
  statusUpdateInfo,
  className
}: IAssetRiskExecutionDateTooltipProps) => {
  const statusUpdateInfoDesc = useMemo(() => {
    if (!statusUpdateInfo) {
      return (
        <FormattedMessage
          id="asset-risk-execution-date-tooltip.status-update-info-desc-3"
          defaultMessage="The asset status did not change or there is no data about it."
        />
      );
    }

    const statusUnknown = (
      <FormattedMessage
        id="asset-risk-execution-date-tooltip.status-update-info-status-unknown"
        defaultMessage="Unknown"
      />
    );

    const statusFrom = (
      <Text strong ellipsis>
        {statusUpdateInfo.From || statusUnknown}
      </Text>
    );

    const statusTo = (
      <Text strong ellipsis>
        {statusUpdateInfo.To || statusUnknown}
      </Text>
    );

    if (!statusUpdateInfo.From) {
      return (
        <FormattedMessage
          id="asset-risk-execution-date-tooltip.status-update-info-desc-2"
          defaultMessage="The asset status changed to {to}"
          values={{
            to: statusTo
          }}
        />
      );
    }

    return (
      <FormattedMessage
        id="asset-risk-execution-date-tooltip.status-update-info-desc-1"
        defaultMessage="The asset status changed from {from} to {to}"
        values={{
          from: statusFrom,
          to: statusTo
        }}
      />
    );
  }, [statusUpdateInfo]);

  return (
    <AssetRiskExecutionDateTooltipExtendedWrapper
      className={`${className} asset-risk-execution-date-tooltip-extended`}
    >
      <Header level={5} className="header">
        <FormattedMessage
          defaultMessage="Last updated {diff} ago."
          id="asset-risk-execution-date-tooltip.diff"
          values={{
            diff: lastUpdatedBefore
          }}
        />
      </Header>
      <div className="details">
        <div data-qa="tooltip-calculation-date">
          <SectionTitle strong={true}>
            <FormattedMessage
              id="asset-risk-execution-date-tooltip.calculation-date"
              defaultMessage="Calculation date"
            />
            <TooltipFormattedDate date={conditionCalculationDate} />
          </SectionTitle>
          <SectionDesc className="desc">
            <FormattedMessage
              id="asset-risk-execution-date-tooltip.calculation-date-desc"
              defaultMessage="The latest date of the condition calculation for this asset"
            />
          </SectionDesc>
        </div>
        <Divider />
        <div data-qa="tooltip-execution-date">
          <SectionTitle strong={true}>
            <FormattedMessage
              id="asset-risk-execution-date-tooltip.condition-date"
              defaultMessage="Condition date"
            />
            <TooltipFormattedDate date={conditionDate} />
          </SectionTitle>
          <SectionDesc className="desc">
            <FormattedMessage
              id="asset-risk-execution-date-tooltip.condition-date-desc"
              defaultMessage="The latest timestamp among the parameter values that were used for the condition calculation"
            />
          </SectionDesc>
        </div>
        <Divider />
        <div data-qa="tooltip-latest-parameter-date">
          <SectionTitle strong={true}>
            <FormattedMessage
              id="asset-risk-execution-date-tooltip.latest-parameter-date"
              defaultMessage="Latest parameter"
            />
            <TooltipFormattedDate date={latestParameterDate} />
          </SectionTitle>
          <SectionDesc className="desc">
            <FormattedMessage
              id="asset-risk-execution-date-tooltip.latest-parameter-date-desc"
              defaultMessage="The latest timestamp among the uploaded parameter values"
            />
          </SectionDesc>
        </div>
        <Divider />
        <div data-qa="tooltip-status-update-info">
          <SectionTitle strong={true}>
            <FormattedMessage
              id="asset-risk-execution-date-tooltip.status-update-info-date"
              defaultMessage="Last status change"
            />
            <TooltipFormattedDate date={statusUpdateInfo?.Date} />
          </SectionTitle>
          <SectionDesc className="desc">{statusUpdateInfoDesc}</SectionDesc>
        </div>
      </div>
    </AssetRiskExecutionDateTooltipExtendedWrapper>
  );
};

const AssetRiskExecutionDateTooltipExtendedWrapper = styled.div`
  max-width: 40ch;
  margin: ${spacingMedium} 0;
  padding: 0 ${spacingLarge};
`;

const Divider = styled.hr`
  margin: ${spacingLarge} 0;
`;

const Header = styled(Title)`
  &.ant-typography {
    margin-bottom: ${spacingXLarge};
  }
`;

const SectionTitle = styled(Text)`
  strong {
    display: flex;
    justify-content: space-between;
  }

  .date {
    flex-shrink: 0;
  }
`;

const SectionDesc = styled.div`
  padding-top: ${spacingSmall};
`;

export default AssetRiskExecutionDateTooltipExtended;
