// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { IAjaxAction } from "reducers/Index";
import { AppDispatch } from "store";
import { config } from "utils/AppConfig";
import IKeyRiskChangesInfo from "../models/IKeyRiskChangesInfo";

export enum Actions {
  KeyRiskChanges_SetInfo = "KeyRiskChanges_SetInfo"
}

export const setKeyRiskChangesInfo = (
  request: JQuery.jqXHR,
  data?: IKeyRiskChangesInfo[]
): IAjaxAction<IKeyRiskChangesInfo[]> => ({
  request,
  data,
  type: Actions.KeyRiskChanges_SetInfo
});

export const loadKeyRiskChangesInfo = () => (dispatch: AppDispatch) =>
  fetchKeyRiskChangesInfo(dispatch);

function fetchKeyRiskChangesInfo(dispatch: AppDispatch): Promise<void> {
  return new Promise((resolve, reject) => {
    const url = UrlService.getApiUrl(config.api.homepage.keyRiskChanges);
    const request = EndpointService.getJson<IKeyRiskChangesInfo[]>(
      url,
      (request, data) => {
        dispatch(setKeyRiskChangesInfo(request, data));
        resolve();
      },
      (request) => {
        dispatch(setKeyRiskChangesInfo(request));
        reject();
      }
    );
    dispatch(setKeyRiskChangesInfo(request));
  });
}
