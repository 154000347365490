// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Col, Row, Typography } from "antd";
import IDGAOperations from "features/detailpage/features/ltc/models/IDGAOperations";
import IOperations from "features/detailpage/features/ltc/models/IOperations";
import { isEmpty } from "lodash";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { colorGray10 } from "styles/ColorVariables";
import {
  fontSizeMedium,
  fontSizeSmall,
  spacingMedium
} from "styles/StyleVariables";
import DGAOperationsRow from "./DGAOperationsRow";

const { Text } = Typography;

interface IDGAOperationsGridProps {
  dgaOperations: IDGAOperations;
  modelId: string;
  implementationId: string;
  className?: string;
}

const DGAOperationsGrid = ({
  dgaOperations: { Operations },
  modelId,
  implementationId,
  className
}: IDGAOperationsGridProps) => (
  <div className={className} data-qa="dga-operations">
    <Row className="header-row">
      <Col span={5}>
        <Text strong className="title">
          <FormattedMessage
            id="detail_page.widgets.analytics.transformers.ltc.dga_operations"
            defaultMessage="DGA"
          />
        </Text>
      </Col>
      <Col span={3}>
        <Text type="secondary" className="secondary-title">
          <FormattedMessage
            id="detail_page.widgets.analytics.transformers.ltc.dga_operations_unit"
            defaultMessage="Unit"
          />
        </Text>
      </Col>
      <Col span={4}>
        <Text type="secondary" className="secondary-title">
          <FormattedMessage
            id="detail_page.widgets.analytics.transformers.ltc.dga_operations_previous_date"
            defaultMessage="Previous date"
          />
        </Text>
      </Col>
      <Col span={4}>
        <Text type="secondary" className="secondary-title">
          <FormattedMessage
            id="detail_page.widgets.analytics.transformers.ltc.dga_operations_previous_value"
            defaultMessage="Previous value"
          />
        </Text>
      </Col>
      <Col span={4}>
        <Text type="secondary" className="secondary-title">
          <FormattedMessage
            id="detail_page.widgets.analytics.transformers.ltc.dga_operations_current_date"
            defaultMessage="Current date"
          />
        </Text>
      </Col>
      <Col span={4}>
        <Text type="secondary" className="secondary-title">
          <FormattedMessage
            id="detail_page.widgets.analytics.transformers.ltc.dga_operations_current_value"
            defaultMessage="Current value"
          />
        </Text>
      </Col>
    </Row>
    {!isEmpty(Operations) &&
      Operations.map((operation: IOperations, index: number) => (
        <DGAOperationsRow
          className="data-row"
          key={`${operation.Name}_${index}`}
          operation={operation}
          modelId={modelId}
          implementationId={implementationId}
        />
      ))}
  </div>
);

export default styled(DGAOperationsGrid)`
  .ant-col {
    padding: ${spacingMedium};
  }

  .header-row {
    .title {
      font-size: ${fontSizeMedium};
    }

    .secondary-title {
      font-size: ${fontSizeSmall};
      word-break: break-word;
    }
  }

  .data-row {
    &:nth-child(even) {
      background-color: ${colorGray10};
    }
  }
`;
