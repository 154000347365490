// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { hexToRgb } from "@pg/common";
import { colorGray100 } from "@pg/common/build/styles/ColorVariables";
import { Col, Row, Typography } from "antd";
import { FormattedDate, FormattedMessage, FormattedNumber } from "react-intl";
import styled from "styled-components";
import {
  fontSizeMedium,
  fontSizeSmall,
  spacingLarge,
  spacingSmall,
  spacingXLarge,
  spacingXSmall
} from "styles/StyleVariables";

const { Text } = Typography;

interface ILTCLatestUpdateInfoProps {
  cumulativeOperationCount: number;
  maximumOperationCount: number;
  lastUpdate: string;
  className?: string;
}

const LTCLatestUpdateInfo = ({
  cumulativeOperationCount,
  maximumOperationCount,
  lastUpdate,
  className
}: ILTCLatestUpdateInfoProps) => (
  <div className={className}>
    <Text strong className="ltc-latest-update-title">
      <FormattedMessage
        id="detail_page.widgets.analytics.transformers.ltc.last_update"
        defaultMessage="Last update"
      />
    </Text>
    <div className="ltc-latest-update-date">
      <FormattedDate value={new Date(lastUpdate)} />
    </div>
    <Row>
      <Col span={18}>
        <Text type="secondary">
          <FormattedMessage
            id="detail_page.widgets.analytics.transformers.ltc.cumulative_operation_count"
            defaultMessage="Cumulative Operation Count"
          />
        </Text>
      </Col>
      <Col span={6} className="ltc-latest-update-value">
        <FormattedNumber value={cumulativeOperationCount} />
      </Col>
    </Row>
    <Row>
      <Col span={18}>
        <Text type="secondary">
          <FormattedMessage
            id="detail_page.widgets.analytics.transformers.ltc.maximum_operation_count"
            defaultMessage="Maximum Operation Count"
          />
        </Text>
      </Col>
      <Col span={6} className="ltc-latest-update-value">
        <FormattedNumber value={maximumOperationCount} />
      </Col>
    </Row>
  </div>
);

export default styled(LTCLatestUpdateInfo)`
  padding: ${spacingXLarge};
  box-shadow: 0 ${spacingXSmall} ${spacingSmall}
    ${hexToRgb(colorGray100, 0.075)};
  border-radius: ${spacingXSmall};
  background-color: white;

  .ltc-latest-update-title {
    font-size: ${fontSizeMedium};
  }

  .ltc-latest-update-date {
    font-size: ${fontSizeSmall};
    margin-bottom: ${spacingLarge};
  }

  .ltc-latest-update-value {
    text-align: right;
  }
`;
