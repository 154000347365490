// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { ChartOptions } from "billboard.js";
import ILTCOperationsChartLegendItem from "features/detailpage/features/ltc/models/ILTCOperationsChartLegendItem";
import getDefaultConfiguration, {
  colors,
  LTCAlarmLegendId,
  LTCPercentageUsedId,
  LTCWarningLegendId
} from "./DefaultConfiguration";

export default class LTCOperationsChartService {
  public static getChartConfiguration(
    percentageUsed: number,
    warningThreshold: number,
    alarmThreshold: number
  ): ChartOptions {
    return getDefaultConfiguration(
      percentageUsed,
      warningThreshold * 100,
      alarmThreshold * 100
    );
  }

  public static getChartLegendData(): ILTCOperationsChartLegendItem[] {
    return [
      {
        name: "Percentage Used",
        color: colors.percentageUsed,
        id: LTCPercentageUsedId,
        isGridLine: false
      },
      {
        name: "Warning",
        color: colors.warning,
        id: LTCWarningLegendId,
        isGridLine: true
      },
      {
        name: "Alarm",
        color: colors.alarm,
        id: LTCAlarmLegendId,
        isGridLine: true
      }
    ];
  }
}
