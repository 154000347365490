// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Cascader as AntdCascader } from "antd";
import { CascaderProps, DefaultOptionType } from "antd/lib/cascader";
import { useMemo } from "react";
import { useIntl } from "react-intl";

const Cascader = (props: CascaderProps<DefaultOptionType>) => {
  const intl = useIntl();

  const placeholder = useMemo(() => {
    return (
      props.placeholder ||
      intl.formatMessage({
        defaultMessage: "All",
        id: "common.ddl.default_text"
      })
    );
  }, [intl, props.placeholder]);

  const notFoundContent = useMemo(() => {
    return (
      props.notFoundContent ||
      intl.formatMessage({
        defaultMessage: "No data",
        id: "common.no_data_message"
      })
    );
  }, [intl, props.notFoundContent]);

  return (
    <AntdCascader
      {...props}
      placeholder={placeholder}
      notFoundContent={notFoundContent}
    />
  );
};

export default Cascader;
