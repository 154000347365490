// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import {
  IContactWearLine,
  IContactWearRegion,
  IContactWearSeries,
  RegionClass
} from "@apm/widgets/build/widgets/ContactWear";
import { formatDateTime } from "common/DateTime/utils/dateFormatters";
import { ITrendChart } from "common/chart/components/TrendLineChart";
import { kebabCase } from "lodash";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";

interface IUseContactWearChartOptions {
  trendChart: ITrendChart;
}

const useWearI2tChart = ({
  trendChart: { DataSeries, Regions, Lines }
}: IUseContactWearChartOptions) => {
  const intl = useIntl();
  const series: IContactWearSeries[] = useMemo(() => {
    const series: IContactWearSeries[] = [];

    DataSeries &&
      Object.keys(DataSeries).forEach((key) => {
        series.push({
          name: key,
          displayName: intl.formatMessage({
            id: `detail_page.widgets.circuit_breaker.aging.contact_wear_chart.${kebabCase(
              key
            )}`,
            defaultMessage: key
          }),
          values: DataSeries[key],
          unit: "%"
        });
      });

    return series;
  }, [DataSeries, intl]);

  const regions: IContactWearRegion[] = useMemo(() => {
    return (
      Regions?.map<IContactWearRegion>((r) => ({
        regionClass: r.Class as RegionClass,
        displayName: intl.formatMessage({
          id: `threshold.${r.Class}`,
          defaultMessage: r.Class
        }),
        name: r.Class,
        start: r.Start,
        end: r.End
      })) || []
    );
  }, [Regions, intl]);

  const lines: IContactWearLine[] = useMemo(() => {
    return (
      Lines?.map<IContactWearLine>((l) => ({
        displayName: l.Description,
        name: l.Class,
        value: l.Value
      })) || []
    );
  }, [Lines]);

  const formatXTick = useCallback(
    (date: Date) => intl.formatDate(date),
    [intl]
  );

  const formatValue = useCallback(
    (value: number) =>
      `${intl.formatNumber(value, {
        maximumFractionDigits: 1
      })} %`,
    [intl]
  );

  const formatTooltipTitle = useCallback(
    (date: Date) =>
      formatDateTime(intl, date),
    [intl]
  );

  return {
    series,
    regions,
    lines,
    formatXTick,
    formatValue,
    formatTooltipTitle
  };
};

export default useWearI2tChart;
