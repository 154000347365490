// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { config } from "utils/AppConfig";

const getWatchListCount = () => {
  const url = UrlService.getApiUrl(config.api.assetWatchList.watchListCountUrl);

  return new Promise<number>(function (resolve, reject) {
    EndpointService.get(
      url,
      (request, data: number) => {
        resolve(data);
      },
      (request) => {
        reject(request);
      },
      "application/json; charset=utf-8"
    );
  });
};

export default getWatchListCount;
