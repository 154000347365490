// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { IAjaxAction } from "reducers/Index";
import { Action } from "redux";
import { AppDispatch } from "store";
import { config } from "utils/AppConfig";
import INotificationsModelInput from "../models/INotificationsModelInput";
import { INotificationsModelOutput } from "../models/INotificationsModelOutput";
import NotificationsService from "../services/NotificationsService";

export enum Actions {
  Notifications_SetNotificationsSettings = "Notifications_SetNotificationsSettings",
  Notifications_SetNotificationsEnabled = "Notifications_SetNotificationsEnabled",
  Notifications_SetCanBeSaved = "Notifications_SetCanBeSaved",
  Notifications_SetAllOrganizationsChecked = "Notifications_SetAllOrganizationsChecked",
  Notifications_SetAllAssetTypesChecked = "Notifications_SetAllAssetTypesChecked",
  Notifications_SetAllDegradedChecked = "Notifications_SetAllDegradedChecked",
  Notifications_ResetToDefaultState = "Notifications_ResetToDefaultState"
}

export interface ISetNotificationsEnabledAction extends Action<string> {
  value: boolean;
}
export interface ISetAllOrganizationsCheckedAction extends Action<string> {
  value: boolean;
}

export interface ISetAllAssetTypesCheckedAction extends Action<string> {
  value: boolean;
}

export interface ISetCanBeSavedAction extends Action<string> {
  value: boolean;
}

export interface ISetAllDegradedCheckedAction extends Action<string> {
  value: boolean;
}

// #region Actions creators

export const setNotificationsSettingsAction = (
  request: JQuery.jqXHR,
  data?: any
): IAjaxAction<INotificationsModelInput> => ({
  request,
  data,
  type: Actions.Notifications_SetNotificationsSettings
});

export const setNotificationsEnabledAction = (
  value: boolean
): ISetNotificationsEnabledAction => ({
  type: Actions.Notifications_SetNotificationsEnabled,
  value
});

export const setAllOrganizationCheckedAction = (
  value: boolean
): ISetAllOrganizationsCheckedAction => ({
  type: Actions.Notifications_SetAllOrganizationsChecked,
  value
});

export const setAllAssetTypesCheckedAction = (
  value: boolean
): ISetAllAssetTypesCheckedAction => ({
  type: Actions.Notifications_SetAllAssetTypesChecked,
  value
});

export const setCanBeSavedAction = (value: boolean): ISetCanBeSavedAction => ({
  type: Actions.Notifications_SetCanBeSaved,
  value
});

export const setAllDegradedCheckedAction = (
  value: boolean
): ISetAllDegradedCheckedAction => ({
  type: Actions.Notifications_SetAllDegradedChecked,
  value
});

export const resetToDefaultStateAction = (): Action<string> => ({
  type: Actions.Notifications_ResetToDefaultState
});

// #endregion

// #region Actions

export const loadNotificationsSettingsAction = () => (dispatch: AppDispatch) =>
  loadNotificationsSettings(dispatch);

export const saveNotificationsSettingsAction = (
  dispatch: AppDispatch,
  payload: INotificationsModelOutput
) => saveNotificationsSettings(dispatch, payload);

// #endregion

// #region Helpers

function loadNotificationsSettings(dispatch: AppDispatch): Promise<void> {
  return new Promise((resolve, reject) => {
    const url = UrlService.getApiUrl(config.api.notifications);
    const request = EndpointService.getJson<INotificationsModelInput>(
      url,
      (request, data) => {
        dispatch(
          setNotificationsEnabledAction(
            NotificationsService.areNotificationsEnabled(data)
          )
        );
        dispatch(setNotificationsSettingsAction(request, data));
        resolve();
      },
      (request) => {
        dispatch(setNotificationsSettingsAction(request));
        reject(
          `Error during loading notifications: ${request.status} - ${request.statusText}`
        );
      }
    );
    dispatch(setNotificationsSettingsAction(request));
  });
}

function saveNotificationsSettings(
  dispatch: AppDispatch,
  payload: INotificationsModelOutput
): Promise<void> {
  return new Promise((resolve, reject) => {
    const url = UrlService.getApiUrl(config.api.notifications);
    EndpointService.putJson<INotificationsModelOutput>(
      url,
      (request, data) => {
        dispatch(setCanBeSavedAction(false));
        resolve();
      },
      (request) => {
        dispatch(setCanBeSavedAction(true));
        reject(
          `Error during saving notifications: ${request.status} - ${request.statusText}`
        );
      },
      null,
      payload
    );

    dispatch(setCanBeSavedAction(false));
  });
}

// #endregion
