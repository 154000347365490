// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { useCallback } from "react";
import { useAppDispatch } from "store";
import { setAllDegradedCheckedAction } from "../actions/NotificationsActions";

const useSetAllDegradedChecked = () => {
  const dispatch = useAppDispatch();
  const setAllDegradedChecked = useCallback(
    (value: boolean) => {
      dispatch(setAllDegradedCheckedAction(value));
    },
    [dispatch]
  );

  return { setAllDegradedChecked };
};

export default useSetAllDegradedChecked;
