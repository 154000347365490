// Copyright 2016-2024 Hitachi Energy. All rights reserved.

/* eslint-disable jsx-a11y/anchor-is-valid */
import Icon from "@pg/common/build/components/Icon";
import TileErrorMessage from "common/tile/TileErrorMessage";
import Processing from "components/common/Processing";
import { WidgetNoDataMessage } from "components/common/widget/Widget";
import { NavigateFn } from "core/app/components/RouterProvider";
import { Statuses } from "core/data/models/Data";
import { isEmpty } from "lodash";
import React from "react";
import { FormattedMessage } from "react-intl";
import onClickOutsideHOC, {
  HandleClickOutside,
  InjectedOnClickOutProps
} from "react-onclickoutside";
import { config } from "utils/AppConfig";
import "./Analytics.less";
import ReportsStore, { IReportState } from "./ReportsStore";

interface IAnalyticsProps {
  navigate: NavigateFn;
}

class Analytics
  extends React.Component<
    IAnalyticsProps & InjectedOnClickOutProps,
    IReportState
  >
  implements HandleClickOutside<any>
{
  constructor(props: IAnalyticsProps & InjectedOnClickOutProps) {
    super(props);

    this.state = ReportsStore.getReportState();
    this.handleChange = this.handleChange.bind(this);
  }

  public render(): JSX.Element {
    let component: JSX.Element = null;

    if (
      this.state.pbiReport.status === Statuses.Loading ||
      this.state.linkedReport.status === Statuses.Loading
    )
      component = this.getLoadingComponent();
    else if (
      (this.state.pbiReport.status === Statuses.Succeeded ||
        this.state.pbiReport.status == null) &&
      this.state.linkedReport.status === Statuses.Succeeded
    )
      component = this.getReportComponent();
    else if (
      this.state.pbiReport.status === Statuses.Failed ||
      this.state.linkedReport.status === Statuses.Failed
    )
      component = this.getErrorComponent();
    else component = this.getEmptyComponent();

    return component;
  }

  public componentDidMount(): void {
    ReportsStore.addChangeListener(this.handleChange);
    ReportsStore.loadLinkedReports();
  }

  public componentWillUnmount(): void {
    ReportsStore.removeChangeListener(this.handleChange);
  }

  public handleClickOutside: React.MouseEventHandler<any> = (): void => {
    ReportsStore.hideReports();
  };

  private handleChange(): void {
    this.setState(ReportsStore.getReportState());
  }

  private getLoadingComponent(): JSX.Element {
    return (
      <div className="analytics">
        <div className="tile processing">
          <Processing className="spinner small dark" />
        </div>
      </div>
    );
  }

  private getPbiReports(): JSX.Element {
    const { navigate } = this.props;

    return (
      <div className="reports" data-qa="pbi-reports">
        <ul>
          {(this.state.pbiReport.data || []).map((r) => {
            return (
              <li key={r.Id} data-qa="report">
                <a
                  onClick={() => {
                    ReportsStore.hideReports();
                    navigate({
                      pathname: "/Reports/" + encodeURIComponent(r.Name) + "/"
                    });
                  }}
                >
                  <FormattedMessage
                    id={"reports." + r.Name}
                    defaultMessage={ReportsStore.removeReportPrefix(r.Name)}
                  />
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  private getLinkedReports(): JSX.Element {
    if ((this.state.linkedReport.data || []).length > 0) {
      return (
        <div className="reports" data-qa="linked-reports">
          <div className="c-label" data-qa="external-links-label">
            <FormattedMessage
              id="homepage.analytics.external_links"
              defaultMessage="External links"
            />
          </div>
          <ul>
            {(this.state.linkedReport.data || []).map((r) => {
              return (
                <li key={r.DisplayName} data-qa="report">
                  <a
                    href={r.Url}
                    rel="noopener noreferrer"
                    target="_blank"
                    onClick={() => {
                      ReportsStore.hideReports();
                    }}
                  >
                    {r.DisplayName}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      );
    } else return null;
  }

  private getReportComponent(): JSX.Element {
    return (
      <div className="analytics">
        <div className="tile analytics" data-qa="analytics-tile">
          <div className="drop-down">
            <div
              className="analytics-icon"
              onClick={function (e: React.MouseEvent<HTMLDivElement>) {
                e.preventDefault();
                window.open(config.UI.pbiUrl, "_blank");
                ReportsStore.hideReports();
              }}
            >
              <Icon name="apps" />
            </div>
            <div
              className="analytics-title"
              onClick={function () {
                ReportsStore.toggleReports();
              }}
            >
              <FormattedMessage
                id="homepage.analytics.title"
                defaultMessage="Analytics"
              />
              <Icon
                className={this.state.showReports ? "top" : ""}
                dataQa="expand-icon"
                name="chevron_left"
                size="lg"
              />
            </div>
          </div>
          {this.state.showReports ? this.getReportsListComponent() : null}
        </div>
      </div>
    );
  }

  private getReportsListComponent(): JSX.Element {
    const ReportsList = () => (
      <div>
        {this.getPbiReports()}
        {this.getLinkedReports()}
      </div>
    );

    return (
      <div className="report-list" data-qa="reports-list-tile">
        {this.hasNoReports() ? <WidgetNoDataMessage /> : <ReportsList />}
      </div>
    );
  }

  private hasNoReports(): boolean {
    const { pbiReport, linkedReport } = this.state;
    return isEmpty(pbiReport.data) && isEmpty(linkedReport.data);
  }

  private getErrorComponent(): JSX.Element {
    return (
      <div className="analytics">
        <TileErrorMessage
          messageId="homepage.server_error"
          defaultMessage="Sorry, we have problems with the server connection."
          className="open-issues"
        />
      </div>
    );
  }

  private getEmptyComponent(): JSX.Element {
    return (
      <div className="analytics">
        <div className="tile">
          <div className="tile-content empty" />
        </div>
      </div>
    );
  }
}

export default onClickOutsideHOC(Analytics);
