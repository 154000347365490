// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import SearchParams from "@pg/common/build/models/SearchParams";
import { routes } from "core/app/components/AppRoutes";
import { getLocaleSubstring } from "core/app/utils/checkSearchLocales";
import { useLocation } from "react-router";

const useNavToAsset = () => {
  const location = useLocation();
  const navigateTo = (assetId: string) => {
    const searchParams = new SearchParams({ assetId });
    const nav = {
      pathname: routes.detailPage.pathname,
      search: `${searchParams.toString()}&${getLocaleSubstring(
        location.search
      )}`
    };

    return nav;
  };

  return { navigateTo };
};

export default useNavToAsset;
