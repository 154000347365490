// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Action } from "redux";

import Data from "core/data/models/Data";
import { IAjaxAction } from "reducers/Index";
import { Actions } from "../actions/BatteryVoltageActions";
import BatteryVoltageReducer from "../models/BatteryVoltageReducer";
import IVoltageData from "../models/IVoltageData";
import IState from "../models/IState";

export const defaultState: IState = {
  voltageData: new Data()
};

const reducer: BatteryVoltageReducer = (
  state = defaultState,
  action: Action
) => {
  switch (action.type) {
    case Actions.DetailPage_BatterVoltage_SetVoltageData: {
      return {
        ...state,
        voltageData: new Data(
          (action as IAjaxAction<IVoltageData>).request,
          (action as IAjaxAction<IVoltageData>).data
        )
      };
    }
    default:
      return state;
  }
};

export default reducer;
