// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { hexToRgb } from "@pg/common";
import Icon from "@pg/common/build/components/Icon";
import { colorPrimary } from "@pg/common/build/styles/ColorVariables";
import { Menu, Typography } from "antd";
import { first, isArray, isNil } from "lodash";
import { useCallback, useMemo } from "react";
import { To, matchPath, useLocation } from "react-router";
import styled, { createGlobalStyle } from "styled-components";
import {
  fontSizeSmall,
  spacingLarge,
  spacingMedium,
  spacingXLarge
} from "styles/StyleVariables";
import useAppNavigate from "../hooks/useAppNavigate";
import ILayoutRoute from "../models/ILayoutRoute";
import RouterSwitch from "./RouterSwitch";

const { Text } = Typography;

interface IRouterProps {
  className?: string;
  routes: ILayoutRoute[];
}

const RouterLayout = ({ className, routes }: IRouterProps) => {
  const location = useLocation();
  const navigate = useAppNavigate();

  const defaultRoute = useMemo(() => {
    return first(routes.filter((r) => !isNil(r.to)));
  }, [routes]);

  const getPathname = useCallback((to: To) => {
    if (typeof to === "string") return to;
    return to?.pathname || "";
  }, []);

  const getDataQa = useCallback((id: string) => {
    const dataQa = `route-${id}`;
    return dataQa;
  }, []);

  const getDataQaClass = useCallback((routeName: string) => {
    const slashIndex = routeName?.indexOf("/");
    const resultRouteName =
      slashIndex !== -1 ? routeName.substring(0, slashIndex) : routeName;
    return resultRouteName?.toLowerCase();
  }, []);

  const navigateTo = useCallback(
    (key: string) => {
      const route = routes.find((r) => getPathname(r.to) === key);
      if (isNil(route)) return;

      navigate(route.to);
    },
    [getPathname, navigate, routes]
  );

  const matchedRoute = useMemo(() => {
    const byPathname = (route: ILayoutRoute) => {
      const pathname = `${getPathname(route.to)}/*`;
      const matched = matchPath(pathname, location.pathname) !== null;
      return matched;
    };

    const matchedRoutes = routes.filter(byPathname);
    const matchedRoute = first(matchedRoutes);
    return matchedRoute;
  }, [getPathname, location.pathname, routes]);

  return (
    <div className={className}>
      <SubmenuGlobalStyle />
      <Menu
        selectedKeys={[getPathname(matchedRoute?.to)]}
        mode="horizontal"
        onClick={(info) => {
          navigateTo(info.key);
        }}
      >
        {routes.map((r) =>
          r.submenu ? (
            <Menu.SubMenu
              data-qa={getDataQa(r.id)}
              popupClassName={`${getDataQa(r.id)}-popup`}
              key={getPathname(r.to)}
              title={
                <>
                  <Text strong>{r.displayName || r.route}</Text>
                  <Icon name="expand_more" />
                </>
              }
            >
              {isArray(r.submenu)
                ? r.submenu.map((s, i) => (
                    <Menu.Item
                      className="router-layout-submenu-item"
                      data-qa={`${getDataQa(r.id)}-popup-item`}
                      key={i}
                      onClick={s.onClick}
                    >
                      {s.displayName || s.route}
                    </Menu.Item>
                  ))
                : r.submenu}
            </Menu.SubMenu>
          ) : (
            <Menu.Item data-qa={getDataQa(r.id)} key={getPathname(r.to)}>
              <Text strong>{r.displayName || r.route}</Text>
            </Menu.Item>
          )
        )}
      </Menu>
      <div
        className="layout-content"
        data-qa={getDataQaClass(matchedRoute?.route ? matchedRoute?.route : "")}
      >
        <RouterSwitch
          defaultRoute={defaultRoute ? { route: defaultRoute } : undefined}
          routes={routes}
        />
      </div>
    </div>
  );
};

const borderSize = "3px";

export default styled(RouterLayout)`
  .ant-menu-horizontal {
    background-color: transparent;
    border-bottom: none;

    .ant-menu-item,
    .ant-menu-submenu {
      border-top: solid ${borderSize} transparent;
      font-size: ${fontSizeSmall};
      padding: 0 ${spacingMedium};

      &.ant-menu-item-active,
      &.ant-menu-item-selected,
      &.ant-menu-submenu-active,
      &.ant-menu-submenu-selected,
      &.ant-menu-submenu-open {
        border-top: solid ${borderSize} ${colorPrimary};
        border-bottom: none;

        &:after {
          display: none;
        }
      }

      &.ant-menu-item-selected,
      &.ant-menu-submenu-selected {
        background-color: white;
      }
    }

    .ant-menu-title-content {
      display: flex;
      align-items: center;
    }
  }

  .layout-content {
    padding: ${spacingLarge};
    padding-top: ${spacingXLarge};
  }
`;

const SubmenuGlobalStyle = createGlobalStyle`
  .ant-menu-submenu {
    .router-layout-submenu-item.ant-menu-item-active {
      background-color: ${hexToRgb(colorPrimary, 0.08)};
    }
  }
`;
