// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import CoordinateService from "./CoordinateService";
import Scale from "../models/Scale";
import Svg from "../models/Svg";

const colorAxesPrimary = "#262626";
const colorAxesSecondary = "#a0a0a0";
const colorLabelPrimary = "#262626";
const colorLabelSecondary = "#a0a0a0";
const fontSize = "10.5pt";

const shift = 4;

class AxesService {
  static drawAxes = (
    svg: Svg,
    width: number,
    height: number,
    labels: string[],
    range: number,
    scale: Scale
  ) => {
    for (let i = 0; i < labels.length; i++) {
      const label = labels[i];
      const radians = (2 * Math.PI * i) / labels.length;
      const isLast = i === labels.length - 1;
      const primary = i % 3 === 0;

      AxesService.drawAxesLine(
        svg,
        width,
        height,
        range,
        scale,
        radians,
        primary
      );
      AxesService.drawAxesLabel(
        svg,
        width,
        height,
        range,
        scale,
        radians,
        label,
        primary,
        isLast
      );
    }
  };

  private static drawAxesLine = (
    svg: Svg,
    width: number,
    height: number,
    range: number,
    scale: Scale,
    radians: number,
    primary: boolean
  ) => {
    const r = range * 1.05;
    const coordinate = CoordinateService.angleToCoordinate(
      width,
      height,
      scale,
      radians,
      r
    );

    svg
      .append("line")
      .attr("x1", width / 2)
      .attr("y1", height / 2)
      .attr("x2", coordinate.x)
      .attr("y2", coordinate.y)
      .attr("stroke", primary ? colorAxesPrimary : colorAxesSecondary)
      .attr("stroke-width", primary ? 2 : 1);
  };

  private static drawAxesLabel = (
    svg: Svg,
    width: number,
    height: number,
    range: number,
    scale: Scale,
    radians: number,
    label: string,
    primary: boolean,
    isLast: boolean
  ) => {
    const r = range * 1.15;

    const coordinate = CoordinateService.angleToCoordinate(
      width,
      height,
      scale,
      radians,
      r
    );

    const xShift = shift * label.length;
    const yShift = shift;

    svg
      .append("text")
      .attr("x", coordinate.x - xShift)
      .attr("y", coordinate.y + yShift)
      .attr("font-weight", primary || isLast ? "bold" : "normal")
      .attr("font-size", fontSize)
      .attr("fill", primary || isLast ? colorLabelPrimary : colorLabelSecondary)
      .text(label);
  };
}

export default AxesService;
