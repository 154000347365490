// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import * as React from "react";
import { FormattedMessage } from "react-intl";

import "./Status.less";

interface IStatusProps {
  value: string;
}

const Status = ({ value }: IStatusProps) => {
  return (
    <div className="column status">
      <FormattedMessage
        defaultMessage={value}
        id={`issues_page.grid.columns.status.${value}`}
      />
    </div>
  );
};

export default Status;
