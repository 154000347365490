// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React, { useCallback } from "react";

import { ITreeNode } from "common/form/components/Tree";
import ConfigurationBoxContainer from "../../containers/ConfigurationBoxContainer";

import useSetAllOrganizationsChecked from "../../hooks/useSetAllOrganizationsChecked";

import { NotificationsFormFields } from "../../selectors/getNotificationsFormSelector";

export interface IOrganizationsProps {
  notificationsEnabled: boolean;
  items: ITreeNode[];
  allOrganizationsChecked: boolean;
  defaultCheckedKeys?: string[];
}

const Organizations = ({
  items,
  notificationsEnabled,
  defaultCheckedKeys,
  allOrganizationsChecked
}: IOrganizationsProps) => {
  const { setAllOrganizationChecked } = useSetAllOrganizationsChecked();
  const handleAllChecked = useCallback(
    (checked: boolean) => {
      setAllOrganizationChecked(checked);
    },
    [setAllOrganizationChecked]
  );

  return (
    <ConfigurationBoxContainer
      headerMessage={{
        id: "notifications.filter.organization",
        description: "Organization",
        defaultMessage: "Organization"
      }}
      options={items}
      disabled={!notificationsEnabled}
      defaultCheckedKeys={defaultCheckedKeys}
      inputName={NotificationsFormFields.organizations}
      dataQa="Organization"
      allChecked={allOrganizationsChecked}
      handleOnAllChecked={handleAllChecked}
    ></ConfigurationBoxContainer>
  );
};

export default Organizations;
